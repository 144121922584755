
/*
 * Written by Alexander Agudelo < alex@kemu.io >, 2021
 * Date: 15/Feb/2021
 * Last Modified: 21/08/2023, 9:48:21 pm
 * Modified By: Alexander Agudelo
 * Description:  Publish recipe
 * 
 * ------
 * Copyright (C) 2021 Kemu - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential.
 */

import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Spin, Steps } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/lib/upload/interface';
import capitalize from 'capitalize';
import { useDispatch, useSelector } from 'react-redux';
import { AssetSection, PublicationDetails, PublishEntityRequestBody } from '@kemu-io/kemu-types/dist/types';
import StandardModal from '../../modal/standardModal';
import StyledButton from '../../form-control/styledButton/styledButton';
import useAlert from '../../alert/useAlert';
import { AsyncRequestStatus, RecipeResponse } from '../../../types/core_t';
import { publishRecipeState, publicationDetails, clearPublicationDetails } from '../../layout/marketplace/marketplaceSlice';
import useMountEffect from '../../../common/hooks/useMountEffect';
import { fetchPublicationDetails } from '../../layout/marketplace/reducers/getPublicationDetails';
import { publishRecipe } from '../../layout/marketplace/reducers/publishRecipe';
import styles from './publisher.module.css';
import RecipeDetails from './recipeDetails';
import SelectedStep from './selectedStep';
import RecipeRequirements from './recipeRequirements';
import RecipeImages from './recipeImages';
import RecipePublish from './recipePublish';

const { Step } = Steps;


interface Asset {
	serverPath: string;
	version: number;
	section: AssetSection;
}

export type UploadedAsset = UploadFile & Asset;
interface Props {
	recipe: RecipeResponse;
	onClose: (reloadList?: boolean)=> void;
}

const defaultPublication = {
	version: 1,
	subtitle: '',
	description: '',
	categories: [],
	requirements: [],
	linkedTutorial: '',
	assets: []
};


const TOTAL_STEPS = 4;

const getAssetType = (contentType: string): 'image' | 'video' => {
	return contentType.includes('ogg') || contentType.includes('mp4') ? 'video' : 'image';
};

const Publisher = (props: Props): React.JSX.Element => {
	const { onClose } = props;
	const recipeId = props.recipe.id;
	const dispatch = useDispatch();
	const publishingState = useSelector(publishRecipeState);
	const publishedInfo = useSelector(publicationDetails);
	const [assets, setAssets] = useState<UploadedAsset[]>([]);
	const [currentStep, setCurrentStep] = useState(0);
	const Alert = useAlert();
	const intl = useIntl();


	const [publication, setPublication] = useState<PublishEntityRequestBody>({
		...defaultPublication,
		type: 'recipe',
		entityId: props.recipe.id,
		title: props.recipe.name,
	});

	const updateSettings = (name: keyof PublicationDetails, value: unknown) => {
		setPublication(p => ({ ...p, [name]: value }) );
	};

	const onInputChanged = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		const name = event.currentTarget.name as keyof PublicationDetails;
		const value = event.currentTarget.value;
		updateSettings(name, value);
	};

	const alertMissingFile = (name: string) => {
		return Alert.error({
			okText: intl.formatMessage({ id: 'Alert.Default.CloseButton', defaultMessage: 'Close' }),
			content: intl.formatMessage({
				id: 'Marketplace.MyRecipes.PublishWizard.RequiredFileMissingContent',
				defaultMessage: 'You need to add a "{fileType}" image first'
				}, { fileType: name }
			),

			title: intl.formatMessage({
				id: 'Marketplace.MyRecipes.PublishWizard.RequiredFileMissingTitle',
				defaultMessage: '{fileType} image is required',
				}, { fileType: capitalize(name) }
			)
		});
	};

	const onHandlePublish = async () => {
		const hasHero = assets.find(file => file.section === 'hero');
		const hasThumbnail = assets.find(file => file.section === 'thumbnail');
		if (!hasHero) { return alertMissingFile('hero'); }
		if (!hasThumbnail) { return alertMissingFile('thumbnail'); }


		const parsedAssets: {type: 'image' | 'video', url: string, section: AssetSection}[] = [];
		assets.forEach(file => {
			if (!file.type) { return; }
			const type = getAssetType(file.type);
			parsedAssets.push({
				type,
				url: file.serverPath,
				section: file.section
			});
		});


		const requestBody: PublishEntityRequestBody = {
			...publication,
			assets: parsedAssets,
		};

		dispatch(publishRecipe({
			recipeId,
			details: requestBody,
			existingPublicationId: props.recipe.publication?.id
		}));
	};

	const handleModalClose = useCallback((reload: boolean) => {
		dispatch(clearPublicationDetails());
		onClose && onClose(reload);
	}, [onClose, dispatch]);

	useMountEffect(() => {
		if (props.recipe.publication?.id) {
			dispatch(fetchPublicationDetails({
				entityType: 'recipe',
				publicationId: props.recipe.publication?.id
			}));
		}
	});

	useEffect(() => {
		if (publishedInfo.details && publishedInfo.asyncState.status === AsyncRequestStatus.completed) {
			setPublication(publishedInfo.details);
			// UPDATE: 07/Jun/2021: Displaying a list of existing assets would
			// allow users to delete them, which would affect the existing publication.
			// I decided then NOT to visualize existing assets and force any re-publishing to 
			// define ALL assets from scratch.
			// setAssets(publishedInfo.details.assets.map((asset, index) => {
			// 	const fileName = (asset.url.split('/').pop() || '').split('?')[0];
			// 	return {
			// 		name: fileName,
			// 		section: asset.section,
			// 		serverPath: asset.url,
			// 		size: 0,
			// 		uid: `uuid_${index}_${fileName}`,
			// 		type: mime.lookup(fileName) || '',
			// 		version: publishedInfo.details!.version,
			// 	};
			// }));
		}
	}, [publishedInfo, props.recipe.id]);

	useEffect(() => {
		if (publishingState.asyncState.status === AsyncRequestStatus.completed) {
			handleModalClose(true);
		} else if (publishingState.asyncState.status === AsyncRequestStatus.error) {
			Alert.error({
				okText: intl.formatMessage({ id: 'Alert.Default.CloseButton', defaultMessage: 'Close' }),
				title: intl.formatMessage({
					id: 'Marketplace.MyRecipes.PublishWizard.PublishFailedTitle',
					defaultMessage: 'Error publishing recipe'
				}),
				content: publishingState.asyncState.error?.message
			});
		}
	}, [publishingState.asyncState, handleModalClose, Alert, intl]);


	const onCategoriesChanged = (values: string[]) => {
		updateSettings('categories', values);
	};

	const onAgeChanged = (value: string) => {
		updateSettings('age', value);
	};

	const onFileAdded = (file: UploadedAsset) => {
		setAssets(files => [...files, { ...file }]);
	};

	const onFileRemoved = (file: UploadedAsset) => {
		const filteredOut = assets.filter(asset => asset.uid !== file.uid);
		setAssets(filteredOut);
	};

	const Footer = ():JSX.Element => {
		return (
			<div className={styles.Footer}>
				{currentStep > 0 && (
					<StyledButton disabled={publishingState.asyncState.status === 'loading'} onClick={() => setCurrentStep(s => s-1)} title={<FormattedMessage id="Marketplace.MyRecipes.PublishWizard.PreviousButton" defaultMessage="Previous"/>} />
				)}

				{currentStep < TOTAL_STEPS-1 && (
					<StyledButton onClick={() => setCurrentStep(s => s+1)} title={<FormattedMessage id="Marketplace.MyRecipes.PublishWizard.NextButton" defaultMessage="Next"/>} className={styles.NextBtn}/>
				)}

				{currentStep === TOTAL_STEPS-1 && (
					<StyledButton disabled={publishingState.asyncState.status === 'loading'} color="secondary" onClick={onHandlePublish} title={<FormattedMessage id="Marketplace.MyRecipes.PublishWizard.PublishButton" defaultMessage="Publish"/>} className={styles.NextBtn}/>
				)}
			</div>
		);
	};

	return (
		<StandardModal
			loading={publishedInfo.asyncState.status === AsyncRequestStatus.loading}
			customFooter={<Footer />}
			closeOnMaskClick={false}
			onCancel={() => handleModalClose(false)}
			title={<FormattedMessage id="Marketplace.MyRecipes.PublishWizard.Title" defaultMessage="Publish Recipe" />}
			width={650}
			visible={true}
		>

			<Steps current={currentStep} onChange={(next) => setCurrentStep(next)} className={styles.Steps}>
				<Step title="Details" />
				<Step title="Requirements" />
				<Step title="Images" />
				<Step title="Publish" />
      </Steps>


			<SelectedStep selectedStep={currentStep} className={styles.StepContent}>
				<RecipeDetails
					title={publication.title}
					onTitleChange={onInputChanged}
					subtitle={publication.subtitle}
					onSubtitleChange={onInputChanged}
					categories={publication.categories}
					onCategoriesChanged={onCategoriesChanged}
					age={publication.age}
					onAgeSelected={onAgeChanged}
					description={publication.description}
					onDescriptionChange={onInputChanged}
				/>

				<RecipeRequirements
					requirements={publication.requirements}
					onSetRequirements={(list) => updateSettings('requirements', list)}
				/>

				<RecipeImages
					recipeId={recipeId}
					onFileAdded={onFileAdded}
					onFileRemoved={onFileRemoved}
					assets={assets}
				/>

				<Spin indicator={<LoadingOutlined />} spinning={publishingState.asyncState.status === 'loading'}>
					<RecipePublish recipeId={recipeId} assets={assets} />
				</Spin>

			</SelectedStep>
		</StandardModal>
	);
};

export default Publisher;
