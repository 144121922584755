
import React from 'react';
import Icon from '@ant-design/icons';
import MapWidgetProcessor, { RangeMapWidgetState, getDefaultState } from '@kemu-io/kemu-core/dist/gates/map';
import classNames from 'classnames';
import { WidgetPortContext } from '@kemu-io/kemu-core/dist/types/gate_t';
import useReactiveWidgetState from '../../../common/hooks/useReactiveWidgetState';
import { GateCustomSettingsProps, GetPortsInformationFunction, GateUI, GateUIProps } from '..';
import GateIcon from '../../gateIcon/gateIcon';
import { ReactComponent as ElapsedGateIcon } from '../../../assets/img/gates/map-gate.svg';
import KemuSwitch from '../../form-control/kemuSwitch/kemuSwitch';
import useNumericInput from '../../../common/hooks/useNumericInput';
import useTranslation from '../../../common/hooks/useTranslation';
import { SETTINGS_CONTAINER_CLASS } from '../../../common/constants';
import styles from './map.module.css';
type Props = GateUIProps

const RangeMapGate = (props: Props): React.JSX.Element => {
	const t = useTranslation('LogicMaker.Gates.Map.');
	const fromMinPlaceholder = t('Placeholder.FromMin', 'min');
	const fromMaxPlaceholder = t('Placeholder.FromMax', 'max');
	const toMinPlaceholder = t('Placeholder.ToMin', 'To min');
	const toMaxPlaceholder = t('Placeholder.ToMax', 'To max');

	const fromMinTooltip = t('Tooltip.FromMin', 'Minimum input value');
	const fromMaxTooltip = t('Tooltip.FromMax', 'Maximum input value');
	const toMinTooltip = t('Tooltip.ToMin', 'Minimum output value');
	const toMaxTooltip = t('Tooltip.ToMax', 'Maximum output value');

	const numbersOnly = useNumericInput();
	const [state, setState] = useReactiveWidgetState<RangeMapWidgetState>(props.recipeId, props.thingRecipeId, props.info.id);
	const fixedState = {
		...getDefaultState(),
		...state,
	};

	const handleInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = (evt.currentTarget.value as unknown) as number;
		const propName = evt.currentTarget.getAttribute('data-range');
		if (!propName) { return; }
		setState({
			...state,
			[propName]: newValue
		}, true);
	};


	return (
		<div className={`${styles.GateBody}`}>
			<div>
				<div className="gate-input">
					<input type="text"
						title={fromMinTooltip}
						onInput={numbersOnly}
						placeholder={fromMinPlaceholder}
						value={fixedState.fromMin || ''}
						data-range="fromMin"
						onChange={handleInputChange}
					/>
				</div>

				<div className="gate-input">
					<input type="text"
						title={fromMaxTooltip}
						onInput={numbersOnly}
						placeholder={fromMaxPlaceholder}
						value={fixedState.fromMax || ''}
						data-range="fromMax"
						onChange={handleInputChange}
					/>
				</div>
			</div>

			<div>
				<div className="gate-input">
					<input type="text"
						title={toMinTooltip}
						onInput={numbersOnly}
						placeholder={toMinPlaceholder}
						value={fixedState.toMin || ''}
						data-range="toMin"
						onChange={handleInputChange}
					/>
				</div>

				<div className="gate-input">
					<input type="text"
						title={toMaxTooltip}
						onInput={numbersOnly}
						placeholder={toMaxPlaceholder}
						value={fixedState.toMax || ''}
						data-range="toMax"
						onChange={handleInputChange}
					/>
				</div>
			</div>
		</div>
	);
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={ElapsedGateIcon} />}/>
	);
};

const GateCustomSettings = (props: GateCustomSettingsProps): React.JSX.Element => {
	const [state, setState] = useReactiveWidgetState<RangeMapWidgetState>(props.recipeId, props.blockId, props.gateInfo.id);
	const fixedState = {
		...getDefaultState(),
		...state,
	};

	const handleSwitchChange = (checked: boolean) => {
		setState({ ...fixedState, clamp: checked });
	};

	return (
		<div className={classNames(styles.SettingsContainer, SETTINGS_CONTAINER_CLASS)}>
			{props.children}
			<label>Limit Values</label>
			<KemuSwitch
				size="small"
				checked={fixedState.clamp}
				onChange={handleSwitchChange}
			/>
		</div>
	);
};


const getPortsInformation: GetPortsInformationFunction = (state, widgetInfo) => {
	const portContext: WidgetPortContext = { recipePoolId: widgetInfo.recipePoolId, recipeType: widgetInfo.recipeType };
	const outputNames = MapWidgetProcessor.getOutputNames(state, portContext);
	const inputNames = MapWidgetProcessor.getInputNames(state, portContext);

	return {
		inputs: [{
			name: inputNames[0].name,
			type: inputNames[0].type,
			position: 'Left'
		}],

		outputs: [{
			name: outputNames[0].name,
			type: outputNames[0].type,
			position: 'Right'
		}]
	};
};

export default {
	getPortsInformation,
	BarIcon: GateBarIcon,
	Element: RangeMapGate,
	CustomSettingsDialog: GateCustomSettings,
	hasTitle: true,
	getWidgetTitle: (intl) => intl.formatMessage({ defaultMessage: 'Range Map', id: 'LogicMaker.Gates.Map.Title' }),
} as GateUI;
