import { WidgetPort } from '@kemu-io/kemu-core/dist/types';
import { PortDescription } from './index';


/**
 * Dynamically builds a list of ports and their position based on the number of ports
 * @param ports The list of ports to build the description for
 * @param customSpacing The space between ports, defaults is 0.12.
 */
export const buildOutputPortsDescription = (ports: WidgetPort[], customSpacing?: number): PortDescription[] => {
  const outputFraction = ((1 - (customSpacing ?? 0.12)) / ports.length);
  const outputs = ports.map((output, i) => {
    let space = (outputFraction * (i + 1));
    if (ports.length === 1) { space = 0.5; }
    if (ports.length === 2) { space = 0.4 + (0.33 * i); }

    // reduce padding on the first port
    space -= 0.05;


    const description: PortDescription =  {
      name: output.name,
      type: output.type,
      jsonShape: output.jsonShape,
      position: [1, space, 1, 0]
    };

    return description;
  });

  return outputs;
};
