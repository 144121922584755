import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import { AsyncRequestStatus } from '../../../types/core_t';
import { fetchingState, fetchPublicRecipes, publicRecipeList } from '../../layout/marketplace/marketplaceSlice';
import StyledButton from '../../form-control/styledButton/styledButton';
import { MarketplaceCacheDefaultState, MarketplaceCacheState, MARKETPLACE_CONFIG_NAME } from '../../layout/marketplace/marketplace';
import { getStoredConfig, setStoredConfig } from '../../../common/utils';
import CardSkeleton from '../CardSkeleton/CardSkeleton';
import PublicationCard from '../PublicationCard/PublicationCard';
import useTranslation from '../../../common/hooks/useTranslation';


const PublicRecipes = (): React.JSX.Element => {
	const recipeCache = getStoredConfig<MarketplaceCacheState>(MARKETPLACE_CONFIG_NAME, MarketplaceCacheDefaultState);
	const dispatch = useDispatch();
	const t = useTranslation('Marketplace');
	const asyncState = useSelector(fetchingState);
	const recipes = useSelector(publicRecipeList);
	const loadRecipeList = () => {
		dispatch(fetchPublicRecipes());
	};

	useEffect(() => {
		dispatch(fetchPublicRecipes());
	}, [dispatch]);

	// Used to record the last number of fetched recipes so we can show skeletons of the same quantity
	useEffect(() => {
		if (recipes.length !== recipeCache.publicRecipes) {
			setStoredConfig<MarketplaceCacheState>(MARKETPLACE_CONFIG_NAME, { publicRecipes: recipes.length });
		}
	}, [recipeCache.publicRecipes, recipes.length]);


	return (
		<>
			{asyncState.status === AsyncRequestStatus.loading && (
				<CardSkeleton totalCards={recipeCache.publicRecipes || 5} />
			)}

			{ (asyncState.status === AsyncRequestStatus.error) && (
				<Row>
					<Col span={6} offset={9} style={{ textAlign: 'center' }}>
						<p style={{ color: 'red' }}>{asyncState.error?.message}</p>
						<StyledButton onClick={loadRecipeList} title={t('RecipeList.TryAgainBtn', 'Try Again')} />
					</Col>
				</Row>
			)}

			{ asyncState.status === AsyncRequestStatus.completed && Array.isArray(recipes) && (
				<>
					<Row gutter={{ xs: 0, sm: 12, md: 16, lg: 16, xl: 24 }}>
						{recipes.map((recipe, index) => {
							return (
								<Col key={index} xs={24} sm={24} md={12} lg={8} xl={6} xxl={4}>
									<PublicationCard {...recipe}/>
								</Col>
							);
						})}
					</Row>
				</>
			)}
		</>
	);
};

export default PublicRecipes;
