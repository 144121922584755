import React, { useEffect } from 'react';
import { notification, Progress } from 'antd';
import { useSelector } from 'react-redux';
import { blockInstallProcess, BlockInstallationProcess } from '../../features/Workspace/workspaceSlice';
import { useTranslation } from '../../common/hooks';
import { selectInstallDefaultThingStatus } from '../../app/reducers/thing/thingSlice';
import { AsyncRequestStatus } from '../../types/core_t';
import styles from './ThingInstallationNotification.module.css';

const INSTALL_MESSAGE_KEY = 'InstallProgressNotification';
const LOADING_MESSAGE_KEY = 'LoadingProgressNotification';

const InstallationBody = (blocksInProgress: Record<string, BlockInstallationProcess>) => {
	return (
		<div>
			{Object.keys(blocksInProgress).map(blockId => {
				const item = blocksInProgress[blockId];
				return (
					<div key={blockId}>
						<label>{item.block.name} <span className={styles.Version}>({item.block.version})</span>:
							{!item.errorMsg ? (
								<Progress percent={item?.progress?.percentage || 0} />
								) : (
								<>
									<span style={{ color: 'red' }}>  {item.errorMsg}</span>
									<Progress percent={100} status="exception" />
								</>
							)}
						</label>
					</div>
				);
			})}
		</div>
	);
};

const ThingInstallationNotification = (): React.JSX.Element | null => {
	const downloadItems = useSelector(blockInstallProcess);
	const fetchingDefaultThingsState = useSelector(selectInstallDefaultThingStatus);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const t = useTranslation('Interface.InstallationPopup');
	const totalItems = Object.keys(downloadItems).length;


	useEffect(() => {
		if (totalItems) {
			notification.open({
				className: 'kemu-things-install-notification',
				key: INSTALL_MESSAGE_KEY,
				duration: 0,
				placement: 'bottomRight',
				message: t('Downloading', 'Downloading Things'),
				description: <InstallationBody {...downloadItems}/>,
			});
		} else {
			notification.close(INSTALL_MESSAGE_KEY);
		}
	}, [downloadItems, totalItems, t]);

	useEffect(() => {
		if (fetchingDefaultThingsState.status === AsyncRequestStatus.loading) {
			notification.info({
				className: 'kemu-things-install-notification',
				key: LOADING_MESSAGE_KEY,
				duration: 0,
				placement: 'bottomRight',
				message: t('Loading', 'Loading things..'),
			});
		} else if (fetchingDefaultThingsState.status === AsyncRequestStatus.completed) {
			notification.close(LOADING_MESSAGE_KEY);
		}
	}, [fetchingDefaultThingsState.status, t]);

	return null;
};

export default ThingInstallationNotification;
