import { PublicationEntity } from '@kemu-io/kemu-types/dist/types';
import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import * as marketplaceApi from '../../../../api/marketplace/marketplaceApi';
import { AsyncRequestStatus } from '../../../../types/core_t';
import { clearUnpublishEntityId, MarketplaceState } from '../marketplaceSlice';

export const unpublishRecipeAction = createAsyncThunk('/marketplace/unpublish', async (publicationId: string, thunk): Promise<PublicationEntity> => {
	thunk.dispatch(clearUnpublishEntityId(publicationId));
	const details = await marketplaceApi.unpublishEntity(publicationId);
	return details;
});

export const unpublishRecipeReducer = ((builder: ActionReducerMapBuilder<MarketplaceState>/*, thunk: any*/): void => {
	builder.addCase(unpublishRecipeAction.pending, (state) => {
		state.unpublishState.asyncState.status = AsyncRequestStatus.loading;
		state.unpublishState.asyncState.error = undefined;
	});

	builder.addCase(unpublishRecipeAction.fulfilled, (state, action: PayloadAction<PublicationEntity>) => {
		state.unpublishState.asyncState.status = AsyncRequestStatus.completed;

		state.userRecipeList = state.userRecipeList.map(recipe => {
			const copy = { ...recipe };
			if (copy.publication?.id === action.payload.id) {
				copy.publication.state = action.payload.state;
			}
			return copy;
		});
	});

	builder.addCase(unpublishRecipeAction.rejected, (state, action) => {
		state.unpublishState.asyncState.status = AsyncRequestStatus.error;
		state.unpublishState.asyncState.error = action.error;
	});
});
