import { ContentBlock, ContentState, EditorState, RichUtils } from 'draft-js';
// @ts-expect-error missing typings
import getFragmentFromSelection from 'draft-js/lib/getFragmentFromSelection';


export const LINK_ENTITY_TYPE = 'LINK';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GetFragmentFromSelectionFun = (state: EditorState) => Immutable.OrderedMap<any, any>;

const getTextFromSelection = (editorState: EditorState): string => {
	const selected = (<GetFragmentFromSelectionFun> getFragmentFromSelection)(editorState);
	const text = selected ? selected.map(x => x.getText()).join('\n') : '';
	return text;
};

/**
 * @returns the entity key in the current selection
 */
const getSelectionKey = (editorState: EditorState): string | null => {
  const selection = editorState.getSelection();
  const content = editorState.getCurrentContent();
  const startKey = selection.getStartKey();
  const startOffset = selection.getStartOffset();
  const block = content.getBlockForKey(startKey);
  const linkKey = block.getEntityAt(startOffset);
  return linkKey;
};

/**
 * @returns the metadata stored by the entity key
 */
const getEntityMetaData = <T=Record<string, unknown>>(editorState: EditorState, entityKey: string): T => {
  const contentState = editorState.getCurrentContent();
  const blockProps = contentState.getEntity(entityKey).getData();
  return blockProps;
};

const createLink = (editorState: EditorState, src: string, target: string, text: string): EditorState => {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(
    LINK_ENTITY_TYPE,
    'MUTABLE',
    { src, target, text }
  );
	const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const selection = editorState.getSelection();

  const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });

  const finalState = RichUtils.toggleLink(
    newEditorState,
    selection, // newEditorState.getSelection(),
    entityKey
  );

  return finalState;
};

/**
 * Toggles off a link in the current selection
 */
const clearLink = (editorState: EditorState): EditorState => {
  const selection = editorState.getSelection();
  if (!selection.isCollapsed()) {
    const newState = RichUtils.toggleLink(editorState, selection, null);
    return newState;
  }

  return editorState;
};

const linkStrategy = (contentBlock: ContentBlock, callback: (start: number, end: number) => void, contentState: ContentState): void => {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === LINK_ENTITY_TYPE
      );
    },
    callback
  );
};

export {
	createLink,
  getTextFromSelection,
  linkStrategy,
  getSelectionKey,
  getEntityMetaData,
  clearLink
};
