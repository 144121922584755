import { Space, Modal } from 'antd';
import classNames from 'classnames';
import React, { ReactElement } from 'react';
import CardWithBorder from '../cardWithBorder/cardWithBorder';
import StyledButton, { ButtonColors } from '../form-control/styledButton/styledButton';
import Spinner from '../spinner/spinner';
import styles from './roundedModal.module.css';

interface Props {
	onOk?: ()=> void;
	onCancel?: ()=> void;
	onAfterClosed?: ()=> void;
	title: string | ReactElement;
	children: React.ReactNode;
	visible: boolean;
	customFooter?: React.ReactNode;
	okBtnLabel?: string | React.ReactNode;
	cancelBtnLabel?: string | React.ReactNode;
	width?: number | string;
	closeOnMaskClick?: boolean;
	/** Modal inner card's class name */
	className?: string;
	/** class name of the card's body. */
	bodyClassName?: string;
	footerClassName?: string;
	transparentFooter?: boolean;
	okButtonColor?: ButtonColors;
	cancelButtonColor?: ButtonColors;
	disableOkButton?: boolean;
	noFooter?: boolean;
	centered?: boolean;
	closable?: boolean;
	noMask?: boolean;
	loading?: boolean;
}

const RoundedModal = (props: Props): React.JSX.Element => {
	return (
		<Modal
			maskClosable={props.closeOnMaskClick}
			width={props.width}
			closable={props.closable}
			visible={props.visible}
			bodyStyle={{ padding: 'unset' }}
			mask={!props.noMask}
			onOk={props.onOk}
			onCancel={props.onCancel}
			footer={null}
			centered={props.centered}
			destroyOnClose={true}
			className={styles.ModalContainer}
			afterClose={props.onAfterClosed}
		>
			<CardWithBorder
				title={props.title}
				borderWidth={3}
				className={classNames(styles.CardShadow, props.className)}
				bodyClassName={props.bodyClassName}
			>
				<Spinner loading={!!props.loading}>
					<div className={styles.ModalContainer}>
						{props.children}

						{!props.noFooter && (
							<div className={classNames(styles.Footer, props.footerClassName)}>
								{props.customFooter ? (
									props.customFooter
								) : (
									props.cancelBtnLabel ? (
										<Space size={20}>
											<StyledButton color={props.okButtonColor || 'light'} title={props.cancelBtnLabel} onClick={props.onCancel}/>
											<StyledButton disabled={!!props.disableOkButton} color={props.cancelButtonColor || 'primary'} title={props.okBtnLabel} onClick={props.onOk}/>
										</Space>
									) : (
										<StyledButton color={props.okButtonColor || 'light'} disabled={!!props.disableOkButton} title={props.okBtnLabel} onClick={props.onOk}/>
									)
								)}
							</div>
						)}
					</div>
				</Spinner>
			</CardWithBorder>
		</Modal>
	);
};

export default RoundedModal;
