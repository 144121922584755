import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataType, GroupWidgetPort } from '@kemu-io/kemu-core/dist/types/gate_t';
import { message, Collapse } from 'antd';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import WidgetGroupProcessor, { WidgetGroupState } from '@kemu-io/kemu-core/dist/gates/widgetGroup';
import { decodePortName } from '@kemu-io/kemu-core/dist/common/utils';
import {
	addCustomWidgetAction,
	editCustomWidgetAction,
	selectShowCreateWidgetModal,
	showCreateWidgetModalAction
} from '../logicMapperSlice';
import { WidgetGroupStateWithId } from '../reducers/customWidgetReducer';
import { selectCurrentRecipeType, selectedBlock, selectVisibleGroup } from '../../Workspace/workspaceSlice';
import PortsList from './portsList';
import styles from './customWidgetModal.module.css';
import IconSelector from './iconSelector/iconSelector';
import useTranslation from '@common/hooks/useTranslation';
import { getLastInputPortWithParent, getOutputPortsWithChildren, LastPortInfo } from '@common/utils';
import FormGroup from '@components/form-control/formGroup/formGroup';
import StyledTextArea from '@components/form-control/styledTextArea/styledTextArea';
import StyledInput from '@components/form-control/styledInput/styledInput';
import StandardModal from '@components/modal/standardModal';
import StyledLabel from '@components/form-control/styledLabel/styledLabel';
import ColorSwatch from '@components/colorSwatch/colorSwatch';

const { Panel } = Collapse;

const defaultInput: GroupWidgetPort = { index: 0, label: 'input1', name: 'input1', type: [DataType.Anything] };
const defaultOutput: GroupWidgetPort = { index: 0, label: 'output1', name: 'output1', type: [DataType.Anything] };
const defaultColor = '#4e3eff';

const getNextName = (prefix: string, names: string[]): string => {
	let loops = names.length;
	let name = `${prefix}${loops+1}`;
	while (names.includes(name)) {
		loops++;
		name = `${prefix}${loops}`;
	}

	return name;
};

const defaultColors = [
	'#42a5f5', '#ab47bd', '#626060',
	'#68bb6c', '#eac43e', '#ff7070',
	'#CE69FC', '#FF6822', '#FF9361',
	'#FFB834', '#02AF97', '#777898',
	'#7CE898', '#FF8CA7', '#0291AF',
	'#0094DF', '#84D2F9', '#00B9DF',
	'#A9B8C2', '#A15D1A', '#20282E'
];

const CustomWidgetModal = (): React.JSX.Element => {
	const showModal = useSelector(selectShowCreateWidgetModal);
	const existingWidgetInfo = (showModal as Partial<WidgetGroupStateWithId>) || null;
	const currentBlockInfo = useSelector(selectedBlock);
	const groupPath = useSelector(selectVisibleGroup);
	const [missingName, setMissingName] = useState(existingWidgetInfo?.name ? false : true);
	const dispatch = useDispatch();
	const t = useTranslation('Interface.Modals.CreateWidget');
	const [inputs, setInputs] = useState<GroupWidgetPort[]>(existingWidgetInfo?.inputs || [defaultInput]);
	const [outputs, setOutputs] = useState<GroupWidgetPort[]>(existingWidgetInfo?.outputs || [defaultOutput]);
	const [color, setColor] = useState<string | undefined>(existingWidgetInfo?.color);
	const [icon, setIcon] = useState<string | undefined>(existingWidgetInfo?.icon);
	const descriptionRef = useRef<TextAreaRef | null>(null);
	const nameRef = useRef<HTMLInputElement | null>(null);
	const isSaving = !!showModal;
	const currentWidgetId = existingWidgetInfo?.widgetId;
	const currentRecipeType = useSelector(selectCurrentRecipeType);

	const handleCancel = useCallback(() => {
		dispatch(showCreateWidgetModalAction(false));
	}, [dispatch]);


	const warnIfInputAttached = (port: LastPortInfo | null, minIndex: number): boolean => {
		// Prevent adding ports if trigger is linked (otherwise new ports will obtain the connection from trigger)
		if (port && port.index >= minIndex) {
			const isInner = port.type === 'innerInput' || port.type === 'innerOutput';
			message.warning({
				content: t('RemoveWarning', 'Please remove all connections from {inner}port "{name}"',
				{
					name: port.label || port.portName,
					inner: isInner ? 'inner ' : ''
				})
			});
			return true;
		}

		return false;
	};

	const handleRemovePort = (type: 'input' | 'output', index: number) => {
		if (existingWidgetInfo && currentBlockInfo && currentWidgetId) {
			const connectedInput = getLastInputPortWithParent<WidgetGroupState>(
				existingWidgetInfo as WidgetGroupState,
				WidgetGroupProcessor,
				currentWidgetId,
				currentBlockInfo.recipeId,
				currentBlockInfo.recipePoolId,
				currentRecipeType,
			);

			const connectedOutputs = getOutputPortsWithChildren<WidgetGroupState>(
				existingWidgetInfo as WidgetGroupState,
				WidgetGroupProcessor,
				currentWidgetId,
				currentBlockInfo.recipeId,
				currentBlockInfo.recipePoolId,
				currentRecipeType
			);

			let ignoreConnectedPort = false;
			let portInfo: LastPortInfo | null = null;

			if (type === 'input') {
				// check for connected parents to outer inputs
				if (connectedInput) {
					const decodedName = decodePortName(connectedInput.portName);
					ignoreConnectedPort = decodedName.type === 'innerOutput';
					if (!ignoreConnectedPort) { portInfo = connectedInput; }
				}

				// Check for connected children to inner outputs
				if (!ignoreConnectedPort && connectedOutputs.length) {
					const innerPorts = connectedOutputs.filter(port =>  decodePortName(port.portName).type !== 'output');
					if (innerPorts.length) {
						portInfo = innerPorts[0];
						ignoreConnectedPort = false;
					}
				}
			} else {
				// Check for connected children to outer ports 
				if (connectedOutputs.length) {
					const outerPorts = connectedOutputs.filter(port =>  decodePortName(port.portName).type !== 'innerInput');
					if (outerPorts.length) {
						portInfo = outerPorts[0];
						ignoreConnectedPort = false;
					}
				}

				// Check for connected parents to inner inputs
				if (!ignoreConnectedPort && connectedInput) {
					const decodedName = decodePortName(connectedInput.portName);
					ignoreConnectedPort = decodedName.type === 'input';
					if (!ignoreConnectedPort) { portInfo = connectedInput; }
				}
			}

			if (!ignoreConnectedPort) {
				if (warnIfInputAttached(portInfo, index)) { return ; }
			}
		}

		const actionFun = type === 'input' ? setInputs : setOutputs;
		const filteredOut = (type === 'input' ? inputs : outputs).filter((_, i) => i !== index);
		actionFun(filteredOut);
	};

	const handleAddPort = (type: 'input' | 'output') => {
		const actionFun = type === 'input' ? setInputs : setOutputs;
		actionFun(list => {
			const defaultName = getNextName(type, list.map(item => item.name));
			return [
				...list,
				{
					name: defaultName,
					label: defaultName,
					type: [DataType.Anything],
					index: list.length,
				}
			];
		});
	};

	const handlePortChange = useCallback((type: 'input' | 'output', index: number, portInfo: GroupWidgetPort) => {
		const funName = type === 'input' ? setInputs : setOutputs;
		funName(currentPorts => {
			return currentPorts.map((input, i) => {
				if (i === index) {
					return portInfo;
				} else {
					return input;
				}
			});
		});
	}, []);

	const handleCreate = useCallback(() => {
		if (nameRef.current && descriptionRef.current && descriptionRef.current.resizableTextArea && currentBlockInfo) {
			const name = nameRef.current.value;
			const description = descriptionRef.current.resizableTextArea.textArea.value;

			const baseInformation = {
				description,
				name,
				inputs,
				outputs,
				icon,
				color,
				recipeId: currentBlockInfo.recipePoolId,
				thingId: currentBlockInfo.recipeId,
				...(groupPath ? { parentGroupId: groupPath.groupId } : undefined)
			};

			if (isSaving && currentWidgetId) {
				dispatch(editCustomWidgetAction({
					...baseInformation,
					widgetId: currentWidgetId,
				}));
			} else {
				dispatch(addCustomWidgetAction({
					...baseInformation,
					...(groupPath ? { parentGroupId: groupPath.groupId } : undefined)
				}));
			}

			// Hide the modal
			dispatch(showCreateWidgetModalAction(false));
		}
	}, [
		dispatch, color, icon, currentBlockInfo,
		groupPath, inputs, outputs, isSaving,
		currentWidgetId,
	]);

	const handleNameBlur = () => {
		if (nameRef.current?.value.trim() === '') {
			setMissingName(true);
		} else {
			setMissingName(false);
		}
	};

	const handleColorChange = useCallback((color: string) => {
		setColor(color);
	}, []);

	const handleIconChange = useCallback((icon: string) => {
		setIcon(icon);
	}, []);

	// Clear list upon exit
	useEffect(() => {
		const modalInfo = (showModal as Partial<WidgetGroupState>);
		if (modalInfo) {
			setInputs(modalInfo.inputs || [defaultInput]);
			setOutputs(modalInfo.outputs || [defaultOutput]);
			setMissingName(modalInfo.name ? false : true);
			setIcon(modalInfo.icon);
			setColor(modalInfo.color || defaultColor);
		}
	}, [showModal]);



	return (
		<StandardModal
			hideCloseIcon
			bodyClassName={styles.ModalBody}
			width={500}
			visible={!!showModal}
			title={t('Title', 'Create Custom Widget')}
			cancelBtnLabel={t('CancelBtn', 'Cancel')}
			disableOkButton={missingName}
			okBtnLabel={
				isSaving ? t('SaveBtn', 'Save') : t('CreateBtn', 'Create')
			}
			onCancel={handleCancel}
			onOk={handleCreate}
			closeOnMaskClick={false}
			dataKemuMeta="custom-widget-modal"
		>
			<Collapse defaultActiveKey={['1']} ghost className={styles.Accordion}>
				<Panel header="Widget Details" key="1" >

					<FormGroup marginBottomLevel={3}>
						<StyledInput
							onBlur={handleNameBlur}
							ref={nameRef}
							defaultValue={(showModal as Partial<WidgetGroupState>)?.name || ''}
							label={t('Name.Label', 'Name')}
							placeholder={t('Name.Placeholder', 'Enter name of the widget')}
						/>
					</FormGroup>

					<StyledTextArea
						ref={descriptionRef}
						preventResize
						defaultValue={(showModal as Partial<WidgetGroupState>)?.description || ''}
						label={t('Description.Label', 'Description')}
						showCount
						maxLength={200}
					/>

					<div className={styles.ColorAndIconRow}>
						<div className={styles.ColorContainer}>
							<StyledLabel text={'Color'} />
							<ColorSwatch
								className={styles.Swatch}
								width={'350px'}
								popupClassName={styles.SwatchPopup}
								color={color}
								colors={defaultColors}
								onColorChange={handleColorChange}
							/>
						</div>

						<div className={styles.IconContainer}>
							<StyledLabel text={'Icon'} />
							<IconSelector
								backgroundColor={color}
								onIconSelected={handleIconChange}
								icon={icon}
							/>
						</div>
					</div>
				</Panel>


				<Panel header={t('InputPorts.Title', 'Input Ports')} key="2">
					<PortsList
						data-kemu-meta="input-ports"
						ports={inputs}
						onPortUpdated={handlePortChange.bind(null, 'input')}
						onAddPort={() => handleAddPort('input')}
						onRemovePort={(index) => handleRemovePort('input', index)}
					/>
				</Panel>

				<Panel header={t('OutputPorts.Title', 'Output Ports')} key="3">
					<PortsList
						data-kemu-meta="output-ports"
						ports={outputs}
						onPortUpdated={handlePortChange.bind(null, 'output')}
						onAddPort={() => handleAddPort('output')}
						onRemovePort={(index) => handleRemovePort('output', index)}
					/>
				</Panel>
			</Collapse>
		</StandardModal>
	);
};

export default CustomWidgetModal;
