import React, { memo, forwardRef } from 'react';
import { Button } from 'antd';
import styles from './styledButton.module.css';

export type ButtonColors = 'primary' | 'secondary' | 'light' | 'danger' | 'warning';
interface Props {
	onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	color?: ButtonColors;
	type?:   'submit' | 'button' | 'reset';
	title?: string | React.ReactNode;
	className?: string;
	disabled?: boolean;
	loading?: boolean;
	icon?: React.ReactNode;
	'data-kemu-meta'?: string;
}

export const StyledButtonClassName = styles.Button;

const ButtonComponent = (props: Props, ref: React.Ref<HTMLButtonElement>): React.JSX.Element => {
	return (
		<Button
			ref={ref}
			icon={props.icon}
			htmlType={props.type}
			onClick={props.onClick}
			disabled={props.disabled}
			loading={props.loading || false}
			btn-type={props.color || 'primary'}
			className={`${props.loading ? 'loading' : ''} ${styles.Button} ${props.className || ''}`}
			{...props['data-kemu-meta'] ? { ['data-kemu-meta']: props['data-kemu-meta'] } : undefined}
		>
			{ props.title }
		</Button>
	);
};

const StyledButton = memo(forwardRef<HTMLButtonElement, Props>(ButtonComponent));
export default StyledButton;
