import React, { useCallback, JSX } from 'react';
import { Pagination, Autoplay } from 'swiper';
/* eslint-disable import/no-unresolved */
// IMPORTANT: unresolved rule ignored until we upgrade to swiper 10.
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/scss/pagination'; // Pagination module
/* eslint-enable import/no-unresolved */

import styles from './GuidedTutorials.module.css';
import TutorialSlide from './TutorialSlide/TutorialSlide';
import helpHero from '@common/helpHero/helpHero';
import useTranslation from '@hooks/useTranslation';

interface Props {
	onClose: () => void;
}

const tutorials = [
	'ThumbsUpDetector',
	'SoundPlayerRecordings'
];

const GuidedTutorials = (props: Props): React.JSX.Element => {
	const t = useTranslation('GuidedTutorials');
	const { onClose } = props;

	const handleOpenProject = useCallback((id: string): void => {
		// Tutorials Rules:
		// 1. All steps should have a nex/prev button. So that users can open a tutorial within an existing recipe.
		// 2. Tours objects (DB entities) should have a list of step names, so that they can be shown to the user.
		// 3. Users should be allowed to jump straight to a specific step.

		// 1) Check if workspace is in use, if so, ask tell users they are about to lose their work and clear it.
		// 2) Redirect to root domain (workspace)
		// 3) [Load a default recipe used by the tutorial ?]
		// 4) Initiate tour.
		const instance = helpHero.getInstance();
		if (instance) {
			// TODO: Disable Asuran Hotspots while a tour is running.
			instance.startTour(id);
			onClose();
		}
	}, [onClose]);

	return (
		<div className={styles.Wrapper}>
			<Swiper
				autoplay={{ delay: 4500 }}
				pagination={{ clickable: true }}
				modules={[Autoplay, Pagination]}
				className={styles.SwiperContainer}
			>
				{tutorials.map((name: string) => (
					<SwiperSlide key={name}>
						<TutorialSlide
							id={t(`${name}.TourId`, '???')}
							title={t(`${name}.Title`, `Missing-${name}.Title`)}
							durationTxt={t(`${name}.Duration`, `Missing-${name}.Duration`)}
							description={t(`${name}.Description`, `Missing-${name}.Description`)}
							videoOrImage={t(`${name}.VideoOrImage`, `Missing-${name}.VideoOrImage`)}
							direction="right"
							onLaunch={handleOpenProject}
						/>
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
};

export default GuidedTutorials;
