import { useCallback, useEffect, useRef, useState } from 'react';
import { BlockAction } from '@kemu-io/kemu-core/dist/types/block_t';
import { getBlockActions } from '../../app/recipe/utils';
import { AsyncRequestStatus, AsyncState } from '../../types/core_t';


type BlockActionsResponse = [ReadonlyArray<BlockAction>, AsyncState]

/**
 * Keeps track of the available actions for a block
 * @param recipeId the id of the recipe in the pool
 * @param blockId th id of the block in the recipe (recipeId)
 */
function useBlockActions (recipeId: string | null, blockId: string | null): BlockActionsResponse {
	const [blockActions, setBlockActions] = useState<readonly BlockAction[]>([]);
	const loadingRef = useRef<AsyncState>({ status: AsyncRequestStatus.idle });

	// TODO: getBlockActions is no longer async, 
	// but some Things are still using the async form,
	// Keeping this async until all Thing's processors are updated.
	const getActions = useCallback(async () => {
		try {
			loadingRef.current.status = AsyncRequestStatus.loading;
			const actions = await getBlockActions(recipeId!, blockId!);
			loadingRef.current.status = AsyncRequestStatus.completed;
			setBlockActions(actions);
		} catch (e) {
			console.log('Failed to load actions: ', );
			loadingRef.current.status = AsyncRequestStatus.error;
			loadingRef.current.error = e;
		}

	}, [recipeId, blockId]);

	useEffect(() => {
		if (recipeId && blockId) { getActions(); }
	}, [recipeId, blockId, getActions]);

	return [blockActions, loadingRef.current];
}

export default useBlockActions;
