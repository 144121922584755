import classNames from 'classnames';
import React from 'react';
import styles from './styledLabel.module.css';

interface Props {
	text: string | React.ReactNode;
	className?: string;
}

const StyledLabel = (props: Props): React.JSX.Element => {
	return (
		<label className={classNames(styles.Label, props.className)}>
			{ props.text }
		</label>
	);
};

export default StyledLabel;
