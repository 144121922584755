import React, { useContext, useEffect } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PublicationCard from '../PublicationCard/PublicationCard';
import AlgoliaResultsGrid from '../AlgoliaResultsGrid/AlgoliaResultsGrid';
import { SearchContext } from '../../../common/context/GlobalSearchContext/GlobalSearchContext';

const SearchContextName = 'PublicThings';

const PublicThings = (): React.JSX.Element | null => {
  const { results, setSearchContext, getSearchContext } = useContext(SearchContext);
  const validContext = getSearchContext() === SearchContextName;

  // Set the context the first time
  useEffect(() => {
    if (!validContext) {
      setSearchContext(SearchContextName, 'publication', {
        hitsPerPage: 10,
        filters: 'type:thing'
      });
    }

  }, [validContext, setSearchContext]);

  // Prevent rendering results from other searches
  if (!validContext) { return null; }

  return (
    <AlgoliaResultsGrid
      hits={results?.hits || []}
      hitRenderer={PublicationCard}
    />
  );
};

export default PublicThings;
