import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Input } from 'antd';
import useTranslation from '@common/hooks/useTranslation';
import AlgoliaSearchInput from '@components/AlgoliaSearchInput/AlogliaSearchInput';
import { SearchContext } from '@common/context/GlobalSearchContext/GlobalSearchContext';

type Props = {
  onFocus?: () => void;
  onBlur?: () => void;
  placeholder?: string;
}

const AlgoliaSearchBox = (props: Props): React.JSX.Element => {
  const t = useTranslation('Marketplace');
  const { search, results, getSearchContext, refreshCache } = useContext(SearchContext);
  const [initialized, setInitialized] = useState(false);
  const searchTmr = useRef<number | null>(null);
  const inputRef = useRef<Input>(null);
  const currentContext = getSearchContext();

  const searchNow = useCallback((firstSearch?: boolean) => {
    const query = inputRef.current?.input.value;

    if (firstSearch) {
      search('');
    } else if (query !== undefined) {
      search(query);
    }
  }, [search]);

  // Wait for a bit before searching
  const programSearch = useCallback(() => {
    if (searchTmr.current) {
      clearTimeout(searchTmr.current);
    }

    searchTmr.current = setTimeout(searchNow, 250);
  }, [searchNow]);

  // Pressing ENTER forces a new search
  const handleKeyPressed = useCallback((evt: React.KeyboardEvent<HTMLInputElement>) => {
		if (evt.key === 'Enter') {
			evt.preventDefault();
			refreshCache();
		}
	}, [refreshCache]);


  const suffix = `${results?.stats.totalHits} ${results?.stats.totalHits === 1 ? t('Search.ResultSingular', 'result') : t('Search.ResultPlural', 'results')}`;

  // Cancel timer on unmount
  useEffect(() => {
    const lastTmr = searchTmr.current;

    // Don't show default results unless a context has been set
    if (currentContext && initialized) {
      searchNow(true);
    } else if (!initialized) {
      setInitialized(true);
    }

    return () => {
      if (lastTmr) {
        clearTimeout(lastTmr);
      }
    };
  }, [searchNow, currentContext, initialized]);

  return (
		<AlgoliaSearchInput
      onChange={programSearch}
      onKeyDown={handleKeyPressed}
      ref={inputRef}
			placeholder={props.placeholder}
      suffix={
        results?.stats ? suffix : undefined
      }
		/>
  );
};

export default AlgoliaSearchBox;
