
import React from 'react';
import { message } from 'antd';
import Icon from '@ant-design/icons';
import ImageCropWidgetProcessor, {
	ImageCropWidgetState,
	getDefaultState,
} from '@kemu-io/kemu-core/dist/gates/imageCrop';

import { CustomWidgetState, WidgetPortContext } from '@kemu-io/kemu-core/dist/types/gate_t';
import classNames from 'classnames';
import useTranslation from '../../../common/hooks/useTranslation';
import NumericInput from '../../WidgetsComponents/NumericInput/NumericInput';
import KemuSwitch from '../../form-control/kemuSwitch/kemuSwitch';
import { PortLocation } from '../../../types/canvas_t';
import GateIcon from '../../gateIcon/gateIcon';
import {
	GateCustomSettingsProps,
	GetPortsInformationFunction,
	GateUI,
	PortDescription,
	GateUIProps,
} from '..';
import useReactiveWidgetState from '../../../common/hooks/useReactiveWidgetState';
import { getLastInputPortWithParent, LastPortInfo } from '../../../common/utils';
import { SETTINGS_CONTAINER_CLASS } from '../../../common/constants';
import styles from './imageCrop.module.css';
import { ReactComponent as ImageCropIcon } from './icon.svg';

const WIDGET_WRAPPER_CLASS = `image-crop-widget-wrapper`;

const ImageCropWidget = (props: GateUIProps): React.JSX.Element => {
	const [state] = useReactiveWidgetState<ImageCropWidgetState>(props.recipeId, props.thingRecipeId, props.info.id);

	return (
		<div className={classNames(styles.GateBody, {
			[styles.LargeGateBody]: state.showInputPorts,
			[styles.DisabledWidget]: props.info.disabled
		})}>
			<Icon component={ImageCropIcon}/>
		</div>
	);
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={ImageCropIcon} />}/>
	);
};

const GateCustomSettings = (props: GateCustomSettingsProps): React.JSX.Element => {
	const [state, setState] = useReactiveWidgetState<ImageCropWidgetState>(props.recipeId, props.blockId, props.gateInfo.id);
	const fixedState = {
		...getDefaultState(),
		...state
	};

	const t = useTranslation('LogicMaker.Gates.ImageCrop.Settings');

	const warnIfInputAttached = (info: LastPortInfo | null): boolean => {
		if (info && info?.portName !== 'image') {
			message.warning({
				content: t('RemoveWarning', 'Please remove all connections from port "{name}"',
				{ name: info.portName })
			});
			return true;
		}

		return false;
	};

	const getLastConnectedInput = () => {
		return getLastInputPortWithParent<ImageCropWidgetState>(
			fixedState,
			ImageCropWidgetProcessor,
			props.gateInfo.id,
			props.blockId,
			props.recipeId,
			props.recipeType
		);
	};

	const updateResizeValue = (prop: keyof ImageCropWidgetState, value: number) => {
		setState({
			...fixedState,
			[prop]: value
		});
	};

	const handleLChange = (value: number) => updateResizeValue('cropLeft', value);
	const handleRChange = (value: number) => updateResizeValue('cropRight', value);
	const handleTChange = (value: number) => updateResizeValue('cropTop', value);
	const handleBChange = (value: number) => updateResizeValue('cropBottom', value);


	const handleSwitchChange = (checked: boolean) => {
		const lastPortWithConnection = getLastConnectedInput();
		// Prevent removing ports if connections are attached
		if (warnIfInputAttached(lastPortWithConnection)) { return ; }

		setState({ ...fixedState, showInputPorts: checked });
	};

	return (
		<div className={classNames(styles.SettingsContainer, SETTINGS_CONTAINER_CLASS)}>
			{props.children}

			<div className={styles.SwitchContainer}>
				<label>{t('Label', 'Show input ports')}</label>
				<KemuSwitch
					size="small"
					checked={fixedState.showInputPorts}
					onChange={handleSwitchChange}
				/>
			</div>

			<div className={styles.InputContainer}>

				<div className={styles.SingleRow}>
					<div className={styles.InputColumn}>
						<label>{t('PlaceHolderLeft', 'left')}</label>
						<NumericInput
							min={0}
							wrapperClass={styles.InputWrapper}
							placeholder={t('PlaceHolderLeft', 'left')}
							value={fixedState.cropLeft}
							onChange={handleLChange}
						/>
					</div>

					<div className={styles.InputColumn}>
						<label>{t('PlaceHolderRight', 'right')}</label>
						<NumericInput
							min={0}
							wrapperClass={styles.InputWrapper}
							placeholder={t('PlaceHolderRight', 'right')}
							value={fixedState.cropRight}
							onChange={handleRChange}
						/>
					</div>

				</div>

				<div className={styles.SingleRow}>
					<div className={styles.InputColumn}>
						<label>{t('PlaceHolderTop', 'top')}</label>
						<NumericInput
							min={0}
							wrapperClass={styles.InputWrapper}
							placeholder={t('PlaceHolderTop', 'top')}
							value={fixedState.cropTop}
							onChange={handleTChange}
						/>
					</div>

					<div className={styles.InputColumn}>
						<label>{t('PlaceHolderBottom', 'bottom')}</label>
						<NumericInput
							min={0}
							wrapperClass={styles.InputWrapper}
							placeholder={t('PlaceHolderBottom', 'bottom')}
							value={fixedState.cropBottom}
							onChange={handleBChange}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const getPortsInformation: GetPortsInformationFunction = (state: CustomWidgetState<ImageCropWidgetState>, widgetInfo) => {
	const portContext: WidgetPortContext = { recipePoolId: widgetInfo.recipePoolId, recipeType: widgetInfo.recipeType };
	const outputNames = ImageCropWidgetProcessor.getOutputNames(state, portContext);
	const inputNames = ImageCropWidgetProcessor.getInputNames(state, portContext);

	const inputPositions: Record<string, PortLocation> = {
		'image': [0, 0.15, -1, 0],
		'left': [0, 0.32, -1, 0],
		'right': [0, 0.50, -1, 0],
		'top': [0, 0.67, -1, 0],
		'bottom': [0, 0.85, -1, 0],
	};

	let inputs: PortDescription[] = [];
	if (inputNames.length === 1) {
		inputs.push({
			...inputNames[0],
			position: 'Left'
		} as PortDescription);
	} else {
		inputs = inputNames.map(input => {
			return {
				...input,
				position: inputPositions[input.name],
			};
		});
	}

	return {
		outputs: [{
			position: 'Right',
			name: outputNames[0].name,
			type: outputNames[0].type,
		}],

		inputs,
	};
};

export default {
	getPortsInformation,
	BarIcon: GateBarIcon,
	Element: ImageCropWidget,
	CustomSettingsDialog: GateCustomSettings,
	getWrapperClass: () => WIDGET_WRAPPER_CLASS,
	hasTitle: true,
	getGatesBarTitle: (intl) => intl.formatMessage({ id: 'LogicMaker.Gates.ImageCrop.Title', defaultMessage: 'Crop Image' }),
	getWidgetTitle: (intl) => intl.formatMessage({ id: 'LogicMaker.Gates.ImageCrop.WidgetHeader', defaultMessage: 'Crop' }),
} as GateUI;
