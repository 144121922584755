
import React, { useEffect } from 'react';
import { Menu } from 'antd';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';
import Icon from '@ant-design/icons';
import { ActionWidgetState, CustomWidgetState, SelectedActionType } from '@kemu-io/kemu-core/dist/types/gate_t';
import ActionWidgetProcessor from '@kemu-io/kemu-core/dist/gates/action';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { GateUI, GetPortsInformationFunction , GateUIProps } from '..';
import GateIcon from '../../gateIcon/gateIcon';
import useReactiveWidgetState from '../../../common/hooks/useReactiveWidgetState';
import useBlockActions from '../../../common/hooks/useBlockActions';
import { AsyncRequestStatus } from '../../../types/core_t';
import { createChildPortIdentifier, getChildBlocksDynamicInputs, getChildrenWidgets } from '../../../app/recipe/utils';
import useChildBlocksActions from '../../../common/hooks/useChildBlocksActions';
import { PortLocation } from '../../../types/canvas_t';
import { disconnectGate } from '../../../features/LogicMapper/logicMapperSlice';
import WidgetDropdownButton from '../../WidgetDropdownButton/WidgetDropdownButton';
import styles from './action.module.css';
import { ReactComponent as ActionWidgetIcon } from './icon.svg';

const KEY_SEPARATOR = '::';
type Props = GateUIProps

/**
 * Info is encoded in the menu key as childBlockId::ActionName
 * @param str 
 */
const decodeMenuKey = (str: string) => {
	const parts = str.split(KEY_SEPARATOR);
	const actionType: SelectedActionType = parts[4] === 'input' ? SelectedActionType.CustomInput : SelectedActionType.Action;
	return {
		thingRecipeId: parts[0],
		thingDbId: parts[1],
		thingVersion: parts[2],
		action: parts[3],
		actionType,
	};
};

const encodeMenuKey = (
	thingRecipeId: string,
	thingDbId: string,
	thingVersion: string,
	action: string,
	isCustomInput: boolean,
	index?: number
): string => {
	const KS = KEY_SEPARATOR;
	return `${thingRecipeId}${KS}${thingDbId}${KS}${thingVersion}${KS}${action}${KS}${isCustomInput?'input':'action'}${KS}${index !== undefined ? `${KEY_SEPARATOR}${index}` : ''}`;
};

const ActionGate = (props: Props): React.JSX.Element | null => {
	const [gateState, setGateState] = useReactiveWidgetState<ActionWidgetState>(props.recipeId, props.thingRecipeId, props.info.id);
	const dispatch = useDispatch();
	const { repaint } = props;
	const [actions, loadingState] = useBlockActions(props.recipeId, props.thingRecipeId);
	const [childrenActions, loadingChildrenState] = useChildBlocksActions(props.recipeId, props.thingRecipeId);
	const childrenDynamicInputs = getChildBlocksDynamicInputs(props.recipeId, props.thingRecipeId);
	const ownActionsReady = loadingState.status === AsyncRequestStatus.completed;
	const childrenActionsReady = loadingChildrenState.status === AsyncRequestStatus.completed;

	const handleMenuClick: MenuClickEventHandler = (info) => {
		const item = decodeMenuKey(String(info.key));
		// Force removing any children
		const successPort = createChildPortIdentifier('output', 0);
		const errorPort = createChildPortIdentifier('output', 1);
		const successChildren = getChildrenWidgets(props.recipeId, props.thingRecipeId, props.info.id, successPort );
		const errorChildren = getChildrenWidgets(props.recipeId, props.thingRecipeId, props.info.id, errorPort );
		const anyChildren = successChildren.concat(errorChildren);

		const newGateState: ActionWidgetState = {
			...gateState,
			selectedAction: item.action,
			targetCurrentThing: false,
		};

		if (item.thingRecipeId === props.thingRecipeId) {
			newGateState.targetCurrentThing = true;
		} else {
			newGateState.targetThing = {
				thingRecipeId: item.thingRecipeId,
				thingDBId: item.thingDbId,
				thingVersion: item.thingVersion,
				actionType: item.actionType
			};
		}


		// Find out if the newly selected action is async (has output ports)
		const nextOutputs = ActionWidgetProcessor.getOutputNames(newGateState, {
			id: props.info.id,
			thingRecipeId: props.thingRecipeId,
			thingDbId: item.thingDbId,
			thingVersion: item.thingVersion,
			recipeId: props.recipeId,
			recipePoolId: props.recipeId,
			recipeType: props.recipeType,
		});

		// Remove any connected child
		if (!nextOutputs.length) {
			console.log('Removing connections since new action has no outputs');
			anyChildren.forEach(child => {
				dispatch(disconnectGate({
					blockRecipeId: props.thingRecipeId,
					recipeId: props.recipeId,
					sourceGateId: props.info.id,
					sourcePortId: child.sourcePort,
					targetPortId: child.targetPort,
					targetGateId: child.childId
				}));
			});
		}


		setGateState(newGateState);
	};

	// Repaints ports when a new action is selected
	useEffect(() => {
		console.log('Repainting points!');
		repaint();
	}, [gateState, repaint]);



	const menu = (
		<Menu onClick={handleMenuClick}>
			{/* Current block's actions */}
			<Menu.ItemGroup title="My actions" key={`ownActions`}>
				{ownActionsReady && actions.map((action) => {
					return (
						// <Menu.Item key={`${props.thingRecipeId}${KEY_SEPARATOR}${action.name}`}>{action.name}</Menu.Item>
						<Menu.Item key={encodeMenuKey(props.thingRecipeId, props.thingDbId, props.thingVersion, action.name, false)}>{action.name}</Menu.Item>
					);
				})}

			</Menu.ItemGroup>

			{/* Children block actions */}
			{childrenActionsReady && Object.keys(childrenActions).map((childId) => {
				const child = childrenActions[childId];
				// FIXME: child.canvasLabel not being populated!
				return (
					<Menu.ItemGroup title={child.name} key={`action_${childId}`}>
						{child.actions.map((action, index) => {
							return (
								// <Menu.Item key={`${childId}${KEY_SEPARATOR}${action.name}${KEY_SEPARATOR}${index}`}>{action.name}</Menu.Item>
								<Menu.Item key={encodeMenuKey(childId, child.blockDbId, child.version, action.name, false, index)}>{action.name}</Menu.Item>
							);
						})}
					</Menu.ItemGroup>
				);
			})}

			{/* Children blocks custom inputs */}
			{Object.keys(childrenDynamicInputs).map((childId) => {
				const child = childrenDynamicInputs[childId];
				return (
					<Menu.ItemGroup title={child.canvasLabel} key={`input_${childId}`}>
						{child.customInputs.map((input, index) => {
							return (
								// <Menu.Item key={`${childId}${KEY_SEPARATOR}${input.name}${KEY_SEPARATOR}${index}`}>{input.name}</Menu.Item>
								<Menu.Item key={encodeMenuKey(childId, child.blockDbId, child.version, input.name, true, index)}>{input.name}</Menu.Item>
							);
						})}
					</Menu.ItemGroup>
				);
			})}
		</Menu>
	);

	return (
		<div className={`action-gate lm-gate-horizontal ${styles.DropDownContainer}`}>
			<WidgetDropdownButton
				menu={menu}
				name={gateState.selectedAction || 'Select'}
				widgetType="consumer"
			/>

			<div className={classNames('gate-icon', styles.CanvasIconContainer)}>
				<Icon className="icon" component={ActionWidgetIcon}/>
			</div>
		</div>
	);
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={ActionWidgetIcon} />}/>
	);
};


const getPortsInformation: GetPortsInformationFunction = (state: CustomWidgetState<ActionWidgetState>, gateInfo) => {
	const inputNames = ActionWidgetProcessor.getInputNames(state, gateInfo);
	const outputNames = ActionWidgetProcessor.getOutputNames(state, gateInfo);

	const positions: Record<string, PortLocation> = {
		'success': [1, 0.33, 1, 0],
		'error': [1, 0.66, 1, 0]
	};

	return {
		inputs: [{
			position: 'Left',
			name: inputNames[0].name,
			type: inputNames[0].type,
		}],

		outputs: outputNames.map(output => ({
			name: output.name,
			type: output.type,
			position: positions[output.name]
		})),
	};
};

export default {
	getPortsInformation,
	BarIcon: GateBarIcon,
	Element: ActionGate,
	hasTitle: false,
	getWidgetTitle: (intl) => intl.formatMessage({ defaultMessage: 'Action', id: 'LogicMaker.Gates.Action.Title' }),
} as GateUI;
