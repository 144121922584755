import 'antd/dist/antd.min.css';
import '../core.css';

import { Route } from 'react-router-dom';
import React, { useCallback, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Layout } from 'antd';
import { useSelector } from 'react-redux';
import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex';
import classNames from 'classnames';
import MainMenu from '../../mainMenu/mainMenu';
import WorkspaceSection from './WorkspaceSection';
import LogicMapperSection from './LogicMapperSection';
import styles from './HorizontalSplit.module.css';
import { getStoredConfig, setStoredConfig } from '@common/utils';
import { currentRecipePoolId, selectedBlock } from '@src/features/Workspace/workspaceSlice';
import {
  CreateEternalInstanceFunction,
  DestroyEternalInstanceFunction,
  GetCanvasInstanceFunction
} from '@common/hooks/useEternalCanvasInstance';
import { UICanvasInstance } from '@src/types/canvas_t';
import { selectUserSettings } from '@src/app/reducers/user/userSlice';
import TutorialBuilder from '@src/features/TutorialBuilder/TutorialBuilder';
import { useQueryString } from '@common/hooks';
import 'react-reflex/styles.css';

interface MenuState {
	collapsed: boolean;
	selectedMenu: string;
}

const CONFIG_NAME = 'mainMenu';
const defaultMenuConfig: MenuState = {
	collapsed: true,
	selectedMenu: 'home'
};


type Props = {
  getCanvasInstance: GetCanvasInstanceFunction,

	createCanvasInstance: CreateEternalInstanceFunction,
	/** 
	 * Removes an instance from memory
	 * @param instance is the returned value from `createCanvasInstance`
	*/
	destroyCanvasInstance: DestroyEternalInstanceFunction,
}

const HorizontalSplit = (props: Props): React.JSX.Element => {
  const [collapsed, setCollapsed] = useState(getStoredConfig<MenuState>(CONFIG_NAME, defaultMenuConfig).collapsed);
  const recipePoolId = useSelector(currentRecipePoolId);
  const openedBlock = useSelector(selectedBlock);
  const userSettings = useSelector(selectUserSettings);
  const queryString = useQueryString();
  const tutorialId = queryString.get('tutorial');
  const tutorialStep = tutorialId && queryString.get('step');

  const lmDocking = userSettings?.logicMapperDocking || 'full';

  const {
    getCanvasInstance,
    createCanvasInstance,
    destroyCanvasInstance,
  } = props;

	const logicMapperVisible = openedBlock !== null;

  const toggle = useCallback(() => {
		setCollapsed(isCollapsed => {
			const nextState = !isCollapsed;
			setStoredConfig<MenuState>(CONFIG_NAME, { collapsed: nextState });
			return nextState;
		});
	}, []);

  const getBlockCanvasInstance = useCallback(() => {
		if (openedBlock) {
			const instance = getCanvasInstance(openedBlock.recipePoolId, openedBlock.recipeId,openedBlock.DbId);
			return instance as UICanvasInstance;
		}

		return null;
	}, [getCanvasInstance, openedBlock]);

  const onLogicMakerMounted = useCallback(() => {
		if (openedBlock) {
			const blockUI = getBlockCanvasInstance();
			// Notify canvas instances that the Logic Mapper is in view
			if (blockUI && blockUI.exports.IS_ETERNAL && blockUI.exports.onProcessorVisible) {
				blockUI.exports.onProcessorVisible && blockUI.exports.onProcessorVisible();
			}
		}
	}, [openedBlock, getBlockCanvasInstance]);


  const WorkspacePanel = (
    <WorkspaceSection
      createCanvasInstance={createCanvasInstance}
      destroyCanvasInstance={destroyCanvasInstance}
      collapsed={collapsed}
      onToggle={toggle}
      openedBlock={openedBlock}
      recipePoolId={recipePoolId}
    />
  );

  const LogicMapperPanel = (
    <LogicMapperSection
      openedBlock={openedBlock}
      recipePoolId={recipePoolId}
      onLogicMapperMounted={onLogicMakerMounted}
      visible={logicMapperVisible}
    />
  );

  const getSectionBySide = (type: 'left' | 'right') => {
    if (type === 'left') {
      if (!logicMapperVisible) { return WorkspacePanel; }
      if (lmDocking === 'left') {
        return LogicMapperPanel;
      } else if (lmDocking === 'right') {
        return WorkspacePanel;
      } else {
        // Full screen
        return (
          <>
            <MainMenu collapsed={collapsed} />
            { LogicMapperPanel };
          </>
        );
      }
    }

    if (type === 'right') {
      if (!logicMapperVisible) { return null; }
      if (lmDocking === 'left') {
        return WorkspacePanel;
      } else if (lmDocking === 'right') {
        return LogicMapperPanel;
      } else {
        // Full screen
        return null;
      }
    }
  };


  return (
    <DndProvider backend={HTML5Backend}>
      <div id="layout-container" className="light-theme">
        { tutorialId && tutorialStep && (
          <TutorialBuilder defaultStep={Number(tutorialStep) || 0} tutorialId={tutorialId} />
        )}
        <Layout className="layout-section">
            <ReflexContainer orientation="vertical">
              {/* Left */}
              <ReflexElement
                className={classNames(styles.SplitScreen, {
                  [styles.FullScreenSection]: !logicMapperVisible || lmDocking === 'full',
                })}
                minSize={lmDocking === 'right' ? 300 : 420}
              >
                {getSectionBySide('left')}
              </ReflexElement>

              <ReflexSplitter
                className={classNames(styles.SplitLine, {
                  [styles.Hidden]: !logicMapperVisible || lmDocking === 'full',
                })}
              />

              {/* Right */}
              <ReflexElement
                className={classNames(styles.SplitScreen, {
                  [styles.HiddenSection]: !logicMapperVisible || lmDocking === 'full',
                })}
                minSize={lmDocking === 'right' ? 420 : 300}
              >
                {getSectionBySide('right')}
              </ReflexElement>

            </ReflexContainer>
        </Layout>
      </div>
    </DndProvider>
  );
};

export default HorizontalSplit;
