import React, { memo } from 'react';
import useTranslation from '../../../common/hooks/useTranslation';
import { removeFunctionProps } from '../../../common/utils';
import StepCard from './stepCard/stepCard';

interface Props {
	readOnly: boolean;
	steps: {
		title: string;
		id: string;
	}[];
	selectedStep: number;
	onSelectStep: (index: number) => void;
	onDeleteStep: (index: number) => void;
	onAddStep: ()=> void;
	onChangeOrder: (index: number, newIndex: number) => void;
}

const StepsBar = (props: Props): React.JSX.Element => {
	const { steps, onSelectStep, onDeleteStep } = props;
	const t = useTranslation('TutorialBuilder.Main.NoStep');

	return (
		<>
			{steps.map((step, index) => {
				return (
					<StepCard
						readOnly={props.readOnly}
						onChangeOrder={props.onChangeOrder}
						key={step.id}
						id={step.id}
						index={index}
						showDeleteBtn={index === props.selectedStep}
						onDelete={() => onDeleteStep(index)}
						label={step.title}
						selected={index === props.selectedStep}
						onClick={() => onSelectStep(index)}
					/>
				);
			})}

			{!props.readOnly && (
				<StepCard
					readOnly={props.readOnly}
					index = {steps.length}
					showDeleteBtn={false}
					label={t('AddStepButton', 'Add Step')}
					onClick={props.onAddStep}
					isAddBtn={true}
				/>
			)}
		</>
	);
};



const areEqual = (prevProps: Props, newProps: Props): boolean => {
	// Remove any other property that might have been passed
	const cleanSteps = (steps: {title: string}[]) => steps.map(step => ({ title: step.title }));

	const prev = removeFunctionProps({ ...prevProps, steps: cleanSteps(prevProps.steps) });
	const now = removeFunctionProps({ ...newProps, steps: cleanSteps(newProps.steps) });
	return JSON.stringify(prev) === JSON.stringify(now);
};

export default memo(StepsBar, areEqual);
