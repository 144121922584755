import React from 'react';
import Icon from '@ant-design/icons';
import RandomBetweenGateProcessor, { RandomBetweenWidgetState, getDefaultState } from '@kemu-io/kemu-core/dist/gates/randomBetween';
import { WidgetPortContext } from '@kemu-io/kemu-core/dist/types/gate_t';
import useReactiveWidgetState from '../../../common/hooks/useReactiveWidgetState';
import { GetPortsInformationFunction, GateUI, GateUIProps } from '..';
import GateIcon from '../../gateIcon/gateIcon';
import { PortLocation } from '../../../types/canvas_t';
import NumericWithoutArrows from '../../WidgetsComponents/NumericWithoutArrows/NumericWithoutArrows';
import { ReactComponent as ElapsedGateIcon } from './icon.svg';

import styles from './randomBetween.module.css';

interface Props extends GateUIProps {
	repaint: ()=> void;
}


const RandomBetweenGate = (props: Props): React.JSX.Element => {
	const [state, setState] = useReactiveWidgetState<RandomBetweenWidgetState>(props.recipeId, props.thingRecipeId, props.info.id);
	const fixedState = {
		...getDefaultState(),
		...state,
	};

	const minInputChanged = (newValue: number) => {
		setState({
			...state,
			min: Number(newValue)
		}, true);
	};

	const maxInputChanged = (newValue: number) => {
		setState({
			...state,
			max: Number(newValue)
		}, true);
	};


	return (
		<div className={styles.GateBody}>
			<div className={`gate-input ${styles.MinInput}`}>
				<NumericWithoutArrows
					value={fixedState.min}
					onChange={minInputChanged}
					className={styles.MinInput}
				/>
			</div>
			<div className={`gate-input ${styles.MaxInput}`}>
				<NumericWithoutArrows
					value={fixedState.max}
					onChange={maxInputChanged}
					className={styles.MaxInput}
				/>
			</div>
		</div>
	);
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={ElapsedGateIcon} />}/>
	);
};

const getPortsInformation: GetPortsInformationFunction = (state, widgetInfo) => {
	const portContext: WidgetPortContext = { recipePoolId: widgetInfo.recipePoolId, recipeType: widgetInfo.recipeType };
	const outputNames = RandomBetweenGateProcessor.getOutputNames(state, portContext);
	const inputNames = RandomBetweenGateProcessor.getInputNames(state, portContext);

	const positionByOutputName: Record<string, PortLocation> = {
		'output': 'Right',
	};

	return {
		inputs: [{
			position: 'Left',
			type: inputNames[0].type,
			name: inputNames[0].name
		}],

		outputs: outputNames.map(output => ({
			position: positionByOutputName[output.name],
			type: output.type,
			name: output.name
		})),
	};
};


export default {
	getPortsInformation,
	BarIcon: GateBarIcon,
	Element: RandomBetweenGate,
	hasTitle: true,
	getWidgetTitle: (intl) => intl.formatMessage({ defaultMessage: 'Random Between', id: 'LogicMaker.Gates.RandomBetween.Title' }),
} as GateUI;
