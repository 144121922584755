import './canvasGate.css';
import React, { useRef, CSSProperties, useEffect, useCallback, useState } from 'react';
import { BrowserJsPlumbInstance } from '@jsplumb/community';
import { CustomWidgetState, WidgetType } from '@kemu-io/kemu-core/dist/types/gate_t';
import { useContextMenu } from 'react-contexify';
import { useIntl } from 'react-intl';
import { SettingFilled } from '@ant-design/icons';
import { motion, AnimatePresence } from 'framer-motion';
import classNames from 'classnames';
import { WidgetGroupState } from '@kemu-io/kemu-core/dist/gates/widgetGroup';
import { useSelector } from 'react-redux';
import { Connection } from '@jsplumb/community/types/core';
import { SourceInfo } from '@kemu-io/kemu-core/dist/common/eventHistoryMap';
import { findWidgetInRecipe } from '@kemu-io/kemu-core/dist/common/recipeCache';
import { getGateBody, PortDescription, WidgetPortsSummary, WidgetPortWithId } from '../gates/index';
import GateSettingsCloseBtn from '../gateSettingsCloseBtn/gateSettingsCloseBtn';
import { MENU_GATE, WidgetMenuProps } from '@src/features/LogicMapper/contextMenu/contextMenu';
import { generateDomId } from '@common/utils';
import { PortLocation } from '@src/types/canvas_t';
import Logger from '@common/logger';
import useReactiveWidgetState from '@common/hooks/useReactiveWidgetState';
import { getUniqueGatePortId } from '@src/app/recipe/utils';
import useAlwaysLinked from '@common/hooks/useAlwaysLinked';
import { getEndpointSetting, PortSettingsParameters } from '@common/jsPlumb/settings';
import { CUSTOM_WIDGET_PORT_COLOR_FLAG, CUSTOM_WIDGET_PORT_COLOR_PREFIX, DISABLED_WIDGET_CLASS, ORIGINAL_EVENT_CLASS, WIDGET_INVOKED_CLASS, WIDGET_PORT_RADIUS } from '@common/constants';
import { KemuConnectionData, KemuEndpointData, StatelessRecipeWidget } from '@src/types/core_t';
import { getCategoryFromType } from '@common/widgetCategories';
import { selectCurrentRecipeType } from '@src/features/Workspace/workspaceSlice';
import useWidgetInvokeMonitor from '@hooks/useWidgetInvokeMonitor';

const logger = Logger('canvasGate');
interface Props{
	thingRecipeId: string;
	thingDbId: string;
	thingVersion: string;
	recipeId: string;
	gateId: string;
	plumbInstance: BrowserJsPlumbInstance;
}

type PortWithoutType = Omit<PortDescription, 'type'>;



/** Builds valid port settings depending on the type */
const getEndpointConfig = (isTarget: boolean, id: string, customPosition: PortLocation) => {
	// top (LR), left (TB), (dx, dy) <== direction of curve
	const portSettings = getEndpointSetting(isTarget, customPosition, WIDGET_PORT_RADIUS);
	portSettings.portId = id;
	portSettings.parameters = {
		isTarget,
	};

	portSettings.uuid = id;
	return portSettings;
};


/**
 * Adds ports to the given gate element
 */
const buildPorts = (
	gateId: string,
	plumbInstance: BrowserJsPlumbInstance,
	inputPorts: PortWithoutType[],
	outputPorts: PortWithoutType[],
	gateEl: HTMLElement
) => {

	const hasPorts = inputPorts.length || outputPorts.length;
	if (!hasPorts) {
		// Without adding ports, the element is not draggable, 
		// here we make sure it is by making it a managed element
		plumbInstance.manage(gateEl);
		return;
	}

	plumbInstance.batch(() => {
		inputPorts.map((port, index) => {
			const portId = getUniqueGatePortId(index, 'input', gateId);
			if (gateEl) {
				const targetPortSettings = getEndpointConfig(true, portId, port.position);
				plumbInstance.addEndpoint(gateEl, { ...targetPortSettings });
			}
		});

		outputPorts.map((port, index) => {
			const portId = getUniqueGatePortId(index, 'output', gateId);
			if (gateEl) {
				const sourcePortSettings = getEndpointConfig(false, portId, port.position);
				plumbInstance.addEndpoint(gateEl, { ...sourcePortSettings });
			}
		});
	});
};

const buildWidgetPortsInfo = (portType: 'input' | 'output', widgetId: string, ports: PortDescription[]): WidgetPortWithId[] => {
	return ports.map((port, index) => {
		const portId = getUniqueGatePortId(index, portType, widgetId);
		return {
			id: portId,
			name: port.name,
			type: port.type,
			shape: port.jsonShape,
		};
	});
};

const checkIfSourceIsInnerWidget = (recipeId: string, thingRecipeId: string, sourceWidgetId: string, currentWidgetGroupId: string) => {
	const sourceWidgetInfo = findWidgetInRecipe(recipeId, thingRecipeId, sourceWidgetId);
	const isChildWidget = sourceWidgetInfo?.groupId && sourceWidgetInfo?.groupId === currentWidgetGroupId;
	return isChildWidget;
};

const CanvasGateElement = (props: Props & {gateInfo: StatelessRecipeWidget}): React.JSX.Element => {
	const intl = useIntl();
	const [showSettingsBox, setShowSettingsBox] = useState(false);
	const gateEl = useRef<HTMLDivElement>(null);
	const { gateInfo, gateId, thingRecipeId, recipeId, plumbInstance } = props;
	const currentRecipeType = useSelector(selectCurrentRecipeType);
	const gateUI = getGateBody(gateInfo.type);
	if (!gateUI) { throw Error(`Unknown gate [${gateInfo.type}]`); }

	const { type } = gateInfo;
	const hasCustomSettings = !!gateUI.CustomSettingsDialog;
	const outPortRef = useRef<HTMLDivElement>(null);
	const inPortRef = useRef<HTMLDivElement>(null);
	const [labelsReady, setLabelsReady] = useState(false);
	// TODO: By default the useGateState should ignore private properties to prevent re-rendering
	// when widget processors use state flags internally (Eg. pixelfy widget)
	const [gateState] = useReactiveWidgetState<CustomWidgetState>(props.recipeId, thingRecipeId, props.gateId);
	const canvasDomId = generateDomId(props.recipeId, thingRecipeId, props.gateId);

	const handleWidgetInvoked = useCallback((wasInvoked: boolean, eventSource?: SourceInfo, aborted?: boolean) => {
		const connections = plumbInstance.getConnections({ target: gateEl.current }) as Connection[];
		for (const conn of connections) {
			// Cancel animations
			if (aborted) {
				conn.removeClass(WIDGET_INVOKED_CLASS);
				continue;
			}

			const connData: KemuConnectionData | undefined = conn.getData();
			if (connData && eventSource?.widgetId) {

				// For groups, the source widget will be one of the inner widgets
				// in which case, this initial conditional doesn't work.
				if (connData?.sourceWidgetId === eventSource?.widgetId
					// if the source is a widget that belongs to a group, we need to find out if the group
					// is a child of connection source.
					|| checkIfSourceIsInnerWidget(recipeId, thingRecipeId, eventSource.widgetId, gateId)
				) {
					if (wasInvoked) {
						conn.addClass(WIDGET_INVOKED_CLASS);
					} else {
						conn.removeClass(WIDGET_INVOKED_CLASS);
					}
				}
			}
		}
	}, [plumbInstance, thingRecipeId, recipeId, gateId]);

	useWidgetInvokeMonitor(props.thingRecipeId, props.gateId, 200, handleWidgetInvoked);

	// Some gates are of type group, which means they render virtual ports
	// const visibleGroup = useSelector(selectVisibleGroup);
	// const hasVirtualPorts = gateInfo.type === 'widgetGroup';
	// const isInsideGroup = visibleGroup?.groupId === (gateState as CustomWidgetState<WidgetGroupState>).groupId && hasVirtualPorts;

	// Get actual presentation information from the gate itself
	const gatePorts = gateUI.getPortsInformation!(gateState, {
		id: gateInfo.id,
		thingRecipeId: thingRecipeId,
		thingDbId: props.thingDbId,
		thingVersion: props.thingVersion,
		recipeId: recipeId,
		recipePoolId: recipeId,
		recipeType: currentRecipeType,
	}, intl);

	const repaintConnections = useAlwaysLinked(
		props.gateId,
		gatePorts.inputs.length,
		gatePorts.outputs.length,
		plumbInstance
	);

	// Keep track of the ports position and id in string format to make sure we don't trigger
	// the useEffect that builds the ports between renders
	const portsStr = JSON.stringify({
		inputPorts: gatePorts.inputs.map(input => ({ position: input.position })),
		outputPorts: gatePorts.outputs.map(output => ({ position: output.position })),
	});

	// Keeps track of the ports shapes and types
	const portTypesStr = JSON.stringify({
		inputPorts: buildWidgetPortsInfo('input', gateInfo.id, gatePorts.inputs),
		outputPorts: buildWidgetPortsInfo('output', gateInfo.id, gatePorts.outputs),
		color: type === WidgetType.widgetGroup ? (gateState as CustomWidgetState<WidgetGroupState>)?.color : undefined,
	} as WidgetPortsSummary);

	/**
	 * Adds a custom style to widget group ports that have a custom color.
	 */
	const updateCustomPortClasses = useCallback(() => {
		const ports = document.querySelectorAll<HTMLElement>(`.port.${CUSTOM_WIDGET_PORT_COLOR_FLAG}`);
		ports.forEach(portEl => {
			const classColor = portEl.classList.value.split(' ').find(className => className.startsWith(CUSTOM_WIDGET_PORT_COLOR_PREFIX));
			const isDisabled = portEl.classList.value.includes(DISABLED_WIDGET_CLASS);
			if (classColor) {
				const hexColor = classColor.replace(CUSTOM_WIDGET_PORT_COLOR_PREFIX, '');
				if (!isDisabled) {
					portEl.style.border = `solid 2px #${hexColor}`;
				} else {
					// clear it out
					portEl.style.border = '';
				}
			}
		});
	}, []);

	const repaintPoints = useCallback(() => {
		if (gateEl.current) {
			plumbInstance.revalidate(gateEl.current);
		}
	}, [plumbInstance, gateEl]);


	useEffect(() => {
		let gateElId: string | null = null;

		if (gateEl.current) {
			const { inputPorts, outputPorts } = JSON.parse(portsStr) as {inputPorts: PortWithoutType[], outputPorts: PortWithoutType[]};

			logger.log(`Building ports for gate ${type}`);
			buildPorts(gateId, plumbInstance, inputPorts, outputPorts, gateEl.current!);
			repaintConnections();

			// By the time the canvas blocks are unmounted the reference to the
			// element (gateEl) no longer exists. So that we can refer to the 
			// original object and remove ports and connections I store the id of the
			// element as a reference which is persistent across the component lifecycle
			gateElId = gateEl.current.id;
			// canvasElementId.current = gateEl.current.id;
		}

		return () => {
			// if(canvasElementId.current){
			if (gateElId) {
				logger.log('Removing all endpoints of %s (%s)', type, gateId);
				plumbInstance.removeAllEndpoints(gateElId);
			}
		};
	}, [repaintConnections, updateCustomPortClasses, gateEl, plumbInstance, portsStr, gateId, type]);



	const gatePos: CSSProperties = {
		left: gateInfo.canvas.position.x,
		top: gateInfo.canvas.position.y,
		...((gateInfo.type === WidgetType.widgetGroup && (gateState as CustomWidgetState<WidgetGroupState>)?.color) ? {
			boxShadow: `0px 0px 7px 1px ${(gateState as CustomWidgetState<WidgetGroupState>)?.color}`
		} : {})
	};

	// Shows the context menu
	const parentClassName = `canvas-gate`;
	const { show: showMenu } = useContextMenu({ id: MENU_GATE });
	const handleItemClick = (event: React.MouseEvent<HTMLElement>) => {
		// @ts-expect-error not sure why tagName isn't recognized
		if (event.target.tagName !== 'INPUT') {
			event.preventDefault();
			showMenu(event, {
				props: {
					disabled: gateInfo.disabled,
					boundaryClass: parentClassName,
					recipeId,
					gateId,
					blockId: thingRecipeId,
					gateType: gateInfo.type,
					returnOriginalEvent: gateInfo.returnOriginalEvent
				} as WidgetMenuProps
			});
		}
	};

	const onShowCustomSettings = () => {
		plumbInstance.getEndpoints(gateEl.current).forEach(ep => ep.addClass('hidden'));
		setShowSettingsBox(true);
	};

	const closeDialog = useCallback(() => {
		setShowSettingsBox(false);
		plumbInstance.getEndpoints(gateEl.current).forEach(ep => ep.removeClass('hidden'));
	}, [plumbInstance]);

	const getPortStyle = (portDescription: PortDescription, isInput=true): React.CSSProperties => {
		const props: React.CSSProperties = {};
		if (!labelsReady) { return props; }

		const portPosition = portDescription.position;
		const container = isInput ? inPortRef.current : outPortRef.current;
		if (!container) { return { display: 'none' }; }

		const cH = container.getBoundingClientRect().height;
		if (portPosition === 'Left' || portPosition === 'Right') {
			return { top: `${(cH / 2) - WIDGET_PORT_RADIUS}px` };
		} else {
			const verticalLocation = portPosition[1] * cH;
			return { top: `${verticalLocation - WIDGET_PORT_RADIUS}px` };
		}
	};


	// Force re-rendering once the reference to the ports is valid
	useEffect(() => {
		if (inPortRef.current && outPortRef.current) {
			setLabelsReady(true);
		}
	}, [inPortRef, outPortRef]);

	const settingsDialogAnimation = {
		visible: {
			opacity: 1,
			scale: 1,
			transition: {
				duration: 0.1
			},
		},

		hide: {
			opacity: 0,
			scale: 0,
			transition: {
				duration: 0.1
			},
		},

		hidden: {
			opacity: 0,
			scale: 0.9,
		}
	};

	const togglePortsClass = useCallback((className: string, add: boolean) => {
		const ports = plumbInstance.getEndpoints(canvasDomId);
		ports.forEach(port => {
			if (add) {
				port.addClass(className);
			} else {
				port.removeClass(className);
			}
		});
	}, [canvasDomId, plumbInstance]);


	const buildPortClasses = useCallback((summary: WidgetPortsSummary) => {
		const plumbPorts = plumbInstance.getEndpoints(canvasDomId);
		const allPorts = summary.inputPorts.concat(summary.outputPorts);
		allPorts.forEach(port => {
			const plumbPort = plumbPorts.find(pp => pp.portId === port.id);
			if (plumbPort) {
				const portParameters = plumbPort.getParameters();
				// Update the json shape in case it changed
				plumbPort.setParameter('jsonShape' as keyof PortSettingsParameters, port.jsonShape);

				// Remove all existing classes
				plumbPort.removeClass(plumbPort.endpoint.classes[0]);

				// Build a new list of classes
				const portTypes = (Array.isArray(port.type) ? port.type : [port.type]).map(arrType => `type-${arrType}`);
				const cssClasses = classNames(
					portParameters.isTarget ? 'target-port': 'source-port',
					'port',
					'gate-port',
					`ctgry-${getCategoryFromType(gateInfo.type)}`,
					...portTypes,
					{
						'original-event': gateInfo.returnOriginalEvent,
						[DISABLED_WIDGET_CLASS]: props.gateInfo.disabled,
						[CUSTOM_WIDGET_PORT_COLOR_FLAG]: summary?.color,
						[`${CUSTOM_WIDGET_PORT_COLOR_PREFIX}${summary.color?.replace('#', '')}`]: summary?.color,
					}
				);

				// This is used by the port debugging panel
				const endpointData: KemuEndpointData = {
					type: port.type,
					name: port.name,
					label: port.label,
					id: port.id,
					jsonShape: port.jsonShape
				};

				plumbPort.setData(endpointData);

				plumbPort.addClass(cssClasses);
			} else {
				console.error(`Port [${port.name}] not found!`);
			}
		});
	}, [canvasDomId, plumbInstance, props.gateInfo.disabled, gateInfo.type, gateInfo.returnOriginalEvent]);

	// FIXME: THIS SHOULD NOT BE NECESSARY. 
	// This method exists to allow widget canvas components to refresh their port classes
	// so that the WidgetPortInfoPanel can display the correct information (widget type)
	// instead, the panel itself should query the widget's processor directly, instead of relying
	// on DOM classes.
	/** used to update the classes for a given port */
	const rebuildPortClasses = useCallback((info: WidgetPortsSummary) => {
		buildPortClasses(info);
		updateCustomPortClasses();
	}, [buildPortClasses, updateCustomPortClasses]);


	// Monitors changes to the returnOriginalEvent property
	useEffect(() => {
		togglePortsClass(ORIGINAL_EVENT_CLASS, !!gateInfo.returnOriginalEvent);
	}, [gateInfo.returnOriginalEvent, togglePortsClass]);

	// Monitors changes to the enable/disable property
	useEffect(() => {
		togglePortsClass(DISABLED_WIDGET_CLASS, !!gateInfo.disabled);
	}, [gateInfo.disabled, togglePortsClass]);

	useEffect(() => {
		if (portTypesStr) {
			const info = JSON.parse(portTypesStr) as WidgetPortsSummary;
			buildPortClasses(info);
			updateCustomPortClasses();
		}
	}, [portTypesStr, buildPortClasses, updateCustomPortClasses]);

	const gateHeaderText = (gateUI.getWidgetTitle && gateUI.getWidgetTitle(intl)) || gateInfo.type;

	return (
		<>
			<div ref={gateEl}
				data-kemu-type={gateInfo.type}
				className={classNames(
					parentClassName,
					`ctgry-${getCategoryFromType(gateInfo.type)}`,
					gateUI.getWrapperClass && gateUI.getWrapperClass(),
					{ 'original-event': gateInfo.returnOriginalEvent, 'widget-disabled': gateInfo.disabled },
					{ ['settings-visible']: showSettingsBox }
				)}
				style={gatePos}
				id={canvasDomId}
				onContextMenu={handleItemClick}
			>

				{gateUI.CustomSettingsDialog && gateEl.current && (
					<div className={
						classNames('gate-settings-wrapper', 'custom-size', { 'visible': showSettingsBox })
					} style={{ position: 'absolute', height: '100%', 'width': '100%' }}>
						<AnimatePresence exitBeforeEnter>
							{showSettingsBox && (
								<motion.div
									className="animator"
									animate="visible"
									initial="hidden"
									variants={settingsDialogAnimation}
									exit="hide"
								>
									<gateUI.CustomSettingsDialog
										repaintPorts={repaintPoints}
										container={gateEl.current}
										onClose={closeDialog}
										gateInfo={gateInfo}
										recipeId={props.recipeId}
										recipeType={currentRecipeType}
										blockId={props.thingRecipeId}
									>
										<GateSettingsCloseBtn onClose={closeDialog}/>
									</gateUI.CustomSettingsDialog>
								</motion.div>
							)}
						</AnimatePresence>
					</div>
				)}

				<div className="cg-container">
					{gateUI.hasTitle && (
						<div className={`cg-header ${hasCustomSettings ? `custom-settings ${showSettingsBox ? 'visible' : ''}` : ''}`}>
							<div className="cg-label noselect">
								<span>{gateHeaderText}</span>
							</div>
							<span className="settings-btn" onClick={onShowCustomSettings}>
								<SettingFilled />
							</span>
						</div>
					)}
					<div className="cg-body">
						<gateUI.Element
							info={gateInfo}
							recipeType={currentRecipeType}
							thingRecipeId={props.thingRecipeId}
							thingDbId={props.thingRecipeId}
							thingVersion={props.thingVersion}
							recipeId={props.recipeId}
							repaint={repaintPoints}
							rebuildPortClasses={rebuildPortClasses}
						/>
					</div>


					<div className="in-ports-container" ref={inPortRef}>
						{labelsReady && (
							gatePorts.inputs.map((port, index) => {
								return (
									<div key={index} className={`port is-input type-${port.type}`} style={getPortStyle(port, true)}>
										<div className={`label noselect ${(port.label || port.name).length > 10 ? 'break-text' : ''}`}>{port.label || port.name}</div>
									</div>
								);
							})
						)}
					</div>

					<div className="out-ports-container" ref={outPortRef}>
						{labelsReady && (
								gatePorts.outputs.map((port, index) => {
								return (
									<div key={index} className={`port is-output type-{${port.type}}`} style={getPortStyle(port, false)}>
										<div className={`label noselect ${(port.label || port.name).length > 10 ? 'break-text' : ''}`}>{port.label || port.name}</div>
									</div>
								);
							})
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default CanvasGateElement;
