import { Skeleton } from 'antd';
import React from 'react';
import styles from './CardsSkeleton.module.css';

interface Props {
	total: number;
	cardWidth?: number;
}

const CardsSkeleton = (props: Props): React.JSX.Element => {
	const cards = Array.from('x'.repeat(props.total));
	return (
		<div className={styles.Grid} style={{
			gridTemplateColumns: `repeat(auto-fill, ${props.cardWidth || 170}px)`
		}}>
			{cards.map((_, index) => (
				<article className={styles.Card} key={index}>
					<div className={styles.Header}>
						<Skeleton.Avatar active={true} shape="square" className={styles.Image}/>
					</div>
					<div className={styles.Details}>
						<div className={styles.Title}>
							<Skeleton active={true} />
						</div>
					</div>
				</article>
			))}
		</div>
	);
};

export default CardsSkeleton;
