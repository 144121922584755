import { TransferProgressEvent } from '@kemu-io/kemu-core/dist/types/common_t';
import axios, { AxiosProgressEvent } from 'axios';

const handleProgress = (evt: AxiosProgressEvent, onProgressCb?: (evt: TransferProgressEvent) => void) => {
	if (onProgressCb) {
		const total = evt.total || 1;
		const percentage = Math.round((evt.loaded / total) * 100);
		onProgressCb({
			total,
			loaded: evt.loaded,
			percentage,
		});
	}
};

/**
 * Downloads a file with progress from s3 using the given pre-signed url.
 * @param presignedUrl a pre-signed S3 url
 * @param onProgressCb a method to call with the progress events of the download
 * @returns the recipe in JSON format
 */
 const downloadFile = async <T>(
	presignedUrl: string,
	responseType: 'json' | 'arraybuffer',
	onProgressCb?: (evt: TransferProgressEvent) => void): Promise<T> => {
	const results = await axios.get(`${presignedUrl}`, {
		responseType,
		onDownloadProgress: (evt) => {
			handleProgress(evt, onProgressCb);
		}
	});

	return results.data;
};

/**
 * Uploads the given file to a S3 using a pre-signed url.
 * @param presignedUrl a pre-signed URL for the upload
 * @param onProgressCb a method to call with the progress events of the upload
 */
 const uploadFile = async (
	presignedUrl: string,
	contents: ArrayBuffer,
	onProgressCb?: (evt: TransferProgressEvent) => void
): Promise<void> => {
	const results = await axios.put(`${presignedUrl}`, contents, {
		// NOTE: this MUST match the content type set by the service when creating the pre-signed url
		headers: { 'Content-Type': 'application/octet-stream' },
		onUploadProgress: (evt) => {
			handleProgress(evt, onProgressCb);
		}
	});

	return results.data;
};

export {
	downloadFile,
	uploadFile,
};
