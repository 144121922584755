import React from 'react';
import { Skeleton } from 'antd';
import styles from './PublicationDetailsSkeleton.module.css';

const PublicationDetailsSkeleton = (): React.JSX.Element => {
	return (
		<div className={styles.Container}>
			<Skeleton.Avatar className={styles.HeroImage} active={true} shape="square"/>
			<Skeleton active={true} paragraph={{ rows: 5 }} title={{ width: '10%' }} className={styles.Description}/>
			<span className={styles.Line} />
			<Skeleton active={true} />
		</div>
	);
};

export default PublicationDetailsSkeleton;
