
import React from 'react';
import Icon from '@ant-design/icons';
import JsonProcessor, { JsonWidgetState, getDefaultState } from '@kemu-io/kemu-core/dist/gates/json';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomWidgetState, WidgetPortContext } from '@kemu-io/kemu-core/dist/types/gate_t';
import { message } from 'antd';
import classNames from 'classnames';
import NumericInput from '../../WidgetsComponents/NumericInput/NumericInput';
import { decodeChildPortIdentifier } from '../../../app/recipe/utils';
import GateIcon from '../../gateIcon/gateIcon';
import {
	GateCustomSettingsProps,
	GetPortsInformationFunction,
	GateUI,
	GateUIProps
} from '..';
import useReactiveWidgetState from '../../../common/hooks/useReactiveWidgetState';
import useTranslation from '../../../common/hooks/useTranslation';
import InputWithAutoComplete from '../../WidgetsComponents/InputWithAutoComplete/InputWithAutoComplete';
import { SETTINGS_CONTAINER_CLASS } from '../../../common/constants';
import styles from './json.module.css';
import { ReactComponent as JsonWidgetIcon } from './json.svg';

type Props = GateUIProps


const getGateDefaultState = (state: JsonWidgetState) => {
	// NOTE: the actual 'default' state is defined at the processor level (kemu-core/src/gates/json/index.ts),
	// here we just make sure we show the same.
	const fixedState: JsonWidgetState = {
		...getDefaultState(),
		...state
	};

	return fixedState;
};

const JsonWidget = (props: Props): React.JSX.Element => {
	const [state, setState] = useReactiveWidgetState<JsonWidgetState>(props.recipeId, props.thingRecipeId, props.info.id);
	const fixedState = getGateDefaultState(state);
	const t = useTranslation();
	const placeHolder = t('LogicMaker.Gates.Json.PlaceHolder', 'Property name');

	const handleAutoCompleteChange = (value: string, index?: number) => {
		const properties = [...fixedState.properties];

		if (index !== undefined && properties[index] !== undefined) {
			properties[index] = value;

			setState({
				...fixedState,
				properties
			}, true);
		}
	};


	return (
		<div className={`${styles.GateBody}`}>
			{fixedState.properties.map((propertyName, i) => (
				<div className={`gate-input`} key={i}>
					<InputWithAutoComplete
						placeholder={placeHolder}
						value={propertyName}
						onChangeMeta={i}
						onChange={handleAutoCompleteChange}
						options={Object.keys(fixedState.detectedProperties)}
					/>
				</div>
			))}
		</div>
	);
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={JsonWidgetIcon} />}/>
	);
};


const GateCustomSettings = (props: GateCustomSettingsProps): React.JSX.Element => {
	const [state, setState] = useReactiveWidgetState<JsonWidgetState>(props.recipeId, props.blockId, props.gateInfo.id);
	const fixedState = getGateDefaultState(state);
	const intl = useIntl();
	const defaultPropPrefix = intl.formatMessage({ id: 'LogicMaker.Gates.Json.DefaultPropName', defaultMessage: 'Property' });

	const handleInputChange = (value: number) => {
		let lastPortWithConnection = 0;
		const outputNames = JsonProcessor.getOutputNames(state, { recipePoolId: props.recipeId, recipeType: props.recipeType });

		props.gateInfo.children.forEach((child) => {
			const identifier = decodeChildPortIdentifier(child.sourcePort);
			if (identifier.portIndex > lastPortWithConnection) { lastPortWithConnection = identifier.portIndex; }
		});

		if (lastPortWithConnection >= value) {
			message.warning({
				content: intl.formatMessage({
					id: 'LogicMaker.Gates.Json.RemoveWarning',
					defaultMessage: 'Please remove all connections from port "{name}"',
				}, { name: outputNames[lastPortWithConnection].name })
			});
			return;
		}

		let remainingProps = [...fixedState.properties];
		if (value < fixedState.properties.length) {
			remainingProps = remainingProps.slice(0, value);
		} else if (value > fixedState.properties.length) {
			remainingProps = [
				...remainingProps,
				...Array.from(Array(value - remainingProps.length).keys()).map(k => {
					return `${defaultPropPrefix}${k + remainingProps.length + 1}`;
				})
			];
		}


		setState({
			...fixedState,
			properties: remainingProps,
		}, true);
	};


	return (
		<div className={classNames(styles.SettingsContainer, SETTINGS_CONTAINER_CLASS)}>
			{props.children}
			<div className={styles.InputContainer}>
				<label>
					<FormattedMessage id="LogicMaker.Gates.Json.CustomSettingsTitle" defaultMessage="Total outputs" />
				</label>
				<NumericInput
					min={1}
					max={20}
					value={fixedState.properties.length}
					onChange={handleInputChange}
				/>
			</div>
		</div>
	);
};

const getPortsInformation: GetPortsInformationFunction = (state: CustomWidgetState<JsonWidgetState>, widgetInfo) => {
	const portContext: WidgetPortContext = { recipePoolId: widgetInfo.recipePoolId, recipeType: widgetInfo.recipeType };
	const outputNames = JsonProcessor.getOutputNames(state, portContext);
	const inputNames = JsonProcessor.getInputNames(state, portContext);

	const outputFraction = ((1 - 0.12) / state.properties.length);

	return {
		inputs: [{
			position: 'Left',
			name: inputNames[0].name,
			type: inputNames[0].type,
		}],

		outputs: outputNames.map((output, i) => {
			let space = (outputFraction * (i + 1));
			if (state.properties.length === 1) { space = 0.5; }
			if (state.properties.length === 2) { space = 0.4 + (0.33 * i); }
			return  {
				name: output.name,
				type: output.type,
				jsonShape: output.jsonShape,
				position: [1, space, 1, 0]
			};
		})
	};
};

export default {
	getPortsInformation,
	BarIcon: GateBarIcon,
	Element: JsonWidget,
	CustomSettingsDialog: GateCustomSettings,
	hasTitle: true,
	getWidgetTitle: (intl) => intl.formatMessage({ id: 'LogicMaker.Gates.Json.Title', defaultMessage: 'Get Property' }),
} as GateUI;
