import React from 'react';
import { Rate } from 'antd';
import CardsSkeleton from '../CardsSkeleton/CardsSkeleton';
import StyledButton from '../../../../components/form-control/styledButton/styledButton';
import useTranslation from '../../../../common/hooks/useTranslation';
import styles from './LauncherRecipes.module.css';

export interface GenericCardProps {
	imageUrl: string;
	title: string;
	rating: number | null;
	description: string;
	id: string;
}

interface Props {
	onCardSelected: (id: string) => void;
	onLoadAllRecipes?: () => void;
	items: GenericCardProps[];
	isLoading: boolean;
}

const LauncherRecipes = (props: Props): React.JSX.Element | null => {
	const t = useTranslation('Luncher.Tabs.PublicRecipes');
	if (!Array.isArray(props.items)) { return null; }

	// handles onClick event
	const handleCardClick = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
		const card = evt.currentTarget.closest('article[data-entity-id]');
		if (card) {
			const cardId = card.getAttribute('data-entity-id');
			cardId && props.onCardSelected(cardId);
		}
	};

	return (
		<div>
			{props.isLoading ? (
				<CardsSkeleton total={8} />
			) : (
				<>
					<div className={styles.Rows}>
						{props.items.map((card) => {
							return (
								<article data-entity-id={card.id} className={styles.Card} data-kemu-meta="launcher-public-recipe-card" key={card.id}>
									<div className={styles.Header}>
										<div className={styles.Link} onClick={handleCardClick}>
											<div className={styles.ImageDiv} style={{
												backgroundImage: `url("${card.imageUrl}")`
											}}/>
										</div>
									</div>

									<div className={styles.Details}>
											<div className={styles.Link} onClick={handleCardClick}>
											<div className={styles.Title}>
												<h3>{card.title}</h3>
											</div>
											<div className={styles.Description}>
												{card.description}
											</div>
										</div>

										{card.rating !== null && (
											<div className={styles.Rating}>
												<Rate disabled={true} allowClear={false} defaultValue={card.rating} />
											</div>
										)}
									</div>
								</article>
							);
						})}
					</div>


					<div className={styles.ButtonContainer}>
						<StyledButton color="secondary" title={t('LoadAllButton', 'See All Recipes')} onClick={props.onLoadAllRecipes}/>
					</div>
				</>
			)}
		</div>
	);
};

export default LauncherRecipes;
