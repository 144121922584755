import { MinimumBlockInfo } from '@kemu-io/kemu-core/dist/blocks/manager';
import { jsonParse, removePrivateProperties } from '@kemu-io/kemu-core/dist/common/utils';
import { CustomWidgetState, DataType, GroupWidgetPort, PortType, RecipeWidget, Widget } from '@kemu-io/kemu-core/dist/types/gate_t';
import { findWidgetInRecipe } from '@kemu-io/kemu-core/dist/common/recipeCache';
import { RecipeType, ThingInfo } from '@kemu-io/kemu-types/dist/types';
import { decodeChildPortIdentifier, getWidgetParents } from '../app/recipe/utils';
import { GlobalTranslateFunction } from './translations';
import { StatelessRecipeWidget, StatelessWidgetsMap } from '@src/types/core_t';

/** string used in DOM ids as separator between recipe, blockId/gateId, etc */
const DOM_INFO_SEP = '::';
export interface DOMElementInfo {
	recipeId: string;
	blockId: string;
	gateId?: string;
	uid?: string;
}

/** 
 * Decodes information from an element's DOM id 
 * @param id is the elements ID attribute.
 **/
const decodeDomId = (id?: string): DOMElementInfo | null => {
	// NOTE: uid is used in cases when the id is shared amongst multiple
	// elements, to refer to the same entity, such as the case of virtual ports (GroupWidget ports)
	// Expected format: recipePoolID::blockId[::gateId][::uid]
	if (!id) { return null; }
	const sp = id.split(DOM_INFO_SEP);
	if (sp.length < 2) { return null; }
	const res = <DOMElementInfo>{};
	res.recipeId = sp[0];
	res.blockId = sp[1];

	if (sp.length > 2) {
		res.gateId = sp[2];
	}

	if (sp.length > 3) {
		res.uid = sp[3];
	}

	return res;
};

/**
 * Encodes the given parameters into a string to be used as DOM id.
 * @param recipeId the id of the recipe in the pool
 * @param blockId the id of the block in the recipe (recipeId property of the block)
 * @param gateId the id of the gate inside the block
 * @param uid use it to add a level of uniqueness to the id, so that multiple elements
 * can share the same base information (recipe, blockId, gateId)
 */
const generateDomId = (recipeId: string, blockId: string, gateId?: string, uid?: string): string => {
	let id = `${recipeId}${DOM_INFO_SEP}${blockId}`;
	if (gateId) { id += `${DOM_INFO_SEP}${gateId}`; }
	if (uid) { id += `${DOM_INFO_SEP}${uid}`; }
	return id;
};

/**
 * Returns true if the given value is a binary type (ArrayBuffer, Uint8Array, Uint8ClampedArray, Uint16Array, Uint32Array)
 * @param value 
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isBinaryType = (value: unknown | any): boolean => {
	if (value) {
		if (value.byteLength !== undefined) {
			if (value instanceof ArrayBuffer) { return true; }
			if (value.buffer !== undefined) {
				return (
					value instanceof Uint8Array ||
					value instanceof Uint8ClampedArray ||
					value instanceof Uint16Array ||
					value instanceof Uint32Array
				);
			}
		}
	}

	return false;
};


/**
 * Determines if the given object has any property with a binary value.
 * It traverses the whole nested structure until one binary property is found
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const hasBinaryValue = (obj: any | unknown): boolean => {
	if (obj) {
		for (const key in obj) {
			const propVal = obj[key];
			if (isBinaryType(propVal)) { return true; }
			if (typeof propVal === 'object') { return hasBinaryValue(propVal); }
		}
	}

	return false;
};

/**
 * Intercepts the list of default blocks from the DB and changes the bundle url
 * to match that of any local block working in dev mode.
 * FIXME: Not indented for production mode
 * @param blocksList a MUTABLE list (or dictionary) of blocks to analyse and replace if found to be in dev mode.
 */
const overrideDevThings = (blocksList: (MinimumBlockInfo[]) | Record<string, MinimumBlockInfo>): void => {
	const devBlocksStr = localStorage.getItem('dev-blocks');
	if (!devBlocksStr) { return; }

	const devBlocks = safeJsonParse<ThingInfo[]>(devBlocksStr);
	if (!devBlocks) { return; }

	const checkAndReplaceItem = (block: MinimumBlockInfo) => {
		const isDev = devBlocks.find(devBlock => devBlock.id === block.id);
		if (isDev) {
			block.bundle = isDev.bundle;
			block.version = isDev.version;
			block._isDevMode = true;
		}

		return block;
	};

	if (Array.isArray(blocksList)) {
		blocksList.forEach(block => {
			return checkAndReplaceItem(block);
		});
	} else {
		Object.keys(blocksList).forEach(blockId => {
			return checkAndReplaceItem(blocksList[blockId]);
		});
	}
};

/**
 * Removes all the 'function' types properties from 'obj'
 * @param obj the object to remove props from
 * @param keep name of properties to preserve
 * @param remove a list of property names to remove regardless of their type
 * @returns a copy of obj without function type properties plus any property defined in 'keep'
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
const removeFunctionProps = (obj: any, keep: string[] = [], remove: string[]=[]): Record<string, unknown> => {
	const objCopy = Object.keys(obj).reduce((current, key) => {
		if (keep.includes(key) || typeof obj[key] !== 'function' && !remove.includes(key)) {
			return { ...current, [key]: obj[key] };
		}

		return current;
	}, {});

	return objCopy;
};

/**
 * Compares all (root) primitive properties of 2 objects
 * @returns true if both objects are equal
 */
const comparePrimitivesOnly = (prevProps: unknown , newProps: unknown): boolean => {
	const prev = removeFunctionProps(prevProps);
	const now = removeFunctionProps(newProps);
	return JSON.stringify(prev) === JSON.stringify(now);
};

/**
 * Loads a configuration from the local storage.
 * @param name the name of the configuration
 * @param defaultConfig if no previous configuration is found, it uses this as default.
 */
const getStoredConfig = <T=Record<string, unknown>>(name: string, defaultConfig: T): T => {
	const currentStr = localStorage.getItem(name);
	const storageStr = currentStr ? jsonParse<T>(currentStr) : null;
	const state =  storageStr ? storageStr : defaultConfig;
	// Spread in case the stored config does not match the signature of the default settings
	return { ...defaultConfig, ...state };
};

/**
 * Patches an existing configuration in the local storage. Only the given properties will
 * be replaced.
 * @param name the name of the configuration to store in the localStorage
 * @param newConfig the object to store
 * @param clear if true all existing properties will be replaced by the given newConfig.
 */
const setStoredConfig = <T=Record<string, unknown>>(name: string, newConfig: Partial<T>, clear = false): void => {
	const currentState = !clear ? getStoredConfig(name, newConfig) : {};
	localStorage.setItem(name, JSON.stringify({ ...currentState, ...newConfig }));
};

/**
 * Checks whether the given string is a valid url
 * @param str 
 */
const isValidUrl = (str: string): boolean => {
  const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
};

/**
 * Returns the current base origin (Eg. https://kemu.io)
 */
const getOrigin = (): string => {
	return (window && window.location.origin) || (location && location.origin) || '';
};

/**
 * Parses a cognito error in search for a Kemu custom message issued by a pre-authentication lambda
 * @param message the raw error message
 */
const decodeCognitoError = (message: string): null | {code: string, message?: string} => {
	// Error messages are just a JSON string wrapped in a custom prefix and suffix
	const prefix = 'AUTH_ERR_INIT__';
	const suffix = '__AUTH_ERR_END';

	const sp = message.split(prefix);
	if (sp.length > 1) {
		try {
			const json = JSON.parse(sp[1].split(suffix)[0]);
			return json;
		} catch (e) {
			// Fail silently
		}
	}

	return null;
};

export type LastPortInfo = {index: number, portName: string, label?: string, type: PortType/* , isInnerPort?: boolean */};
/**
 * Finds out the last input port in the widget that has a parent attached to it.
 * @param state the current state of the widget
 * @param widgetProcessor the instance of the widget processor
 * @param widgetId the id of the widget to check input ports from 
 * @param thingId the id of the thing the widget is in (recipeId property)
 * @param recipeId the id of the recipe in the pool
 * @param portLocation filters parents by the type of inputs they are attached to. Don't assign it to disable filtering
 */
const getLastInputPortWithParent = <T extends CustomWidgetState>(
	state: T,
	widgetProcessor: Widget<T>,
	widgetId: string,
	thingId: string,
	recipeId: string,
	recipeType: RecipeType,
	portLocation?: 'inner' | 'outer'
): LastPortInfo | null => {
	const inputNames = widgetProcessor.getInputNames(state, { recipePoolId: recipeId, recipeType });
	const parents = getWidgetParents(widgetId, thingId, recipeId);


	let lastPortWithConnection: {portType: PortType, portIndex: number } = { portIndex: -1, portType: 'input' };
	parents.forEach((parent) => {
		const identifier = decodeChildPortIdentifier(parent.targetPort);
		const validate = !portLocation ||
			portLocation === 'inner' && (identifier.portType === 'innerInput' || identifier.portType === 'innerOutput') ||
			portLocation === 'outer' && (identifier.portType === 'input' || identifier.portType === 'output');

		if (validate && identifier.portIndex > lastPortWithConnection.portIndex) { lastPortWithConnection = identifier; }
	});

	if (lastPortWithConnection.portIndex > -1) {
		const outputByIndexProp = inputNames.find(input => (input as GroupWidgetPort).index === lastPortWithConnection.portIndex);
		const label = outputByIndexProp ? outputByIndexProp.label : undefined;

		return {
			portName: outputByIndexProp ? outputByIndexProp.name : inputNames[lastPortWithConnection.portIndex].name,
			index: lastPortWithConnection.portIndex,
			type: lastPortWithConnection.portType,
			...(label ? { label } : undefined)
		};
	}

	return null;
};

/**
 * Finds out the last output port in the widget that has a child attached to it.
 * @param state the current state of the widget
 * @param widgetProcessor the instance of the widget processor
 * @param widgetId the id of the widget to check input ports from 
 * @param thingId the id of the thing the widget is in (recipeId property)
 * @param recipeId the id of the recipe in the pool
 * @param portLocation filters parents by the type of inputs they are attached to. Don't assign it to disable filtering
 */
const getOutputPortsWithChildren = <T extends CustomWidgetState>(
	state: T,
	widgetProcessor: Widget<T>,
	widgetId: string,
	thingId: string,
	recipeId: string,
	recipeType: RecipeType,
	portLocation?: 'inner' | 'outer'
): LastPortInfo[] => {
	const portsWithConnections: LastPortInfo[] = [];
	const outputNames = widgetProcessor.getOutputNames(state, { recipePoolId: recipeId, recipeType });
	const widget = findWidgetInRecipe(recipeId, thingId, widgetId);
	if (!widget) { throw new Error(`Widget ${widgetId} does not exist in block ${thingId} and recipe ${recipeId}`); }

	widget.children.forEach((child) => {
		const identifier = decodeChildPortIdentifier(child.sourcePort);
		const validate = !portLocation ||
			portLocation === 'inner' && (identifier.portType === 'innerInput' || identifier.portType === 'innerOutput') ||
			portLocation === 'outer' && (identifier.portType === 'input' || identifier.portType === 'output');

		const outputByIndexProp = outputNames.find(output => (output as GroupWidgetPort).index === identifier.portIndex);
		const label = outputByIndexProp ? outputByIndexProp.label : undefined;

		validate && portsWithConnections.push({
			portName: outputByIndexProp ? outputByIndexProp.name : outputNames[identifier.portIndex].name,
			index: identifier.portIndex,
			type: identifier.portType,
			...(label ? { label } : undefined)
		});
	});

	return portsWithConnections;
};


/**
 * Stringifies an objects removing any private property first.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
const safeJSONStringify = (obj: any): string => {
	const cleanObj = removePrivateProperties(obj);
	return JSON.stringify(cleanObj);
};

/**
 * Clones the given object by stringifying it and then parsing it.
 * @param source the object to clone
 * @returns a deep clone of the given source
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
const jsonCloneObject = <T=any>(source: any): T => {
	return JSON.parse(JSON.stringify(source)) as T;
};

/**
 * Clones the given object by stringifying it and then parsing it but removing all private properties first.
 * @param source the object to clone
 * @returns a deep clone of the given source
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
const safeJsonClone = <T=any>(source: any): T => {
	return JSON.parse(safeJSONStringify(source)) as T;
};

/**
 * Parses a string in JSON format and silents errors.
 * @returns the parsed object or null if the string is not valid.
 */
 const safeJsonParse = <T=Record<string, unknown>>(str: string): T | null => {
	try {
		return JSON.parse(str) as T;
	} catch (e) {
		// silent errors
		return null;
	}
};

/**
 * Removes the 'state' property from the widget.
 * @param widget the widget to remove the state from
 * @returns a full copy of the widget without the 'state' or private properties.
 */
const getStatelessWidget = (widget: RecipeWidget): StatelessRecipeWidget => {
	const { state: _state, ...stateLess } = widget;
	return jsonCloneObject<StatelessRecipeWidget>(stateLess);
};

/**
 * Removes all 'state' properties from the map of widgets (which also includes all
 * private properties ($$)).
 * @param widgets map of widgets in a Thing
 * @returns a full copy of the widgets without the 'state' or private properties.
 */
const widgetMapToStatelessMap = (widgets: Record<string, RecipeWidget>): StatelessWidgetsMap => {
	const stateLessWidgets: StatelessWidgetsMap = {};
	Object.values(widgets).map((widget) => {
		// IMPORTANT: it is more efficient to remove the state property here
		// instead of calling getStatelessWidget() for each widget since
		// the latter will create a copy of the widget.
		const { state: _state, ...stateLess } = widget;
		stateLessWidgets[widget.id] = stateLess as StatelessRecipeWidget;
	});

  const updatedWidgets = jsonCloneObject(stateLessWidgets);
	return updatedWidgets;
};


/**
 * Converts a numeric port type into a human readable string.
 * @param t a translate function from useTranslation hook or intl.formatMessage
 * @param type the numeric port type
 * @returns the name of the port in the current language.
 */
const portTypeToString = (t: GlobalTranslateFunction, type: DataType): string => {
	const entry = Object.entries(DataType).find(([, value]) => value === type);
	if (entry) {
		return t(`Kemu.DataType.HumanReadable.${entry[0]}`, entry[0]);
	}

	return '';
};

/**
 * Launches the FreshDesk widget in article mode
 * @param articleId 
 */
const openHelpArticle = (articleId: string): void => {
	// @ts-expect-error using temporal solution (might change in the future)
	window?._kemu_help_?.openArticle?.(articleId);
};

const isMacOs = (): boolean => navigator.platform.toUpperCase().indexOf('MAC') >= 0;


/**
 * Truncates a text to the given length. 
 * @param str the text to truncate
 * @param maxLength total length of the string including ellipsis
 * @param ellipsis the text to append at the end of the string. Set to an empty string to
 * not append anything.
 */
const truncateString = (str: string, maxChars: number, ellipsis = '...'): string => {
	return `${str.substring(0, maxChars - ellipsis.length)}${ellipsis}`;
};


export {
	decodeDomId,
	generateDomId,
	isBinaryType,
	hasBinaryValue,
	overrideDevThings,
	removeFunctionProps,
	comparePrimitivesOnly,
	getStoredConfig,
	setStoredConfig,
	isValidUrl,
	getOrigin,
	decodeCognitoError,
	getLastInputPortWithParent,
	getOutputPortsWithChildren,
	safeJSONStringify,
	safeJsonParse,
	jsonCloneObject,
	safeJsonClone,
	portTypeToString,
	openHelpArticle,
	isMacOs,
	truncateString,
	widgetMapToStatelessMap,
	getStatelessWidget,
};
