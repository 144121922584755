import { API } from '@aws-amplify/api';
import { CreateOrganizationRequest, CreateOrganizationResponse } from '@kemu-io/kemu-types/dist/types';
import globals from '../../common/globals';

// Check the endpoint url in App.tsx
const API_NAME = globals.ORGANIZATION_API_NAME;

// Forces to return the entire Axios response object instead of only response.data
const returnAxiosResponse = { response: true };

const createOrganization = async (details: CreateOrganizationRequest): Promise<CreateOrganizationResponse> => {
	const response = await API.post(API_NAME, `/organization`, { ...returnAxiosResponse, body: details });
	return response.data as CreateOrganizationResponse;
};

export {
	createOrganization
};
