import { WidgetBundleState } from '@kemu-io/kemu-core/dist/types/widgetBundle_t';
import { PayloadAction, Draft } from '@reduxjs/toolkit';
import { removePrivateProperties } from '@kemu-io/kemu-core/dist/common/utils';
import { LogicMapperState } from '../logicMapperSlice';
import { MinActionInfo } from './customWidgetReducer';
import * as recipeActions from '@src/app/recipe/utils';
import { jsonCloneObject } from '@common/utils';

export type WidgetBundleStateWithId = (WidgetBundleState & { widgetId?: string });

const showCreateWidgetBundleModal = (
  state: Draft<LogicMapperState>,
  action: PayloadAction<MinActionInfo & { widgetId: string } | boolean
>): void => {
	let widgetInfo: WidgetBundleStateWithId | boolean = action.payload === true ? (action.payload as boolean) : false;
	// If a widget id was provided, we are in editing mode. Read state from recipe
	// before showing the modal
	if (action.payload && action.payload !== true) {
		const currentState = recipeActions.getWidgetState<WidgetBundleState>(
			action.payload.widgetId,
			action.payload.thingId,
			action.payload.recipeId,
		);

		// We MUST remove non-serializable values from the state.
		// The modal will instead read the cache directly to get the zip bundle
		const privateLess = removePrivateProperties<WidgetBundleState>(currentState);
		const stateCopy = jsonCloneObject<WidgetBundleState>(privateLess);

		widgetInfo = {
			...stateCopy,
			widgetId: action.payload.widgetId,
			// Restore cache info
			...(currentState.$$cacheInfo ? {
				$$cacheInfo: {
					version: currentState.$$cacheInfo.version,
					widgetThingId: currentState.$$cacheInfo.widgetThingId,
				}
			} :undefined ),
			// Restore collection info
			...(currentState.$$collectionInfo ? {
				$$collectionInfo: {
					version: currentState.$$collectionInfo.version,
					userId: currentState.$$collectionInfo.userId,
					widgetId: currentState.$$collectionInfo.widgetId,
				}
			} :undefined )
		};
	}

	state.showCreateWidgetBundleModal = widgetInfo;
};

export default showCreateWidgetBundleModal;
