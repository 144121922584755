import React, { useEffect, useCallback } from 'react';
import {
  HeadlineOneButton,
  HeadlineTwoButton,
	HeadlineThreeButton,
	createBlockStyleButton,
	DraftJsButtonTheme,
} from '@draft-js-plugins/buttons';

import Icon from '@ant-design/icons';
import { OverrideContentProps, ToolbarChildrenProps } from '../inlineToolbar/components/Toolbar';
import { ReactComponent as HeadlineIcon }  from './headline.svg';


const HeaderButton = (props: ToolbarChildrenProps): React.JSX.Element => {
	const { onOverrideContent } = props;

	const handleMouseDown = (event: React.MouseEvent) => {
		event.preventDefault();
	};

	const showHeaderButtons = () => {
		onOverrideContent(HeaderPicker as React.ComponentType<OverrideContentProps>);
	};


	return (
		<div className={props.theme?.buttonWrapper} onMouseDown={handleMouseDown}>
			<button onClick={showHeaderButtons} className={props.theme?.button}>
				<Icon component={HeadlineIcon} style={{ fontSize: 24 }}/>
			</button>
		</div>
	);
};


const buttons = [
	HeadlineOneButton,
	HeadlineTwoButton,
	HeadlineThreeButton,
	createBlockStyleButton({
		blockType: 'header-four',
		children: 'H4'
	}),
	createBlockStyleButton({
		blockType: 'header-five',
		children: 'H5'
	}),
	createBlockStyleButton({
		blockType: 'header-six',
		children: 'H6'
	}),
];

const HeaderPicker = (props: ToolbarChildrenProps) => {
	const { onOverrideContent } = props;
	const theme = props.theme || {} as DraftJsButtonTheme;
	// Restore header picker when user clicks out
	const onWindowClick = useCallback(() => {
		// Restore original toolbar
		onOverrideContent();
	}, [onOverrideContent]);

	// Create an event listener to detect when the user clicks out
	useEffect(() => {
		window.addEventListener('click', onWindowClick);
		return () => {
			window.removeEventListener('click', onWindowClick);
		};
	}, [onWindowClick]);



	return (
		<div style={{ padding: 8 }}>
			{buttons.map((Button, i) => {

				return (
					<Button key={i} {...props} theme={theme} />
				);
			})}
		</div>
	);
};


export default HeaderButton;
