
import React, { useState } from 'react';
import { Menu, Dropdown, Tooltip } from 'antd';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';
import Icon, { CloseOutlined } from '@ant-design/icons';
import InputWidgetProcessor from '@kemu-io/kemu-core/dist/gates/input';
import { useSelector } from 'react-redux';
import { BlockInput } from '@kemu-io/kemu-core/dist/types/block_t';
import { DataType, InputGateState } from '@kemu-io/kemu-core/dist/types/gate_t';
import classNames from 'classnames';
import GateIcon from '../../gateIcon/gateIcon';
import { GetPortsInformationFunction, GateUI, GateUIProps } from '..';
import useReactiveWidgetState from '../../../common/hooks/useReactiveWidgetState';
import { selectedBlock } from '../../../features/Workspace/workspaceSlice';
import { getBlockInputs } from '../../../app/recipe/utils';
import useTranslation from '../../../common/hooks/useTranslation';
import EditableTextbox from './editableTextbox';
import styles from './input.module.css';
import { ReactComponent as InputWidgetIcon } from './icon.svg';

interface Props extends GateUIProps {
	dataSources?: {
		name: string;
		inputs: string[]
	}[]
}

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={InputWidgetIcon}/>}/>
	);
};

const InputGate = (props: Props): React.JSX.Element => {
	const currentBlockInfo = useSelector(selectedBlock);
	const [gateState, setGateState] = useReactiveWidgetState<InputGateState>(props.recipeId, props.thingRecipeId, props.info.id);
	const blockInputs = getBlockInputs(props.recipeId, props.thingRecipeId);
	const selectedText = gateState.selectedInput as string || '';
	const currentCustomInputs = (gateState.customInputs || []) as BlockInput[];
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const selectedCustomInput = currentCustomInputs.find(input => input.name === gateState.selectedInput);
	const t = useTranslation('LogicMaker.Gates.Input');

	const handleMenuClick: MenuClickEventHandler = (info) => {
		setGateState({
			...gateState,
			selectedInput: info.key as string
		});

		setDropdownVisible(false);
	};

	const onNewInputEntered = (name: string) => {
		const exists = currentCustomInputs.find(input => input.name === name);
		if (!exists) {
			const newCustomInputs = [
				...currentCustomInputs,
				{
					name: name,
					dataType: DataType.Anything,
				}
			];

			setGateState({
				...gateState,
				selectedInput: name,
				customInputs: newCustomInputs
			});
		}


		setDropdownVisible(false);
	};

	const onRemoveCustomInput = () => {
		const inputs = (gateState?.customInputs || []).filter((input) => input.name !== selectedCustomInput?.name);
		setGateState({
			...gateState,
			selectedInput: '',
			customInputs: inputs
		});
	};

	const dropdownVisibilityChanged = (visible: boolean) => {
		setDropdownVisible(visible);
	};


	const menu = (
		<Menu className={styles.DropdownMenu} onClick={handleMenuClick} selectedKeys={[gateState?.selectedInput || '']}>
			<Menu.Item key="selected-itm" disabled>{currentBlockInfo?.title}</Menu.Item>
			{blockInputs.map((blockInput) => (
				<Menu.Item key={`${blockInput.name}`}>{blockInput.name}</Menu.Item>
			))}

			{currentCustomInputs.map((customInput) => {
				return (
					<Menu.Item key={`${customInput.name}`}>
						{customInput.name}
					</Menu.Item>
				);
			})}
		</Menu>
	);


	return (
		<div className="input-gate lm-gate-horizontal">
			<div className="gate-icon">
				{selectedCustomInput ? (
					<Tooltip title={t('RemoveInput', 'Remove custom input')}>
						<button className={styles.DeleteItemBtn}>
							<CloseOutlined onClick={onRemoveCustomInput} />
						</button>
					</Tooltip>
				) : (
					// <LoginOutlined className=""/>
					<div className={classNames('gate-icon', styles.CanvasIconContainer)}>
						<Icon className="icon" component={InputWidgetIcon}/>
					</div>
				)}
			</div>
			<Dropdown overlay={menu} visible={dropdownVisible} className="gate-dropdown">
				<EditableTextbox onEnterKey={onNewInputEntered} defaultText={selectedText} onFocusChanged={dropdownVisibilityChanged} />
			</Dropdown>
		</div>
	);
};



const getPortsInformation: GetPortsInformationFunction = (state, gateInfo) => {
	const outputNames = InputWidgetProcessor.getOutputNames(state, gateInfo);

	return {
		outputs: [{
			position: 'Right',
			name: outputNames[0].name,
			type: outputNames[0].type,
			label: outputNames[0].label || undefined,
		}],

		inputs: [],
	};
};

export default {
	getPortsInformation,
	BarIcon: GateBarIcon,
	Element: InputGate,
	hasTitle: false,
	getWidgetTitle: (intl) => intl.formatMessage({ defaultMessage: 'Input', id: 'LogicMaker.Gates.Input.Title' }),
} as GateUI;
