import { ContentBlock, EditorState } from 'draft-js';

/** returns the container element of the Draft editor */
const getEditorRoot = (): HTMLElement | null => {
  const editor = document.querySelector('.DraftEditor-root');
  return editor as HTMLElement;
};

const getEditorRootContainer = (): HTMLElement | null => {
	const root = getEditorRoot();
	if (!root) { return root; }
	return root.parentElement;
};

/**
 * @returns true if the current active element is a child of the given element
 * OR if it is the given element itself.
 */
const isActiveElementAChildOf = (parentElement: HTMLElement): boolean => {
  const activeParent = document.activeElement as HTMLElement;
  if (!activeParent) { return false; }
  return isElementAChildOf(activeParent, parentElement);
};

/**
 * It searches up the node tree checking if `targetElement` is a child of `parentElement`
 * stopping at the editor's root node.
 */
const isElementAChildOf = (targetElement: HTMLElement, parentElement: HTMLElement): boolean => {
  let activeParent: HTMLElement | null = targetElement;
  const editorContainer = getEditorRootContainer();
  let isChild = false;
  while (activeParent && activeParent !== editorContainer) {
    if (activeParent === parentElement) {
      isChild = true;
      break;
    }
    activeParent = activeParent.parentElement;
  }

  return isChild;
};

/**
 * Replaces the custom block's meta data.
 * @param contentBlockOrKey the content block used to obtain they block's key from. Or the key if already available
 */
const updateBlockMeta = (editorState: EditorState, contentBlockOrKey: ContentBlock | string, meta: Record<string, unknown>): EditorState => {
  const entityKey = typeof contentBlockOrKey === 'string' ? contentBlockOrKey : contentBlockOrKey.getEntityAt(0);
  if (entityKey) {
    const contentState = editorState.getCurrentContent();
    contentState.mergeEntityData(entityKey, { ...meta });
    return EditorState.forceSelection(editorState, editorState.getSelection());
  }

  return editorState;
};


/**
 * @returns true if the string is a base64 encoded content
 */
const isDataURL = (str: string): boolean => {
  // Taken from: https://gist.github.com/bgrins/6194623
  const regex = /^\s*data:([a-z]+\/[a-z0-9-+.]+(;[a-z-]+=[a-z0-9-]+)?)?(;base64)?,([a-z0-9!$&',()*+;=\-._~:@/?%\s]*)\s*$/i;
  return !!str.match(regex);
};

export {
  isActiveElementAChildOf,
  updateBlockMeta,
  isElementAChildOf,
	getEditorRoot,
  getEditorRootContainer,
  isDataURL,
};
