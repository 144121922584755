import React, { useCallback, useEffect, useState } from 'react';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { GetUserTutorialsResponse, TutorialDbEntity } from '@kemu-io/kemu-types/dist/types';
import StandardModal from '../modal/standardModal';
import { AsyncRequestStatus, AsyncState } from '../../types/core_t';
import { getAllTutorials } from '../../api/tutorial/tutorialApi';
import StyledButton from '../form-control/styledButton/styledButton';
import routes from '../../common/routes';
import styles from './tutorialsListPopup.module.css';

interface Props {
	existingLinkId?: string;
	onCancel: () => void;
	onSelectedId: (tutorialId: string) => void;
}

const TitleItem = (tutorial: TutorialDbEntity): React.JSX.Element => {
	return (
		<div key={tutorial.id}>
			<Link to={routes.tutorial.getTutorialRoute(tutorial.id, 0)}>
				<h3>{tutorial.title}</h3>
			</Link>
			<span className={styles.ShortDescription}>{tutorial.description}</span>
		</div>
	);
};

const UpdatedAtItem = (tutorial: TutorialDbEntity) : React.JSX.Element => {
	const date = tutorial.updatedAt ? parseISO(tutorial.updatedAt) : new Date();
	const createdSince = formatDistanceToNow(date) + ' ago';
	return (<span key={tutorial.id} >{createdSince}</span>);
};


const TutorialsListPopup = (props: Props): React.JSX.Element | null => {
	const [tutorials, setTutorials] = useState<GetUserTutorialsResponse>([]);
	const [visible, setVisible] = useState(true);

	const [asyncOperation, setAsyncOperation] = useState<AsyncState>({
		status: AsyncRequestStatus.idle
	});

	const columns = [
		{
			title: 'Title',
			width: '50%',
			render: TitleItem
		},

		{
			title: 'Last modified',
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			// sortDirections: ['descend', 'ascend'],
			// sorter: (recordA: TutorialDbEntity, recordB: TutorialDbEntity) => new Date(recordA.updatedAt).getTime() - new Date(recordB.updatedAt).getTime(),
			render: UpdatedAtItem
		},
		{
			title: 'Actions',
			width: 100,
			dataIndex: 'id',
			render: (id: string) => {
				return (
					<div className={styles.ActionsContainer} key={id}>
						<StyledButton color="light" className={styles.SelectButton} title="Select" onClick={() => props.onSelectedId && props.onSelectedId(id)} />
					</div>
				);
			}
		}
	];


	const loadTutorials = useCallback(async () => {
		try {
			setAsyncOperation({ status: AsyncRequestStatus.loading });
			const list = await getAllTutorials();
			console.log('List: ', list);
			setTutorials(list);
			setAsyncOperation({ status: AsyncRequestStatus.completed });
		} catch (e) {
			console.log('Error fetching tutorials: ', e);
			setAsyncOperation({ status: AsyncRequestStatus.error, error: e });
		}
	}, []);


	const handleClose = () => {
		props.onCancel && props.onCancel();
	};

	useEffect(() => {
		if (asyncOperation.status === AsyncRequestStatus.idle) {
			loadTutorials();
		}
	}, [loadTutorials, asyncOperation.status]);




	return (
		<StandardModal
			onCancel={() => setVisible(false)}
			onOk={() => setVisible(false)}
			onAfterClosed={handleClose}
			okBtnLabel="Close"
			okButtonColor="primary"
			visible={visible}
			width={650}
		>
			<div className={styles.ModalContainer}>
				<Table
					rowKey="id"
					loading={{
						spinning: asyncOperation.status === AsyncRequestStatus.loading,
						indicator: <LoadingOutlined />
					}}
					columns={columns}
					dataSource={tutorials}
					scroll={{ y: 410 }}
					pagination={false}
				/>
			</div>
		</StandardModal>
	);
};

export default TutorialsListPopup;
