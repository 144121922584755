import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Button } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { TutorialState } from '../../../types/tutorial';
import { updateTutorial } from '../../../api/tutorial/tutorialApi';
// import { useDispatch } from 'react-redux';
// import { setTutorialDetails } from '../../interface/interfaceSlice';

interface Props {
	className?: string;
	autoSave?: boolean;
	tutorialId?: string;
	getTutorial?: ()=> TutorialState;
	onSaved?: ()=> void;
}

// auto save every 10 sec.
const AUTO_SAVE_INTERVAL = 10000;

const SaveTutorialButton = (props: Props): React.JSX.Element => {
	// const dispatch = useDispatch();
	const { getTutorial, tutorialId, autoSave, onSaved } = props;
	const [isSaving, setIsSaving] = useState(false);
	const timeOut = useRef<NodeJS.Timeout | null>(null);

	const onSave = useCallback(async () => {
		if (!getTutorial) { return; }
		timeOut.current && clearTimeout(timeOut.current);
		setIsSaving(true);
		const tutorial = getTutorial();
		const tutorialString = JSON.stringify(tutorial);

		if (tutorialId) {
			const storedTutorial = await updateTutorial(tutorialId, tutorial, tutorialString);
			// dispatch(setTutorialDetails({ isDraft: false, updatedAt: storedTutorial.updatedAt }));
			onSaved && onSaved();
			console.log('Stored tutorial: ', storedTutorial);
		}

		setIsSaving(false);
	}, [getTutorial, tutorialId, onSaved]);



	useEffect(() => {
		if (autoSave && !timeOut.current) {
			timeOut.current = setTimeout(onSave, AUTO_SAVE_INTERVAL);
		}

		return () => {
			if (timeOut.current) {
				clearTimeout(timeOut.current);
				timeOut.current = null;
			}
		};
	}, [autoSave, onSave]);

	return (
		<div className={props.className || ''}>
			<Button
				loading={isSaving}
				disabled={!props.tutorialId}
				type="text"
				icon={<SaveOutlined style={{ fontSize: 24 }}/>}
				onClick={onSave}
			/>
		</div>
	);
};

export default SaveTutorialButton;
