import { Point } from '@kemu-io/kemu-core/dist/types/gate_t';
import { SpeedyMatrix } from '@kemu-io/kemu-core/dist/types/external-libs/speedy-vision/core/speedy-matrix-wasm';
import { ImageWarpWidgetState } from '@kemu-io/kemu-core/dist/gates/imageWarp';
import Speedy from '@kemu-io/kemu-core/dist/types/external-libs/speedy-vision/main';
import { Vertex, WindowWithSpeedy } from '@kemu-io/kemu-core/dist/gates/imageWarp/types';
import { getAnchorLocation, getIdentityMatrix } from '@kemu-io/kemu-core/dist/gates/imageWarp/utils';


export const getSpeedyLib = (): typeof Speedy => {
  const speedyLib = (window as Partial<WindowWithSpeedy>).Speedy;
  if (!speedyLib) { throw new Error(`Speedy library not initialized`); }
  return speedyLib;
};

export const VERTEX_RADIUS = 4;
const TWO_PI = Math.PI * 2;

/**
 * @returns the scaled compensated value of the given size.
 */
const getScaledVertexRadius = (canvas: HTMLCanvasElement, size=VERTEX_RADIUS) => {
  const { rw, rh } = getScaleRatio(canvas);
  return size * Math.min(rw, rh);
};

const getScaleRatio = (canvas: HTMLCanvasElement) => {
  const rect = canvas.getBoundingClientRect();
  const ratioW = canvas.width / rect.width;
  const ratioH = canvas.height / rect.height;
  return {
    rw: ratioW,
    rh: ratioH,
  };
};

export const cursorPosition = (canvas: HTMLCanvasElement, event: MouseEvent): Point => {
	const rect = canvas.getBoundingClientRect();
	const x = event.clientX - rect.left;
	const y = event.clientY - rect.top;

  // Compensate for resized canvas via css
  const { rw, rh } = getScaleRatio(canvas);
  const correctedX = x * rw;
  const correctedY = y * rh;

	return {
		x: correctedX,
		y: correctedY,
	};
};


/**
 * Draws the state vertices
 * UPDATE: As of 16/Aug/2022, calling context.fill() after a context.putImageData() is clearing
 * out the memory thus rendering a black canvas. This does not happen when context.drawImage() is used.
 * On this day, anchors have been replaced by div elements as a workaround to this problem.
 */
export const drawVertices = (context: CanvasRenderingContext2D | null, state: ImageWarpWidgetState): void => {
	// render the vertices
	if (context) {
    const canvas = context.canvas;
		/* context.fillStyle = '#FF773E';
		context.strokeStyle = '#4e3eff';
		context.lineWidth = getScaledVertexRadius(canvas, 2); */

		context.beginPath();
		if (state.anchors.length < 4) {
      deleteCirclesFromTo(canvas, state.anchors.length, 4);
			for (let i = 0; i < state.anchors.length; i++) {
				const vertex = state.anchors[i];
        drawCircle(canvas, i, vertex.x, vertex.y);
        // context.moveTo(vertex.x + getScaledVertexRadius(canvas), vertex.y);
				// context.arc(vertex.x, vertex.y, getScaledVertexRadius(canvas), 0, TWO_PI);
			}
		} else {
			// for (const vertex of state.vertices) {
      // deleteCirclesFromTo(canvas, state.vertices.length, 4);
      deleteCirclesFromTo(canvas, 0, 4);
      context.fillStyle = '#FF773E';
      context.strokeStyle = '#4e3eff';
      context.lineWidth = getScaledVertexRadius(canvas, 2);
      for (let i = 0; i < state.vertices.length; i++) {
        const vertex = state.vertices[i];
        // drawCircle(canvas, i, vertex.x, vertex.y);
				context.moveTo(vertex.x + getScaledVertexRadius(canvas), vertex.y);
				context.arc(vertex.x, vertex.y, getScaledVertexRadius(canvas), 0, TWO_PI);
			}

      context.fill();
      context.stroke();
		}

		/* context.fill();
		context.stroke(); */
	}
};

/**
 * Paints the given image into the canvas.
 */
export const drawImageBitmap = (image: ImageBitmap | undefined, canvas: HTMLCanvasElement, context: CanvasRenderingContext2D): void => {
  if (context && image) {
    canvas.width = image.width;
    canvas.height = image.height;
    context.drawImage(image, 0, 0, image.width, image.height);
  }
};

/**
 * Returns a speedy matrix initialized with a identity matrix.
 */
export const getSpeedyIdentityMatrix = (): SpeedyMatrix => {
  const speedyLib = getSpeedyLib();
  return speedyLib.Matrix(3, 3, getIdentityMatrix());
};


export const deleteCirclesFromTo = (canvas: HTMLCanvasElement, from: number, to: number) => {
  const container = canvas.parentElement;
  if (container) {
    for (let i = from; i <= to; i++) {
      deleteCircle(canvas, i);
    }
  }
};

export const deleteCircle = (canvas: HTMLCanvasElement, index: number) => {
  const container = canvas.parentElement;
  if (container) {
    const targetClass = `.image-warp-dot.dot-${index}`;
    const target = container.querySelector<HTMLDivElement>(targetClass);
    if (target) {
      container.removeChild(target);
    }
  }
};

export const drawCircle = (canvas: HTMLCanvasElement, index: number, x: number, y: number) => {
  const radius = 10;
  const container = canvas.parentElement;

  const { rw, rh } = getScaleRatio(canvas);
	const actualX = (1/rw) * x;
	const actualY = (radius*2) + (1/rh) * y;

  if (container) {
    const targetClass = `dot-${index}`;
    const target = container.querySelector<HTMLDivElement>(`.image-warp-dot.${targetClass}`);
    if (target) {
      target.style.top = `${actualY - (radius/2)}px`;
      target.style.left = `${actualX - (radius/2)}px`;
    } else {
      const dot = document.createElement('div');
      dot.className = `image-warp-dot ${targetClass}`;
      dot.style.width = `${radius}px`;
      dot.style.height = `${radius}px`;
      dot.style.backgroundColor = '#FF773E';
      dot.style.borderWidth = '2px';
      dot.style.borderStyle = 'solid';
      dot.style.borderRadius = '50%';
      dot.style.borderColor = '#4e3eff';
      dot.style.position = 'absolute';
      dot.style.top = `${actualY - (radius/2)}px`;
      dot.style.left = `${actualX - (radius/2)}px`;
      container.appendChild(dot);
    }
  }
};

export const updateVertices = async (
  anchors: Point[],
  vertices: Vertex[],
  canvas: HTMLCanvasElement,
  mousePos: Vertex,
  lastEntries: number[],
): Promise<{ homography: SpeedyMatrix, updatedMatrix: number[] }> => {
  const speedyLib = getSpeedyLib();

  const vr = getScaledVertexRadius(canvas);
  const r2 = vr * vr;
  let anydrag = vertices.reduce((d, v) => d || v.drag, false);
  let anyhover = false;
  for (const vertex of vertices) {
    const dx = mousePos.x - vertex.x;
    const dy = mousePos.y - vertex.y;
    const nearby = (dx * dx + dy * dy <= r2);

    if (!anydrag && mousePos.drag && nearby) {
      anydrag = vertex.drag = true;
    }

    anyhover = anyhover || nearby;

    if (vertex.drag) {
      if ((vertex.drag = mousePos.drag)) {
        vertex.x = mousePos.x;
        vertex.y = mousePos.y;
      }
    }
  }
  canvas.style.cursor = anydrag || anyhover ? 'pointer' : 'auto';

  // if we're dragging the vertices,
  // compute a new homography
  if (anydrag) {
    const src = speedyLib.Matrix(2, 4, getAnchorLocation(anchors));
    const dest = speedyLib.Matrix(2, 4,
      vertices.reduce((entries, v) => entries.concat([v.x, v.y]), [] as number[])
    );

    const homography = speedyLib.Matrix.Zeros(3, 3);
    await speedyLib.Matrix.perspective(homography, src, dest);
    lastEntries = homography.read();
  }

  const homography = speedyLib.Matrix(3, 3, lastEntries.length ? lastEntries : getIdentityMatrix());

  return {
    homography,
    updatedMatrix: lastEntries,
  };
};

/**
 * Applies a perspective transform using the current anchors
 * and return the resulting vertices.
 */
 export const getTransformedVertices = async (anchors: Point[]): Promise<Vertex[]> => {
	if (anchors.length !== 4) { return []; }
  const speedyLib = getSpeedyLib();
	const homography = speedyLib.Matrix(3, 3, getIdentityMatrix());
	const src = speedyLib.Matrix(2, 4, getAnchorLocation(anchors));

	const dest = speedyLib.Matrix.Zeros(2, 4);
	await speedyLib.Matrix.applyPerspectiveTransform(dest, src, homography);

	const updatedVertices: Vertex[] = [];
	for (let i = 0; i < dest.columns; i++) {
		updatedVertices[i] = {
			x: dest.at(0, i),
			y: dest.at(1, i),
      drag: false,
		};
	}

	return updatedVertices;
};
