import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { CheckCircleFilled, CloseOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { UserEntity } from '@kemu-io/kemu-types/dist/types';
import useTranslation from '../../../../common/hooks/useTranslation';
import { VideoInfo } from '../../types';
import WelcomeMessagePopup from '../WelcomeMessagePopup/WelcomeMessagePopup';
import { UserOnboarding } from '../../../../app/reducers/user/userSlice';
import VideoPopup from './VideoPopup';
import styles from './OnboardingModal.module.css';

interface Props {
	onboardingItems: UserOnboarding;
	onCloseModal: (showLauncher?: boolean) => void;
	dispatchVideoWatched: (video: keyof UserOnboarding) => void;
	firstTimeUser: boolean;
	userProfile: UserEntity;
}

const founderMessageInfo: VideoInfo = {
	url: '',
	duration: '2',
};

// Determines the order in which videos are placed
const videos: (keyof UserOnboarding)[] = [
	'interface',
	'widgetSettings',
	'customWidgets',
	'widgetsCollection',
	'sharing'
];


const TickOrNumber = (props: { isTicked?: boolean, stepNumber: number }): React.JSX.Element => {
	return (
		<>
			{props.isTicked ? (
				<CheckCircleFilled className={styles.Icon} />
			) : (
				<span className={styles.StepNumber}>{props.stepNumber}</span>
			)}
		</>
	);
};

const OnboardingModal = (props: Props): React.JSX.Element | null => {
	const [selectedVideo, setSelectedVideo] = useState<keyof UserOnboarding | null>(null);
	const [showFounderModal, setShowFounderModal] = useState(false);
	const t = useTranslation('Onboarding.Modal');
	const { onCloseModal } = props;

	// extracts the data-name from the event's element
	const handleItemClick = (event: React.MouseEvent<HTMLElement>): void => {
		const name = event.currentTarget.getAttribute('data-name') as keyof UserOnboarding;
		// Make sure the video actually exists
		const videoIndex = videos.indexOf(name);
		if (name && videoIndex !== -1) {
			setSelectedVideo(name);
		}
	};

	const handleVideoPlayed = (): void => {
		if (selectedVideo) {
			props.dispatchVideoWatched(selectedVideo);
		}
	};

	const handleCloseFounderModal = (): void => {
		setShowFounderModal(false);
	};

	const handleModalClosed = (): void => {
		setSelectedVideo(null);
	};

	// TODO: Re-enable once founder message is available
	const handleFounderMessageWatched = (): void => {
		// props.dispatchVideoWatched('customWidgets')
	};

	// TODO: re-enable once the founder message is ready
	/* useEffect(() => {
		if(props.firstTimeUser){
			setShowFounderModal(true);
		}
	}, [props.firstTimeUser]); */

	return (
		<div className={classNames(styles.Wrapper, { invisible: selectedVideo || showFounderModal })}>
			<WelcomeMessagePopup
				visible={showFounderModal}
				url={founderMessageInfo.url}
				onModalClosed={handleCloseFounderModal}
				onVideoPlayed={handleFounderMessageWatched}
			/>

			<Modal
				visible={!!selectedVideo}
				afterClose={handleModalClosed}
				onCancel={handleModalClosed}
				destroyOnClose={true}
				centered={true}
				footer={null}
				maskClosable={true}
				bodyStyle={{ padding: 0 }}
				wrapClassName={styles.ModalWrapper}
				closable={false}
				width={'53.332vw'}
			>
				<VideoPopup
					url={selectedVideo ? t(`Checklist.${selectedVideo}.Url`, '') : undefined}
					onVideoViewed={handleVideoPlayed}
					onVideoCompleted={handleModalClosed}
					onClose={handleModalClosed}
				/>
			</Modal>

			<Button
				type="link"
				className={styles.CloseButton}
				icon={<CloseOutlined />}
				onClick={() => onCloseModal()}
			/>

			<div className={styles.HeaderBackground} />
			<div className={styles.Header}>
				<h1 className={styles.Title}>{t('Title', 'Hi {userName}!', { userName: props.userProfile.firstName })}</h1>
				<span className={styles.Subtitle}>{t('Subtitle', 'Let\'s get you set up.')}</span>
			</div>


			<div className={styles.ContentWrapper}>
				<h3>{t('ChecklistTitle', 'Getting started with Kemu is easy')}</h3>
				<div className={styles.ChecklistItems}>

					{videos.map((videoName, index) => {
						const videoKey = videoName as keyof UserOnboarding;
						const videoIndex = index+1;
						return (
							<div className={styles.TickMarkItem} data-name={videoName} onClick={handleItemClick} key={videoKey}>
								<TickOrNumber isTicked={props.onboardingItems[videoKey]} stepNumber={videoIndex} />
								<div className={styles.TextContainer}>
									<div className={styles.ItemTitle}>
										{t(`Checklist.${videoKey}.Title`, `${videoKey}`)}
									</div>
									<div className={styles.ItemSubtitle}>
										{t(`Checklist.${videoKey}.Duration`, `${videoKey}`)} {t('Checklist.DurationSuffix', 'min')}
										</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>

		</div>
	);
};

export default OnboardingModal;
