import React, { CSSProperties, FC, useState, useCallback, useEffect } from 'react';
// @ts-expect-error no typings available for DraftOffsetKey
import DraftOffsetKey from 'draft-js/lib/DraftOffsetKey';

import { SideToolbarPluginTheme } from '../theme';
import { SideToolbarPluginStore } from '../index';
import BlockTypeSelect, { BlockTypeSelectChildProps } from './blockTypeSelect';

export type SideToolbarChildrenProps = BlockTypeSelectChildProps;

interface ToolbarProps {
  children?: FC<SideToolbarChildrenProps>;
  store: SideToolbarPluginStore;
  theme: SideToolbarPluginTheme;
}

const Toolbar = (props: ToolbarProps): React.JSX.Element => {
	const { store, children, theme } =  props;
	const [state, setState] = useState<CSSProperties>({
    transform: 'scale(0)',
    visibility: 'hidden'
	});


  const calculatePosition = useCallback(() => {
    const getEditorState = store.getItem('getEditorState');
    if (!getEditorState) { return; }
    const editorState = getEditorState();
    const selection = editorState.getSelection();
    if (!selection?.getHasFocus()) {
			// Hide body when the editor is not in focus
			// FIXME: Restore this
      // setState({
			// 	transform: 'scale(0)',
      // });
      return;
    }

    const currentContent = editorState.getCurrentContent();
    const currentBlock = currentContent.getBlockForKey(selection.getStartKey());
    // TODO verify that always a key-0-0 exists
    const offsetKey = DraftOffsetKey.encode(currentBlock.getKey(), 0, 0);
    // Note: need to wait on tick to make sure the DOM node has been create by Draft.js

    const node = document.querySelectorAll<HTMLDivElement>(
      `[data-offset-key="${offsetKey}"]`
    )[0];

    if (!node) { return; }
    // The editor root should be two levels above the node from
    // `getEditorRef`. In case this changes in the future, we
    // attempt to find the node dynamically by traversing upwards.
    const getEditorRef = store.getItem('getEditorRef');
    if (!getEditorRef) { return; }
    const editorRef = getEditorRef();

    // this keeps backwards-compatibility with react 15
    let editorRoot =
      editorRef.refs && editorRef.refs.editor
        ? editorRef.refs.editor
        : editorRef.editor;
    while (editorRoot.className.indexOf('DraftEditor-root') === -1) {
      editorRoot = editorRoot.parentNode as HTMLElement;
    }

    const position: CSSProperties = {
      top: node.offsetTop + editorRoot.offsetTop,
      visibility: 'visible',
      transform: 'scale(1)',
      transition: 'transform 0.15s cubic-bezier(.3,1.2,.2,1)',
      left: editorRoot.offsetLeft - 80
    };

    setState(position);
  }, [store]);


	const onEditorStateChange = useCallback((/* editorState?: EditorState */) => {
    setTimeout(() => {
      calculatePosition();
    }, 5);
	}, [calculatePosition]);


	useEffect(() => {
		store.subscribeToItem('editorState', onEditorStateChange);
		return () => {
			store.unsubscribeFromItem('editorState', onEditorStateChange);
		};
	}, [store, onEditorStateChange]);


	return (
		<div className={theme.toolbarStyles?.wrapper} style={state}>
			<BlockTypeSelect
        // refreshPosition={calculatePosition}
				getEditorState={store.getItem('getEditorState')!}
				setEditorState={store.getItem('setEditorState')!}
				theme={theme}
				childNodes={children!}
			/>
		</div>
	);
};

export default Toolbar;
