import React from 'react';
import { IntlProvider } from 'react-intl';

import enMessages from './messages/en';
import esMessages from './messages/es';

const allMessages = {
  en: enMessages,
  es: esMessages,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function I18nProvider( props ) {
  const locale = props.language; // useSelector(({ i18n }) => i18n.lang);
  const messages = allMessages[locale];

  return (
      <IntlProvider locale={locale} messages={messages}>
        {props.children}
      </IntlProvider>
  );
}
