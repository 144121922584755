import { Position } from '@kemu-io/kemu-core/dist/types/block_t';
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { LogicMapperState } from '../logicMapperSlice';
import { WidgetCollectionItem } from '@src/app/reducers/widget/widgetSlice';
import { StatelessRecipeWidget, StatelessWidgetsMap } from '@src/types/core_t';
import * as RecipeActions from '@src/app/recipe/utils';
import { safeJsonClone } from '@common/utils';

type AddWidgetFromTemplateProps = {
  widget: WidgetCollectionItem,
  recipeId: string,
  thingId: string,
  currentGroupId?: string,
  position?: Position,
}

/**
 * Adds custom widgets and widget bundles to the current recipe
 * from a template. 
 * 
 * NOTE: For bundles, this handles widgets already installed, not
 * newly created widgets, for that checkout `addWidgetBundleFromZip`.
 */
const addWidgetFromTemplateAction = createAsyncThunk(
  '/LogicMapper/addWidgetFromTemplate', async (
  payload: AddWidgetFromTemplateProps
) : Promise<StatelessWidgetsMap> => {

	const widgetsMap = await RecipeActions.addCustomWidgetFromTemplate(
		payload.widget,
		payload.recipeId,
		payload.thingId,
		payload.position,
		payload.currentGroupId
	);

	const stateLessWidgets: StatelessWidgetsMap = {};
	Object.values(widgetsMap).map((widget) => {
		const { state: _state, ...stateLess } = widget;
		stateLessWidgets[widget.id] = stateLess as StatelessRecipeWidget;
	});

  const updatedWidgets = safeJsonClone(stateLessWidgets);
  return updatedWidgets;
});


const addWidgetFromTemplateReducer = ((builder: ActionReducerMapBuilder<LogicMapperState>): void => {
  builder.addCase(addWidgetFromTemplateAction.fulfilled, (state, action) => {
    state.gates = action.payload;
  });

  builder.addCase(addWidgetFromTemplateAction.rejected, (_state, action) => {
    console.error('Failed to add widget from template: ', action.error);
  });
});

export default {
  action: addWidgetFromTemplateAction,
  reducer: addWidgetFromTemplateReducer,
};
