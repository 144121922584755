import React, { ComponentType, useState, useRef, useEffect } from 'react';
import { EditorPlugin } from '@draft-js-plugins/editor';
import Icon, { CheckOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { SideToolbarChildrenProps } from '../sideToolbar/components/toolbar';
import { ReactComponent as VideoIcon } from '../../../../assets/img/tutorialBuilder/video.svg';
import styles from './video.module.css';
import { addVideo, VIDEO_ENTITY_TYPE } from './utils';
import VideoComponent, { VideoComponentProps } from './videoComponent';

export type VideoPluginTheme = {
	IframeContainer: string;
	Iframe: string;
	Video: string;
	EditButton: string;
}

export type VideoPlugin = EditorPlugin & {
  VideoButton: ComponentType<SideToolbarChildrenProps>;
}

export interface VideoPluginConfig {
  theme?: VideoPluginTheme;
  // videoComponent?: ComponentType<VideoComponentProps>;
  decorator?(
    component: ComponentType<VideoComponentProps>
  ): ComponentType<VideoComponentProps>;
}

const isValidURL = (url: string): boolean => {
	const res = url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g);
	return (res !== null);
};

const EnterUrlPopup = (props: SideToolbarChildrenProps): React.JSX.Element => {
	const [state, setState] = useState({
		url: '',
		width: 320,
		height: 240
	});


	const inputRef = useRef<HTMLInputElement | null>(null);
	const intl = useIntl();

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const targetKey = event.currentTarget.name;
		const value = event.currentTarget.value.trim();
		setState(s => ({ ...s, [targetKey]: value }));
	};

	const onAddVideo = () => {
		const newState = addVideo(props.getEditorState(), state.url, state.width, state.height);
		props.setEditorState(newState);
		props.hideToolbar();
	};


	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, [inputRef]);


	return (
		<div className={styles.Wrapper}>
			<div className={styles.Controls}>
				<input className={styles.UrlInput} type="text" name="url" value={state.url} onChange={handleChange} placeholder={intl.formatMessage({ id: 'TutorialBuilder.Plugin.Video.UrlPlaceholder', defaultMessage: 'Paste a YouTube, Vimeo or video link' })} />
				<input className={styles.DimensionInput} type="text" name="width" value={state.width} onChange={handleChange} placeholder={intl.formatMessage({ id: 'TutorialBuilder.Plugin.Video.Popup.Width', defaultMessage: 'width' })} />
				<input className={styles.DimensionInput} type="text" name="height" value={state.height} onChange={handleChange} placeholder={intl.formatMessage({ id: 'TutorialBuilder.Plugin.Video.Popup.Height', defaultMessage: 'height' })} />
				<button onClick={onAddVideo} disabled={!state.url.length || !isValidURL(state.url)}><CheckOutlined /></button>
			</div>
		</div>
	);
};

const VideoButton = (props: SideToolbarChildrenProps): React.JSX.Element => {

	const onAddVideo = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		event.stopPropagation();
		props.onOverrideContent(EnterUrlPopup);
	};

	return (
    <div className={props.theme.buttonWrapper}>
      <button className={props.theme.button} onMouseDown={onAddVideo}>
				<Icon component={VideoIcon} style={{ fontSize: 24 }} />
      </button>
    </div>
  );
};

const createVideoPlugin = (config: VideoPluginConfig = {}): VideoPlugin => {
	const Video = config.decorator ? config.decorator(VideoComponent) : VideoComponent;
	const theme = { ...styles as VideoPluginTheme };

  const ThemedVideo = (props: VideoComponentProps): React.JSX.Element => (
    <Video {...props} theme={theme} />
	);

  return {
		VideoButton,
		blockRendererFn: (block, { getEditorState, setEditorState, setReadOnly, getReadOnly }) => {
      if (block.getType() === 'atomic') {
        // TODO subject to change for draft-js next release
        const contentState = getEditorState().getCurrentContent();
        const entityKey = block.getEntityAt(0);
        if (!entityKey) { return null; }
        const entity = contentState.getEntity(entityKey);
        const type = entity.getType();
        if (type === VIDEO_ENTITY_TYPE) {
					const data = entity.getData();
          return {
            component: ThemedVideo,
            editable: false,
            props: {
							...data,
							setReadOnly,
							getReadOnly,
							setEditorState,
							getEditorState
            },
          };
        }
      }

      return null;
    },
  };
};

export default createVideoPlugin;
