import { nanoid } from 'nanoid';
import {
	KemuAnnotation,
	AnnotationPosition,
	AnnotationDataTypes,
} from '@kemu-io/kemu-core/dist/types/annotation_t';
import { findRecipe } from '@kemu-io/kemu-core/dist/common/recipeCache';


/**
 * @returns All the annotations in the recipe
 */
export const getRecipeAnnotations = (id: string): Record<string, KemuAnnotation> => {
	const recipe = findRecipe(id);
	if (!recipe) { throw new Error(`Recipe [${id} does not exist]`); }
	if (!recipe.canvas.annotations) { recipe.canvas.annotations = {}; }
	return recipe.canvas.annotations;
};

const _getAnnotationById = <T extends AnnotationDataTypes>(recipeId: string, id: string): KemuAnnotation<T> => {
	const recipeAnnotations = getRecipeAnnotations(recipeId);
	if (!recipeAnnotations[id]) { throw new Error(`Annotation [${id} not found]`); }
	const annotation = recipeAnnotations[id] as KemuAnnotation<T>;
	return annotation;
};

/**
 * Adds a new annotation object to the given recipe
 * @param recipeId the id of the recipe in the pool
 * @param annotation the new annotation to add
 */
export const addNewAnnotation = <T extends AnnotationDataTypes>(recipeId: string, annotation: Omit<KemuAnnotation<T>, 'id'>): KemuAnnotation<T> => {
	const recipeAnnotations = getRecipeAnnotations(recipeId);
	const id = `${recipeId}_${nanoid(10)}`;
	// @ts-expect-error FIXME: please fix this
	recipeAnnotations[id] = {
		...annotation,
		thingId: annotation.thingId,
		id,
	};

	// @ts-expect-error FIXME: please fix this
	return recipeAnnotations[id];
};

/**
 * Changes the position of the annotation
 * @returns the updated annotation object
 */
export const updateAnnotationPosition = <T extends AnnotationDataTypes>(recipeId: string, annotationId: string, position: AnnotationPosition): KemuAnnotation<T> => {
	const annotation = _getAnnotationById<T>(recipeId, annotationId);
	annotation.position = position;
	return annotation;
};

/**
 * Updates the annotation metadata
 * @returns the updated annotation object
 */
export const updateAnnotationData = <T extends AnnotationDataTypes>(recipeId: string, annotationId: string, data: T): KemuAnnotation<T> => {
	const annotation = _getAnnotationById<T>(recipeId, annotationId);
	annotation.data = {
		...annotation.data,
		...data,
	} as T;

	return annotation;
};

/**
 * Removes the given annotation id from the recipe
 * @returns an updated map of the remaining annotations
 */
export const removeAnnotation = (recipeId: string, annotationId: string): Record<string, KemuAnnotation> => {
	const recipeAnnotations = getRecipeAnnotations(recipeId);
	delete recipeAnnotations[annotationId];
	return recipeAnnotations;
};
