import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { posthog } from 'posthog-js';
import gatesManager from '@kemu-io/kemu-core/dist/gates/manager';
import { Route, Redirect, useHistory, Switch } from 'react-router-dom';
import CustomWidgetModal from '../LogicMapper/customWidgetModal/customWidgetModal';
import ViewRecipe from '../directView/recipe/ViewRecipe';
import EnvironmentModal from '../EnvironmentModal/EnvironmentModal';
import BrowserRecipeScreen from '../BrowserRecipeScreen/BrowserRecipeScreen';
import WidgetBundleModal from '../LogicMapper/WidgetBundleModal/WidgetBundleModal';
import { selectBaseRecipeLoaded } from '../Workspace/workspaceSlice';
import MarketplaceLayout from '@components/layout/marketplace/marketplace';
import RecipeDownloadIndicator from '@components/recipeDownloadIndicator/recipeDownloadIndicator';
import RecipeUploadProgress from '@components/recipeUploadProgress/recipeUploadProgress';
import SaveRecipeIndicator from '@components/saveRecipeIndicator/saveRecipeIndicator';
import { selectSignedUserProfile } from '@src/app/reducers/user/userSlice';
import globals from '@common/globals';
import VersionAvailablePopup from '@components/versionAvailablePopup/VersionAvailablePopup';
import { initFreshChatWidget } from '@src/app/recipe/freshChatWidget';
import { initFreshDeskWidget } from '@src/app/recipe/freshDeskWidget';
import WidgetAsyncIndicator from '@components/widgetAsyncIndicator/widgetAsyncIndicator';
import widgetCache from '@src/app/reducers/widget/widgetCache';
import routes from '@common/routes';
import { fetchUserProfileAction } from '@src/app/reducers/user/fetchUserReducer';
import helpHero from '@common/helpHero/helpHero';
import { UserSessionContext } from '@common/context/UserSessionContext/UserSessionContext';
// import { getUserbackInstance } from '@common/userback/userback';
import SaveRecipeModal from '@components/SaveRecipeModal/SaveRecipeModal';
// import CloudRecipeScreen from '../CloudRecipeScreen/CloudRecipeScreen';
import { installDefaultThingsAction } from '@src/app/reducers/thing/installDefaultThingsReducer';
import ThingInstallationNotification from '@components/ThingInstallationNotification/ThingInstallationNotification';
// import useWebsocketConnection from '@hooks/useWebsocketConnection';
// import WebsocketConnector from '../WebsocketConnector/WebsocketConnector';

type ReducedSessionInfo = {
	attributes: Record<string, string>;
	state: string;
}


export const Kemu = (): React.JSX.Element => {
	const dispatch = useDispatch();
	const session = useContext(UserSessionContext);
	const userProfile = useSelector(selectSignedUserProfile);
	const history = useHistory();
	const [environmentReady, setEnvironmentReady] = useState(false);
	// Create initial connection to websocket, any component using this hook
	// will re-use the same connection
	// const { readyState } = useWebsocketConnection();
	// TODO: make this dynamic depending on user locale
	initFreshDeskWidget('en');

	const freshChatWidget = initFreshChatWidget();
	// Convert the data we use to a primitive to prevent re-rendering this component when the session object changes
	const userInfoStr = JSON.stringify({
		attributes: session.user?.attributes,
		state: session.state,
	} as ReducedSessionInfo);


	// Loads a sample recipe when the page is loaded for the first time
  const prepareEnvironment = useCallback(async () => {
		// Downloads a list of default things
		dispatch(installDefaultThingsAction());
    // Update title based on current language
    await gatesManager.loadGates();
		// Initialize the widgets cache;
		if (!widgetCache.isInitialized()) {
			await widgetCache.initialize();
		}

		setEnvironmentReady(true);
  }, [dispatch]);

  useEffect(() => {
		// Account setup not completed yet, redirect to config
		const userInfo = JSON.parse(userInfoStr) as ReducedSessionInfo;
		if (userInfo.state === 'LoggedIn' && userInfo.attributes) {
			// Fetch user profile
			dispatch(fetchUserProfileAction());

			if (!userInfo.attributes['custom:organization']) {
				history.push(routes.auth.getAccountSelectionWithUserRoute(userInfo.attributes['sub']));
			} else {
				prepareEnvironment();
			}
		}
	}, [prepareEnvironment, userInfoStr, history, dispatch]);


	// Sets the user info in the chat widget and posthog
	useEffect(() => {
		if (userProfile.profile) {
			const helphero = helpHero.initialize(globals.HELP_HERO_APP_ID);

			helphero.identify(userProfile.profile.id, {
				id: userProfile.profile.id,
				firstName: userProfile.profile.firstName,
				lastName: userProfile.profile.lastName,
				email: userProfile.profile.email,
				plan: userProfile.profile.organizationId ? 'organization' : 'individual'
			});

			posthog.init(
				globals.POSTHOG_PROJECT_API_TOKEN,
				{
					api_host: 'https://app.posthog.com',
					autocapture: false,
					'opt_in_site_apps': true,
				}
			);

			posthog.identify(userProfile.profile.id, {
				id: userProfile.profile.id,
				firstName: userProfile.profile.firstName,
				lastName: userProfile.profile.lastName,
				email: userProfile.profile.email,
				plan: userProfile.profile.organizationId ? 'organization' : 'individual',
				webAppVersion: globals.WEB_APP_VERSION,
			});

			// Set userback user info
			/* const userBackInstance = getUserbackInstance();
			if (userBackInstance) {
				userBackInstance.email = userProfile.profile.email as string;
				userBackInstance.name = `${userProfile.profile.firstName} ${userProfile.profile.lastName}`;
				// NOTE: Requires 'Company' or 'Premium' plan. Not currently working.
				userBackInstance.identify(userProfile.profile.id, {
					name: `${userProfile.profile.firstName} ${userProfile.profile.lastName}`,
					firstName: userProfile.profile.firstName,
					lastName: userProfile.profile.lastName,
					loginId: userProfile.profile.email || userProfile.profile.loginId,
					plan: userProfile.profile.organizationId ? 'organization' : 'individual'
				});
			} */

			if (freshChatWidget.initialized) {
				freshChatWidget.setUserInfo({
					id: userProfile.profile.id,
					firstName: userProfile.profile.firstName,
					lastName: userProfile.profile.lastName,
					email: userProfile.profile.email,
					plan: userProfile.profile.organizationId ? 'organization' : 'individual'
				});

				freshChatWidget.show();
			}
		}
	}, [freshChatWidget.initialized, freshChatWidget, userProfile.profile]);

	// console.log('ReadyState', readyState);
	return (
		<>
			{environmentReady && session.state === 'LoggedIn' && session.user?.attributes['custom:organization'] ? (
				<>
					<VersionAvailablePopup />
					<SaveRecipeIndicator />
					<RecipeUploadProgress />
					<RecipeDownloadIndicator />
					<WidgetAsyncIndicator />
					<CustomWidgetModal />
					<WidgetBundleModal />
					<SaveRecipeModal />
					<ThingInstallationNotification />
					{/* <WebsocketConnector /> */}

					<Switch>
						<Route path={routes.marketplace.getMarketplaceRoute()} component={MarketplaceLayout} />
						<Route path={routes.recipe.getDirectRecipeRoute()} component={ViewRecipe} />
						<Route path={routes.recipe.getBrowserRecipeRoute()} component={BrowserRecipeScreen} />
						{/* <Route path={routes.recipe.getCloudRecipeRoute()} component={CloudRecipeScreen} /> */}
						<Route path={routes.getBaseRoute()} component={EnvironmentModal} />
					</Switch>
				</>
			) : session.state === 'NotLoggedIn' && (
				// Only redirect once we are SURE the user is not logged in
				<Redirect to={routes.auth.getSignInRoute()} />
			)}

		</>
	);
};
