import React from 'react';
import classNames from 'classnames';
import useMarkedText from '../../common/hooks/useMarkedText';
import styles from './MarkedText.module.css';

interface Props {
	/** unparsed text */
	text: string;
	/** if true, it won't override the default line-height and marking properties of <p> elements */
	preserveParagraphStyle?: boolean;
}

/**
 * Renders a text with markdown syntax.
 * The text is automatically sanitized, parsed and rendered.
 */
const MarkedText = (props: Props): React.JSX.Element => {
	const convertToHtml = useMarkedText();
	return (
		<div
			className={classNames(styles.MarkedContainer, { ['use-default-p']: props.preserveParagraphStyle })}
			dangerouslySetInnerHTML={{ __html: convertToHtml(props.text || '') }}
		/>
	);
};

export default MarkedText;
