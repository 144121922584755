import React, { ComponentType, ReactElement, FC } from 'react';
import { EditorState } from 'draft-js';
import { createStore, Store } from '@draft-js-plugins/utils';
import { EditorPlugin } from '@draft-js-plugins/editor';
import Toolbar, { SideToolbarChildrenProps } from './components/toolbar';
// import { SideToolbarPluginTheme } from './theme';

import toolbarStyles from './components/toolbar.module.css';
import buttonStyles from './components/button.module.css';
import blockTypeSelectStyles from './components/blockTypeSelect.module.css';

// export interface SideToolbarPluginConfig {
//   theme?: SideToolbarPluginTheme;
// }

export interface SideToolbarProps {
  children?: FC<SideToolbarChildrenProps>;
}

export type SideToolbarPlugin = EditorPlugin & {
  SideToolbar: ComponentType<SideToolbarProps>;
};


interface StoreItemMap {
  isVisible?: boolean;
  getEditorState?(): EditorState;
  setEditorState?(state: EditorState): void;
  editorState?: EditorState;
  getEditorRef?(): {
    refs?: { editor: HTMLElement };
    editor: HTMLElement;
  };
}

export type SideToolbarPluginStore = Store<StoreItemMap>;

export default (): SideToolbarPlugin => {

  const store = createStore<StoreItemMap>({
    isVisible: false,
  });

  const SideToolbar = (props: SideToolbarProps): ReactElement => (
    <Toolbar {...props} store={store} theme={{
			blockTypeSelectStyles,
			buttonStyles,
			toolbarStyles
		}} />
  );

  return {
    initialize: ({ setEditorState, getEditorState, getEditorRef }) => {
      store.updateItem('getEditorState', getEditorState);
      store.updateItem('setEditorState', setEditorState);
      store.updateItem('getEditorRef', getEditorRef);
    },
    // Re-Render the toolbar on every change
    onChange: (editorState) => {
      store.updateItem('editorState', editorState);
      return editorState;
    },
    SideToolbar,
  };
};
