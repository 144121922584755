/*
 * Written by Alexander Agudelo < alex.agudelo@kitsunei.com >, 2020
 * Date: 06/Dec/2020
 * Last Modified: 17/07/2023, 4:36:40 pm
 * Modified By: Alexander Agudelo
 * Description:  State of blocks might happen outside the store (Kemu-core), this cook
 * uses Kemu's event listener subscriptions to notify the component the state has changed
 * 
 * ------
 * Copyright (C) 2020 Kitsunei - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential.
 */
import { useCallback, useEffect, useState } from 'react';
import { StateChangeEvent } from '@kemu-io/kemu-core/dist/blocks/logicProcessor';
import { ThingState } from '@kemu-io/kemu-core/dist/types/block_t';
import KemuCore from '@kemu-io/kemu-core/dist/index';
import { getBlockState } from '../../app/recipe/utils';

type BlockStateResponse = [currentState: ThingState, setState: (newState: ThingState, triggerEvents?: boolean) => void];



/**
 * Keeps track of the state of the block
 * @param recipeId the id of the recipe in the pool
 * @param blockId th id of the block in the recipe (recipeId)
 */
function useBlockState (recipeId: string | null, blockId: string | null): BlockStateResponse {

	const [state, setState] = useState<ThingState | null>(() => {
		if (recipeId && blockId) {
			return getBlockState(blockId, recipeId);
		}

		return null;
	});

	const onBlockStateChanged = useCallback((evt: StateChangeEvent) => {
		if (evt.recipeId === recipeId && evt.blockId === blockId) {
			setState(evt.newState);
		}
	}, [recipeId, blockId]);


	const setBlockState = useCallback((newState: ThingState, triggerEvents=true): void => {
		if (recipeId && blockId) {
			KemuCore.setBlockState(recipeId, blockId, newState, triggerEvents);
		}
	}, [recipeId, blockId]);


	useEffect(() => {
		if (blockId && recipeId) {
			// The state was not set during mounting
			setState(getBlockState(blockId, recipeId));
		}

		if (blockId) {
			console.log('useBlockState => Creating Kemu listener');
			KemuCore.onBlockStateChanged(onBlockStateChanged, blockId);
		}

		return () => {
			if (blockId) {
				console.log('useBlockState => Removing Kemu listener');
				KemuCore.offBlockStateChanged(onBlockStateChanged, blockId);
			}
		};
	}, [recipeId, blockId, onBlockStateChanged]);

	return [state || {}, setBlockState];
}


export default useBlockState;
