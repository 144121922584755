import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, List, Space } from 'antd';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import { FileAddOutlined, DeleteOutlined } from '@ant-design/icons';
import widgetBundleManager, { ValidatedBundle } from '@kemu-io/kemu-core/dist/widgetBundle/manager';
import byteSize from 'byte-size';
import {
	addWidgetBundleFromZipAction,
	selectShowCreateWidgetBundleModal,
	showCreateWidgetBundleModalAction,
  updateTempWidgetBundleAction,
} from '../logicMapperSlice';
import { WidgetBundleStateWithId } from '../reducers/showCreateWidgetBundleModal';
import styles from './WidgetBundleModal.module.css';
import FileDropZone from './FileDropZone/FileDropZone';
import { getBundleColors, getDefaultBundleColor, getDefaultBundleSvg } from './defaults';
import { selectedBlock, selectVisibleGroup } from '@src/features/Workspace/workspaceSlice';
import useFilePicker from '@hooks/useFilePicker';
import useTranslation from '@common/hooks/useTranslation';
import useAlert from '@components/alert/useAlert';
import FormGroup from '@components/form-control/formGroup/formGroup';
import StyledTextArea from '@components/form-control/styledTextArea/styledTextArea';
import StyledInput from '@components/form-control/styledInput/styledInput';
import StandardModal from '@components/modal/standardModal';
import StyledLabel from '@components/form-control/styledLabel/styledLabel';
import ColorSwatch from '@components/colorSwatch/colorSwatch';
import StyledButton from '@components/form-control/styledButton/styledButton';
import SvgFromString from '@components/SvgFromString/SvgFromString';
import TruncatedText from '@components/truncatedText/truncatedText';
import Logger from '@common/logger';
import {
  saveBundleInCollectionAction,
  updateBundleInCollectionAction
} from '@src/app/reducers/widget/widgetSlice';

// const defaultInput: GroupWidgetPort = { index: 0, label: 'input1', name: 'input1', type: [DataType.Anything] };
// const defaultOutput: GroupWidgetPort = { index: 0, label: 'output1', name: 'output1', type: [DataType.Anything] };
const defaultColor = getDefaultBundleColor();
const defaultColors = getBundleColors();
const defaultIcon = getDefaultBundleSvg();

const getHumanSize = (bytes: number) => {
  const size = byteSize(bytes || 0);
  return `${size.value} ${size.unit}`;
};

const logger = Logger('WidgetBundleModal');

const WidgetBundleModal = (): React.JSX.Element => {
	const t = useTranslation('Interface.Modals.CreateWidgetBundle');
  const { openFileSelector } = useFilePicker({ accept: '*', multiple: true });
	const showModal = useSelector(selectShowCreateWidgetBundleModal);
	const existingWidgetInfo = (showModal as Partial<WidgetBundleStateWithId>) || null;
	const currentThingInfo = useSelector(selectedBlock);
	const groupPath = useSelector(selectVisibleGroup);
	const [missingName, setMissingName] = useState(existingWidgetInfo?.name ? false : true);
  const [changesMade, setChangesMade] = useState(false);
	const dispatch = useDispatch();
  const resetNameTmrRef = useRef<NodeJS.Timeout | null>(null);
	const [color, setColor] = useState<string>(existingWidgetInfo?.color || defaultColor);
	const descriptionRef = useRef<TextAreaRef | null>(null);
	const nameRef = useRef<HTMLInputElement | null>(null);
	const isSaving = !!showModal;
	const currentWidgetId = existingWidgetInfo?.widgetId;
  const [currentBundleFile, setCurrentBundleFile] = useState<(Omit<ValidatedBundle, 'size'> & {
    humanSize: string,
    icon: string,
    zipFile?: Uint8Array
  }) | null>(null);

  const alert = useAlert();
  // const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const handleCancel = useCallback(() => {
		dispatch(showCreateWidgetBundleModalAction(false));
    setCurrentBundleFile(null);
    setColor(defaultColor);
    nameRef.current = null;
    descriptionRef.current = null;
	}, [dispatch]);

  const handleRemoveAsset = useCallback(async (assetPath: string) => {
    if (currentBundleFile) {
      const updatedFile = await widgetBundleManager.removeAssetsFromZip(
        currentBundleFile.zipHandler,
        [assetPath],
        { noGenerateFile: true }
      );

      const size = getHumanSize(updatedFile.size);
      setChangesMade(true);
      setCurrentBundleFile({
        ...currentBundleFile,
        assetsList: updatedFile.assetsList,
        zipHandler: updatedFile.zipHandler,
        humanSize: size,
      });
    }
  }, [currentBundleFile]);

  const handleAddFileToAssets = useCallback(async () => {
    if (currentBundleFile) {
      const { files } = await openFileSelector();
      const filesAndTargetPaths = files.map((file) => ({
        data: file.data,
        path: file.name,
      }));

      const updatedBundle = await widgetBundleManager.addAssetsToZip(
        currentBundleFile.zipHandler,
        filesAndTargetPaths,
        { noGenerateFile: true }
      );

      const size = getHumanSize(updatedBundle.size);
      setChangesMade(true);
      setCurrentBundleFile({
        ...currentBundleFile,
        assetsList: updatedBundle.assetsList,
        zipHandler: updatedBundle.zipHandler,
        humanSize: size,
      });
    }
  }, [currentBundleFile, openFileSelector]);

  /**
   * Called when a new zip file is added
   */
  const processZipBundle = useCallback(async (data: Uint8Array) => {
    // TODO: Process zip file, and check if it is a valid bundle
    logger.log('Validating bundle file...');
    const bundleData = await widgetBundleManager.validateBundleFile(data);
    logger.log('Bundle data', bundleData);

    if (!bundleData.hasProcessor) {
      alert.error({
        title: t('InvalidBundle.Title', 'Invalid File'),
        content: t('InvalidBundle.MissingProcessor', 'Missing processor.'),
        okText: t('InvalidBundle.AlertClose', 'Close'),
      });

      return;
    }

    setCurrentBundleFile({
      assetsList: bundleData.assetsList,
      hasProcessor: bundleData.hasProcessor,
      zipFile: data,
      icon: bundleData.icon || defaultIcon,
      zipHandler: bundleData.zipHandler,
      humanSize: getHumanSize(data.length),
    });

    setChangesMade(true);
  }, [t, alert]);

	// const warnIfInputAttached = (port: LastPortInfo | null, minIndex: number): boolean => {
	// 	// Prevent adding ports if trigger is linked (otherwise new ports will obtain the connection from trigger)
	// 	if (port && port.index >= minIndex) {
	// 		const isInner = port.type === 'innerInput' || port.type === 'innerOutput';
	// 		message.warning({
	// 			content: t('RemoveWarning', 'Please remove all connections from {inner}port "{name}"',
	// 			{
	// 				name: port.label || port.portName,
	// 				inner: isInner ? 'inner ' : ''
	// 			})
	// 		});
	// 		return true;
	// 	}

	// 	return false;
	// };

	const handleSaveCollection = useCallback(async () => {
    // IMPORTANT: this should NOT be allowed if there were changes after the
    // widget is saved in the current recipe.
    if (currentWidgetId && currentThingInfo && currentBundleFile && nameRef.current && descriptionRef.current?.resizableTextArea) {
      logger.log('Saving collection...');
      const name = nameRef.current?.value;
			const description = descriptionRef.current.resizableTextArea.textArea.value;
      const icon = currentBundleFile?.icon || defaultIcon;

      const isUpdate = existingWidgetInfo?.$$collectionInfo?.widgetId;
      if (isUpdate) {
        dispatch(updateBundleInCollectionAction({
          name,
          description,
          color,
          icon,
          recipePoolId: currentThingInfo.recipePoolId,
          thingId: currentThingInfo.recipeId,
          widgetCacheId: currentWidgetId,
          zipHandler: currentBundleFile.zipHandler,
        }));
      } else {
        dispatch(saveBundleInCollectionAction({
          name,
          description,
          color,
          icon,
          recipePoolId: currentThingInfo.recipePoolId,
          thingId: currentThingInfo.recipeId,
          widgetThingId: currentWidgetId,
          zipHandler: currentBundleFile.zipHandler,
        }));
      }

    }
  }, [
    currentThingInfo, currentWidgetId, dispatch,
    currentBundleFile, color, existingWidgetInfo?.$$collectionInfo?.widgetId
  ]);

	const handleSaveLocalVersion = useCallback(async () => {
    logger.log(`${currentWidgetId ? 'Updating' : 'Creating'} widget bundle...`);
		if (nameRef.current && descriptionRef.current && descriptionRef.current.resizableTextArea && currentThingInfo) {
			const name = nameRef.current.value;
			const description = descriptionRef.current.resizableTextArea.textArea.value;
      if (currentBundleFile) {
        const icon = currentBundleFile?.icon || defaultIcon;
        if (currentWidgetId) {
          // Update Existing Bundle
          dispatch(updateTempWidgetBundleAction({
            color,
            description,
            name,
            icon,
            widgetId: currentWidgetId,
            recipeId: currentThingInfo.recipePoolId,
            thingId: currentThingInfo.recipeId,
            zipHandler: currentBundleFile.zipHandler,
          }));
        } else {
          // Re-build zip file
          const zipFile = await widgetBundleManager.generateZipFromHandler(currentBundleFile.zipHandler);
          // Save new widget
          dispatch(addWidgetBundleFromZipAction({
            color,
            description,
            name,
            icon,
            parentGroup: groupPath?.groupId,
            recipeId: currentThingInfo.recipePoolId,
            thingId: currentThingInfo.recipeId,
            zipFile: zipFile,
          }));
        }
      }
		}
	}, [
		dispatch, color, currentThingInfo,
		groupPath,
		currentWidgetId,
    currentBundleFile,
	]);

	const handleNameChange = useCallback(() => {
    if (resetNameTmrRef.current) {
      clearTimeout(resetNameTmrRef.current);
    }

    setChangesMade(existingWidgetInfo?.name !== nameRef.current?.value);
    resetNameTmrRef.current = setTimeout(() => {
      if (nameRef.current?.value.trim() === '') {
        setMissingName(true);
      } else {
        setMissingName(false);
      }
    }, 200);
	}, [existingWidgetInfo?.name]);

  const handleDescriptionBlur = useCallback(() => {
    setChangesMade(existingWidgetInfo?.description !== descriptionRef.current?.resizableTextArea?.textArea.value);
  }, [existingWidgetInfo?.description]);

	const handleColorChange = useCallback((color: string) => {
		setColor(color);
    setChangesMade(existingWidgetInfo?.color !== color);
	}, [existingWidgetInfo?.color]);

  // Called when editing an existing widget
  const validateCacheData = useCallback(async (
    widgetId: string,
    version: number,
    isTempLocation: boolean
  ) => {
    const bundleInStorage = await widgetBundleManager.recreateBundleFromStorage(
      widgetId,
      version,
      isTempLocation
    );

    setCurrentBundleFile({
      assetsList: bundleInStorage.assetsList,
      hasProcessor: bundleInStorage.hasProcessor,
      humanSize: getHumanSize(bundleInStorage.size),
      zipHandler: bundleInStorage.zipHandler,
      icon: bundleInStorage.icon || defaultIcon,
    });
  }, []);

	// Clear list upon exit
	useEffect(() => {
		const modalInfo = (showModal as Partial<WidgetBundleStateWithId>);
		if (showModal) {
			setMissingName(modalInfo.name ? false : true);
			setColor(modalInfo.color || defaultColor);
      setChangesMade(false);
		}
	}, [showModal]);

  // Process the widget from cache
  useEffect(() => {
    if (showModal) {
      if (existingWidgetInfo?.widgetId) {
        if (existingWidgetInfo?.$$collectionInfo) {
          validateCacheData(
            existingWidgetInfo.$$collectionInfo.widgetId,
            existingWidgetInfo.$$collectionInfo.version,
            false,
          );
        } else {
          if (existingWidgetInfo?.$$cacheInfo) {
            validateCacheData(
              existingWidgetInfo.$$cacheInfo.widgetThingId,
              existingWidgetInfo.$$cacheInfo.version,
              true,
            );
          }
        }

      }
    }
  }, [existingWidgetInfo, validateCacheData, showModal]);

	return (
		<StandardModal
			hideCloseIcon
			bodyClassName={styles.ModalBody}
			width={500}
			visible={!!showModal}
			title={t('Title', 'Create Custom Widget')}
			closeOnMaskClick={false}
      onAfterClosed={handleCancel}
			dataKemuMeta="widget-bundle-modal"
      customFooter={
        <div className={styles.FooterWrapper}>
          {currentWidgetId && (
            <div className={styles.LeftFooterBtn}>
              <StyledButton
                data-kemu-meta="bundle-modal-save-collection-btn"
                disabled={changesMade}
                color={'warning'}
                title={'Save Collection'}
                onClick={handleSaveCollection}
              />
            </div>
          )}
          <Space size={20}>
            <StyledButton
              data-kemu-meta="bundle-modal-cancel-btn"
              color="light"
              title={t('CancelBtn', 'Cancel')}
              onClick={handleCancel}
            />

            <StyledButton
              data-kemu-meta="bundle-modal-ok-btn"
              disabled={missingName || !currentBundleFile}
              color={'primary'}
              title={isSaving ? t('SaveBtn', 'Save') : t('CreateBtn', 'Create')}
              onClick={handleSaveLocalVersion}
            />
          </Space>

        </div>
      }
		>
      <div className={styles.Content}>
        <FormGroup marginBottomLevel={3}>
          <StyledInput
              onChange={handleNameChange}
              ref={nameRef}
              defaultValue={(showModal as Partial<WidgetBundleStateWithId>)?.name || ''}
              label={t('Name.Label', 'Name')}
              placeholder={t('Name.Placeholder', 'Enter name of the widget')}
            />
        </FormGroup>

        <StyledTextArea
          ref={descriptionRef}
          preventResize
          onBlur={handleDescriptionBlur}
          defaultValue={(showModal as Partial<WidgetBundleStateWithId>)?.description || ''}
          label={t('Description.Label', 'Description')}
          showCount
          maxLength={200}
        />

        <div className={styles.ColorAndIconRow}>
          <div className={styles.ColorContainer}>
            <StyledLabel text={t('ColorLabel', 'Color')} />
            <ColorSwatch
              className={styles.Swatch}
              width={'350px'}
              popupClassName={styles.SwatchPopup}
              color={color}
              colors={defaultColors}
              onColorChange={handleColorChange}
            />
          </div>
        </div>

        <div className={styles.FileDropWrapper}>
          <FileDropZone
            className={styles.FileDrop}
            onFileAdded={processZipBundle}
            accept=".zip"
            fileLoaded={Boolean(currentBundleFile)}
            noFileElement={
              <div>{t('DropZoneText', 'Load file')}</div>
            }
            yesFileElement={
              <div>
                <SvgFromString
                  svgStr={currentBundleFile?.icon || 'file'}
                  width={50}
                  height={50}
                />
                <span>{currentBundleFile?.humanSize}</span>
              </div>
            }
          />
        </div>

        {currentBundleFile && (
          <div className={styles.AssetWrapper}>
            <div className={styles.ListHeader}>
              <b>{t('Assets.Header', 'Assets')}</b>
              <Button
                type="text"
                className={styles.AddAssetBtn}
                icon={<FileAddOutlined />}
                onClick={handleAddFileToAssets}
              />
            </div>
            <div className={styles.AssetList}>
              <List
                size="small"
                className={styles.AntList}
                bordered
                locale={{
                  emptyText: 'No data here'
                }}
                dataSource={currentBundleFile?.assetsList || []}
                renderItem={(item) =>
                    <List.Item className={styles.AssetItem}>
                      <div className={styles.AssetItemInner}>
                        <TruncatedText showFullOn="click" text={item} maxChars={53} truncateMiddle />
                        <Button
                          type="text"
                          danger
                          icon={<DeleteOutlined />}
                          onClick={() => handleRemoveAsset(item)}
                        />
                      </div>
                    </List.Item>
                }
              />
            </div>
          </div>
        )}
      </div>
		</StandardModal>
	);
};

export default WidgetBundleModal;
