import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import widgetBundleManager from '@kemu-io/kemu-core/dist/widgetBundle/manager';
import { findWidgetInRecipe } from '@kemu-io/kemu-core/dist/common/recipeCache';
import { WidgetBundleState, WidgetType } from '@kemu-io/kemu-core/dist/types';
import { LogicMapperState } from '../logicMapperSlice';
import { removeBundleFromRecipeStorage } from './common';
import * as recipeUtils from '@src/app/recipe/utils';
import { StatelessWidgetsMap, WidgetsMap } from '@src/types/core_t';
import { widgetMapToStatelessMap } from '@common/utils';

type RemoveWidgetsPayload = {
  /** the id of the recipe in the pool */
  recipeId: string;
  /** the id of the Thing in the recipe the widgets will be removed from */
  thingId: string;
  /** the list of widgets ids in the Thing to be removed */
  widgetsIds: string[];
  // /** if provided, `widgetsIds` is ignored and instead, all the currently selected widgets are removed. */
  // allSelected?: boolean;
  clearSelection?: boolean;
}

/**
 * Removes a list of widgets from the recipe.
 */
export const removeWidgetsAction = createAsyncThunk(
  'LogicMapper/removeWidgets',  async (
    payload: RemoveWidgetsPayload,
): Promise<{
  statelessMap: StatelessWidgetsMap;
  clearSelection?: boolean;
}> => {

  let remainingWidgetsInThing: WidgetsMap = {};
  for (const widgetId of payload.widgetsIds) {
    const widget = findWidgetInRecipe(payload.recipeId, payload.thingId, widgetId);
    remainingWidgetsInThing = await recipeUtils.removeWidget(payload.recipeId, payload.thingId, widgetId);
    // If a widget bundle, make sure to remove is /tmp location in cache.
    if (widget?.type === WidgetType.widgetBundle) {
      const widgetState = widget.state as unknown as WidgetBundleState;
      if (widgetState.$$cacheInfo) {
        const isTempStorage = widgetState.$$collectionInfo?.widgetId !== widgetState.$$cacheInfo.widgetThingId;
        if (isTempStorage) {
          await widgetBundleManager.removeWidgetFilesFromCache(
            widgetState.$$cacheInfo.widgetThingId,
            String(widgetState.$$cacheInfo.version),
            true
          );
        }
      }
      // Also, remove data from storage unit
      if (widgetState.storageUnitId) {
        removeBundleFromRecipeStorage(payload.recipeId, payload.thingId, widgetState.storageUnitId);
      }
    }
  }

  const statelessMap = widgetMapToStatelessMap(remainingWidgetsInThing);
  return {
    statelessMap,
    clearSelection: payload.clearSelection
  };
});

export const removeWidgetsReducer = ((builder: ActionReducerMapBuilder<LogicMapperState>): void => {
  builder.addCase(removeWidgetsAction.fulfilled, (state, action) => {
    if (action.payload.clearSelection) {
      state.selectedWidgets = [];
    }

    state.gates = action.payload.statelessMap;
  });

  builder.addCase(removeWidgetsAction.rejected, (_draft, action) => {
    console.error('Failed to add widget from bundle: ', action.error);
  });
});
