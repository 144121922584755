import React from 'react';
import { Menu, Layout } from 'antd';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Icon, { ShoppingCartOutlined } from '@ant-design/icons';
import routes from '../../../../common/routes';
import { ReactComponent as RecipeIcon } from '../../../../assets/img/menus/recipe.svg';
import useTranslation from '../../../../common/hooks/useTranslation';
import { ReactComponent as TutorialsIcon } from './tutorials.svg';
import styles from './MarketplaceMenu.module.css';


const { Sider } = Layout;

const MarketplaceMenu = (): React.JSX.Element => {
  const history = useHistory();
  const t = useTranslation('Marketplace.Navigation.LeftPanel');

  const inRecipes = useRouteMatch({ path: routes.marketplace.getMyRecipesRoute() });
	const inTutorials = useRouteMatch({ path: routes.marketplace.getMyTutorialsRoute() });
  const inMarketplace = useRouteMatch({ path: routes.marketplace.getMarketplacePublicRoute() });


  const navigateToTab = (route: string) => {
		history.push(route);
	};

  return (
    <Sider className={styles.Sider} theme='light' width={200} breakpoint={'md'} collapsedWidth={0}>
      {/* ID added for specificity reasons only. ant styles were overriding my custom ones */}
      <Menu  mode="inline" className={`sidebar-menu`} selectable={false} id={styles.Menu}>
        <Menu.Item
          data-kemu-meta="recipes-section"
          onClick={() => navigateToTab(routes.marketplace.getMyRecipesRoute())}
          className={`${styles.MenuItem} ${inRecipes ? styles.MenuItemSelected : ''}`}
          title=""
          key="1"
          icon={<Icon component={RecipeIcon} />}
        >
          {t('Recipes', 'Recipes')}
        </Menu.Item>

        <Menu.Item
          data-kemu-meta="tutorials-section"
          onClick={() => navigateToTab(routes.marketplace.getMyTutorialsRoute())}
          className={`${styles.MenuItem} ${inTutorials ? styles.MenuItemSelected : ''}`}
          title=""
          key="2"
          icon={<Icon component={TutorialsIcon}/>}
        >
          {t('Tutorials', 'Tutorials')}
        </Menu.Item>

        <Menu.Item
          data-kemu-meta="marketplace-section"
          onClick={() => navigateToTab(routes.marketplace.getPublicationListRoute('recipe'))}
          className={`${styles.MenuItem} ${inMarketplace ? styles.MenuItemSelected : ''}`}
          title=""
          key="3"
          icon={<ShoppingCartOutlined />}
        >
          {t('Marketplace', 'Marketplace')}
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default MarketplaceMenu;
