// NOTE: Export names MUST match the gate 'type' property
import {
	WidgetType,
	CustomWidgetState,
	WidgetPort,
	BasicWidgetInfo,
} from '@kemu-io/kemu-core/dist/types/gate_t';
import { IntlShape } from 'react-intl';
import React from 'react';
import { RecipeType } from '@kemu-io/kemu-types/dist/types';
import { PortLocation } from '../../types/canvas_t';
import { StatelessRecipeWidget } from '../../types/core_t';
import input from './input/input';
import action from './action/action';
import counter from './counter/counter';
import play from './play/play';
import elapsed from './elapsed/elapsed';
import ifGate from './if/ifGate';
import skipEvent from './skipEvent/skipEvent';
import between from './between/between';
import map from './map/map';
import parser from './parser/parser';
import slider from './slider/slider';
import suspend from './suspend/suspend';
import display from './display/display';
import pixelfy from './pixelfy/pixelfy';
import json from './json/json';
import arrayItem from './arrayItem/arrayItem';
import extractImage from './extractImage/extractImage';
import imageConvolution from './imageConvolution/imageConvolution';
import firstEvent from './firstEvent/firstEvent';
import pixelDraw from './pixelDraw/pixelDraw';
import randomBetween from './randomBetween/randomBetween';
import arrayCombine from './arrayCombine/arrayCombine';
import clock from './clock/clock';
import multiplication from './multiplication/multiplication';
import object from './object/object';
import widgetGroup from './widgetGroup/widgetGroup';
import script from './script/script';
import base64ToImageData from './base64ToImageData/base64ToImageData';
import jsonParse from './jsonParse/jsonParse';
import text from './text/text';
import imageCrop from './imageCrop/imageCrop';
import imageResize from './imageResize/imageResize';
import value from './value/value';
import imageWarp from './imageWarp/imageWarp';
import dynamoDb from './dynamoDb/dynamoDb';
import widgetBundle from './widgetBundle/widgetBundle';
import sequence from './sequence/sequence';
// --- gate-cli-insert-import: DO NOT REMOVE THIS LINE ---

export type WidgetPortWithId = WidgetPort & { id: string};
export type WidgetPortsSummary = {
	inputPorts: WidgetPortWithId[];
	outputPorts: WidgetPortWithId[];
	color?: string;
}


export interface GateUIProps {
	/** repaints the ports */
	repaint: () => void;
	/** re-generates the list of classes for all ports based on the port type */
	rebuildPortClasses: (info: WidgetPortsSummary) => void;
	info: StatelessRecipeWidget;
	/** the id of the block in the recipe */
	thingRecipeId: string;
	thingDbId: string;
	thingVersion: string;
	/** the id of the recipe in the pool */
	recipeId: string;
	recipeType: RecipeType;
	[k: string]: unknown;
}

// interface GateInfoProp extends RecipeGate{
// 	/** The id of the recipe in the pool */
// 	recipeId: string;
// 	/** the id of the block in the recipe (recipeId) */
// 	blockId: string;
// }
export interface GateCustomSettingsProps{
	container: HTMLDivElement;
	repaintPorts: ()=> void;
	onClose: ()=> void;
	gateInfo: StatelessRecipeWidget;
	children: React.ReactNode;
	/** The id of the recipe in the pool */
	recipeId: string;
	recipeType: RecipeType;
	/** the id of the block in the recipe (recipeId) */
	blockId: string;
}

export type PortDescription = WidgetPort & {
	position: PortLocation;
	// id: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GetPortsInformationFunction = (gateState: CustomWidgetState<any>, gateInfo: BasicWidgetInfo, intl: IntlShape) => {
	inputs: PortDescription[];
	outputs: PortDescription[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// export type GatePortsPositionFunction = (gateState: CustomWidgetState<any>) => {inputs: Record<string, PortLocation>, outputs: Record<string, PortLocation>};
export type GateUI = {

	/** 
	 * @returns a human readable label to be used as the header of the gate in the Logic Mapper
	 * It receives a reference to the useIntl hook to return the title in the correct language
	 **/
	getWidgetTitle?: (intl: IntlShape) => string;

	/**  
	 * @returns a human readable label to be used as the label of the Gates Bar.
	 * It receives a reference to the useIntl hook to return the title in the correct language
	*/
	getGatesBarTitle?: (intl: IntlShape) => string;

	// getPortsPosition: GatePortsPositionFunction;
	getPortsInformation: GetPortsInformationFunction;

	Element: (props: GateUIProps)=> React.JSX.Element;
	BarIcon?: () => React.JSX.Element;
	// If present, it allows the gate to show custom settings
	CustomSettingsDialog?: (props: GateCustomSettingsProps) => React.JSX.Element;
	hasTitle: boolean;
	// If true, it means the gate's custom settings dialog will expand outside the body of the gate,
	// In these cases, the animation used to show up the dia log is scale instead of slide.
	settingsCustomSize?: boolean;
	/** defines a custom class name to be added to the wrapper element */
	getWrapperClass?: () => string;
	getBarWrapperClass?: () => string;
}

type GateCollection = {
	[keys in WidgetType]: GateUI
};

/** 
 * A collection of gate UI. 
 * NOTE: You can add new gate modules HERE! But make sure the gate type 
 * has already been defined in kemu-core/~/gate_t.ts
 **/
const collection: GateCollection = {
	input,
	// logger,
	action,
	counter,
	play,
	elapsed,
	ifGate,
	skipEvent,
	between,
	map,
	parser,
	slider,
	suspend,
	display,
	pixelfy,
	json,
	arrayItem,
	extractImage,
	imageConvolution,
	firstEvent,
	pixelDraw,
	randomBetween,
	arrayCombine,
	clock,
	multiplication,
	object,
	widgetGroup,
	script,
	base64ToImageData,
	jsonParse,
	text,
	imageCrop,
	imageResize,
	value,
	imageWarp,
	dynamoDb,
	widgetBundle,
	sequence,
	// --- gate-cli-insert-collection: DO NOT REMOVE THIS LINE ---
};

const getGateBody = (type: WidgetType): GateUI | null => {
	if (type in collection) { return collection[type]; }
	return null;
};

/**
 * Returns a list of names of available gate types
 */
const getAvailableGateNames = (): string[] => {
	return Object.keys(collection);
};

/**
 * Returns a dictionary of available gate components
 */
const getGatesCollection = (): GateCollection => {
	return Object.freeze({ ...collection });
};

export {
	getGateBody,
	getAvailableGateNames,
	getGatesCollection
};
