import React from 'react';
import { message } from 'antd';
import { Notifier } from '../../types/canvas_t';

// NOTE: there is a global rule (core.css) that takes care of this styling
const className = 'kemu-thing-notification';

const getContent = (name: string, content: string): React.JSX.Element => {
	return(
		<span>
			<b>{name}</b><br/>{content}
		</span>
	);
};

const buildNotifier = (blockName: string, blockId: string): Notifier => {
	return {
		info: (text: string, duration = 1.5) => message.info({ key: blockId, className, duration, content: getContent(blockName, text) }),
		success: (text: string, duration = 1.5) => message.success({ key: blockId, className, duration, content: getContent(blockName, text) }),
		warning: (text: string, duration = 1.5) => message.warning({ key: blockId, className, duration, content: getContent(blockName, text) }),
		error: (text: string, duration = 1.5) => message.error({ key: blockId, className, duration, content: getContent(blockName, text) }),
		loading: (text: string, duration = 1.5) => message.loading({ key: blockId, className, duration, content: getContent(blockName, text) }),
	};
};

export default buildNotifier;
