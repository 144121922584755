import { UserEntity } from '@kemu-io/kemu-types/dist/types';
import { useEffect, useRef } from 'react';
import { UserAccountState } from '../../types/user';
import { CognitoUserExt } from '../context/UserSessionContext/UserSessionContext';
import globals from '../globals';
import Logger from '../logger';

const logger = Logger('usePendoWidget');

const usePendoWidget = (abort: boolean, user?: CognitoUserExt, profile?: UserEntity, accountState?: UserAccountState): void => {
	const initialized = useRef<boolean>(false);

	const init = (user: CognitoUserExt, profile: UserEntity, accountState: UserAccountState): void => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const pendoInstance = (window as any).pendo;
		if (pendoInstance) {
			const organizationId = profile.organization?.organizationId;
			const kemuAccessCode = user.attributes['custom:access_code'];
			logger.log('Initializing pendo....');
			pendoInstance.initialize({
				visitor: {
					id:	profile.id,
					email: profile.email,
					username: user.getUsername(),
					full_name: `${String(profile.firstName + ' ' + profile.lastName).trim()}`,
					first_name: profile.firstName,
					last_name: profile.lastName,
					gender: user.attributes['gender'],
					...( kemuAccessCode ? { access_code: kemuAccessCode } : undefined),
					role: profile.organization?.role || 'Customer',
				},

				account: {
					id: organizationId ? `org_${organizationId}` : `ind_${profile.id}`,
					...( organizationId ? { name: profile.organization?.name } : undefined),
					userPool: user?.pool?.getUserPoolId() || 'Unknown',
					stage: globals.STAGE,
					creationDate: profile.createdAt,
					stripeSubscriptions: profile.subscriptionInfo?.stripeSubscriptions?.join(' '),
					...( organizationId ? { organizationName: profile.organization?.name } : undefined),
					is_paying: !!accountState.hasKemuAccessLicense
				}
			});
		}
	};

	useEffect(() => {
		if (!abort && user && profile && accountState && !initialized.current) {
			init(user, profile, accountState);
			initialized.current = true;
		}
	}, [user, profile, accountState, abort]);
};

export default usePendoWidget;
