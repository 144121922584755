
import React from 'react';
import Icon from '@ant-design/icons';
import SkipEventsWidgetProcessor, { SkipEventGateState } from '@kemu-io/kemu-core/dist/gates/skipEvent';
import { WidgetPortContext } from '@kemu-io/kemu-core/dist/types/gate_t';
import useReactiveWidgetState from '../../../common/hooks/useReactiveWidgetState';
import { GetPortsInformationFunction, GateUI, GateUIProps } from '..';
import GateIcon from '../../gateIcon/gateIcon';
import { ReactComponent as ElapsedGateIcon } from '../../../assets/img/gates/skipEvent-gate.svg';
import { PortLocation } from '../../../types/canvas_t';
import NumericInput from '../../WidgetsComponents/NumericWithoutArrows/NumericWithoutArrows';
import styles from './skipEvent.module.css';
interface Props extends GateUIProps {
	repaint: ()=> void;
}

const LIMIT_DEFAULT_VALUE = 1;

const SkipEventGate = (props: Props): React.JSX.Element => {
	const [state, setState] = useReactiveWidgetState<SkipEventGateState>(props.recipeId, props.thingRecipeId, props.info.id);

	// NOTE: the actual 'default' limit is defined at the processor level (kemu-core/src/gates/if/index.ts),
	// here we just make sure we show the same.
	const callsLimit = state.callsLimit === undefined ? LIMIT_DEFAULT_VALUE : state.callsLimit;

	const inputChangedCb = (value: number) => {
		setState({
			...state,
			// Making sure the type is numeric, since the processor tests for deep equality
			callsLimit: Number(value)
		}, true);
	};


	const increaseTotalCalls = () => {
		let nextTotal = (state.totalCalls || 0) + 1;
		if (nextTotal > (state.callsLimit || LIMIT_DEFAULT_VALUE)) { nextTotal = 0; }

		setState({
			...state,
			// SUPER important. Make sure the type is numeric, since the processor tests for deep equality
			totalCalls: nextTotal,
		}, true);
	};

	return (
		<div className={`${styles.GateBody}`}>
			<div className={styles.Counter} onClick={increaseTotalCalls}>
				{state.totalCalls || 0}
			</div>
			<NumericInput
				value={callsLimit}
				onChange={inputChangedCb}
			/>
		</div>
	);
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={ElapsedGateIcon} />}/>
	);
};


const getPortsInformation: GetPortsInformationFunction = (state, widgetInfo) => {
	const portContext: WidgetPortContext = { recipePoolId: widgetInfo.recipePoolId, recipeType: widgetInfo.recipeType };
	const outputNames = SkipEventsWidgetProcessor.getOutputNames(state, portContext);
	const inputNames = SkipEventsWidgetProcessor.getInputNames(state, portContext);

	const positions: Record<string, PortLocation> = {
		'in': [0, 0.3, -1, 0],
		'reset': [0, 0.7, -1, 0],
	};

	return {
		inputs: inputNames.map(input => ({
			name: input.name,
			type: input.type,
			position: positions[input.name]
		})),

		outputs: [{
			name: outputNames[0].name,
			type: outputNames[0].type,
			position: 'Right'
		}]
	};
};

export default {
	getPortsInformation,
	BarIcon: GateBarIcon,
	Element: SkipEventGate,
	hasTitle: true,
	getWidgetTitle: (intl) => intl.formatMessage({ defaultMessage: 'Skip Events', id: 'LogicMaker.Gates.SkipEvent.Title' }),
} as GateUI;
