import 'antd/dist/antd.min.css';

import React, { ReactElement, useContext, useEffect } from 'react';
import '@components/layout/core.css';
import { Route, useHistory } from 'react-router-dom';
import { posthog } from 'posthog-js';
import getFreshChatWidget from '../../app/recipe/freshChatWidget';
import getFreshDeskWidget from '../../app/recipe/freshDeskWidget';
import styles from './layout.module.css';
import SignUp from './signup/signup';
import Login from './login/login';
import PasswordReset from './passwordReset/passwordReset';
import ForgotPassword from './forgotPassword/forgotPassword';
import AccountDetails from './AccountDetails/AccountDetails';
import { UserSessionContext } from '@common/context/UserSessionContext/UserSessionContext';
import routes from '@common/routes/index';

function AuthLayout (): ReactElement {
	const history = useHistory();
	const session = useContext(UserSessionContext);
	const organizationType = session.user?.attributes['custom:organization'];
	const userId = session.user?.attributes['sub'];

	// Make sure widgets are hidden if not logged in.
	useEffect(() => {
		const freshChat = getFreshChatWidget();
		const freshDesk = getFreshDeskWidget();
		freshChat.hide();
		freshDesk.hideLauncher();

		// Using try as a workaround for:
		// https://github.com/PostHog/posthog-js/issues/323
		try {
			// Reset user info in case multiple users share the same computer.
			posthog.reset(true);
		} catch (e) {
			console.warn('Failed to reset posthog');
		}

		if (session.state === 'LoggedIn') {
			if (organizationType) {
				history.push(routes.getBaseRoute());
			} else if (userId) {
				history.push(routes.auth.getAccountSelectionWithUserRoute(userId));
			}
		}
	}, [session.state, organizationType, history, userId]);


	return (
		<div className={`${styles.Container} ${styles.Light}`}>
			<div className={styles.Section}>

				<Route path={routes.auth.getForgotPasswordRoute()} component={ForgotPassword} />
				<Route path={routes.auth.getPasswordResetRoute()} component={PasswordReset} />
				<Route path={routes.auth.getSignInRoute()} component={Login} />
				<Route path={routes.auth.getSignUpRoute()} component={SignUp} />
				<Route path={routes.auth.getAccountSelectionRoute()} component={AccountDetails} />

				{/* <Route path="/auth/confirm" component={Confirm} /> */}
			</div>
		</div>
	);
}

export default AuthLayout;
