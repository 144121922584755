import React, { useCallback } from 'react';
import Icon from '@ant-design/icons';
import { Menu } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
import { useHistory } from 'react-router-dom';
import { CustomWidgetVariant } from '@kemu-io/kemu-types/dist/types';
import { currentRecipePoolId, selectedBlock, selectVisibleGroup, unsavedChanges } from '../../../features/Workspace/workspaceSlice';
import useTranslation from '../../../common/hooks/useTranslation';
import { selectCurrentWidget } from '../../../app/reducers/widget/widgetSlice';
import { storeRecipeAction } from '../../../features/interface/reducers/storeRecipeReducer';
import { saveWidgetAction } from '../../../app/reducers/widget/saveWidgetReducer';
import { updateWidgetAction } from '../../../app/reducers/widget/updateWidgetReducer';
import { ReactComponent as SaveWidgetIcon } from './widgetSaveIcon.svg';
import { ReactComponent as SaveRecipeIcon } from './recipeSaveIcon.svg';
import styles from './saveMenuButton.module.css';

interface Props extends MenuItemProps {
	isCollapsed: boolean;
}

const SaveMenuButton = (props: Props): React.JSX.Element => {
	const dispatch = useDispatch();
	const visibleGroup = useSelector(selectVisibleGroup);
	const currentBlock = useSelector(selectedBlock);
	const showRedDot = useSelector(unsavedChanges);
	const recipePoolId = useSelector(currentRecipePoolId);
	const currentWidget = useSelector(selectCurrentWidget);
	const history = useHistory();
	const t = useTranslation('Menu');
	// const typeT = useTranslation('Kemu.DataType.HumanReadable');
	// const widgetT = useTranslation('WidgetAsyncIndicator');

	// antd v4.16.1 is adding these extra properties 
	// @ts-expect-error was present in antd v4.16.1 before downgrade.
	const { isCollapsed, eventKey, warnKey,  ...menuProps } = props; 	// eslint-disable-line @typescript-eslint/no-unused-vars


	// Detect if the widget already exists, and if so, call update instead
	const saveWidgetHandler = useCallback(() => {
		if (currentBlock?.recipeId && visibleGroup?.groupId && recipePoolId) {

			if (!currentWidget) {
				dispatch(saveWidgetAction({
					recipeId: recipePoolId,
					thingId: currentBlock?.recipeId,
					widgetIdInRecipe: visibleGroup?.groupId,
					variant: CustomWidgetVariant.Group,
				}));
			} else {
				dispatch(updateWidgetAction({
					widgetDbId: currentWidget,
					recipeId: recipePoolId,
					thingId: currentBlock.recipeId,
					widgetRecipeId: visibleGroup.groupId,
					variant: CustomWidgetVariant.Group,
				}));
			}

		}
	}, [dispatch, visibleGroup, currentWidget, currentBlock, recipePoolId]);

	const saveRecipeHandler = useCallback(() => {
		// FIXME: restore showRedDot
		if (/*showRedDot && */ recipePoolId) {
			dispatch(storeRecipeAction({
				recipePoolId: recipePoolId,
				history,
			}));
		}
	}, [dispatch, /* showRedDot,*/ recipePoolId, history]);

	const iconStyle = { fontSize: isCollapsed ? 26 : 16 };
	return (
		<Menu.Item {...menuProps}
			key="save"
			data-kemu-meta="save-recipe-btn"
			title={ visibleGroup ? t('SaveWidgetTooltip', 'Save Widget') : t('SaveRecipeTooltip', 'Save Recipe') }
			icon={<Icon component={visibleGroup ? SaveWidgetIcon : SaveRecipeIcon} style={iconStyle} />}
			onClick={visibleGroup ? saveWidgetHandler : saveRecipeHandler}
			className={`${styles.MenuItem} ${showRedDot ? styles.RedDot : styles.GreenDot} ${!isCollapsed ? styles.Expanded : ''}`}
		>
			{ visibleGroup ? t('SaveWidgetTooltip', 'Save Widget') : t('SaveRecipeTooltip', 'Save Recipe') }
		</Menu.Item>
	);
};

export default SaveMenuButton;
