import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import * as userApi from '../../../api/user/userApi';
import { clearInstallationProgress, setInstallationProgress } from '../../../features/Workspace/workspaceSlice';
import { AsyncRequestStatus } from '../../../types/core_t';
import { setUserInstalledThings } from '../user/userSlice';
import { downloadThingBundlesInParallel } from './helpers';
import { ThingInfoWithIcon, ThingState } from './thingSlice';

export const installThingAction = createAsyncThunk('/thing/install', async (
  thingId: string,
  thunkAPI
): Promise<ThingInfoWithIcon> => {

  const response = await userApi.installThing(thingId);
  const installationId = `${response.installedThing.id}_${response.installedThing.version}`;

  const thingMap = {
    [installationId]: {
			...response.installedThing
		}
  };

  const results = await downloadThingBundlesInParallel(thingMap, (thingId, download) => {
		if (!download.errorMsg) {
			thunkAPI.dispatch(setInstallationProgress({
				id: response.installedThing.id,
				block: response.installedThing,
				progress: download.progress!,
        version: response.installedThing.version,
			}));
		} else if (download.errorMsg) {
			thunkAPI.dispatch(setInstallationProgress({
				id: response.installedThing.id,
				block: response.installedThing,
				errorMsg: download.errorMsg,
        version: response.installedThing.version,
			}));
		}
	});

  const result = results[0];

  if (result.status === 'fulfilled') {
    thunkAPI.dispatch(clearInstallationProgress({
      id: response.installedThing.id,
      version: response.installedThing.version
    }));

    // Update local user profile
    thunkAPI.dispatch(setUserInstalledThings(response.user.installedThings));
  } else {
    // Don't clear, leave notification visible to the user
  }


  const installedThingInfo: ThingInfoWithIcon = {
    ...response.installedThing,
    icon: response.installedThing.bundle + '/icon.svg'
  };

  return installedThingInfo;

});


export const installThingReducer = ((builder: ActionReducerMapBuilder<ThingState>/*, thunk: any*/): void => {
  builder.addCase(installThingAction.pending, (state) => {
    state.installThingStatus = {
      status: AsyncRequestStatus.loading,
      error: undefined,
    };
    state.action = 'installing';
  });

  builder.addCase(installThingAction.fulfilled, (state, action: PayloadAction<ThingInfoWithIcon>) => {
    state.installThingStatus = {
      status: AsyncRequestStatus.completed,
      error: undefined,
    };
    // Append new thing to list
    state.things = [
      ...state.things,
      action.payload,
    ];
  });

  builder.addCase(installThingAction.rejected, (state, action) => {
    state.installThingStatus = {
      status: AsyncRequestStatus.error,
      error: action.error,
    };
  });
});
