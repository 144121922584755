/*
 * Written by Alexander Agudelo < alex@kemu.io >, 2020
 * Date: 28/Nov/2020
 * Last Modified: 09/09/2023, 1:32:19 pm
 * Modified By: Alexander Agudelo
 * Description: This hook initializes block canvas UIs and keeps the processing
 * module (custom block UI) in memory even after the canvas instance is unmounted from the DOM.
 * This guarantees that blocks that need the canvas UI to generate data (such as those that
 * access the users webcam or other media device) can continue operating when the user switches
 * to the LogicMaker.
 * 
 * ------
 * Copyright (C) 2020 Kemu - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential.
 */

import type { ExecuteDataEvent, StateChangeEvent } from '@kemu-io/kemu-core/dist/blocks/logicProcessor';
import { useCallback, useRef } from 'react';
import BlocksManager from '@kemu-io/kemu-core/dist/blocks/manager';
import KemuCore from '@kemu-io/kemu-core/dist/index';
import { findBlockInRecipe } from '@kemu-io/kemu-core/dist/common/recipeCache';
import { message } from 'antd';
import { useDispatch } from 'react-redux';
import { ThingType } from '@kemu-io/kemu-types/dist/types';
import Logger from '../logger';
import EventBridge from '@src/app/eventBridge/EventBridge';
import {
	consumeOwnData,
	getBlockState,
	getStorageUnitFromKey,
	removeStorageUnit,
	addToStorage,
	getBlockStorageKeys,
	handleWidgetExecuteActionResponse,
	getRecipeInfo
} from '@src/app/recipe/utils';
import { UICanvasInstance, HardwareInstanceMediaLinkAccess } from '@src/types/canvas_t';
import { CanvasIconConstructor } from '@src/types/core_t';
import AuthenticatedApi from '@src/api/authenticatedApi/authenticatedApi';
import buildNotifier from '@src/api/authenticatedApi/notifier';
import MediaLink from '@src/app/mediaLink/mediaLink';
import { addCustomWidgetTemplatesAction, removeCustomWidgetTemplateAction } from '@src/features/Workspace/workspaceSlice';

const logger = Logger('useEternalCanvasInstance');

export interface EnhancedUICanvasInstance extends UICanvasInstance {
	_id: string;
	mount: (target: HTMLDivElement) => Promise<void>;
	_target: Element | null;
	_executeActionEvtHandler?: (evt: ExecuteDataEvent)=> Promise<void>;
	_recipeId: string;
	_blockRecipeId: string;
	_blockDbId: string;
	_blockVersion: string;
	_updateBlockState?: (evt: StateChangeEvent) => void;
}

export type CreateEternalInstanceFunction = (blockDbId: string, blockVersion: string, recipeId: string, blockRecipeId: string, label: string) => EnhancedUICanvasInstance;
export type DestroyEternalInstanceFunction = (instance: EnhancedUICanvasInstance, isUserAction: boolean) => Promise<void>;
export type GetCanvasInstanceFunction = (recipeId: string, blockRecipeId: string, blockDbId: string) => EnhancedUICanvasInstance | null;

type EternalInstanceResponse = {
	getCanvasInstance: GetCanvasInstanceFunction,

	createCanvasInstance: CreateEternalInstanceFunction,
	/** 
	 * Removes an instance from memory
	 * @param instance is the returned value from `createCanvasInstance`
	*/
	destroyCanvasInstance: DestroyEternalInstanceFunction,

	/** A method to call to remove ALL instances from memory */
	destroyAll: () => Promise<void>
};


export function useEternalCanvasInstance (): EternalInstanceResponse {
	// Create a temporal target since we don't have a reference to the container yet
	const customUIMap = useRef<{[k: string]: EnhancedUICanvasInstance}>({});
	const dispatch = useDispatch();

	const getInstance: GetCanvasInstanceFunction = useCallback((recipeId: string, blockRecipeId: string, blockDbId: string) => {
		const id = `${recipeId}_${blockRecipeId}_${blockDbId}`;
		if (customUIMap.current[id]) {
			return customUIMap.current[id];
		}

		logger.log(`Instance [${id}] not found`);
		return null;
	}, []);

	const create: CreateEternalInstanceFunction = useCallback((blockDbId: string, blockVersion: string, recipeId: string, blockRecipeId: string, label: string): EnhancedUICanvasInstance => {
		const id = `${recipeId}_${blockRecipeId}_${blockDbId}`;
		if (customUIMap.current[id]) {
			logger.log(`Instance of ${id} already created!`);
			return customUIMap.current[id];
		}

		let virtualTarget: HTMLDivElement | null = document.createElement('div');
		virtualTarget.setAttribute('id', id);
		const blockRecipeInfo = findBlockInRecipe(recipeId, blockRecipeId);

		logger.log(`Creating custom instance of ${blockDbId} with recipeId ${blockRecipeId}`);
		const IconConstructor = <CanvasIconConstructor> BlocksManager.getBlockUI(blockDbId, blockVersion).CanvasIcon;
		const instance = new IconConstructor({ target: virtualTarget! }) as EnhancedUICanvasInstance;
		instance._id = id;
		instance._target = virtualTarget.firstElementChild;

		instance._recipeId = recipeId;
		instance._blockRecipeId = blockRecipeId;
		instance._blockDbId = blockDbId;
		instance._blockVersion = blockVersion;

		const bundleTools = BlocksManager.getThingTools(blockDbId, blockVersion);

		// Get a list of of custom widgets and update the store
		if (bundleTools.getCustomWidgets) {
			dispatch(addCustomWidgetTemplatesAction({
				thingDbId: blockDbId,
				templates: bundleTools.getCustomWidgets(),
			}));
		}

		// Allows canvas instances to receive on execute action events
		if (instance.exports && instance.exports.onExecuteAction) {
			instance._executeActionEvtHandler = async (evt: ExecuteDataEvent): Promise<void> => {
				if (instance.exports.onExecuteAction) {
					const executeResponse = instance.exports.onExecuteAction(evt);
					return handleWidgetExecuteActionResponse(
						evt.actionWidgetId,
						evt.sourceBlockId,
						blockDbId,
						blockVersion,
						recipeId,
						executeResponse,
						evt.data
					);
				}
			};
		}

		if (instance.exports && instance.exports.setSecureApi) {
			// IMPORTANT: Now Only certain things have access to this api
			const allowedThings  = ['email-sender'];
			if (allowedThings.includes(blockDbId) || blockRecipeInfo?.type === ThingType.Cloud) {
				instance.exports.setSecureApi(AuthenticatedApi);
			}
		}

		// Notifier module
		if (instance.exports && instance.exports.setNotifier && blockRecipeInfo) {
			instance.exports.setNotifier(buildNotifier(blockRecipeInfo.name, blockRecipeId));
		}

		// Notify canvas instance about their state change
		instance._updateBlockState = (evt: StateChangeEvent): void => {
			instance.exports.setState(evt.newState);
		};

		// Helper method to allow instances to be mounted into a target
		instance.mount = async (target: HTMLDivElement) => {
			logger.log(`Mounting ${blockDbId}_${id} into target ${target}`);

			if (instance.exports && instance.exports.HAS_CUSTOM_ICON) {
				target.appendChild(instance._target!);
			}


			// Only the very first time this instance is created `virtualTarget` holds a value
			if (virtualTarget) {
				logger.log(`Initializing`);

				// Check if the canvas instance is expecting events from the
				// properties panel and if so, create a link between the two
				if (instance.exports && instance.exports.onPropsPanelEvent) {
					EventBridge.setCanvasInstanceListener(blockDbId, blockVersion, blockRecipeId, async (evt) => {
						if (instance.exports.onPropsPanelEvent) {
							return instance.exports.onPropsPanelEvent(evt);
						}
					});
				}

				instance.exports && instance.exports.setEmitToPropsPanel && instance.exports.setEmitToPropsPanel(async (evt) => {
					return EventBridge.emitToPropsPanel(blockRecipeId, evt);
				});

				// Define callbacks
				if (instance.exports && instance.exports.onDataAvailable) {
					instance.exports.onDataAvailable(async (data) => {
						return consumeOwnData(recipeId, blockRecipeId, data as Record<string, unknown>);
					});
				}

				if (instance.exports && instance.exports.onExecuteAction && instance._executeActionEvtHandler) {
					KemuCore.onExecuteAction(instance._executeActionEvtHandler, blockRecipeId, recipeId);
				}

				if (instance.exports && instance.exports.onStateChanged) {
					logger.log(`onStateChanged definition`);
					instance.exports.onStateChanged((newState) => {
						// logger.log(`state changed in canvas block!!: ${JSON.stringify(newState)}`);
						KemuCore.setBlockState(recipeId, blockRecipeId, newState, true);
					});
				}

				// if(instance.exports.setState){
					// const initialState = getBlockState(blockRecipeId, recipeId);
					// instance.exports.setState(JSON.parse(JSON.stringify(initialState)));
				KemuCore.onBlockStateChanged(instance._updateBlockState!, blockRecipeId);
				// }

				if (instance.exports && instance.exports.setStorageCallbacks) {
					instance.exports.setStorageCallbacks(
						() => getBlockStorageKeys(recipeId, blockRecipeId),
						(k) => getStorageUnitFromKey(recipeId, blockRecipeId, k),
						(d, k) => addToStorage(recipeId, blockRecipeId, d, k),
						(k) => removeStorageUnit(recipeId, blockRecipeId, k)
					);
				}

				// Initialize module passing the installation location
				const blockLocation = BlocksManager.getThingLocation(blockDbId, blockVersion);
				const initialState = JSON.parse(JSON.stringify(getBlockState(blockRecipeId, recipeId)));

				// Undocumented API to allow hardware instances to access stream references
				let globalAccess: HardwareInstanceMediaLinkAccess | undefined = undefined;
				const allowedInstances = ['phone-camera', 'phone-microphone'];
				if (allowedInstances.includes(blockDbId)) {
					globalAccess = {
						getAudioStream: MediaLink.getAudioStream,
						getVideoStream: MediaLink.getVideoStream,
						sendToDevice: (data: string) => {
							if (typeof data !== 'string') { return; }
							MediaLink.sendToDevice(data);
						}
					};
				}

				// The very first time we have to get the state directly from the recipe
				if (instance.exports.initialize) {
					await instance.exports.initialize(blockLocation, initialState, label, globalAccess);
				} else if (instance.exports.onInitialize) {
					await instance.exports.onInitialize({
						cacheLocation: blockLocation,
						initialState: initialState,
						label: label,
						mediaLink: globalAccess,
						getRecipeInfo: async () => getRecipeInfo(recipeId),
					});
				}
			} else {
				// Notify module it is being re-painted
				if (instance.exports && instance.exports.HAS_CUSTOM_ICON) {
					instance.exports.refresh && instance.exports.refresh();
				}
			}

			virtualTarget = null;
		};

		customUIMap.current[id] = instance;

		return instance;
	}, [dispatch]);


	const destroy = useCallback(async (canvasInstance: EnhancedUICanvasInstance, isUserAction: boolean) => {
		if (!canvasInstance._id) { throw new Error('Invalid canvasInstance'); }

		// Notify svelte instance listener
		if (canvasInstance.exports.onBeforeDestroy) {
			await canvasInstance.exports.onBeforeDestroy(isUserAction);
		}

		canvasInstance.exports.setEmitToPropsPanel && canvasInstance.exports.setEmitToPropsPanel(null);

		if (canvasInstance.exports.onExecuteAction) {
			KemuCore.offExecuteAction(canvasInstance._executeActionEvtHandler!, canvasInstance._blockRecipeId!, canvasInstance._recipeId!);
		}

		if (canvasInstance._updateBlockState) {
			logger.log('Unsubscribing from state changes');
			KemuCore.offBlockStateChanged(canvasInstance._updateBlockState, canvasInstance._blockRecipeId!);
		}

		// Remove the list of custom widget templates from the store (if any)
		dispatch(removeCustomWidgetTemplateAction(canvasInstance._blockDbId));

		// Cancel event listeners
		typeof canvasInstance.exports.onDataAvailable === 'function' && canvasInstance.exports.onDataAvailable(null);

		canvasInstance.exports.setTriggerCanvasAction && canvasInstance.exports.setTriggerCanvasAction(null);

		if (canvasInstance.exports.setStorageCallbacks) {
			canvasInstance.exports.setStorageCallbacks(null, null, null, null);
		}

		// Remove any visible notification from this Thing
		if (canvasInstance.exports.setNotifier) {
			message.destroy(canvasInstance._blockRecipeId);
		}

		// Remove bridge event listener
		EventBridge.setCanvasInstanceListener(canvasInstance._blockDbId, canvasInstance._blockVersion, canvasInstance._blockRecipeId, null);



		// Invoke Svelte's destroy method
		canvasInstance.$destroy();

		logger.log(`Destroying instance ${canvasInstance._id}`);
		canvasInstance._target?.remove();
		canvasInstance._target = null;


		// Remove it from memory
		delete customUIMap.current[canvasInstance._id];
	}, [dispatch]);


	const destroyAll = useCallback(async () => {
		if (customUIMap.current) {
			const promises: Promise<void>[] = [];
			for (const instanceId in customUIMap.current) {
				logger.log(`Destroying instance id ${instanceId}`);
				promises.push(destroy(customUIMap.current[instanceId], false));
			}

			await Promise.all(promises);
		}
	}, [destroy]);

	//========== FIXME : DELETE THIS =========
	// Used for debugging purposes only
	// useEffect(() => {
	// 	logger.log('MOUNTED!');
	// 	// @ts-expect-error i know what I'm doing
	// 	window.eternalMap = customUIMap;
	// 	// @ts-expect-error i know what I'm doing
	// 	window.destroyAll = destroyAll;
	// }, [customUIMap, destroyAll]);
	// ======================================

	return {
		createCanvasInstance: create,
		destroyCanvasInstance: destroy,
		destroyAll,
		getCanvasInstance: getInstance
	};
}
