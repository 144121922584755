
import React from 'react';
import Icon from '@ant-design/icons';
import SuspendWidgetProcessor, { SuspendGateState, getDefaultState } from '@kemu-io/kemu-core/dist/gates/suspend';
import { WidgetPortContext } from '@kemu-io/kemu-core/dist/types/gate_t';
import useReactiveWidgetState from '../../../common/hooks/useReactiveWidgetState';
import { GetPortsInformationFunction, GateUI, GateUIProps } from '..';
import GateIcon from '../../gateIcon/gateIcon';
import { ReactComponent as ElapsedGateIcon } from '../../../assets/img/gates/suspend.svg';

import { PortLocation } from '../../../types/canvas_t';
import { useTranslation } from '../../../common/hooks';
import styles from './suspend.module.css';

interface Props extends GateUIProps {
	repaint: ()=> void;
}


const SuspendGate = (props: Props): React.JSX.Element => {
	const t = useTranslation('LogicMaker.Gates.Suspend');
	const [state, setState] = useReactiveWidgetState<SuspendGateState>(props.recipeId, props.thingRecipeId, props.info.id);
	const fixedState = {
		...getDefaultState(),
		...state
	};


	const inputChangedCb = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = (evt.currentTarget.value as unknown) as number;
		setState({
			...state,
			delayMs: Number(newValue)
		}, true);
	};


	return (
		<div className={`${styles.GateBody}`}>
			<div className={`gate-input ${styles.InputContainer}`}>
				<input
					type="number"
					title={t('Placeholder', 'Time in ms (use 0 for infinity)')}
					value={fixedState.delayMs}
					onChange={inputChangedCb}
					className={styles.Input}
				/>
			</div>
		</div>
	);
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={ElapsedGateIcon} />}/>
	);
};


const getPortsInformation: GetPortsInformationFunction = (state, widgetInfo) => {
	const portContext: WidgetPortContext = { recipePoolId: widgetInfo.recipePoolId, recipeType: widgetInfo.recipeType };
	const outputNames = SuspendWidgetProcessor.getOutputNames(state, portContext);
	const inputNames = SuspendWidgetProcessor.getInputNames(state, portContext);

	const positions: Record<string, PortLocation> = {
		'in': [0, 0.3, -1, 0],
		'reset': [0, 0.7, -1, 0],
	};

	return {
		inputs: inputNames.map(input => ({
			name: input.name,
			type: input.type,
			position: positions[input.name]
		})),

		outputs: [{
			name: outputNames[0].name,
			type: outputNames[0].type,
			position: 'Right'
		}]
	};
};

export default {
	getPortsInformation,
	BarIcon: GateBarIcon,
	Element: SuspendGate,
	hasTitle: true,
	getWidgetTitle: (intl) => intl.formatMessage({ defaultMessage: 'Suspend Events', id: 'LogicMaker.Gates.Suspend.Title' }),
} as GateUI;
