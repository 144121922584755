import React from 'react';
import { usePreview } from 'react-dnd-preview';
import { usePreviewStateFull } from 'react-dnd-preview/dist/cjs/usePreview';
import CustomWidgetIcon, { CustomWidgetIconInfo } from '../CustomWidgetIcon/CustomWidgetIcon';
import styles from './CustomWidgetDragPreview.module.css';
import { DroppableTypes } from '@common/constants';


/** Shows up when a widget is being dragged */
const CustomWidgetDragPreview = (): React.JSX.Element | null => {
	const {
		display,
		style,
		itemType,
		item: widgetInfo
	} = usePreview() as usePreviewStateFull<CustomWidgetIconInfo>;

	if (!display || itemType !== DroppableTypes.CustomWidget) {
		return null;
  }

	return (
		<div className={styles.DragPreview} style={{ ...style }}>
			<CustomWidgetIcon
				color={widgetInfo.color}
				icon={widgetInfo.icon}
				isBundle={widgetInfo.isBundle}
			/>
		</div>
	);
};


export default CustomWidgetDragPreview;
