import { UserPropertyValue } from '@kemu-io/kemu-types/dist/types';
import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import * as userApi from '../../../api/user/userApi';
import { CustomerState, UserActionEvent, UserSettings } from './userSlice';

/**
 * Replaces the user's `settings` property with the given value.
 * IMPORTANT: Passing a partial object will delete all other properties.
 */
export const updateUserSettingsAction = createAsyncThunk('/user/event/track/settings', async (
	args: { eventName: UserActionEvent, settings: UserSettings },
): Promise<UserSettings> => {

	// This is NOT async.
	userApi.trackEvent(args.eventName, {
		settings: args.settings
	});

	return args.settings;
});

export const updateUserSettingsReducer = ((builder: ActionReducerMapBuilder<CustomerState>): void => {
	// WARNING: we are assuming posthog event tracking went OK. 
	// Here we update the user's integrations to keep data "in sync".
	builder.addCase(updateUserSettingsAction.fulfilled, (state, action: PayloadAction<UserSettings>) => {
		if (state.currentUserProfile.integrations.posthog) {
			state.currentUserProfile.integrations = {
				...state.currentUserProfile.integrations,
				posthog: {
					...state.currentUserProfile.integrations.posthog,
					properties: {
						...state.currentUserProfile.integrations.posthog.properties,
            settings: {
              ...(state.currentUserProfile.integrations.posthog.properties?.settings as Record<string, UserPropertyValue>),
              ...action.payload
            }
					}
				}
			};
		}
	});

});
