import React, { useState } from 'react';
import Icon, { DownOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { CloudEnvironmentType, RecipeType } from '@kemu-io/kemu-types/dist/types';
import { Dropdown, Menu } from 'antd';
import EditableHeading from '../form-control/editableHeading/editableHeading';
import { setRecipeName, currentRecipeTitle, selectCurrentRecipeType, setRecipeType, selectCurrentRecipeCloudInfo } from '../../features/Workspace/workspaceSlice';
import { useTranslation } from '../../common/hooks';
import RoundedModal from '../roundedModal/roundedModal';
import KemuSwitch from '../form-control/kemuSwitch/kemuSwitch';
import StyledLabel from '../form-control/styledLabel/styledLabel';
import { ReactComponent as CloudIcon } from './cloud-icon.svg';
import styles from './RecipeWorkspaceTitle.module.css';

interface MenuInfo {
	key: React.Key;
	keyPath: React.Key[];
	item: React.ReactInstance;
	domEvent: React.MouseEvent<HTMLElement>;
}

const ValidEnvironments: CloudEnvironmentType[] = [
	CloudEnvironmentType.Cpu_128M,
	CloudEnvironmentType.Cpu_256M,
	CloudEnvironmentType.Cpu_512M,
	CloudEnvironmentType.Cpu_1024M,
	CloudEnvironmentType.Cpu_2048M,
	CloudEnvironmentType.Cpu_4096M,
];

const RecipeWorkspaceTitle = (): React.JSX.Element => {
	const dispatch = useDispatch();
	const [showModal, setShowModal] = useState(false);
	const t = useTranslation('Recipe');
	const defaultRecipeTitle = t('DefaultTitle', 'Untitled Recipe');
	const recipeTitle = useSelector(currentRecipeTitle);
	const recipeType = useSelector(selectCurrentRecipeType);
	const cloudInfo = useSelector(selectCurrentRecipeCloudInfo);
	const [selectedEnvironment, setSelectedEnvironment] = useState<CloudEnvironmentType>(cloudInfo?.environment || ValidEnvironments[0]);
	const [cloudEnabled, setCloudEnabled] = useState(!!cloudInfo?.enabled);


	const applySelection = () => {
		dispatch(setRecipeType({
			type: RecipeType.Cloud,
			cloudInfo: {
				enabled: cloudEnabled,
				environment: selectedEnvironment
			}
		}));

		setShowModal(false);
	};

	const handleEnvironmentSelection = (e: MenuInfo) => {
		const environmentType = e.key as CloudEnvironmentType;
		if (!ValidEnvironments.includes(environmentType)) {
			console.error(`Unknown environment type: ${environmentType}`);
			return;
		}

		setSelectedEnvironment(environmentType);
	};

	const menu = (
		<Menu onClick={handleEnvironmentSelection}>
			<Menu.Item key="kmu-cpu-128m">
				{t('CloudModal.Environment.kmu-cpu-128m', 'kmu-cpu-128m')}
			</Menu.Item>
			<Menu.Item key="kmu-cpu-256m">
				{t('CloudModal.Environment.kmu-cpu-256m', 'kmu-cpu-256m')}
			</Menu.Item>
			<Menu.Item key="kmu-cpu-512m">
				{t('CloudModal.Environment.kmu-cpu-512m', 'kmu-cpu-512m')}
			</Menu.Item>
			<Menu.Item key="kmu-cpu-1024m">
				{t('CloudModal.Environment.kmu-cpu-1024m', 'kmu-cpu-1024m')}
			</Menu.Item>
			<Menu.Item key="kmu-cpu-2048m">
				{t('CloudModal.Environment.kmu-cpu-2048m', 'kmu-cpu-2048m')}
			</Menu.Item>
			<Menu.Item key="kmu-cpu-4096m">
				{t('CloudModal.Environment.kmu-cpu-4096m', 'kmu-cpu-4096m')}
			</Menu.Item>
		</Menu>
	);

	const handleSwitchChange = (checked: boolean) => {
		setCloudEnabled(checked);
	};

	const handleTitleChange = (text: string) => {
		dispatch(setRecipeName(text));
	};

	const handleShowCloudSettings = () => {
		setShowModal(true);
	};

	const handleSettingsClosed = () => {
		setShowModal(false);
	};

	return (
		<>
			{recipeType === RecipeType.Cloud && (
				// <Icon component={CloudIcon} className={styles.CloudIcon} />
				<span className={styles.CloudWrapper} onClick={handleShowCloudSettings}>
					<Icon component={CloudIcon} className={styles.CloudIcon}/>
				</span>
			)}

			<EditableHeading onEditFinished={handleTitleChange} tag="h1" text={recipeTitle || defaultRecipeTitle} />
			<RoundedModal
				visible={showModal}
				closeOnMaskClick={true}
				onCancel={handleSettingsClosed}
				title={t('CloudModal.Title', 'Cloud Recipe Settings')}
				okBtnLabel={t('CloudModal.SaveBtn', 'Save')}
				onOk={applySelection}
				// cancelBtnLabel={t('CloudModal.CancelBtn', 'Cancel')}
				noFooter={false}
			>
				<div className={styles.EnvironmentSelection}>
					<StyledLabel
						text={t('CloudModal.Environment.Title', 'Choose an environment')}
						className={styles.Labels}
					/>
					<Dropdown overlay={menu} trigger={['click']} className={styles.DropdownWrapper}>
						<div>
							{t(`CloudModal.Environment.${selectedEnvironment}`, `${selectedEnvironment}`)}
							<DownOutlined custom-dropdown="yes" className={styles.DropIcon}/>
						</div>
					</Dropdown>
					<p className={styles.EnvDescription}>{t('CloudModal.Environment.Description', 'CloudModal.Environment.Description')}</p>
				</div>
				<div className={styles.SwitchContainer}>
					<StyledLabel
						className={styles.Labels}
						text={t('CloudModal.EnableSwitch', 'Enable cloud execution')}
					/>
					<KemuSwitch
						checked={cloudEnabled}
						size="default"
						onChange={handleSwitchChange}
					/>
				</div>
			</RoundedModal>
		</>
	);
};

export default RecipeWorkspaceTitle;
