import { MenuItemProps } from 'antd/lib/menu/MenuItem';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu } from 'antd';
import { FormattedMessage } from 'react-intl';
import {
	CopyOutlined,
} from '@ant-design/icons';
import { CustomWidgetVariant } from '@kemu-io/kemu-types/dist/types';
import { currentRecipePoolId, selectedBlock, selectVisibleGroup } from '@src/features/Workspace/workspaceSlice';
import { selectCurrentWidget, showSaveAsModal } from '@src/app/reducers/widget/widgetSlice';
import { showSaveAsDialog } from '@src/features/interface/interfaceSlice';


type Props = MenuItemProps;

const SaveAsMenuButton = (props: Props): React.JSX.Element => {
	const { ...menuProps } = props;
	const dispatch = useDispatch();
	const visibleGroup = useSelector(selectVisibleGroup);
	const currentWidget = useSelector(selectCurrentWidget);
	const recipePoolId = useSelector(currentRecipePoolId);
	const currentBlock = useSelector(selectedBlock);
	const canSaveAs = currentWidget && recipePoolId && visibleGroup && currentBlock;

	const saveAsWidgetHandler = useCallback(() => {
		if (currentWidget && recipePoolId && visibleGroup && currentBlock) {
			dispatch(showSaveAsModal({
				dbId: currentWidget,
				widgetRecipeId: visibleGroup.groupId,
				thingId: currentBlock.recipeId,
				name: visibleGroup.name,
				variant: CustomWidgetVariant.Group,
			}));
		}
	}, [dispatch, currentBlock, currentWidget, recipePoolId, visibleGroup]);

	const saveAsRecipeHandler = useCallback(() => {
		dispatch(showSaveAsDialog(true));
	}, [dispatch]);

	return (
		<Menu.Item {...menuProps}
			disabled={!canSaveAs && !!visibleGroup}
			data-kemu-meta="save-as-item"
			className="main-menu-item"
			key="save-as"
			icon={<CopyOutlined />}
			onClick={visibleGroup ? saveAsWidgetHandler : saveAsRecipeHandler}
		>
			{visibleGroup ? (
				<FormattedMessage id="Menu.SaveWidgetAs" defaultMessage="Save Widget As"/>
			) : (
				<FormattedMessage id="Menu.SaveRecipeAs" defaultMessage="Save As"/>
			)}
		</Menu.Item>
	);
};

export default SaveAsMenuButton;
