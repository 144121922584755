
import React, { useCallback } from 'react';
import Icon from '@ant-design/icons';
import DynamoDbWidgetProcessor, {
	DynamoDbWidgetState,
	getDefaultState,
	InputNames as WidgetInputs,
	OutputNames as WidgetOutputs
} from '@kemu-io/kemu-core/dist/gates/dynamoDb';
import { Collapse } from 'antd';
import { CustomWidgetState, WidgetPortContext } from '@kemu-io/kemu-core/dist/types/gate_t';
import classNames from 'classnames';
import useTranslation from '../../../common/hooks/useTranslation';

import NumericInput from '../../WidgetsComponents/NumericInput/NumericInput';
import TextInput from '../../WidgetsComponents/TextInput/TextInput';
import { PortLocation } from '../../../types/canvas_t';
import GateIcon from '../../gateIcon/gateIcon';
import {
	GateCustomSettingsProps,
	GetPortsInformationFunction,
	GateUI,
	PortDescription,
	GateUIProps,
} from '..';
import useReactiveWidgetState from '../../../common/hooks/useReactiveWidgetState';
import { SETTINGS_CONTAINER_CLASS } from '../../../common/constants';
import TagsInput from '../../WidgetsComponents/TagsInput/TagsInput';
import styles from './dynamoDb.module.css';
import { ReactComponent as DynamoDbIcon } from './icon.svg';

const { Panel } = Collapse;
const WIDGET_WRAPPER_CLASS = `dynamodb-widget-wrapper`;
const ValidKeys: (keyof DynamoDbWidgetState)[] = [
	'tableName', 'region', 'limit', 'attributes',
	'externalId', 'operation', 'roleArn', 'secretKey',
	'accessKey', 'indexName',
];


const DynamoDbWidget = (props: GateUIProps): React.JSX.Element => {
	// const [state] = useReactiveWidgetState<DynamoDbWidgetState>(props.recipeId, props.thingRecipeId, props.info.id);

	return (
		<div className={classNames(styles.GateBody, {
			[styles.WidgetDisabled]: props.info.disabled
		})}>
			<Icon component={DynamoDbIcon}/>
		</div>
	);
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={DynamoDbIcon} />}/>
	);
};

const GateCustomSettings = (props: GateCustomSettingsProps): React.JSX.Element => {
	const [widgetState, setState] = useReactiveWidgetState<DynamoDbWidgetState>(props.recipeId, props.blockId, props.gateInfo.id);
	const fixedState = {
		...getDefaultState(),
		...widgetState
	};

	const t = useTranslation('LogicMapper.Widgets.DynamoDb.Settings');

	const handleAttributeChange = useCallback((tags: string[]) => {
		setState(s => ({
			...s,
			attributes: tags,
		}));
	}, [setState]);

	const handleInputChange = (text: string | number, eventId?: string) => {
		// Quick sanity check
		if (!eventId || !ValidKeys.includes(eventId as keyof DynamoDbWidgetState)) {
			return console.error(`The given key [${eventId}] is not part of the widget's state`);
		}

		setState({
			...fixedState,
			[eventId]: text
		});
	};

	return (
		<div className={classNames(styles.SettingsContainer, SETTINGS_CONTAINER_CLASS)}>
			{props.children}

			<div className={styles.RowContainer}>
				<div className={styles.InputColumn}>
					<TextInput
						eventId="tableName"
						defaultValue={fixedState.tableName}
						label={t('TableName', 'Table name')}
						onBlur={handleInputChange}
					/>
				</div>

				<div className={styles.InputColumn}>
					<TextInput
						eventId="region"
						label={t('Region', 'Region')}
						defaultValue={fixedState.region}
						onBlur={handleInputChange}
					/>
				</div>

				<div className={styles.InputColumn}>
					<TextInput
						eventId="accessKey"
						label={t('AccessKey', 'Access Key')}
						defaultValue={fixedState.accessKey}
						onBlur={handleInputChange}
					/>
				</div>

				<div className={styles.InputColumn}>
					<TextInput
						type={'password'}
						eventId="secretKey"
						label={t('SecretKey', 'Secret Key')}
						defaultValue={fixedState.secretKey}
						onBlur={handleInputChange}
					/>
				</div>
			</div>


			<Collapse ghost bordered={false} className={styles.Collapse} expandIconPosition="right">
				<Panel header="Advanced Settings" key="1" className={classNames(styles.Panel, styles.RightArrow)} >
					<div className={styles.InputColumn}>
						<TextInput
							type={'text'}
							eventId="roleArn"
							label={t('RoleArn', 'Role ARN')}
							defaultValue={fixedState.secretKey}
							onBlur={handleInputChange}
						/>
					</div>

					<div className={styles.InputColumn}>
						<label>{t('Limit', 'Limit')}</label>
						<NumericInput
							eventId="limit"
							max={1000}
							min={0}
							value={fixedState.limit}
							onChange={handleInputChange}
						/>
					</div>

					<div className={styles.InputColumn}>
						<TagsInput
							label={t('Attributes', 'Attributes')}
							value={fixedState.attributes}
							onChange={handleAttributeChange}
						/>
						{/* <label>{t('Attributes', 'Attributes')}</label>
						<Select
							tokenSeparators={[',']}
							defaultValue={fixedState.attributes}
							className={styles.TagsElement}
							dropdownClassName={styles.TagsDropdown}
							mode="tags"
							onChange={handleAttributeChange}
						>
							{fixedState.attributes?.map((attrName, i) => (
								<Option key={`usr_${i}`} value={attrName}>
									{attrName}
								</Option>
							))}
						</Select> */}
					</div>
				</Panel>
			</Collapse>
		</div>
	);
};

const getPortsInformation: GetPortsInformationFunction = (state: CustomWidgetState<DynamoDbWidgetState>, widgetInfo) => {
	const portContext: WidgetPortContext = { recipePoolId: widgetInfo.recipePoolId, recipeType: widgetInfo.recipeType };
	const outputNames = DynamoDbWidgetProcessor.getOutputNames(state, portContext);
	const inputNames = DynamoDbWidgetProcessor.getInputNames(state, portContext);

	const inputPositions: Record<string, PortLocation> = {
		[WidgetInputs.scan]: [0, 0.3, -1, 0],
		[WidgetInputs.nextBatch]: [0, 0.7, -1, 0],
	};

	const outputPositions: Record<string, PortLocation> = {
		[WidgetOutputs.success]: [1, 0.3, 1, 0],
		[WidgetOutputs.items]: [1, 0.3, 1, 0],
		[WidgetOutputs.error]: [1, 0.7, 1, 0],
	};


	let inputs: PortDescription[] = [];
	let outputs: PortDescription[] = [];

	if (state.operation === 'scan') {
		inputs = inputNames.map(input => {
			return {
				...input,
				position: inputPositions[input.name],
			};
		});

		outputs = outputNames.map(output => {
			return {
				...output,
				position: outputPositions[output.name],
			};
		});
	}

	return {
		outputs,
		inputs,
	};
};

export default {
	getPortsInformation,
	// BarIcon: GateBarIcon,
	Element: DynamoDbWidget,
	CustomSettingsDialog: GateCustomSettings,
	getWrapperClass: () => WIDGET_WRAPPER_CLASS,
	hasTitle: true,
	getGatesBarTitle: (intl) => intl.formatMessage({ id: 'LogicMapper.Widgets.DynamoDb.Title', defaultMessage: 'Dynamo Table' }),
	getWidgetTitle: (intl) => intl.formatMessage({ id: 'LogicMapper.Widgets.DynamoDb.WidgetHeader', defaultMessage: 'Dynamo DB' }),
} as GateUI;
