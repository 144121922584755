/* eslint-disable @typescript-eslint/no-explicit-any */
import globals from './globals';

type LoggerInstance = {
	log: (msg: string, ...args: any[])=> void;
	error: (msg: string, ...args: any[])=> void;
}

const instances: Record<string, LoggerInstance> = {};

const Logger = (name: string): LoggerInstance => {
	instances[name] = {
		log: function (message: string, ...args: any[]): void {
			if (globals.STAGE !== 'production' && globals.STAGE !== 'test') {
				console.log.apply(undefined, [`${name}: `, message, ...args]);
			}
		},
		error: function (message: string, ...args: any[]): void {
			if (globals.STAGE !== 'production' && globals.STAGE !== 'test') {
				console.error.apply(undefined, [`${name}: `, message, ...args]);
			}
		},
	};

	return instances[name];
};

export default Logger;
