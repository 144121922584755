import globals from '../../common/globals';

/**
 * Takes the full URL of an asset and returns its id.
 * @param originalUrl - a full URL of an asset with format 
 * `https://domain.com/USER_ID/tutorial/TUTORIAL_ID/uploads/ASSET_ID`
 * @returns the id of the asset and the containing directory (`uploads`).
 */
 const getAssetIdFromUrl = (originalUrl: string): { assetId: string, directory: string } | null => {

  // TODO: This is a workaround for https://app.clickup.com/t/20je63k
  // remove this once solved
  if (originalUrl.startsWith('uploads/')) {
    return {
      assetId: originalUrl.split('/').pop() as string,
      directory: 'uploads',
    };
   }

  const fixedUrl = !originalUrl.startsWith('http') ? `https://${originalUrl}` : originalUrl;
  const url = new URL(fixedUrl);
  const fileId = url.pathname.split('/').pop();
  const directory = url.pathname.split('/').slice(-2, -1).pop();

  if (fileId && directory) {
    return {
      assetId: fileId,
      directory,
    };
  }

  return null;
};

/**
 * Builds the relative URL of an asset uploaded to the tutorial. 
 * @param fullUrl the full URL of the asset.
 * @returns a string that contains only the containing folder and asset id.
 * NOTE: the resulting url will not have a prepended forward slash (`/`).
 */
const buildAssetRelativeUrl = (fullUrl: string): string | null => {
  const parts = getAssetIdFromUrl(fullUrl);
  if (!parts) { return null; }

  return `${parts.directory}/${parts.assetId}`;
};

/**
 * Creates a complete asset url from the given relative url.
 * @param relativeUrl - a relative url of an asset with format which should be the
 * target directory (usually `uploads`) and the id of the asset. The url should NOT have
 * a prepended forward slash (`/`).
 * @param userId - the id of the user the tutorial belongs to.
 * @param tutorialId - the id of the tutorial the asset belongs to.
 * @returns a url that contains full path of the asset including the domain name and user directory
 */
const buildFullAssetUrl = (relativeUrl: string, userId: string, tutorialId: string): string => {
  if (relativeUrl?.startsWith('/')) { throw new Error('Asset URL should not start with a forward slash (`/`)'); }
  return `${globals.TUTORIAL_STATIC}/${userId}/tutorial/${tutorialId}/${relativeUrl}`;
};


export {
  getAssetIdFromUrl,
  buildFullAssetUrl,
  buildAssetRelativeUrl
};
