import React, { ReactElement, useState } from 'react';
import { Row, Col, Spin, Alert } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { Auth } from '@aws-amplify/auth';
import { LoadingOutlined } from '@ant-design/icons';
import { RouteComponentProps } from 'react-router-dom';
import { parse } from 'date-fns';
import AuthCard from '../authCard';
import FormGroup from '../../../components/form-control/formGroup/formGroup';
import ConfirmCode from '../confirm/confirm';
import AccessCode from '../accessCode/accessCode';
import DatePicker from '../../../components/form-control/datePicker/datePicker';
import StyledButton from '../../../components/form-control/styledButton/styledButton';
import StyledInput from '../../../components/form-control/styledInput/styledInput';
import StyledSelect, { Option } from '../../../components/form-control/styledSelect/styledSelect';
import PasswordMeter from '../../../components/form-control/passwordMeter/passwordMeter';
import routes from '../../../common/routes';
import styles from './signup.module.css';
interface SignUpElements {
	email: string;
	firstName: string;
	lastName: string;
	password: string;
	repeatPassword: string;
	agree: boolean;
	gender: string;
	dob: string;
	errors: {
		email?: string;
		firstName?: string;
		lastName?: string;
		password?: string;
		gender?: string;
	}
}

interface SignUpDetails {
	userName: string;
	destination: string;
	userId: string;
	password: string;
}

function SignUp (props: RouteComponentProps): ReactElement {
	const [accessCode, setAccessCode] = useState<string | null>(null);
	const [processing, setProcessing] = useState(false);
	const [submitError, setSubmitError] = useState<string | null>(null);
	const [customGender, setCustomGender] = useState(false);
	const intl = useIntl();
	const [signUpDetails, setSignUpDetails] = useState<SignUpDetails | null>(null);
	const [formElements, setFormElements] = useState<SignUpElements>({
		agree: false,
		firstName: '',
		email: '',
		lastName: '',
		password: '',
		repeatPassword: '',
		dob: '',
		gender: '',
		errors: {}
	});

	const onDateChange = (value: Date | null, dateString: string) => {
		setFormElements(elements => {
			return {
				...elements,
				dob: dateString
			};
		});
	};

	const setFormValue = (propName: keyof SignUpElements, value: string) => {
		setFormElements(elements => {
			return {
				...elements,
				[propName]: value
			};
		});
	};

	const handlePasswordChange = (text: string, hasErrors?: string) => {
		if (hasErrors) {
			setFormElements(elements => ({ ...elements, password: '', errors: { password: hasErrors } }));
		} else {
			setFormValue('password', text);
			setFormElements(elements => ({ ...elements, errors: { password: undefined } }));
		}
	};

	const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
		const elName = event.currentTarget.getAttribute('name') || undefined;
		const value = elName !== 'agree' ? event.currentTarget.value : event.currentTarget.checked;
		if (elName !== undefined && value !== undefined) {
			setFormValue(elName as keyof SignUpElements, value as string);
		}
	};

	const getRepeatPasswordError = (): string | null => {
		let message: string | null = null;
		if (formElements.password.trim() !== formElements.repeatPassword.trim()) {
			message = intl.formatMessage({ id: 'Screen.SignUp.PasswordMismatch', defaultMessage: 'Passwords don\'t match' });
		}

		return message;
	};

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();

		const elements = { ...formElements };
		let formHasErrors = false;
		elements.errors = {};
		setSubmitError(null);

		if (elements.password.trim() !== elements.repeatPassword.trim()) {
			elements.errors.password = intl.formatMessage({ id: 'Screen.SignUp.PasswordMismatch', defaultMessage: 'Passwords don\'t match' });
			formHasErrors = true;
		}

		if (elements.firstName.trim() === '') {
			elements.errors.firstName = intl.formatMessage({ id: 'Screen.SignUp.RequiredField', defaultMessage: '* required' });
			formHasErrors = true;
		}


		if (elements.email.trim() === '') {
			elements.errors.email = intl.formatMessage({ id: 'Screen.SignUp.RequiredField', defaultMessage: '* required' });
			formHasErrors = true;
		}

		if (elements.password.trim() === '') {
			elements.errors.password = intl.formatMessage({ id: 'Screen.SignUp.RequiredField', defaultMessage: '* required' });
			formHasErrors = true;
		}

		if (!elements.gender || elements.gender.trim() === '') {
			elements.errors.gender = intl.formatMessage({ id: 'Screen.SignUp.RequiredField', defaultMessage: '* required' });
			formHasErrors = true;
		}

		if (!elements.agree) {
			return setSubmitError(intl.formatMessage({ id: 'Screen.SignUp.YouMustAgree', defaultMessage: 'You haven\'t agree to the Terms and Conditions and Privacy Policy' }));
		}


		if (formHasErrors) {
			setFormElements(elements);
			return;
		}

		setProcessing(true);
		try {
			const response = await Auth.signUp({
				username: formElements.email,
				password: formElements.password,
				attributes: {
					given_name: formElements.firstName,
					// The account needs to be confirmed in order to set preferred_username
					// preferred_username: formElements.email,
					family_name: formElements.lastName,
					email: formElements.email,
					gender: formElements.gender,
					'custom:access_code': accessCode,
					'custom:dob': formElements.dob,
				}
			});

			console.log('Submit response: ', response);

			if (!response.userConfirmed) {
				setSignUpDetails({
					destination: response.codeDeliveryDetails.Destination,
					userName: response.user.getUsername(),
					userId: response.userSub,
					password: formElements.password
				});
			} else {
				// Student signup?
				// TODO: Redirect to login
			}
		} catch (e) {
			console.log('Error: ', e);
			if (e && e.code === 'UsernameExistsException') {
				setSubmitError(null);
				setFormElements(current => ({
					...current,
					errors: {
						email: intl.formatMessage({ id: 'Screen.SignUp.UsernameExistsException', defaultMessage: e.message })
					}
				}));
			} else {
				setSubmitError(e.message ? e.message : intl.formatMessage({ id: 'Screen.SignUp.UnknownError', defaultMessage: 'There was an error sending a new confirmation code' }));
			}
		}

		setProcessing(false);
	};

	const handleAccessCode = (code: string) => {
		setAccessCode(code);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onGenderChange = (value: any) => {
		if (value !== 'custom') {
			setCustomGender(false);
			setFormValue('gender', value);
		} else {
			setFormValue('gender', '');
			setCustomGender(true);
		}
	};

	const handleCustomGender = (event: React.FormEvent<HTMLInputElement>) => {
		setFormValue('gender', event.currentTarget.value);
	};

	if (signUpDetails) {
		return <ConfirmCode userId={signUpDetails.userId} password={signUpDetails.password} destination={signUpDetails.destination} username={signUpDetails.userName} {...props}/>;
	}

	const canSignUp = formElements.agree &&
		!!formElements.email.trim() &&
		!!formElements.password.trim() &&
		!!formElements.firstName.trim() &&
		formElements.password === formElements.repeatPassword;

	if (!accessCode) {
		return <AccessCode onCodeValid={handleAccessCode} />;
	}

	return (
		<AuthCard title={<FormattedMessage id="Screen.SignUp.Title" defaultMessage="Register" />}>
			<Spin indicator={<LoadingOutlined />} spinning={processing}>
				{submitError && (
					<>
						<Alert closable={false} message={submitError} type="error" />
						<br/>
					</>
				)}

				<form method="POST" onSubmit={handleSubmit}>
					<Row gutter={15}>
						<Col xs={24} sm={12} md={12} lg={12} xl={12}>
							<FormGroup>
								<StyledInput i18nPlaceholderId="Screen.SignUp.FirstName" i18nPlaceholderDefaultMessage="* First Name" name="firstName" onChange={handleChange} value={formElements.firstName} errorMessage={formElements.errors.firstName}/>
							</FormGroup>
						</Col>
						<Col xs={24} sm={12} md={12} lg={12} xl={12}>
							<FormGroup>
								<StyledInput i18nPlaceholderId="Screen.SignUp.LastName" i18nPlaceholderDefaultMessage="Last Name" name="lastName" onChange={handleChange} value={formElements.lastName} errorMessage={formElements.errors.lastName}/>
							</FormGroup>
						</Col>
					</Row>

					<Row gutter={15}>
						<Col xs={24} sm={12} md={12} lg={12} xl={12}>
							{/* <FormGroup row> */}
								<div className={styles.GenderContainer}>
									<FormGroup column>
										<StyledSelect onChange={onGenderChange} className={styles.GenderSelect} placeholder="Gender" allowClear errorMessage={!customGender ? formElements.errors.gender : ''}>
											<Option value="female">Female</Option>
											<Option value="male">Male</Option>
											<Option value="wont-say">Rather not say</Option>
											<Option value="custom">Custom</Option>
										</StyledSelect>

										{customGender && (
											<StyledInput errorMessage={formElements.errors.gender} value={formElements.gender !== 'custom' ? formElements.gender : ''} placeholder="Enter gender" className={styles.CustomInput} onChange={handleCustomGender}/>
										)}
									</FormGroup>
								</div>
							{/* </FormGroup> */}
						</Col>
						<Col xs={24} sm={12} md={12} lg={12} xl={12}>
							<FormGroup>
								{/* <DatePicker defaultValue={dfn('2015/01/01', dateFormat)} format={dateFormat} /> */}
								<DatePicker name="dob" onChange={onDateChange} value={formElements.dob ? parse(formElements.dob, 'dd/MM/yyyy', new Date()) : undefined} format='dd/MM/yyyy' placeholder="Birthday"/>
							</FormGroup>
						</Col>
					</Row>

					<Row>
						<Col xs={24} sm={24} lg={24}>
							<FormGroup>
								<StyledInput i18nPlaceholderId="Screen.SignUp.Email" i18nPlaceholderDefaultMessage="* Email" name="email" onChange={handleChange} value={formElements.email} errorMessage={formElements.errors.email}/>
							</FormGroup>
						</Col>
					</Row>

					<Row gutter={15}>
						<Col xs={24} sm={12} md={12} lg={12} xl={12}>
							<FormGroup>
								{/* <StyledInput i18nPlaceholderId="Screen.SignUp.Password" i18nPlaceholderDefaultMessage="* Password" type="password" name="password" onChange={handleChange} value={formElements.password} errorMessage={formElements.errors.password}/> */}
								<PasswordMeter
									upperCase
									lowerCase
									digits={1}
									min={8}
									symbols={1}
									value={formElements.password}
									i18nPlaceholderId="Screen.SignUp.Password"
									i18nPlaceholderDefaultMessage="* Password"
									name="password"
									errorMessage={formElements.errors.password}
									onChange={handlePasswordChange}
								/>
							</FormGroup>
						</Col>

						<Col xs={24} sm={12} md={12} lg={12} xl={12}>
							<FormGroup>
								<StyledInput
									i18nPlaceholderId="Screen.SignUp.ConfirmPassword"
									i18nPlaceholderDefaultMessage="* Password Confirmation"
									type="password"
									name="repeatPassword"
									onChange={handleChange}
									value={formElements.repeatPassword}
									errorMessage={formElements.password.length ? getRepeatPasswordError() : null}
								/>
							</FormGroup>
						</Col>
					</Row>

					<Row>
						<Col span={24}>
							<FormGroup className={styles.AgreeContainer}>
								<input type="checkbox" name="agree" id="agree" onChange={handleChange} />
								<label className={styles.AgreeLabel} htmlFor="agree">
									<FormattedMessage
										id="Screen.SignUp.TermsCheckbox"
										defaultMessage="I agree to the {termsLink} and {privacyPolicyLink}"
										values={{
											termsLink: <a target="_blank" href={routes.auth.getTermsRoute()} rel="license noreferrer"><FormattedMessage id="Screen.SignUp.Terms" defaultMessage="Terms of Service" /></a>,
											privacyPolicyLink: <a target="_blank" href={routes.auth.getPrivacyPolicyRoute()} rel="license noreferrer"><FormattedMessage id="Screen.SignUp.PrivacyPolicy" defaultMessage="Privacy Policy" /></a>
										}}
									/>
								</label>
							</FormGroup>
						</Col>
					</Row>


					<Row>
						<Col span={24}>
							<FormGroup className={styles.SubmitContainer}>
								<StyledButton disabled={!canSignUp} title={<FormattedMessage id="Screen.SignUp.SubmitButton" defaultMessage="Register" />} type="submit" className={styles.SubmitButton} />
							</FormGroup>
						</Col>
					</Row>
				</form>

				<div className={styles.CardBottomText}>
					<FormattedMessage id="Screen.SignUp.AlreadyRegister" defaultMessage="Already Registered?"/>
					&nbsp;<a href={routes.auth.getSignInRoute()}><FormattedMessage id="Screen.SignUp.LoginLink" defaultMessage="Login" /></a>
				</div>
			</Spin>
		</AuthCard>
	);
}

export default SignUp;
