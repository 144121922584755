import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import widgetBundleManager, { ValidatedBundle } from '@kemu-io/kemu-core/dist/widgetBundle/manager';
import kemuCore from '@kemu-io/kemu-core/dist/index';
import { findThingInRecipe, findWidgetInRecipe } from '@kemu-io/kemu-core/dist/common/recipeCache';
import { CustomWidgetState, WidgetBundleState } from '@kemu-io/kemu-core/dist/types';
import { removePrivateProperties } from '@kemu-io/kemu-core/dist/common/utils';
import { LogicMapperState, showCreateWidgetBundleModalAction } from '../logicMapperSlice';
import { addBundleToRecipeStorage } from './common';
import { StatelessWidgetsMap } from '@src/types/core_t';
import { widgetMapToStatelessMap } from '@common/utils';

type UpdateTempWidgetBundlePayload = {
  zipHandler: ValidatedBundle['zipHandler'];
  /** the id of the recipe in the pool */
  recipeId: string;
  /** the id of the thing in the recipe the widget will be added to */
  thingId: string;
  widgetId: string;

  name: string;
  description: string;
  color: string;
  icon: string;
}

/**
 * Replaces the temporal cache storage with the given zip contents.
 */
export const updateTempWidgetBundleAction = createAsyncThunk(
  'LogicMapper/updateTempWidgetBundle',  async (
    payload: UpdateTempWidgetBundlePayload,
    thunkApi
) : Promise<{
  widgetsMap: StatelessWidgetsMap;
  newWidgetId: string;
  newWidgetVersion: number;
}> => {

  const widgetId = payload.widgetId;
  // First we need to create a new empty widget bundle in the thing.
  const targetThing = findThingInRecipe(payload.recipeId, payload.thingId);
  if (!targetThing) {
    throw new Error(`Could not find thing with id ${payload.thingId}`);
  }

  const currentWidget = findWidgetInRecipe(payload.recipeId, payload.thingId, widgetId);
  if (!currentWidget) {
    throw new Error(`Could not find widget with id ${widgetId}`);
  }

  const currentWidgetState = {
    ...currentWidget.state as unknown as WidgetBundleState
  };

  // Create a new state for the current widget, replacing the new properties
  const widgetState: WidgetBundleState = {
    ...currentWidgetState,
    description: payload.description,
    name: payload.name,
    color: payload.color,
    icon: payload.icon,
  };

  if (!widgetState.$$cacheInfo) {
    throw new Error(`Missing cache info for ${widgetId}`);
  }

  // Replace the default state in the widget with the current one.
  const stateWithoutPrivateProps = removePrivateProperties<WidgetBundleState>(widgetState);
  const { newZipFile } = await widgetBundleManager.replaceStateInZip(
    payload.zipHandler,
    stateWithoutPrivateProps,
    {
      noGenerateFile: false,
    }
  );

  if (!newZipFile) {
    throw new Error('Failed to replace state in zip');
  }

  // Update the version of the widget
  const prevVersion = widgetState.$$cacheInfo.version;
  const nextVersion = prevVersion + 1;

  // Install the widget contents in a new location
  const processedBundle = await widgetBundleManager.installBundleInTempCache(
    newZipFile,
    payload.widgetId,
    nextVersion,
  );

  if (!processedBundle) {
    throw new Error('Failed generate new bundle location');
  }

  // Create a new state with the private properties to replace
  // the current instance in the recipe. IMPORTANT: We make sure to
  // remove existing private properties to force the new instance to 
  // re-create whatever it needs during initialization.
  const cleanState = removePrivateProperties<WidgetBundleState>(widgetState);
	const updatedState: CustomWidgetState<WidgetBundleState> = {
    ...cleanState,
    // IMPORTANT: update the processor instance in case a new bundle file was added.
    $$processor: processedBundle.processor,
    ...(widgetState.$$collectionInfo ? {
      $$collectionInfo: widgetState.$$collectionInfo,
    } : {}),
    $$cacheInfo: {
      widgetThingId: widgetId, // <== refresh the cache id to make sure we point to the right location
      version: nextVersion,
    }
  };

  // Cleanly terminate the previous widget instance
  if (typeof currentWidgetState.$$processor?.terminate === 'function') {
    console.log('Terminating previous widget instance');
    await kemuCore.terminateWidget(payload.recipeId, payload.thingId, widgetId);
  }

  // Also call set state with the current state to point to the new processor,
  // also triggers re-rendering.
  kemuCore.setGateState(payload.recipeId, payload.thingId, widgetId, updatedState, true);

  // Re-initialize the widget bundle
  await kemuCore.initializeWidget(payload.recipeId, payload.thingId, widgetId);

  // Finally we clear out the cache used by the previous version
  await widgetBundleManager.removeWidgetFilesFromCache(widgetId, String(prevVersion), true);

  const updatedWidgets = widgetMapToStatelessMap(targetThing.gates);

  // Hide the modal
  thunkApi.dispatch(showCreateWidgetBundleModalAction(false));

  return {
    widgetsMap: updatedWidgets,
    newWidgetId: widgetId,
    newWidgetVersion: nextVersion,
  };
});

export const updateTempWidgetBundleReducer = ((builder: ActionReducerMapBuilder<LogicMapperState>): void => {
  builder.addCase(updateTempWidgetBundleAction.fulfilled, (state, action) => {
    state.gates = action.payload.widgetsMap;
  });

  builder.addCase(updateTempWidgetBundleAction.rejected, (_draft, action) => {
    console.error('Failed to add widget from bundle: ', action.error);
  });
});
