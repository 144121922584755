import auth from '@aws-amplify/auth';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import {
  DynamoService,
  GoogleSheetsService,
  HttpService,
  ServiceResponse
} from '@kemu-io/kemu-core/dist/types/webServices_t';
import {
  SendRequestBody,
  ScanItemsRequest,
  GetRowsRequestBody,
  SaveRowsRequestBody,
  ClearSheetRequestBody,
} from '@kemu-io/kemu-types/dist/types';
import globals from '@common/globals';
import { getCustomHeader } from '@common/amplify';

let axiosInstance: AxiosInstance;

const getAxiosInstance = async (): Promise<AxiosInstance> => {
  const authHeader = await getCustomHeader();

  if (!axiosInstance) {
    axiosInstance = axios.create({
      headers: {
        ...authHeader,
      }
    });
  }

  axiosInstance.defaults.headers.common = {
    ...axiosInstance.defaults.headers.common,
    ...authHeader,
  };

  return axiosInstance;
};

export const buildHttpService = (): HttpService => {
  return {
    request: async (config: SendRequestBody<unknown>): Promise<ServiceResponse> => {
      const axiosInstance = await getAxiosInstance();
      try {
        const response = await axiosInstance.post(`${globals.WEB_SERVICES_API}/service/http/sendRequest`, config);
        return response.data;
      } catch (e) {
        return {
          success: false,
          data: e.response?.data || e.message || 'Unknown error',
          statusCode: e.response?.status || e.code,
          ...(e.response?.statusText ? { statusText: e.response.statusText } : {})
        };
      }
    }
  };
};

export const buildDynamoDbService = (): DynamoService => {
  return {
    scan: async (config: ScanItemsRequest) => {
      return withHandledErrors(async () => {
        const axiosInstance = await getAxiosInstance();
        return axiosInstance.post(`${globals.WEB_SERVICES_API}/service/dynamodb/scan`, config);
      });
    }
  };
};


const withHandledErrors = async (fn: () => Promise<AxiosResponse>): Promise<ServiceResponse> => {
  try {
    const result = await fn();
    return {
      success: true,
      data: result.data
    };
  } catch (e) {
    return {
      success: false,
      data: e.response?.data || e.message || 'Unknown error',
      statusCode: e.response?.status || e.code,
      ...(e.response?.statusText ? { statusText: e.response.statusText } : {})
    };
  }
};

export const buildGoogleSheetsService = (): GoogleSheetsService => {
  return {
    getRows: async (config: GetRowsRequestBody) => {
      return withHandledErrors(async () => {
        const axiosInstance = await getAxiosInstance();
        return axiosInstance.post(`${globals.WEB_SERVICES_API}/service/googleSheets/getRows`, config);
      });
    },
    saveRows: async (config: SaveRowsRequestBody) => {
      return withHandledErrors(async () => {
        const axiosInstance = await getAxiosInstance();
        return axiosInstance.post(`${globals.WEB_SERVICES_API}/service/googleSheets/saveRows`, config);
      });
    },
    clearSheet: async (config: ClearSheetRequestBody) => {
      return withHandledErrors(async () => {
        const axiosInstance = await getAxiosInstance();
        return axiosInstance.post(`${globals.WEB_SERVICES_API}/service/googleSheets/clearSheet`, config);
      });
    },
  };
};
