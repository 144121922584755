/*
 * Written by Alexander Agudelo < alex@kemu.io >, 2020
 * Date: 29/Nov/2020
 * Last Modified: 15/10/2023, 9:57:28 am
 * Modified By: Alexander Agudelo
 * Description:  Helper functions to manipulate the cached recipe. This module
 * does NOT perform any type of UI or state changes, it modifies the recipe cache directly and
 * accesses Kemu Core functionality
 * 
 * ------
 * Copyright (C) 2020 Kemu - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential.
 */

import { nanoid, customAlphabet } from 'nanoid';
import {
	findBlockInRecipe,
	findThingInRecipe,
	findWidgetInRecipe,
	findRecipe
} from '@kemu-io/kemu-core/dist/common/recipeCache';
import widgetBundleManager from '@kemu-io/kemu-core/dist/widgetBundle/manager';
import kemuCore from '@kemu-io/kemu-core/dist/index';
import {
	BlockAction,
	BlockCanvasInfo,
	BlockInput,
	ChildBlock,
	Position,
	RecipeBlock,
	BlockState,
	ExecuteActionResponse
} from '@kemu-io/kemu-core/dist/types/block_t';
import {
	CustomWidgetState,
	Data,
	GateCanvasInfo,
	GateChild,
	GatePort,
	GateState,
	WidgetType,
	GroupWidgetPort,
	InputGateState,
	PartialData,
	PortType,
	RecipeWidget,
	WidgetPortIdentifier
} from '@kemu-io/kemu-core/dist/types/gate_t';
import GatesManager from '@kemu-io/kemu-core/dist/gates/manager';
import BlocksManager from '@kemu-io/kemu-core/dist/blocks/manager';
import * as LogicProcessor from '@kemu-io/kemu-core/dist/blocks/logicProcessor';
import { BlockMap, CachedRecipe, RecipeCanvasInfo, RecipeStorage } from '@kemu-io/kemu-core/dist/types/recipe_t';
import { WidgetGroupState } from '@kemu-io/kemu-core/dist/gates/widgetGroup';
import { LimitedThingInfo, RecipeWidgetMeta, ThingCategory, ThingType, CustomWidgetVariant } from '@kemu-io/kemu-types/dist/types';
import { SaveRecipeRequestInfo } from '@kemu-io/kemu-types/dist/types/recipe';
import {
	addToStorageUnit,
	getStorageUnitFromKey,
	removeStorageUnit,
	removePrivateProperties
} from '@kemu-io/kemu-core/dist/common/utils';
import { KEMU_ID_MARK } from '@kemu-io/kemu-core/dist/common/constants';
import { WidgetBundleState, getDefaultState as getDefaultWidgetBundleState } from '@kemu-io/kemu-core/dist/gates/widgetBundle';
import { WidgetCollectionItem } from '../reducers/widget/widgetSlice';
import { WidgetsMap } from '@src/types/core_t';
import { generateDomId, safeJsonClone, safeJSONStringify } from '@common/utils';
import { CANVAS_DRAGGABLE_CLASS, LM_CANVAS_CONTAINER_CLASS, LOGIC_MAPPER_CANVAS_CLASS } from '@common/constants';
import { ParsedWidgetContents } from '@src/types/widgets';
import { RecipeInfo } from '@src/types/canvas_t';
import { RecipeMetaFields } from '@src/types/recipe_t';

/** 
 * Generates consistent and unique block id within the recipe scope
 * @param recipeId is the id of the recipe where the block will be located.
 * It is used to search for existing blocks and make sure no 2 IDs are equal
 **/
const _getUniqueBlockId = (recipe: CachedRecipe) => {
	// Make sure IDs are unique within the recipe
	const len = 4;
	let id = getRandomId(len);
	while (recipe.blocks[id]) { id = getRandomId(len); }
	// Generate an id like: {#}ABCD
	// the {#} is used to identify references to this block and easily update them
	// during merge
	return `${KEMU_ID_MARK}${id}`;
};

/**
 * Uses the last block in the recipe to determine where the new block should be added
 * @param poolId the id of the recipe in the pool
 * @param recipe the actual recipe contents
 */
const _getDefaultBlockPosition = (poolId: string, recipe: CachedRecipe): Position => {
	const blockKeys = Object.keys(recipe.blocks);
	if (blockKeys.length) {
		const lastAddedBlock = recipe.blocks[blockKeys[blockKeys.length-1]];
		const nextPosition = _getNextBlockPosition(poolId, lastAddedBlock);
		return nextPosition;
	} else {
			// The first block
		return {
			x: 200,
			y: 200
		};
	}
};

/**
 * Adds a new block to the recipe
 * @param recipeId the id of the recipe in the pool
 * @param blockInfo information about the block from the DB
 * @param allowOnlyOneType If true it will not add a new block if there is one that shares the same
 * dbId and version number.
 * @returns a reference of the newly added block. IMPORTANT: Mutating this reference
 * WILL mutate the original object.
 */
const addBlock = (recipeId: string, blockInfo: LimitedThingInfo, allowOnlyOneType=false): RecipeBlock | null => {
	const recipe = findRecipe(recipeId);
	if (!recipe) { throw new Error(`Recipe [${recipeId} does not exist]`); }
	const position = _getDefaultBlockPosition(recipeId, recipe);
	const id = _getUniqueBlockId(recipe);

	// Make sure the block doesn't exist
	// FIXME: Write unit test
	if (allowOnlyOneType) {
		const existingBlock = Object.keys(recipe.blocks).find(brId => recipe.blocks[brId].id === blockInfo.id && recipe.blocks[brId].version === blockInfo.version);
		if (existingBlock) {
			console.error(`Block ${blockInfo.id} with version ${blockInfo.version} already exists in the recipe. Aborting`);
			return null;
		}
	}

	const blockModule = BlocksManager.getThingWebProcessor(blockInfo.id, blockInfo.version);

	const inputs = blockModule.getInputPortsNames && blockModule.getInputPortsNames() || [];
	const outputs = blockModule.getOutputPortsNames && blockModule.getOutputPortsNames() || [];

	const block: RecipeBlock = {
		canvas: { logicMaker: { position: { x: 0, y: 0 } }, position },
		category: blockInfo.category as ThingCategory,
		children: [],
		gates: {},
		id: blockInfo.id,
		inputPorts: _getUniqueBlockPorts(inputs, id, recipeId),
		outputPorts: _getUniqueBlockPorts(outputs, id, recipeId),
		name: blockInfo.name,
		state: {},
		type: blockInfo.type,
		version: blockInfo.version,
		recipeId: id
	};

	recipe.blocks[id] = block;
	return block;
};

/**
 * Removes a block from the recipe and all references to it from parent blocks.
 * This method also deletes all storage units that belong to the block.
 * @param recipeId the recipe pool id
 * @param blockId the id of the block in the recipe
 * @returns a dictionary of the remaining blocks in the recipe. 
 * WARNING: Mutating the returned reference WILL mutate the cached recipe object
 */
const removeBlock = (recipeId: string, blockId: string): BlockMap => {
	const recipe = findRecipe(recipeId);
	if (!recipe) { throw new Error(`Recipe [${recipeId} does not exist]`); }

	const block = findBlockInRecipe(recipeId, blockId);
	if (!block) { throw new Error(`Block ${blockId} does not exist in recipe ${recipeId}`); }

	// Delete references from parents
	for (const parent in recipe.blocks) {
		const foundAtIndex = (recipe.blocks[parent].children || []).findIndex(child => child.blockId === blockId);
		if (foundAtIndex !== -1) {
			recipe.blocks[parent].children.splice(foundAtIndex, 1);
		}
	}

	// Delete storage references
	if (block.storageUnits && recipe.storage) {
		for (const unitId of block.storageUnits) {
			delete recipe.storage[unitId];
		}
	}

	// Delete the actual block
	delete recipe.blocks[blockId];
	return recipe.blocks;
};

/**
 * Finds the best location for a block. By default, th
 * @param recipe a reference to the recipe
 * @param sourceBlock if provided, the next location will be 
 * 'close' to the given source. Otherwise, the next location will be 'close'
 * to the last block in the recipe
 */
const _getNextBlockPosition = (recipeId: string, sourceBlock: RecipeBlock) => {
	let BLOCK_WIDTH = 130;
	const leftPadding = 50;		// distance from the right of the last block
	const blockDomEl = getEntityDomEl(recipeId, sourceBlock.recipeId);
	if (blockDomEl) {
		BLOCK_WIDTH = blockDomEl.getBoundingClientRect().width || BLOCK_WIDTH;
	}

	// TODO: Make sure the new position is not outside the view port, if so, choose a different position.
	return {
		// Add them horizontally
		x: sourceBlock.canvas.position.x + BLOCK_WIDTH + leftPadding,
		y: sourceBlock.canvas.position.y,
	};
};

/***
 * Clones an existing block excluding children. It also clones the storage units contents
 * @param recipeId the id of the recipe in the pool
 * @param blockRecipeId the id of the block in the recipe (recipeId property)
 * @returns a reference to the newly added block. IMPORTANT: modifying the returned reference
 * WILL modify the stored object.
 */
const duplicateBlock = (recipeId: string, blockRecipeId: string): RecipeBlock => {
	const recipe = findRecipe(recipeId);
	if (!recipe) { throw new Error(`Recipe [${recipeId} does not exist]`); }
	const sourceBlock = findBlockInRecipe(recipeId, blockRecipeId);
	if (!sourceBlock) { throw new Error(`Block ${blockRecipeId} does not exist in recipe ${recipeId}`); }

	// Clone everything, specially the 'state'
	const cleanCopy = safeJSONStringify(sourceBlock);
	const clonedBlock: RecipeBlock = JSON.parse(cleanCopy);
	const id = _getUniqueBlockId(recipe);

	const blockModule = BlocksManager.getThingWebProcessor(clonedBlock.id, clonedBlock.version);

	// Extract the port names so we can generate unique ones using the standard method
	const inputNames = blockModule.getInputPortsNames && blockModule.getInputPortsNames() || [];
	const outputNames = blockModule.getOutputPortsNames && blockModule.getOutputPortsNames() || [];

	// Replace values
	clonedBlock.recipeId = id;
	clonedBlock.canvas.position = _getNextBlockPosition(recipeId, sourceBlock);
	clonedBlock.children = [];
	clonedBlock.storageUnits = [];
	clonedBlock.inputPorts = _getUniqueBlockPorts(inputNames, id, recipeId);
	clonedBlock.outputPorts = _getUniqueBlockPorts(outputNames, id, recipeId);

	if (recipe.storage) {
		let stateStr = JSON.stringify(clonedBlock.state);
		for (const oldStorageId of sourceBlock.storageUnits || []) {
			const storageSource = recipe.storage[oldStorageId];
			const newDiskId = nanoid(4);
			const markedId = `${KEMU_ID_MARK}${newDiskId}`;
			const diskCopy = new Uint8Array(storageSource);
			// Add copied data to storage
			recipe.storage[markedId] = diskCopy;
			// Add reference to cloned instance
			clonedBlock.storageUnits.push(markedId);
			// Update the state of the block so that any reference to the modify keys can be updated
			stateStr = replaceMarkedIds(stateStr, oldStorageId, newDiskId);
		}

		// Restore the block state
		clonedBlock.state = JSON.parse(stateStr);
	}



	recipe.blocks[id] = clonedBlock;
	return clonedBlock;
};

/**
 * Registers a block as a child in the recipe. This method does NOT handle
 * any type of UI connections or events.
 * @param recipeId the recipe pool id
 * @param source the id of the parent block in the recipe (recipeId)
 * @param target the id of the block in the recipe (recipeId) to add as a child
 * @param sourcePort the id of the source port (current block)
 * @param targetPort the id of the port in the `target` to attach the connection to
 * @returns an updated reference of the list of children of the parent block. IMPORTANT: Mutating
 * the returned reference WILL mutate the original object
 */
const registerChildBlock = (recipeId: string, source: string, target: string, sourcePort: string, targetPort: string): ChildBlock[] => {
	const block = findBlockInRecipe(recipeId, source);
	if (!block) { throw new Error(`Block ${source} does not exist in recipe ${recipeId}`); }

	const child = {
		blockId: target,
		sourcePort,
		targetPort
	};

	block.children.push(child);

	return block.children;
};

/**
 * Removes an existing child from a block in the recipe. This method does NOT handle
 * any type of UI connections or events.
 * @param recipeId the recipe pool id
 * @param source the id of the parent block in the recipe (recipeId)
 * @param target the id of the block in the recipe (recipeId) to add as a child
 * @param sourcePort the id of the source port (current block)
 * @param targetPort the id of the port in the `target` to attach the connection to
 * @returns an updated reference of the list of children of the parent block. IMPORTANT: Mutating
 * the returned reference WILL mutate the original object
 */
const removeChildBlock = (recipeId: string, source: string, target: string, sourcePort: string, targetPort: string): ChildBlock[] => {
	const block = findBlockInRecipe(recipeId, source);
	if (!block) { throw new Error(`Block ${source} does not exist in recipe ${recipeId}`); }

	const index = block.children.findIndex(child => child.blockId === target && child.sourcePort === sourcePort && child.targetPort === targetPort);
	if (index !== -1) {
		block.children.splice(index, 1);
	}

	return block.children;
};


/**
 * Registers a gate as a child. This method does NOT handle
 * any type of UI connections or events.
 * @param recipeId the recipe pool id
 * @param blockId the id of the block (recipeId) the gate is located in
 * @param source the id of the parent gate in the recipe (recipeId)
 * @param target the id of the gate in the recipe (recipeId) to add as a child
 * @param sourcePort the id of the source port (current gate)
 * @param targetPort the id of the port in the `target` to attach the connection to
 * @returns an updated reference to the list of children of the parent gate. IMPORTANT: Mutating the reference
 * WILL mutate the original object
 */
const registerChildGate = (recipeId: string, blockId: string, source: string, target: string, sourcePort: string, targetPort: string): GateChild[] => {
	const block = findBlockInRecipe(recipeId, blockId);
	if (!block) { throw new Error(`Block ${blockId} does not exist in recipe ${recipeId}`); }

	const parentGate = block.gates[source];
	const childGate = block.gates[target];
	if (parentGate && childGate) {
		parentGate.children.push({
			childId: target,
			sourcePort: (sourcePort as WidgetPortIdentifier),
			targetPort: (targetPort as WidgetPortIdentifier)
		});
	}

	return parentGate.children;
};


/**
 * Checks whether the target gate is already attached to the same port of the source gate
 * @param recipeId the recipe pool id
 * @param blockId the id of the block (recipeId) the gate is located in
 * @param source the id of the parent gate in the recipe (recipeId)
 * @param target the id of the gate in the recipe (recipeId) to add as a child
 * @param sourcePort the id of the source port (current gate)
 * @param targetPort the id of the port in the `target` to attach the connection to
 */
const isGateAChildAtPort = (recipeId: string, blockId: string, source: string, target: string, sourcePort: string, targetPort: string): boolean => {
	const block = findBlockInRecipe(recipeId, blockId);
	if (!block) { throw new Error(`Block ${blockId} does not exist in recipe ${recipeId}`); }

	const alreadyAChild = block.gates[source].children.find(child => {
		return (
			child.childId === target &&
			child.sourcePort === sourcePort &&
			child.targetPort === targetPort
		);
	});

	return !!alreadyAChild;
};

/**
 * Removes an existing child gate from its parent. This method does NOT handle
 * any type of UI connections or events.
 * @param recipeId the recipe pool id
 * @param blockId the id of the block (recipeId) the gate is located in
 * @param source the id of the parent gate in the recipe (recipeId)
 * @param target the id of the gate in the recipe (recipeId) to add as a child
 * @param sourcePort the id of the source port (current gate)
 * @param targetPort the id of the port in the `target` to attach the connection to
 * @returns an updated reference to the list of children of the parent gate. IMPORTANT: Mutating the reference
 * WILL mutate the original object
 */
const removeChildGate = (recipeId: string, blockId: string, source: string, target: string, sourcePort: string, targetPort: string): GateChild[] => {
	const block = findBlockInRecipe(recipeId, blockId);
	if (!block) { throw new Error(`Block ${blockId} does not exist in recipe ${recipeId}`); }

	const parentGate = block.gates[source];
	const childGate = block.gates[target];
	if (parentGate && childGate) {
		const index = parentGate.children.findIndex(child => child.childId === target && child.sourcePort === sourcePort && child.targetPort === targetPort);
		if (index !== -1) {
			parentGate.children.splice(index, 1);
		}
	}

	return parentGate.children;
};

/**
 * @param recipeId the recipe pool id
 * @param blockId the id of the block (recipeId) the gate is located in
 * @param widgetId id of the parent widget
 * @param sourcePort id of the source port children are connected to
 * @returns a list of widgets connected to the given port
 */
const getChildrenWidgets = (recipeId: string, blockId: string, widgetId: string, sourcePort: WidgetPortIdentifier): GateChild[] => {
	const widget = findWidgetInRecipe(recipeId, blockId, widgetId);
	if (!widget) { throw new Error(`Widget ${widgetId} does not exist in block ${blockId} and recipe ${recipeId}`); }
	const children = widget.children.filter(child => child.sourcePort === sourcePort);
	return children;
};

/** 
 * Generates consistent and unique gate id within the block scope
 * @param thing a reference to the target Thing the generated widget id will
 * be placed into. This is used to make sure no other widget exists
 **/
const generateUniqueWidgetId = (thing: RecipeBlock): string => {
	// Make sure IDs are unique within the block
	const len = 4;
	let id = getRandomId(len);
	while (thing.gates[id]) { id = getRandomId(len); }
	// Generate an id like: {#}ABCD
	// the {#} is used to identify references to this gate and easily update them
	// during merge
	return `${KEMU_ID_MARK}${id}`;
};

/**
 * @deprecated use `generateUniqueWidgetId` instead
 */
const generateUniqueGateId = generateUniqueWidgetId;

/**
 * Determines the position of a gate based on the last one added.
 * TODO: Position gates based on their type (inputs on the left, actions on the right)
 * 
 * @param recipeId the id of the recipe in the pool
 * @param blockId the id of the block in the recipe
 * @param type the type of gate being added
 * @param gatesMap a list of other gates already added to the block. It will be used to calculate
 * the best position for the new gate
 */
const _getDefaultGatePosition = (recipeId: string, blockId: string, type: WidgetType, gatesMap: Record<string, RecipeWidget>): Position => {
	type GateDimensions = {cx: number, cy: number, left: number, top: number, w: number, h: number};
	const defaultX = 100;
	const defaultY = 100;
	const paddingBottom = 10;

	const gatesKeys = Object.keys(gatesMap || {});
	let lastGateDimensions: GateDimensions | null = null;

	if (gatesKeys.length) {
		const lastAddedGate = gatesMap[gatesKeys[gatesKeys.length-1]];
		const lastGateDomEl = getEntityDomEl(recipeId, blockId, lastAddedGate.id);

		if (lastGateDomEl) {
			const boundingBox = lastGateDomEl.getBoundingClientRect();

			lastGateDimensions = {
				cx: lastAddedGate.canvas.position.x,			// Absolute positioning
				cy: lastAddedGate.canvas.position.y,
				left: boundingBox.left,
				top: boundingBox.top,
				w: boundingBox.width,
				h: boundingBox.height,
			};
		}
	}

	if (lastGateDimensions) {
		return {
			// Horizontally
			x: lastGateDimensions.cx,
			y: lastGateDimensions.cy + lastGateDimensions.h + paddingBottom
		};
	} else {
		return {
			x: defaultX,
			y: defaultY
		};
	}
};

/**
 * Creates a string to be used as a port Id that guaranteed to be unique across recipe instances.
 * It follows the ID markers convention.
 * @param portName name of the port (this is what users see in the interface)
 * @param entityId the id of the gate in the block or the block in the recipe
 * @param recipeId is the id of the recipe in the pool. 
 */
// const getUniquePortId = (portName: string, entityId: string, recipeId: string): string => {
// 	return `${KEMU_ID_MARK}${recipeId}_${entityId}_${portName}`;
// };

/**
 * Creates a unique port identifier for a widget (aka gate) based on its index, type and the widet it belongs to
 * @param portIndex the index of the port within the gate
 * @param type the type of port this index is for
 * @param gateId the id of the gate in the block
 * @param blockId the id of the block in the recipe (recipeId property)
 * @param recipeId the id of the recipe in the pool
 */
// const getUniqueGatePortId = (portIndex: number, type: 'input' | 'output', gateId: string, blockId: string, recipeId: string): string => {
const getUniqueGatePortId = (portIndex: number, type: PortType, gateId: string): string => {
	const name = `${gateId}_${type}_${portIndex}`;
	return `${name}`;
};

/**
 * Translate the source or target port properties of a widget children link.
 * @param childPort the id of the port with or without the widget id (Eg: input_0 OR {#}widget123_input_0);
 * 
 */
const decodeChildPortIdentifier = (childPort: string): {portType: PortType, portIndex: number} => {
	const sp = childPort.split('_');
	if (sp.length < 2) { throw new Error(`Unknown child identifier format [${childPort}]`); }
	const lastTwo = sp.splice(-2);
	const portType = lastTwo[0] as PortType;// === 'input' ? 'input' : 'output';
	const portIndex = Number(lastTwo[1]);
	return {
		portType,
		portIndex
	};
};

/**
 * Creates an identifier for a widget port which encodes information about the port type and index.
 * @param portIndex is the index of the port within the widget
 * @param portType the type of port (input or output)
 */
const createChildPortIdentifier = (portType: PortType, portIndex: number): WidgetPortIdentifier => {
	return `${portType}_${portIndex}`;
};

export type DecodedGatePortInfo = {
	gateId: string;
	portType: PortType;
	portIndex: number;
};

/**
 * Translates a port id generated via `getUniqueGatePortId` into its
 * individual components.
 * 
 * PortId is expected to have the following format: {#}{gateId}_[input|output]_{index} Eg: `{#}dJfs_out_0`
 */
const decodeGatePortId = (portId: string): DecodedGatePortInfo => {
	const sp = portId.split('_');
	if (sp.length !== 3) { throw new Error(`Unknown port id format [${portId}]`); }

	const gateId = sp[0];
	const identifier = decodeChildPortIdentifier(portId);

	return {
		gateId,
		...identifier
	};
};


/**
 * Creates a list of unique gate ports by using the entity's id as the port name
 * @param portsNames list of input or output names of the gate
 * @param entityId the id of the gate in the block or the block in the recipe
 * @param recipeId is the id of the recipe in the pool. 
 * NOTE: Recipe Id is an extra added layer of uniqueness to allow the same recipe
 * to be loaded multiple times without conflicts between their ports.
 */
const _getUniqueBlockPorts = (portsNames: string[], entityId: string, recipeId: string): GatePort[] => {
	const res = portsNames.map(name => ({
		name: name,
		// id: getUniquePortId(name, entityId, recipeId)
		id: `${KEMU_ID_MARK}${recipeId}_${entityId}_${name}` as WidgetPortIdentifier
	}));

	return res;
};

/**
 * Transforms a list of WidgetPorts into a list of GatePorts with unique ids
 * @param IOs list of ports to transform
 * @param type whether the given IOs are inputs are outputs
 * @param gateId the id of the gate in the block
 * @param blockId the id of the block in the recipe (recipeId property)
 * @param recipeId the id of the recipe in the pool
 */
// const identifyWidgetPorts = (IOs: WidgetPort[], type: 'input' | 'output', gateId: string, blockId: string, recipeId: string): GatePort[] => {
// 	const uniquePorts: GatePort[] = IOs.map((input, index) => {
// 		return {
// 			name: input.name,
// 			id: getUniqueGatePortId(index, type, gateId/*, blockId, recipeId*/)
// 		};
// 	});

// 	return uniquePorts;
// };

const getIdFromAlphabet = customAlphabet('ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789');

/**
 * Generates a valid id removing any invalid characters
 */
const getRandomId = (len: number): string => {
	// We prevent using underscores because that character is used while defining
	// the ids of some ports.

	// NOTE: given the new custom alphabet (as of 29/Aug/2023), it is impossible to generate an id with '_', however,
	// I'm leaving this here for future reference to emphasize the fact that we cannot use such character
	// and to avoid potential bugs if the custom alphabet is changed.
	let id = getIdFromAlphabet(len).replace(/_/g, '-');
	// Make sure the ID marker is not accidentally generated.
	id = id.replace(KEMU_ID_MARK, '[#]');
	return id;
};

/**
 * Replaces all the IDs in a recipe with new ones.
 * This method assumes all ID types in the given recipe are prepended
 * with the generic marker defined by `KEMU_ID_MARK`.
 * @param recipeStr the recipe to process in stringify format.
 * @returns a copy of `recipeStr` with all the ids replaced.
 */
const regenerateIds = (recipeStr: string): string => {
	const regex = new RegExp(`${KEMU_ID_MARK}[.]*[^"]*`, 'gm');
	let m;
	const dictionary: Record<string, string> = {};
	const len = 7;
	while ((m = regex.exec(recipeStr)) !== null) {
		// This is necessary to avoid infinite loops with zero-width matches
		if (m.index === regex.lastIndex) {
			regex.lastIndex++;
		}

		// [0] is full match
		if (!dictionary[m[0]]) {
			dictionary[m[0]] = `${KEMU_ID_MARK}${getRandomId(len)}`;
		}
	}

	// Replace all ids as per dictionary 
	let newStr = recipeStr;
	Object.keys(dictionary).forEach(key => {
		newStr = newStr.split(`"${key}"`).join(`"${dictionary[key]}"`);
	});

	return newStr;
};

/**
 * Removes the id marker from a string, globally
 * @param str the string to search the ID marker for
 */
const removeIdMarker = (str: string): string => {
	return str.replace(new RegExp(KEMU_ID_MARK, 'gm'), '');
};

/**
 * Returns the string used as an id marker
 */
const getIdMarker = (): string => KEMU_ID_MARK;

/**
 * Replaces all the ids in the given string that contain an ID marker with the newly
 * provided one. 
 * @param str string to replace ids from
 * @param oldId the id to be replaced including the id marker
 * @param newId the new id excluding the id marker.
 * @returns `str` with the replaced ids.
 */
const replaceMarkedIds = (str: string, oldId: string, newId: string) => {
	const regex = new RegExp(`${oldId}`, 'gm');
	return str.replace(regex, `${KEMU_ID_MARK}${newId}`);
};

/**
 * Returns a reference to the block's `gates` property.
 * WARNING: Mutating the properties of objects in the returned dictionary WILL
 * mutate the objects in the recipe.
 */
const getWidgetsInThing = (recipeId: string, blockId: string): WidgetsMap => {
	const block = findThingInRecipe(recipeId, blockId);
	if (!block) { throw new Error(`Recipe [${recipeId}] has not been registered`); }
	return { ...block.gates };
};

/**
 * @deprecated use `getWidgetsInThing` instead
 */
const getWidgetsInBlock = getWidgetsInThing;

/**
 * Adds a brand new gate to the given block in the recipe. 
 * NOTE: This method does NOT manipulate the UI
 * @param recipeId the recipe pool id
 * @param blockId the id of the block (recipeId) the gate will be added to
 * @param type the type of gate to add
 * @returns a reference to the newly added gate. WARNING: mutating the returned reference
 * WILL mutate the newly added object
 */
const addGateToBlock = (recipeId: string, blockId: string, type: WidgetType, position: Position, parentGroupId?: string): RecipeWidget => {
	const block = findBlockInRecipe(recipeId, blockId);
	if (!block) { throw new Error(`Recipe [${recipeId}] has not been registered`); }

	const gateModule = GatesManager.getGateByType(type);
	// const gateDefaultState = gateModule.getDefaultState ? gateModule.getDefaultState() : {};
	// const gateInputs = gateModule.getInputNames && gateModule.getInputNames(gateDefaultState) || [];
	// const gateOutputs = gateModule.getOutputNames && gateModule.getOutputNames(gateDefaultState) || [];
	// Make sure IDs are unique within the block
	const id = generateUniqueGateId(block);

	// NOTE: WE can potentially add the port type (since getInputNames is returning it) to the recipe itself.
	const newGate: RecipeWidget = {
		id,
		canvas: {
			// position: _getDefaultGatePosition(recipeId, blockId, type, block.gates) 
			position
		},
		children: [],
		// inputPorts: identifyWidgetPorts(gateInputs, 'input', id, blockId, recipeId),
		// outputPorts: identifyWidgetPorts(gateOutputs, 'output', id, blockId, recipeId),
		state: gateModule.getDefaultState ? gateModule.getDefaultState() : {},
		type: type
	};

	if (parentGroupId) {
		newGate.groupId = parentGroupId;
	}

	block.gates[id] = newGate;
	return newGate;
};

/**
 * Creates a new widget type 'group' and adds it to the given thing
 * @param recipeId the recipe pool id
 * @param thingId the id of the thing (recipeId) the gate will be added to
 * @param name the name of the custom widget
 * @param description description of the widget
 * @param inputs list of custom inputs
 * @param outputs list of custom outputs
 * @param parentGroupId the id of the group this widget belongs to
 * @returns a reference to the newly added gate. WARNING: mutating the returned reference
 * WILL mutate the newly added object
 */
const addCustomWidgetToThing = (
	recipeId: string,
	thingId: string,
	name: string,
	description: string,
	inputs: GroupWidgetPort[],
	outputs: GroupWidgetPort[],
	position: Position,
	icon?: string,
	color?: string,
	parentGroupId?: string,
	isWidgetBundle?: boolean
): RecipeWidget => {

	const type = isWidgetBundle ? WidgetType.widgetBundle : WidgetType.widgetGroup;
	const newWidget = addGateToBlock(
		recipeId,
		thingId,
		type,
		position,
		parentGroupId
	);

	const newState: WidgetGroupState = {
		name,
		description,
		inputs,
		outputs,
		icon,
		color,
		type: 'custom',
		canvasPosition: {
			x: 0,
			y: 0,
		}
	};


	newWidget.state = newState as CustomWidgetState<WidgetGroupState>;

	return newWidget;
};

/**
 * Adds the given stringify map of widgets into the Thing.
 * This method automatically re-generates ids before adding the widgets to the block.
 * @param mapData WidgetsMap in string format
 * @param recipeId the id of the recipe in the pool
 * @param thingId the id of the thing (recipeId) the widgets will be added to
 * @param targetLocation the relative position widgets will be added from
 * @param groupId id of the group within the Thing that widgets will be added to
 * @returns a map of the newly added widgets. WARNING: Mutating this object will mutate
 * the object in the recipe pool.
 */
const addWidgetsFromStringMap = async (
	mapData: string,
	recipeId: string,
	thingId: string,
	targetLocation: Position,
	groupId?: string
): Promise<{
	thingWidgets: WidgetsMap;
	addedWidgets: WidgetsMap;
	/** 
	 * a map that links the original widget id
	 * with the newly generated one.
	 * oldId => newId
	 */
	widgetsIdMap: Record<string, string>;
	originalParsedData: WidgetsMap;
}> => {
	const thing = findThingInRecipe(recipeId, thingId);
	if (!thing) { throw new Error(`Recipe [${recipeId}] has not been registered`); }

	try {
		const originalData = JSON.parse(mapData) as WidgetsMap;
		const dataWithNewIds = regenerateIds(mapData);
		const widgetsMap = JSON.parse(dataWithNewIds) as WidgetsMap;
		const widgetsList = Object.values(widgetsMap);

		/** 
		 * IMPORTANT: here we are building a dictionary of 
		 * oldWidgetId => newWidgetId. This works because
		 * after the ids are regenerated, it is expected the
		 * objects remain in the same location. If this was to change
		 * the following logic would break.
		 */
		const widgetsIdMap: Record<string, string> = {};
		const oldWidgetsList = Object.values(originalData);
		oldWidgetsList.forEach((oldWidget, index) => {
			widgetsIdMap[oldWidget.id] = widgetsList[index].id;
		});

		if (widgetsList.length) {
			// Find the least top/left widget in the root (not added to a group), 
			// since all other widgets will be positioned relative to it.
			const leastTopLeftPosition: Position = { x: Infinity, y: Infinity };
			widgetsList.forEach((widget) => {
				if (!widget.groupId) {
					if (widget.canvas.position.x < leastTopLeftPosition.x) {
						leastTopLeftPosition.x = widget.canvas.position.x;
					}

					if (widget.canvas.position.y < leastTopLeftPosition.y) {
						leastTopLeftPosition.y = widget.canvas.position.y;
					}
				}
			});

			// Calculate distance from desired drop location
			const deltaX = targetLocation.x - leastTopLeftPosition.x;
			const deltaY = targetLocation.y - leastTopLeftPosition.y;

			// Update the position of ALL parent widgets
			widgetsList.forEach((widget) => {
				// Only modify the widgets that are NOT within a group
				if (!widget.groupId) {
					widget.canvas.position.x += deltaX;
					widget.canvas.position.y += deltaY;

					// Since this is a root level widget, assign them to the given group (if provided)
					if (groupId) { widget.groupId = groupId; }
				}
			});
		}

		// Add new widgets to the thing
		thing.gates = {
			...thing.gates,
			...widgetsMap
		};

		return {
			thingWidgets: thing.gates,
			addedWidgets: widgetsMap,
			widgetsIdMap,
			originalParsedData: originalData,
		};
	} catch (e) {
		console.warn(`Failed to decode clipboard data. Aborting paste action`);
		return {
			thingWidgets: thing.gates,
			addedWidgets: {},
			widgetsIdMap: {},
			originalParsedData: {},
		};
	}
};


/** 
 * Adds a new custom widget into the thing from the given template. This method also creates and adds
 * any inner widget contained by the custom widget.
 * @param template the widget to be added to the thing
 * @param recipeId the id of the recipe in the pool
 * @param thingId the id of the thing (recipeId) the gate will be added to
 * @param parentGroupId the id of the group this widget belongs to
 * @returns a reference to the newly added gate. WARNING: mutating the returned reference
 * WILL mutate the newly added object
*/
const addCustomWidgetFromTemplate = async (
	template: WidgetCollectionItem,
	recipeId: string,
	thingId: string,
	position?: Position,
	parentGroupId?: string
): Promise<WidgetsMap> => {
	const thing = findThingInRecipe(recipeId, thingId);
	if (!thing) { throw new Error(`Recipe [${recipeId}] has not been registered`); }

	if (template.variant === CustomWidgetVariant.Bundle) {
		const defaultState = getDefaultWidgetBundleState();

		const widgetState: CustomWidgetState<WidgetBundleState> = {
			...defaultState,
			// IMPORTANT: Widget Bundle's initialization function is in charge of loading
			// the processor and stored state. Here we MUST set the collection info
			// so it knows where to load the widget from.
			$$collectionInfo: {
				userId: template.author.id,
				version: template.version,
				widgetId: template.dbId,
			}
		};

		// Make sure IDs are unique within the Thing
		const id = generateUniqueWidgetId(thing);
		// const parentWidget = parentGroupId ? thing.gates[parentGroupId] : null;
		const canvasPosition = position
			? position
			: _getDefaultGatePosition(recipeId, thingId, WidgetType.widgetBundle, thing.gates);

		const newBundleWidget: RecipeWidget = {
			id,
			canvas: {
				position: canvasPosition
			},
			children: [],
			state: widgetState,
			type: WidgetType.widgetBundle,
		};

		if (parentGroupId) {
			newBundleWidget.groupId = parentGroupId;
		}

		thing.gates[id] = newBundleWidget;

		// Finally we MUST initialize the widget so it can load the processor and state
		await kemuCore.initializeWidget(recipeId, thingId, id);
	} else if (template.variant === CustomWidgetVariant.Group) {
		if (!template.contents) { throw new Error(`Missing contents, this method cannot process widget bundles.`); }
		// Parse the widget contents with new IDS (to avoid collisions if adding the same widget multiple times)
		const widgetsMap: ParsedWidgetContents = JSON.parse(regenerateIds(template.contents));
		// Find the container widget again (since the ids changed after regeneration)
		const widgetParent = Object.values(widgetsMap).find(widget => widget.isContainer === true);
		if (!widgetParent) { throw new Error(`Containing widget not found in contents`); }

		const widgetState = widgetParent.state as CustomWidgetState<WidgetGroupState>;
		// Restore the link with the collection itself.
		widgetState.collectionInfo = {
			userId: template.author.id,
			version: template.version,
			widgetId: template.dbId
		};

		// Assign container group 
		widgetParent.groupId = parentGroupId;
		// Change the default position of parent
		widgetParent.canvas = {
			position: position ? position : _getDefaultGatePosition(recipeId, thingId, widgetParent.type, thing.gates)
		};

		// Add new list of gates to thing
		thing.gates = {
			...thing.gates,
			...widgetsMap,
		};
	} else {
		console.error(`Unknown widget variant [${template.variant}], cannot add widget to thing`);
	}



	return thing.gates;
};

/**
 * Replaces the internal state of a widgetGroup Type
 * @param recipeId the recipe pool id
 * @param thingId the id of the thing (recipeId) the gate will be added to
 * @param widgetId the id of the widget to change
 * @param state the new state of the widget
 * @param triggerEvents whether event listeners should be notified. Defaults to true.
 * @returns a reference to the widget state. WARNING updating this property WILL mutate the original object.
 */
const editCustomWidgetState = (recipeId: string, thingId: string, widgetId: string, state: CustomWidgetState<WidgetGroupState>, triggerEvents=true): WidgetGroupState => {
	const widget = findWidgetInRecipe(recipeId, thingId, widgetId);
	if (!widget) { throw new Error(`Widget ${widgetId} not found in thing ${thingId} and recipe ${recipeId}`); }
	if (widget.type !== WidgetType.widgetGroup) { throw new Error(`The given widget id is not a widgetGroup type`); }
	// Change the state in the recipe
	kemuCore.setGateState(recipeId, thingId, widgetId, state, triggerEvents);
	// At this point the reference should have been updated
	return widget.state as CustomWidgetState<WidgetGroupState>;
};

/**
 * Reads the current state of the custom widget.
 * @param recipeId the recipe pool id.
 * @param thingId the id of the thing (recipeId) the gate will be added to.
 * @param widgetId the id of the custom widget.
 * @returns a deep COPY of the widget state. 
 */
const getCustomWidgetState = (recipeId: string, thingId: string, widgetId: string): WidgetGroupState => {
	const widget = findWidgetInRecipe(recipeId, thingId, widgetId);
	if (!widget) { throw new Error(`Widget ${widgetId} not found in thing ${thingId} and recipe ${recipeId}`); }
	if (widget.type !== WidgetType.widgetGroup) { throw new Error(`The given widget id is not a widgetGroup type`); }
	return JSON.parse(JSON.stringify(widget.state));
};

/**
 * Returns a map of all inner widgets in the given group (aka custom widget) including the widget group itself.
 * @param customWidgetId the id of the group (widgetGroup) that contains the other widgets to look for.
 * @param thingId the id of the thing (recipeId) in the recipe
 * @param recipeId the id of the recipe in the pool
 * @param idsInSelection a list of widgets id that should be preserved if referenced as children
 * @returns a deep copy of the widgets. Changing this object has no effect in the recipe state.
 */
const getCustomWidgetInnerWidgets = (
	customWidgetId: string,
	thingId: string,
	recipeId: string,
	idsInSelection: string[] = [],
): WidgetsMap => {
	const thing = findBlockInRecipe(recipeId, thingId);
	if (!thing) { throw new Error(`Thing ${thingId} not found in recipe ${recipeId}`); }

	const widget = safeJsonClone<RecipeWidget>(thing.gates[customWidgetId]);
	if (!widget) { throw new Error(`Widget ${customWidgetId} not found in thing ${thingId} and recipe ${recipeId}`); }

	// Remove references to collections, this prevents copies from being able to modify
	// the original collection.
	if (widget.type === WidgetType.widgetGroup && widget.state) {
		// IMPORTANT: This is safe to do so here only because `widget` is a copy
		const widgetState = widget.state as CustomWidgetState<WidgetGroupState>;
		widgetState.collectionInfo = undefined;
	}

	// Get parents connected to inner inputs
	// const parentsInfo = getWidgetParents(customWidgetId, thingId, recipeId);
	// Build a list of related widgets (group, parents and children)
	let gatesMap: WidgetsMap = {};
	gatesMap[widget.id] = widget;

	// Remove any reference to attached children not inside of the widget
	widget.children = widget.children.filter((child) => {
		// This might be undefined if the group has lost references of children
		// that no longer exist.
		const childInfo = thing.gates[child.childId] as RecipeWidget | undefined;
		return (
			// Keep if child is linked to an inner output (group's inputs)
			child.sourcePort.startsWith('innerOutput')
			// Keep if child is linked to an inner input (group's outputs)
			|| child.targetPort.startsWith('innerInput')
			// Keep if the target's group id is the same as the widget id
			// NOTE: this would also include widgets that are at the root level
			// where both childInfo.groupId is undefined. The caller needs
			// to remove root level widgets that are not part of the selection.
			|| (childInfo && childInfo.groupId === widget.groupId)
			|| idsInSelection.includes(child.childId)
		);
	});

	// Look for all the gates (widgets) that belong to the custom widgetId 
	for (const gateId in thing.gates) {
		const child = thing.gates[gateId];
		if (child.groupId === customWidgetId) {
			// If a group itself, include its inner children
			if (child.type === WidgetType.widgetGroup) {
				// NOTE: this returns the group itself as well
				const nestedChildren = getCustomWidgetInnerWidgets(child.id, thingId, recipeId);
				gatesMap = {
					...gatesMap,
					...nestedChildren
				};
			} else {
				gatesMap[gateId] = safeJsonClone<RecipeWidget>(child);
			}
		}
	}

	return gatesMap;
};

/**
 * Finds the DOM element that represents the given entity
 * @param recipeId the id of the recipe in the pool
 * @param blockId the id of the block in the recipe (recipeId property)
 * @param entityId Optional. the id of the entity inside of the Thing to find in the doom 
 */
const getEntityDomEl = (recipeId: string, blockId: string, entityId?: string): HTMLElement | null => {
	const domId = generateDomId(recipeId, blockId, entityId);
	const domEl = document.getElementById(domId);
	if (domEl) { return domEl; }
	return null;
};



/**
 * Changes the state of the widget. Disabled widgets are not invoked by the Kemu processor.
 * @param recipeId the id of the recipe in the pool
 * @param blockRecipeId the id of the block in the recipe (recipeId property)
 * @param widgetId the id of the widget inside the block to be disabled
 * @param disable new state of the widget
 * @returns a reference to the updated widget. IMPORTANT: modifying the returned reference
 * WILL modify the stored object.
 */
const disableWidget = (recipeId: string, blockRecipeId: string, widgetId: string, disable: boolean): RecipeWidget => {
	const targetBlock = findBlockInRecipe(recipeId, blockRecipeId);
	if (!targetBlock) { throw new Error(`Block ${blockRecipeId} does not exist in recipe ${recipeId}`); }
	const widget = targetBlock.gates[widgetId];
	if (!widget) { throw new Error(`Gate ${widgetId} not found in block ${blockRecipeId}`); }

	widget.disabled = disable;
	return widget;
};


/**
 * Non-reactive method to return the disabled state of a widget.
 * @param recipeId the id of the recipe in the pool
 * @param blockRecipeId the id of the block in the recipe (recipeId property)
 * @param widgetId the id of the widget inside the block to be disabled
 * @returns true if the widget is disabled
 */
const getWidgetDisabled = (recipeId: string, blockRecipeId: string, widgetId: string): boolean => {
	const targetBlock = findBlockInRecipe(recipeId, blockRecipeId);
	if (!targetBlock) { throw new Error(`Block ${blockRecipeId} does not exist in recipe ${recipeId}`); }
	const widget = targetBlock.gates[widgetId];
	if (!widget) { throw new Error(`Gate ${widgetId} not found in block ${blockRecipeId}`); }

	return !!widget.disabled;
};

/***
 * Clones an existing gate and adds it to the same target block.
 * @param recipeId the id of the recipe in the pool
 * @param blockRecipeId the id of the block in the recipe (recipeId property)
 * @param sourceGateId the id of the gate inside the block to be cloned
 * @returns a reference to the newly added gate. IMPORTANT: modifying the returned reference
 * WILL modify the stored object.
 */
// const duplicateGate = (recipeId: string, blockRecipeId: string, sourceGateId: string): RecipeWidget => {
// 	const targetBlock = findBlockInRecipe(recipeId, blockRecipeId);
// 	if (!targetBlock) { throw new Error(`Block ${blockRecipeId} does not exist in recipe ${recipeId}`); }
// 	const sourceGate = targetBlock.gates[sourceGateId];
// 	if (!sourceGate) { throw new Error(`Gate ${sourceGateId} not found in block ${blockRecipeId}`); }
// 	// Clone everything, specially the 'state'
// 	const clonedGate: RecipeWidget = JSON.parse(JSON.stringify(sourceGate));
// 	const newGateId = generateUniqueGateId(targetBlock);

// 	clonedGate.id = newGateId;
// 	clonedGate.canvas.position = _getClonedGatePosition(recipeId, blockRecipeId, sourceGate);
// 	clonedGate.children = [];

// 	targetBlock.gates[newGateId] = clonedGate;
// 	// We need to fix all ports
// 	return clonedGate;
// };


/**
 * Removes all the widgets that belong to the given group. This method does NOT remove the 
 * group itself.
 * @param recipePoolId the id of the recipe in the pool
 * @param block an instance of the block in the recipe. This element is NOT modified.
 * @param groupId the id of the group to search for.
 * @returns an updated list of all the remaining widgets in the block.
 */
const removeWidgetsInsideGroup = async (
	recipePoolId: string,
	block: Readonly<RecipeBlock>,
	groupId: string
): Promise<WidgetsMap> => {
	const filteredMap: WidgetsMap = {};

	/**
	 * Traverses the parent three starting at the given widget, checking
	 * if the widget is a nested child of the given group.
	 * @param widgetId 
	 */
	const isNestedWidget = (widgetId: string): boolean => {
		const widget = block.gates[widgetId];
		if (!widget) { return false; }
		if (widget.groupId === groupId) { return true; }
		if (widget.groupId) { return isNestedWidget(widget.groupId); }
		return false;
	};

	for (const widgetId in block.gates) {
		const thisWidget = block.gates[widgetId];
		const isInnerWidget = isNestedWidget(widgetId);
		// Create a map of widgets NOT inside of the given group (to be preserved)
		if (!isInnerWidget || widgetId === groupId) {
			filteredMap[widgetId] = thisWidget;
		} else {
			// Widget is inside of the given group (to be removed), so we should terminate it.
			await kemuCore.terminateWidget(recipePoolId, block.id, thisWidget.id);
		}
	}

	return filteredMap;
};

/**
 * Removes a Widget from a Thing, invoking its termination function if necessary.
 * If the given widget is a group, it also terminates and removes all the widgets inside it.
 * @param recipeId the id of the recipe in the pool
 * @param blockRecipeId the id of the block in the recipe (recipeId property)
 * @param gateId the id of the gate inside the block to be cloned
 * @returns a reference to the list of gates remaining in the block and their children. IMPORTANT:
 * mutating the returned reference WILL mutate the original object
 */
const removeWidget = async (
	recipeId: string,
	blockRecipeId: string,
	gateId: string
): Promise<WidgetsMap> => {
	const targetBlock = findBlockInRecipe(recipeId, blockRecipeId);
	if (!targetBlock) { throw new Error(`Block ${blockRecipeId} does not exist in recipe ${recipeId}`); }
	if (!targetBlock.gates[gateId]) { throw new Error(`Gate ${gateId} not found in block ${blockRecipeId}`); }

	let blockGates = targetBlock.gates;
	// If a group, we remove any gate that belonged to the given group
	const myself = targetBlock.gates[gateId];
	if (myself.type === WidgetType.widgetGroup) {
		blockGates = await removeWidgetsInsideGroup(recipeId, targetBlock, gateId);
		// Replace the original list of gates in the recipe
		targetBlock.gates = blockGates;
	} else {
		// Terminate this widget
		await kemuCore.terminateWidget(recipeId, blockRecipeId, gateId);
	}

	// Remove the gate itself
	delete targetBlock.gates[gateId];

	// Now we need to search through all the gates in the block for gates that had the deleted gate
	// as a child
	for (const gate in targetBlock.gates) {
		for (let i = targetBlock.gates[gate].children.length-1; i >= 0; i--) {
			const childInfo = targetBlock.gates[gate].children[i];
			if (childInfo.childId === gateId) {
				targetBlock.gates[gate].children.splice(i, 1);
			}
		}
	}


	return targetBlock.gates;
};

/**
 * @deprecated use `removeWidget` instead
 */
const removeGate = removeWidget;

/**
 * Replaces the current recipe settings with the given info
 * @param recipeId id of the recipe in the pool
 * @param info information to store
 */
const setCanvasInfo = (recipeId: string, info: RecipeCanvasInfo): void => {
	const recipe = findRecipe(recipeId);
	if (recipe && info) {
		recipe.canvas = JSON.parse(JSON.stringify(info));
	}
};

/**
 * Returns a copy of the recipe's canvas settings
 * @param recipeId id of the recipe in the pool
 * @param info information to store
 * @throws an error if the recipe has not been registered
 */
const getCanvasInfo = (recipeId: string): RecipeCanvasInfo => {
	const recipe = findRecipe(recipeId);
	if (!recipe) { throw new Error(`Recipe [${recipeId}] has not been registered`); }

	return JSON.parse(JSON.stringify(recipe.canvas || {}));
};

/**
 * Replaces the current block's canvas settings with the given info
 * @param recipeId the recipe pool id
 * @param blockRecipeId the id of the block in the recipe (recipeId)
 * @param info information to store
 */
const setBlockCanvasInfo = (recipeId: string, blockRecipeId: string, info: BlockCanvasInfo): void => {
	const block = findBlockInRecipe(recipeId, blockRecipeId);
	if (block && info) {
		block.canvas = JSON.parse(JSON.stringify(info));
	}
};

/**
 * Returns a copy of the block's canvas property.
 * @param recipeId the recipe pool id
 * @param blockRecipeId the id of the block in the recipe (recipeId)
 * @throws an error if the block is not found
 */
const getBlockCanvasInfo = (recipeId: string, blockRecipeId: string): BlockCanvasInfo => {
	const block = findBlockInRecipe(recipeId, blockRecipeId);
	if (!block) { throw new Error(`Block [${blockRecipeId}] does not exist in recipe [${recipeId}]`); }
	return JSON.parse(JSON.stringify(block.canvas || {}));
};


/**
 * Replaces the gate's canvas settings with the given info
 * @param recipeId the recipe pool id
 * @param blockRecipeId the id of the block in the recipe (recipeId)
 * @param gateId the id of the gate in the recipe
 * @param info information to store
 */
const setGateCanvasInfo = (recipeId: string, blockRecipeId: string, gateId: string, info: GateCanvasInfo): void => {
	const gate = findWidgetInRecipe(recipeId, blockRecipeId, gateId);
	if (gate && info) {
		gate.canvas = JSON.parse(JSON.stringify(info));
	}
};


/**
 * Returns a copy of the gate's canvas property or null if the gate was not found
 * @param recipeId the recipe pool id
 * @param blockRecipeId the id of the block in the recipe (recipeId)
 * @throws an error if the gate was not found in the recipe
 */
const getGateCanvasInfo = (recipeId: string, blockRecipeId: string, gateId: string): GateCanvasInfo => {
	const gate = findWidgetInRecipe(recipeId, blockRecipeId, gateId);
	if (!gate) { throw new Error(`Gate ${gateId} not found in block ${blockRecipeId} and recipe ${recipeId}`); }
	return JSON.parse(JSON.stringify(gate.canvas || {}));
};

/**
 * Loops through all the widget in the recipe and generates a zip file for each widget bundle.
 * and adds it to the recipe's storage. It also updates the bundle's state
 * to point to the new storage location.
 * @param recipeId the id of the recipe in the pool
 */
const addWidgetBundlesIntoRecipeStorage = async (recipeId: string): Promise<void> => {
const recipe = findRecipe(recipeId);
if (!recipe) { throw new Error(`Recipe [${recipeId} does not exist]`); }

const recipeThings = recipe.blocks;
for (const thingId in recipeThings) {
	const thingWidgets = recipeThings[thingId].gates;

	for (const widgetId in thingWidgets) {
		const widget = thingWidgets[widgetId];
		// If the widget is a bundle, recreate the zip bundle from cache
		// and add it to the recipe storage.
		if (widget.type === WidgetType.widgetBundle) {
			const bundleState = widget.state as CustomWidgetState<WidgetBundleState>;
			if (bundleState.$$cacheInfo) {
				const isTempBundle = bundleState.$$cacheInfo?.widgetThingId
						!== bundleState.$$collectionInfo?.widgetId;

				const { zipHandler } = await widgetBundleManager.recreateBundleFromStorage(
					bundleState.$$cacheInfo.widgetThingId,
					bundleState.$$cacheInfo.version,
					isTempBundle,
					{
						// IMPORTANT: When the zip file is generated, even though the files are the same, their
						// creation date is not. This causes the final checksum to change every time
						// the recipe is saved. Here, we force all files to have the same creation date.
						// This will ensure the checksum is always the same when the zip contents doesn't change
						filesCreationDate: new Date('2022-09-27T10:49:00.000Z').getTime(),
					}
				);


				const zipFile = await widgetBundleManager.generateZipFromHandler(zipHandler);
				// IMPORTANT: we pass the current storageUnit in case there was one already created
				// in which case the contents would get replaced.
				const addedKey = addToStorageUnit(recipeId, thingId, zipFile, bundleState.storageUnitId);
				// Update the bundle's state to point to the new storage location
				bundleState.storageUnitId = addedKey;
			}
		}
	}
}
};

/**
 * Finds the recipe in the pool and returns a copy 
 * ready to be stored. Note this method does NOT populate the 'contents' property.
 * @param recipeId the id of the recipe in the pool
 * @param name if provided, it will override the existing recipe name.
 * Note that this is a replacement of the returned object, not the original
 * recipe name.
 */
const prepareRecipeForSaving = async (recipeId: string, name?: string): Promise<{
	recipe: SaveRecipeRequestInfo,
	contents: string, dbId: string
}> => {
	const recipe = findRecipe(recipeId);
	if (!recipe) { throw new Error(`Recipe [${recipeId} does not exist]`); }

	// Generate or update storage units for each widget bundle
	await addWidgetBundlesIntoRecipeStorage(recipeId);

	// IMPORTANT: remove the the storage BEFORE calling removePrivateProperties.
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { storage, dbInfo, author, ...storageLessRecipe } = recipe;

	const cleanRecipe = removePrivateProperties(storageLessRecipe);
	const recipeCopy = JSON.parse(JSON.stringify(cleanRecipe)) as CachedRecipe;
	if (name) { recipeCopy.name = name; }

	// Remove all private properties from the blocks state. 
	// A `private property` is any property in the 'state' object that
	// starts with '$$'.
	for (const key in recipeCopy.blocks) {
		const block: RecipeBlock = recipeCopy.blocks[key];
		block.state = removePrivateProperties(block.state);

		// When a block is in dev mode, the 'bundle' property is added during
		// startup, to force the system load the bundle from localhost. 
		// This creates a temporal inconsistency with the types definition.
		// here, we make sure this property is NOT saved as part of the recipe.
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		delete (block as any).bundle;

		// Do the same for all the widget states
		for (const gateKey in block.gates) {
			const gate = block.gates[gateKey];
			gate.state = removePrivateProperties(gate.state);
		}
	}

	const blocksKeys = Object.keys(recipeCopy.blocks);

	// Build widget's metadata
	const widgetsMeta: RecipeWidgetMeta[] = [];
	blocksKeys.forEach(blockId => {
		const widgets = recipeCopy.blocks[blockId].gates;
		Object.keys(widgets).forEach(widgetId => {
			const widget = recipeCopy.blocks[blockId].gates[widgetId];
			widgetsMeta.push({
				// TODO: Replace once users can rename widgets
				name: widget.type === WidgetType.widgetGroup ? (widget.state as CustomWidgetState<WidgetGroupState>).name : widget.type,
				thingId: blockId,
				type: widget.type,
			});
		});
	});

	const recipeMeta: SaveRecipeRequestInfo = {
		// id: recipeCopy.id,
		name: recipeCopy.name,
		dataSources: recipeCopy.dataSources,
		protocolVersion: recipeCopy.protocolVersion,
		blocks: blocksKeys.map((blockId) => {
			// Some older recipes (before cloud recipes were introduced - and later decommissioned -)
			// had a `thing.type` that was equal to the thing's id and did not match the current type (cloud, web).
			// Here we make sure that if an older recipe is opened and saved, the thing type is updated to match
			// a valid type (default web);
			const thisThing = recipeCopy.blocks[blockId];
			if (thisThing.type !== ThingType.Cloud && thisThing.type !== ThingType.Web) {
				thisThing.type = ThingType.Web;
			}

			return {
				name: thisThing.name,
				category: thisThing.category,
				id: thisThing.id,
				type: thisThing.type,
				version: thisThing.version,
			};
		}),

		widgets: widgetsMeta,
	};

	// Raw contents
	const contents = JSON.stringify(recipeCopy);

	return {
		contents,
		recipe: recipeMeta,
		dbId: recipeCopy.id
	};
};

/**
 * Updates a set of limited fields in the cached recipe. 
 * Not provided fields will be ignored.
 * @param recipePoolId the id of the recipe in the pool
 * @param meta the fields to modify
 */
const updateRecipeCacheMeta = (recipePoolId: string, meta: Partial<RecipeMetaFields>): void => {
	const recipe = findRecipe(recipePoolId);
	if (!recipe) { throw new Error(`Recipe [${recipePoolId} does not exist]`); }

	recipe.dbInfo = { ...recipe.dbInfo };

	if (meta.name) {
		recipe.name = meta.name;
	}

	if (meta.recipeType) {
		recipe.dbInfo.recipeType = meta.recipeType;
	}

	if (meta.authorId) {
		recipe.dbInfo.authorId = meta.authorId;
	}

	if (meta.id) {
		recipe.dbInfo.id = meta.id;
		recipe.id = meta.id;
	}

	if (meta.version) {
		recipe.dbInfo.version = meta.version;
	}
};

/**
 * Returns a reference to storage map of a recipe if exists or null if the recipe has no storage.
 * WARNING: Modifying the reference WILL modify the original data.
 * @param recipePoolId the id of the recipe in the pool
 */
const getRecipeStorage = (recipePoolId: string): RecipeStorage | null => {
	const recipe = findRecipe(recipePoolId);
	if (!recipe) { throw new Error(`Recipe [${recipePoolId} does not exist]`); }
	return recipe.storage || null;
};

/**
 * Returns a list of ids representing the unique identifiers of the storage units the
 * block has access to.
 * @param recipeId the id of the recipe in the pool
 * @param blockId The id of the block in the recipe
 */
const getBlockStorageKeys = (recipeId: string, blockId: string): string[] => {
	const block = findBlockInRecipe(recipeId, blockId);
	if (!block) { throw new Error(`Block ${blockId} does not exist in recipe ${recipeId}`); }
	return [...(block.storageUnits || [])];
};


// /**
//  * Creates a new storage unit at the recipe level and adds the given data.
//  * @param recipePoolId the id of the recipe in the pool
//  * @param blockId The id of the block in the recipe
//  * @param data raw data to store
//  * @param key if provided, it will attempt to override an existing key, granted the block has access to it
//  * @returns the unique identifier for the storage unit that needs to be used to retrieve the contents
//  * back from storage. If key is provided and the operation succeeded, it returns `key`
//  */
// const addToStorage = (recipeId: string, blockId: string, data: Uint8Array, key?: string): string => {
// 	const recipe = findRecipe(recipeId);
// 	if (!recipe) { throw new Error(`Recipe [${recipeId} does not exist]`); }

// 	const block = findBlockInRecipe(recipeId, blockId);
// 	if (!block) { throw new Error(`Block ${blockId} does not exist in recipe ${recipeId}`); }

// 	if (!recipe.storage) { recipe.storage = {}; }
// 	// Make sure the block has storage units
// 	block.storageUnits = block.storageUnits || [];

// 	const unitId = nanoid(4);
// 	let unitKey = `${KEMU_ID_MARK}${unitId}`;

// 	// If key was provided and there were storage units
// 	if (key && block.storageUnits.length) {
// 		// Check if the block is the owner of the requested unit (key)
// 		const blockHasAccess = block.storageUnits.includes(key);
// 		if (!blockHasAccess) { throw new Error(`Block ${blockId} has no access to storage unit ${key}`); }
// 		// Ok, the key does exist and the block is the owner, allow replacement
// 		unitKey = key;
// 	}



// 	// Add/replace the raw data
// 	recipe.storage[unitKey] = data;
// 	// Associate new storage unit to block
// 	const keyExists = block.storageUnits.find(blockKey => blockKey === unitKey);
// 	if (!keyExists) { block.storageUnits.push(unitKey); }

// 	return unitKey;
// };

// /**
//  * Retrieves the stored contents of the given storage unit key.
//  * @param recipeId the id of the recipe in the pool
//  * @param blockId the id of the block in the recipe
//  * @param key the storage unit unique identifier generated by `addToStorage()`.
//  */
// const getStorageUnitFromKey = (recipeId: string, blockId: string, key: string): Uint8Array | null => {
// 	const recipe = findRecipe(recipeId);
// 	if (!recipe) { throw new Error(`Recipe [${recipeId} does not exist]`); }
// 	if (!recipe.storage) { return null; }

// 	// Make sure the block is the owner of the given storage
// 	const block = findBlockInRecipe(recipeId, blockId);
// 	if (!block) { throw new Error(`Block ${blockId} does not exist in recipe ${recipeId}`); }
// 	const blockUnits = block.storageUnits || [];
// 	const hasOwnership = blockUnits.includes(key);
// 	if (!hasOwnership) { throw new Error(`Block ${blockId} has no access to storage unit ${key}`) ; }

// 	return recipe.storage[key] || null;
// };

// /**
//  * Removes an existing unit from storage and the storage key reference from the block.
//  * @param recipeId the id of the recipe in the pool
//  * @param blockId the id of the block in the recipe
//  * @param key the storage unit unique identifier generated by `addToStorage()`.
//  * @returns true if the unit was successfully removed or false if not found.
//  * @throws an error if the block has not access to the storage unit
//  */
// const removeStorageUnit = (recipeId: string, blockId: string, key: string): boolean => {
// 	const recipe = findRecipe(recipeId);
// 	if (!recipe) { throw new Error(`Recipe [${recipeId} does not exist]`); }
// 	// the recipe has no storage
// 	if (!recipe.storage) { return false; }

// 	// The storage unit does not exist
// 	if (!recipe.storage[key]) { return false; }

// 	const block = findBlockInRecipe(recipeId, blockId);
// 	if (!block) { throw new Error(`Block ${blockId} does not exist in recipe ${recipeId}`); }
// 	const blockKeyIndex = (block.storageUnits || []).indexOf(key);
// 	const blockHasAccess = blockKeyIndex !== -1;
// 	// The block has no access to the unit
// 	if (!blockHasAccess) { throw new Error(`Block ${blockId} has no access to storage unit ${key}`); }
// 	delete recipe.storage[key];
// 	// Remove the reference from the block itself
// 	(block.storageUnits || []).splice(blockKeyIndex, 1);
// 	return true;
// };


/**
 * Invokes the block's module to get a list of available actions.
 * @param recipeId the id of the recipe in the pool
 * @param blockId the id of the block in the recipe
 */
const getBlockActions = (recipeId: string, blockId: string): ReadonlyArray<BlockAction> => {
	const block = findBlockInRecipe(recipeId, blockId);
	if (!block) { throw new Error(`Block ${blockId} does not exist in recipe ${recipeId}`); }

	const blockModule = BlocksManager.getThingWebProcessor(block.id, block.version);
	const actions = blockModule.getActions(Object.freeze({ ...block.state }));
	return actions;
};

/**
 * Invokes the block's module to get a list of inputs (data sources) 
 * @param recipeId 
 * @param blockId 
 */
const getBlockInputs = (recipeId: string, blockId: string): ReadonlyArray<BlockInput> => {
	const block = findBlockInRecipe(recipeId, blockId);
	if (!block) { throw new Error(`Block ${blockId} does not exist in recipe ${recipeId}`); }

	const blockModule = BlocksManager.getThingWebProcessor(block.id, block.version);
	const inputs = typeof blockModule.getInputs === 'function' && blockModule.getInputs({ ...block.state });
	return inputs || [];
};

/**
 * Processes executeAction responses, sending the resulting response to any child widget connected
 * to the output ports of an Action widget.
 * @param widgetId the id of the widget in the recipe that received the event or null if the event was triggered
 * directly.
 * @param thingRecipeId the id of the Thing in the recipe.
 * @param thingDbId the id of the Thing in the database.
 * @param thingVersion the version number of the Thing.
 * @param recipeId the id of the recipe in the pool.
 * @param response the response from the `executeAction` method
 * @param data the original event data used to trigger the 'executeAction' method
 */
const handleWidgetExecuteActionResponse = async (
	widgetId: string,
	thingRecipeId: string,
	thingDbId: string,
	thingVersion: string,
	recipeId: string,
	response: Promise<ExecuteActionResponse>,
	data: Data
): Promise<void> => {
	return LogicProcessor.handleExecuteActionResponse(
		widgetId,
		thingRecipeId,
		thingDbId,
		thingVersion,
		recipeId,
		response,
		data
	);
};

/**
 * Creates an interrupt to call the given widget at the given time.
 * Mostly used by the Block widget.
 * @param widgetId the id of the widget to invoke
 * @param blockRecipeId the id of the block in the recipe
 * @param recipeId the id of the recipe in the pool
 * @param interval the elapsed in ms to wait before invoking the widget
 * @returns the id of the interrupt id
 */
const addIntervalInterrupt = (widgetId: string, blockRecipeId: string, recipeId: string, portName: string, interval: number): string => {
	return kemuCore.registerInterrupt(
		'interval',
		recipeId,
		blockRecipeId,
		widgetId,
		portName,
		{ interval },
	);
};

/**
 * Stops and removes an existing interrupt
 * @param interruptId the id of the interrupt generated when calling `addIntervalInterrupt`
 * @returns true if the interrupt was successfully removed.
 */
const cancelInterrupt = (interruptId: string): boolean => {
	return kemuCore.cancelInterrupt(interruptId);
};

interface ChildBlockCustomInfo {
	blockDbId: string;
	// same as `key`
	blockRecipeId: string;
	canvasLabel?: string;
	name: string;
	type: ThingType;
	category: ThingCategory;
	version: string;
}

export type ChildBlockCustomInfoWithActions = Record<string, ChildBlockCustomInfo & {actions: ReadonlyArray<BlockAction>}>;
type ChildBlockCustomInfoWithInputs = Record<string, ChildBlockCustomInfo & {customInputs: ReadonlyArray<BlockInput>}>;


/**
 * Invokes all the child block's modules attached to the given parent block
 * and query their actions. It returns a dictionary where the keys are the child block's recipeId
 * @param recipeId the id of the recipe in the pool
 * @param parentBlock the id of the block in the recipe (`recipeId`)
 */
const getChildBlocksActions = async (recipeId: string, parentBlock: string): Promise<ChildBlockCustomInfoWithActions> => {
	const recipe = findRecipe(recipeId);
	if (!recipe) { throw new Error(`Recipe [${recipeId} does not exist]`); }

	const block = findBlockInRecipe(recipeId, parentBlock);
	if (!block) { throw new Error(`Block ${parentBlock} does not exist in recipe ${recipeId}`); }

	const childrenBlockIds = block.children.map(child => child.blockId);

	// Get all the child modules and retrieve their actions
	const actionPromises = childrenBlockIds.map(blockId =>
		BlocksManager.getThingWebProcessor(recipe.blocks[blockId].id, recipe.blocks[blockId].version)
		.getActions(Object.freeze({ ...recipe.blocks[blockId].state }), true)
	);

	// Wait for all requests to complete (most of them will be sync btw)
	// 03/Jun/2021 TODO: This is not needed since `getActions` is no longer async
	// remove promises after all clients have been migrated to the latest version
	const actions = await Promise.all(actionPromises);

	// Build a proper response
	const response = childrenBlockIds.reduce((prev, currentBlockRecipeId, index) => {
		return {
			...prev,
			[currentBlockRecipeId]: {
				version: recipe.blocks[currentBlockRecipeId].version,
				blockDbId: recipe.blocks[currentBlockRecipeId].id,
				blockRecipeId: currentBlockRecipeId,
				canvasLabel: recipe.blocks[currentBlockRecipeId].canvas.label,
				category: recipe.blocks[currentBlockRecipeId].category,
				name: recipe.blocks[currentBlockRecipeId].name,
				type: recipe.blocks[currentBlockRecipeId].type,
				actions: actions[index]
			}
		};
	}, {} as ChildBlockCustomInfoWithActions);

	// Return a copy to prevent altering the original ones
	return JSON.parse(JSON.stringify(response));
};

/**
 * Searches for all 'input' type gates of all child blocks attached to the given parent
 * and returns a dictionary with their dynamic inputs, where the key represents the child block's recipeId
 * @param recipeId the id of the recipe in the pool
 * @param parentBlock the id of the block in the recipe (`recipeId`)
 */
const getChildBlocksDynamicInputs = (recipeId: string, parentBlock: string): ChildBlockCustomInfoWithInputs => {
	const recipe = findRecipe(recipeId);
	if (!recipe) { throw new Error(`Recipe [${recipeId} does not exist]`); }

	const block = findBlockInRecipe(recipeId, parentBlock);
	if (!block) { throw new Error(`Block ${parentBlock} does not exist in recipe ${recipeId}`); }

	const response: ChildBlockCustomInfoWithInputs = {};

	for (const childBlockRef of block.children) {
		const child = recipe.blocks[childBlockRef.blockId];
		let customInputs: BlockInput[] = [];
		for (const gateId in child.gates) {
			if (child.gates[gateId].type === WidgetType.input) {
				const inputGateState = <InputGateState> child.gates[gateId].state;
				if (inputGateState.customInputs?.length) {
					customInputs = [...customInputs, ...inputGateState.customInputs];
				}
			}
		}

		if (customInputs.length) {
			response[child.recipeId] = {
				version: child.version,
				blockDbId: child.id,
				blockRecipeId: child.recipeId,
				canvasLabel: child.canvas.label,
				category: child.category,
				name: child.name,
				type: child.type,
				customInputs
			};
		}
	}

	return JSON.parse(JSON.stringify(response));
};


/**
 * Invokes the next gate in the sequence. Mostly used by 'Play' gates.
 * @param recipePoolId id of the recipe in the pool. Note that this is NOT the database id.
 * @param thingRecipeId id of the Thing in the recipe (`recipeId` property).
 * @param thingDbId id of the Thing in the database.
 * @param thingVersion version number of the Thing.
 * @param currentGateId id of the gate from the recipe that is generating the action.
 * @param sourcePortName name of the source that is originating the event
 * @param originalEvent original event that started the reaction.
 * @param data a valid Data type to pass to the next gate
 */
const invokeNextGate = (
	recipePoolId: string,
	thingRecipeId: string,
	thingDbId: string,
	thingVersion: string,
	currentGateId: string,
	sourcePortName: string,
	originalEvent: Data,
	data: Data
): Promise<void> => {
	return kemuCore.triggerNextGate(
		recipePoolId,
		thingRecipeId,
		thingDbId,
		thingVersion,
		currentGateId,
		sourcePortName,
		originalEvent,
		data
	);
};

/**
 * Invokes the widget's processor 'onParentEvent' method.
 * @param widgetId id of the widget in the recipe to invoke.
 * @param thingId id of the thing in the recipe.
 * @param recipeId id of the recipe in the pool.
 * @param targetPortName name of the port the widget received the event on. This is not verified
 * so it does not have to be an actual widget's input port.
 * @param data the raw data to pass to the widget.
 */
const invokeWidgetProcessor = (
	widgetId: string,
	thingId: string,
	recipeId: string,
	targetPortName: string,
	data: PartialData,
): Promise<void> => {
	return kemuCore.triggerWidgetProcessor(
		widgetId, thingId, recipeId, targetPortName, data
	);
};

/**
 * Used by blocks when a data source or canvas instance has produced data that needs to be consumed
 * by gates.
 * @param recipePoolId 
 * @param blockRecipeId 
 * @param data 
 */
const consumeOwnData = async (recipePoolId: string, blockRecipeId: string, data: {[k: string]: unknown}): Promise<void> => {
	await kemuCore.consumeData(data, [blockRecipeId], recipePoolId);
};

/**
 * Returns a shallow copy of the block state.
 * WARNING: Modifying non-primitive properties of the object 
 * WILL mutate the state of the block
 * @param blockId 
 * @param recipeId 
 */
const getBlockState = (blockId: string, recipeId: string): Readonly<BlockState> => {
	// Read the initial state from the store
	const recipe = findRecipe(recipeId);
	if (!recipe) { throw new Error(`Recipe [${recipeId} does not exist]`); }

	const block = recipe.blocks[blockId];
	return { ...block.state };
};

/**
 * Returns a shallow copy of the block state.
 * WARNING: Modifying non-primitive properties values of the object 
 * WILL mutate the state of the block.
 * @param gateId the id of the gate in the block
 * @param blockId id of the block in the recipe (`recipeId` property).
 * @param recipeId id of the recipe in the pool. Note that this is NOT the database id.
 */
const getWidgetState = <T=GateState>(gateId: string, blockId: string, recipeId: string): Readonly<T> => {
	// Read the initial state from the store
	const gate = findWidgetInRecipe(recipeId, blockId, gateId);
	if (!gate) { throw new Error(`Gate ${gateId} not found in block ${blockId} and recipe ${recipeId}`); }

	return { ...(gate.state as unknown as T) };
};

/**
 * @deprecated use `getWidgetState` instead
 */
const getGateState = getWidgetState;

type GateParent = Omit<GateChild, 'childId'> & {parentId: string};
/**
 * Searches for all the widgets in the block and returns a list of widgets that
 * have the given widgetId as a child
 * @param widgetId the id of the widget to search parents for
 * @param blockId id of the block in the recipe (`recipeId` property).
 * @param recipeId id of the recipe in the pool. Note that this is NOT the database id.
 */
const getWidgetParents = (widgetId: string, blockId: string, recipeId: string): GateParent[] => {
	const block = findBlockInRecipe(recipeId, blockId);
	if (!block) { throw new Error(`Block ${blockId} does not exist in recipe ${recipeId}`); }

	const parents: GateParent[] = [];

	for (const key in block.gates) {
		const gate = block.gates[key];
		const childrenList = gate.children.filter(child => child.childId === widgetId);
		if (childrenList) {
			childrenList.forEach(child => {
				parents.push({
					parentId: gate.id,
					sourcePort: child.sourcePort,
					targetPort: child.targetPort,
				});
			});
		}
	}

	return parents;
};


/**
 * Returns the widget's information in the recipe.
 * 
 * NOTE: This method is meant to be used at high data-rates and to avoid making costly deep copies
 * of the object, it was decided to implement it this way, and give it a name to remind future
 * developers that the returning object SHOULD NOT be modified.
 * 
 * @param recipeId the recipe pool id
 * @param thingRecipeId the id of the Thing in the recipe (recipeId)
 * @param widgetId the id of the widget in the block.
 * @returns a shallow read-only (statically marked as read-only although not enforced in runtime) 
 * copy of the widget from the recipe. WARNING: changing inner properties WILL change the original 
 * object.
 */
 const dangerouslyGetWidgetInfo = (recipeId: string, thingRecipeId: string, widgetId: string): Readonly<RecipeWidget> | null => {
	const widget = findWidgetInRecipe(recipeId, thingRecipeId, widgetId);
	if (!widget) { throw new Error(`Gate ${widgetId} not found in block ${thingRecipeId} and recipe ${recipeId}`); }
	return { ...widget } || null;
};


const toggleReturnOriginalEvent = (widgetId: string, blockId: string, recipeId: string): RecipeWidget => {
	const gate = findWidgetInRecipe(recipeId, blockId, widgetId);
	if (!gate) { throw new Error(`Gate ${widgetId} not found in block ${blockId} and recipe ${recipeId}`); }

	gate.returnOriginalEvent = !gate.returnOriginalEvent;
	return gate;
};

/**
 * @returns the position of the draggable area universally. In other words, regardless of whether
 * inside of a thing or not.
 */
const getDraggableCanvasPosition = (): Position => {
	const canvasContainer = document.querySelector(`.${LM_CANVAS_CONTAINER_CLASS}`);
	const draggableEl = document.querySelector(`.${CANVAS_DRAGGABLE_CLASS}.${LOGIC_MAPPER_CANVAS_CLASS}`);
	if (!draggableEl || ! canvasContainer) { return { x: 0, y: 0 }; }

	const childPos = draggableEl.getBoundingClientRect();
	const parentPos = canvasContainer.getBoundingClientRect();

	const top = childPos.top - parentPos.top;
	const left = childPos.left - parentPos.left;
	// const right = childPos.right - parentPos.right;
	// const bottom = childPos.bottom - parentPos.bottom;

	return {
		x: left,
		y: top
	};

};

/**
 * Returns limited information about the recipe.
 * @param recipePoolId the id of the recipe in the pool
 * @returns 
 */
const getRecipeInfo = (recipePoolId: string): RecipeInfo => {
	const recipe = findRecipe(recipePoolId);
	if (!recipe) { throw new Error(`Recipe [${recipePoolId} does not exist]`); }
	return {
		id: recipe.dbInfo.id || recipe.id,
		name: recipe.name,
	};
};

export {
	registerChildBlock,
	removeChildBlock,
	registerChildGate,
	removeChildGate,
	addGateToBlock,
	// duplicateGate,
	removeGate,
	removeWidget,
	setCanvasInfo,
	setBlockCanvasInfo,
	setGateCanvasInfo,
	getBlockCanvasInfo,
	getGateCanvasInfo,
	getCanvasInfo,
	regenerateIds,
	prepareRecipeForSaving,
	updateRecipeCacheMeta,
	getRecipeStorage,
	addBlock,
	removeBlock,
	duplicateBlock,
	getBlockActions,
	getBlockInputs,
	getChildBlocksActions,
	getChildBlocksDynamicInputs,
	invokeNextGate,
	consumeOwnData,
	getBlockState,
	addToStorageUnit as addToStorage,
	getStorageUnitFromKey,
	// getStorageUnitFromKey,
	removeStorageUnit,
	getBlockStorageKeys,
	removeIdMarker,
	getIdMarker,
	isGateAChildAtPort,
	getUniqueGatePortId,
	getGateState,
	getWidgetState,
	decodeGatePortId,
	decodeChildPortIdentifier,
	createChildPortIdentifier,
	getWidgetParents,
	toggleReturnOriginalEvent,
	getChildrenWidgets,
	handleWidgetExecuteActionResponse,
	disableWidget,
	getWidgetDisabled,
	addIntervalInterrupt,
	cancelInterrupt,
	dangerouslyGetWidgetInfo,
	addCustomWidgetToThing,
	getDraggableCanvasPosition,
	editCustomWidgetState,
	getCustomWidgetState,
	getCustomWidgetInnerWidgets,
	addCustomWidgetFromTemplate,
	getWidgetsInBlock,
	getWidgetsInThing,
	addWidgetsFromStringMap,
	invokeWidgetProcessor,
	getRecipeInfo,
	generateUniqueWidgetId,
	getEntityDomEl,
};
