import { SelectValue, LabeledValue } from 'antd/lib/select';
import React, { FocusEvent, useCallback, useEffect, useState } from 'react';
import { DataType, GroupWidgetPort } from '@kemu-io/kemu-core/dist/types/gate_t';
import { PlusOutlined, DeleteFilled } from '@ant-design/icons';
import StyledLabel from '../../../components/form-control/styledLabel/styledLabel';
import IconButton from '../../../components/form-control/iconButton/iconButton';
import StyledSelect, { Option } from '../../../components/form-control/styledSelect/styledSelect';
import StyledInput from '../../../components/form-control/styledInput/styledInput';
import useTranslation from '../../../common/hooks/useTranslation';
import StyledButton from '../../../components/form-control/styledButton/styledButton';
import { portTypeToString } from '../../../common/utils';
import styles from './portsList.module.css';

interface Props {
	ports: GroupWidgetPort[];
	onAddPort: () => void;
	onRemovePort: (index: number) => void;
	onPortUpdated: (index: number, port: GroupWidgetPort) => void;
	'data-kemu-meta'?: string;
}


const PortsList = (props: Props): React.JSX.Element => {
	const t = useTranslation('Interface.Modals.CreateWidget.Ports');
	const typeT = useTranslation('Kemu.DataType.HumanReadable');
	const { onPortUpdated } = props;
	const [ports, setPorts] = useState<GroupWidgetPort[]>(props.ports);

	const handlePortChange = useCallback((index, value: SelectValue, options: LabeledValue[]) => {
		const keys = options.map(item => (!isNaN(Number(item.key)) ? Number(item.key) : DataType.Anything) as DataType);
		const portInfo = ports[index];

		onPortUpdated && onPortUpdated(index, { ...portInfo, type: keys });
	}, [onPortUpdated, ports]);

	const handlePortNameChange = (index: number, text: string, originalEvent: FocusEvent<HTMLInputElement>) => {
		const nameExists = props.ports.find(port => port.label === text.trim());
		const port = props.ports[index];
		if (port.label !== text) {
			if (!nameExists) {
				props.onPortUpdated(index, { ...port, label: text });
			} else {
				// Restore original value
				originalEvent.currentTarget.value = port.label || '???';
			}
		}
	};

	const handleNameChange = useCallback((index: number, name: string) => {
		setPorts(list => {
			if (list[index].label !== name) {
				const updatedPorts = list.map(port => ({ ...port }));
				updatedPorts[index].label = name;
				return updatedPorts;
			}

			return list;
		});
	}, []);

	useEffect(() => {
		setPorts(props.ports);
	}, [props.ports]);


	return (
		<div className={styles.PortsContainer} data-kemu-meta={props['data-kemu-meta'] || 'ports-list'}>
			<div className={styles.Headers}>
				<StyledLabel text={t('Header.Name', 'Name')} />
				<StyledLabel text={t('Header.DataType', 'Data Type')} />
			</div>

			{ports.length === 0 && (
				<StyledButton
					className={styles.AddPortBtn}
					color="light"
					title={t('AddPortBtn', 'Add Port')}
					onClick={props.onAddPort}
				/>
			)}

			{ports.map((port, i) => (
				<div className={styles.PortRow} key={i}>
					<StyledInput
						className={styles.PortName}
						value={port.label || port.name}
						onChange={evt => handleNameChange(i, evt.target.value)}
						onBlur={(text, evt) => handlePortNameChange(i, text, evt)}
					/>

					<StyledSelect
						size="middle"
						className={styles.PortType}
						mode='tags'
						maxTagCount={2}
						defaultValue={(port.type as DataType[]).map(type =>  portTypeToString(typeT, type))}
						onChange={(value, options) => handlePortChange(i, value, options as LabeledValue[])}
					>
						<Option key={DataType.Anything} value={'Anything'}>{typeT('Anything', 'Anything')}</Option>
						<Option key={DataType.Number} value={'Number'}>{typeT('Number', 'Number')}</Option>
						<Option key={DataType.String} value={'String'}>{typeT('String', 'String')}</Option>
						<Option key={DataType.JsonObj} value={'JsonObj'}>{typeT('JsonObj', 'Object')}</Option>
						<Option key={DataType.ImageData} value={'ImageData'}>{typeT('ImageData', 'Image')}</Option>
						<Option key={DataType.Boolean} value={'Boolean'}>{typeT('Boolean', 'Boolean')}</Option>
						<Option key={DataType.Array} value={'Array'}>{typeT('Array', 'Array')}</Option>
						<Option key={DataType.Point} value={'Point'}>{typeT('Point', 'Point')}</Option>
						<Option key={DataType.Rect} value={'Rect'}>{typeT('Rect', 'Rect')}</Option>
						<Option key={DataType.AudioBuffer} value={'AudioBuffer'}>{typeT('AudioBuffer', 'Audio Buffer')}</Option>
						<Option key={DataType.ArrayBuffer} value={'ArrayBuffer'}>{typeT('ArrayBuffer', 'Array Buffer')}</Option>
					</StyledSelect>

					{(i + 1) === props.ports.length ? (
						<>
							<IconButton
								className={styles.AddPortBtn}
								color="transparent"
								shape="square"
								onClick={() => props.onRemovePort(i)}
								icon={<DeleteFilled />}
							/>
							<IconButton
								className={styles.AddPortBtn}
								color="transparent"
								shape="square"
								onClick={props.onAddPort}
								icon={<PlusOutlined />}
							/>
						</>
					) : (
						<IconButton
							className={styles.AddPortBtn}
							color="transparent"
							shape="square"
							onClick={() => props.onRemovePort(i)}
							icon={<DeleteFilled />}
						/>
					)}
				</div>
			))}
		</div>
	);
};

export default PortsList;
