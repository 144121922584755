import React from 'react';
import { Button, Tooltip } from 'antd';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { LogicMapperDockingMode, selectUserSettings } from '../../../app/reducers/user/userSlice';
import { updateUserSettingsAction } from '../../../app/reducers/user/updateUserSettingsReducer';
import useTranslation from '../../../common/hooks/useTranslation';
import { ReactComponent as LeftIcon } from './left-pane.svg';
import { ReactComponent as RightIcon } from './right-pane.svg';
import { ReactComponent as FullIcon } from './full-pane.svg';
import styles from './SplitPaneButtons.module.css';

type ButtonProps = {
  onClick: (mode: LogicMapperDockingMode) => void;
  icon: React.ReactNode;
  mode: LogicMapperDockingMode;
  className?: string;
  selected?: boolean;
  tooltip: string;
}

const SplitButton = (props: ButtonProps): React.JSX.Element => {
  return (
    <Tooltip title={props.tooltip} mouseEnterDelay={0.5}>
      <Button
        onClick={() => props.onClick(props.mode)}
        data-mode={props.mode}
        type="default"
        icon={props.icon}
        size={'small'}
        className={classNames(styles.Icon, props.selected && styles.Active)}
      />
    </Tooltip>
  );
};

const SplitPaneButtons = (): React.JSX.Element => {
  const userSettings = useSelector(selectUserSettings);
  const dispatch = useDispatch();
  const t = useTranslation('SplitButtons');
  const selected = userSettings?.logicMapperDocking || 'full';

  const handleClick = (mode: LogicMapperDockingMode) => {
    dispatch(updateUserSettingsAction({
      eventName: `ToggleSplitPaneMode:${mode}`,
      settings: {
        ...userSettings,
        logicMapperDocking: mode
      }
    }));
  };

  return (
    <div className={styles.ButtonsContainer}>
      <SplitButton
        mode="left"
        onClick={handleClick}
        icon={<LeftIcon />}
        selected={selected === 'left'}
        tooltip={t('Left', 'Dock to left')}
      />

      <SplitButton
        mode="full"
        onClick={handleClick}
        icon={<FullIcon />}
        selected={selected === 'full'}
        tooltip={t('Full', 'Full screen')}
      />

      <SplitButton
        mode="right"
        onClick={handleClick}
        icon={<RightIcon />}
        selected={selected === 'right'}
        tooltip={t('Right', 'Dock to right')}
      />
    </div>
  );
};

export default SplitPaneButtons;
