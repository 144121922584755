import React, { useRef, useState } from 'react';
import { Input } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import styles from './input.module.css';

interface Props {
	defaultText: string;
	onEnterKey?: (text: string) => void;
	onFocusChanged: (focused: boolean) => void;
}

const EditableTextbox = (props: Props): React.JSX.Element => {
	const { defaultText } = props;
	const [userTyping, setUserTyping] = useState(false);
	const inputRef = useRef<Input>(null);


	const handleKeyPressed = (evt: React.KeyboardEvent<HTMLInputElement>) => {
		if (evt.key === 'Enter') {
			evt.preventDefault();
			props.onEnterKey && props.onEnterKey(evt.currentTarget.value.trim());
		}
	};

	const handleFocusIn = () => {
		props.onFocusChanged(true);
		setUserTyping(true);
	};

	const handleFocusOut = (evt: React.FocusEvent<HTMLInputElement>) => {
		const nativeEvent = evt.nativeEvent.relatedTarget as HTMLUListElement | null;
		// Ignore clicks on menu list
		if (!nativeEvent?.classList.contains('ant-dropdown-menu-item') && !nativeEvent?.classList.contains('ant-dropdown-menu')) {
			props.onFocusChanged(false);
		}

		setUserTyping(false);
	};


	return (
		<Input
			value={userTyping ? undefined : defaultText}
			// onFocus={handleFocusIn}
			onMouseDown={handleFocusIn}
			onBlur={handleFocusOut}
			ref={inputRef}
			onKeyDown={handleKeyPressed}
			className={styles.InputName}
			suffix={
				<DownOutlined
					onClick={handleFocusIn}
					custom-dropdown="yes"
					className={styles.DropIcon}
				/>
			}
		/>

	);
};

export default EditableTextbox;
