import React, { useCallback, useEffect, useState } from 'react';
import { Spin, Alert } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { CustomWidgetDbEntity } from '@kemu-io/kemu-types/dist/types';
import AuthCard from '../../auth/authCard';
import styles from './importWidget.module.css';
import { selectWidgetAsyncStatus } from '@src/app/reducers/widget/widgetSlice';
import useTranslation from '@hooks/useTranslation';
import { AsyncRequestStatus, AsyncState } from '@src/types/core_t';
import { getWidgetInfoByPublicId } from '@src/api/widget/widgetApi';
import FormGroup from '@components/form-control/formGroup/formGroup';
import StyledButton from '@components/form-control/styledButton/styledButton';
import routes from '@common/routes/index';
import { importWidgetAction } from '@src/app/reducers/widget/importWidgetReducer';


const ImportWidgetScreen = (): React.JSX.Element => {
	const history = useHistory();
	const dispatch = useDispatch();
	const t = useTranslation('WidgetImportPage');
	const { id: publicWidgetId } = useParams<{id: string}>();
	const importOperation = useSelector(selectWidgetAsyncStatus);
	const [conditionsCheckbox, setConditionsCheckbox] = useState(false);
	const [widgetInfo, setWidgetInfo] = useState<CustomWidgetDbEntity & {alreadyAdded: boolean} | null>(null);
	const [loadDetails, setLoadDetails] = useState<AsyncState>({ status: AsyncRequestStatus.idle });
	const isImporting = importOperation.asyncStatus.status === AsyncRequestStatus.loading;
	const widgetAlreadyAdded = !!widgetInfo?.alreadyAdded;

	const GoHome = () => {
		return (
			<div className={styles.GoHomeButton}>
				<StyledButton title={t('GoHomeBtn', 'Kemu app')} onClick={navigateHome} />
			</div>
		);
	};

	const getDetails = useCallback(async () => {
		try {
			setLoadDetails({ status: AsyncRequestStatus.loading, error: undefined });
			const response = await getWidgetInfoByPublicId(publicWidgetId);
			setWidgetInfo({
				...response.widget,
				alreadyAdded: response.alreadyAdded
			});
			setLoadDetails({ status: AsyncRequestStatus.completed, error: undefined });
		} catch (e) {
			console.log('Error loading details: ', e);
			// Axios response, not found
			if (e?.response?.status === 404) {
				setLoadDetails({
					status: AsyncRequestStatus.error,
					error: {
						message: t('404', 'The requested widget does not exist or is no longer available.')
					}
				});
			} else {
				setLoadDetails({ status: AsyncRequestStatus.error, error: e });
			}
		}
	}, [publicWidgetId, t]);


	const handleImport = () => {
		dispatch(importWidgetAction({
			publicId: publicWidgetId,
		}));
	};

	const navigateHome = () => {
		history.push(routes.getBaseRoute());
	};

	useEffect(() => {
		if (!widgetInfo && loadDetails.status === AsyncRequestStatus.idle) {
			getDetails();
		}
	}, [getDetails, loadDetails, widgetInfo, importOperation]);

	// Load the app once done
	useEffect(() => {
		let tmr: NodeJS.Timeout;
		if (importOperation.asyncStatus.status === AsyncRequestStatus.completed) {
			tmr = setTimeout(() => history.push(routes.getBaseRoute()), 1000);
		}

		return () => {
			clearTimeout(tmr);
		};
	}, [history, importOperation]);

	return (
		<AuthCard title={t('Title', 'Import Widget')}>
			{loadDetails.status === AsyncRequestStatus.error ? (
				<>
					<Alert closable={false} message={ loadDetails.error!.message || 'Unknown Error' } type="error" />
					<GoHome />
				</>
			): <></>}

			{importOperation.asyncStatus.status === AsyncRequestStatus.error ? (
				<Alert closable={true} message={importOperation.asyncStatus.error!.message || 'Unknown Error'} type="error" />
			): <></>}

			{importOperation.asyncStatus.status === AsyncRequestStatus.completed ? (
				<Alert type="success" message={t('ImportSuccess', 'The widget has been successfully added to your collection.')} />
			): <></>}

			{loadDetails.status === AsyncRequestStatus.loading ? (
				<div className={styles.LoadingContainer}>
					<p>{t('LoadingMessage', 'Loading details...')}</p>
					<Spin spinning={loadDetails.status === AsyncRequestStatus.loading} indicator={<LoadingOutlined />} />
				</div>
			) : (
				<div>
					{loadDetails.status === AsyncRequestStatus.completed && !widgetAlreadyAdded && importOperation.asyncStatus.status !== AsyncRequestStatus.completed ? (
						<>
							<p>
								{t('Question', 'Do you want to add this widget ({name}) to your collection?', { name: widgetInfo?.name })}
							</p>
							<Alert closable={false} type="warning" message={
								<span>
									<strong>{t('Warning', 'WARNING')}: </strong>
									{t('WarningText', 'Only import widgets from sources that you trust.')}
								</span>
							}/>

							<FormGroup className={styles.AgreeContainer}>
								<input type="checkbox" name="agree" id="agree" onChange={(e) => setConditionsCheckbox(e.target.checked)} />
								<label className={styles.AgreeLabel} htmlFor="agree">
									{t(
										'TermsText',
										'I accept the {risks} of using widgets from unknown sources',
										{
											risks: <a href={routes.recipe.getImportRecipeRisks()}>
												{t('RisksWord', 'risks')}
											</a>
										})}
								</label>
							</FormGroup>

							<div className={styles.ButtonContainer}>
								<StyledButton
									disabled={isImporting || !conditionsCheckbox}
									loading={isImporting} onClick={handleImport}
									title={t('ImportBtn', 'Import widget')}
								/>
							</div>
						</>
					) : <></>}

					{loadDetails.status === AsyncRequestStatus.completed && widgetAlreadyAdded ? (
						<>
							<Alert
								type="success"
								message={t('AlreadyAdded', 'The widget "{name}" is already part of your collection.', { name: widgetInfo?.name })}
							/>
							<GoHome />
						</>
					) : <></>}
				</div>
			)}
		</AuthCard>
	);
};

export default ImportWidgetScreen;
