import React, { forwardRef } from 'react';
import { InputNumber } from 'antd';
import classNames from 'classnames';
import styles from './NumericInput.module.css';

interface Props {
	className?: string;
	min?: number;
	max?: number;
	defaultValue?: number;
	value?: number;
	placeholder?: string;
	disabled?: boolean;
	step?: number;
	wrapperClass?: string;
	title?: string;
	/** used to uniquely identify the input in event handlers */
	eventId?: string;
	/** Called when the user types in a value or string */
	// onInputChange?: (value: number | string) => void;
	/** calls when the value has changed either by keyboard or arrows */
	onChange?: (value: number, eventId?: string) => void;
}


	const NumericInput = (props: Props, ref: React.Ref<NumericInputRefType | undefined>): React.JSX.Element => {

	// Handles keyboard entries
	const handleKeyboardInput = (value: number | string | undefined | null) => {
		if (!isNaN(Number(value)) && value !== null && value !== undefined) {
			// Abort notifications outside range
			if (props.min !== undefined && value < props.min) { return; }
			if (props.max !== undefined && value > props.max) { return; }
			props.onChange && props.onChange(Number(value), props.eventId);
		}
	};



	return (
		/* VERY important to add 'no-child-drag' or up/down buttons will stop working */
		<div className={`gate-input no-child-drag ${props.wrapperClass || ''}`}>
			<InputNumber
				ref={ref}
				step={props.step}
				disabled={props.disabled}
				title={props.title}
				bordered={false}
				className={classNames(styles.Container, props.className)}
				min={props.min}
				max={props.max}
				defaultValue={props.defaultValue}
				value={props.value}
				placeholder={props.placeholder}
				onChange={handleKeyboardInput}
				data-testid="numeric-input"
			/>
		</div>
	);
};

// export default NumericInput;
export type NumericInputRefType = {
	blur: ()=> void;
	focus: ()=> void;
}

export default forwardRef<NumericInputRefType | undefined, Props>(NumericInput);
