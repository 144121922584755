import React, { CSSProperties, memo } from 'react';
import { Button, Tooltip } from 'antd';
import { CloseOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import SaveTutorialButton from '../saveButton/saveButton';
import { TutorialState } from '../../../types/tutorial';
import { removeFunctionProps } from '../../../common/utils';
import useTranslation from '../../../common/hooks/useTranslation';
import styles from './header.module.css';
interface Props {
	title: string;
	isLocked: boolean;
	unsavedChanges?: boolean;
	tutorialId?: string;
	remainingTime?: string;
	onClose?: ()=> void;
	onGetState?: ()=> TutorialState;
	onSaved: () => void;
	onToggleLock: (lock: boolean) => void;
}

const TutorialBuilderHeader = (props: Props): React.JSX.Element => {
	const { tutorialId, unsavedChanges, onGetState } = props;
	const iconStyle: CSSProperties = { fontSize: 24 };
	const t = useTranslation('TutorialBuilder.Main');

	const handleLock = () => {
		props.onToggleLock(!props.isLocked);
	};

	return (
		<div className={styles.TitleBar}>

			<h1>{props.title}</h1>
			<div className={styles.Buttons}>
				<Tooltip title={
					props.isLocked ? t('EnableEditing', 'Enable Editing') : t('DisableEditing', 'Disable Editing')
				} placement="bottomRight">
					<Button
						type="text"
						onClick={handleLock}
						icon={ props.isLocked ? <LockOutlined style={iconStyle}/> : <UnlockOutlined style={iconStyle} /> }
					/>
				</Tooltip>

				<SaveTutorialButton
					onSaved={props.onSaved}
					className={unsavedChanges ? styles.ButtonsUnsaved : ''}
					autoSave={unsavedChanges}
					tutorialId={tutorialId}
					getTutorial={onGetState}
				/>
				<Button
					type="text"
					onClick={props.onClose}
					icon={<CloseOutlined style={iconStyle}/>}
				/>
			</div>
		</div>
	);
};

// FIXME: still re-rendering on content changes. 
// The problem is the that the onGetState depends on the 'steps' variable
// and the function keeps being re-created
const areEqual = (previous: Props, current: Props) => {
	const prev = removeFunctionProps(previous);
	const now = removeFunctionProps(current);
	return JSON.stringify(prev) === JSON.stringify(now) && previous.onGetState === current.onGetState;
};

export default memo(TutorialBuilderHeader, areEqual);
