import React, { ReactElement, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { PlusOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { LearningPathItem } from '@kemu-io/kemu-types/dist/types';
import FormGroup from '../../form-control/formGroup/formGroup';
import StyledInput from '../../form-control/styledInput/styledInput';
import IconButton from '../../form-control/iconButton/iconButton';
import { isValidUrl } from '../../../common/utils';
import styles from './publisher.module.css';

interface Props {
	requirements: LearningPathItem[];
	onSetRequirements: (requirements: LearningPathItem[]) => void;
}

const RecipeRequirements = (props: Props): ReactElement => {
	const [newRequirement, setNewRequirement] = useState<LearningPathItem>({ name: '', link: '' });
	const canAddResource = newRequirement.name.length && newRequirement.link?.length;

	const onRequirementElChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
		const property = event.currentTarget.name;
		const value = event.currentTarget.value;

		setNewRequirement(r => ({ ...r, [property]: value }));
	};

	const addRequirement = () => {
		// updateSettings('requirements', [...publication.requirements, newRequirement]);
		props.onSetRequirements([...props.requirements, newRequirement]);
		setNewRequirement({ name: '', link: '' });
	};

	const removeRequirement = (index: number) => {
		const filtered = props.requirements.filter((_, i) => i !== index);
		props.onSetRequirements(filtered);
	};

	return (
		<div>
			<FormGroup>
				<label>
					<FormattedMessage id="Marketplace.MyRecipes.PublishWizard.Requirements" defaultMessage="Requirements" />
				</label>
					<p><FormattedMessage id="Marketplace.MyRecipes.PublishWizard.Requirements.Description" defaultMessage=""/></p>
			</FormGroup>

			<FormGroup row={true} className={styles.RequirementsContainer}>
				<StyledInput value={newRequirement.name} onChange={onRequirementElChanged} name="name" placeholder="name" />
				<StyledInput value={newRequirement.link} onChange={onRequirementElChanged} name="link" placeholder="link to resource"/>
				<IconButton onClick={addRequirement} disabled={!canAddResource || !isValidUrl(newRequirement.link || '')} className={styles.PlusButton} color="transparent" shape="round" icon={<PlusOutlined />} />
			</FormGroup>

			{!!props.requirements.length && (
				<FormGroup  column={true}>
					<div className={styles.RequirementsList}>
						<ol>
						{props.requirements.map((item, index) => (
							<li key={index}>
								<span className={styles.RequirementItem}>
									<a key={index} href={item.link} target="_blank" rel="noreferrer">{item.name}</a>
									<button className={styles.RemoveButton} onClick={() => removeRequirement(index)}><CloseCircleOutlined /></button>
								</span>
							</li>
						))}
						</ol>
					</div>
				</FormGroup>
			)}



		</div>
	);
};

export default RecipeRequirements;
