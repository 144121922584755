import { storageMapToUnit } from '@kemu-io/kemu-core/dist/common/utils';
import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ArrayBuffer as MD5ArrayBuffer } from 'spark-md5';
import { History } from 'history';
import { RecipeType } from '@kemu-io/kemu-types/dist/types';
import { InterfaceState, setAsyncOperationDetails } from '../interfaceSlice';
import { setRecipeMetaAction } from '../../Workspace/workspaceSlice';
import { uploadRecipeStorageAction } from './uploadRecipeStorageReducer';
import { uploadRecipeContentsAction } from './uploadRecipeContentsReducer';
import { getRecipeStorage, prepareRecipeForSaving } from '@src/app/recipe/utils';
import { AsyncRequestStatus } from '@src/types/core_t';
import { USER_CHAT_PROPERTIES } from '@common/constants';
import getFreshChatWidget from '@src/app/recipe/freshChatWidget';
import * as recipeApi from '@src/api/recipe/recipeApi';
import { RootState } from '@src/app/store';
import routes from '@common/routes';

// 100MB limit
const REQUEST_LIMIT = 1024 * 1024 * 100;

/**
 * Uploads a recipe to the server.
 * If the recipe uses a storage unit, it uses multipart upload.
 * @param options.recipePoolId the id of the recipe in the pool
 * @returns the sha1 of the combined storage (if present)
 */
export const storeRecipeAction = createAsyncThunk('/interface/storeRecipe', async (options: {
	recipePoolId: string,
	newName?: string,
	hideProgress?: boolean
	history: History,
}, thunkAPI): Promise<string | null> => {

	// TODO: Currently, we don't update the id or author info in the recipe content's file 
	// because at the time of generating the checksum we don't know what version number of id
	// the recipe will have, so if we were to update the id after the server responds, the checksum
	// would no longer match. This however creates a lot of confusion because the Cached recipe instance
	// does contain id and author properties that we are supposed to ignore. 
	const { contents, dbId, recipe: parsedRecipe } = await prepareRecipeForSaving(options.recipePoolId, options.newName);

	const recipeStorage = getRecipeStorage(options.recipePoolId);

	// Combine all storage units
	let storageSize = 0;
	let checksum = '0';
	let disk: ArrayBuffer | undefined;
	if (recipeStorage) {
		// WARNING: heavy operation
		disk = storageMapToUnit(recipeStorage);
		if (disk.byteLength) {
			storageSize = disk.byteLength;
			// WARNING: heavy operation 2
			checksum = MD5ArrayBuffer.hash(disk);
		}
	}

	// Prevent showing upload notification
	if (!options.hideProgress) {
		// Show saving notification
		thunkAPI.dispatch(setAsyncOperationDetails({ action: 'save', extra: !!recipeStorage }));
	}

	// Append cloud environment info to the recipe (if any)
	const { workspace } = thunkAPI.getState() as RootState;
	if (workspace.currentRecipe.cloud) {
		parsedRecipe.cloud = { ...workspace.currentRecipe.cloud };
	}

	// Make sure the contents don't surpass the request limit
	const encoder = new TextEncoder();
	const contentsBuffer = encoder.encode(contents);
	if (contentsBuffer.byteLength > REQUEST_LIMIT) { throw new Error(`Recipe contents exceed the allowed limit`); }


	const recipeName = options.newName || parsedRecipe.name;
	const canUploadStorage = (uploadUrl?: string) => !!(uploadUrl && disk !== undefined && disk.byteLength);

	const handleDiskUpload = (uploadUrl?: string) => {
		// If we need to upload the contents, dispatch a new event
		if (disk !== undefined && disk.byteLength && uploadUrl) {
			return thunkAPI.dispatch(uploadRecipeStorageAction({ name: recipeName, disk, uploadUrl }));
		}
	};

	// Will store the final id of the recipe
	let recipeID: string;

	if (!dbId || options.newName) {
		const response = await recipeApi.saveRecipe(parsedRecipe, contentsBuffer.byteLength, storageSize, checksum );
		const uploadStorage = canUploadStorage(response.storageUrl);

		// Update the ID of the local recipe with that provided by the server
		// setRecipeMeta(options.recipeId, {
		// 	id: response.recipeId,
		// 	version: response.version,
		// 	name: recipeName,
		// });

		thunkAPI.dispatch(setRecipeMetaAction({
			id: response.recipeId,
			version: response.version,
			name: recipeName,
			recipeType: workspace.currentRecipe.cloud ? RecipeType.Cloud : RecipeType.Browser,
		}));

		// Also update the title of the recipe
		// thunkAPI.dispatch(setRecipeName(recipeName));
		// Upload contents
		await thunkAPI.dispatch(uploadRecipeContentsAction({
			contents: contentsBuffer,
			uploadUrl: response.contentsUrl,
			hasStorage: uploadStorage,
		}));

		// Upload storage
		if (uploadStorage && disk && response.storageUrl) {
			// await thunkAPI.dispatch(uploadRecipeStorageAction({ name: recipeName, disk, uploadUrl: response.storageUrl }));
			await handleDiskUpload(response.storageUrl);
		}

		recipeID = response.recipeId;
	} else {
		const response = await recipeApi.updateRecipe(parsedRecipe, dbId, contentsBuffer.byteLength, storageSize, checksum);
		// The server determines based on the checksum if contents need to be uploaded
		const uploadDiskAgain = canUploadStorage(response.storageUrl);

		// Upload contents
		await thunkAPI.dispatch(uploadRecipeContentsAction({
			contents: contentsBuffer,
			uploadUrl:
			response.contentsUrl,
			hasStorage: uploadDiskAgain,
		}));

		if (!uploadDiskAgain && !options.hideProgress) {
			thunkAPI.dispatch(setAsyncOperationDetails({ extra: false }));
		}

		// Upload storage
		// TODO: The first time a recipe is opened, the disk ids are re-generated,
		// this causes the storage checksum to change and forces the user to re-upload
		// the disk even though it hasn't really changed.
		await handleDiskUpload(response.storageUrl);
		recipeID = dbId;
	}

	// TODO: Refactor? having this here is ugly!
	// Update the user properties in the chat widget. This allows support
	// personnel to know which recipe the user is looking at.
	const freshChatInstance = getFreshChatWidget();
	if (freshChatInstance.initialized && recipeID) {
		freshChatInstance.setUserProperty({
			[USER_CHAT_PROPERTIES.LastOpenedRecipeId]: recipeID,
			[USER_CHAT_PROPERTIES.LastOpenedRecipeName]: recipeName,
		});
	}

	// Show the created id in the url
	if (workspace.currentRecipe.type === RecipeType.Browser) {
		options.history.push(routes.recipe.getBrowserRecipeRoute(recipeID));
	} else if (workspace.currentRecipe.type === RecipeType.Cloud) {
		options.history.push(routes.recipe.getCloudRecipeRoute(recipeID));
	}

	return checksum;
});


export const storeRecipeReducer = ((builder: ActionReducerMapBuilder<InterfaceState>): void => {
	builder.addCase(storeRecipeAction.pending, (state) => {
		state.asyncOperation = {
			...state.asyncOperation,
			status: AsyncRequestStatus.loading,
			error: undefined
		};
	});

	builder.addCase(storeRecipeAction.fulfilled, (state, action: PayloadAction<string | null >) => {
		state.asyncOperation = { ...state.asyncOperation, status: AsyncRequestStatus.completed };
		state.lastStorageChecksum = action.payload;
	});

	builder.addCase(storeRecipeAction.rejected, (state, action) => {
		state.asyncOperation = {
			...state.asyncOperation,
			status: AsyncRequestStatus.error,
			error: action.error
		};
	});
});
