import React, { ComponentType, CSSProperties, FC, useCallback, useEffect, useRef, useState } from 'react';
import { EditorState, getVisibleSelectionRect } from 'draft-js';
import { DraftJsButtonTheme } from '@draft-js-plugins/buttons';
import { InlineToolbarPluginStore } from '../..';
import { InlineToolbarPluginTheme } from '../../theme';
import { getEditorRoot, isActiveElementAChildOf } from '../../../utils';

export interface OverrideContentProps {
  getEditorState: () => EditorState;
  setEditorState: (editorState: EditorState) => void;
  onOverrideContent: (content: ComponentType<unknown> | undefined) => void;
}

export interface ToolbarChildrenProps {
  theme: DraftJsButtonTheme;
  getEditorState: () => EditorState;
  setEditorState: (editorState: EditorState) => void;
  onOverrideContent: (content?: ComponentType<OverrideContentProps>) => void;
  containerRef?: React.RefObject<HTMLDivElement>
}



interface ToolbarProps {
  store: InlineToolbarPluginStore;
  children?: FC<ToolbarChildrenProps>;
  position?: { top: number; left: number };
  overrideContent?: ComponentType<ToolbarChildrenProps /* | undefined */>;
  theme?: InlineToolbarPluginTheme;
}


const Toolbar = (props: ToolbarProps): React.JSX.Element => {
  const { store, theme } = props;
  const toolbarRef = useRef<HTMLDivElement>(null);
  const positionRef = useRef<{ top: number; left: number }>();
  const customContentRef = useRef<HTMLDivElement>(null);
  const [state, setState] = useState<ToolbarProps & { containerStyle: CSSProperties }>({
    containerStyle: { visibility: 'hidden' },
    store,
    // theme,
  });

  const getContainerPosition = useCallback((): CSSProperties | null => {
    if (!toolbarRef.current) { return null; }

    // const editorRef = store.getItem('getEditorRef')!();
    // if (!editorRef) { return null; }

     // This keeps backwards compatibility with React 15
     const editorRoot = getEditorRoot();
     if (!editorRoot) { return null; }

    const editorRootRect = editorRoot.getBoundingClientRect();
    const parentWindow = editorRoot.ownerDocument && editorRoot.ownerDocument.defaultView;
    const selectionRect = getVisibleSelectionRect(parentWindow || window);
    if (!selectionRect) {
      // return null;
      if (positionRef.current) {
        return positionRef.current;
      } else {
        return null;
      }
    }

    // The toolbar shouldn't be positioned directly on top of the selected text,
    // but rather with a small offset so the caret doesn't overlap with the text.
    const extraTopOffset = -5;

    const position = {
      top:
        editorRoot.offsetTop -
        toolbarRef.current.offsetHeight +
        (selectionRect.top - editorRootRect.top) +
        extraTopOffset,
      left:
        editorRoot.offsetLeft +
        (selectionRect.left - editorRootRect.left) +
        selectionRect.width / 2,
    };

    positionRef.current = position;
    return position;
  }, []);


  /**
   * This can be called by a child in order to render custom content instead
   * of the children prop. It's the responsibility of the callee to call
   * this function again with `null` in order to reset `overrideContent`.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onOverrideContent = (overrideContent?: ComponentType<any>): void => {
    // Update the content and make modal invisible until we re-calculate the position
    setState(s => ({
      ...s,
      overrideContent,
      containerStyle:
      { visibility: 'hidden' }
    }));
  };


  const calculatePosition = useCallback(() => {
    if (!store) { return; }
    const getEditorState = store.getItem('getEditorState');
    if (!getEditorState) { return; }
    const selection = getEditorState().getSelection();
    // overrideContent could for example contain a text input, hence we always show overrideContent
    const position = getContainerPosition();

    const preserveBarInDevMode = customContentRef.current && !!localStorage.getItem('preserve-inlineToolbar');
    let isVisible = (!selection.isCollapsed() && selection.getHasFocus() || preserveBarInDevMode);// || state.overrideContent;

    if (customContentRef.current) {
      // Check if any element in the custom window has focus.
      // NOTE: Widget is responsible for focusing at least one element 
      // during mounting
      const customModalHasFocus = isActiveElementAChildOf(customContentRef.current);
      if (customModalHasFocus) {
        isVisible = true;
      }
    }

    const style: CSSProperties = { ...position };

    if (isVisible && position) {
      style.visibility = 'visible';
      style.transform = 'translate(-50%) scale(1)';
      style.transition = 'transform 0.15s cubic-bezier(.3,1.2,.2,1)';
    } else {
      style.transform = 'translate(-50%) scale(0)';
      style.visibility = 'hidden';
    }

    // store.updateItem('isVisible', isVisible);
    // Restore toolbar content when the user selects out
    if (!isVisible) {
      onOverrideContent();
    }

    setState(s => ({
      ...s,
      containerStyle: style,
    }) );
  }, [store, getContainerPosition/*, state.overrideContent*/]);


  const { overrideContent: OverrideContent } = state;
  const childrenProps: ToolbarChildrenProps = {
    theme: theme?.buttonStyles as DraftJsButtonTheme,
    getEditorState: store.getItem('getEditorState')!,
    setEditorState: store.getItem('setEditorState')!,
    onOverrideContent,
  };

  const onSelectionChanged = useCallback(() => {
    // need to wait a tick for window.getSelection() to be accurate
    // when focusing editor with already present selection
    setTimeout(() => {
      if (!toolbarRef.current) { return; }
      calculatePosition();
    }, 5);

  }, [calculatePosition]);


  useEffect(() => {
    store.subscribeToItem('selection', onSelectionChanged);
    return () => {
      store.unsubscribeFromItem('selection', onSelectionChanged);
    };
  }, [onSelectionChanged, store, calculatePosition]);


  // re-calculate every time the content is changed.
  useEffect(() => {
    calculatePosition();
  }, [calculatePosition, state.overrideContent]);

  return (
    <div
      className={`${theme?.toolbarStyles.toolbar} ${OverrideContent ? 'custom' : ''}`}
      style={state.containerStyle}
      ref={toolbarRef}
    >
      {OverrideContent ? (
        <OverrideContent {...childrenProps} containerRef={customContentRef} />
      ) : (
        props.children!(childrenProps)
      )}
    </div>
  );
};

export default Toolbar;
