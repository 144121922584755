import React, { useState } from 'react';
import { Row, Col, Spin, Alert } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { LoadingOutlined } from '@ant-design/icons';
import { Auth } from '@aws-amplify/auth';
import AuthCard from '../authCard';
import FormGroup from '../../../components/form-control/formGroup/formGroup';
import StyledInput from '../../../components/form-control/styledInput/styledInput';
import StyledButton from '../../../components/form-control/styledButton/styledButton';
import PasswordReset from '../passwordReset/passwordReset';
import routes from '../../../common/routes';
import styles from './forgotPassword.module.css';

const ForgotPassword = (): React.JSX.Element => {
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [processing, setProcessing] = useState(false);
	const [emailSent, setEmailSent] = useState(false);
	const [email, setEmail] = useState('');
	const intl = useIntl();


	// const tryAgain = () => {
	// 	setEmailSent(false);
	// };

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		if (email.trim() === '') {
			return setErrorMessage(intl.formatMessage({ id: 'Screen.ForgotPassword.InvalidEmail', defaultMessage: 'Invalid email address' }));
		}

		setErrorMessage(null);
		setProcessing(true);
		try {
			const response = await Auth.forgotPassword(email);
			console.log('Forgot response: ', response);
			setEmailSent(true);
		} catch (e) {
			if (e.code) {
				setErrorMessage(intl.formatMessage({ id: `Screen.ForgotPassword.${e.code}`, defaultMessage: e.message }));
			} else {
				console.log('Error: ', e);
				setErrorMessage(intl.formatMessage({ id: 'Screen.ForgotPassword.UnknownError', defaultMessage: 'There was an error processing your request. Please try again later.' }));
			}
		}

		setProcessing(false);
	};

	if (emailSent) { return <PasswordReset email={email}/>; }

	return (
		<AuthCard title={<FormattedMessage id="Screen.ForgotPassword.Title" defaultMessage="Forgot Password" />} >
			<Spin indicator={<LoadingOutlined />} spinning={processing}>
				{errorMessage && (
					<>
						<Alert closable={true} message={errorMessage} type="error" />
						<br/>
					</>
				)}

				<form method="POST" onSubmit={handleSubmit}>
					<p className="text-muted"><FormattedMessage id="Screen.ForgotPassword.Description" /></p>
					<Row>
						<Col span={24}>
							<FormGroup>
								<StyledInput onChange={e => setEmail(e.currentTarget.value)} i18nPlaceholderId="Screen.ForgotPassword.UsernameOrEmail" i18nPlaceholderDefaultMessage="Username or email" type="text" name="email" value={email}/>
							</FormGroup>
						</Col>
					</Row>

					<Row>
						<Col span={24}>
							<FormGroup className={styles.SubmitContainer}>
								<StyledButton disabled={!email.length} title={<FormattedMessage id="Screen.ForgotPassword.SubmitButton" defaultMessage="Sign In" />} type="submit" className={styles.SubmitButton} />
							</FormGroup>
						</Col>
					</Row>
				</form>

				<div className={styles.CardBottomText}>
					<a href={routes.auth.getSignInRoute()}><FormattedMessage id="Screen.ForgotPassword.LoginLink" defaultMessage="Back to login" /></a>
				</div>
			</Spin>
		</AuthCard>
	);
};

export default ForgotPassword;
