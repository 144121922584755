import React, { useCallback, useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { Result, Spin } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { LoadingOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
// Custom styles for all draftJs editors
import '../../components/layout/editors.css';
import { nanoid } from 'nanoid';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as EmptyTutorialIcon } from '../../assets/img/tutorialBuilder/emptyTutorial.svg';
import * as tutorialApi from '../../api/tutorial/tutorialApi';
import { TutorialState, TutorialStep } from '../../types/tutorial';
import StyledButton from '../../components/form-control/styledButton/styledButton';
import { activeTutorialInfo, setActiveTutorialInfo } from '../interface/interfaceSlice';
import useAlert from '../../components/alert/useAlert';
import useTranslation from '../../common/hooks/useTranslation';
import routes from '../../common/routes';
import { TutorialProtocols } from '../../common/constants';
import { selectSignedUserProfile } from '../../app/reducers/user/userSlice';
import useCurrentWorkspacePath from '../../common/hooks/useCurrentWorkspacePath';
import IntroCard from './introCard/introCard';
import StepsBar from './stepsBar/stepsBar';
import TutorialEditor from './editor/editor';
import TutorialBuilderHeader from './header/header';
import { buildAssetRelativeUrl } from './utils';
import styles from './TutorialBuilder.module.css';

const modalAnimation = {
	visible: {
		opacity: 1,
		transition: { delay: 0.2 },
		// y: '0'
	},

	hidden: {
		opacity: 0,
		// y: '-100vh'
	},
};

const backdropAnimation = {
	visible: { opacity: 0.6/*, translateY: '-100px' */ },
	hidden: { opacity: 0/*, translateY: '0px' */ }
};

interface Props{
	defaultStep: number;
	tutorialId: string;
}


interface TutorialDetails {
	description: string;
	title: string;
	updatedAt: string;
	author: string;
}

/**
 * Performs fixes to the structure of the tutorial based on its protocol version.
 * NOTE: This method MUTATES the tutorial object.
 */
const upgradeProtocol = (tutorial: tutorialApi.TutorialEntityWithContent) => {
	// Version 1 had absolute urls for images and other assets, here we make them relative.
	// Version 2 had an asset path without domain but with user id and tutorial id
	// Version 3 only has the asset url relative to the containing folder (Eg: `uploads/asset123`).
	if (tutorial.protocolVersion === TutorialProtocols.v1 || tutorial.protocolVersion === TutorialProtocols.v2) {
		for (const page of tutorial.contents.pages) {
			for (const key in page.content.entityMap) {
				if (page.content.entityMap[key].data) {
					page.content.entityMap[key].data.src = buildAssetRelativeUrl(page.content.entityMap[key].data.src);
				}
			}
		}

		tutorial.protocolVersion = TutorialProtocols.v3;
	}
};

const TutorialBuilder = (props: Props): React.JSX.Element => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const history = useHistory();
	// const { id: tutorialId, step: defaultStep } = useParams<{id: string, step: string}>();
	const tutorialId = props.tutorialId;
	const defaultStep = props.defaultStep;
	const getWorkspacePath = useCurrentWorkspacePath();
	const Alert = useAlert();
	const t = useTranslation('TutorialBuilder.Main');
	const tutorialInfo = useSelector(activeTutorialInfo);
	const currentUser = useSelector(selectSignedUserProfile);
	const [isLocked, setIsLocked] = useState(tutorialInfo ? true : false);
	const currentPath = history.location.pathname;
	const [tutorialDetails, setTutorialDetails] = useState<TutorialDetails>({
		author: '',
		description: '',
		title: '',
		updatedAt: ''
	});

	const [loadingContent, setLoadingContent] = useState<boolean | null>(null);
	const showTutorialBuilder = true;
	const [steps, setSteps] = useState<(TutorialStep & {selected: boolean})[]>([]);
	const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

	const selectedStep = steps.findIndex(step => step.selected);
	const currentState = steps[selectedStep];


	const onStepContentUpdated = useCallback((stepIndex: number, data: Partial<TutorialStep>) => {
		setSteps(list => {
			return list.map((s, i) => {
				if (i === stepIndex) {
					const step = { ...s, ...data };
					return step;
				} else {
					return s;
				}
			});
		});
	}, []);

	const notifyTutorialStepChange = useCallback((selectedIndex: number) => {
		dispatch(setActiveTutorialInfo({
			id: tutorialId,
			isDraft: false,
			notificationShown: true,
			step: selectedIndex
		}));

		history.push(
			routes.tutorial.getTutorialRoute(tutorialId, selectedIndex)
		);
	}, [dispatch, tutorialId, history]);

	/** marks a step as selected and loads its content */
	const selectStep = useCallback((index: number, abortNotification?: boolean) => {
		setSteps(allSteps => allSteps.map((step, i) => ({ ...step, selected: i === index })) );
		!abortNotification && notifyTutorialStepChange(index);
	}, [notifyTutorialStepChange]);


	/** creates a new empty step */
	const addStep = useCallback(() => {
		const totalSteps = steps.length;
		const emptyState = EditorState.createEmpty();
		const newStep: TutorialStep = {
			id: nanoid(),
			title: `Step ${totalSteps+1}`,
			durationMinutes: 0,
			content: emptyState
		};

		setSteps(list => list.map(step => ({ ...step, selected: false }))
		.concat({ ...newStep, selected: true }));
		notifyTutorialStepChange(steps.length);
		setHasUnsavedChanges(true);
	}, [steps, notifyTutorialStepChange]);


	const onCloseBuilder = useCallback(() => {
		const basePath = getWorkspacePath();
		if (hasUnsavedChanges) {
			Alert.confirm({
				title: t('CloseWarning.Title', 'You have unsaved changes'),
				iconColor: 'warning',
				content: t('CloseWarning.Description', 'If you close this window you will lose the most recent changes.'),
				okText: t('CloseWarning.CancelButton', 'Cancel'),
				okButtonColor: 'light',
				cancelButtonColor: 'danger',
				cancelText: t('CloseWarning.ExitButton', 'ExitButton'),
				onCancel: () => {
					history.push(basePath);
				}
			});
		} else {
			history.push(basePath);
		}
	}, [history, hasUnsavedChanges, Alert, t, getWorkspacePath]);

	/** Returns the current contents and metadata of the tutorial */
	const getTutorialState = useCallback(() => {
		const document: TutorialState = {
			title: tutorialDetails.title,
			description: tutorialDetails.description,
			pages: steps.map(step => {
				const content = step.content.getCurrentContent();
				const page = convertToRaw(content);
				return {
					content: page,
					durationMinutes: step.durationMinutes,
					title: step.title,
					id: step.id,
				};
			})
		};

		setHasUnsavedChanges(false);

		return document;
	}, [steps, tutorialDetails]);


	const handleDeleteStep = useCallback((index: number) => {
		setSteps(currentSteps => {
			// Remove requested index
			const filteredSteps = currentSteps.filter((_, i) => i !== index);
			// Find out the next best step
			const nextStep = (selectedStep - 1) >= 0 ? selectedStep - 1 : selectedStep;
			notifyTutorialStepChange(nextStep);
			return filteredSteps.map((step, i) => i === nextStep ? { ...step, selected: true } : step);
		});

		setHasUnsavedChanges(true);
	}, [selectedStep, notifyTutorialStepChange]);

	const updateStepTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.currentTarget.value;
		setSteps(steps => steps.map((step, index) => index === selectedStep ? { ...step, title: value } : { ...step }));
	};

	const updateTutorialTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.currentTarget.value;
		setTutorialDetails(d => ({ ...d, title: value }) );
	};

	const updateDescription = (text: string) => {
		setTutorialDetails(d => ({ ...d, description: text }) );
	};

	const onToggleLocked = useCallback((locked: boolean) => {
		setIsLocked(locked);
	}, []);

	const onTutorialSaved = useCallback(() => {
		setTutorialDetails(d => ({
			...d,
			isDraft: false,
			updatedAt: new Date().toJSON()
		}) );
	}, []);

	const handleEditorStateChange = useCallback((newState: EditorState) => {
		const index = steps.findIndex(step => step.selected);
		onStepContentUpdated(index, {
			content: newState
		});

		const currentContent = currentState.content.getCurrentContent();
		const newContent = newState.getCurrentContent();
		if (currentContent !== newContent) {
			setHasUnsavedChanges(true);
		}

	}, [onStepContentUpdated, steps, currentState]);


	const onChangeStepOrder = useCallback((fromIndex: number, toIndex: number) => {
		setSteps(prevSteps => {
			const stepsCopy = [...prevSteps];
			stepsCopy.splice(toIndex, 0, stepsCopy.splice(fromIndex, 1)[0]);
			return stepsCopy;
		});

		setHasUnsavedChanges(true);
	}, []);


	//  Load contents from recipe
	useEffect(() => {
		const loadContents = async () => {
			if (tutorialId && loadingContent === null) {
				console.log('Loading recipe contents');
				setLoadingContent(true);
				const tutorialRaw = await tutorialApi.getTutorialContentsById(tutorialId);
				// IMPORTANT: we upgrade older protocol versions no longer compatible
				upgradeProtocol(tutorialRaw);

				const tutorialState = tutorialRaw.contents;
				let initialStep = defaultStep || 0;
				if (initialStep >= tutorialState.pages.length) { initialStep = tutorialState.pages.length - 1; }
				if (initialStep < 0) { initialStep = 0; }

				if (tutorialState) {
					// Restore steps list from pages
					const storedSteps = tutorialState.pages.map(((page, i) => {

						const content = convertFromRaw(page.content);
						// Parse pages and extract the urls?
						return {
							content: EditorState.createWithContent(content),
							durationMinutes: page.durationMinutes,
							// The first one by default
							selected: i === initialStep,
							title: page.title,
							// Compatibility with older tutorials without step ids
							id: page.id || nanoid()
						};
					}));

					// Should go first to prevent re-issuing another request once the setTutorialDetails is dispatched
					setLoadingContent(false);
					setSteps(storedSteps);

					setTutorialDetails({
						author: `${tutorialRaw.author.name}`,
						updatedAt: tutorialRaw.updatedAt,
						title: tutorialRaw.title,
						description: tutorialRaw.description
					});

					notifyTutorialStepChange(initialStep);
				}
			}
		};

		loadContents();

	}, [loadingContent, tutorialId, notifyTutorialStepChange, defaultStep]);


	useEffect(() => {
		if (selectedStep !== -1 && Number(defaultStep) !== selectedStep) {
			selectStep(Number(defaultStep), true);
		}
	}, [currentPath, selectStep, defaultStep, selectedStep]);

	return (
		<AnimatePresence exitBeforeEnter>
			{ showTutorialBuilder && (
				<>
					<motion.div animate="visible" initial="hidden" variants={backdropAnimation} exit="hidden" className={styles.Backdrop} />
					<motion.div animate="visible" initial="hidden" variants={modalAnimation} className={styles.Modal}>
						<div className={styles.Container}>

							<TutorialBuilderHeader
								isLocked={isLocked}
								onToggleLock={onToggleLocked}
								onSaved={onTutorialSaved}
								onClose={onCloseBuilder}
								unsavedChanges={hasUnsavedChanges}
								tutorialId={tutorialId || undefined}
								onGetState={getTutorialState}
								title={tutorialDetails.title}
							/>

							{currentState && (
								<div className={styles.Drawer}>
									<StepsBar
										readOnly={isLocked}
										onDeleteStep={handleDeleteStep}
										onAddStep={addStep}
										onChangeOrder={onChangeStepOrder}
										steps={steps}
										onSelectStep={selectStep}
										selectedStep={selectedStep}
									/>
								</div>
							)}


							<Spin
								wrapperClassName={styles.SpinNotLoadingWrapper}
								spinning={!!loadingContent}
								tip={intl.formatMessage({ id: 'TutorialBuilder.Main.Default.LoadingAnimation', defaultMessage: 'Loading...' })}
								className={styles.LoaderContainer}
								indicator={<LoadingOutlined className={styles.LoaderIcon} spin />}
							>
								<div className={styles.Main}>
									{loadingContent ? (
										<div className={styles.LoadingPlaceholder} />
									) : (
										<>
										{currentState ? (
											<>

												<div className={styles.Title}>
													{/* Introduction to Kemu */}
													<input type="text" onChange={updateTutorialTitle} value={tutorialDetails.title} alt="Tutorial title"/>
												</div>

												{selectedStep === 0 && (
													<IntroCard
														onDescriptionChanged={updateDescription}
														description={tutorialDetails.description}
														author={tutorialDetails.author}
														updatedAt={tutorialDetails.updatedAt}
													/>
												)}

												<div className={`${styles.Card}`}>
													<div className={styles.StepHeader}>
														<span>{selectedStep + 1}. </span>
														<input onChange={updateStepTitle} name="title" type="text" value={steps[selectedStep].title} className={styles.StepHeaderInput} />
													</div>

													{tutorialId && currentUser.profile && (
														<TutorialEditor
															tutorialId={tutorialId}
															userId={currentUser.profile.id}
															editorState={currentState.content}
															updateEditorState={handleEditorStateChange}
															readOnly={isLocked}
														/>
													)}

												</div>
											</>
										) : (
											<div className={styles.EmptyContent}>
												<Result
													icon={<EmptyTutorialIcon style={{ color: 'var(--kemu-color-secondary)' }}/>}
													status="warning"
													title={<FormattedMessage id="TutorialBuilder.Main.NoStep.Title" defaultMessage="No content available" />}
													extra={
														<StyledButton onClick={addStep} title={<FormattedMessage id="TutorialBuilder.Main.NoStep.AddStepButton" defaultMessage="Add step" />} color="primary"/>
													}
												/>
											</div>
										)}
										</>
									)}
								</div>
							</Spin>
						</div>
					</motion.div>
				</>
			)}

		</AnimatePresence>
	);
};

export default TutorialBuilder;
