import React, { useCallback, useEffect, useState } from 'react';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
import { Menu, Popover } from 'antd';
import Icon from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useDrag } from 'react-dnd';
import { usePreview } from 'react-dnd-preview';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { usePreviewStateFull } from 'react-dnd-preview/dist/cjs/usePreview';
import { AnnotationPosition } from '@kemu-io/kemu-core/dist/types/annotation_t';
import classNames from 'classnames';
import useMountEffect from '../../../common/hooks/useMountEffect';
import TextAnnotation from '../../Annotations/TextAnnotation/TextAnnotation';
import { DroppableTypes, LM_CANVAS_CONTAINER_CLASS } from '../../../common/constants';
import { currentRecipePoolId, selectedBlock, selectVisibleGroup } from '../../../features/Workspace/workspaceSlice';
import { addAnnotation } from '../../../features/interface/interfaceSlice';
import useTranslation from '../../../common/hooks/useTranslation';
import { DropEventResult } from '../../../types/ui';
import DragNoteGif from './drag-note.gif';
import { ReactComponent as StickyNoteIcon } from './stickyNoteIcon.svg';
import styles from './AnnotationsMenu.module.css';

interface Props extends MenuItemProps {
	isCollapsed: boolean;
}


const AnnotationsPlaceholder = () => {
	const { display, style, itemType } = usePreview() as usePreviewStateFull;
  if (!display || itemType !== DroppableTypes.Annotation) {
		return null;
  }

	return (
		<div
			style={{
				backgroundColor: 'yellow',
				width: 100,
				position: 'fixed',
				...style
			}}
		>
			{/* Assuming itemType is ANNOTATION */}
			<TextAnnotation
				id="does_not_matter"
				recipeId="does_not_matter"
			/>
		</div>
	);
};


const AnnotationsMenu = (props: Props): React.JSX.Element | null => {
	const t = useTranslation('Workspace.Annotations');
	const dispatch = useDispatch();
	const [showPopOver, setShowPopOver] = useState(false);
	const { isCollapsed, ...menuProps } = props; 	// eslint-disable-line @typescript-eslint/no-unused-vars	
	const iconStyle = { fontSize: isCollapsed ? 26 : 16 };
	const recipePoolId = useSelector(currentRecipePoolId);
	const currentFolder = useSelector(selectVisibleGroup);
	const currentBlock = useSelector(selectedBlock);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [{ isDragging }, drag, preview] = useDrag<any, DropEventResult, any>(() => ({
    type: DroppableTypes.Annotation,
    // item: { name },
    end: (item, monitor) => {
			setShowPopOver(false);
      const dropResult = monitor.getDropResult();
			if (dropResult) {
				const isLogicMapper = dropResult.target.classList.contains(LM_CANVAS_CONTAINER_CLASS);
				onAddNote({
					x: dropResult.x,
					y: dropResult.y,
				}, isLogicMapper);
			}
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }), [recipePoolId, currentFolder]);



	const onAddNote = (position: AnnotationPosition, inLogicMapper: boolean) => {
		if (recipePoolId) {
			dispatch(addAnnotation({
				recipeId: recipePoolId,
				annotation: {
					id: 'FIXME: fix types... it should not require an id',
					thingId: inLogicMapper ? currentBlock?.recipeId : undefined,
					groupId: inLogicMapper ? currentFolder?.groupId : undefined,
					location: inLogicMapper ? 'logicMapper' : 'workspace',
					width: 0,
					height: 0,
					type: 'text',
					position: position
				}
			}));
		}
	};

	const onClick = () => {
		setShowPopOver(true);
	};

	const hidePopOver = useCallback(() => {
		setShowPopOver(false);
	}, []);

	// Make sure to hide the popup helper when the user clicks outside
	useMountEffect(() => {
		window.addEventListener('mousedown', hidePopOver);
		return () => {
			window.removeEventListener('mousedown', hidePopOver);
		};
	});

	useEffect(() => {
		preview(getEmptyImage());
	}, [preview, isDragging]);

	return (
		<>
			<Menu.Item {...menuProps}
				onClick={onClick}
				title={null}
				key="addNote"
				data-kemu-meta="add-annotation-btn"
				className={
					classNames(styles.MenuItem, { [styles.Expanded]: !props.isCollapsed })
				}
			>
				<Popover
					visible={showPopOver && !isDragging}
					destroyTooltipOnHide
					overlayClassName={styles.PopOverWrapper}
					placement="right"
					trigger="click"
					title={t('HelpTitle', 'Use Drag & Drop')}
					content={<>
						<img src={DragNoteGif} className={styles.HelperPreview}/>
					</>}
				>
					<div ref={drag}>
						<Icon component={StickyNoteIcon} style={iconStyle} />
						<span>{t('MenuButton.Title', 'Add Annotation')}</span>
					</div>
				</Popover>
			</Menu.Item>
			<AnnotationsPlaceholder />
		</>
	);
};

export default AnnotationsMenu;
