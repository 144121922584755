/*
 * Written by Alexander Agudelo < alex@kemu.io >, 2023
 * Date: 09/Sep/2023
 * Last Modified: 09/09/2023, 1:29:07 pm
 * Modified By: Alexander Agudelo
 * Description: This hook attempts to keep the /tmp cache clean of unused bundles.
 * It creates a list of bundles added to the /tmp cache when the page is unloaded.
 * It then proceeds to remove that list when the page is loaded. 
 * ------
 * Copyright (C) 2023 Kemu - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential.
 */

import { findRecipe } from '@kemu-io/kemu-core/dist/common/recipeCache';
import { CustomWidgetState, WidgetBundleState, WidgetType } from '@kemu-io/kemu-core/dist/types';
import widgetBundleManager from '@kemu-io/kemu-core/dist/widgetBundle/manager';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { currentRecipePoolId } from '@src/features/Workspace/workspaceSlice';
import { safeJsonParse } from '@common/utils';
import Logger from '@common/logger';

const logger = Logger('useClearBundleCacheOnRefresh');
const storageKey = 'bundleCleanup';
type CleanupKey = {
  widgetId: string;
  version: string;
};

const getCleanupList = (): CleanupKey[] => {
  const cleanupArrayStr = localStorage.getItem(storageKey) || '[]';
  const cleanupArray = safeJsonParse<CleanupKey[]>(cleanupArrayStr) || [];
  return cleanupArray;
};

const saveCleanupList = (cleanupArray: CleanupKey[]) => {
  localStorage.setItem(storageKey, JSON.stringify(cleanupArray));
};

const processCleanup = async () => {
  const cleanupList = getCleanupList();
  logger.log('**** Cleaning up environment ****');
  for (const cleanupItem of cleanupList) {
    logger.log(`Removing old bundle: ${cleanupItem.widgetId} - ${cleanupItem.version}`);
    try {
      await widgetBundleManager.removeWidgetFilesFromCache(
        cleanupItem.widgetId,
        cleanupItem.version,
        true,
      );
    } catch (e) {
      logger.error('Error removing bundle from cache', e);
    }
  }

  // Clear the cleanup list
  saveCleanupList([]);
};

/**
 * Loop through every bundle in the recipe and add it to the removal list.
 * (to be removed when the page is loaded the next time)
 * @param recipePoolId 
 */
const handlePageUnload = (recipePoolId: string) => {
  const recipe = findRecipe(recipePoolId);
  if (recipe) {
    const cleanupList = getCleanupList();
    for (const thingId in recipe.blocks) {
      const thing = recipe.blocks[thingId];
      for (const widgetId in thing.gates) {
        const widget = thing.gates[widgetId];
        if (widget.type === WidgetType.widgetBundle) {
          const bundleState = widget.state as CustomWidgetState<WidgetBundleState>;
          if (bundleState.$$cacheInfo) {
            const isTempBundle = bundleState.$$cacheInfo?.widgetThingId
              !== bundleState.$$collectionInfo?.widgetId;

            if (isTempBundle) {
              cleanupList.push({
                widgetId: bundleState.$$cacheInfo.widgetThingId,
                version: String(bundleState.$$cacheInfo.version),
              });
            }
          }
        }
      }
    }

    saveCleanupList(cleanupList);
  }
};

/**
 * Hook to remove all /tmp bundles in the Cache storage when the page is unloaded.
 * This hook loops through all the widget bundle type in the current recipe and removes
 * the bundle from the cache storage.
 */
const useClearBundleCacheOnRefresh = () => {
  const recipePoolId = useSelector(currentRecipePoolId);

  useEffect(() => {
    const onConfirmRefresh = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      if (recipePoolId) {
        return handlePageUnload(recipePoolId);
      }
    };

    if (recipePoolId) {
      window.addEventListener('beforeunload', onConfirmRefresh);
      processCleanup();
    }

    return () => {
      if (recipePoolId) {
        window.removeEventListener('beforeunload', onConfirmRefresh);
      }
    };
  }, [recipePoolId]);
};

export default useClearBundleCacheOnRefresh;
