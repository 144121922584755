import React, { useState } from 'react';
import classNames from 'classnames';
import ReactPlayer from 'react-player/vimeo';
import { Result } from 'antd';
import StyledButton from '../../../../components/form-control/styledButton/styledButton';
import useTranslation from '../../../../common/hooks/useTranslation';
import { ReactComponent as ConnectionErrorIcon } from './connection-error.svg';

import styles from './VideoPopup.module.css';

interface Props {
	url?: string;
	/** invoked when the video starts playing */
	onPlay?: () => void;
	/** invoked when 80% of the video has been watched */
	onVideoViewed?: () => void;
	/** invoked when the video has finished playing */
	onVideoCompleted?: () => void;
	onClose: () => void;
	wrapperClassName?: string;
}

type VideoProgress = {
	played: number;
	playedSeconds: number;
	loaded: number;
	loadedSeconds: number;
}

const VideoPopup = (props: Props): React.JSX.Element | null => {
	const [parentNotified, setParentNotified] = useState(false);
	const [showFallback, setShowFallback] = useState(false);
	const t = useTranslation('VideoPopup.Error');


	const handleProgress = (progress: VideoProgress) => {
		if (progress.played > 0.8 && !parentNotified) {
			props.onVideoViewed && props.onVideoViewed();
			setParentNotified(true);
		}
	};

	const handleError = () => {
		setShowFallback(true);
	};


	if (!props.url) { return null; }
	return (
		<div className={classNames(styles.Wrapper, props.wrapperClassName)}>
			{showFallback ? (
				<div className={styles.OfflineContainer}>
					<Result
						className={styles.ResultContainer}
						icon={<ConnectionErrorIcon className={styles.Icon}/>}
						title={t('Title', 'Network Error')}
						subTitle={t('Subtitle', 'Failed to load video, please check your internet connection and try again.')}
						extra={
							<StyledButton type="button" color="primary" title={t('Button', 'Close')} onClick={props.onClose}/>
						}
					/>,
				</div>
			) : (
				<ReactPlayer
					url={props.url}
					controls={true}
					playing={true}
					onPlay={props.onPlay}
					onError={handleError}
					onProgress={handleProgress}
					onEnded={props.onVideoCompleted}
					className={styles.VideoIframe}
					width="100%"
					height="100%"
				/>
			)}
		</div>
	);
};

export default VideoPopup;
