import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import thingApiInterface from '@kemu-io/kemu-core/dist/common/thingApiInterface';
import widgetApiInterface from '@kemu-io/kemu-core/dist/common/widgetApi';
import WebServiceManager from '@kemu-io/kemu-core/dist/common/webServicesManager';
import { WebServiceType } from '@kemu-io/kemu-core/dist/types/webServices_t';
import kemuCore from '@kemu-io/kemu-core/dist/index';
import eventHistory from '@kemu-io/kemu-core/dist/common/eventHistoryMap';
import * as recipeCache from '@kemu-io/kemu-core/dist/common/recipeCache';
import I18nProvider from './assets/i18n/I18nProvider';
import AuthLayout from './features/auth/layout';
import { Kemu } from './features/kemu/kemu';
import PageNotFound from './features/404/PageNotFound';
import DirectActionLayout from './features/directAction/layout';
import * as serviceWorker from './serviceWorker';
import { setNewVersionAvailable } from './features/interface/interfaceSlice';
import Hotspots from './@asurantech/hotspots/Hotspots';
import * as thingApi from './api/thing/thingApi';
import * as widgetApi from './api/widget/widgetApi';
import { buildDynamoDbService, buildGoogleSheetsService, buildHttpService } from './api/webServices/webServicesApi';
import globals from '@common/globals';
// import useUserbackWidget from '@common/hooks/useUserbackWidget';
import { setLanguage } from '@common/translations';
import routes from '@common/routes/index';
import UserSessionContextProvider from '@common/context/UserSessionContext/UserSessionContext';
import loadSpeedyVision from '@common/speedy-vision/speedyVision';
import configureAmplify from '@common/amplify';
import Logger from '@common/logger';
import useClearBundleCacheOnRefresh from '@hooks/useClearBundleCacheOnRefresh';

const logger = Logger('App');

configureAmplify();

if (globals.STAGE === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).__kd__ = {
    core: kemuCore,
    recipeCache,
    eventHistory,
  };
}

const App: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const [language] = useState('en');
  const abortLoading = globals.STAGE !== 'production';
  // useUserbackWidget(abortLoading);
  useClearBundleCacheOnRefresh();
  loadSpeedyVision();

  useEffect(() => {
    // This exports the whole font-awesome icon packs for Brand and Solid.
    library.add(fab, fas);

    // Set the actual (authenticated) api so that kemu-core can fetch block information.
    thingApiInterface.setApi(thingApi);
    widgetApiInterface.setApi(widgetApi);
    WebServiceManager.setServiceManager(WebServiceType.Http, buildHttpService());
    WebServiceManager.setServiceManager(WebServiceType.DynamoDb, buildDynamoDbService());
    WebServiceManager.setServiceManager(WebServiceType.GoogleSheets, buildGoogleSheetsService());

    logger.log('Registering service worker');
    setLanguage(language);

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    // serviceWorker.unregister();
    serviceWorker.register({
      onUpdate: (registration) => {
        logger.log('Skipping waiting to use new version: ');
        registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
      },

      onInstalling: (state) => {
        // STATES:
        // 'installed'
        // => version skipped after the above message
        // 'activating'
        // 'activated' <== Show popup to user?
        logger.log('Service worker state: ', state);
        if (state === 'activated') {
          dispatch(setNewVersionAvailable());
        }
      }
    });
  }, [dispatch, language]);

  return (
    <div className="App">
      <Router>
        <I18nProvider language={ language }>
          <Hotspots />
          <UserSessionContextProvider>
            <Switch>
              <Route path={routes.auth.getAuthBaseRoute()} component={AuthLayout} />
              <Route path={routes.getDirectActionRoute()} component={DirectActionLayout} />
              <Route path={routes.getBaseRoute()} component={Kemu} />
              <Route component={PageNotFound} />
            </Switch>
          </UserSessionContextProvider>
        </I18nProvider>
      </Router>
    </div>
  );
};

export default App;
