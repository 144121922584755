import React, { FocusEvent, useCallback } from 'react';
import { Select } from 'antd';
import classNames from 'classnames';
import { LM_COMPONENT_INPUT_TYPE_CLASS } from '../../../common/constants';
import styles from './TagsInput.module.css';

const { Option } = Select;

type Props = {
  label?: string;
  className?: string;
  placeholder?: string;
  readOnly?: boolean;
	value?: string[];
	name?: string;
  disabled?: boolean;
  /** used to uniquely identify the input in event handlers */
  eventId?: string,
  onChange?: (tags: string[], eventId?: string) => void;
  /**
   * @param text the new text
   * @param key same as properties key
   */
	onBlur?: (eventId: string | undefined, originalEvent: FocusEvent<HTMLInputElement>) => void;
}

const TagsInput = (props: Props): React.JSX.Element => {
  const { onChange, onBlur, eventId } = props;

  const handleBlur = useCallback((originalEvent: FocusEvent<HTMLInputElement>) => {
    onBlur && onBlur(eventId, originalEvent);
  }, [onBlur, eventId]);

  const handleAttributeChange = useCallback((tags: string[]) => {
    const trimmedTags = tags.map(tag => tag.trim()).filter(tag => tag !== '');
    onChange && onChange(trimmedTags, eventId);
	}, [onChange, eventId]);

  return (
    <div className={classNames(styles.Wrapper, props.className)}>
      {props.label && (
        <label className={styles.Label}>{props.label}</label>
      )}

      <Select
        tokenSeparators={[',']}
        onBlur={handleBlur}
        value={props.value}
        className={classNames(LM_COMPONENT_INPUT_TYPE_CLASS, styles.TagsElement)}
        dropdownClassName={styles.TagsDropdown}
        mode="tags"
        onChange={handleAttributeChange}

      >
        {props.value?.map((attrName, i) => (
          <Option key={`attr_${attrName}_${i}`} value={attrName}>
            {attrName}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default TagsInput;
