import React, { useState } from 'react';
import ReactPlayer from 'react-player/vimeo';
import useTranslation from '../../../../../common/hooks/useTranslation';
import StyledButton from '../../../../../components/form-control/styledButton/styledButton';
import MarkedText from '../../../../../components/MarkedText/MarkedText';
import styles from './TutorialSlide.module.css';

interface Props {
	direction: 'right' | 'left';
	videoOrImage: string;
	title: string;
	description: string;
	durationTxt: string;
	id: string;
	onLaunch: (id: string) => void;
}

const isImage = (url: string) => {
	if (url.startsWith('https://vimeo.com')) { return false; }
	return true;
};

const TutorialSlide = (props: Props): React.JSX.Element => {
	const urlIsImage = isImage(props.videoOrImage);
	const [isPlaying, setIsPlaying] = useState(false);
	const t = useTranslation('Launcher.Tabs.GuidedTutorials');
	const handleClick = (): void => {
		props.onLaunch(props.id);
	};

	const handleVideoReady = (): void => {
		setIsPlaying(true);
	};


	return (
		<div className={styles.TutorialSlideWrapper}>
			<div className={styles.Body}>
				<div className={styles.LeftText}>
					<div className={styles.Title}>
						{props.title}
					</div>
					<div className={styles.Duration}>
						{props.durationTxt}
					</div>
					<div className={styles.Description}>
						<MarkedText text={props.description} />
					</div>

					<StyledButton
						className={styles.Button}
						color="secondary"
						onClick={handleClick}
						title={t('OpenBtn', 'Open Tutorial')}
					/>
				</div>

				<div className={styles.MediaContainer}>
					{urlIsImage ? (
						<div className={styles.Image}
							style={{ backgroundImage: `url(${props.videoOrImage})` }}
						/>
					) : (
						<ReactPlayer
							playing={isPlaying}
							url={props.videoOrImage}
							controls={false}
							loop={true}
							muted={true}
							onReady={handleVideoReady}
							className={styles.Video}
							width='320px'
							height='240px'
						/>
					)}
				</div>
			</div>


		</div>
	);
};


export default TutorialSlide;
