import './canvas.css';
import React, { useRef, useMemo, useCallback, useEffect, useState, forwardRef } from 'react';
import Draggable, { DraggableEvent, DraggableEventHandler } from 'react-draggable';
import { Position } from '@kemu-io/kemu-core/dist/types/block_t';
import { useDrop } from 'react-dnd';
import { useContextMenu } from 'react-contexify';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import {
	editCustomWidgetState,
	getBlockCanvasInfo,
	getCanvasInfo,
	getCustomWidgetState,
	setBlockCanvasInfo,
	setCanvasInfo
} from '../../app/recipe/utils';
import { SelectedBlockInfo } from '../../types/core_t';
import { ABORT_DRAGGING_CLASS,
	ANNOTATION_DRAGGABLE_CLASS,
	CANVAS_DRAGGABLE_CLASS,
	DroppableTypes,
	LM_CANVAS_CONTAINER_CLASS,
	CANVAS_CONTAINER_CLASS,
	LOGIC_MAPPER_CANVAS_CLASS,
	RIGHT_BUTTON_INDEX,
	GET_CANVAS_DRAG_BUTTON_INDEX
} from '../../common/constants';
import { MENU_LOGIC_MAKER } from '../../features/LogicMapper/contextMenu/contextMenu';
import { selectVisibleGroup } from '../../features/Workspace/workspaceSlice';
import AnnotationsManager from '../Annotations/AnnotationsManager';
import { DropEventResult } from '../../types/ui';

interface Props {
	children?: React.ReactNode;
	recipeId: string;
	/** 
	 * indicates if there is a block selected (normally this means someone double clicked on the block to access
	 * its Logic Maker
	*/
	selectedBlock: SelectedBlockInfo | null;
	loadingArea: 'workspace' | 'logic-mapper';
}


const Canvas = (props: Props, ref: React.Ref<HTMLDivElement>): React.JSX.Element => {
	const containerRef = useRef<HTMLDivElement | null>(null);
	const currentFolder = useSelector(selectVisibleGroup);
	const [defaultPosition, setDefaultPosition] = useState<{x: number, y: number}>();
	const { show: showMenu } = useContextMenu({ id: MENU_LOGIC_MAKER });
	const canvasBackgroundEl = useMemo(() => {
		return containerRef.current?.querySelector(`.${CANVAS_DRAGGABLE_CLASS}.${LOGIC_MAPPER_CANVAS_CLASS}`);
	}
	// IMPORTANT: We DO need to monitor the ref to wait for the container to be mounted
	// eslint-disable-next-line react-hooks/exhaustive-deps
	, [containerRef.current]);

	// Handles drag and Dropping of annotations and widgets
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [{ canDrop, isOver }, dropRef] = useDrop(() => ({
    accept: [DroppableTypes.Widget, DroppableTypes.CustomWidget, DroppableTypes.Annotation],
    drop: (item, monitor) => {
			const offset = monitor.getSourceClientOffset();
			if (offset && containerRef.current && defaultPosition) {
				const dropTargetXy = containerRef.current.getBoundingClientRect();
				const result: DropEventResult = {
					x: offset.x - dropTargetXy.left - defaultPosition.x,
					y: offset.y - dropTargetXy.top - defaultPosition.y,
					target: containerRef.current,
				};

				return result;
			}
		},
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }), [defaultPosition]);

	// NOTE: This should be done via redux actions?.. Maybe not for better performance? (unless undo is required)
	const handleDragStopped: DraggableEventHandler = useCallback((e, data) => {
		const newPos = {
			x: data.x,
			y: data.y
		};

		if (props.loadingArea === 'logic-mapper' && props.selectedBlock) {
			// Check if we are inside of a group
			if (currentFolder?.groupId) {
				const widgetGroupState = getCustomWidgetState(props.recipeId, props.selectedBlock.recipeId, currentFolder.groupId);
				const deltaMovement = (newPos.x - widgetGroupState.canvasPosition.x) + (newPos.y - widgetGroupState.canvasPosition.y);
				// Ignore clicks on the same position
				if (deltaMovement) {
					editCustomWidgetState(props.recipeId, props.selectedBlock.recipeId, currentFolder.groupId, {
						...widgetGroupState,
						canvasPosition: newPos
					});
				}
			} else {
				// Logic Maker dragging
				const canvasInfo = getBlockCanvasInfo(props.recipeId, props.selectedBlock.recipeId);

				canvasInfo.logicMaker = {
					...canvasInfo.logicMaker,
					position: newPos
				};

				setBlockCanvasInfo(props.recipeId, props.selectedBlock.recipeId, canvasInfo);
			}
		} else if (props.loadingArea === 'workspace') {
			// Workspace dragging
			const canvasInfo = getCanvasInfo(props.recipeId);
			canvasInfo.workspace = {
				...canvasInfo.workspace,
				position: newPos
			};

			setCanvasInfo(props.recipeId, canvasInfo);
		}

		// we are using the 'position' argument of <Draggable /> to reset its internal state, so, for the drag to preserve
		// the final position we need to set it here as it will get passed to the `position` attribute on the next render
		setDefaultPosition(newPos);
	}, [props.recipeId, props.selectedBlock, currentFolder?.groupId, props.loadingArea]);


	// If the selected block changes (enter or exit the logic maker)
	// we get the canvas position from the recipe
	useEffect(() => {
		let nextPosition: Position = { x: 0, y: 0 };
		if (props.loadingArea === 'workspace') {
			// Workspace
			const canvasInfo = getCanvasInfo(props.recipeId);
			nextPosition = canvasInfo.workspace.position;
		} else if (props.selectedBlock) {
			// Logic mapper root or widget
			if (currentFolder?.groupId) {
				const widgetGroupState = getCustomWidgetState(props.recipeId, props.selectedBlock.recipeId, currentFolder.groupId);
				nextPosition = { ...widgetGroupState.canvasPosition };
			} else {
				const blockCanvasInfo = getBlockCanvasInfo(props.recipeId, props.selectedBlock.recipeId);
				nextPosition = blockCanvasInfo.logicMaker?.position || { x: 0, y: 0 };
			}
		}

		setDefaultPosition(nextPosition);

	}, [props.selectedBlock, props.recipeId, currentFolder?.groupId, props.loadingArea]);


	const handleItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		const classList = (event.target as HTMLDivElement)?.classList || [];
		const clickedOnBackground = classList.contains(CANVAS_DRAGGABLE_CLASS);
		const clickedOnCurrentArea = classList.contains(LOGIC_MAPPER_CANVAS_CLASS);

		if (props.selectedBlock && clickedOnBackground && clickedOnCurrentArea) {
			event.preventDefault();
			showMenu(event, {
				props: {
					boundaryClass: props.loadingArea === 'workspace'
					? CANVAS_CONTAINER_CLASS
					: LM_CANVAS_CONTAINER_CLASS
				},
			});
		} else if (event.buttons === RIGHT_BUTTON_INDEX) {
			event.preventDefault();
		}
	};

	const onDragStart = useCallback((event: DraggableEvent & { buttons?: number }) => {
		// Allow dragging via middle button
		if (event.buttons !== GET_CANVAS_DRAG_BUTTON_INDEX()) {
			return false;
		}
	}, []);

	return (
		/* 
		* `nodeRef={containerRef}` is a workaround to the ReactDOM.findDOMNode() warning. Check official documentation for more info
		* https://www.npmjs.com/package/react-draggable#draggable-api
		*/
		<div ref={dropRef} style={{ width: 'auto', height: '100%' }}>
			<div
				ref={containerRef} onContextMenu={handleItemClick}
				className={
					props.loadingArea === 'workspace'
					? CANVAS_CONTAINER_CLASS
					: LM_CANVAS_CONTAINER_CLASS
				}
			>
				<Draggable
					nodeRef={containerRef}
					cancel={`.canvas-block, .canvas-gate, .${ANNOTATION_DRAGGABLE_CLASS}, .${ABORT_DRAGGING_CLASS}`}
					handle={`.${CANVAS_DRAGGABLE_CLASS}`}
					onStop={handleDragStopped}
					onStart={onDragStart}
					allowAnyClick={true}
					position={defaultPosition}
					bounds={{
						left: -(canvasBackgroundEl ? ((canvasBackgroundEl?.clientWidth || 0) - (containerRef.current?.clientWidth || 0)) : containerRef.current?.clientWidth || 0),
						top: -(canvasBackgroundEl ? ((canvasBackgroundEl?.clientHeight || 0) - (containerRef.current?.clientHeight || 0)) : containerRef.current?.clientHeight || 0),
						right: 0,
						bottom: 0,
					}}
				>
					<div
						ref={ref}
						className={classNames(
							CANVAS_DRAGGABLE_CLASS,
							{
								[LOGIC_MAPPER_CANVAS_CLASS]: props.loadingArea === 'logic-mapper'
							}
						)}
					>
						{ props.children }
						<AnnotationsManager
							recipeId={props.recipeId}
							location={props.loadingArea === 'workspace' ? 'workspace' : 'logicMapper'}
						/>
					</div>
				</Draggable>
			</div>
		</div>
	);
};

export default forwardRef<HTMLDivElement, Props>(Canvas);
