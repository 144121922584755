import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import SplitLayout from '../../components/layout/SplitLayout/SplitLayout';
import { currentRecipePoolId, selectBaseRecipeLoaded, selectCurrentRecipeType } from '../Workspace/workspaceSlice';
import routes from '../../common/routes';
import { downloadRecipeAction } from '../interface/reducers/downloadRecipeReducer';
import useAlert from '../../components/alert/useAlert';

const BrowserRecipeScreen = (): React.JSX.Element | null => {
  const dispatch = useDispatch();
  const recipeType = useSelector(selectCurrentRecipeType);
  const alert = useAlert();
  const history = useHistory();
  const { id: recipeIdInPath } = useParams<{id?: string}>();
  const baseRecipeLoaded = useSelector(selectBaseRecipeLoaded);
  const recipePoolId = useSelector(currentRecipePoolId);


  useEffect(() => {
    // Download the recipe in the path if not already initialized
    // if(!baseRecipeLoaded) {
    //   history.push(routes.getBaseRoute());
    //   return;
    // }
    if (!recipePoolId && recipeIdInPath) {
      // Download contents and initialized the recipe
      dispatch(downloadRecipeAction({
        recipeId: recipeIdInPath,
        alert,
        history
      }));
    } else if (!recipePoolId && !recipeIdInPath) {
      // Redirect to environment selection
      history.push(routes.getBaseRoute());
    }
  }, [recipeIdInPath, alert, history, dispatch, recipePoolId]);

  if (!recipeType) { return null; }

  return (
    <>
			<SplitLayout />
    </>
  );
};

export default BrowserRecipeScreen;
