import React from 'react';
import createToolbarPlugin, { StaticToolBarPlugin, Separator } from '@draft-js-plugins/static-toolbar';
import { ToolbarPubProps } from '@draft-js-plugins/static-toolbar/lib/components/Toolbar';
import classnames from 'classnames';
import styles from './index.module.css';


export type StaticToolbarProps = ToolbarPubProps & {
	popupVisible?: boolean;
}

export type StaticToolbarPluginConfig = {
	Toolbar:  React.ComponentType<StaticToolbarProps>;
	plugins: StaticToolBarPlugin[];
	Separator: () => React.ReactElement
}

const buildPlugins = () => {
	const toolbarPlugin = createToolbarPlugin({
		theme: {
			buttonStyles: {
				button: classnames(styles.Button, 'static-btn'),
				buttonWrapper: classnames(styles.ButtonWrapper, 'static-btn-wrapper'),
				active: classnames(styles.ButtonActive, 'static-btn-active')
			},
			toolbarStyles: {
				toolbar: classnames(styles.Wrapper, 'static-toolbar'),
			}
		}
	});

	const plugins = [toolbarPlugin];
	const { Toolbar } = toolbarPlugin;

	return { plugins, Toolbar };
};


const createStaticToolbarPlugin = (): StaticToolbarPluginConfig  => {
	const { plugins, Toolbar } = buildPlugins();

  return {
    plugins,
		Toolbar,
		Separator: () => <Separator className={styles.Separator} />
  };
};

export default createStaticToolbarPlugin;
