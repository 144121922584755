import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import StyledInput from '../form-control/styledInput/styledInput';
import StandardModal from '../modal/standardModal';

interface Props {
	onSubmit: (name: string) => void;
	onCancel: () => void;
	currentName: string;
	/** default to false */
	closeOnMaskClick?: boolean;
	/** default true */
	submitOnEnter?: boolean;
	/** default 'Save' */
	okLabel?: string | React.ReactNode;
	/** default 'Cancel' */
	cancelLabel?: string | React.ReactNode;
	/** default 'Rename' */
	title?: string;
	placeholder?: string;
	width?: number;
}

const RenameModal = (props: Props): React.JSX.Element => {
	const [newName, setNewName] = useState(props.currentName);
	const [visible, setVisible] = useState(true);

	const intl = useIntl();

	const handleOk = () => {
		props.onSubmit && props.onSubmit(newName);
	};

	const handleCancel = () => {
		setVisible(false);
	};

	const reportClosed = () => {
		props.onCancel && props.onCancel();
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			handleOk();
		}
	};

	return (
		<StandardModal
			dataKemuMeta="rename-modal"
			closeOnMaskClick={props.closeOnMaskClick || false}
			onOk={handleOk}
			onCancel={handleCancel}
			onAfterClosed={reportClosed}
			title={props.title || <FormattedMessage id="Interface.Modals.Rename.Title" defaultMessage="Rename" />}
			okBtnLabel={props.okLabel || <FormattedMessage id="Interface.Modals.Rename.OkBtn" defaultMessage="Save" />}
			cancelBtnLabel={props.cancelLabel || <FormattedMessage id="Interface.Modals.Rename.CancelBtn" defaultMessage="Cancel" />}
			width={props.width || 300}
			disableOkButton={!newName.trim().length}
			visible={visible}
		>
			<StyledInput placeholder={props.placeholder !== undefined ? props.placeholder : intl.formatMessage({ id: 'Interface.Modals.Rename.Placeholder', defaultMessage: 'Type a new name' })} onChange={(e) => setNewName(e.currentTarget.value)} value={newName} onKeyDown={handleKeyDown}  />

	</StandardModal>
	);
};

export default RenameModal;
