import { PayloadAction, Draft } from '@reduxjs/toolkit';
import { AnnotationDataTypes, AnnotationPosition, KemuAnnotation } from '@kemu-io/kemu-core/dist/types/annotation_t';
import { InterfaceState } from '../interfaceSlice';
import * as annotationUtils from '../../../app/recipe/annotations';

type AddAnnotationOptions<T> = { recipeId: string; annotation: KemuAnnotation<T> };
type RemoveAnnotationOptions = { recipeId: string; annotationId: string };
type MoveAnnotationOptions = { recipeId: string; annotationId: string; position: AnnotationPosition };
type EditAnnotationOptions<T> = { recipeId: string; annotationId: string; data: T };

export const addAnnotationAction = <T extends AnnotationDataTypes>(state: Draft<InterfaceState>, action: PayloadAction<AddAnnotationOptions<T>>): void => {
	// Create a copy since the payload is frozen
	const addedAnnotation = annotationUtils.addNewAnnotation<T>(action.payload.recipeId, { ...action.payload.annotation });
	state.annotations = {
		...state.annotations,
		// Make sure to clone the object since redux will freeze it internally
		[addedAnnotation.id]: { ...addedAnnotation }
	};
};

export const removeAnnotationAction = (state: Draft<InterfaceState>, action: PayloadAction<RemoveAnnotationOptions>): void => {
	const remainingAnnotations = annotationUtils.removeAnnotation(action.payload.recipeId, action.payload.annotationId);
	state.annotations = JSON.parse(JSON.stringify(remainingAnnotations));
};

export const moveAnnotationAction = <T extends AnnotationDataTypes>(state: Draft<InterfaceState>, action: PayloadAction<MoveAnnotationOptions>): void => {
	const updatedAnnotation = annotationUtils.updateAnnotationPosition<T>(
		action.payload.recipeId,
		action.payload.annotationId,
		{ ...action.payload.position },
	);

	state.annotations = {
		...state.annotations,
		[updatedAnnotation.id]: { ...updatedAnnotation }
	};
};

export const editAnnotationDataAction = <T extends AnnotationDataTypes>(state: Draft<InterfaceState>, action: PayloadAction<EditAnnotationOptions<T>>): void => {
	const updatedAnnotation = annotationUtils.updateAnnotationData<T>(
		action.payload.recipeId,
		action.payload.annotationId,
		{ ...action.payload.data }
	);

	state.annotations = {
		...state.annotations,
		[updatedAnnotation.id]: { ...updatedAnnotation }
	};
};


export const loadAllAnnotationsAction = (state: Draft<InterfaceState>, action: PayloadAction<string>): void => {
	const currentAnnotations = annotationUtils.getRecipeAnnotations(action.payload);
	state.annotations = JSON.parse(JSON.stringify(currentAnnotations));
};
