
import React, { KeyboardEventHandler, useEffect, useRef, useState, CSSProperties } from 'react';


import WidgetGroupProcessor, {
	WidgetGroupState,
	getDefaultState,
} from '@kemu-io/kemu-core/dist/gates/widgetGroup';
import { motion } from 'framer-motion';
import { CustomGateState, WidgetPortContext } from '@kemu-io/kemu-core/dist/types/gate_t';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PortLocation } from '../../../types/canvas_t';
import { pushFolderPath } from '../../../features/Workspace/workspaceSlice';
import { setCurrentWidgetAction } from '../../../app/reducers/widget/widgetSlice';
import DynamicIcon from '../../dynamicIcon/dynamicIcon';
import { WIDGET_DEFAULT_ICON_NAME } from '../../../common/constants';
import {
	GetPortsInformationFunction,
	GateUI,
	GateUIProps
} from '..';
import useReactiveWidgetState from '../../../common/hooks/useReactiveWidgetState';
import styles from './widgetGroup.module.css';


type Props = GateUIProps

const animationVariants = {
  opening: { opacity: 0, scale: 5 },
  closed: { opacity: 1, scale: 1 },
};

const WidgetGroup = (props: Props): React.JSX.Element => {
	const dispatch = useDispatch();
	const [state, setState] = useReactiveWidgetState<WidgetGroupState>(props.recipeId, props.thingRecipeId, props.info.id);
	const [editing, setEditing] = useState(false);
	const inputRef = useRef<HTMLInputElement | null>(null);
	const [isOpen, setIsOpen] = useState(false);

	const fixedState = {
		...getDefaultState(),
		...state
	};

	const styledBody: CSSProperties = fixedState.color && !props.info.disabled ? {
		backgroundColor: fixedState.color
	} : {};

	const handleEditName = (event: React.MouseEvent<HTMLLabelElement> ) => {
		event.stopPropagation();
		setEditing(true);
	};

	const handleAnimationComplete = () => {
		if (isOpen) {
			// Set the id of the widget being edited in the widget slice or clear it out
			dispatch(setCurrentWidgetAction(fixedState.collectionInfo?.widgetId || null));


			// Set the info of the path in the workspace slice
			dispatch(pushFolderPath({
				groupId: props.info.id,
				name: fixedState.name,
				description: fixedState.description,
			}));
		}
	};

	const handleDoubleClick = () => {
		setIsOpen(true);
	};

	const handleBlur = () => {
		if (inputRef.current) {
			const newName = inputRef.current.value.trim();
			if (newName) {
				setState({
					...state,
					name: newName
				});
			}
		}
		setEditing(false);
	};

	const handleKeyDown: KeyboardEventHandler = (event) => {
		if (event.key === 'Enter') {
			handleBlur();
		}
	};

	useEffect(() => {
		if (editing && inputRef.current) {
			inputRef.current.select();
		}
	}, [editing]);

	// 44 = Default height is 54
	// 10 = extra pixels per added port
	const bodyHeight = 44 + ((Math.max(fixedState.inputs.length, fixedState.outputs.length)) * 10);

	return (
		<motion.div
			animate={isOpen ? 'opening' : 'closed'}
			variants={animationVariants}
			transition={{ duration: 0.25 }}
			onAnimationComplete={handleAnimationComplete}
		>
			<div className={styles.GateBody} onDoubleClick={handleDoubleClick} style={{ height: bodyHeight }}>
				{fixedState.type === 'custom' ? (
					<div className={styles.WidgetTypeContainer} style={styledBody}>
						<DynamicIcon
							className={styles.DynamicIcon}
							iconName={fixedState.icon}
							default={<FontAwesomeIcon icon={['fas', WIDGET_DEFAULT_ICON_NAME]} className={styles.DynamicIcon}/>}
						/>

						{fixedState.collectionInfo !== undefined && (
							<div className={styles.VersionContainer}>
								v{fixedState.collectionInfo?.version}
							</div>
						)}
					</div>
					) : (
						<div className={styles.FolderTypeContainer}>
							<DynamicIcon
								className={styles.DynamicIcon}
								iconName={'folder'}
							/>
						</div>
				)}
				<div className={classNames(styles.Label, { editing })}>
					{!editing ? (
						<Tooltip title={fixedState.description} placement="bottom" mouseEnterDelay={0.8}>
							<label onDoubleClick={handleEditName}>{fixedState.name}</label>
						</Tooltip>
					) : (
						<input ref={inputRef} onKeyDown={handleKeyDown} onBlur={handleBlur} defaultValue={fixedState.name} />
					)}
				</div>

			</div>
		</motion.div>
	);
};

const getPortsInformation: GetPortsInformationFunction = (state: CustomGateState<WidgetGroupState>, widgetInfo) => {
	const portContext: WidgetPortContext = { recipePoolId: widgetInfo.recipePoolId, recipeType: widgetInfo.recipeType };
	const outputNames = WidgetGroupProcessor.getOutputNames(state, portContext);
	const inputNames = WidgetGroupProcessor.getInputNames(state, portContext);

	const onlyOuterOutputs = outputNames.filter(port => !port.isInner);
	const onlyOuterInputs = inputNames.filter(port => !port.isInner);

	const portSize = 0.02;

	const getPositionFromIndex = (total: number, index: number, isInput: boolean): PortLocation => {
		const topPadding = total <= 12 ? 0.15 : 0.05;
		const inputFraction = ((1 - (topPadding + portSize)) / total);
		let space = (inputFraction * index) + topPadding;
		const totalPorts = total + (state.useTriggerPort ? 1 : 0);
		if (totalPorts === 1) { space = 0.5; }
		if (totalPorts === 2) { space = 0.30 + (0.4 * index); }
		if (totalPorts === 3) { space = 0.25 + (0.25 * index); }
		if (totalPorts === 4) { space = 0.27 + (0.17 * index); }
		if (totalPorts === 5) { space = 0.20 + (0.15 * index); }
		if (totalPorts === 6) { space = 0.20 + (0.13 * index); }

		return [isInput ? 0 : 1, space, isInput ? -1 : 1, 0];
	};

	return {
		outputs: onlyOuterOutputs.map((output, i) => {
			return  {
				name: output.name,
				type: output.type,
				position: getPositionFromIndex(onlyOuterOutputs.length, i, false),
				...(output.jsonShape ? { jsonShape: output.jsonShape } : undefined),
				...(output.label ? { label: output.label } : undefined),
			};
		}),

		inputs: onlyOuterInputs.map((input, i) => {
			return  {
				name: input.name,
				type: input.type,
				position: getPositionFromIndex(onlyOuterInputs.length, i, true),
				...(input.jsonShape ? { jsonShape: input.jsonShape } : undefined),
				...(input.label ? { label: input.label } : undefined),
			};
		})
	};
};

export default {
	getPortsInformation,
	Element: WidgetGroup,
	// CustomSettingsDialog: GateCustomSettings,
	getWrapperClass: () => styles.GateWrapper,
	hasTitle: false,
} as GateUI;
