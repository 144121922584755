import React, { useCallback, useEffect, useState } from 'react';
import Icon, {
  QrcodeOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { Tooltip } from 'antd';
import { ReactComponent as TutorialsIcon } from '../../components/layout/marketplace/MarketplaceMenu/tutorials.svg';
import OpenLinkedTutorialPopup from '../../components/openLinkedTutorialPopup/openLinkedTutorialPopup';
import { activeTutorialInfo, setActiveTutorialInfo } from '../interface/interfaceSlice';
import AccountMenu from '../../components/accountMenu/accountMenu';
import QRCodeModal from '../../components/qrCodeModal/qrCodeModal';
import { selectSignedUserProfile } from '../../app/reducers/user/userSlice';
import globals from '../../common/globals';
import useTranslation from '../../common/hooks/useTranslation';
import routes from '../../common/routes';
import { ReactComponent as AdminIcon } from './adminIcon.svg';
// import { ReactComponent as UpgradeIcon } from './upgradeIcon.svg';
import styles from './headerIcons.module.css';

const HeaderIcons = (): React.JSX.Element => {
	const history = useHistory();
	const dispatch = useDispatch();
	const userProfile = useSelector(selectSignedUserProfile);
	const [showQrModal, setShowQrModal] = useState(false);
	const activeTutorial = useSelector(activeTutorialInfo);
	const [showLinkedTutorialPopup, setShowLinkedTutorialPopup] = useState(true);
	const t = useTranslation('Interface.HeaderIcons');

	const handleShowQrCode = () => {
		setShowQrModal(true);
	};

	const handleModalClose = useCallback(() => {
		setShowQrModal(false);
	}, [setShowQrModal]);

	/** Hides the popup and marks it as shown */
	const hidePopup = useCallback(() => {
		setShowLinkedTutorialPopup(false);
		if (activeTutorial) {
			dispatch(setActiveTutorialInfo({
				...activeTutorial,
				notificationShown: true
			}));
		}
	}, [dispatch, activeTutorial]);


	// const onOpenDashboardUpgrade = () => {
	// 	window.location.href = `https://${globals.DASHBOARD_APP_DOMAIN}/dashboard/billing/upgrade`;
	// };

	const onOpenDashboard = () => {
		window.location.href = `https://${globals.DASHBOARD_APP_DOMAIN}/dashboard`;
	};

	const handleShowTutorial = useCallback(() => {
		hidePopup();
		activeTutorial && history.push(
			routes.tutorial.getTutorialRoute(activeTutorial.id, activeTutorial.step)
		);
	}, [hidePopup, activeTutorial, history]);

	useEffect(() => {
		if (activeTutorial) { setShowLinkedTutorialPopup(true); }
	}, [activeTutorial]);

	return (
		<>
			<QRCodeModal visible={showQrModal} onCancel={handleModalClose}/>
			<ul className={styles.NavBar}>

				{/* {(userProfile.profile && !userProfile.accountState?.hasKemuAccessLicense) && (
					<li>
						<span className={styles.NavBtn}>
							<StyledButton data-kemu-meta="upgrade-account-btn" onClick={onOpenDashboardUpgrade} className={styles.Rounded} title={
								<>
									<Icon component={UpgradeIcon} style={{ fontSize: 20 }} />
									{t('UpgradeAccount', 'Upgrade Account')}
								</>
							} color="secondary" />
						</span>
					</li>
				)} */}


				{(userProfile.accountState?.isAdmin || userProfile.accountState?.isOwner) && (
					<li className={styles.NavBtnContainer} data-kemu-meta="manage-organization">
						<Tooltip placement="bottomRight" destroyTooltipOnHide title={t('ManageOrganization', 'Manage This Organization')}>
							<span className={styles.NavBtn} onClick={onOpenDashboard}>
								<Icon component={AdminIcon} />
							</span>
						</Tooltip>
					</li>
				)}



				{activeTutorial && (
					<li className={styles.NavBtnContainer} data-kemu-meta="open-linked-tutorial">
						<Tooltip placement="bottomRight" destroyTooltipOnHide title={t('LinkedTutorialPopup', 'Open tutorial')}>
							<span className={styles.NavBtn} onClick={handleShowTutorial}>
								<Icon component={TutorialsIcon} />
							</span>
						</Tooltip>
					</li>
				)}


				<li className={styles.NavBtnContainer} data-kemu-meta="account-menu">
					<AccountMenu className={styles.NavBtn}/>
					{showLinkedTutorialPopup && activeTutorial && !activeTutorial.notificationShown && (
						<OpenLinkedTutorialPopup onDismiss={hidePopup} onShowTutorial={handleShowTutorial} />
					)}
				</li>

				<li className={styles.NavBtnContainer} data-kemu-meta="qrcode-btn">
					<Tooltip placement="bottomRight" destroyTooltipOnHide title={t('QRCode', 'Link Mobile Device')}>
						<span className={styles.NavBtn} onClick={handleShowQrCode}>
							<QrcodeOutlined />
						</span>
					</Tooltip>
				</li>
			</ul>
		</>
	);
};

export default HeaderIcons;

