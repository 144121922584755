import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { GateChild, RecipeWidget } from '@kemu-io/kemu-core/dist/types/gate_t';
import { BrowserJsPlumbInstance } from '@jsplumb/community';
import { removePrivateProperties } from '@kemu-io/kemu-core/dist/common/utils';
import { currentGates } from '@src/features/LogicMapper/logicMapperSlice';
import { KemuConnectionData, StatelessRecipeWidget, StatelessWidgetsMap, WithOptional } from '@src/types/core_t';
import Logger from '@common/logger';

const logger = Logger('useAlwaysLinked');

/**
 * A function that can be use to re-paint all the connections of the given widget.
 * The hook guarantees that re-painting will only happen if the number of ports have
 * changed, otherwise, invoking this method will do nothing.
 * 
 * @param forceRepaint set to true to force the method to repaint ports on the first render.
 * @param groupId if provided, only the widgets in the given group id will be linked.
 */
type UseAlwaysLinkResponse = (forceRepaint?: boolean, groupId?: string) => void;

/**
 * Encapsulates the logic for decoding child references and linking widgets
 */
export const childWidgetLink = (parentId: string, child: GateChild, plumbInstance: BrowserJsPlumbInstance): void => {
	const sourcePort = `${parentId}_${child.sourcePort}`;
	const targetPort = `${child.childId}_${child.targetPort}`;
	const conn = plumbInstance.connect({ uuids: [sourcePort, targetPort] });
	if (conn) {
		// Used to identify this connection when triggering animations
		const data: KemuConnectionData = {
			sourceWidgetId: parentId,
		};

		conn.setData(data);
	}
};

const ignoreCanvasUpdates = (widgetId: string, current: StatelessWidgetsMap, prev: StatelessWidgetsMap) => {
	const reduceFun = (instance: StatelessWidgetsMap, map: RecipeWidget, gateId: string) => {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { canvas, ...rest } = instance[gateId];
		// Remove any private property in the widget state that could slow things down significantly (when binary data is stored)
		const cleanedProps = removePrivateProperties<WithOptional<RecipeWidget, 'children'>>(rest);

		// Remove the children property from most widgets, except myself or any parent.
		// This is in order to avoid re-rendering all the widgets just because one of them changed its children.
		const widgetIsChild = cleanedProps?.children?.find(child => child.childId === widgetId);
		if (gateId !== widgetId && !widgetIsChild) {
			delete cleanedProps.children;
		}

		return { ...map, [gateId]: { ...cleanedProps } };
	};

	const prevFiltered = Object.keys(prev).reduce(reduceFun.bind(null, prev), {} as RecipeWidget);
	const currentFiltered = Object.keys(current).reduce(reduceFun.bind(null, current), {} as RecipeWidget);
	const areEqual = JSON.stringify(prevFiltered) === JSON.stringify(currentFiltered);
	return areEqual;
};

/** 
 * Re-builds connects to and from the given widget when the number of inputs
 * or outputs change.
 **/
const useAlwaysLinked = (widgetId: string, totalInputs: number, totalOutputs: number, plumbInstance: BrowserJsPlumbInstance): UseAlwaysLinkResponse => {
	const widgets = useSelector(currentGates, ignoreCanvasUpdates.bind(null, widgetId));
	const widgetsRef = useRef(widgets);
	const firstTimeRef = useRef(true);
	const canRePaint = useRef(false);
	const totalPorts = `${totalInputs}_${totalOutputs}`;

	/**
 	* Re-draws all connections for the given widget, including those of the parent.
	* Keep in mind this method will trigger `connection` events.
	* 
	* @param forceRepaint set to true to force the method to repaint ports on the first render.
	*/
	const redrawAllConnectionsForWidget = useCallback((forceRepaint=false, visibleGroup?: string) => {
		if (plumbInstance && (canRePaint.current || forceRepaint)) {
			logger.log(`Re-drawing connections for widget ${widgetId}`);
			const widgets = widgetsRef.current;
			canRePaint.current = forceRepaint ? true : false;
			plumbInstance.batch(() => {
				const drawWidgetConnections = (widget: StatelessRecipeWidget, targetId?: string) => {
					// Allow painting if:
					// - Not inside a group
					// - A group is visible and widget is inside
					// - The widget itself is the group
					if (!visibleGroup || widget?.groupId === visibleGroup || widget.id === visibleGroup) {
						widget.children.forEach(child => {
							if (!targetId || child.childId === targetId) {
								// Make sure the target child is also INSIDE the group (if a group is active)
								// or if the child itself is the group
								if (!widgets[child.childId]) {
									console.error('WARNING: A widget was saved with a child reference that does not exist!');
								}

								if (!visibleGroup || widgets[child.childId]?.groupId === visibleGroup || child.childId === visibleGroup ) {
									childWidgetLink(widget.id, child, plumbInstance );
								}
							}
						});
					}
				};

				// Find any widget that has the requested widget as a child
				const parents = Object.keys(widgets).filter(wId => widgets[wId].children.find(child => child.childId === widgetId));

				// Draw connections from parent to myself ONLY, otherwise other connections from the parents to 
				// other children would get drawn twice
				parents.forEach(parentId => drawWidgetConnections(widgets[parentId], widgetId));
				// Draw connections from myself to any child
				drawWidgetConnections(widgets[widgetId]);
			});
		} else {
			logger.log(`No repaint required, port count has not changed for widget ${widgetId}`);
		}
	}, [plumbInstance, widgetId/* , visibleGroup */]);


	// Enables re-painting only when the port count has changed
	useEffect(() => {
		// the first mount should not link anything
		if (!firstTimeRef.current) {
			canRePaint.current = true;
		} else {
			logger.log(`Ignore redraw all connections because this is the first time for ${widgetId}`);
		}

		firstTimeRef.current = false;
	}, [totalPorts, widgetId]);


	// Keep track of changes to the list of widgets.
	// We use a reference to make sure the returned function `redrawAllConnectionsForWidget` does not
	// change between renders.
	useEffect(() => {
		widgetsRef.current = widgets;
	}, [widgets]);

	return redrawAllConnectionsForWidget;
};

export default useAlwaysLinked;
