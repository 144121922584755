
import React, { memo, useCallback } from 'react';
import Icon from '@ant-design/icons';
import PixelfyWidgetProcessor, { PixelfyGateState, getDefaultState, ColorPalette } from '@kemu-io/kemu-core/dist/gates/pixelfy';
import { useIntl } from 'react-intl';
import { InputNumber } from 'antd';
import classNames from 'classnames';
import { CustomGateState, WidgetPortContext } from '@kemu-io/kemu-core/dist/types/gate_t';
import useReactiveWidgetState from '../../../common/hooks/useReactiveWidgetState';
import { GateCustomSettingsProps, GetPortsInformationFunction, GateUI, GateUIProps } from '..';
import GateIcon from '../../gateIcon/gateIcon';
import StyledButton from '../../form-control/styledButton/styledButton';
import { SETTINGS_CONTAINER_CLASS } from '../../../common/constants';
import { ReactComponent as PixelfyGateIcon } from './pixelfy.svg';
import styles from './pixelfy.module.css';

type Props = GateUIProps

/**
 * Produces a palette of random colors and a random number of colors
 */
const generatePalette = (): ColorPalette => {
	const randomBetween = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1) + min);
	const totalColors = randomBetween(4, 16);

	const palette: ColorPalette = [];
	const getColor = () => randomBetween(1, 255);

	for (let i = 0; i < totalColors; i++) {
		const color: [number, number, number] = [
			getColor(),
			getColor(),
			getColor(),
		];

		palette.push(color);
	}

	return palette;
};



const ignorePrivateState = (prevState: CustomGateState<PixelfyGateState>, newState: CustomGateState<PixelfyGateState>): boolean => {
	return prevState.scale !== newState.scale
		|| prevState.palette !== newState.palette;
};


const PixelfyGate = (props: Props): React.JSX.Element => {
	const [state, setState] = useReactiveWidgetState<PixelfyGateState>(
		props.recipeId,
		props.thingRecipeId,
		props.info.id,
		ignorePrivateState
	);

	const fixedState: PixelfyGateState = {
		...getDefaultState(),
		...state
	};

	const min = 1;
	const max = 50;

	// Handles keyboard entries
	const handleChange = useCallback((value: number | string | undefined | null) => {
		if (value && !isNaN(Number(value))) {
			if (value >= min && value <= max) {
				setState(st => ({
					...st,
					scale: Number(value)
				}), true);
			}
		}
	}, [setState]);

	const handleInputChange = useCallback((value: number) => {
		setState(st => ({
			...st,
			scale: Number(value)
		}), true);
	}, [setState]);

	return (
		<div className={`${styles.GateBody}`}>
			{/* VERY important to add 'no-child-drag' or up/down buttons will stop working */}
			<div className="gate-input no-child-drag">
				<InputNumber
					bordered={false}
					className={styles.Input}
					min={min}
					max={max}
					defaultValue={7}
					value={fixedState.scale}
					onStep={handleInputChange}
					onChange={handleChange}
				/>
			</div>
		</div>
	);
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={PixelfyGateIcon} />}/>
	);
};

const GateCustomSettings = (props: GateCustomSettingsProps): React.JSX.Element => {
	const [state, setState] = useReactiveWidgetState<PixelfyGateState>(props.recipeId, props.blockId, props.gateInfo.id);
	const fixedState: PixelfyGateState = {
		...getDefaultState(),
		...state
	};

	const intl = useIntl();
	const newPaletteBtn = intl.formatMessage({ id: 'LogicMaker.Gates.Pixelfy.NewPaletteButton', defaultMessage: 'New Palette' });
	const resetPaletteBtn = intl.formatMessage({ id: 'LogicMaker.Gates.Pixelfy.ResetPaletteButton', defaultMessage: 'Reset' });

	const resetPalette =() => {
		setState({ ...fixedState, palette: getDefaultState().palette });
	};

	const setPalette = () => {
		setState({ ...fixedState, palette: generatePalette() });
	};

	return (
		<div className={classNames(styles.SettingsContainer, SETTINGS_CONTAINER_CLASS)}>
			{props.children}
			<div className={styles.Buttons}>
				<StyledButton className={styles.Button} onClick={setPalette} title={newPaletteBtn} />
				<StyledButton className={styles.Button} color="secondary" onClick={resetPalette} title={resetPaletteBtn} />
			</div>
		</div>
	);
};


const getPortsInformation: GetPortsInformationFunction = (state, widgetInfo) => {
	const portContext: WidgetPortContext = { recipePoolId: widgetInfo.recipePoolId, recipeType: widgetInfo.recipeType };
	const outputNames = PixelfyWidgetProcessor.getOutputNames(state, portContext);
	const inputNames = PixelfyWidgetProcessor.getInputNames(state, portContext);

	return {
		inputs: [{
			name: inputNames[0].name,
			type: inputNames[0].type,
			position: 'Left'
		}],

		outputs: [{
			name: outputNames[0].name,
			type: outputNames[0].type,
			position: 'Right'
		}]
	};
};

export default {
	getPortsInformation,
	BarIcon: GateBarIcon,
	// memo is necessary to prevent re-rendering the canvas instance since the widget processor
	// changes the 'processing' flag multiple times per cycle.
	Element: memo(PixelfyGate),
	CustomSettingsDialog: GateCustomSettings,
	hasTitle: true,
	getWidgetTitle: (intl) => intl.formatMessage({ defaultMessage: 'Pixelfy', id: 'LogicMaker.Gates.Pixelfy.Title' }),
} as GateUI;
