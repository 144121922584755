/*
 * Written by Alexander Agudelo < alex.agudelo@kitsunei.com >, 2021
 * Date: 12/Jan/2021
 * Last Modified: 09/09/2023, 1:32:19 pm
 * Modified By: Alexander Agudelo
 * Description:  Encapsulates Twilio's video WebRTC
 * 
 * ------
 * Copyright (C) 2021 Kitsunei - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential.
 */

import { useCallback, useEffect, useRef, useState } from 'react';
import Twilio from 'twilio-video';
import { jsonParse } from '@kemu-io/kemu-core/dist/common/utils';
import Logger from '../logger';
import { MobilePhoneEvent } from '../../types/mobile';
import { VideoTrackInfo, ConnectionStatus } from '../../types/twilioRTC';
const logger = Logger('useTwilioWebRTC');


type ParticipantInfo = {
	name: string;
}

type TrackChangedType = {trackType?: Twilio.Track.Kind};

interface TwilioHookInstance {
	connect: (token: string) => void;
	videoTrack: null | VideoTrackInfo;
	audioTrack: null | HTMLMediaElement;
	/** Allow parents to define an event listener to receive data events */
	onPhoneEvent?: (event: MobilePhoneEvent) => void;
	/** sends data to a remote client */
	sendString: (data: string) => void;
	errorMsg: string | null;
}


const useTwilioWebRTC = (): [TwilioHookInstance, ConnectionStatus, ParticipantInfo | null, TrackChangedType] => {
	const [status, setStatus] = useState<ConnectionStatus>('disconnected');
	const statusRef = useRef<ConnectionStatus>('disconnected');
	const [errorMsg, setErrMsg] = useState<string | null>(null);
	const [currentParticipant, setCurrentParticipant] = useState<ParticipantInfo | null>(null);
	const [videoTrack, setVideoTrack] = useState<VideoTrackInfo | null>(null);
	const [audioTrack, setAudioTrack] = useState<HTMLMediaElement | null>(null);
	const [signalTrackUpdated, setSignalTrackUpdated] = useState<TrackChangedType>({});
	const localDataTrack = useRef<Twilio.LocalDataTrack | null>(null);
	const currentRoom = useRef<Twilio.Room | null>(null);

	const sendString = useCallback((data: string) => {
		if (localDataTrack.current && statusRef.current === 'connected') {
			localDataTrack.current.send(data);
		}
	}, []);

	const recordAudioTrack = (track: Twilio.RemoteAudioTrack | null) => {
		if (track && track.isEnabled) {
			logger.log('Attaching audio track');
			// WARNING: This enables the audio straightaway!
			// even if the element is NOT mounted
			setAudioTrack(track.attach());
		} else {
			setAudioTrack(currentTrack => {
				currentTrack && currentTrack.remove();
				return null;
			});
		}
	};

	// Intercepts data events and determines if the event is internal (related to the stream or audio tracks)
	// if so, it consumes it, otherwise it propagates the event to the parent
	const preProcessDataEvent = useCallback((data: unknown) => {
		// Data SHOULD be a string
		if (typeof data !== 'string') { return; }
		const event = jsonParse(data as string) as MobilePhoneEvent | null;
		if (event) {
			if (event.action === 'PhoneOrientationChanged') {
				setVideoTrack(track => {
					if (track) {
						// update the dimensions reference based on the original resolution, using
						// the orientation to determine the real width and height
						const maxValue = Math.max((track.dimensions.width || 1), (track.dimensions.height || 1));
						const minValue = Math.min((track.dimensions.width || 1), (track.dimensions.height || 1));
						const newDimensions = {
							width: event.value === 'landscape' ? maxValue : minValue,
							height: event.value === 'landscape' ? minValue : maxValue,
						};

						return {
							...track,
							dimensions: newDimensions
						};
					}

					return null;
				});
			}

			hookRef.current.onPhoneEvent && hookRef.current.onPhoneEvent(event);
		}
	}, []);


	const recordVideoTrack = (track: Twilio.RemoteVideoTrack | null) => {
		if (track && track.isEnabled) {
			const videoEl = track.attach() as HTMLVideoElement;

			if (track.dimensions.width && track.dimensions.height) {
				videoEl.width = track.dimensions.width;
				videoEl.height = track.dimensions.height;
			}

			setVideoTrack({
				stream: videoEl,
				dimensions: track.dimensions
			});
		} else {
			setVideoTrack(currentTrack => {
				currentTrack && currentTrack.stream.remove();
				return null;
			});
		}
	};

	const handleParticipantTrack = useCallback((track: Twilio.RemoteTrack) => {
		if (track.kind === 'video') {
			// recordVideoTrack(track);

			track.on('subscriptionFailed', (error: Twilio.TwilioError) => {
				console.log('Subscription failed!! ', error);
			});

			track.on('unsubscribed', () => {
				console.log('Track unsubscribed: ', track);
			});
			track.on('enabled', () => {
				logger.log(`Track has been enabled: ${track}`);
				// Takes care of cases when the track had already been enabled
				if (track.isStarted) {
					logger.log('Track already started!');
					recordVideoTrack(track);
				}

				track.once('disabled', () => {
					logger.log(`Track has been disabled: ${track}`);
					recordVideoTrack(null);
				});
			});

			// track.on('switchedOn', () => {
			// 	logger.log('Track has been switchedOn: ', track);
			// 	// recordVideoTrack(track);
			// });

			// Wait for the video to start before marking it as available, otherwise
			// we don't have access to its dimensions
			track.on('started', () => {
				logger.log(`Track started, dimensions are: ${track.dimensions}`);
				recordVideoTrack(track);
			});

		} else if (track.kind === 'audio') {
			track.on('enabled', () => {
				logger.log(`Audio has been enabled: ${track.isStarted}`);
				recordAudioTrack(track);

				track.once('disabled', () => {
					logger.log(`Track has been disabled: ${track}`);
					recordAudioTrack(null);
				});
			});
		} else if (track.kind === 'data') {
			console.log('Data track detected: ', track);
			track.on('message', data => {
				console.log('Data Event: ', data);
				preProcessDataEvent(data);
			});
		}
	}, [preProcessDataEvent]);


	const processRoomParticipant = useCallback((participant: Twilio.RemoteParticipant) => {

		setCurrentParticipant({
			name: participant.identity
		});

		// Handle tracks pre-published by the participant
		// NOTE: commenting this out because there should not be other participants
		// in the room when the application joins
		// participant.tracks.forEach(publication => {
		// 	if (publication.isSubscribed) {
		// 		const track = publication.track;
		// 		if(track){
		// 			handleParticipantTrack(track);
		// 		}
		// 	}
		// });


		// Handle tracks published later on. Triggered when this client has subscribed
		// to the remote participant's track
		participant.on('trackSubscribed', (track: Twilio.RemoteTrack) => {
			logger.log(`Track subscribed!: ${track}`);
			handleParticipantTrack(track);
		});

	}, [handleParticipantTrack]);


	const connect = useCallback(async (token: string): Promise<void> => {
		if (currentRoom.current) {
			currentRoom.current.disconnect();
			currentRoom.current = null;
			setStatus('disconnected');
		}

		setStatus('connecting');
		localDataTrack.current = new Twilio.LocalDataTrack();

		// eslint-disable-next-line import/no-named-as-default-member
		Twilio.connect(token, {
			video: false,
			audio: false,
			tracks: [localDataTrack.current]
		}).then(room => {
			setStatus('connected');
			currentRoom.current = room;

			// Handle participants already present in the room
			currentRoom.current.participants.forEach(participant => {
				logger.log(`Existing participant: ${participant.identity}`);
				processRoomParticipant(participant);
			});

			// Wait for a participant to connect
			currentRoom.current.on('participantConnected', (participant: Twilio.RemoteParticipant) => {
				logger.log(`New participant: ${participant.identity}`);
				processRoomParticipant(participant);
			});


			currentRoom.current.on('participantDisconnected', (participant: Twilio.RemoteParticipant) => {
				logger.log(`Participant disconnected: ${participant.identity}`);
				// Cancel all track listeners
				participant.tracks.forEach(track => {
					if (track.isSubscribed) {
						track.removeAllListeners();
					}
				});

				// Clear tracks
				recordAudioTrack(null);
				recordVideoTrack(null);
			});

			currentRoom.current.on('trackSubscriptionFailed', function (error/* , publication, participant */) {
				console.log('Subscription failed!!!!!: ', error);
			});

		}).catch(error => {
			console.error('Error connecting to room: ', error);
			setErrMsg(error.message || 'Unknown error connecting to room');
			setStatus('error');
		});
	}, [processRoomParticipant, setErrMsg]);

	const hookRef = useRef<TwilioHookInstance>({
		connect,
		errorMsg,
		sendString,
		videoTrack: videoTrack,
		audioTrack: audioTrack,
	});

	useEffect(() => {
		// TERRIBLE hack! TODO: Find a better way!
		// I want status changes to be propagated to my parent. Using statusRef as the
		// 2nd argument of my return array won't work because refs won't force updates.
		// Using 'status' works but then, the `sendString` reference in the hookRef will be
		// pointing to an old function that when called, will show 'status' as 'disconnected'.
		// This hacky workaround will keep my localRef up to date with the actual status.
		statusRef.current = status;
	}, [status]);

	useEffect(() => {
		if (hookRef.current) {
			logger.log(`video track reference change detected: ${videoTrack}`);
			hookRef.current.videoTrack = videoTrack;
			setSignalTrackUpdated({ trackType: 'video' });
		}
	}, [videoTrack]);


	useEffect(() => {
		if (hookRef.current) {
			logger.log('audio track reference change detected: ');
			hookRef.current.audioTrack = audioTrack;
			setSignalTrackUpdated({ trackType: 'audio' });
		}
	}, [audioTrack]);

	return [hookRef.current, status, currentParticipant, signalTrackUpdated];
};

export default useTwilioWebRTC;
