import React from 'react';
import { Upload } from 'antd';
import { UploadRequestOption } from 'rc-upload/lib/interface';

type Props = {
  onFileAdded: (data: Uint8Array) => void;
  className?: string;
  noFileElement: React.ReactNode;
  yesFileElement: React.ReactNode;
  accept: string;
  fileLoaded: boolean;
}

const FileDropZone = (props: Props) => {
  const { onFileAdded, yesFileElement, accept, fileLoaded } = props;

  const handleCustomRequest = async (options: UploadRequestOption) => {
    const binaryData = await (options.file as Blob).arrayBuffer();
    const uint8 = new Uint8Array(binaryData);
    onFileAdded(uint8);
  };

  return (
    <Upload
      listType="text"
      className={props.className}
      accept={accept}
      showUploadList={false}
      customRequest={handleCustomRequest}
    >
      {fileLoaded ? (
        <>
          {props.yesFileElement}
        </>
      ) : (
        <>
          {props.noFileElement}
        </>
      )}
    </Upload>
  );
};

export default FileDropZone;
