import React from 'react';
import { Select } from 'antd';
import { SelectProps, SelectValue } from 'antd/lib/select';
import StyledLabel from '../styledLabel/styledLabel';
import styles from './styledSelect.module.css';

type Props = SelectProps<SelectValue> & {
	children?: React.ReactNode;
	label?: React.ReactNode;
	errorMessage?: React.ReactNode;
}

const { Option } = Select;
export { Option };

/**
 * Usage:
 * ```js
 *	import StyledSelect, {Option} from './styledSelect';
 * 
 *
 *	<StyledSelect label="My label" onChange={onOptionSelected} value="Default Value" errorMessage="Something went wrong">
 *		<Option value="Member">Member</Option>
 *		<Option value="Admin">Admin</Option>
 *	</StyledSelect>
 * ```
 */
const StyledSelect = (props: Props): React.JSX.Element => {
	const { errorMessage, ...attributes } = props;
	return (
		<>
			{props.label && (
				<StyledLabel text={props.label} />
			)}

			<Select {...attributes} className={`${styles.Select} ${errorMessage ? styles.InvalidInput : ''} ${props.className ? props.className : ''}`} >
				{props.children}
			</Select>

			{ errorMessage &&  <p className={styles.ErrorMessage}>{errorMessage}</p>}
		</>
	);
};

export default StyledSelect;
