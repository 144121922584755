import {
	GetSignedCookiesResponse,
	GetTutorialResponse,
	GetUserTutorialsResponse,
	LimitedTutorialInfo,
	Tutorial,
	TutorialCreateRequestBody,
	TutorialDbEntity
} from '@kemu-io/kemu-types/dist/types';
import { API } from '@aws-amplify/api';
import axios from 'axios';
import Cookies from 'js-cookie';
import { TransferProgressEvent } from '@kemu-io/kemu-core/dist/types/common_t';
import { TutorialState } from '../../types/tutorial';
import globals from '../../common/globals';
import { getLatestTutorialVersion } from '../../common/constants';

// Check the endpoint url in App.tsx
const API_NAME = globals.TUTORIAL_API_NAME;

// Forces to return the entire Axios response object instead of only response.data
const returnAxiosResponse = { response: true };

export interface SaveTutorialResponse {
	uploadUrl?: string;
	tutorial: TutorialDbEntity;
}

export interface UpdateTutorialResponse {
	uploadUrl?: string;
	tutorial: TutorialDbEntity;
}

export interface UploadFileResponse {
	uploadUrl: string;
	/** 
	 * A pre-signed URL with read access to the file. 
	 * Keep in mind the client is responsible for uploading the actual file contents BEFORE
	 * using this url.
	 */
	viewUrl: string;
	fileId: string;
}

export interface TutorialEntityWithContent extends LimitedTutorialInfo {
	/** The actual content downloaded from storage (S3) */
	contents: TutorialState;
}

interface GetReadUrlsResponse {
	[fileId: string]: string;
}

/**
 * Gets the tutorial data from the database
 * @param id the id of the tutorial in the database
 */
const getTutorialContentsById = async (id: string): Promise<TutorialEntityWithContent> => {
	const response = await API.get(API_NAME, `/tutorial/${id}`, returnAxiosResponse);
	const tutorialInfo = response.data as GetTutorialResponse;

	if (!tutorialInfo.tutorial.downloadUrl) { throw new Error(`Invalid service response: ${JSON.stringify(response.data)}`); }

	// Write cookie so we can access the contents
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { expireTimeUtc, authorizedPath, ...cookies } = tutorialInfo.cookies;

	// Set the cookies so we can access the tutorial contents
	for (const cookieName in cookies) {
		const propName = cookieName as keyof Omit<GetSignedCookiesResponse, 'expireTimeUtc' | 'authorizedPath'>;

		Cookies.set(cookieName, cookies[propName], {
			expires: 1,
			secure: true,
			// Here, we make sure we set the path to match the tutorial root, otherwise the cookie will
			// replace a previously accessed tutorial. Without this, users wouldn't be able 
			// to open multiple tutorials in different tabs.
			path: authorizedPath,
			domain: 'kemu.io'
		});
	}

	// Now get the actual contents
	const contents = await axios.get<TutorialState>(tutorialInfo.tutorial.downloadUrl, {
		// Fundamental as we need the cloudfront cookies to be included.
		withCredentials: true,
		headers: {
			'content-type': 'application/json;charset=UTF-8',
		}
	});

	return {
		...tutorialInfo.tutorial,
		contents: contents.data,
	};
};

/**
 * Store a new tutorial in the DB. It takes care of requesting a new id
 * and uploading the contents automatically.
 * @param tutorial the tutorial object to upload.
 * @param content the stringified version of the content in ArrayBuffer format.
 * NOTE: 
 * @returns the newly created tutorial, including the service given ID.
 */
const saveTutorial = async (
	tutorial: TutorialState,
	content: ArrayBuffer | string,
	onProgress?: (evt: TransferProgressEvent)=> void
): Promise<TutorialDbEntity> => {

	// Strip out the actual content from each page, to keep the DB small
	const contentLessTutorial: TutorialCreateRequestBody = {
		...tutorial,
		pages: tutorial.pages.map(page => {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const { content, ...cleanPage } = page;
			return cleanPage;
		})
	};

	// v1 ==> Absolute assets paths - assets such as images used to include the full S3 public url.
	// v2 ==> Relative assets paths - paths of images and other resources are now relative to the S3 bucket, viewers must
	// prepend the correct domain.
	const protocolVersion = getLatestTutorialVersion();
	const response = await API.post(API_NAME, `/tutorial/${protocolVersion}`, {
		...returnAxiosResponse,
		body: contentLessTutorial
	});

	const info = response.data as SaveTutorialResponse;
	if (!info.uploadUrl) { throw new Error(`Invalid service response: ${JSON.stringify(info)}`); }

	await uploadFileContents(info.uploadUrl, content, 'application/octet-stream', onProgress);
	return info.tutorial;
};

/**
 * Updates the contents of an existing tutorial in the DB as well as the content files in S3
 * @param id the id of the tutorial previously saved in the DB
 * @param tutorial the tutorial object to upload.
 * @param content the stringified version of the content in ArrayBuffer format.
 * NOTE: 
 * @returns the updated tutorial, including the service given ID.
 */
const updateTutorial = async (
	id: string,
	tutorial: TutorialState,
	content: ArrayBuffer | string,
	onProgress?: (evt: TransferProgressEvent) => void
): Promise<TutorialDbEntity> => {

	// Strip out the actual content from each page, to keep the DB small
	const contentLessTutorial: TutorialCreateRequestBody = {
		...tutorial,
		protocolVersion: getLatestTutorialVersion(),
		pages: tutorial.pages.map(page => {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const { content, ...cleanPage } = page;
			return cleanPage;
		})
	};

	const response = await API.put(API_NAME, `/tutorial/${id}`, { ...returnAxiosResponse, body: contentLessTutorial });
	const info = response.data as UpdateTutorialResponse;
	if (!info.uploadUrl) { throw new Error(`Invalid service response: ${JSON.stringify(info)}`); }

	await uploadFileContents(info.uploadUrl, content, 'application/octet-stream', onProgress);
	return info.tutorial;
};

/**
 * Uploads the file to permanent storage. 
 * @param tutorialId the id of the tutorial the file will be stored under.
 * @param contents the file contents in base64. This will be transformed into binary before uploading it.
 * @param contentType the MIME type.
 */
const uploadTutorialFile = async (
	tutorialId: string,
	contents: ArrayBuffer,
	contentType: string,
	onProgress?: (evt: TransferProgressEvent) => void
): Promise<{id: string, url: string}> => {
	// First, we request a pre-signed url to upload the file
	const response = await API.post(
		API_NAME,
		`/tutorial/${tutorialId}/upload`,
		{
			...returnAxiosResponse,
			body: { contentType }
		}
	);

	const uploadDetails = response.data as UploadFileResponse;
	if (!uploadDetails.uploadUrl || !uploadDetails.fileId || !uploadDetails.viewUrl) { throw new Error(`Invalid service response: ${JSON.stringify(uploadDetails)}`); }

	// Now, we upload the actual contents
	await uploadFileContents(uploadDetails.uploadUrl, contents, contentType, onProgress);

	return {
		id: uploadDetails.fileId,
		url: uploadDetails.viewUrl
	};
};

/**
 * Gets a list of cookies that are needed to access tutorial assets.
 * By default, the cookie has an expiration time of 6h
 * @param tutorialId 
 */
// const getSignedCookies = async (tutorialId: string): Promise<GetSignedCookiesResponse> => {
// 	// https://tutorial-api.dev.kemu.io/tutorial/XXXXXX/cookie
// 	const response = await API.get(TUTORIAL_STATIC_NAME, `/tutorial/${tutorialId}/cookie`, returnAxiosResponse);
// 	const cookies = response.data as GetSignedCookiesResponse;
// 	return cookies;
// };

/**
 * Returns a list of pre-signed urls with view (read) permissions.
 */
const getTutorialFilesUrls = async (tutorialId: string, files: string[]): Promise<GetReadUrlsResponse> => {

	const response = await API.post(
		API_NAME,
		`/tutorial/${tutorialId}/contents/view`,
		{ ...returnAxiosResponse, body: { files } }
	);

	const urls = response.dat as GetReadUrlsResponse;
	return urls;
};

/**
 * Uploads a file to S3 using the given pre-signed url
 * @param presignedUrl a pre-signed URL for the upload
 * @param onProgressCb a method to call with the progress events of the upload
 */
const uploadFileContents = async (
	presignedUrl: string,
	contents: ArrayBuffer | string,
	contentType='application/octet-stream',
	onProgressCb?: (evt: TransferProgressEvent) => void
): Promise<void> => {
	const results = await axios.put(`${presignedUrl}`, contents, {
		// NOTE: this MUST match the content type set by the service when creating the pre-signed url
		headers: { 'Content-Type': contentType },
		onUploadProgress: (evt) => {
			if (onProgressCb) {
				const total = evt.total || 1;
				const percentage = Math.round((evt.loaded / total) * 100);
				onProgressCb({
					loaded: evt.loaded,
					percentage,
					total,
				});
			}
		}
	});

	return results.data;
};

/**
 * Returns a list of tutorials from the current user
 */
const getAllTutorials = async (): Promise<GetUserTutorialsResponse> => {
	const tutorials = await API.get(API_NAME, `/tutorials`, returnAxiosResponse);
	return tutorials.data;
};


const deleteTutorial = async (tutorialId: string): Promise<void> => {
	await API.del(API_NAME, `/tutorial/${tutorialId}`, returnAxiosResponse);
};

type UpdatableDetails = Omit<Tutorial, 'author' | 'sharedWith'>;
const patchTutorial = async (tutorialId: string, details: Partial<UpdatableDetails>): Promise<TutorialDbEntity> => {
	const response = await API.patch(API_NAME, `/tutorial/${tutorialId}`, { ...returnAxiosResponse, body: details });
	return response.data as TutorialDbEntity;
};

export {
	getTutorialContentsById,
	saveTutorial,
	updateTutorial,
	getTutorialFilesUrls,
	uploadTutorialFile,
	// getSignedUrls,
	getAllTutorials,
	deleteTutorial,
	patchTutorial,
	// getSignedCookies
};
