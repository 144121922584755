import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { useHistory, useParams } from 'react-router-dom';
import { Tag, Rate, Carousel, Result } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { MarketplaceAsset, PublicationType } from '@kemu-io/kemu-types/dist/types';
import { capitalize } from '@material-ui/core';
import useAlert from '../../alert/useAlert';
import { selectSignedUserProfile } from '../../../app/reducers/user/userSlice';
import { publicationDetails } from '../../layout/marketplace/marketplaceSlice';
import { fetchPublicationDetails } from '../../layout/marketplace/reducers/getPublicationDetails';
import { AsyncRequestStatus } from '../../../types/core_t';
import useMountEffect from '../../../common/hooks/useMountEffect';
import useTranslation from '../../../common/hooks/useTranslation';
import useMarkedText from '../../../common/hooks/useMarkedText';
import StyledButton from '../../form-control/styledButton/styledButton';
import * as MarketplaceAPI from '../../../api/marketplace/marketplaceApi';
import routes from '../../../common/routes';
import { selectThingInstallationStatus } from '../../../app/reducers/thing/thingSlice';
import { uninstallThingAction } from '../../../app/reducers/thing/uninstallThingReducer';
import { installThingAction } from '../../../app/reducers/thing/installThingReducer';
import { SearchContext } from '../../../common/context/GlobalSearchContext/GlobalSearchContext';
import PublicationDetailSkeleton from './PublicationDetailsSkeleton';
import styles from './PublicationDetails.module.css';

const PublicationDetailsView = (): React.JSX.Element => {
	const history = useHistory();
	const dispatch = useDispatch();
	const convertToHtml = useMarkedText();
	const currentUser = useSelector(selectSignedUserProfile);
	const { clearSearchContext } = useContext(SearchContext);
	const [importingRecipe, setImportingRecipe] = useState(false);
	const thingInstallationStatus = useSelector(selectThingInstallationStatus);
	const actionRef = useRef<'installing' | 'uninstalling' | 'importing' | null>(null);
	const [rating, setRating] = useState(false);
	const publication = useSelector(publicationDetails);
	const t = useTranslation();
	const Alert = useAlert();
	const thingInstallationError = thingInstallationStatus.error;
	const { id: publicationId, entityType } = useParams<{id: string, entityType: PublicationType}>();
	const previewAssets: MarketplaceAsset[] = (publication?.details?.assets || []).filter(asset => asset.section === 'asset');
	const heroImageUrl = (publication?.details?.assets || []).find(asset => asset.section === 'hero')?.url || '';
	const thumbnailImageUrl = (publication?.details?.assets || []).find(asset => asset.section === 'thumbnail')?.url || '';
	const canAddToLibrary = currentUser.profile && currentUser.profile.id !== publication?.details?.author.id;
	const thingAlreadyInstalled = !!currentUser.profile?.installedThings.find(thing => thing.id === publication?.details?.entityId);

	const onRateRecipe = async (value: number) => {
		if (publication.details && !rating) {
			setRating(true);
			await MarketplaceAPI.ratePublication(publication.details?.id, publication.details?.version, value);
			setRating(false);
		}
	};

	const getMarkedDescription = () => {
		return convertToHtml(publication.details?.description || '');
	};

	/**
	 * @param action a verb in gerund (Eg: installing, importing)
	 */
	const showAlertMessage = useCallback((action: string, errorMessage: string) => {
		if (publication.details?.type) {
			const entityType = t(`Entity.${capitalize(publication.details.type)}`, publication.details.type);

			Alert.error({
				okText: t('Alert.Default.CloseButton', 'Close'),
				title: t('Marketplace.Publication.Import.Error.Title', 'Error {action} {entity}', {
					action,
					entity: capitalize(entityType),
				}),

				content: t(
					'Marketplace.Publication.Import.Error.Content',
					'There was an error {action} this {entity}. Please try again later.{breakingLine}{breakingLine}{errorMessage}',
					{
						entity: entityType,
						action,
						breakingLine: <br/>,
						errorMessage,
					}
				)
			});

		}
	}, [Alert, publication.details?.type, t]);

	const handleThingUninstall = useCallback(async () => {
		if (publication.details?.entityId) {
			actionRef.current = 'uninstalling';
			dispatch(uninstallThingAction(publication.details.entityId));
			// try {
			// }catch(e){
			// 	console.log('Error installing thing: ', e);
			// 	showAlertMessage(t('Verbs.Uninstalling', 'uninstalling'), e.message || 'Unknown error');
			// }
		}
	}, [publication.details?.entityId, dispatch]);

	const showUninstallConfirmation = useCallback(async () => {
		const thingName = publication.details?.title;
		Alert.confirm({
			cancelText: t('Alert.CancelBtn', 'Cancel'),
			okText: t('Marketplace.Publication.Uninstall.Btn', 'Uninstall'),
			okButtonColor: 'danger',
			title: t('Marketplace.Publication.Uninstall.Title', 'Uninstall {name}?', { name: thingName }),
			content: t('Marketplace.Publication.Uninstall.Content', 'Are you sure you want to uninstall {name}?', { name: thingName }),
			onOk: handleThingUninstall
		});
	}, [Alert, publication.details?.title, t, handleThingUninstall]);

	const handleInstallThing = useCallback(async () => {
		if (publication.details?.entityId) {
			actionRef.current = 'installing';
			dispatch(installThingAction(publication.details.entityId));
			// try {
			// 	// // setInstallingThing(true);
			// 	// // Add to user profile
			// 	// const response = await userApi.installThing(publication.details.entityId);
			// 	// // Update local user profile
			// 	// dispatch(setUserInstalledThings(response.user.installedThings));
			// 	// // Download new thing
			// 	// dispatch(downloadSingleThingAction(response.installedThing));
			// }catch(e){
			// 	console.log('Error installing thing: ', e);
			// 	showAlertMessage(t('Verbs.Installing', 'installing'), e.message || 'Unknown error');
			// }
		}
	}, [publication.details?.entityId, dispatch]);

	const handleImport = async () => {
		try {
			// This in case a 'curious' user decide to enable the button from the dev console.
			if (!canAddToLibrary) { return; }
			actionRef.current = 'importing';
			setImportingRecipe(true);
			const importDetails = await MarketplaceAPI.addToUserLibrary(publicationId);
			setImportingRecipe(false);
			await clearSearchContext();
			history.push(routes.marketplace.getMyRecipesRoute(importDetails.entityId));
		} catch (e) {
			setImportingRecipe(false);
			console.log('Error getting the recipe details: ', e);
			showAlertMessage(t('Verbs.Importing', 'importing'), e.message || 'Unknown error');
		}
	};

	const getDetails = useCallback(() => {
		dispatch(fetchPublicationDetails({
			entityType,
			publicationId,
		}));
	}, [dispatch, entityType, publicationId]);


	const onTryAgain = () => {
		getDetails();
	};

	useMountEffect(() => {
		getDetails();
	});

	useEffect(() => {
		if (thingInstallationError && actionRef.current) {
			console.log(`Error ${actionRef.current}: ${thingInstallationError}`);
			showAlertMessage(
				t('Verbs.Importing', actionRef.current),
				thingInstallationError?.message || 'Unknown error'
			);
		}
	}, [thingInstallationError, showAlertMessage, t]);


	if (publication.asyncState.error) { return (
		<Result
			status="warning"
			title={<>
				<h4><FormattedMessage id="Marketplace.NetworkError.Title" defaultMessage="We encountered an error while sending the request to server" /></h4>
				<small>{publication.asyncState.error.message}</small>
			</>
			}
			extra={
				<StyledButton onClick={onTryAgain} title={<FormattedMessage id="Marketplace.NetworkError.TryAgainButton" defaultMessage="Try Again" />} />
			}
		/>
	); }

	if (publication.asyncState.status === AsyncRequestStatus.loading || !publication.details) {
		return <PublicationDetailSkeleton />;
	}

	return (
		<div className={styles.Container}>
			<div className={styles.OuterContainer}>
				<div className={styles.TopContainer} style={{ backgroundImage: `url('${heroImageUrl}')` }}>
					<div className={`${styles.InnerTopContainer} ${styles.FlexRow}`}>
						<div
							className={styles.ThumbnailContainer}
							style={{ backgroundImage: `url('${thumbnailImageUrl}')` }}
						/>

						<div className= "main-info flex-column">
							<h2 className="main-title">{publication?.details.title}</h2>
							<div className="sub-title">{publication?.details.subtitle}</div>
							<div className={`${styles.SubInfo} ${styles.FlexRow}`}>
								<div className={styles.Mr30}>
									<div className="creator">
										<div className={`mr-10 ${styles.Fw700}`}>{t('Marketplace.PublicRecipe.Details.CreatedBy', 'Created by')}</div>
										<div>{publication?.details.author.name}</div>
									</div>
									<div className="date">
										<div className={`mr-10 ${styles.Fw700}`}>{t('Marketplace.PublicRecipe.Details.PublishedOn', 'Published on')}</div>
										<div>{format(parseISO(publication?.details.updatedAt), 'PP')}</div>
									</div>
								</div>

								<div className={styles.InstallButtonContainer}>
									{publication.details.type === 'recipe' && (
										<StyledButton
											data-kemu-meta="add-to-library-btn"
											loading={importingRecipe}
											disabled={importingRecipe || !canAddToLibrary}
											onClick={handleImport}
											className={styles.LibraryBtn}
											title={t('Marketplace.PublicRecipe.Details.AddToLibraryBtn', 'Add To Library')}
											color="secondary"
										/>
									)}

									{publication.details.type === 'thing' && (
										<StyledButton
											data-kemu-meta="install-thing-btn"
											loading={thingInstallationStatus.status === AsyncRequestStatus.loading}
											disabled={thingInstallationStatus.status === AsyncRequestStatus.loading}
											onClick={thingAlreadyInstalled ? showUninstallConfirmation : handleInstallThing}
											className={styles.LibraryBtn}
											title={thingAlreadyInstalled ?
												t('Marketplace.PublicRecipe.Details.UninstallBtn', 'Uninstall Thing') :
												t('Marketplace.PublicRecipe.Details.InstallThingBtn', 'Install Thing')
											}
											color={thingAlreadyInstalled ? 'danger' : 'secondary'}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className={`${styles.InfoContainerB} ${styles.FlexRow} section`}>
					<div className="rating">
						<strong>{t('Marketplace.PublicRecipe.Details.Rating', 'Rating')}  </strong>
						<div className="text-center">
							<Rate
								allowClear={false}
								defaultValue={publication?.details.ratings.rating}
								onChange={onRateRecipe}
								disabled={rating}
							/>
						</div>
					</div>

					<div className="rated">
						<strong>{t('Marketplace.PublicRecipe.Details.RatedBy', 'Rated By')}</strong>
						<div className="text-center">{publication?.details.ratings.total} {t('Marketplace.PublicRecipe.Details.UsersSuffix', 'users')}</div>
					</div>

					{publication?.details.age && (
						<>
							<div className="dash-line"></div>
							<div className="age">
								<strong>{t('Marketplace.PublicRecipe.Details.Age', 'Age')}</strong>
								<div className="text-center">{publication?.details.age}-{publication?.details.age + 1}</div>
							</div>
						</>
					)}

					{publication.details.type === 'recipe' && (
						<>
							<div className="dash-line" />
							<div className="tutorial">
								<strong>{t('Marketplace.PublicRecipe.Details.TutorialProvided', 'Tutorial Provided')}</strong>
								<div className="text-center">
									{publication?.details.linkedResource?.type === 'tutorial' ? (
										t('Marketplace.PublicRecipe.Details.Yes', 'Yes')
									) : (
										t('Marketplace.PublicRecipe.Details.No', 'No')
									)}
								</div>
							</div>
						</>
					)}
				</div>

				<table className={`${styles.InfoContainerS} ${styles.FlexColumn} section`}>
					<tbody>
						<tr>
							<th>{t('Marketplace.PublicRecipe.Details.Rating', 'Rating')}</th>
							<th><Rate allowClear={false} defaultValue={publication?.details.ratings.rating} onChange={onRateRecipe} /></th>
						</tr>
						<tr>
							<th>{t('Marketplace.PublicRecipe.Details.RatedBy', 'Rated By')}</th>
							<th className="">
								{publication?.details.ratings.total} {t('Marketplace.PublicRecipe.Details.UsersSuffix', 'users')}
							</th>
						</tr>

						{publication?.details.age && (
							<tr>
								<th className="">{t('Marketplace.PublicRecipe.Details.Age', 'Age')}</th>
								<th>{publication?.details.age}-{publication?.details.age + 1}</th>
							</tr>
						)}

						<tr>
							<th className="">{t('Marketplace.PublicRecipe.Details.TutorialProvided', 'Tutorial Provided')}</th>
							<th>
								{publication?.details.linkedResource?.type === 'tutorial' ? (
									t('Marketplace.PublicRecipe.Details.Yes', 'Yes')
								) : (
									t('Marketplace.PublicRecipe.Details.No', 'No')
								)}
							</th>
						</tr>
					</tbody>
				</table>

				<div className="description-container section">
					<h2>{t('Marketplace.PublicRecipe.Details.Description', 'Description')}</h2>
					<div dangerouslySetInnerHTML={{ __html: getMarkedDescription() }} />
				</div>

				<div className={`${styles.SlickContainer} section`}>
					<div className="images">
						<Carousel autoplay={true}
							draggable={true}
							slidesToShow={1}
							slidesToScroll={1}
							centerMode={true}
							infinite={true}>
							{previewAssets.map((asset, index) => {
								if (asset.type === 'image') {
									return (
										<div className={`${styles.SlideContainer}`} key={`pic-${index}`}>
											<div className={`${styles.ImgBackground}`} style={{ backgroundImage: `url('${asset.url}')` }} />
										</div>
									);
								} else {
									return (
										<video src={asset.url} className={styles.VideoAsset} key={`video-${index}`} controls/>
									);
								}
							})}
						</Carousel>
					</div>
				</div>


				{publication?.details.categories.length > 0 && (
					<>
						<div className="divider" />
						<div className={`${styles.Categories} section`}>
							<h2>{t('Marketplace.PublicRecipe.Details.Categories', 'Categories')}</h2>
							{publication?.details.categories.map((category, index) => (
								<div key={index} className={styles.Category}>
									<Tag color="default">{category}</Tag>
								</div>
							))}
						</div>
					</>
				)}


				{publication?.details.requirements.length > 0 && (
					<>
						<div className="divider" />
						<div className="requirements-container section">
							<h2>{t('Marketplace.PublicRecipe.Details.Requirements', 'Requirements')}</h2>
							{publication?.details.requirements.map((requirement, index) => (
								<div key={index}>{index+1}. <a href={requirement.link || '#'} target="_blank" rel="noreferrer">{requirement.name}</a></div>
							))}
						</div>
					</>
				)}
			</div>

		</div>
	);
};

export default PublicationDetailsView;
