import { WidgetType } from '@kemu-io/kemu-core/dist/types/gate_t';
import { WidgetCategory } from '../types/widgets';

export const WidgetCategoryMap: Record<WidgetType, WidgetCategory> = {
  // Consumers
  display: 'consumer',
  action: 'consumer',

  // Transformers
  arrayCombine: 'transformer',
  arrayItem: 'transformer',
  extractImage: 'transformer',
  json: 'transformer',
  jsonParse: 'transformer',
  map: 'transformer',
  multiplication: 'transformer',
  parser: 'transformer',
  object: 'transformer',

  // Image operations
  base64ToImageData: 'transformer',
  imageConvolution: 'transformer',
  imageCrop: 'transformer',
  imageResize: 'transformer',
  pixelDraw: 'transformer',
  pixelfy: 'transformer',
  imageWarp: 'transformer',
  counter: 'transformer',

  // Store data
  text: 'storage',
  value: 'storage',
  dynamoDb: 'storage',


  // Conditions
  between: 'condition',
  ifGate: 'condition',

  // Producers
  clock: 'producer',
  play: 'producer',
  input: 'producer',
  slider: 'producer',
  randomBetween: 'producer',

  // Logic
  elapsed: 'logic',
  firstEvent: 'logic',
  skipEvent: 'logic',
  suspend: 'logic',
  sequence: 'logic',

  widgetGroup: 'custom',
  widgetBundle: 'custom',
  script: 'custom',
};


export const getCategoryFromType = (type: WidgetType): WidgetCategory => {
	return WidgetCategoryMap[type] ?? 'custom';
};
