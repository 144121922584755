
import React from 'react';
import Icon from '@ant-design/icons';
import ElapsedWidgetProcessor from '@kemu-io/kemu-core/dist/gates/elapsed';
import { WidgetPortContext } from '@kemu-io/kemu-core/dist/types/gate_t';
import useReactiveWidgetState from '../../../common/hooks/useReactiveWidgetState';
import { GetPortsInformationFunction, GateUI, GateUIProps } from '..';
import GateIcon from '../../gateIcon/gateIcon';
import { ReactComponent as ElapsedGateIcon } from '../../../assets/img/gates/elapsed-gate.svg';
import { PortLocation } from '../../../types/canvas_t';


interface Props extends GateUIProps {
	dataSources?: {
		name: string;
		inputs: string[]
	}[]
}


const ElapsedGate = (props: Props): React.JSX.Element => {
	const [state, setState] = useReactiveWidgetState(props.recipeId, props.thingRecipeId, props.info.id);
	const initialValue = state.elapsed || 0;

	const inputChangedCb = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = evt.currentTarget.value;
		setState({ ...state, elapsed: newValue, updatedAt: Date.now() }, true);
	};


	return (
		<div className="elapsed-gate standard-body">
			<div className="gate-input">
				<input type="text" value={initialValue as string} onChange={inputChangedCb}/>
			</div>
		</div>
	);
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={ElapsedGateIcon} />}/>
	);
};


const getPortsInformation: GetPortsInformationFunction = (state, widgetInfo) => {
	const portContext: WidgetPortContext = { recipePoolId: widgetInfo.recipePoolId, recipeType: widgetInfo.recipeType };
	const outputNames = ElapsedWidgetProcessor.getOutputNames(state, portContext);
	const inputNames = ElapsedWidgetProcessor.getInputNames(state, portContext);

	const positions: Record<string, PortLocation> = {
		'in': [0, 0.33, -1, 0],
		'reset': [0, 0.66, -1, 0]
	};

	return {
		inputs: inputNames.map(input => ({
			name: input.name,
			type: input.type,
			position: positions[input.name]
		})),

		outputs: [{
			name: outputNames[0].name,
			type: outputNames[0].type,
			position: 'Right'
		}]
	};
};

export default {
	getPortsInformation,
	BarIcon: GateBarIcon,
	Element: ElapsedGate,
	hasTitle: true,
	getWidgetTitle: (intl) => intl.formatMessage({ defaultMessage: 'Elapsed', id: 'LogicMaker.Gates.Elapsed.Title' }),
} as GateUI;
