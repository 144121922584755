import { BrowserJsPlumbInstance } from '@jsplumb/community/types/dom/browser-jsplumb-instance';
import { ConnectionEstablishedParams, ConnectionMovedParams } from '@jsplumb/community/types/core';
import React, { memo, useCallback, useEffect } from 'react';
import { ChildBlock, RecipeBlock } from '@kemu-io/kemu-core/dist/types/block_t';
import { useDispatch, useSelector } from 'react-redux';
import CanvasBlockElement from '../../components/canvasBlock/canvasBlock';
import { getBlockCanvasInfo } from '../../app/recipe/utils';
import { PlumbDragEvent } from '../../types/core_t';
import { CreateEternalInstanceFunction, DestroyEternalInstanceFunction } from '../../common/hooks/useEternalCanvasInstance';
import { decodeDomId, generateDomId } from '../../common/utils';
import { updateBlockCanvasInfo, blocksInRecipe, addChild, removeChild } from '../Workspace/workspaceSlice';
import Logger from '../../common/logger';



const logger = Logger('virtualBlocks');
interface Props {
	plumbInstance: BrowserJsPlumbInstance;
	recipeId: string;
	createCanvasUI: CreateEternalInstanceFunction
	destroyCanvasUI: DestroyEternalInstanceFunction
}


interface ReducedRecipeBlock {
	recipeId: string;
	children: ChildBlock[]
}

const connectBlocks = (plumbInstance: BrowserJsPlumbInstance, blocks: Record<string, ReducedRecipeBlock>) => {
	plumbInstance.batch(() => {
		for (const blockId in blocks) {
			const block = blocks[blockId];
			logger.log(`Connecting ${block.children.length} children to block ${(block as RecipeBlock).name} [${block.recipeId}]`);
			block.children.forEach(child => {
				plumbInstance.connect({ uuids: [child.sourcePort, child.targetPort] });
			});
		}
	});
};


const disconnectBlocks = (plumbInstance: BrowserJsPlumbInstance, blocks: Record<string, ReducedRecipeBlock>, recipeId: string) => {
	plumbInstance.batch(() => {
		for (const blockId in blocks) {
			// NOTE: Block DOM elements use blockId::blockId format as their DOM id. Check canvasBlock.tsx
			const canvasId = generateDomId(recipeId, blockId);
			plumbInstance.deleteConnectionsForElement(canvasId);
			// NOTE: Kind of a workaround. Jsplumb keeps a reference to all managed elements
			// (elements that ether have endpoints or are draggable), when the block is unmounted
			// the reference remains in memory and prevents any further actions to the same elements.
			// The only way to get rid of that reference is to call plumbInstance.remove() directly, the problem is that
			// by the time useEffect is triggered, the element no longer exists in the canvas, and plumbInstance.remove()
			// ignores the call without removing the reference. Here, we remove the memory reference manually
			delete plumbInstance.getManagedElements()[canvasId];
			// plumbInstance.unmanage(canvasId);
		}
	});
};


/** 
 * SUPER important equality function to prevent re-rendering
 * every time a block is moved in the canvas.
 * It extracts the canvas property (which holds the block position), stringifies 
 * the remaining object and compares the resulting strings
 **/
const ignoreCanvasUpdates = (current: Record<string, RecipeBlock>, prev: Record<string, RecipeBlock>) => {
	const reduceFun = (instance: Record<string, RecipeBlock>, map: RecipeBlock, blockId: string) => {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { canvas, children, ...rest } = instance[blockId];
		return { ...map, [blockId]: { ...rest } };
	};

	const prevFiltered = Object.keys(prev).reduce(reduceFun.bind(null, prev), {} as RecipeBlock);
	const currentFiltered = Object.keys(current).reduce(reduceFun.bind(null, current), {} as RecipeBlock);
	const areEqual = JSON.stringify(prevFiltered) === JSON.stringify(currentFiltered);
	return areEqual;
};

const VirtualBlocks = (props: Props): React.JSX.Element => {
	const dispatch = useDispatch();
	const blocks = useSelector(blocksInRecipe, ignoreCanvasUpdates);
	const { plumbInstance, recipeId } = props;

	/** 
	 * Updates the block's position in the recipe
	 **/
	const handleBlockMovedEvent = useCallback((evt: PlumbDragEvent) => {

		const block = decodeDomId(evt.el.id);
		if (block) {
			const canvasInfo = getBlockCanvasInfo(block.recipeId, block.blockId);
			canvasInfo.position = {
				x: evt.pos.left,
				y: evt.pos.top
			};

			dispatch(updateBlockCanvasInfo({
				blockId: block.blockId,
				recipeId: block.recipeId,
				canvasInfo
			}));
		}
	}, [dispatch]);

	/** Invoked when 2 blocks are linked */
	const handleConnectionEvent = useCallback((event: ConnectionEstablishedParams) => {
		const sourceBlock = decodeDomId(event.sourceId);
		const targetBlock = decodeDomId(event.targetId);
		if (!sourceBlock || !targetBlock) { return; }

		logger.log(`Connection detected from ${sourceBlock.blockId} to ${targetBlock.blockId}`);
		dispatch(addChild({
			recipeId: sourceBlock.recipeId,
			source: sourceBlock.blockId,
			target: targetBlock.blockId,
			sourcePortId: event.sourceEndpoint.getUuid(),
			targetPortId: event.targetEndpoint.getUuid()
		}));

	}, [dispatch]);

	/** Invoked when a connection is removed */
	const handleConnectionDetachedEvent = useCallback((event: ConnectionEstablishedParams, mouseEvent: MouseEvent) => {
		const sourceBlock = decodeDomId(event.sourceId);
		const targetBlock = decodeDomId(event.targetId);
		if (!sourceBlock || !targetBlock) { return; }

		// Only user-initiated events are taken into account. Any other event should take care of
		// removing connections themselves.
		logger.log(`Disconnection event from ${sourceBlock.blockId} to ${targetBlock.blockId} by ${mouseEvent ? 'user' : 'system'}`);
		if (mouseEvent) {
			dispatch(removeChild({
				recipeId: sourceBlock.recipeId,
				source: sourceBlock.blockId,
				target: targetBlock.blockId,
				sourcePortId: event.sourceEndpoint.getUuid(),
				targetPortId: event.targetEndpoint.getUuid()
			}));
		}
	}, [dispatch]);

	/** Invoked when a connections is moved from one block to another */
	const handleConnectionMovedEvent = useCallback((event: ConnectionMovedParams, mouseEvent: MouseEvent) => {
		const sourceBlock = decodeDomId(event.originalSourceId);
		const targetBlock = decodeDomId(event.originalTargetId);
		if (!sourceBlock || !targetBlock) { return; }
		logger.log(`Connection moved event from ${sourceBlock.blockId} to ${targetBlock.blockId} by ${mouseEvent ? 'user' : 'system'}`);

		dispatch(removeChild({
			recipeId: sourceBlock.recipeId,
			source: sourceBlock.blockId,
			target: targetBlock.blockId,
			sourcePortId: event.originalSourceEndpoint.getUuid(),
			targetPortId: event.originalTargetEndpoint.getUuid()
		}));

	}, [dispatch]);

	useEffect(() => {
		connectBlocks(plumbInstance, blocks);
		// Define an event listener for when connections are made
		plumbInstance.bind('connection', handleConnectionEvent);
		plumbInstance.bind('connectionDetached', handleConnectionDetachedEvent);
		plumbInstance.bind('connectionMoved', handleConnectionMovedEvent);
		plumbInstance.bind('drag:stop', handleBlockMovedEvent);

		return () => {
			logger.log(`Removing event listeners`);
			plumbInstance.unbind('connection', handleConnectionEvent);
			plumbInstance.unbind('connectionDetached', handleConnectionDetachedEvent);
			plumbInstance.unbind('connectionMoved', handleConnectionMovedEvent);
			plumbInstance.unbind('drag:stop', handleBlockMovedEvent);
			disconnectBlocks(plumbInstance, blocks, recipeId);
		};
	}, [plumbInstance, recipeId, handleBlockMovedEvent, handleConnectionMovedEvent, handleConnectionDetachedEvent, handleConnectionEvent, blocks]);

	return (
		<>
			{Object.keys(blocks).map((blockId, index) => {
				return (
					<CanvasBlockElement
						recipeBlock={blocks[blockId]}
						createCanvasUI={props.createCanvasUI}
						destroyCanvasUI={props.destroyCanvasUI}
						recipePoolId={recipeId}
						index={index}
						plumbInstance={plumbInstance}
						key={index} /*{...block}*/
					/>);
			})}
		</>
	);
};

export default memo(VirtualBlocks);
