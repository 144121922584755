import { UserPropertyValue } from '@kemu-io/kemu-types/dist/types';
import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import * as userApi from '../../../api/user/userApi';
import { CustomerState, UserActionEvent, UserOnboarding } from './userSlice';

/**
 * Replaces the user's `onboarding` property with the given value.
 * IMPORTANT: Passing a partial object will delete all other properties.
 */
export const updateUserOnboardingAction = createAsyncThunk('/user/event/track/onboarding', async (
	args: { eventName: UserActionEvent, onboarding: UserOnboarding },
): Promise<UserOnboarding> => {

	// This is NOT async.
	userApi.trackEvent(args.eventName, {
		onboarding: args.onboarding
	});

	return args.onboarding;
});

export const updateUserOnboardingReducer = ((builder: ActionReducerMapBuilder<CustomerState>): void => {
	// WARNING: we are assuming posthog event racking went OK. 
	// Here we update the user's integrations to keep data "in sync".
	builder.addCase(updateUserOnboardingAction.fulfilled, (state, action: PayloadAction<UserOnboarding>) => {
		if (state.currentUserProfile.integrations.posthog) {
			state.currentUserProfile.integrations = {
				...state.currentUserProfile.integrations,
				posthog: {
					...state.currentUserProfile.integrations.posthog,
					properties: {
						...state.currentUserProfile.integrations.posthog.properties,
            onboarding: {
              ...(state.currentUserProfile.integrations.posthog.properties?.onboarding as Record<string, UserPropertyValue>),
              ...action.payload
            }
					}
				}
			};
		}
	});

});
