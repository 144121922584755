import { AssetsUploadResponse } from '@kemu-io/kemu-types/dist/types';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as marketplaceApi from '../../../api/marketplace/marketplaceApi';
import { RootState } from '../../../app/store';
import { AsyncRequestStatus, AsyncState } from '../../../types/core_t';

interface PublisherState {
	assetsUpload: {
		request: AsyncState,
		url: string;
		version: number;
	}
}


const initialState: PublisherState = {
	assetsUpload: {
		request: {
			status: AsyncRequestStatus.idle
		},

		url: '',
		version: 0,
	},

};


const fetchAssetsUploadUrl = createAsyncThunk('/publisher/fetchAssetsUploadUrl', async (options: {recipeId: string}): Promise<AssetsUploadResponse> => {
	const response = await marketplaceApi.getUploadUrl(options.recipeId, '');
	return response;
});

export const publisherSlice = createSlice({
	name: 'publisher',
	initialState,
	reducers: {

	},

	extraReducers: (builder) => {
		builder.addCase(fetchAssetsUploadUrl.pending, (state) => {
			state.assetsUpload.request.status = AsyncRequestStatus.loading;
		});

		builder.addCase(fetchAssetsUploadUrl.fulfilled, (state, action: PayloadAction<AssetsUploadResponse>) => {
			state.assetsUpload.request.status = AsyncRequestStatus.completed;
			state.assetsUpload.url = action.payload.uploadUrl;
			state.assetsUpload.version = action.payload.version;
		});

		builder.addCase(fetchAssetsUploadUrl.rejected, (state, action) => {
			state.assetsUpload.request.status = AsyncRequestStatus.error;
			state.assetsUpload.request.error = action.error;
		});

	}
});

// export const {

// } = publisherSlice.actions;

export {
	fetchAssetsUploadUrl
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const assetsUploadInfo = (state: RootState) => state.recipePublisher.assetsUpload;

export default publisherSlice.reducer;
