import React, { useEffect, useRef } from 'react';
import { CloseCircleFilled } from '@ant-design/icons';
import { useDrop, DropTargetMonitor, useDrag } from 'react-dnd';
import { XYCoord } from 'dnd-core';
import classNames from 'classnames';
import { DroppableTypes } from '../../../../common/constants';
import styles from './stepCard.module.css';

interface Props {
	label: string | React.ReactNode;
	onClick: ()=> void;
	onDelete?: ()=> void;
  onChangeOrder?: (index: number, newIndex: number) => void;
	showDeleteBtn: boolean;
	selected?: boolean;
	isAddBtn?: boolean;
  index: number;
  id?: string;
  readOnly: boolean;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}


const StepCard = (props: Props): React.JSX.Element => {
	const ref = useRef<HTMLDivElement>(null);
  const { index, id } = props;


	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_, drop] = useDrop({
    accept: DroppableTypes.TutorialStep,

    hover (item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current || props.readOnly) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;
      if (!id ) { return; }

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }


      // Time to actually perform the action
      props.onChangeOrder && props.onChangeOrder(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: DroppableTypes.TutorialStep,
    item: () => ({
      index: index,
      id: id
    }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

	const handleDeleteClick = (event: React.MouseEvent) => {
		event.stopPropagation();
		props.onDelete && props.onDelete();
	};


  const stepCount = index + 1;

  // Update the references if the index changes
  useEffect(() => {
    if (id && !props.readOnly) {
      drag(ref);
      drop(ref);
    } else if (props.readOnly) {
      drag(null);
    }
  }, [index, props.label, drag, drop, id, props.readOnly]);

	return (
		<div
      ref={ref}
      style={{ opacity }}
      onClick={props.onClick}
      className={classNames(styles.Step, { selected: props.selected, 'add-btn': props.isAddBtn })}
      key={stepCount || 'NA'}
    >
			{id !== undefined && (
				<span className={styles.StepCounter}>
					{stepCount}
				</span>
			)}

			<span className={styles.StepTitle}>
				{props.label} {isDragging ? '(Dragging)' : ''}
			</span>

			{props.showDeleteBtn && !props.readOnly && (
				<span className={styles.DeleteBtn} onClick={handleDeleteClick} >
					<CloseCircleFilled style={{ fontSize: 18 }}/>
				</span>
			)}
		</div>
	);
};

export default StepCard;
