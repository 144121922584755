
import React from 'react';
import { message } from 'antd';
import Icon from '@ant-design/icons';
import ImageResizeWidgetProcessor, {
	ImageResizeWidgetState,
	getDefaultState,
} from '@kemu-io/kemu-core/dist/gates/imageResize';

import { CustomWidgetState, WidgetPortContext } from '@kemu-io/kemu-core/dist/types/gate_t';
import classNames from 'classnames';
import useTranslation from '../../../common/hooks/useTranslation';
import NumericInput from '../../WidgetsComponents/NumericInput/NumericInput';
import KemuSwitch from '../../form-control/kemuSwitch/kemuSwitch';
import { PortLocation } from '../../../types/canvas_t';
import GateIcon from '../../gateIcon/gateIcon';
import {
	GateCustomSettingsProps,
	GetPortsInformationFunction,
	GateUI,
	PortDescription,
	GateUIProps,
} from '..';
import useReactiveWidgetState from '../../../common/hooks/useReactiveWidgetState';
import { getLastInputPortWithParent, LastPortInfo } from '../../../common/utils';
import { SETTINGS_CONTAINER_CLASS } from '../../../common/constants';
import styles from './imageResize.module.css';
import { ReactComponent as Base64ToImageIcon } from './icon.svg';

const WIDGET_WRAPPER_CLASS = `image-resize-widget-wrapper`;

const ImageResizeWidget = (props: GateUIProps): React.JSX.Element => {
	const [state] = useReactiveWidgetState<ImageResizeWidgetState>(props.recipeId, props.thingRecipeId, props.info.id);

	return (
		<div className={classNames(styles.GateBody, {
			[styles.LargeGateBody]: state.showInputPorts,
			[styles.WidgetDisabled]: props.info.disabled
		})}>
			<Icon component={Base64ToImageIcon}/>
		</div>
	);
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={Base64ToImageIcon} />}/>
	);
};

const GateCustomSettings = (props: GateCustomSettingsProps): React.JSX.Element => {
	const [state, setState] = useReactiveWidgetState<ImageResizeWidgetState>(props.recipeId, props.blockId, props.gateInfo.id);
	const fixedState = {
		...getDefaultState(),
		...state
	};

	const t = useTranslation('LogicMaker.Gates.ImageResize.Settings');

	const warnIfInputAttached = (info: LastPortInfo | null): boolean => {
		if (info && info?.portName !== 'image') {
			message.warning({
				content: t('RemoveWarning', 'Please remove all connections from port "{name}"',
				{ name: info.portName })
			});
			return true;
		}

		return false;
	};

	const getLastConnectedInput = () => {
		return getLastInputPortWithParent(
			fixedState,
			ImageResizeWidgetProcessor,
			props.gateInfo.id,
			props.blockId,
			props.recipeId,
			props.recipeType
		);
	};

	const updateResizeValue = (prop: keyof ImageResizeWidgetState, value: number) => {
		setState({
			...fixedState,
			[prop]: value
		});
	};

	const handleWChange = (value: number) => updateResizeValue('width', value);
	const handleHChange = (value: number) => updateResizeValue('height', value);


	const handleSwitchChange = (checked: boolean) => {
		const lastPortWithConnection = getLastConnectedInput();
		// Prevent removing ports if connections are attached
		if (warnIfInputAttached(lastPortWithConnection)) { return ; }

		setState({ ...fixedState, showInputPorts: checked });
	};

	return (
		<div className={classNames(styles.SettingsContainer, SETTINGS_CONTAINER_CLASS)}>
			{props.children}

			<div className={styles.SwitchContainer}>
				<label>{t('Label', 'Show input ports')}</label>
				<KemuSwitch
					size="small"
					checked={fixedState.showInputPorts}
					onChange={handleSwitchChange}
				/>
			</div>

			<div className={styles.InputContainer}>

				<div className={styles.SingleRow}>
					<div className={styles.InputColumn}>
						<label>{t('PlaceHolderWidth', 'width')}</label>
						<NumericInput
							min={0}
							wrapperClass={styles.InputWrapper}
							placeholder={t('PlaceHolderWidth', 'width')}
							value={fixedState.width}
							onChange={handleWChange}
						/>
					</div>

					<div className={styles.InputColumn}>
						<label>{t('PlaceHolderHeight', 'height')}</label>
						<NumericInput
							min={0}
							wrapperClass={styles.InputWrapper}
							placeholder={t('PlaceHolderHeight', 'height')}
							value={fixedState.height}
							onChange={handleHChange}
						/>
					</div>

				</div>
			</div>
		</div>
	);
};

const getPortsInformation: GetPortsInformationFunction = (state: CustomWidgetState<ImageResizeWidgetState>, widgetInfo) => {
	const portContext: WidgetPortContext = { recipePoolId: widgetInfo.recipePoolId, recipeType: widgetInfo.recipeType };
	const outputNames = ImageResizeWidgetProcessor.getOutputNames(state, portContext);
	const inputNames = ImageResizeWidgetProcessor.getInputNames(state, portContext);

	const inputPositions: Record<string, PortLocation> = {
		'image': [0, 0.25, -1, 0],
		'width': [0, 0.50, -1, 0],
		'height': [0, 0.75, -1, 0],
	};

	let inputs: PortDescription[] = [];
	if (inputNames.length === 1) {
		inputs.push({
			...inputNames[0],
			position: 'Left'
		} as PortDescription);
	} else {
		inputs = inputNames.map(input => {
			return {
				...input,
				position: inputPositions[input.name],
			};
		});
	}

	return {
		outputs: [{
			position: 'Right',
			name: outputNames[0].name,
			type: outputNames[0].type,
		}],

		inputs,
	};
};

export default {
	getPortsInformation,
	BarIcon: GateBarIcon,
	Element: ImageResizeWidget,
	CustomSettingsDialog: GateCustomSettings,
	getWrapperClass: () => WIDGET_WRAPPER_CLASS,
	hasTitle: true,
	getGatesBarTitle: (intl) => intl.formatMessage({ id: 'LogicMaker.Gates.ImageResize.Title', defaultMessage: 'Resize Image' }),
	getWidgetTitle: (intl) => intl.formatMessage({ id: 'LogicMaker.Gates.ImageResize.WidgetHeader', defaultMessage: 'Resize' }),
} as GateUI;
