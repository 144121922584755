import React, { useEffect, useRef, useCallback, useState, memo } from 'react';
import classNames from 'classnames';
import { Popover } from 'antd';
import { Portal } from 'react-portal';
import { createPopper, Instance } from '@popperjs/core';

import {
  QuestionCircleOutlined
} from '@ant-design/icons';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Hotspot, TriggerIconMethods } from '../types';
import { getCompiledCode, getEffectiveZIndex, isVisible } from '../utils';
import styles from './tooltip.module.css';
import VideoContent from './contentTypes/videoContent/videoContent';
import HTMLContent from './contentTypes/htmlContent/htmlContent';
import ImageContent from './contentTypes/imageContent/imageContent';


type TooltipWrapperProps = {
	hotspot: Hotspot;
	style: CSSProperties;
	triggerRef: React.MutableRefObject<HTMLDivElement | null>;
	invisible?: boolean;
	onClick: () => void;
}

type TooltipState = 'showing' | 'show' | 'hide';

const TooltipWrapper = (props: TooltipWrapperProps) => {
	const { hotspot: info, style: iconStyle, triggerRef } = props;
	const [popOverVisible, setPopOverVisible] = useState(false);
	const showTmrRef = useRef<NodeJS.Timeout | null>(null);
	const nextStateRef = useRef<TooltipState>('hide');

	const wrapperStyle: CSSProperties = {
		maxWidth: info?.tooltipSettings?.maxWidth,
		maxHeight: info?.tooltipSettings?.maxHeight,
		minWidth: info?.tooltipSettings?.minWidth,
		minHeight: info?.tooltipSettings?.minHeight,
	};

	// from: https://github.com/yiminghe/dom-align
	const alignConfig = {
		// points: ['tl', 'tr'],        // align top left point of sourceNode with top right point of targetNode
		offset: [
			info.tooltipSettings?.offset?.x || 0,
			info.tooltipSettings?.offset?.y || 0
		],            // the offset sourceNode by 10px in x and 20px in y,
		// targetOffset: ['30%','40%'], // the offset targetNode by 30% of targetNode width in x and 40% of targetNode height in y,
		// overflow: { adjustX: true, adjustY: true }, // auto adjust position when sourceNode is overflowed
	};

	/** sets the future state of the tooltip, allowing delayed events to be cancelled */
	const toggleShow = (show: boolean, nextState: TooltipState) => {
		setPopOverVisible(show);
		nextStateRef.current = nextState;
	};

	// Propagate clicks to parent
	const handleClick = () => {
		if (triggerRef.current) {
			triggerRef.current.parentElement?.click();
			toggleShow(false, 'hide');
			props.onClick();
		}
	};

	const handleMouseDown = () => {
		if (triggerRef.current) {
			toggleShow(false, 'hide');
			triggerRef.current.parentElement?.dispatchEvent(new MouseEvent('mousedown'));
		}
	};

	const handleMouseLeave = () => {
		toggleShow(false, 'hide');
	};

	const showNow = () => {
		if (nextStateRef.current === 'showing') {
			toggleShow(true, 'show');
		}
	};

	const handleMouseHover = () => {
		toggleShow(false, 'showing');
		showTmrRef.current = setTimeout(showNow, info.tooltipSettings?.showDelay ? info.tooltipSettings?.showDelay * 1000 : 200);
	};

	let tooltipContent: React.JSX.Element;
	if (info.type === 'video') {
		tooltipContent = <VideoContent text={info.videoSettings?.description} videoUrl={info.videoSettings?.url}/>;
	} else if (info.type === 'image') {
		tooltipContent = <ImageContent text={info.imageSettings?.description} url={info.imageSettings?.url}/>;
	} else {
		tooltipContent = <HTMLContent content={info.htmlSettings?.content} />;
	}

	useEffect(() => {
		return () => {
			if (showTmrRef.current) {
				clearTimeout(showTmrRef.current);
			}
		};
	}, []);

	return (
		<Popover
			visible={ info.tooltipSettings?.invisible ? false : popOverVisible }
			overlayStyle={wrapperStyle}
			align={alignConfig}
			overlayClassName={classNames(styles.TooltipOverlay, { 'no-arrow': info?.tooltipSettings?.hideTooltipArrow })}
			title={<HTMLContent content={info.title} noMarkdown />}
			arrowPointAtCenter={false}
			content={tooltipContent}
			placement={info.tooltipSettings?.placement}
			trigger={info.triggers}
			destroyTooltipOnHide={true}
		>
			<span
				onClick={handleClick}
				onMouseDown={handleMouseDown}
				onMouseEnter={handleMouseHover}
				onMouseLeave={handleMouseLeave}
				ref={triggerRef}
				className={classNames(styles.Trigger)}
			>
				{!props.invisible && (
					<>
						{info.triggerIcon?.type === 'QuestionCircleOutlined' ? (
							<QuestionCircleOutlined
								style={iconStyle}
							/>
						) : (
							<span></span>
						)}
					</>
				)}
			</span>
		</Popover>
	);
};

interface Props {
	/** piece of extra code to prepend before compilation */
	scriptPrepend: string;
	info: Hotspot;
}

const TooltipTargeter = (props: Props): React.JSX.Element => {
	const { info } = props;
	const [targets, setTargets] = useState<Element[]>([]);

	const handleHotspotClick = useCallback(() => {
		if (info.triggerIcon?.onClick) {
			const userCode = getCompiledCode<TriggerIconMethods>({
				onClick: info.triggerIcon?.onClick,
				prepend: props.scriptPrepend
			});
			userCode.onClick();
		}
	}, [info.triggerIcon, props.scriptPrepend]);

	useEffect(() => {
		const { selector, allowMultipleMatches } = info.selectorSettings;
		const nodeList = document.querySelectorAll(selector);
		const elements = Array.prototype.slice.call(nodeList);
		if (allowMultipleMatches) {
			// Only update list of targets if there are different elements after the selector
			setTargets(currentElements => {
				if (currentElements.length !== elements.length) { return elements; }
				const areTheSame = elements.every(el => currentElements.find(cEl => cEl === el));
				if (areTheSame) {
					return currentElements;
				} else {
					return elements;
				}
			});
		} else {
			setTargets(l => l[0] !== elements[0] ? [elements[0]] : l);
		}
	}, [info]);

	return (
		<>
			{targets.map((target, i) => {
				return <TooltipHotspot key={i} onClick={handleHotspotClick} info={info} target={target} />;
			})}
		</>
	);
};

type TooltipHotspotProps = {
	info: Hotspot;
	target: Element;
	onClick: () => void;
}

const areEqual = (prevProps: TooltipHotspotProps, newProps: TooltipHotspotProps) => {
	return prevProps.target === newProps.target;
};

const TooltipHotspot = memo((props: TooltipHotspotProps): React.JSX.Element => {
	const { info, target: targetEl, onClick } = props;
	const triggerRef = useRef<HTMLDivElement | null>(null);
	const hasIcon = !!info.triggerIcon;
	const [iconStyle, setIconStyle] = useState<CSSProperties>({});
	const [triggerInstance, setTriggerInstance] = useState<Instance | null>(null);
	const visible = targetEl ? isVisible(targetEl) : false;

	useEffect(() => {
		if (targetEl && triggerRef.current) {
			if (info.triggerIcon) {
				// Set the z-index of the container
				triggerRef.current.style.zIndex = info.triggerIcon.zIndex !== undefined ?
					String(info.triggerIcon.zIndex) :
					String(getEffectiveZIndex(targetEl) + 1);

				const finalStyle: CSSProperties = {};
				if (info.triggerIcon.size) {
					if (info.triggerIcon.image) {
						finalStyle.width = info.triggerIcon.size;
						finalStyle.height = info.triggerIcon.size;
					} else {
						finalStyle.fontSize = info.triggerIcon.size;
					}
				}

				if (info.triggerIcon.color) {
					finalStyle.color = info.triggerIcon.color;
				}

				finalStyle.visibility = 'visible';
				setIconStyle(finalStyle);
			} else {

				triggerRef.current.style.visibility = 'visible';

				// If the parent has no position defined, make it relative
				// because we need to position the trigger relative to it.
				if (getComputedStyle(targetEl).position === 'static') {
					(targetEl as HTMLElement).style.position = 'relative';
				}

				// When the target is the trigger, make trigger wrapper the same size
				triggerRef.current.style.width = '100%';
				triggerRef.current.style.height = '100%';
				triggerRef.current.style.left = '0px';
				triggerRef.current.style.top = '0px';
				triggerRef.current.style.position = 'absolute';
			}
		}
	}, [info.triggerIcon, targetEl]);

	// Creates the trigger icon and attaches it to the target
	useEffect(() => {
		if (triggerRef.current && targetEl && hasIcon) {
			const instance = createPopper(targetEl, triggerRef.current, {
				placement: info.triggerIcon?.attachment,
				modifiers: [
				{
					name: 'offset',
					options: {
						offset: [info.triggerIcon?.offset?.y || 0, info.triggerIcon?.offset?.x || 0]
					}
				}]
			});

			setTriggerInstance(instance);
		}
	}, [hasIcon, targetEl, info.triggerIcon]);

	// Re calculate position on every render
	if (triggerInstance && hasIcon) {
		triggerInstance.update();
	}


	return (
		<>
		{hasIcon ? (
			<>
			<Portal node={targetEl}>
				<TooltipWrapper
					onClick={onClick}
					invisible={!visible}
					style={iconStyle}
					hotspot={info}
					triggerRef={triggerRef}
				/>
			</Portal>
			</>
		) : (
			<Portal node={targetEl}>
				<TooltipWrapper
					onClick={onClick}
					style={iconStyle}
					hotspot={info}
					triggerRef={triggerRef}
				/>
			</Portal>
		)}
		</>
	);
}, areEqual);

export default TooltipTargeter;
