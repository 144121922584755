/*
 * Written by Alexander Agudelo < alex@kemu.io >, 2021
 * Date: 11/Mar/2021
 * Last Modified: 19/08/2023, 3:24:02 pm
 * Modified By: Alexander Agudelo
 * Description:  Shows up when a new user is added via admin portal and needs to set a new password
 * 
 * ------
 * Copyright (C) 2021 Kemu - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential.
 */

import React, { useState } from 'react';
import { Row, Col, Spin, Alert } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { LoadingOutlined } from '@ant-design/icons';
import { Auth } from '@aws-amplify/auth';
import StyledInput from '../../../components/form-control/styledInput/styledInput';
import StyledButton from '../../../components/form-control/styledButton/styledButton';
import FormGroup from '../../../components/form-control/formGroup/formGroup';
import AuthCard from '../authCard';
import styles from './passwordReplace.module.css';

interface Props {
	/** username or email */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	user: any;
	oldPassword: string;
	backToLogin?: () => void;
}

interface Message{
	type: 'success' | 'error',
	text: string;
}

const PasswordReplace = (props: Props): React.JSX.Element => {
	const intl = useIntl();
	const [message, setMessage] = useState<Message | null>(null);
	const [processing, setProcessing] = useState(false);
	const [formElements, setFormElements] = useState({
		// code: '',
		password: '',
		repeatPassword: '',
	});

	const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
		const elName = event.currentTarget.getAttribute('name') || undefined;
		const value = event.currentTarget.value;
		if (elName !== undefined && value !== undefined) {
			setFormElements(elements => {
				return {
					...elements,
					[elName]: value!
				};
			});
		}
	};

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		const elements = { ...formElements };

		if (elements.password.trim() === '' || elements.repeatPassword.trim() === '') {
			return setMessage({
				text: intl.formatMessage({ id: 'Screen.PasswordReplace.PleaseFillOut', defaultMessage: 'Please fill out all the fields' }),
				type: 'error'
			});
		}

		if (elements.password.trim() !== elements.repeatPassword.trim()) {
			return setMessage({
				text: intl.formatMessage({ id: 'Screen.SignUp.PasswordMismatch', defaultMessage: 'Passwords don\'t match' }),
				type: 'error'
			});
		}

		setProcessing(true);
		setMessage(null);
		try {
			const result = await Auth.completeNewPassword(props.user, elements.password.trim(), {});
			console.log('completeNewPassword result: ', result);
			// If successful it should automatically redirect to login because a user session gets detected
		} catch (e) {
				console.log('Error: ', e);
			if (e.code) {
				// Simplify password policy error messages
				if (e.message.indexOf('Password does not conform to policy: ') !== -1) {
					e.message = e.message.replace('Password does not conform to policy: ', '');
				}

				setMessage({
					type: 'error',
					text: intl.formatMessage({ id: `Screen.PasswordReplace.${e.code}`, defaultMessage: e.message })
				});
			} else {
				console.log('Error: ', e);
				setMessage({
					type: 'error',
					text: intl.formatMessage({ id: 'Screen.PasswordReplace.UnknownError', defaultMessage: 'There was an error processing your request. Please try again later.' })
				});
			}
		}

		setProcessing(false);
	};

	return (
		<AuthCard title={<FormattedMessage id="Screen.PasswordReplace.Title" defaultMessage="Forgot Password" />}>
			<Spin indicator={<LoadingOutlined className={styles.LoadingIcon}/>} spinning={processing}>
				{message && (
					<>
						<Alert closable={false} message={message.text} type={message.type} />
						<br/>
					</>
				)}

				<form onSubmit={handleSubmit}>
					<p className="text-muted"><FormattedMessage id="Screen.PasswordReplace.Description" /></p>
					<Row>
						<Col span={24}>
							<FormGroup>
								<StyledInput i18nPlaceholderId="Screen.PasswordReplace.NewPasswordField" i18nPlaceholderDefaultMessage="New Password" type="password" name="password" onChange={handleChange} value={formElements.password}/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<FormGroup>
								<StyledInput i18nPlaceholderId="Screen.PasswordReplace.ConfirmPasswordField" i18nPlaceholderDefaultMessage="Confirm Password" name="repeatPassword" type="password" onChange={handleChange} value={formElements.repeatPassword}/>
							</FormGroup>
						</Col>
					</Row>

					<Row>
						<Col span={24}>
							<FormGroup className={styles.SubmitContainer}>
								<StyledButton title={<FormattedMessage id="Screen.PasswordReplace.SubmitButton" defaultMessage="Reset Password" />} type="submit" className={styles.SubmitButton} />
							</FormGroup>
						</Col>
					</Row>
				</form>

				<div className={styles.CardBottomText}>
					<button type="button" onClick={props.backToLogin}>
						<FormattedMessage id="Screen.PasswordReplace.BackToLoginLink" defaultMessage="Back to login" />
					</button>
				</div>
			</Spin>
		</AuthCard>
	);
};

export default PasswordReplace;
