import { ContentBlock, ContentState, SelectionState } from 'draft-js';
import React, { CSSProperties, ReactElement, useState, useRef, forwardRef } from 'react';
import { EditFilled } from '@ant-design/icons';
import { GetSetEditorState } from '@draft-js-plugins/editor';
import { updateBlockMeta } from '../utils';
import { getSrc } from './utils';
import popupStyles from './editVideo.module.css';
import { VideoPluginTheme } from '.';

export interface VideoComponentProps {
  blockProps: GetSetEditorState & {
    src: string;
    width: number;
    height: number;
    setReadOnly: (value: boolean) => void;
    getReadOnly: ()=> boolean;
  };
  className?: string;
  style?: CSSProperties;
  theme: VideoPluginTheme;

  //removed props
  block: ContentBlock;
  customStyleMap: unknown;
  customStyleFn: unknown;
  decorator: unknown;
  forceSelection: unknown;
  offsetKey: string;
  selection: SelectionState;
  tree: unknown;
  contentState: ContentState;
  blockStyleFn: unknown;
  preventScroll: unknown;
}

const VideoComponent = forwardRef<HTMLDivElement, VideoComponentProps>((props, ref): ReactElement => {
	const { blockProps, className, theme, ...otherProps } = props;
  const style = { ...props.style } || {};
  const [editing, setEditing] = useState(false);
  const readOnlyMode = blockProps.getReadOnly();
  const settings = useRef({
    width: blockProps.width,
    height: blockProps.height,
    src: blockProps.src
  });


  const {
    block, // eslint-disable-line @typescript-eslint/no-unused-vars
    customStyleMap, // eslint-disable-line @typescript-eslint/no-unused-vars
    customStyleFn, // eslint-disable-line @typescript-eslint/no-unused-vars
    decorator, // eslint-disable-line @typescript-eslint/no-unused-vars
    forceSelection, // eslint-disable-line @typescript-eslint/no-unused-vars
    offsetKey, // eslint-disable-line @typescript-eslint/no-unused-vars
    selection, // eslint-disable-line @typescript-eslint/no-unused-vars
    tree, // eslint-disable-line @typescript-eslint/no-unused-vars
    contentState, // eslint-disable-line @typescript-eslint/no-unused-vars
    blockStyleFn, // eslint-disable-line @typescript-eslint/no-unused-vars
    preventScroll, // eslint-disable-line @typescript-eslint/no-unused-vars
    ...elementProps
  } = otherProps;

  const onEdit = () => {
    blockProps.setReadOnly(true);
    setEditing(true);
  };


  const finishEditing = () => {

    blockProps.setReadOnly(false);
    setEditing(false);
    const newState = updateBlockMeta(blockProps.getEditorState(), block, settings.current);
    blockProps.setEditorState(newState);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;

    if (target && target.name) {
      const value = target.value;
      const elName = target.name;
      settings.current = {
        ...settings.current,
        [elName]: value
      };
    }
  };

  const src = getSrc(settings.current);
  style.width = `${settings.current.width}px`;
  style.height = `${settings.current.height}px`;


  const EditButton = (): React.JSX.Element | null => {
    if (readOnlyMode) { return null; }
    return (
      <span className={theme.EditButton} onClick={onEdit} >
        <EditFilled style={{ fontSize: 24 }}/>
      </span>
    );
  };

  const EditSettingsOverlay = (): React.JSX.Element => {
    return (
      <div className={popupStyles.Wrapper}>
        <div className={popupStyles.Controls}>
          <input onChange={handleChange} defaultValue={settings.current.src} className={popupStyles.UrlInput} type="text" name="src"/>
          <div className={popupStyles.DimensionsContainer}>
            <label>W</label>
            <input onChange={handleChange} defaultValue={settings.current.width} className={popupStyles.DimensionInput} type="text" name="width" />
            <label>H</label>
            <input onChange={handleChange} defaultValue={settings.current.height} className={popupStyles.DimensionInput} type="text" name="height" />
          </div>
          <button className={popupStyles.SaveButton} onClick={finishEditing}>Save</button>
        </div>
      </div>
    );
  };

  if (src) {
    return (
      <div ref={ref} className={`${theme.IframeContainer} ${className ? className : ''}`} {...elementProps} style={style} >
        <EditButton />

        {editing ? (
          <EditSettingsOverlay />
        ): (
          <iframe
            className={theme.Iframe}
            src={src}
            frameBorder="0"
            allowFullScreen
          />
        )}
      </div>
    );
  }



  return (
    <div ref={ref} className={`${theme.IframeContainer} ${className ? className : ''}`} {...elementProps} style={style} >
      <EditButton />

      {editing ? (
        <EditSettingsOverlay />
      ): (
        <video
          src={blockProps.src}
          className={theme.Video}
          {...elementProps}
          // style={style}
          controls
        />
      )}
    </div>
  );
});


export default VideoComponent;
