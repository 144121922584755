import React, { useMemo, useState, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Draggable, { DraggableData } from 'react-draggable';
import { AnnotationLocation, AnnotationTextData } from '@kemu-io/kemu-core/dist/types/annotation_t';
import { moveAnnotation, selectAnnotations } from '../../features/interface/interfaceSlice';
import { ABORT_DRAGGING_CLASS, ANNOTATION_DRAGGABLE_CLASS } from '../../common/constants';
import { selectedBlock, selectVisibleGroup } from '../../features/Workspace/workspaceSlice';
import TextAnnotation from './TextAnnotation/TextAnnotation';

interface Props {
	recipeId: string;
	location: AnnotationLocation;
}


const AnnotationsManager = (props: Props): React.JSX.Element => {
	const [draggingDisabled, setDraggingDisabled] = useState(false);
	const currentThing = useSelector(selectedBlock);
	const annotations = useSelector(selectAnnotations);
	const annotationFilter: AnnotationLocation = props.location;
	const thingId = currentThing ? currentThing.recipeId : undefined;
	const currentFolder = useSelector(selectVisibleGroup);

	// Create a list of references for the draggable nodes that changes depending on the number of annotations.
	const refs = useMemo(() => Array.from({ length: Object.keys(annotations).length })
		.map(() => createRef<HTMLDivElement>()), [annotations]);

	const dispatch = useDispatch();

	const onCancelDragging = (cancel: boolean): void => {
		setDraggingDisabled(cancel);
	};

	const onDragStop = (annotationId: string, data: DraggableData) => {
		dispatch(moveAnnotation({
			annotationId,
			recipeId: props.recipeId,
			position: {
				x: data.x,
				y: data.y
			}
		}));
	};


	return (
		<>
			{Object.values(annotations).map((annotation, i) => {
				if (annotation.groupId === currentFolder?.groupId || props.location === 'workspace') {
					return (
						<React.Fragment key={annotation.id}>
							{ (
									props.location === 'logicMapper' ? annotation?.thingId === thingId : true
								) && annotation.type === 'text' &&  annotation.location === annotationFilter && (
								<Draggable
									nodeRef={refs[i]}
									disabled={draggingDisabled}
									cancel={`.${ABORT_DRAGGING_CLASS}`}
									handle={`.${ANNOTATION_DRAGGABLE_CLASS}`}
									onStop={(evt, d) => onDragStop(annotation.id, d)}
									position={annotation.position}
									bounds={{ left: 0, top: 0 }}
								>
									<div ref={refs[i]}>
										<TextAnnotation
											id={annotation.id}
											info={annotation.data as AnnotationTextData}
											recipeId={props.recipeId}
											onCancelDragging={onCancelDragging}
										/>
									</div>
								</Draggable>
							)}
						</React.Fragment>
					);
				} else {
					return null;
				}
			})}
		</>
	);
};

export default AnnotationsManager;
