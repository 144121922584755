import React, { ComponentType, useCallback, useEffect, useState } from 'react';
import { EditorPlugin } from '@draft-js-plugins/editor';
import { FontColorsOutlined } from '@ant-design/icons';
import { CompactPicker, ColorChangeHandler, CirclePicker } from 'react-color';
import { EditorState, RichUtils } from 'draft-js';
import classNames from 'classnames';
import { OverrideContentProps, ToolbarChildrenProps } from '../inlineToolbar/components/Toolbar';

export type CustomPickerProps = ToolbarChildrenProps & {
  /**
   * By default, this component changes the color of the
   * selected text. 
   * 
   * Setting this callback disables the default behavior so you
   * can customize what to do with the new color.
   **/
  onColorChange?: (color: string) => void;
  hideOnColorChange?: boolean;
  customIcon?: React.ReactElement;
  wrapperClassName?: string;
  colors?: string[];
  pickerStyle?: 'compact' | 'circle';
  defaultColor?: string;
}

export type ColorPickerPlugin = EditorPlugin & {
  ColorPickerButton: ComponentType<CustomPickerProps>;
}

const CUSTOM_STYLE_PREFIX_COLOR = 'COLOR_';

const pickerColors = [
  '#4D4D4D', '#999999', '#FFFFFF', '#F44E3B',
  '#FE9200', '#FCDC00', '#DBDF00', '#A4DD00',
  '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF',
  '#333333', '#808080', '#cccccc', '#D33115',
  '#E27300', '#FCC400', '#B0BC00', '#68BC00',
  '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF',
  '#000000', '#666666', '#B3B3B3', '#9F0500',
  '#C45100', '#FB9E00', '#808900', '#194D33',
  '#0C797D', '#0062B1', '#653294', '#AB149E',
  // Kemu primary - light
  '#4e3eff', '#6051ff', '#7165ff', '#8378ff',
  // Kemu primary - dark
  '#311eff', '#1500fe', '#1200de', '#05003f',
  // Kemu secondary - light
  '#fc544b', '#fc655d', '#fd766f', '#fd8781',
  // Kemu secondary -dark
  '#fb352b', '#fb170b', '#e10f04', '#400401'
];

const ColorModal = (props: CustomPickerProps): React.JSX.Element => {
  const [color, setColor] = useState<string>(props.defaultColor || '');
  const currentStyle = props.getEditorState().getCurrentInlineStyle().toJS() as string[];
  const { onOverrideContent } = props;

  if (currentStyle && currentStyle.length > 0) {
    // Select first style as default style
    currentStyle.some((colorName) => {
      if (colorName.startsWith(CUSTOM_STYLE_PREFIX_COLOR)) {
        const selectedStyleColor = colorName.split(CUSTOM_STYLE_PREFIX_COLOR)[1];
        if (selectedStyleColor !== color) {
          setColor(selectedStyleColor);
        }

        return true;
      }
    });
  }

  const handleMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
  };


  const onColorSelectedFinally: ColorChangeHandler = (color) => {
    if (props.onColorChange) {
      props.onColorChange(color.hex);
    } else {
      // Default behavior
      const newEditorState = setTextColor(color.hex, props.getEditorState());
      props.setEditorState(newEditorState);
    }

    // Restore popup
    if (props.hideOnColorChange) { props.onOverrideContent(); }
  };

  const hidePopup = useCallback(() => {
    onOverrideContent && onOverrideContent();
  }, [onOverrideContent]);


  useEffect(() => {
    if (props.containerRef?.current) {
      props.containerRef.current?. addEventListener('click', hidePopup);
    }

    return () => {
      if (props.containerRef?.current) {
        props.containerRef.current?.removeEventListener('click', hidePopup);
      }
    };
  }, [props.containerRef, hidePopup]);

  return (
    <div
      onMouseDown={handleMouseDown}
      className={classNames('picker-wrapper', props.wrapperClassName)}
    >
      {props.pickerStyle === 'circle' ? (
        <CirclePicker
          circleSize={20}
          colors={props.colors || pickerColors}
          color={color}
          onChange={onColorSelectedFinally}
        />
      ) : (
        <CompactPicker
          colors={props.colors || pickerColors}
          color={color}
          data-name="color"
          onChange={onColorSelectedFinally}
        />
      )}
    </div>
  );
};

/** Returns a list of styles in the selection */
const getInlineStylesInSelection = (editorState: EditorState): string[] => {
  const selection 	= editorState.getSelection();
  const contentState	= editorState.getCurrentContent();
  const styles	= new Set<string>();
  if (selection.isCollapsed()) {
    editorState.getCurrentInlineStyle().forEach(style => style && styles.add(style));
  } else {
    let 	key		= selection.getStartKey();
    let	startOffset	= selection.getStartOffset();
    const	endKey		= selection.getEndKey();
    const	endOffset	= selection.getEndOffset();
    let lastRound;
    do {
      lastRound		= (key == endKey);
      const block		= contentState.getBlockForKey(key);
      const offsetEnd		= lastRound ? endOffset : block.getLength();
      const characterList	= block.getCharacterList();
      for (let offsetIndex=startOffset; offsetIndex<offsetEnd; offsetIndex++) {
        characterList.get(offsetIndex).getStyle().forEach(style => style && styles.add(style));
      }

      key		= contentState.getKeyAfter(key);
      startOffset	= 0;
    } while (!lastRound);
  }

  return Array.from(styles);
};

const setTextColor = (color: string, editorState: EditorState): EditorState =>  {

  const stylesInSelection = getInlineStylesInSelection(editorState);

  // toggle (disable) any custom color in the current selection
  let newState = stylesInSelection.reduce((state: EditorState, styleKey: string) => {
    if (styleKey.startsWith(CUSTOM_STYLE_PREFIX_COLOR)) {
      return RichUtils.toggleInlineStyle(state, styleKey);
    }

    return state;
  }, editorState);

  // Turn ON the new color
  if (color != null) {
    newState = RichUtils.toggleInlineStyle(newState, CUSTOM_STYLE_PREFIX_COLOR + color);
  }

  return newState;
};

const ColorPickerButton = (props: CustomPickerProps): React.JSX.Element => {
  const [showModal, setShowModal] = useState(false);

  const CustomizedModal = (modalProps: ToolbarChildrenProps) => {
    return (
      <ColorModal
        {...modalProps}
        {...props}
      />
    );
  };

  const onToggleModal = (event: React.MouseEvent) => {
    event.preventDefault();
    if (!showModal) {
      props.onOverrideContent(CustomizedModal as ComponentType<OverrideContentProps>);
    } else {
      props.onOverrideContent();
    }

    setShowModal(!showModal);
  };

  return (
    <div className={props.theme.buttonWrapper}>
      <button className={props.theme.button} onMouseDown={onToggleModal}>
        {props.customIcon ? (
          props.customIcon
        ) : (
          <FontColorsOutlined style={{ fontSize: 24 }}/>
        )}
      </button>
    </div>
  );
};


const createColorPickerPlugin = (): ColorPickerPlugin => {
  return {
    customStyleFn: (style/* , block */) => {
      const styleNames = style.toJS();
      const newNames = styleNames.reduce((styles: Record<string, string>, styleName: string) => {
        if (styleName.startsWith(CUSTOM_STYLE_PREFIX_COLOR)) {
          styles.color = styleName.split(CUSTOM_STYLE_PREFIX_COLOR)[1];
        }
        return styles;
      }, {});

      return newNames;
    },
    ColorPickerButton
  };
};

export default createColorPickerPlugin;
