import { Col, Row } from 'antd';
import { FormattedMessage } from 'react-intl';
import { GetUserTutorialsResponse } from '@kemu-io/kemu-types/dist/types';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import { AsyncRequestStatus } from '../../../types/core_t';
import { MARKETPLACE_CONFIG_NAME, MarketplaceCacheDefaultState, MarketplaceCacheState } from '../../layout/marketplace/marketplace';
import { fetchUserTutorials, fetchingState, userTutorialList } from '../../layout/marketplace/marketplaceSlice';
import { getStoredConfig, setStoredConfig } from '../../../common/utils';
import StyledButton from '../../form-control/styledButton/styledButton';
import TutorialCard from '../tutorialCard/tutorialCard';
import CardSkeleton from '../CardSkeleton/CardSkeleton';

const MyTutorials = (): React.JSX.Element => {
	const asyncState = useSelector(fetchingState);
	const fetchedTutorials = useSelector(userTutorialList);
	const [tutorials, setTutorials] = useState<GetUserTutorialsResponse>([]);
	const tutorialsCache = getStoredConfig<MarketplaceCacheState>(MARKETPLACE_CONFIG_NAME, MarketplaceCacheDefaultState);
	const dispatch = useDispatch();
	const [selectedIndex, setSelectedIndex] = useState(-1);

	const onLoadAgain = useCallback(() => {
		dispatch(fetchUserTutorials());
	}, [dispatch]);

	// Load things the very first time
	useEffect(() => {
		onLoadAgain();
	}, [onLoadAgain]);

	// Used to record the last number of fetched recipes so we can show skeletons of the same quantity
	useEffect(() => {
		if (tutorials.length !== tutorialsCache.myTutorials) {
			setStoredConfig<MarketplaceCacheState>(MARKETPLACE_CONFIG_NAME, { myTutorials: tutorials.length });
		}
	}, [tutorialsCache.myTutorials, tutorials.length]);

	useEffect(() => {
		if (fetchedTutorials) {
			setTutorials(fetchedTutorials);
		}
	}, [fetchedTutorials]);

	const onTutorialRemoved = useCallback((id: string) => {
		setTutorials(list => list.filter(item => item.id !== id));
		setSelectedIndex(-1);
	}, []);

	const cardSelected = useCallback((id: string) => {
		setSelectedIndex(tutorials.findIndex(t => t.id === id));
	}, [tutorials]);

	const onRenamed = useCallback((id: string, newTitle: string) => {
		setTutorials(list => list.map(item => {
			const newItem = { ...item };
			if (newItem.id === id) {
				newItem.title = newTitle;
				newItem.updatedAt = new Date().toJSON();
			}

			return newItem;
		}));
	}, []);


	return (
		<>
			{asyncState.status === AsyncRequestStatus.loading && (
				<CardSkeleton totalCards={tutorialsCache.myTutorials || 5} />
			)}

			{ (asyncState.status === AsyncRequestStatus.error) && (
				<Row>
					<Col span={6} offset={9} style={{ textAlign: 'center' }}>
						<p style={{ color: 'red' }}>{asyncState.error?.message}</p>
						<StyledButton onClick={onLoadAgain} title={<FormattedMessage id="Marketplace.RecipeList.TryAgainBtn" defaultMessage="Try Again" />} />
					</Col>
				</Row>
			)}

			{ asyncState.status === AsyncRequestStatus.completed && Array.isArray(tutorials) && (
				<>
					<Row gutter={{ xs: 0, sm: 12, md: 16, lg: 16, xl: 24 }}>
						{tutorials.map((tutorial, index) => {
							return (
								<Col key={tutorial.id} xs={24} sm={12} md={12} lg={8} xl={6} xxl={4}>
									<TutorialCard
										onRenamed={onRenamed}
										onSelected={cardSelected}
										onRemoved={onTutorialRemoved}
										selected={selectedIndex === index}
										{...tutorial}
									/>
								</Col>
							);
						})}
					</Row>
				</>
			)}
		</>
	);
};

export default MyTutorials;
