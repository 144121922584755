import './mainMenu.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { ReactElement, useCallback, useState } from 'react';
import { Menu, Layout } from 'antd';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import Icon, {
	FileOutlined,
	HomeOutlined,
	ShoppingCartOutlined,
} from '@ant-design/icons';
import { ReactSVG } from 'react-svg';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ThingCategory } from '@kemu-io/kemu-types/dist/types';
import LogoAndText from '../../assets/img/kemu-logo-text.png';
import Logo from '../../assets/img/kemu-logo.png';
import { ReactComponent as hardwareBlockIcon } from '../../assets/img/interface/hardware-block.svg';
import { ReactComponent as RecipeIcon } from '../../assets/img/menus/recipe.svg';
import { ReactComponent as TutorialsIcon } from '../layout/marketplace/MarketplaceMenu/tutorials.svg';
import { addBlock } from '../../features/Workspace/workspaceSlice';
import StatusIndicator from '../statusIndicator/statusIndicator';
import { getStoredConfig, setStoredConfig } from '../../common/utils';
import globals from '../../common/globals';
import routes from '../../common/routes';
import { HardwareBlockInfo, onlineBlocks as hardwareBlocks } from './mainMenuSlice';
import SaveMenuButton from './saveMenuButton/saveMenuButton';
import ThingsMenuButton from './thingsMenuButton/thingsMenuButton';
import AnnotationsMenu from './AnnotationsMenu/AnnotationsMenu';
import SaveAsMenuButton from './saveAsMenuButton/saveAsMenuButton';

const { Sider } = Layout;
interface Props {
	collapsed: boolean
}

interface MenuConfig {
	openedMenus: string[];
}

enum MarketplaceTarget {
	PublicRecipes,
	MyRecipes,
	MyTutorials,
}

const CONFIG_NAME = 'selectedMenus';
const defaultConfig: MenuConfig = {
	openedMenus: ['apps']
};


function MainMenu ({ collapsed }: Props): ReactElement {
	const dispatch = useDispatch();
	const hbList = useSelector(hardwareBlocks);
	const [openedItems, setOpenedItems] = useState<MenuConfig>(getStoredConfig(CONFIG_NAME, defaultConfig));
	const history = useHistory();

	// NOTE: Only 1 hardware block per type can be added to the canvas
	// Their id in the recipe will be the same id as in the database
	// FIXME: If the recipe is saved, this is likely going to fail since
	/// id's are re-generated.
	const addHardwareBlockHandler = useCallback((block: HardwareBlockInfo) => {
		dispatch(addBlock({ blockInfo: block.dbInfo, alowOnlyOneType: true }));
	}, [dispatch]);

	const openMarketplace = useCallback((target: MarketplaceTarget) => {
		target === MarketplaceTarget.PublicRecipes && history.push(routes.marketplace.getPublicationListRoute('recipe'));
		target === MarketplaceTarget.MyRecipes && history.push(routes.marketplace.getMyRecipesRoute());
		target === MarketplaceTarget.MyTutorials && history.push(routes.marketplace.getMyTutorialsRoute());
	}, [history]);

	const createNewRecipe = useCallback(() => {
		window.open(`https://${globals.KEMU_APP_DOMAIN}`, '_blank');
	}, []);


	const iconStyle = { fontSize: collapsed ? 26 : 20 };
	const onSVGInjection = (svg: SVGElement, category: ThingCategory) => {
		let size = iconStyle.fontSize;
		if (category === ThingCategory.Virtual) { size = 20; }
		svg.setAttribute('style', `width: ${size}px; height: ${size}px`);
	};

	const getDynamicSVG = (icon: string, category: ThingCategory) => {
		return () => <ReactSVG src={icon} wrapper="span" beforeInjection={svgEl => onSVGInjection(svgEl, category)}/>;
	};

	const getHbSubItems = () => {
		return (
			hbList.map((block, index) => (
				<Menu.Item
					data-kemu-meta={`thing-type-${block.dbInfo.id}`}
					className="main-menu-item custom-menu-item"
					onClick={() => addHardwareBlockHandler(block)}
					title={!collapsed ? block.dbInfo.name : ''}
					key={`${block.dbInfo.type}_${index}`} icon={<Icon component={getDynamicSVG(block.icon, block.dbInfo.category)} />}
				>
					{block.dbInfo.name}
				</Menu.Item>
			))
		);
	};


	const onOpenChange = (keys: React.ReactText[]) => {
		// when the menu collapses, keys is an empty array and it would delete everything 
		if (!collapsed) {
			setOpenedItems({
				openedMenus: keys as string[]
			});

			setStoredConfig<MenuConfig>(CONFIG_NAME, {
				openedMenus: keys as string[]
			} as MenuConfig, true);
		}
	};

	const inDevServer = window.location.host.includes('dev.kemu.io');
	return (
		<Sider width={250} trigger={null} collapsible collapsed={collapsed} className={`main-sidebar ${collapsed ? 'collapsed' : ''}`}>
			<div className="sidebar-container">
				<div className="sidebar-brand">
					<a href="/">
						{!collapsed ? (
							<img alt="image" src={LogoAndText} className="header-logo" />
						) : (
							<img alt="image" src={Logo} className="header-logo-collapsed" />
						)}
					</a>
					<span className="beta-span">BETA</span>
				</div>
				<PerfectScrollbar>
					<Menu onOpenChange={onOpenChange} triggerSubMenuAction="click" defaultOpenKeys={!collapsed ? openedItems.openedMenus: []}  mode="inline" className="sidebar-menu" selectable={false} subMenuCloseDelay={0.35}>

						{/* Settings */}
						<Menu.SubMenu data-menu-name="home" popupClassName="sub-menu-popup" key="home" icon={<HomeOutlined style={iconStyle} />} title={<FormattedMessage id="Menu.Home" defaultMessage="Home" />} className="sub-menu custom-icon">
							{ !collapsed && (
								<SaveMenuButton isCollapsed={collapsed} />
							)}
							<Menu.Item data-kemu-meta="create-new-item" className="main-menu-item"  key="new-recipe" icon={<FileOutlined />} onClick={createNewRecipe}><FormattedMessage id="Menu.NewRecipe" defaultMessage="New Recipe"/></Menu.Item>
							<SaveAsMenuButton />
							<Menu.Item data-kemu-meta="my-recipes-item" className="main-menu-item custom-icon"  key="k4" icon={<Icon component={RecipeIcon} />} onClick={() => openMarketplace(MarketplaceTarget.MyRecipes)}><FormattedMessage id="Menu.MyRecipes" defaultMessage="My Recipes"/></Menu.Item>
							<Menu.Item data-kemu-meta="my-tutorials-item" className="main-menu-item custom-icon"  key="k5" icon={<Icon component={TutorialsIcon} />} onClick={() => openMarketplace(MarketplaceTarget.MyTutorials)}><FormattedMessage id="Menu.MyTutorials" defaultMessage="My Tutorials"/></Menu.Item>
							{/* <Menu.Item className="main-menu-item"  key="k6" icon={<CloudDownloadOutlined />}><FormattedMessage id="Menu.DownloadRecipe" defaultMessage="Download Copy"/></Menu.Item> */}
							<Menu.Item
								data-kemu-meta="marketplace-item"
								className="main-menu-item"
								key="k7"
								icon={<ShoppingCartOutlined />}
								onClick={() => openMarketplace(MarketplaceTarget.PublicRecipes)}
							>
								<FormattedMessage id="Menu.Marketplace" defaultMessage="Marketplace"/>
							</Menu.Item>
						</Menu.SubMenu>

						{/* Kemu Apps list */}
						<ThingsMenuButton collapsed={collapsed} />


						{/* Hardware blocks list */}
						{ !collapsed ? (
							<>
								{/* Annotations icon */}
								<AnnotationsMenu isCollapsed={collapsed} />
								{/* In expanded mode items appear as sub menu items */}
								<Menu.SubMenu popupClassName="sub-menu-popup" key="hardware" icon={<Icon component={hardwareBlockIcon} style={iconStyle}/> } title={<FormattedMessage id="Menu.Hardware.Title" defaultMessage="Hardware Devices" />} className="sub-menu custom-icon">
									{ getHbSubItems() }
								</Menu.SubMenu>
							</>
						) : (
							<>
								<SaveMenuButton isCollapsed={collapsed} />
								{/* Annotations icon */}
								<AnnotationsMenu isCollapsed={collapsed} />

								{hbList.length > 0 && (<li className="menu-separator" />)}

								{ getHbSubItems() }
							</>
						)}
					</Menu>
				</PerfectScrollbar>

				<StatusIndicator />
				<span className={`version-box ${inDevServer ? 'staging' : ''}`}>v{globals.WEB_APP_VERSION}</span>
			</div>
		</Sider>
	);
}


export default MainMenu;
