import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-named-as-default
import QRCode from 'qrcode.react';
import { Spin, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import StandardModal from '../modal/standardModal';
import { setTwilioConnectionToken, connectedPhone } from '../../features/interface/interfaceSlice';
import { clearWebRTCSession, webRTCSession } from '../../app/reducers/user/userSlice';
import { AsyncRequestStatus } from '../../types/core_t';
import Logger from '../../common/logger';
import { fetchWebRTCSessionTokenAction } from '../../app/reducers/user/fetchWebRTCTokenReducer';
import { UserSessionContext } from '../../common/context/UserSessionContext/UserSessionContext';
import styles from './qrCodeModal.module.css';

const logger = Logger('qrCodeModal');
interface Props {
	visible: boolean;
	onCancel?: ()=> void;
}


function QRCodeModal (props: Props): ReactElement {
	const dispatch = useDispatch();
	const { user } = useContext(UserSessionContext);
	const connectedDevice = useSelector(connectedPhone);
	const webSessionToken = useSelector(webRTCSession);
	const intl = useIntl();
	const [qrCode, setQrCode] = useState('');
	const { onCancel, visible } = props;

	const closeBtnLabel = intl.formatMessage({ id: 'QRCode.Modal.CloseBtn', defaultMessage: 'Close' });

	const handleClose = useCallback(() => {
		onCancel && onCancel();
	}, [onCancel]);

	useEffect(() => {
		if (connectedDevice) {
			console.log('Device connected!');
			handleClose();
		}
	}, [connectedDevice, handleClose]);

	const fetchNow = useCallback(async () => {
		if (!user) { return; }
		logger.log('Dispatching updated user: ', user);
		dispatch(fetchWebRTCSessionTokenAction());
	}, [dispatch, user]);

	const handleModalClosed = () => {
		logger.log('Closed!');
		dispatch(clearWebRTCSession());
	};

	useEffect(() => {
		if (webSessionToken.asyncState.status === AsyncRequestStatus.completed && webSessionToken.token) {
			logger.log('Setting QR code clients session');
			setQrCode(webSessionToken.token.client);
			dispatch(setTwilioConnectionToken(webSessionToken.token.browser));
		} else if (webSessionToken.asyncState.status === AsyncRequestStatus.error) {
			handleClose();
			message.error({
				content: webSessionToken.asyncState.error?.message ,
				duration: 3,
				key: 'qrSessionError'
			});
		} else if (webSessionToken.asyncState.status === AsyncRequestStatus.idle && visible) {
			fetchNow();
		}
	}, [webSessionToken.asyncState, webSessionToken.token, dispatch, handleClose, fetchNow, visible]);

	const loadingIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

	return (
		<StandardModal onAfterClosed={handleModalClosed} visible={props.visible} className={styles.Modal} okBtnLabel={closeBtnLabel} onOk={handleClose} width={300}>
			{webSessionToken.asyncState.status === AsyncRequestStatus.completed ? (
				<div className={styles.ModalContent}>
					<p>
						<FormattedMessage defaultMessage="Open the Kemu app in your phone and scan this image" id="QRCode.Modal.Title" />
					</p>

					<QRCode
						bgColor="#FFFFFF"
						fgColor="#000000"
						level="Q"
						style={{ width: 200, height: 'auto' }}
						value={qrCode}
					/>
				</div>
			) : (
				// Loading
				<Spin size="large" className={styles.Spinner} tip="Loading..." indicator={loadingIcon}/>
			)}

		</StandardModal>
	);
}

export default QRCodeModal;
