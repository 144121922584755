import React, { useMemo } from 'react';
import Editor, { composeDecorators } from '@draft-js-plugins/editor';
import createAlignmentPlugin from '@draft-js-plugins/alignment';
import createFocusPlugin from '@draft-js-plugins/focus';
import '@draft-js-plugins/inline-toolbar/lib/plugin.css';
import 'draft-js/dist/Draft.css';
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
	CodeButton,
	UnorderedListButton,
  OrderedListButton,
  BlockquoteButton,
} from '@draft-js-plugins/buttons';
import { EditorState } from 'draft-js';
import HeaderPicker from '../plugins/headerPicker/headerPicker';
import '@draft-js-plugins/alignment/lib/plugin.css';
import { uploadTutorialFile } from '../../../api/tutorial/tutorialApi';


// Custom plugins
import createSideToolbarPlugin from '../plugins/sideToolbar';
import createImagePlugin, { UploadFileHandler } from '../plugins/image';
import createColorPicker from '../plugins/colorPicker';
import createInlineToolbarPlugin from '../plugins/inlineToolbar';
import createVideoPlugin from '../plugins/video';
import createLinkPlugin from '../plugins/link';
import blockTypeSelectStyles from './sideBarStyles.module.css';
import alignmentToolStyles from './alignmentToolStyles.module.css';
import toolbarStyles from './toolbarStyles.module.css';
import buttonStyles from './buttonStyles.module.css';

interface Props {
	tutorialId: string;
	userId: string;
	readOnly: boolean;
	editorState: EditorState;
	updateEditorState: (newState: EditorState) => void;
}


const buildPlugins = (tutorialId: string, userId: string) => {
	const sideToolbarPlugin = createSideToolbarPlugin();
	const inlineToolbarPlugin = createInlineToolbarPlugin({
		theme: { buttonStyles, toolbarStyles },
	});

	const focusPlugin = createFocusPlugin();
	const alignmentPlugin = createAlignmentPlugin({
		theme: { buttonStyles, alignmentToolStyles: { alignmentTool: alignmentToolStyles.alignmentTool } },
	});


	const colorPickerPlugin = createColorPicker();
	const linkPlugin = createLinkPlugin();


	const decorator = composeDecorators(
		alignmentPlugin.decorator,
		focusPlugin.decorator,
		// resizablePlugin.decorator
	);

	/**
	 * Uploads the files contents (in base64) to permanent storage
	 * @returns a read URL to access the contents
	 */
	const handleImageUpload: UploadFileHandler = async (fileContents: ArrayBuffer, mimeType: string) => {
		const response = await uploadTutorialFile(tutorialId, fileContents, mimeType, upload => console.log('Uploading ', upload.percentage));
		return response;
	};

	const imagePlugin = createImagePlugin({ decorator, handleUpload: handleImageUpload, userId, tutorialId });
	const videoPlugin = createVideoPlugin({ decorator });

	const { InlineToolbar } = inlineToolbarPlugin;
	const { SideToolbar } = sideToolbarPlugin;
	const { ImageButton } = imagePlugin;
	const { AlignmentTool } = alignmentPlugin;
	const { ColorPickerButton } = colorPickerPlugin;
	const { VideoButton } = videoPlugin;
	const { LinkButton } = linkPlugin;

	const plugins = [
		inlineToolbarPlugin,
		sideToolbarPlugin,
		imagePlugin,
		focusPlugin,
		alignmentPlugin,
		// resizablePlugin,
		colorPickerPlugin,
		videoPlugin,
		linkPlugin
	];

	return {
		InlineToolbar,
		SideToolbar,
		ImageButton,
		AlignmentTool,
		ColorPickerButton,
		VideoButton,
		LinkButton,
		plugins
	};
};


const LessonEditor = (props: Props): React.JSX.Element => {
	const { editorState, updateEditorState, tutorialId, userId } = props;
	const {
		InlineToolbar,
		SideToolbar,
		ImageButton,
		AlignmentTool,
		ColorPickerButton,
		VideoButton,
		LinkButton,
		plugins
	} = useMemo(() => buildPlugins(tutorialId, userId), [tutorialId, userId]);


	return (
		<>
			<Editor
				editorState={editorState}
				onChange={updateEditorState}
				plugins={plugins}
				readOnly={props.readOnly}
			/>

			{!props.readOnly && (
				<AlignmentTool />
			)}

			{/* Plus button (left side) */}
			{!props.readOnly && (
				<SideToolbar>
					{
						(externalProps) => {
							return (
							<div className={blockTypeSelectStyles.ButtonsContainer}>
								<ImageButton {...externalProps} />
								<VideoButton {...externalProps}/>
							</div>
							);
						}
					}
				</SideToolbar>
			)}

			{/* Inline toolbar (shows up when words are selected) */}
			<InlineToolbar >
				{
					(externalProps) => {
						return (
						<>
							<BoldButton {...externalProps} />
							<ItalicButton {...externalProps} />
							<UnderlineButton {...externalProps} />
							<CodeButton {...externalProps} />
							<HeaderPicker {...externalProps} />
							<UnorderedListButton {...externalProps} />
							<OrderedListButton {...externalProps} />
							<BlockquoteButton {...externalProps} />
							<ColorPickerButton {...externalProps} />
							<LinkButton {...externalProps} />
						</>
						);
					}
				}
			</InlineToolbar>
		</>
	);
};

export default LessonEditor;
