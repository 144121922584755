
import React, { useRef } from 'react';
import Icon from '@ant-design/icons';
import ObjectWidgetProcessor, {
	ObjectWidgetState,
	getDefaultState,
	getDefaultValueForType,
} from '@kemu-io/kemu-core/dist/gates/object';
import { CustomWidgetState, DataType, WidgetPortContext } from '@kemu-io/kemu-core/dist/types/gate_t';
import { message, Menu, Dropdown } from 'antd';
import classNames from 'classnames';
import useReactiveWidgetState from '../../../common/hooks/useReactiveWidgetState';
import {
	GateCustomSettingsProps,
	GetPortsInformationFunction,
	GateUI,
	GateUIProps
} from '..';
import GateIcon from '../../gateIcon/gateIcon';
import useTranslation from '../../../common/hooks/useTranslation';
import NumericInput from '../../WidgetsComponents/NumericInput/NumericInput';
import { PortLocation } from '../../../types/canvas_t';
import { getLastInputPortWithParent, LastPortInfo } from '../../../common/utils';
import KemuSwitch from '../../form-control/kemuSwitch/kemuSwitch';
import { SETTINGS_CONTAINER_CLASS } from '../../../common/constants';
import { ReactComponent as JsonWidgetIcon } from './icon.svg';
import { ReactComponent as NumberIcon } from './numberIcon.svg';
import { ReactComponent as StringIcon } from './stringIcon.svg';
import { ReactComponent as ArrayIcon } from './arrayIcon.svg';
import { ReactComponent as ImageIcon } from './imageIcon.svg';
import styles from './object.module.css';


type Props = GateUIProps


const ObjectWidget = (props: Props): React.JSX.Element => {
	const [state, setState] = useReactiveWidgetState<ObjectWidgetState>(props.recipeId, props.thingRecipeId, props.info.id);
	const selectedPropRef = useRef<number>(0);

	const fixedState = {
		...getDefaultState(),
		...state
	};

	const t = useTranslation('LogicMaker.Gates.Object');
	const namePlaceholder = t('NamePlaceholder', 'Enter name');
	const valuePlaceholder = t('ValuePlaceholder', 'Default value');

	const updatePropertyChange = (propName: 'name' | 'value', index: number, value: string) => {
		const properties = [...fixedState.properties];
		if (properties[index] !== undefined) {
			// IMPORTANT: We create a new object to avoid potentially mutating the state reference in the recipe
			// held internally by `useReactiveWidgetState`.
			properties[index] = {
				...properties[index],
				[propName]: value
			};

			setState({
				...fixedState,
				properties
			});
		}
	};

	const captureCurrentItem = (index: number) => {
		selectedPropRef.current = index;
	};


	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onUpdateType = ({ key }: any) => {
		const properties = structuredClone([...fixedState.properties]);
		const index = selectedPropRef.current;
		if (properties[index] !== undefined) {
			properties[index].type = Number(key);
			properties[index].value = getDefaultValueForType(Number(key))!;
			setState({
				...fixedState,
				properties
			});
		}
	};


	const typesMenu = (
		<Menu className={styles.TypesMenu} onClick={onUpdateType}>
			<Menu.Item key={DataType.Number}>
				<span><Icon component={NumberIcon} /></span> {t('Types.Number', 'Number')}
			</Menu.Item>
			<Menu.Item  key={DataType.String}>
				<span><Icon component={StringIcon} /></span> {t('Types.String', 'String')}
			</Menu.Item>
			<Menu.Item  key={DataType.Array}>
				<span><Icon component={ArrayIcon} /></span> {t('Types.Array', 'Array')}
			</Menu.Item>
			<Menu.Item  key={DataType.JsonObj}>
				<span><Icon component={JsonWidgetIcon} /></span> {t('Types.Object', 'Object')}
			</Menu.Item>
			<Menu.Item  key={DataType.ImageData}>
				<span><Icon component={ImageIcon} /></span> {t('Types.Image', 'Image')}
			</Menu.Item>
		</Menu>
	);

	const editableTypes = [DataType.Number, DataType.String];
	return (
		<div className={`${styles.GateBody}`}>
			{fixedState.properties.map((property, i) => (
				<div key={i} className={styles.PropItem}>
					<div className={classNames('gate-input name', { 'has-value': editableTypes.includes(property.type) })}>
						<input
							type='text'
							placeholder={namePlaceholder}
							value={property.name}
							onChange={evt => updatePropertyChange('name', i, evt.currentTarget.value)}
						/>
					</div>

					{editableTypes.includes(property.type) && (
						<div className='gate-input value'>
							<input
								type='text'
								placeholder={valuePlaceholder}
								value={String(property.value)}
								onChange={evt => updatePropertyChange('value', i, evt.currentTarget.value)}
							/>
						</div>
					)}

					<Dropdown overlay={typesMenu} placement="bottomLeft" trigger={['click']} >
						<span className={classNames(styles.ChooseType, `type-${property.type}`)} onClick={() => captureCurrentItem(i)}>
							{property.type === DataType.Number && (
								<Icon component={NumberIcon}/>
							)}

							{property.type === DataType.String && (
								<Icon component={StringIcon} />
							)}

							{property.type === DataType.Array && (
								<Icon component={ArrayIcon} />
							)}

							{property.type === DataType.JsonObj && (
								<Icon component={JsonWidgetIcon} />
							)}

							{property.type === DataType.ImageData && (
								<Icon component={ImageIcon} />
							)}
						</span>

					</Dropdown>
				</div>
			))}
		</div>
	);
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={JsonWidgetIcon} />}/>
	);
};

const GateCustomSettings = (props: GateCustomSettingsProps): React.JSX.Element => {
	const [state, setState] = useReactiveWidgetState<ObjectWidgetState>(props.recipeId, props.blockId, props.gateInfo.id);
	const fixedState = {
		...getDefaultState(),
		...state
	};

	const t = useTranslation('LogicMaker.Gates.Object.Settings');
	// const defaultPropPrefix = 'property';


	const warnIfInputAttached = (info: LastPortInfo | null, minIndex: number): boolean => {
		// Prevent adding ports if trigger is linked (otherwise new ports will obtain the connection from trigger)
		if (info && info.index >= minIndex || info?.portName === 'trigger') {
			message.warning({
				content: t('RemoveWarning', 'Please remove all connections from port "{name}"',
				{ name: info.portName })
			});
			return true;
		}

		return false;
	};

	const getLastConnectedInput = () => {
		return getLastInputPortWithParent(
			fixedState,
			ObjectWidgetProcessor,
			props.gateInfo.id,
			props.blockId,
			props.recipeId,
			props.recipeType
		);
	};

	const handleInputChange = (value: number) => {
		const lastPortWithConnection = getLastConnectedInput();
		if (warnIfInputAttached(lastPortWithConnection, value)) { return ; }

		let remainingProps = [...fixedState.properties];
		if (value < fixedState.properties.length) {
			remainingProps = remainingProps.slice(0, value);
		} else if (value > fixedState.properties.length) {
			remainingProps = [
				...remainingProps,
				...Array.from(Array(value - remainingProps.length).keys()).map(() => {
					return {
						name: `${String.fromCharCode(65 + value - 2 + 32)}`,
						type: DataType.Number,
						value: getDefaultValueForType(DataType.Number) || 0
					};
				})
			];
		}


		setState({
			...fixedState,
			properties: remainingProps,
		}, true);
	};

	const handleSwitchChange = (checked: boolean) => {
		const lastPortWithConnection = getLastConnectedInput();
		if (warnIfInputAttached(lastPortWithConnection, fixedState.properties.length)) { return ; }

		setState({ ...fixedState, useTriggerPort: checked });
	};

	return (
		<div className={classNames(styles.SettingsContainer, SETTINGS_CONTAINER_CLASS)}>
			{props.children}
			<div className={styles.InputContainer}>
				<label>{t('Title', 'Properties')}</label>
				<NumericInput
					min={1}
					max={20}
					value={fixedState.properties.length}
					onChange={handleInputChange}
				/>
			</div>
			<div className={styles.SwitchContainer}>
				<label>{t('UseTriggerPort', 'Trigger port')}</label>
				<KemuSwitch
					size="small"
					checked={fixedState.useTriggerPort}
					onChange={handleSwitchChange}
				/>
			</div>
		</div>
	);
};

const getPortsInformation: GetPortsInformationFunction = (state: CustomWidgetState<ObjectWidgetState>, widgetInfo) => {
	const portContext: WidgetPortContext = { recipePoolId: widgetInfo.recipePoolId, recipeType: widgetInfo.recipeType };
	const outputNames = ObjectWidgetProcessor.getOutputNames(state, portContext);
	const inputNames = ObjectWidgetProcessor.getInputNames(state, portContext);

	const portSize = 0.02;
	const topPadding = inputNames.length <= 12 ? 0.15 : 0.05;
	const inputFraction = ((1 - (topPadding + portSize)) / inputNames.length);

	const getPositionFromIndex = (index: number): PortLocation => {
		let space = (inputFraction * index) + topPadding;
		const totalPorts = state.properties.length + (state.useTriggerPort ? 1 : 0);
		if (totalPorts === 1) { space = 0.5; }
		if (totalPorts === 2) { space = 0.30 + (0.4 * index); }
		if (totalPorts === 3) { space = 0.25 + (0.25 * index); }
		if (totalPorts === 4) { space = 0.27 + (0.17 * index); }
		if (totalPorts === 5) { space = 0.24 + (0.14 * index); }
		if (totalPorts === 6) { space = 0.20 + (0.13 * index); }

		return [0, space, -1, 0];
	};

	return {
		outputs: [{
			position: 'Right',
			name: outputNames[0].name,
			type: outputNames[0].type,
			jsonShape: outputNames[0].jsonShape
		}],

		inputs: inputNames.map((input, i) => {
			return  {
				name: input.name,
				type: input.type,
				position: getPositionFromIndex(i)
			};
		})
	};
};

export default {
	getPortsInformation,
	BarIcon: GateBarIcon,
	Element: ObjectWidget,
	CustomSettingsDialog: GateCustomSettings,
	hasTitle: true,
	getWidgetTitle: (intl) => intl.formatMessage({ id: 'LogicMaker.Gates.Object.Title', defaultMessage: 'Object' }),
} as GateUI;
