import { PublicationState } from '@kemu-io/kemu-types/dist/types';
import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import * as marketplaceApi from '../../../../api/marketplace/marketplaceApi';
import { AsyncRequestStatus } from '../../../../types/core_t';
import { SetReviewDecisionResponse } from '../../../../types/marketplace';
import { MarketplaceState } from '../marketplaceSlice';

export const submitPublicationReviewAction = createAsyncThunk('/marketplace/submitPublicationReview', async (
	config: { publicationId: string, state: PublicationState, message: string }
): Promise<SetReviewDecisionResponse> => {
	const response = await marketplaceApi.submitReviewDecision(config.publicationId, config.state, config.message);
	return response;
});

export const submitPublicationReviewReducer = ((builder: ActionReducerMapBuilder<MarketplaceState>/*, thunk: any*/): void => {
	builder.addCase(submitPublicationReviewAction.pending, (state) => {
		state.reviewingPublication.error = undefined;
		state.reviewingPublication.status = AsyncRequestStatus.loading;
	});

	builder.addCase(submitPublicationReviewAction.fulfilled, (state, action: PayloadAction<SetReviewDecisionResponse>) => {
		state.reviewingPublication.status = AsyncRequestStatus.completed;
		const updatedEntity = state.publicRecipeList.find(publication => publication.id === action.payload.entity.id);
		const filteredOut = state.publicRecipeList.filter(publication => publication.id !== action.payload.entity.id);
		// Update the current list of recipes
		if (updatedEntity) {
			state.publicRecipeList = [
				...filteredOut,
				{
					...action.payload.entity,
					ratings: updatedEntity.ratings
				}
			];
		}
	});

	builder.addCase(submitPublicationReviewAction.rejected, (state, action) => {
		state.reviewingPublication.status = AsyncRequestStatus.error;
		state.reviewingPublication.error = action.error;
	});
});
