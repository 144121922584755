import blocksManager, { MinimumBlockInfo } from '@kemu-io/kemu-core/dist/blocks/manager';
import { LimitedThingInfo } from '@kemu-io/kemu-types/dist/types';
import Logger from '@common/logger';
import { BlockInstallationProcess } from '@src/features/Workspace/workspaceSlice';

const logger = Logger('thingReducerHelpers');

/**
 * Downloads and installs thing bundles in parallel. Things that are already installed are omitted
 * @param things a map of things to download, where the key is the block's id in the recipe + version. Eg: block.id + "_" + block.version.
 * (Realistically, any unique identifier would do)
 * @param onProgress a function to invoke with information about the bundle download process
 */
export const downloadThingBundlesInParallel = async (things: Record<string, LimitedThingInfo>, onProgress: (blockId: string, progress: BlockInstallationProcess)=> void): Promise<PromiseSettledResult<MinimumBlockInfo>[]> => {
	const downloadsInProgress: Promise<MinimumBlockInfo>[] = [];
	for (const thingId in things) {
		const thing = things[thingId];
		let forceReInstall = false;
		if (typeof localStorage !== undefined && localStorage.getItem('enable-vb-caching') === 'false') {
			forceReInstall = true;
		}


		const p = blocksManager.downloadThing(thing, 'web', forceReInstall, (download) => {
			onProgress(thingId, {
				block: thing,
				progress: download,
			});
		})
		.catch(e => {
			const errMsg = typeof e === 'string' ? e : e.message ? e.message : 'unknown';
			logger.log(`Failed to download thing ${thing.name}: ${errMsg}`);

			onProgress(thingId, {
				block: thing,
				errorMsg: errMsg
			});

			return Promise.reject(errMsg);
		});

		downloadsInProgress.push(p);
	}

	const results = await Promise.allSettled(downloadsInProgress);
	return results;
};

/**
 * @param thingId the id of the thing in the database.
 */
export const removeThingVersionsFromCache = async (thingId: string): Promise<void> => {
	await blocksManager.uninstallAllVersions(thingId);
};

/**
 * Checks if there is any other version that is compatible with the given version.
 * and returns it, otherwise returns the given version.
 */
export const getCompatibleVersionInstalled = async (thingId: string, version: string) => {
	const compatibleVersion = await blocksManager.getCompatibleVersionInstalled(thingId, version);
	return compatibleVersion;
};
