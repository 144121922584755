/*
 * Written by Alexander Agudelo < alex@kemu.io >, 2022
 * Date: 27/Jan/2022
 * Last Modified: 27/01/2022, 9:28:09 am
 * Modified By: Alexander Agudelo
 * Description:  This is an API used by certain Things (Eg. EmailSender) to produce http requests
 * with an Authorization header that contains the user's JWT.
 * 
 * ------
 * Copyright (C) 2022 Kemu - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential.
 */

import { API } from '@aws-amplify/api';
import { AxiosResponse } from 'axios';
import globals from '../../common/globals';
import { SecureAPI } from '../../types/canvas_t';

const returnAxiosResponse = { response: true };
const API_NAME = globals.AUTHENTICATED_API_NAME;

const post = async (path: string, params?: Record<string, unknown>): Promise<AxiosResponse> => {
	const response = await API.post(API_NAME, path, { ...returnAxiosResponse, ...params });
	return response;
};

const get = async (path: string, params?: Record<string, unknown>): Promise<AxiosResponse> => {
	const response = await API.get(API_NAME, path, { ...returnAxiosResponse, ...params });
	return response;
};

const put = async (path: string, params?: Record<string, unknown>): Promise<AxiosResponse> => {
	const response = await API.put(API_NAME, path, { ...returnAxiosResponse, ...params });
	return response;
};

const patch = async (path: string, params?: Record<string, unknown>): Promise<AxiosResponse> => {
	const response = await API.patch(API_NAME, path, { ...returnAxiosResponse, ...params });
	return response;
};

const del = async (path: string, params?: Record<string, unknown>): Promise<AxiosResponse> => {
	const response = await API.del(API_NAME, path, { ...returnAxiosResponse, ...params });
	return response;
};


const api: SecureAPI = {
	get,
	post,
	put,
	patch,
	delete: del,
};

export default api;
