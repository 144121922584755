import {
  CustomWidgetDbEntity,
  CustomWidgetPortInfo,
  WidgetMetaInfo,
  BaseWidgetInfo,
  CustomWidgetVariant,
} from '@kemu-io/kemu-types/dist/types';
import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import widgetBundleManager, { ValidatedBundle } from '@kemu-io/kemu-core/dist/widgetBundle/manager';
import { findWidgetInRecipe } from '@kemu-io/kemu-core/dist/common/recipeCache';
import { removePrivateProperties } from '@kemu-io/kemu-core/dist/common/utils';
import { ArrayBuffer as MD5ArrayBuffer } from 'spark-md5';
import { CustomWidgetState, WidgetBundlePort, WidgetBundleState } from '@kemu-io/kemu-core/dist/types';
import kemuCore from '@kemu-io/kemu-core/dist/index';
// import widgetCache from './widgetCache';
import { WidgetCollectionState } from './widgetSlice';
import { widgetBundleStatePortToPortInfo } from './utils';
import * as widgetApi from '@src/api/widget/widgetApi';
import { uploadFile } from '@src/api/utils';
import { AsyncRequestStatus } from '@src/types/core_t';
import { InvalidSaveWidgetResponse } from '@src/types/errors_t';
import { getGlobalIntl } from '@common/translations';
import { portTypeToString } from '@common/utils';
import { showCreateWidgetBundleModalAction } from '@src/features/LogicMapper/logicMapperSlice';

type SaveBundleInCollectionActionPayload = {
  recipePoolId: string;
  /** the id of the widget used for storage, either the local id or db id */
  widgetThingId: string;
  thingId: string;
	zipHandler: ValidatedBundle['zipHandler'];
	name: string;
  description: string;
  color: string;
  icon: string;
}


/**
 * Saves the given widget bundle in the database and uploads the .zip file to S3.
 * It also adds the widget to the local cache.
*/
export const saveBundleInCollectionAction = createAsyncThunk('/widgets/saveBundleInCollectionAction', async (
	payload: SaveBundleInCollectionActionPayload,
  thunkApi
): Promise<CustomWidgetDbEntity> => {

	const t = getGlobalIntl();
  const widgetId = payload.widgetThingId;

  const currentWidget = findWidgetInRecipe(payload.recipePoolId, payload.thingId, widgetId);
  if (!currentWidget) {
    throw new Error(`Could not find widget with id ${widgetId}`);
  }

  const currentWidgetState = {
    ...currentWidget.state as unknown as WidgetBundleState,
		// Update the widget state with the new properties
		description: payload.description,
    name: payload.name,
    color: payload.color,
    icon: payload.icon,
  };

  if (!currentWidgetState.$$cacheInfo) {
    throw new Error(`Missing cache info for ${widgetId}`);
  }

	if (!currentWidgetState.$$processor) {
    throw new Error(`Missing processor for ${widgetId}. You need to 'save' the widget first.`);
  }

  // 1) Create a zip file from the widget storage location
  // const { zipHandler } = await widgetBundleManager.recreateBundleFromStorage(
  //   widgetId,
  //   currentWidgetState.$$cacheInfo?.version,
  //   // FIXME: This will break if the widget was loaded from a cached bundle
  //   true
  // );


  // 2) Edit the zip file and add the current state of the widget to the zip file (in memory)
  const stateWithoutPrivateProps = removePrivateProperties<WidgetBundleState>(currentWidgetState);
  const { newZipFile } = await widgetBundleManager.replaceStateInZip(
    payload.zipHandler,
    stateWithoutPrivateProps,
    {
      noGenerateFile: false,
    }
  );

  if (!newZipFile) {
    throw new Error(`Failed to replace state in zip file`);
  }

  const widgetMeta: WidgetMetaInfo = {
		contentsLength: newZipFile.byteLength,
		contentsChecksum: MD5ArrayBuffer.hash(newZipFile)
	};

	const widgetInputs = widgetBundleStatePortToPortInfo(currentWidgetState.inputs, t);
	const widgetOutputs = widgetBundleStatePortToPortInfo(currentWidgetState.outputs, t);

	const widgetInfo: BaseWidgetInfo = {
		name: currentWidgetState.name,
		htmlDescription: currentWidgetState.description,
		color: currentWidgetState.color,
		icon: currentWidgetState.icon,
		meta: widgetMeta,
		widgets: [],
		inputs: widgetInputs,
		outputs: widgetOutputs,
		variant: CustomWidgetVariant.Bundle
	};

  // 3) Create a widget record in the DB
	const response = await widgetApi.saveNewWidget(widgetInfo);


	if (!response.uploadUrl) {
		throw new Error(`Fatal Error ${InvalidSaveWidgetResponse[0]}`);
	}

	// 4) Upload widget contents to S3
	await uploadFile(response.uploadUrl, newZipFile);

  // 5) Store the widget locally using the new cloud id, this will allow users to add the widget from the launcher.
  // IMPORTANT: here we do not saved the widget in the standard widget cache, 
  // instead the bundle manager provides a method to install zip files directly.
	await widgetBundleManager.installWidgetBundle(response.widget.id, response.widget.version, {
		contents: newZipFile,
		// Install and also add to local cache
		cacheLocally: {
			dbId: response.widget.id,
			processor: currentWidgetState.$$processor,
			protocolVersion: response.widget.protocolVersion,
			version: response.widget.version,
			storedState: stateWithoutPrivateProps,
		}
	});

  // Update the new collection info in the running widget's state
  const updatedState: CustomWidgetState<WidgetBundleState> = {
    ...currentWidgetState,
    $$collectionInfo: {
      ...currentWidgetState.$$collectionInfo,
      version: response.widget.version,
      widgetId: response.widget.id,
      userId: response.widget.userId,
    }
  };

  // Update state in the recipe and notify watchers (this should force the canvas instance to re-render)
  kemuCore.setGateState(payload.recipePoolId, payload.thingId, widgetId, updatedState, true);

  // 6) Close dialog
  thunkApi.dispatch(showCreateWidgetBundleModalAction(false));

	return {
    ...response.widget,
  };
});


export const saveWidgetBundleReducer = ((builder: ActionReducerMapBuilder<WidgetCollectionState>/*, thunk: any*/): void => {

	builder.addCase(saveBundleInCollectionAction.pending, (state) => {
		const t = getGlobalIntl();
		state.asyncIndicator = {
			title: t('Widget.AsyncIndicator.Progress', '{action} Widget...', { action: 'Saving' }),
			asyncStatus: {
				status: AsyncRequestStatus.loading,
				error: undefined,
			}
		};
	});

	builder.addCase(saveBundleInCollectionAction.rejected, (state, action) => {
		const t = getGlobalIntl();
		state.asyncIndicator = {
			title: t('Widget.AsyncIndicator.Failure', 'Failed to {action} widget: ', { action: 'save' }),
			asyncStatus: {
				status: AsyncRequestStatus.error,
				error: action.error,
			}
		};
	});

	builder.addCase(saveBundleInCollectionAction.fulfilled, (state, action: PayloadAction<CustomWidgetDbEntity>) => {
		const t = getGlobalIntl();
		state.selectedWidget = action.payload.id;

		// Add new widget to local collection
		state.customWidgets = [
			...state.customWidgets,
			{
				dbId: action.payload.id,
				description: action.payload.htmlDescription,
				isDefault: !!action.payload.isDefault,
				meta: action.payload.meta,
				name: action.payload.name,
				protocolVersion: action.payload.protocolVersion,
				version: action.payload.version,
				color: action.payload.color,
				icon: action.payload.icon,
				contents: action.payload.contents,
				author: action.payload.author,
				variant: action.payload.variant,
			}
		];

		state.asyncIndicator = {
			title: t('Widget.AsyncIndicator.Success', 'Widget {action}!', { action: 'saved' }),
			asyncStatus: {
				status: AsyncRequestStatus.completed,
				error: undefined,
			}
		};
	});
});

