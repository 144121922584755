import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
/* import Icon from '@ant-design/icons';
import { RecipeType } from '@kemu-io/kemu-types/dist/types';
import classNames from 'classnames';
import { useTranslation } from '../../common/hooks';
import RoundedModal from '../../components/roundedModal/roundedModal';
import { selectCreatingCloudRecipe } from '../Workspace/workspaceSlice';
import { createCloudRecipeAction } from '../../app/reducers/workspace/createCloudRecipeReducer';
import { AsyncRequestStatus } from '../../types/core_t';
import styles from './EnvironmentModal.module.css';
import { ReactComponent as CloudIcon } from './cloud-icon.svg';
import { ReactComponent as BrowserIcon } from './browser-icon.svg'; */
import { createBrowserRecipeAction } from '../../app/reducers/workspace/createBrowserRecipeReducer';

const EnvironmentModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();


  useEffect(() => {
    dispatch(createBrowserRecipeAction({
      history
    }));
  }, [history, dispatch]);

  return null;

  // Update 21/Jul/2023: Cloud mode is not supported anymore, by default
  // we will create a browser recipe.
  /* const [selectedMode, setSelectedMode] = useState<RecipeType>(RecipeType.Browser);
  const cloudCreation = useSelector(selectCreatingCloudRecipe);
  const dispatch = useDispatch();
  const history = useHistory();
  const t = useTranslation('EnvironmentModal');
  const changeSelectedMode = (mode: RecipeType) => {
    setSelectedMode(mode);
  };

  const handleOk = useCallback(async () => {
    if (selectedMode === RecipeType.Browser) {
      dispatch(createBrowserRecipeAction({
        history
      }));
    } else {
      dispatch(createCloudRecipeAction({
        history
      }));

    }
  }, [selectedMode, history, dispatch]);

  return (
    <RoundedModal
      title={t('Title', 'Environment Mode')}
      closeOnMaskClick={false}
      closable={false}
      visible={true}
      okBtnLabel={t('OkButton', 'Continue')}
      onOk={handleOk}
      disableOkButton={cloudCreation?.status === AsyncRequestStatus.loading}
      loading={cloudCreation?.status === AsyncRequestStatus.loading}
    >

      <p>{t('Subtitle', 'Please select the type of recipe you wish to create:')}</p>

      <div className={styles.CardsContainer}>
        <div
          onClick={() => changeSelectedMode(RecipeType.Browser)}
          className={classNames(
            styles.EnvironmentCard,
            {
              [styles.SelectedCard]: selectedMode === RecipeType.Browser
            })}
          >
          <Icon component={BrowserIcon} className={styles.CustomSvg} />
          <span>{t('Browser.Name', 'Browser')}</span>
        </div>

        <div
          onClick={() => changeSelectedMode(RecipeType.Cloud)}
          className={classNames(
            styles.EnvironmentCard,
            {
              [styles.SelectedCard]: selectedMode === RecipeType.Cloud
            })}
          >
          <Icon component={CloudIcon} className={styles.CustomSvg} />
          <span>{t('Cloud.Name', 'Cloud')}</span>
        </div>
      </div>
      <div className={styles.ModeDescription}>
        {selectedMode === RecipeType.Browser
          ? t('Browser.Description', 'Browser.Description')
          : t('Cloud.Description', 'Cloud.Description')
        }
      </div>
    </RoundedModal>
  ); */
};

export default EnvironmentModal;
