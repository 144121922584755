import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEternalCanvasInstance } from '../../../common/hooks/useEternalCanvasInstance';
import { selectOpeningRecipe } from '../../../features/Workspace/workspaceSlice';
import HorizontalSplit from '../HorizontalSplit/HorizontalSplit';


const SplitLayout = (): React.JSX.Element => {
  const dispatch = useDispatch();
  const openingRecipe = useSelector(selectOpeningRecipe);

  const {
    getCanvasInstance,
    createCanvasInstance,
    destroyCanvasInstance,
    destroyAll,
  } = useEternalCanvasInstance();


  useEffect(() => {
		return () => {
			(async () => {
				await destroyAll();
			})();
		};
	}, [destroyAll, dispatch]);

  // TODO: Write unit tests
  useEffect(() => {
    if (openingRecipe) {
      (async () => {
        await destroyAll();
      })();
    }
  }, [openingRecipe, destroyAll]);

  return (
    <HorizontalSplit
      getCanvasInstance={getCanvasInstance}
      createCanvasInstance={createCanvasInstance}
      destroyCanvasInstance={destroyCanvasInstance}
    />
  );
};

export default SplitLayout;
