import { useCallback, useMemo } from 'react';
import { GateState, ReadOnlyCustomWidgetState } from '@kemu-io/kemu-core/dist/types/gate_t';
import { findRecipe } from '@kemu-io/kemu-core/dist/common/recipeCache';

type UseWidgetStateResponse<T> = {
  /** The state of the widget at the point of using the hook. */
  state: ReadOnlyCustomWidgetState<T>,
  /** a function to get the latest state of the widget. */
  getState: () => ReadOnlyCustomWidgetState<T>,
};

const readWidgetState = <T>(recipePoolId: string, thingId: string, widgetId: string): ReadOnlyCustomWidgetState<T> => {
	// Read the initial state from the store
	const recipe = findRecipe(recipePoolId);
	if (!recipe) {
    return {} as ReadOnlyCustomWidgetState<T>;
  }

	const block = recipe.blocks[thingId];
	const gate = block.gates[widgetId];
	return { ...gate.state as ReadOnlyCustomWidgetState<T> };
};


/**
 * Non-reactive hook to get a SHALLOW the state of the widget directly from the recipe.
 * This is intended to be use by highly performant components as it does not perform
 * any type of cleaning of private properties.
 * 
 * WARNING: this method returns only performs a shallow copy of the widget's state object,
 * mutating nested properties of the object WILL modify the state of the widget.
 * 
 * @param recipePoolId the id of the recipe in the pool.
 * @param thingId the id of the thing in the recipe (blockRecipeId).
 * @param widgetId the id of the widget in the thing.
 */
const useWidgetState = <T=GateState>(recipePoolId: string, thingId: string, widgetId: string): UseWidgetStateResponse<T> => {
  const initialState = useMemo(() => readWidgetState<T>(recipePoolId, thingId, widgetId), [recipePoolId, thingId, widgetId]);

  const getState = useCallback((): ReadOnlyCustomWidgetState<T> => {
    const latestState = readWidgetState<T>(recipePoolId, thingId, widgetId);
    return latestState;
  }, [recipePoolId, thingId, widgetId]);

  return {
    state: initialState,
    getState
  };
};


export default useWidgetState;
