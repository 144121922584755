import { useCallback, useState } from 'react';
import { convertToRaw, EditorState } from 'draft-js';
import { nanoid } from 'nanoid';
import { AsyncRequestStatus, AsyncState } from '../../types/core_t';
import { TutorialState } from '../../types/tutorial';
import * as tutorialApi from '../../api/tutorial/tutorialApi';

type CreateNewTutorialResponse = [
	()=> Promise<string | null>,
	AsyncState
]


/**
 * Creates an empty tutorial in 'draft' state and returns its new id.
 */
export const useCreateNewTutorial = (): CreateNewTutorialResponse  => {
	const [loadingState, setLoadingState] = useState<AsyncState>({ status: AsyncRequestStatus.idle });

	const getTutorialState = useCallback(() => {
		const emptyState = EditorState.createEmpty();

		const document: TutorialState = {
			title: 'Untitled tutorial',
			description: '',
			pages: [{
				id: nanoid(),
				title: 'Step 1',
				durationMinutes: 0,
				content: convertToRaw(emptyState.getCurrentContent())
			}]
		};

		return document as unknown as TutorialState;
	}, []);


	const generateId = async (): Promise<string | null> => {
		try {
			setLoadingState({ status: AsyncRequestStatus.loading });
			const tutorial = getTutorialState();
			const results = await tutorialApi.saveTutorial(tutorial, JSON.stringify(tutorial));
			if (!results.id) { throw new Error(`Invalid server response: ${JSON.stringify(results)}`); }
			setLoadingState({ status: AsyncRequestStatus.completed });
			return results.id;
		} catch (e) {
			console.log('Error generating tutorial id: ', e);
			setLoadingState({ status: AsyncRequestStatus.error, error: e });
			return null;
		}
	};

	return [
		generateId,
		loadingState
	];
};
