import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import { EditorState, ContentState, convertFromRaw, convertToRaw } from 'draft-js';
import {
	CheckOutlined,
	CloseOutlined,
	EditOutlined,
	BgColorsOutlined,
	DeleteFilled
} from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import { AnnotationTextData } from '@kemu-io/kemu-core/dist/types/annotation_t';
import { ABORT_DRAGGING_CLASS, ANNOTATION_DRAGGABLE_CLASS } from '../../../common/constants';
import createColorPickerPlugin from '../../../features/TutorialBuilder/plugins/colorPicker';
import { ToolbarChildrenProps } from '../../../features/TutorialBuilder/plugins/inlineToolbar/components/Toolbar';
import { editAnnotation, removeAnnotation } from '../../../features/interface/interfaceSlice';
import useTranslation from '../../../common/hooks/useTranslation';
import styles from './TextAnnotation.module.css';
import BasicEditor from './BasicEditor';


interface Props {
	id: string;
	recipeId: string;
	onCancelDragging?: (cancel: boolean) => void;
	info?: AnnotationTextData;
}

const colorPickerPlugin = createColorPickerPlugin();
const { ColorPickerButton } = colorPickerPlugin;

const colorMap: Record<string, string> = {
	primary: '#4e3eff',
	secondary: '#ff773e',
	warning: '#ffa426',
	danger: '#fc544b',
	success: '#52c41a',
	white: '#ffffff',
};

const TextAnnotation = (props: Props): React.JSX.Element => {
	const t = useTranslation('Workspace.Annotations.Text');
	const existingContent = props.info?.contents ? convertFromRaw(props.info.contents as Draft.RawDraftContentState) : undefined;
	const [currentState, setCurrentState] = useState(
		EditorState.createWithContent(
			existingContent || ContentState.createFromText(t('DefaultText', 'Type something...'))
		)
	);

	const [temporalState, setTemporalState] = useState(currentState);
	const [editing, setEditing] = useState(false);
	const [background, setBackground] = useState( props.info?.color || 'primary');
	const dispatch = useDispatch();

	const handleEditorStateChange = useCallback((newState: EditorState) => {
		setTemporalState(newState);
	}, []);


	const handleEditingStateChange = (editing: boolean) => {
		// Cancel editing is handled via buttons
		if (editing) {
			setEditing(editing);
		}
	};

	const onDeleteNote = () => {
		dispatch(removeAnnotation({
			annotationId: props.id,
			recipeId: props.recipeId
		}));
	};

	const onStopEditing = () => {
		setEditing(false);
		// Restore to previous state
		setTemporalState(currentState);
	};

	const onSaveEditing = () => {
		const rawContent = convertToRaw(temporalState.getCurrentContent());
		dispatch(editAnnotation({
			annotationId: props.id,
			recipeId: props.recipeId,
			data: {
				...props.info,
				contents: rawContent
			} as AnnotationTextData,
		}));
		setCurrentState(temporalState);
		setEditing(false);
	};

	const colorChanged = useCallback((color: string) => {
		const colorName = Object.keys(colorMap).find(key => colorMap[key] === color);
		if (colorName) {
			setBackground(colorName);
			dispatch(editAnnotation({
				annotationId: props.id,
				recipeId: props.recipeId,
				data: {
					...props.info,
					color: colorName,
				} as AnnotationTextData,
			}));
		}
	}, [dispatch, props.id, props.recipeId, props.info]);


	const BackgroundPicker = useCallback((externalProps: React.PropsWithChildren<ToolbarChildrenProps>) => {
		return (
			<ColorPickerButton
				{...externalProps}
				defaultColor={colorMap[background]}
				wrapperClassName={styles.ColorPickerWrapper}
				onColorChange={colorChanged}
				hideOnColorChange
				customIcon={<BgColorsOutlined />}
				pickerStyle="circle"
				colors={Object.values(colorMap)}
			/>
		);
	}, [background, colorChanged]);

	return (
		<div
			className={
				classnames(
					styles.Container,
					ANNOTATION_DRAGGABLE_CLASS,
					{ 'editing': editing },
					background
				)
			}
		>
			<BasicEditor
				id={props.id}
				className={editing ? ABORT_DRAGGING_CLASS : ''}
				updateEditorState={handleEditorStateChange}
				onEditingStatus={handleEditingStateChange}
				editorState={editing ? temporalState : currentState }
				readOnly={!editing}
				additionalPlugins={[
					BackgroundPicker
				]}
			/>

			{!editing && (
				<div className={styles.EditButton}>
					<Tooltip title={t('EditButton', 'Edit note')}>
						<Button
							onClick={() => setEditing(true)}
							type="text"
							shape="circle"
							icon={<EditOutlined />}
						/>
					</Tooltip>
				</div>
			)}

			{editing && (
				<div className={styles.ButtonsContainer}>
					<Tooltip title={t('DeleteButton', 'Delete note')} placement="bottomLeft">
						<button
							className={classnames(styles.Button, styles.DeleteButton)}
							onClick={onDeleteNote}
						>
							<DeleteFilled />
						</button>
					</Tooltip>
					<button
						className={classnames(styles.Button, styles.CancelButton)}
						onClick={onStopEditing}
					>
						<CloseOutlined />
					</button>

					<button
						className={classnames(styles.Button, styles.SaveButton)}
						onClick={onSaveEditing}
					>
						<CheckOutlined />
					</button>

				</div>
			)}
		</div>
	);
};

export default TextAnnotation;
