import React from 'react';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import generatePicker from 'antd/es/date-picker/generatePicker';
import 'antd/es/date-picker/style/index';
import { PickerProps } from 'antd/lib/date-picker/generatePicker';
import styles from './datePicker.module.css';

type Props = PickerProps<Date> & {
	errorMessage?: React.ReactNode;
}

const DatePicker = (props: Props): React.JSX.Element => {
	const Picker = generatePicker<Date>(dateFnsGenerateConfig);
	const { errorMessage } = props;

	return (
		<>
			<Picker {...props} className={styles.Container}/>
			{ errorMessage &&  <p className={styles.ErrorMessage}>{errorMessage}</p>}
		</>
	);
};

export default DatePicker;
