import { CustomWidgetDbEntity, CustomWidgetVariant } from '@kemu-io/kemu-types/dist/types';
import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { buildWidgetRequestBody } from './utils';
import widgetCache from './widgetCache';
import { WidgetCollectionState } from '@src/app/reducers/widget/widgetSlice';
import * as widgetApi from '@src/api/widget/widgetApi';
import { uploadFile } from '@src/api/utils';
import { AsyncRequestStatus } from '@src/types/core_t';
import { editCustomWidgetState } from '@src/app/recipe/utils';
import { InvalidSaveWidgetResponse } from '@src/types/errors_t';
import { getGlobalIntl } from '@common/translations';

/**
 * Saves the given widget in the database and uploads the contents to S3.
 * It also adds the widget to the local cache.
*/
export const saveWidgetAction = createAsyncThunk('/widgets/saveWidgetAction', async (
	args: {
		widgetIdInRecipe: string;
		recipeId: string;
		thingId: string;
		variant: CustomWidgetVariant;
	},
): Promise<CustomWidgetDbEntity> => {

	const t = getGlobalIntl();

	const {
		requestObject,
		contentsBuf,
		contentsStr,
		widgetState
	} = buildWidgetRequestBody(args.widgetIdInRecipe, args.thingId, args.recipeId, t, args.variant);

	const response = await widgetApi.saveNewWidget(requestObject);
	if (!response.uploadUrl) {
		throw new Error(`Fatal Error ${InvalidSaveWidgetResponse[0]}`);
	}

	// Upload file to S3
	await uploadFile(response.uploadUrl, contentsBuf);
	// Store the widget locally
	await widgetCache.storeWidget(response.widget.id, response.widget.version, contentsStr);
	// Update the recipe reference
	editCustomWidgetState(args.recipeId, args.thingId, args.widgetIdInRecipe, {
		...widgetState,
		collectionInfo: {
			userId: response.widget.userId,
			version: response.widget.version,
			widgetId: response.widget.id
		}
	}, true);

	return {
		...response.widget,
		contents: contentsStr
	};
});


export const saveWidgetReducer = ((builder: ActionReducerMapBuilder<WidgetCollectionState>/*, thunk: any*/): void => {

	builder.addCase(saveWidgetAction.pending, (state) => {
		const t = getGlobalIntl();
		state.asyncIndicator = {
			title: t('Widget.AsyncIndicator.Progress', '{action} Widget...', { action: 'Saving' }),
			asyncStatus: {
				status: AsyncRequestStatus.loading,
				error: undefined,
			}
		};
	});

	builder.addCase(saveWidgetAction.rejected, (state, action) => {
		const t = getGlobalIntl();
		state.asyncIndicator = {
			title: t('Widget.AsyncIndicator.Failure', 'Failed to {action} widget: ', { action: 'save' }),
			asyncStatus: {
				status: AsyncRequestStatus.error,
				error: action.error,
			}
		};
	});

	builder.addCase(saveWidgetAction.fulfilled, (state, action: PayloadAction<CustomWidgetDbEntity>) => {
		const t = getGlobalIntl();
		state.selectedWidget = action.payload.id;

		// Add new widget to local collection
		state.customWidgets = [
			...state.customWidgets,
			{
				dbId: action.payload.id,
				description: action.payload.htmlDescription,
				isDefault: !!action.payload.isDefault,
				meta: action.payload.meta,
				name: action.payload.name,
				protocolVersion: action.payload.protocolVersion,
				version: action.payload.version,
				color: action.payload.color,
				icon: action.payload.icon,
				contents: action.payload.contents,
				author: action.payload.author,
				variant: action.payload.variant,
			}
		];

		state.asyncIndicator = {
			title: t('Widget.AsyncIndicator.Success', 'Widget {action}!', { action: 'saved' }),
			asyncStatus: {
				status: AsyncRequestStatus.completed,
				error: undefined,
			}
		};
	});
});

