
import React, { useCallback, useEffect } from 'react';
import { Radio, Layout, Button } from 'antd';
import Icon, { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { useHistory, useRouteMatch, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MyRecipesList from '../../marketplace/RecipeList/RecipeList';
import MyTutorials from '../../marketplace/MyTutorials/MyTutorials';
import PublicThings from '../../marketplace/PublicThings/PublicThings';
import PageModal from '../../pageModal/pageModal';
import PublicationDetailsView from '../../marketplace/PublicationDetails/PublicationDetails';
import PublicRecipes from '../../marketplace/PublicRecipes/PublicRecipes';
import { useCreateNewTutorial } from '../../../features/TutorialBuilder/useCreateNewTutorial';
import { AsyncRequestStatus } from '../../../types/core_t';
import SectionHeaderWithButton from '../../sectionHeaderWithButton/sectionHeaderWithButton';
import { setActiveTutorialInfo } from '../../../features/interface/interfaceSlice';
import AlgoliaSearchBox from '../../marketplace/AlgoliaSearchBox/AlgoliaSearchBox';
import { selectSignedUserProfile } from '../../../app/reducers/user/userSlice';
import { ReactComponent as KemuLogoAndText } from '../../../assets/img/kemuAndText.svg';
import MarketplaceMenu from './MarketplaceMenu/MarketplaceMenu';
import styles from './marketplace.module.css';
import GlobalSearchContextProvider from '@common/context/GlobalSearchContext/GlobalSearchContext';
import useCurrentWorkspacePath from '@common/hooks/useCurrentWorkspacePath';
import useTranslation from '@common/hooks/useTranslation';
import routes from '@common/routes';
import globals from '@common/globals';
import 'antd/dist/antd.min.css';
import '../core.css';

const { Header, Content } = Layout;

export const MARKETPLACE_CONFIG_NAME = 'marketplace';
export interface MarketplaceCacheState {
	myRecipes: number;
	myTutorials: number;
	publicRecipes: number;
	publicThings: number;
}

export const MarketplaceCacheDefaultState: MarketplaceCacheState = {
	myRecipes: 5,
	publicRecipes: 5,
	myTutorials: 5,
	publicThings: 10,
};


const MarketplaceLayout = (): React.JSX.Element => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { tokens } = useSelector(selectSignedUserProfile);
	const [createTutorial, createTutorialState] = useCreateNewTutorial();
	const inMarketplaceRoot = useRouteMatch({ path: routes.marketplace.getMarketplaceRoute(), exact: true });
	const t = useTranslation('Marketplace');
	const getWorkspacePath = useCurrentWorkspacePath();
	const inMyRecipes = useRouteMatch({ path: routes.marketplace.getMyRecipesRoute(), exact: true });
	const inMyTutorials = useRouteMatch({ path: routes.marketplace.getMyTutorialsRoute(), exact: true });

	const inPublicRecipes = useRouteMatch({ path: routes.marketplace.getPublicationListRoute('recipe') });
	const inPublicThings = useRouteMatch({ path: routes.marketplace.getPublicationListRoute('thing') });


	const navigateToTab = (route: string) => {
		history.push(route);
	};

	const closeMarketplace = useCallback(() => {
		const workspacePath = getWorkspacePath();
		history.push(workspacePath);
	}, [getWorkspacePath, history]);


	const handleCreateTutorial = async () => {
		const id = await createTutorial();
		if (id) {
			// Clear any previous reference
			dispatch(setActiveTutorialInfo(null));
			const workspacePath = getWorkspacePath();
			const withWorkspacePath = `${workspacePath}${routes.tutorial.getTutorialRoute(id, 0)}`;
			history.push(withWorkspacePath);
		}
	};

	const handleCreateRecipe = useCallback(() => {
		window.open(`https://${globals.KEMU_APP_DOMAIN}`, '_blank');
	}, []);

	// If no route defined, navigate to my recipes by default
	useEffect(() => {
		if (inMarketplaceRoot) {
			history.push(routes.marketplace.getMyRecipesRoute());
		}
	}, [inMarketplaceRoot, history]);

	const RecipeTabs = (): React.JSX.Element => {
		return (
			<>
				<Radio.Button
					onClick={() => navigateToTab(routes.marketplace.getMyRecipesRoute())}
					type="link"
					className={`my-recipes-tab ${styles.SubCategoryBarBtn} ${inMyRecipes ? styles.SelectedSubCategory : ''}`}
				>
					{t('Navigation.MyRecipes', 'My Recipes')}
				</Radio.Button>
			</>
		);
	};

	const TutorialTabs = (): React.JSX.Element => {
		return (
			<>
				<Radio.Button
					onClick={() => navigateToTab(routes.marketplace.getMyTutorialsRoute())}
					type="link"
					className={`my-tutorials-tab ${styles.SubCategoryBarBtn} ${inMyTutorials ? styles.SelectedSubCategory : ''}`}
				>
					{t('Navigation.MyTutorials', 'My Tutorials')}
				</Radio.Button>
			</>
		);
	};

	const MarketplaceTabs = (): React.JSX.Element => {
		return (
			<>
				<Radio.Button
					onClick={() => navigateToTab(routes.marketplace.getPublicationListRoute('recipe'))}
					type="link"
					className={`public-recipes-tab ${styles.SubCategoryBarBtn} ${inPublicRecipes ? styles.SelectedSubCategory : ''}`}
				>
					{t('Navigation.PublicRecipes', 'Public Recipes')}
				</Radio.Button>

				<Radio.Button
					onClick={() => navigateToTab(routes.marketplace.getPublicationListRoute('thing'))}
					type="link"
					className={`public-things-tab ${styles.SubCategoryBarBtn} ${inPublicThings ? styles.SelectedSubCategory : ''}`}
				>
					{t('Navigation.PublicThings', 'Public Things')}
				</Radio.Button>
			</>
		);
	};


	return (
		<PageModal visible={true} fullScreen={true}>
			<div id="layout-container" className="light-theme marketplace">
				<Layout className="site-layout">
					<Header className={`site-layout-background ${styles.Header}`}>
						<Icon component={KemuLogoAndText} className={styles.HeaderLogo} />
						<Button data-kemu-meta="close-marketplace-btn" type="text"className={styles.BackButton} onClick={closeMarketplace} icon={<CloseOutlined style={{ fontSize: 24 }}/>} />
					</Header>


					<Layout>
						{/* Left Menu */}
						<MarketplaceMenu />

						{/* Wait for the user token to be provided before rendering anything */}
						{!!tokens && (
							<GlobalSearchContextProvider appId={globals.ALGOLIA_APP_ID} apiKey={tokens.algolia}>
								<Content>
									<div className={`${styles.Section} section`}>
										<div className={`subcategory-bar `} id={styles.SubCategoryBar}>
											<Radio.Group value='large'>
												<Route path={routes.marketplace.getMyRecipesRoute()} component={RecipeTabs} />
												<Route path={routes.marketplace.getMyTutorialsRoute()} component={TutorialTabs} />
												<Route path={routes.marketplace.getMarketplacePublicRoute()} component={MarketplaceTabs} />
											</Radio.Group>

											{(inPublicThings || inMyRecipes) && (
												<div data-kemu-meta={'search-box'} className={styles.SearchBoxContainer}>
													<AlgoliaSearchBox
														placeholder={inMyRecipes ?
															t('MyRecipes.SearchPlaceholder', 'Search for recipes...') :
															t('PublicThings.SearchPlaceholder', 'Search for things...')
														}
													/>
												</div>
											)}
										</div>

										<div className={`${styles.SectionHeader} section-header`}>

											{inMyRecipes && (
												<SectionHeaderWithButton
													data-kemu-meta="create-new-recipe"
													disabled={createTutorialState.status === AsyncRequestStatus.loading}
													loading={createTutorialState.status === AsyncRequestStatus.loading}
													onClick={handleCreateRecipe}
													color="light"
													className={styles.CreateTutorialBtn}
													sectionTitle={t('Navigation.MyRecipes', 'My Recipes')}
													buttonTitle={
														<>
															{createTutorialState.status !== AsyncRequestStatus.loading && <PlusOutlined /> }
															<span>{t('MyRecipes.Header.CreateNewBtn', 'Create New')}</span>
														</>
													}
												/>
											)}

											{inMyTutorials && (
												<SectionHeaderWithButton
													data-kemu-meta="create-new-tutorial"
													disabled={createTutorialState.status === AsyncRequestStatus.loading}
													loading={createTutorialState.status === AsyncRequestStatus.loading}
													onClick={handleCreateTutorial}
													color="light"
													className={styles.CreateTutorialBtn}
													sectionTitle={t('Navigation.MyTutorials', 'My Tutorials')}
													buttonTitle={
														<>
															{createTutorialState.status !== AsyncRequestStatus.loading && <PlusOutlined /> }
															<span>{t('MyTutorials.CreateNewBtn', 'Create New')}</span>
														</>
													}
												/>
											)}
										</div>

										<div className={styles.SectionBody}>

											<Route path={routes.marketplace.getMyRecipesRoute()} component={MyRecipesList} exact={true}/>
											<Route path={routes.marketplace.getPublicationListRoute('recipe')} component={PublicRecipes} exact={true}/>
											<Route path={routes.marketplace.getGenericPublicationDetailsRoute()} component={PublicationDetailsView} exact={true} />
											<Route path={routes.marketplace.getMyTutorialsRoute()} component={MyTutorials} exact={true} />

											<Route
												path={routes.marketplace.getPublicationListRoute('thing')}
												component={PublicThings}
												exact={true}
											/>

										</div>
									</div>
								</Content>
							</GlobalSearchContextProvider>
						)}
					</Layout>
				</Layout>
			</div>
		</PageModal>
	);
};

export default MarketplaceLayout;
