import React from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { useIntl } from 'react-intl';
import styles from './introCard.module.css';

interface Props {
	readOnly?: boolean;
	description: string;
	author: string;
	updatedAt?: string;
	onDescriptionChanged: (text: string) => void;
}

const IntroCard = (props: Props): React.JSX.Element => {
	const lastUpdated = props.updatedAt ? formatDistanceToNow(parseISO(props.updatedAt)) + ' ago' : 'never';
	const intl = useIntl();
	const defaultText = intl.formatMessage({ id: 'TutorialBuilder.Main.Default.Description', defaultMessage: 'Enter a description for this tutorial' });
	const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		const totalLines = event.target.value.split('\n').length;
		if (totalLines > 2) {
			props.onDescriptionChanged(props.description);
		} else {
			props.onDescriptionChanged(event.target.value);
		}
	};


	const totalLines = props.description.split('\n').length;
	return (
		<div className={styles.IntroContainer}>
			<h2>About this tutorial</h2>
			<span className={styles.UserInfo}>
				<Avatar size={48} icon={<UserOutlined />} className={styles.UserIcon}/>
				<div className={styles.Details}>
					<span className={styles.WrittenBy}>Written by</span> {props.author}
					<div><span className={styles.Date}>Last updated:</span> {lastUpdated}</div>
				</div>
			</span>

			<div className={`${styles.Description} ${!props.description.length ? 'default' : ''}`}>
				<textarea placeholder={defaultText} value={props.description} onChange={handleDescriptionChange} readOnly={props.readOnly} rows={totalLines || 1}/>
			</div>

		</div>
	);
};

export default IntroCard;
