import React, { useRef, CSSProperties, FC, ComponentType, useCallback, useEffect, useState, memo } from 'react';
import { EditorState } from 'draft-js';
import { DraftJsButtonTheme } from '@draft-js-plugins/buttons';
import { PlusOutlined } from '@ant-design/icons';
import { SideToolbarPluginTheme } from '../theme';
import { isElementAChildOf } from '../../utils';
import { removeFunctionProps } from '../../../../../common/utils';
export interface BlockTypeSelectChildProps {
  theme: DraftJsButtonTheme;
  getEditorState(): EditorState;
	setEditorState(state: EditorState): void;
	hideToolbar: ()=> void;
	onOverrideContent: (
    content: ComponentType<BlockTypeSelectChildProps> | null
  ) => void;
}

interface BlockTypeSelectProps {
  style?: CSSProperties;
  theme: SideToolbarPluginTheme;
  getEditorState(): EditorState;
  setEditorState(state: EditorState): void;
	childNodes: FC<BlockTypeSelectChildProps>;
	// refreshPosition: ()=> void;
}

const BlockTypeSelect = (props: BlockTypeSelectProps): React.JSX.Element => {
	const buttonRef = useRef<HTMLDivElement>(null);
	const { theme, getEditorState, setEditorState, childNodes } = props;
	const [iconsVisible, setIconsVisible] = useState<boolean>(false);
	const [state, setState] = useState<{overrideContent?: ComponentType<BlockTypeSelectChildProps> | null}>({});

  const setInvisible = useCallback((/* event: globalThis.MouseEvent */): void => {
		setIconsVisible(false);
		setState({ overrideContent: null });
	}, [setIconsVisible]);

	const onOverrideContent = (newContent: ComponentType<BlockTypeSelectChildProps> | null): void => {
		setState({ overrideContent: newContent });
  };

  const onMouseDown = (clickEvent: React.MouseEvent<HTMLDivElement>): void => {
    clickEvent.preventDefault();
		clickEvent.stopPropagation();
		setIconsVisible(true);
  };

	const onWindowMouseDown = useCallback((event: MouseEvent): void => {
		// Prevent hiding if clicking on a child element
		if (buttonRef.current) {
			const target = event.target;
			const isMyChild = isElementAChildOf(target as HTMLElement, buttonRef.current);
			if (isMyChild) {
				return;
			}
		}

		setInvisible();
	}, [setInvisible]);

	useEffect(() => {
		window.addEventListener('mousedown', onWindowMouseDown);
		return () => {
			window.removeEventListener('mousedown', onWindowMouseDown);
		};
	}, [onWindowMouseDown]);

	const { overrideContent: OverriddenContent } = state;
	const childrenProps: BlockTypeSelectChildProps = {
		onOverrideContent,
		getEditorState,
		setEditorState,
		hideToolbar: setInvisible,
		theme: theme.buttonStyles!,
	};

	// useEffect(()=>{
	// 	props.refreshPosition();
	// }, [OverriddenContent]);

	return (
		<div
			ref={buttonRef}
			// className="hover-detector"
			style={{ display: 'flex', alignItems: 'center' }}
		>
			<div onMouseDown={onMouseDown} className={`${theme.blockTypeSelectStyles?.blockType} ${iconsVisible ? 'active' : 'inactive'}`}>
				<PlusOutlined style={{ fontSize: 24 }} />
			</div>

			<div className={`${theme.blockTypeSelectStyles?.popup} ${iconsVisible ? 'visible' : 'invisible'} ${OverriddenContent ? 'custom' : ''}`}>
				{!OverriddenContent ? (
					childNodes(childrenProps)
				) : (
					<OverriddenContent {...childrenProps} />
				)}
			</div>
		</div>
	);
};


const areEqual = (prevProps: BlockTypeSelectProps, newProps: BlockTypeSelectProps): boolean => {
  const prev = removeFunctionProps(prevProps);
	const now = removeFunctionProps(newProps);
  const equal = JSON.stringify(prev) === JSON.stringify(now);
  return equal;
};


export default memo(BlockTypeSelect, areEqual);
