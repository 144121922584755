import { Recipe, RecipeStatus } from '@kemu-io/kemu-core/dist/types/recipe_t';

/**
 * Returns a default recipe based on the organizational settings
 */
const getDefaultRecipe = async (): Promise<Recipe> => {
	// TODO: Get this from the DB
	const recipe: Recipe = {
		id: '',
		name: 'Untitled recipe',
		blocks: {},
		canvas: {
			workspace: {
				position: { x: 0, y: 0 },
			}
		},

		// ==== Just for compliance ====
		author: {
			firstName: 'Unknown',
			id: '0',
			lastName: 'unknown',
		},

		credits: 0,
		dataSources: [],
		startedAt: 0,
		lastInvokedAt: 0,
		lastProcessedAt: 0,
		status: RecipeStatus.RUNNING,
		// v1.0 => Alpha
		// v1.3 => Support for dynamic ports
		// v1.4 => Recipe widgets metadata
		// v1.5 => Support for cloud recipes
		protocolVersion: '1.5'
	};

	return recipe;
};

export {
	getDefaultRecipe,
};
