import React, { useCallback, useState } from 'react';
import { Menu, Tooltip, SubMenuProps } from 'antd';
import { FormattedMessage } from 'react-intl';
import Icon, { Loading3QuartersOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { RecipeType, ThingCategory, ThingType } from '@kemu-io/kemu-types/dist/types';
import { ReactComponent as virtualBlockIcon } from '../../../assets/img/interface/virtual-block.svg';
import { AsyncRequestStatus } from '../../../types/core_t';
import { addBlock, selectCurrentRecipeType } from '../../../features/Workspace/workspaceSlice';
import { selectInstallDefaultThingStatus, selectInstalledThings, ThingInfoWithIcon } from '../../../app/reducers/thing/thingSlice';
import styles from './thingsMenuButton.module.css';


interface Props extends SubMenuProps{
	collapsed: boolean;
}

const ThingsMenuButton = (props: Props): React.JSX.Element => {
	// @ts-expect-error antd v4.16.1 is adding these extra props 
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { collapsed, warnKey, eventKey, ...subMenuProps } = props;
	const iconStyle = { fontSize: collapsed ? 26 : 20 };
	const fetchingDefaultThingsState = useSelector(selectInstallDefaultThingStatus);
	const installedThingsList = useSelector(selectInstalledThings);
	const recipeType = useSelector(selectCurrentRecipeType);
	const dispatch = useDispatch();
	const [tooltipVisible, setTooltipVisible] = useState(false);


	const addVirtualBlockHandler = useCallback((block: ThingInfoWithIcon) => {
		dispatch(addBlock({ blockInfo: block }));
	}, [dispatch]);

	const onSVGInjection = (svg: SVGElement, category: ThingCategory) => {
		let size = iconStyle.fontSize;
		if (category === ThingCategory.Virtual) { size = 20; }
		svg.setAttribute('style', `width: ${size}px; height: ${size}px`);
	};

	const getDynamicSVG = (icon: string, category: ThingCategory) => {
		return () => <ReactSVG src={icon} wrapper="span" beforeInjection={svgEl => onSVGInjection(svgEl, category)}/>;
	};

	const getVbSubItems = () => {
		let filteredByEnvironment: ThingInfoWithIcon[];
		if (recipeType === RecipeType.Cloud) {
			filteredByEnvironment = installedThingsList.filter(thing => thing.type === ThingType.Cloud);
		} else {
			filteredByEnvironment = installedThingsList.filter(thing => thing.type === ThingType.Web);
		}

		return (
			filteredByEnvironment.map((thing, index) => (
				<Menu.Item
					data-kemu-meta={`thing-type-${thing.id}`}
					className={`main-menu-item custom-menu-item ${thing._isDevMode ? styles.DevMode : ''}`}
					onClick={() => addVirtualBlockHandler(thing)}
					title={!collapsed ? thing.name : ''}
					key={`${thing.id}_${index}`}
					icon={<Icon component={getDynamicSVG(thing.icon, thing.category)} />}
				>
					{thing.name}
				</Menu.Item>
			))
		);
	};

	return (
		<Menu.SubMenu
			{...subMenuProps}
			onTitleMouseEnter={() => setTooltipVisible(true)}
			onTitleMouseLeave={() => setTooltipVisible(false)}
			onTitleClick={() => setTooltipVisible(false)}
			data-menu-name="kemu-things"
			title={<FormattedMessage id="Menu.Apps.Title" defaultMessage="Kemu Things" />}
			popupClassName="sub-menu-popup"
			key="apps"
			icon={
				<Tooltip
					title={<FormattedMessage id="Menu.KemuThingsTooltip" defaultMessage="Add Things" />}
					align={{ offset: [25] }}
					placement="right"
					visible={tooltipVisible && collapsed}
					overlayClassName={styles.TooltipContainer}
				>
					<Icon component={virtualBlockIcon} style={iconStyle}/>
				</Tooltip>
			}
			className="sub-menu custom-icon"
		>
			{ fetchingDefaultThingsState.status === AsyncRequestStatus.completed ? (
				getVbSubItems()
			) : (
				<Menu.Item
					className="main-menu-item isLoading"
					title=""
					key="loading-item"
					icon={<Loading3QuartersOutlined spin={true}/>}
				>
					<FormattedMessage id="Menu.Apps.Loading" defaultMessage="Loading..."/>
				</Menu.Item>
			)}
		</Menu.SubMenu>
	);
};

export default ThingsMenuButton;
