import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Menu, Dropdown, Tooltip } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { Auth } from '@aws-amplify/auth';
import { useSelector } from 'react-redux';
import { selectSignedUserProfile } from '../../app/reducers/user/userSlice';
import globals from '../../common/globals';
import styles from './accountMenu.module.css';
interface Props {
	className?: string;
}

const AccountMenu = (props: Props): ReactElement => {
	const userProfile = useSelector(selectSignedUserProfile);
	const [menuIsVisible, setMenuIsVisible] = useState(false);
	const [tooltipVisible, setTooltipVisible] = useState(false);
	const handleSignOut = async () => {
		try {
			await Auth.signOut({ global: true });
		} catch (e) {
			console.log('Error signing out: ', e);
		}
	};

	const makeInvisible = useCallback(() => {
		setMenuIsVisible(false);
		window.removeEventListener('mousedown', makeInvisible);
	}, []);

	const makeVisible = useCallback(() => {
		setMenuIsVisible(true);
		window.addEventListener('mousedown', makeInvisible);
	}, [makeInvisible]);


	const handleMouseDown = (event: React.MouseEvent) => {
		event.stopPropagation();
		event.preventDefault();

		if (menuIsVisible) {
			makeInvisible();
		} else {
			makeVisible();
		}
	};


	const onOpenUserPortal = () => {
		window.location.href = `https://${globals.DASHBOARD_APP_DOMAIN}/dashboard/billing/portal`;
	};

	// const resetAccountType = async ()=>{
	// 	const user = await Auth.currentAuthenticatedUser();
	// 	await Auth.updateUserAttributes(user, {
	// 		'custom:organization': ''
	// 	});
	// };

	useEffect(() => {
		return () => {
			makeInvisible();
		};
	}, [makeInvisible]);

	const menu = (
		<Menu>
			{/* <Menu.Item>
				<FormattedMessage defaultMessage="Account" id="AccountMenu.Account" />
			</Menu.Item> */}

			{/* <Menu.Item>
				<FormattedMessage defaultMessage="Developer settings" id="AccountMenu.DeveloperSettings" />
			</Menu.Item> */}

			{/* <Menu.Item onMouseDown={resetAccountType}>
				Reset account type (experimental)
			</Menu.Item> */}

			<Menu.Item>
				<div className={styles.UserInfo}>
					{userProfile.profile?.firstName || ''} {userProfile.profile?.lastName || ''}
					{userProfile.profile?.email && (
						<small>({userProfile.profile?.email})</small>
					)}
				</div>
			</Menu.Item>
			<Menu.Divider />

			{userProfile.accountState?.hasKemuAccessLicense && !userProfile.accountState.belongsToOrganization && (
				<>
					<Menu.Item onMouseDown={onOpenUserPortal}>
						<FormattedMessage defaultMessage="Manage Subscriptions" id="AccountMenu.ManageSubscriptions" />
					</Menu.Item>
					<Menu.Divider />
				</>
			)}

			<Menu.Item onMouseDown={handleSignOut}>
				<FormattedMessage defaultMessage="Logout" id="AccountMenu.SignOut" />
			</Menu.Item>
		</Menu>
	);

	return (
		<Tooltip
			onVisibleChange={(visible) => setTooltipVisible(visible)}
			visible={!menuIsVisible && tooltipVisible}
			title={<FormattedMessage id="Interface.HeaderIcons.Account" defaultMessage="User Account" />}
			placement="bottomRight"
			destroyTooltipOnHide
		>
			<span className={props.className || ''} onMouseDown={handleMouseDown}>
				<Dropdown overlay={menu} placement="bottomRight" arrow visible={menuIsVisible} onVisibleChange={v => !v && setTooltipVisible(false)}>
					<UserOutlined />
				</Dropdown>
			</span>
		</Tooltip>
	);
};

export default AccountMenu;
