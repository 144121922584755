import React, { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { selectedBlock, openBlockAction, selectFolderPath, gotoBlockRootAction, gotoFolderPathId } from '../../features/Workspace/workspaceSlice';
import styles from './breadcrumb.module.css';
import LogicMapperSettings from '@components/layout/LogicMapperSettings/LogicMapperSettings';
import useTranslation from '@hooks/useTranslation';

const Breadcrumb = (): React.JSX.Element => {
	const openedBlock = useSelector(selectedBlock);
	const dispatch = useDispatch();
	const folderPath = useSelector(selectFolderPath);
	const hasNestedObjects = folderPath.length > 0;
	const t = useTranslation('CommonWords');

	const onNavigate = () => {
		// Reset the path
		handleNavigateToRootPath();
		// Navigate outside the Logic Mapper
		dispatch(openBlockAction(null));
	};

	const handleNavigateToRootPath = () => {
		dispatch(gotoBlockRootAction());
	};

	const navigateToGroup = (groupId: string) => {
		dispatch(gotoFolderPathId(groupId));
	};

	// TODO:
	// - Don't show if on workspace
	// - Push to a list of items when user enters inside a group/block and feed this
	// list from that array.
	return (
		<div className={styles.CanvasBreadcrumb}>
			<LogicMapperSettings />
			<div
				className={classNames(styles.BreadcrumbItem, { active: !!openedBlock })}
				data-kemu-type="workspace-breadcrumb"
			>
				<a onClick={onNavigate}>{t('Workspace', 'Workspace')}</a>
			</div>

			{openedBlock && (
				<>
					<div
						data-kemu-type="thing-breadcrumb"
						className={classNames(styles.BreadcrumbItem, { active: hasNestedObjects })}
					>
						<a onClick={handleNavigateToRootPath}>
							{openedBlock?.title}
						</a>
					</div>

					{folderPath.map((pathInfo, i) => (
						<div
							key={i}
							className={classNames(styles.BreadcrumbItem, { active: i !== (folderPath.length-1) })}
						>
							<a onClick={() => navigateToGroup(pathInfo.groupId)}>
								{pathInfo.name}
							</a>
						</div>
					))}
				</>
			)}

		</div>
	);
};

export default memo(Breadcrumb);
