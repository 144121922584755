import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { History } from 'history';
import { CloudEnvironmentType, RecipeType } from '@kemu-io/kemu-types/dist/types';
import { uniqueNamesGenerator, NumberDictionary, animals, adjectives, Config } from 'unique-names-generator';
import { AsyncRequestStatus } from '../../../types/core_t';
import { getDefaultRecipe } from '../../../features/interface/interfaceApi';
import { storeRecipeAction } from '../../../features/interface/reducers/storeRecipeReducer';
import { RootState } from '../../store';
import { setRecipeType, WorkspaceState } from '../../../features/Workspace/workspaceSlice';
import routes from '../../../common/routes';
import { openRecipeAction } from './openRecipeReducer';


export const generateUniqueName = (): string => {
  const forbiddenAdjectives = ['fat', 'ugly', 'short', 'stupid', 'tall', 'xenophobic'];
  const cleanAdjectives = adjectives.filter(adj => !forbiddenAdjectives.includes(adj));
  const numberDictionary = NumberDictionary.generate({ min: 100, max: 999 });
  const config: Config = {
    dictionaries: [cleanAdjectives, animals, numberDictionary],
    length: 3,
    separator: ' ',
    style: 'capital'
  };

  const name = uniqueNamesGenerator(config);
  return name;
};

/**
 * Creates a default recipe, registers it with Kemu and saves it in the DB.
 */
export const createCloudRecipeAction = createAsyncThunk<void, {
  name?: string,
  history: History
}>(
  '/Workspace/createCloudRecipe', async (options, thunkAPI) => {
    // Create a basic recipe
    const defaultRecipe = await getDefaultRecipe();

    if (options.name) {
      defaultRecipe.name = options.name;
    } else {
      // Choose a default name
      defaultRecipe.name = generateUniqueName();
    }

    // Open it so that Kemu creates a pool id
    await thunkAPI.dispatch(openRecipeAction({
      recipe: defaultRecipe,
      isDefault: true,
      dbInfo: {
        id: '',
        authorId: '',
        version: 0,
        recipeType: RecipeType.Cloud
      }
    }));

    thunkAPI.dispatch(setRecipeType({
      type: RecipeType.Cloud,
      cloudInfo: {
        enabled: true,
        environment: CloudEnvironmentType.Cpu_128M,
      },
    }));

    // Get the pool id
    const { workspace } = thunkAPI.getState() as RootState;
    let currentRecipe = workspace.currentRecipe;
    const poolId = currentRecipe.poolId;
    if (!poolId) { throw new Error(`Error creating cloud recipe: poolId is undefined`); }

    // Save it to the DB
    await thunkAPI.dispatch(storeRecipeAction({
      recipePoolId: poolId,
      hideProgress: true,
      newName: currentRecipe.name,
      history: options.history,
    }));

    // Update the reference since it is changed after storeRecipeAction()
    currentRecipe = (thunkAPI.getState() as RootState).workspace.currentRecipe;

    // Redirect to the cloud recipe screen
    options.history.push(routes.recipe.getCloudRecipeRoute(currentRecipe.entityInfo?.dbId));
  }
);


export const createCloudRecipeReducer = ((builder: ActionReducerMapBuilder<WorkspaceState>): void => {
  builder.addCase(createCloudRecipeAction.fulfilled, (state) => {
    state.baseRecipeLoaded = true;
    state.creatingCloudRecipe = {
      status: AsyncRequestStatus.completed,
      error: undefined,
    };
  });

  builder.addCase(createCloudRecipeAction.rejected, (state, action) => {
    console.log('Error creating cloud recipe: ', action);
    state.creatingCloudRecipe = {
      status: AsyncRequestStatus.error,
      error: action.error,
    };
  });

  builder.addCase(createCloudRecipeAction.pending, (state) => {
    state.creatingCloudRecipe = {
      status: AsyncRequestStatus.loading,
      error: undefined,
    };
  });
});
