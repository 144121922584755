import { EditorState, AtomicBlockUtils, RichUtils } from 'draft-js';

const YOUTUBE_MATCH_URL = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
const VIMEO_MATCH_URL = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/; // eslint-disable-line no-useless-escape
const YOUTUBE_PREFIX = 'https://www.youtube.com/embed/';
const VIMEO_PREFIX = 'https://player.vimeo.com/video/';

export const VIDEO_ENTITY_TYPE = 'CUSTOM_VIDEO';

export const isYoutube = (url: string): boolean => {
  return YOUTUBE_MATCH_URL.test(url);
};
export const isVimeo = (url: string): boolean => {
  return VIMEO_MATCH_URL.test(url);
};

export type SourceType = 'youtube' | 'vimeo';
export interface SourceResult {
  srcID: string;
  srcType: SourceType;
  url: string;
}

const getYoutubeSrc = (url: string): SourceResult => {
  const id = url && url.match(YOUTUBE_MATCH_URL)![1];
  return {
    srcID: id,
    srcType: 'youtube',
    url,
  };
};

const getVimeoSrc = (url: string): SourceResult => {
  const id = url.match(VIMEO_MATCH_URL)![3];
  return {
    srcID: id,
    srcType: 'vimeo',
    url,
  };
};

const getSrc = ({ src }: { src: string }): string | undefined => {
  if (isYoutube(src)) {
    const { srcID } = getYoutubeSrc(src);
    return `${YOUTUBE_PREFIX}${srcID}`;
  }
  if (isVimeo(src)) {
    const { srcID } = getVimeoSrc(src);
    return `${VIMEO_PREFIX}${srcID}`;
  }
  return undefined;
};

const addVideo = (editorState: EditorState, src: string, width: number, height: number): EditorState => {
	if (RichUtils.getCurrentBlockType(editorState) === 'atomic') {
    return editorState;
	}

  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(
    VIDEO_ENTITY_TYPE,
    'IMMUTABLE',
    { src, width, height }
  );
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  return AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');
};

export {
	addVideo,
	getVimeoSrc,
	getYoutubeSrc,
	getSrc
};
