import React, { useEffect, useState } from 'react';
import useNumericInput from '../../../common/hooks/useNumericInput';

interface Props{
	onChange?: (value: number) => void;
	value?: string | number;
	disabled?: boolean;
	className?: string;
	containerClass?: string;
	placeholder?: string;
	min?: number;
	max?: number;
}

const NumericWithoutArrows = (props: Props): React.JSX.Element => {
	const numbersOnly = useNumericInput();
	const [value, setValue] = useState<string | number>(String(props.value) || '');

	const handleInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = (evt.currentTarget.value as unknown) as number;
		setValue(newValue);
		const n = Number(newValue);
		if (isNaN(n)) { return; }
		if (props.min !== undefined && n < props.min) { return; }
		if (props.max !== undefined && n > props.max) { return; }
		props.onChange && props.onChange(Number(newValue));
	};

	const onFocusOut = () => {
		props.value !== undefined && setValue(props.value);
	};

	useEffect(() => {
		props.value !== undefined && setValue(props.value);
	}, [props.value]);

	return (
		<div className={`gate-input numeric-input ${props.containerClass || ''}`}>
			<input type="text"
				{...props}
				className={props.className || ''}
				onInput={numbersOnly}
				placeholder={props.placeholder}
				value={String(value)}
				onChange={handleInputChange}
				onBlur={onFocusOut}
			/>
		</div>
	);
};

export default NumericWithoutArrows;
