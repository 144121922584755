
import React from 'react';
import Icon from '@ant-design/icons';
import ValueWidgetProcessor, {
	ValueWidgetState,
	getDefaultState
} from '@kemu-io/kemu-core/dist/gates/value';
import { message } from 'antd';
import { CustomWidgetState, WidgetPortContext } from '@kemu-io/kemu-core/dist/types/gate_t';
import classNames from 'classnames';
import { PortLocation } from '../../../types/canvas_t';
import useReactiveWidgetState from '../../../common/hooks/useReactiveWidgetState';
import useTranslation, { getTranslationFunction } from '../../../common/hooks/useTranslation';
import KemuSwitch from '../../form-control/kemuSwitch/kemuSwitch';
import NumericInput from '../../WidgetsComponents/NumericInput/NumericInput';
import { getLastInputPortWithParent, LastPortInfo } from '../../../common/utils';
import GateIcon from '../../gateIcon/gateIcon';
import {
	GetPortsInformationFunction,
	GateUI,
	GateUIProps,
	GateCustomSettingsProps,
} from '..';
import { SETTINGS_CONTAINER_CLASS } from '../../../common/constants';
import styles from './value.module.css';
import { ReactComponent as ValueWidgetIcon } from './icon.svg';

const ValueWidget = (props: GateUIProps): React.JSX.Element => {
	const [state, setState] = useReactiveWidgetState<ValueWidgetState>(props.recipeId, props.thingRecipeId, props.info.id);
	const fixedState: ValueWidgetState = {
		...getDefaultState(),
		...state
	};


	const handleInputChange = (value: number) => {
		setState({
			...state,
			value,
		}, true);
	};

	return (
		<div className={styles.GateBody}>
			<NumericInput
				value={fixedState.value}
				onChange={handleInputChange}
			/>
		</div>
	);
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={ValueWidgetIcon} />}/>
	);
};

const GateCustomSettings = (props: GateCustomSettingsProps): React.JSX.Element => {
	const [state, setState] = useReactiveWidgetState<ValueWidgetState>(props.recipeId, props.blockId, props.gateInfo.id);
	const fixedState = {
		...getDefaultState(),
		...state
	};

	const t = useTranslation('LogicMaker.Gates.Value.Settings');
	const gT = useTranslation('LogicMaker.Gates.Generic');

	const warnIfInputAttached = (info: LastPortInfo | null): boolean => {
		// Prevent adding ports if trigger is linked (otherwise new ports will obtain the connection from trigger)
		if (info && info?.portName === 'trigger') {
			message.warning({
				content: gT('RemoveWarning', 'Please remove all connections from port "{name}"',
				{ name: info.portName })
			});
			return true;
		}

		return false;
	};

	const getLastConnectedInput = () => {
		return getLastInputPortWithParent(
			fixedState,
			ValueWidgetProcessor,
			props.gateInfo.id,
			props.blockId,
			props.recipeId,
			props.recipeType
		);
	};

	const handleSwitchChange = (checked: boolean) => {
		const lastPortWithConnection = getLastConnectedInput();
		if (warnIfInputAttached(lastPortWithConnection)) { return ; }
		setState({ ...fixedState, dispatchOnSet: checked });
	};

	return (
		<div className={classNames(styles.SettingsContainer, SETTINGS_CONTAINER_CLASS)}>
			{props.children}

			<div className={styles.SwitchContainer}>
				<label>{t('Switch', 'Trigger next gate on \'set value\'.')}</label>
				<KemuSwitch
					size="small"
					checked={fixedState.dispatchOnSet}
					onChange={handleSwitchChange}
				/>
			</div>
		</div>
	);
};


const getPortsInformation: GetPortsInformationFunction = (state: CustomWidgetState<ValueWidgetState>, widgetInfo, intl) => {
	const portContext: WidgetPortContext = { recipePoolId: widgetInfo.recipePoolId, recipeType: widgetInfo.recipeType };
	const outputNames = ValueWidgetProcessor.getOutputNames(state, portContext);
	const inputNames = ValueWidgetProcessor.getInputNames(state, portContext);
	const t = getTranslationFunction(intl, 'LogicMaker.Gates.Value.Port');

	const inputPositions: Record<string, PortLocation> = {
		'trigger': [0, 0.7, -1, 0],
		'setValue': state.dispatchOnSet ? 'Left' : [0, 0.3, -1, 0],
	};

	return {
		inputs: inputNames.map(input => ({
			name: input.name,
			type: input.type,
			position: inputPositions[input.name],
			label: t(input.name, input.name),
		})),

		outputs: outputNames.map(output => ({
			name: output.name,
			type: output.type,
			position: 'Right',
			label: output.label
		})),
	};
};

export default {
	getPortsInformation,
	BarIcon: GateBarIcon,
	Element: ValueWidget,
	CustomSettingsDialog: GateCustomSettings,
	hasTitle: true,
	getGatesBarTitle: (intl) => intl.formatMessage({ id: 'LogicMaker.Gates.Value.Title', defaultMessage: 'Value' }),
} as GateUI;
