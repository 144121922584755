import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { LimitedThingInfo } from '@kemu-io/kemu-types/dist/types';
import { RootState } from '../../app/store';
import { HardwareBlock } from '../../types/kemuCommLink';
import { overrideDevThings } from '../../common/utils';
import * as thingApi from '../../api/thing/thingApi';

// Local cache of block info to prevent multiple requests to the DB for the same block type
const blockInfoCache: Record<string, LimitedThingInfo> = {};

export interface HardwareBlockInfo extends HardwareBlock {
	dbInfo: LimitedThingInfo;
	icon: string;
}

// type HardwareBlocksMap = Record<string, HardwareBlockInfo>;

interface MainMenuState {
	hardwareBlocks: HardwareBlockInfo[];
}

const initialState: MainMenuState = {
	hardwareBlocks: []
};


const registerHardwareBlock = createAsyncThunk('/mainMenu/registerHardwareBlock', async (block: HardwareBlock): Promise<HardwareBlockInfo> => {
	if (!blockInfoCache[`${block.id}_${block.version}`]) {
		const info = await thingApi.getThingInfo(block.id, block.version);
		if (!info) { throw new Error(`Block ${block.id} not found`); }
		overrideDevThings([info]);
		blockInfoCache[`${block.id}_${block.version}`] = info;
	}

	const blockInfo: LimitedThingInfo = blockInfoCache[`${block.id}_${block.version}`];
	return {
		dbInfo: blockInfo,
		icon: blockInfo.bundle + '/icon.svg',
		...block
	};
});


export const mainMenuSlice = createSlice({
	name: 'mainMenu',
	initialState,
	reducers: {
		unregisterHardwareBlock: (state, action: PayloadAction<{id: string, version: string}>) => {
			// TODO: get the db info from the actions' id
			const filteredOut = state.hardwareBlocks.filter(block => block.dbInfo.id !== action.payload.id && block.dbInfo.version !== action.payload.version);
			state.hardwareBlocks = filteredOut;
		}
	},

	extraReducers: (builder) => {
		builder.addCase(registerHardwareBlock.fulfilled, (state, action) => {
			const exists = state.hardwareBlocks.find(block => block.id === action.payload.id && block.version === action.payload.version);
			if (exists) {
				// Abort adding the same hardware block info twice
				return;
			}

			const newBlocks = [
				...state.hardwareBlocks,
				action.payload
			];

			state.hardwareBlocks = newBlocks;
		});

		builder.addCase(registerHardwareBlock.rejected, (state, action) => {
			console.log('Error registering hardware block: ', action.error);
		});
	}
});


export const onlineBlocks = (state: RootState): HardwareBlockInfo[] => state.mainMenu.hardwareBlocks;

export const {
	unregisterHardwareBlock
} = mainMenuSlice.actions;

export {
	registerHardwareBlock
};

export default mainMenuSlice.reducer;
