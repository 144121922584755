import React, { MouseEventHandler, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDrag } from 'react-dnd';
import { CloseCircleFilled } from '@ant-design/icons';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { Position } from '@kemu-io/kemu-core/dist/types/block_t';
import { CustomWidgetVariant } from '@kemu-io/kemu-types/dist/types';
import CustomWidgetIcon from '../CustomWidgetIcon/CustomWidgetIcon';
import styles from './LaunchpadWidgetIcon.module.css';
import { FolderPathInfo } from '@src/features/Workspace/workspaceSlice';
import { DroppableTypes } from '@common/constants';
import TruncatedText from '@components/truncatedText/truncatedText';

export interface LaunchpadWidgetInfo {
	name: string;
	icon?: string;
	color?: string;
	id: string;
	variant: CustomWidgetVariant;
}
interface Props {
	widgetInfo: LaunchpadWidgetInfo;
	currentFolder: FolderPathInfo | null;
	recipePoolId: string;
	thingDbId: string;
	/** indicates the widget comes from a Thing */
	isThingTemplate: boolean;
	isSelected: boolean;
	disabled?: boolean;
	onClick?: (widgetId: string, thingDbId: string) => void;
	onDelete?: (widgetId: string, thingDbId: string) => void;
	onAddWidget: (widgetId: string, dropLocation: Position, thingDbId: string) => void;
	onHideLaunchpad: () => void;
}


const LaunchpadWidgetIcon = (props: Props): React.JSX.Element => {
	const [textExpanded, setTextExpanded] = useState(false);
	const { onAddWidget, widgetInfo, onHideLaunchpad } = props;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [{ isDragging }, drag, preview] = useDrag<any, Position, any>(() => ({
    type: DroppableTypes.CustomWidget,
		canDrag: !props.disabled,
    item: {
			color: widgetInfo.color,
			icon: widgetInfo.icon,
			isBundle: widgetInfo.variant === CustomWidgetVariant.Bundle,
		},
    end: (item, monitor) => {
      const dropLocation = monitor.getDropResult();
			if (dropLocation) {
				onAddWidget(props.widgetInfo.id, {
					x: dropLocation.x,
					y: dropLocation.y,
				}, props.thingDbId);
			}
    },

    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }), [widgetInfo, onAddWidget]);


	const handleDelete: MouseEventHandler = (event): void => {
		event.stopPropagation();
		props.onDelete && props.onDelete(props.widgetInfo.id, props.thingDbId);
	};


	const textVisibilityChanged = (visible: boolean) => {
		setTextExpanded(visible);
	};




	useEffect(() => {
		const emptyImage = getEmptyImage();
		preview(emptyImage);
		if (isDragging) {
			onHideLaunchpad();
		}
	}, [preview, isDragging, onHideLaunchpad]);

	return (
		<>
			<div
				className={
					classNames(styles.CustomWidgetButton, {
						'text-expanded': textExpanded,
						'selected': props.isSelected ,
						'disabled': props.disabled
					})
				}
				data-kemu-meta={props.thingDbId ? `thing-id-${props.thingDbId}` : undefined}
				data-kemu-type={`custom-widget-${props.widgetInfo.id}`}
				ref={drag}
				onClick={() => props.onClick && props.onClick(props.widgetInfo.id, props.thingDbId)}
			>

				<CustomWidgetIcon
					color={widgetInfo.color}
					icon={widgetInfo.icon}
					disabled={props.disabled}
					isBundle={widgetInfo.variant === CustomWidgetVariant.Bundle}
				/>

				{!props.isThingTemplate && (
					<div className={styles.DeleteBtn} onClick={handleDelete}>
						<CloseCircleFilled />
					</div>
				)}

				<div className="noselect gate-name">
					<label>
						<TruncatedText
							text={props.widgetInfo.name}
							maxChars={24}
							onVisibilityChange={textVisibilityChanged}
							showFullOn="hover"
						/>
					</label>
				</div>
			</div>
		</>
	);
};

export default LaunchpadWidgetIcon;
