
import React from 'react';
import ExtractImageProcessor, { ExtractImageState, getDefaultState } from '@kemu-io/kemu-core/dist/gates/extractImage';
import Icon from '@ant-design/icons';
import classNames from 'classnames';
import { WidgetPortContext } from '@kemu-io/kemu-core/dist/types/gate_t';
import useReactiveWidgetState from '../../../common/hooks/useReactiveWidgetState';
import { GateCustomSettingsProps, GateUI, GateUIProps, GetPortsInformationFunction } from '..';
import GateIcon from '../../gateIcon/gateIcon';
import { PortLocation } from '../../../types/canvas_t';
import useTranslation from '../../../common/hooks/useTranslation';
import useNumericInput from '../../../common/hooks/useNumericInput';
import { SETTINGS_CONTAINER_CLASS } from '../../../common/constants';
import { ReactComponent as ExtractImageIcon } from './extractImage.svg';
import styles from './extractImage.module.css';

interface Props extends GateUIProps {
	dataSources?: {
		name: string;
		inputs: string[]
	}[]
}

const mergeStates = (state: ExtractImageState) => {
	const fixedState: ExtractImageState = {
		...getDefaultState(),
		...state
	};

	return fixedState;
};

const ExtractImage = (props: Props): React.JSX.Element => {
	const [state] = useReactiveWidgetState<ExtractImageState>(props.recipeId, props.thingRecipeId, props.info.id);
	const fixedState = mergeStates(state);
	const t = useTranslation();

	return (
		<div className={`extractImage-gate standard-body ${styles.GateBody}`}>
			<div className="gate-input">
				<input type="text" value={fixedState.rect ? t('LogicMaker.Gates.ExtractImage.Active', 'Active') : t('LogicMaker.Gates.ExtractImage.Waiting', '...')} disabled/>
			</div>
		</div>
	);
};



const GateCustomSettings = (props: GateCustomSettingsProps): React.JSX.Element => {
	const t = useTranslation();
	const [state, setState] = useReactiveWidgetState<ExtractImageState>(props.recipeId, props.blockId, props.gateInfo.id);
	const fixedState = mergeStates(state);
	const numbersOnly = useNumericInput();

	const handleInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = (evt.currentTarget.value as unknown) as string;
		const propName = evt.currentTarget.getAttribute('data-name');
		if (!propName) { return; }
		if (isNaN(Number(newValue))) { return; }

		setState({
			...state,
			offset: {
				...state.offset,
				[propName]: newValue,
			}
		}, true);
	};

	return (
		<div className={classNames(styles.SettingsContainer, SETTINGS_CONTAINER_CLASS)}>
			{props.children}
			<label>Offset</label>
			<div className={styles.Column}>
				<div className="gate-input">
					<input type="text"
						title={t('LogicMaker.Gates.ExtractImage.Settings.Left', 'Left')}
						onInput={numbersOnly}
						placeholder='left'
						value={fixedState.offset.left  || ''}
						data-name="left"
						onChange={handleInputChange}
					/>
				</div>

				<div className="gate-input">
					<input type="text"
						title={t('LogicMaker.Gates.ExtractImage.Settings.Top', 'Top')}
						onInput={numbersOnly}
						placeholder='top'
						value={fixedState.offset.top  || ''}
						data-name="top"
						onChange={handleInputChange}
					/>
				</div>
			</div>

			<div className={styles.Column}>
				<div className="gate-input">
					<input type="text"
						title={t('LogicMaker.Gates.ExtractImage.Settings.Width', 'Width')}
						onInput={numbersOnly}
						placeholder='width'
						value={fixedState.offset.width  || ''}
						data-name="width"
						onChange={handleInputChange}
					/>
				</div>

				<div className="gate-input">
					<input type="text"
						title={t('LogicMaker.Gates.ExtractImage.Settings.Height', 'Height')}
						onInput={numbersOnly}
						placeholder='height'
						value={fixedState.offset.height  || ''}
						data-name="height"
						onChange={handleInputChange}
					/>
				</div>
			</div>
		</div>
	);
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={ExtractImageIcon} />}/>
	);
};


const getPortsInformation: GetPortsInformationFunction = (state, widgetInfo) => {
	const portContext: WidgetPortContext = { recipePoolId: widgetInfo.recipePoolId, recipeType: widgetInfo.recipeType };
	const outputNames = ExtractImageProcessor.getOutputNames(state, portContext);
	const inputNames = ExtractImageProcessor.getInputNames(state, portContext);

	const positions: Record<string, PortLocation> = {
		'image': [0, 0.33, -1, 0],
		'rect': [0, 0.66, -1, 0]
	};

	return {
		inputs: inputNames.map(input => ({
			name: input.name,
			type: input.type,
			position: positions[input.name]
		})),

		outputs: [{
			name: outputNames[0].name,
			type: outputNames[0].type,
			position: 'Right'
		}]
	};
};

export default {
	CustomSettingsDialog: GateCustomSettings,
	getPortsInformation,
	BarIcon: GateBarIcon,
	Element: ExtractImage,
	hasTitle: true,
	getWidgetTitle: (intl) => intl.formatMessage({ defaultMessage: 'Extract Image', id: 'LogicMaker.Gates.ExtractImage.Title' }),
} as GateUI;
