
import React from 'react';
import Icon from '@ant-design/icons';
import FirstEventWidgetProcessor, { FirstEventWidgetState, getDefaultState } from '@kemu-io/kemu-core/dist/gates/firstEvent';
import { FormattedMessage } from 'react-intl';
import { CustomWidgetState, WidgetPortContext } from '@kemu-io/kemu-core/dist/types/gate_t';
import { message } from 'antd';
import classNames from 'classnames';
import { RecipeType } from '@kemu-io/kemu-types/dist/types';
import { decodeChildPortIdentifier, getWidgetParents } from '../../../app/recipe/utils';
import GateIcon from '../../gateIcon/gateIcon';
import {
	GateCustomSettingsProps,
	GetPortsInformationFunction,
	GateUI,
	GateUIProps
} from '..';
import useReactiveWidgetState from '../../../common/hooks/useReactiveWidgetState';
import useTranslation from '../../../common/hooks/useTranslation';
import { PortLocation } from '../../../types/canvas_t';
import NumericInput from '../../WidgetsComponents/NumericInput/NumericInput';
import KemuSwitch from '../../form-control/kemuSwitch/kemuSwitch';
import { StatelessRecipeWidget } from '../../../types/core_t';
import { SETTINGS_CONTAINER_CLASS } from '../../../common/constants';
import styles from './firstEvent.module.css';
import { ReactComponent as FirstEventWidgetIcon } from './firstEvent.svg';

type Props = GateUIProps


const mergeStates = (state: FirstEventWidgetState) => {
	const fixedState: FirstEventWidgetState = {
		...getDefaultState(),
		...state
	};

	return fixedState;
};

const FirstEventWidget = (props: Props): React.JSX.Element => {
	const [state, setState] = useReactiveWidgetState<FirstEventWidgetState>(props.recipeId, props.thingRecipeId, props.info.id);
	const fixedState = mergeStates(state);
	const t = useTranslation();

	const onNumberChange = (value: number) => {
		setState({
			...fixedState,
			delayMs: value
		}, true);
	};

	// 54 = Default height
	// -1 = default height is calculated based on a minimum input number of 1
	// 10 = extra pixels per added port
	const bodyHeight = 54 + ((fixedState.inputs - 1) * 10);
	return (
		<div className={`${styles.GateBody}`}>

			<div className={styles.InnerBody} style={{ height: bodyHeight }}>
				<span className={styles.Title}>{t('LogicMaker.Gates.FirstEvent.Pause', 'Pause')}</span>
				<div className={`gate-input`}>
					<NumericInput
						placeholder={t('LogicMaker.Gates.FirstEvent.Placeholder', 'ms')}
						min={0}
						value={state.delayMs}
						onChange={onNumberChange}
					/>
				</div>
			</div>

		</div>
	);
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={FirstEventWidgetIcon} />}/>
	);
};

type LastPortInfo = {index: number, portName: string};
const getLastInputPortWithParent = (
	state: FirstEventWidgetState,
	gateId: string,
	blockId: string,
	recipeId: string,
	recipeType: RecipeType
): LastPortInfo | null => {
	const inputNames = FirstEventWidgetProcessor.getInputNames(state, { recipePoolId: recipeId, recipeType });
	const parents = getWidgetParents(gateId, blockId, recipeId);

	let lastPortWithConnection = -1;
	parents.forEach((parent) => {
		const identifier = decodeChildPortIdentifier(parent.targetPort);
		if (identifier.portIndex > lastPortWithConnection) { lastPortWithConnection = identifier.portIndex; }
	});

	if (lastPortWithConnection > -1) {
		return {
			portName: inputNames[lastPortWithConnection].name ,
			index: lastPortWithConnection
		};
	}

	return null;
};

const getLastOutputPortWithChildren = (
	state: FirstEventWidgetState,
	gateInfo: StatelessRecipeWidget,
	recipeType: RecipeType,
	recipePoolId: string,
): LastPortInfo | null => {
	let lastPortWithConnection = 0;
	const outputNames = FirstEventWidgetProcessor.getOutputNames(state, { recipePoolId, recipeType });

	gateInfo.children.forEach((child) => {
		const identifier = decodeChildPortIdentifier(child.sourcePort);
		if (identifier.portIndex > lastPortWithConnection) { lastPortWithConnection = identifier.portIndex; }
	});

	if (lastPortWithConnection >= 1) {
		return {
			portName: outputNames[lastPortWithConnection].name ,
			index: lastPortWithConnection
		};
	}

	return null;
};

const GateCustomSettings = (props: GateCustomSettingsProps): React.JSX.Element => {
	const [state, setState] = useReactiveWidgetState<FirstEventWidgetState>(props.recipeId, props.blockId, props.gateInfo.id);
	const fixedState = mergeStates(state);
	const t = useTranslation('LogicMaker.Gates.FirstEvent.');

	/** returns true if a notification is shown */
	const warnIfOutputAttached = (info: LastPortInfo | null, minIndex: number): boolean => {
		if (info && info.index >= minIndex) {
			message.warning({
				content: t(
					'LogicMaker.Gates.Json.RemoveWarning',
					'Please remove all connections from "{name}"',
					{ name: info.portName }
				)
			});
			return true;
		}

		return false;
	};

	const handleInputChange = (value: number) => {

		const lastConnectedInput = getLastInputPortWithParent(
			fixedState,
			props.gateInfo.id,
			props.blockId,
			props.recipeId,
			props.recipeType
		);

		if (lastConnectedInput && lastConnectedInput.index >= value) {
			message.warning({
				content: t(
					'RemoveWarning',
					'Please remove all connections from "{name}"',
					{ name: lastConnectedInput.portName }
				)
			});
			return;
		}

		// Also make sure no outputs are connected
		const lastConnectedOutput = getLastOutputPortWithChildren(fixedState, props.gateInfo, props.recipeType, props.recipeId);
		if (warnIfOutputAttached(lastConnectedOutput, value)) { return; }

		setState({
			...fixedState,
			inputs: value
		}, true);
	};

	const handleSwitchChange = (checked: boolean) => {
		const lastConnectedOutput = getLastOutputPortWithChildren(fixedState, props.gateInfo, props.recipeType, props.recipeId);
		if (warnIfOutputAttached(lastConnectedOutput, 1)) { return; }

		setState({ ...fixedState, matchingOutputs: checked });
	};

	return (
		<div className={classNames(styles.SettingsContainer, SETTINGS_CONTAINER_CLASS)}>
			{props.children}
			<div className={styles.InputContainer}>
				<label>
					<FormattedMessage id="LogicMaker.Gates.Json.CustomSettingsTitle" defaultMessage="Total outputs" />
				</label>
				<NumericInput
					min={2}
					max={20}
					value={fixedState.inputs}
					onChange={handleInputChange}
				/>
			</div>

			<div className={styles.SwitchContainer}>
				<label>Match outputs</label>
				<KemuSwitch size="small" checked={fixedState.matchingOutputs} onChange={handleSwitchChange} />
			</div>
		</div>
	);
};

const getPortsInformation: GetPortsInformationFunction = (state: CustomWidgetState<FirstEventWidgetState>, widgetInfo) => {
	const portContext: WidgetPortContext = { recipePoolId: widgetInfo.recipePoolId, recipeType: widgetInfo.recipeType };
	const outputNames = FirstEventWidgetProcessor.getOutputNames(state, portContext);
	const inputNames = FirstEventWidgetProcessor.getInputNames(state, portContext);

	// const positionByOutputName: Record<string, PortLocation> = {
	// 	'output': [1, 0.3, 1, 0],
	// 	'aborted': [1, 0.7, 1, 0]
	// };

	const inputFraction = ((1 - 0.08) / inputNames.length);

	const getPositionFromIndex = (index: number, isInput: boolean): PortLocation => {
		let space = (inputFraction * (index + 1));
		if (state.inputs === 1) { space = 0.4 + (0.33 * index); }
		if (state.inputs === 2) { space = 0.24 + (0.26 * index); }
		if (state.inputs === 3) { space = 0.2 + (0.2 * index); }
		if (state.inputs === 4) { space = 0.15 + (0.17 * index); }
		if (state.inputs === 5) { space = 0.14 + (0.14 * index); }

		return [isInput ? 0 : 1, space, isInput ? -1 : 1, 0];
	};

	return {
		outputs: outputNames.map((output, i) => ({
			position: state.matchingOutputs ? getPositionFromIndex(i, false) : [1, i === 0 ? 0.33 : 0.66, 1, 0],
			type: output.type,
			name: output.name
		})),

		inputs: inputNames.map((input, i) => {

			return  {
				name: input.name,
				type: input.type,
				// position: [0, space, -1, 0]
				position: getPositionFromIndex(i, true)
			};
		})
	};
};

export default {
	getPortsInformation,
	BarIcon: GateBarIcon,
	Element: FirstEventWidget,
	CustomSettingsDialog: GateCustomSettings,
	hasTitle: true,
	getWidgetTitle: (intl) => intl.formatMessage({ id: 'LogicMaker.Gates.FirstEvent.Title', defaultMessage: 'First Event' }),
} as GateUI;
