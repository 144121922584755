import { EditorState, Entity, AtomicBlockUtils, ContentState, SelectionState, Modifier } from 'draft-js';



/** Creates a new image block */
const addImage = (editorState: EditorState, url: string, width: string, height: string, uploading: boolean): {editorState: EditorState, blockKey: string} => {
  /*const urlType = 'IMAGE';
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(
    urlType,
    'IMMUTABLE',
    { ...extraData, src: url }
  );
	const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  const newEditorState = AtomicBlockUtils.insertAtomicBlock(
    editorState,
    entityKey,
    ' '
	);

  return EditorState.forceSelection(
    newEditorState,
    newEditorState.getCurrentContent().getSelectionAfter()
  );*/

  // ======== Adding image without spaces around ==========
  const contentState = editorState.getCurrentContent();

  const entityKey = Entity.create('IMAGE', 'IMMUTABLE', { src: url, width, height, uploading });
  const withAtomic = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');
  const newContentState = withAtomic.getCurrentContent();
  const blockMap = newContentState.getBlockMap();
  const currentAtomicBlock = blockMap.find((block) => {
    return !!(block && block.getEntityAt(0) === entityKey);
  });

  const atomicBlockKey = currentAtomicBlock.getKey();
  const blockBefore = newContentState.getBlockBefore(atomicBlockKey)?.getKey();
  const newBlockMap = blockMap.filter(block => {
    return !!(block && block.getKey() !== blockBefore);
  });

  const finalContentState = contentState.set('blockMap', newBlockMap) as ContentState;
  const newEditorState = EditorState.createWithContent(finalContentState);

  return {
    editorState: newEditorState,
    blockKey: entityKey
  };
};

const getImageDataFromUrl = async (url: string): Promise<Blob> => {
  const response = await fetch(url);
  const blob = await response.blob();
  return blob;
};

interface FileInput extends HTMLInputElement{
	onFilesSelected?: (files: FileList) => void;
	onFileLoaded?: (mimeType: string, content: string | ArrayBuffer) => void;
}

/** Creates a file selector dialog */
const buildFileSelector = (accept?: string, inBase64=true): FileInput => {
  const fileSelector: FileInput = document.createElement('input');
  fileSelector.setAttribute('type', 'file');
	fileSelector.setAttribute('multiple', 'multiple');
	fileSelector.setAttribute('accept', accept || 'image/x-png,image/gif,image/jpeg,image/svg+xml');

	const handleSelectedFile = async (e: Event) => {
		const target = e.target as HTMLInputElement;
    const files = target.files;
    if (!files || files.length < 1) { return; }

    if (fileSelector.onFilesSelected) {
      fileSelector.onFilesSelected(files);
    }

    if (fileSelector.onFileLoaded) {
      const file = files[0];
      const fileData = await getFileContents(file, inBase64);
      fileSelector.onFileLoaded(fileData.mimeType, fileData.content);
    }

    // Allow adding the same file multiple times
    fileSelector.value = '';
	};

	fileSelector.onchange = handleSelectedFile;

  return fileSelector;
};

/** Removes the given block */
const removeBlockContent = (contentState: ContentState, blockKey: string): ContentState => {
	const afterKey = contentState.getKeyAfter(blockKey);
  const afterBlock = contentState.getBlockForKey(afterKey);
  let targetRange;

  console.log('Block key: ', blockKey);
  // Only if the following block the last with no text then the whole block
  // should be removed. Otherwise the block should be reduced to an unstyled block
  // without any characters.
  if (afterBlock &&
    afterBlock.getType() === 'unstyled' &&
    afterBlock.getLength() === 0 &&
    afterBlock === contentState.getBlockMap().last()) {
    targetRange = new SelectionState({
      anchorKey: blockKey,
      anchorOffset: 0,
      focusKey: afterKey,
      focusOffset: 0,
    });
  } else {
    targetRange = new SelectionState({
      anchorKey: blockKey,
      anchorOffset: 0,
      focusKey: blockKey,
      focusOffset: 1,
    });
  }

  // change the blocktype and remove the characterList entry with the block
  const newContentState = Modifier.setBlockType(
    contentState,
    targetRange,
    'unstyled'
  );
  return Modifier.removeRange(newContentState, targetRange, 'backward');
};

/** Extracts the contents from a file */
const getFileContents = <T extends boolean=true>(file: Blob, inBase64: T | true = true): Promise<{content: T extends true ? string : ArrayBuffer, mimeType: string}> => {
  return new Promise((resolve, reject) => {

    const onFileLoaded = (e: ProgressEvent<FileReader>) => {
      const target = e.target;
      if (!target) { return reject('NO_TARGET_FOUND'); }

      if (!inBase64) {
        return resolve({
          content: target.result as T extends true ? string : ArrayBuffer,
          mimeType: file.type
        });
      }

      const base64 = target.result as string;
      resolve({
        content: base64 as T extends true ? string : ArrayBuffer,
        mimeType: file.type
      });
    };

    const reader = new FileReader();
    reader.onload = onFileLoaded;
    if (inBase64) {
      reader.readAsDataURL(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  });
};

export {
	buildFileSelector,
  addImage,
  removeBlockContent,
  getImageDataFromUrl,
  getFileContents
};
