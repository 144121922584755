import React, { ComponentType, ImgHTMLAttributes, useRef, useState, forwardRef, useEffect } from 'react';
import { ContentBlock, ContentState, SelectionState, EditorState, DraftHandleValue } from 'draft-js';
import { EditorPlugin, PluginFunctions, GetSetEditorState } from '@draft-js-plugins/editor';
import Icon, { EditFilled, LoadingOutlined } from '@ant-design/icons';
import b64Tools from 'base64-arraybuffer';
import { ReactComponent as ImageIcon }  from '../../../../assets/img/tutorialBuilder/image.svg';
import { BlockTypeSelectChildProps } from '../sideToolbar/components/blockTypeSelect';
import { updateBlockMeta } from '../utils';
import { buildAssetRelativeUrl, buildFullAssetUrl } from '../../utils';
import imageStyle from './imageStyle.module.css';
import { addImage, getFileContents, buildFileSelector } from './utils';

interface ImageButtonProps extends BlockTypeSelectChildProps {
	handleUpload?: UploadFileHandler
}

export type ImagePlugin = EditorPlugin & {
  ImageButton: ComponentType<BlockTypeSelectChildProps>;
};

export type UploadFileHandler = (fileContent: ArrayBuffer, mimeType: string) => Promise<{url: string, id: string}>;

interface PluginConfig {
	decorator?(component: ComponentType<ImageProps>): ComponentType<ImageProps>;
	/** a method to call when a new image is dropped. It should return a url to read the image data back from */
	handleUpload?: UploadFileHandler;
	tutorialId: string;
	userId: string;
}
interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
	block: ContentBlock;
  className?: string;
  contentState: ContentState;
	offsetKey: string;
	selection: SelectionState;
  blockProps: GetSetEditorState & {
		tutorialId: string;
		userId: string;
		width: string;
		height: string;
		src: string;
		fileId: string;
		uploading: boolean;
		getReadOnly(): boolean;
		setReadOnly(value: boolean): void;
	};

  //removed props
  blockStyleFn: unknown;
  customStyleMap: unknown;
  customStyleFn: unknown;
  decorator: unknown;
  forceSelection: unknown;
  tree: unknown;
	preventScroll: unknown;
	clicked?: boolean;
}

const DEFAULT_IMAGE_WIDTH = '250';
const DEFAULT_IMAGE_HEIGHT = 'auto';

const isBase64Content = (content: string) => content?.startsWith('data:image/png;base64');

type UploadIndicatorProps = {
	uploading: boolean;
	inReadMode: boolean;
	imageEl: HTMLImageElement | null;
}

const UploadIndicator = (props: UploadIndicatorProps): React.JSX.Element | null => {
	const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });

	useEffect(() => {
		const image = props.imageEl;
		if (image) {
			image.onload = () => {
				setImageDimensions({
					width: image.clientWidth,
					height: image.clientHeight
				});
			};
		}

		return () => {
			if (image) { image.onload = null; }
		};
	}, [props.imageEl]);

	if (props.inReadMode || !props.uploading) { return null; }
	return (
		<span className={imageStyle.UploadIndicator} style={{
			// this in order to make sure the loader fits within the image preview being uploaded
			width: imageDimensions.width,
			height: imageDimensions.height
		}}>
			<LoadingOutlined style={{ fontSize: 54 }} spin/>
		</span>
	);
};

/** Custom block component (the component that gets added to the editor) */
const ThemedImage = forwardRef<HTMLDivElement, ImageProps>((props, ref): React.JSX.Element => {
	const { getReadOnly, setReadOnly, width, height, src, getEditorState, setEditorState } = props.blockProps;
	const [editing, setEditing] = useState(false);
	const settings = useRef({ width, height });
	const inReadMode = getReadOnly();
	const imageElRef = useRef<HTMLImageElement>(null);
	// Prepend the correct domain to the source. Since v2 of the tutorial protocol, assets are relative.
	const fixedSource = isBase64Content(src) ? src : buildFullAssetUrl(src, props.blockProps.userId, props.blockProps.tutorialId);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { block, className, blockProps, contentState, style, selection, ...otherProps } = props;

  // leveraging destructuring to omit certain properties from props
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
		customStyleMap,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		customStyleFn,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		decorator,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		forceSelection,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		offsetKey,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		tree,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		blockStyleFn,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
    preventScroll,
    ...elementProps
	} = otherProps;

	/** Image delete button */
	// const onDeleteImage = (evt: React.MouseEvent<HTMLSpanElement>)=>{
	// 	console.log('Entity at 0: ', selection.getFocusKey());
	// 	evt.preventDefault();
	// 	const newContent = removeBlockContent(contentState, selection.getFocusKey());
	// 	const currentState = blockProps.getEditorState();
	// 	blockProps.setEditorState(EditorState.push(currentState, newContent, 'delete-character'));
	// };

	// const { src } = contentState.getEntity(block.getEntityAt(0)).getData();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { clicked, ...cleanElProps } = elementProps;

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;

    if (target && target.name) {
      const value = target.value;
      const elName = target.name;
      settings.current = {
        ...settings.current,
        [elName]: value
      };
    }
	};

	const finishEditing = () => {
		blockProps.setReadOnly(false);
		setEditing(false);
		const newState = updateBlockMeta(getEditorState(), block, settings.current );
		setEditorState(newState);
	};

	const onEdit = () => {
    setReadOnly(true);
    setEditing(true);
	};

	const pixelW = isNaN(Number(width)) ? width : `${width}px`;
	const pixelH = isNaN(Number(height)) ? height : `${height}px`;

	const EditSettingsOverlay = (): React.JSX.Element => {
    return (
      <div className={imageStyle.SettingsWrapper} style={{ width: pixelW, ...style, display: 'flex' }}>
        <div className={imageStyle.Controls}>
          <div className={imageStyle.DimensionsContainer}>
            <label>W</label>
            <input onChange={handleChange} defaultValue={settings.current.width} className={imageStyle.DimensionInput} type="text" name="width" />
            <label>H</label>
            <input onChange={handleChange} defaultValue={settings.current.height} className={imageStyle.DimensionInput} type="text" name="height" />
          </div>
          <button className={imageStyle.SaveButton} onClick={finishEditing}>Save</button>
        </div>
      </div>
    );
  };

	const EditButton = (): React.JSX.Element | null => {
    if (inReadMode) { return null; }
    return (
      <span className={imageStyle.EditButton} onClick={onEdit} >
        <EditFilled style={{ fontSize: 24 }}/>
      </span>
    );
	};


	if (style) {
		if (style.float === 'left') { style.marginRight = '15px'; }
		if (style.float === 'right') { style.marginLeft = '15px'; }
	}


	return (
		<div ref={ref} className={`${imageStyle.ImageBlock} ${className}`} {...cleanElProps} style={{ width: pixelW, height: pixelH, ...style }}>
			<EditButton />
			<UploadIndicator
				uploading={blockProps.uploading}
				inReadMode={inReadMode}
				imageEl={imageElRef.current}
			/>

			{editing && (
				<EditSettingsOverlay />
			)}

			<img
				ref={imageElRef}
				src={fixedSource}
				data-id={blockProps.fileId}
				style={{ visibility: editing ? 'hidden' : 'visible' }}
			/>
		</div>
	);
});



const handleImageFileAdded = async (file: Blob, { getEditorState, setEditorState }: GetSetEditorState, handleUpload?: UploadFileHandler, selection?: SelectionState) => {

	const getCurrentState = (): EditorState => {
		let currentState = getEditorState();
		if (selection) {
			currentState = EditorState.forceSelection(currentState, selection);
		}

		return currentState;
	};

	try {
		// If there is a handler to upload the file, read as binary directly
		const readAsBase64 = !handleUpload;
		const fileData = await getFileContents(file, readAsBase64);
		const currentState = getCurrentState();


		// Upload to server
		if (handleUpload) {
			// Show the image content's with an upload indicator
			const base64Contents = `data:image/png;base64,${b64Tools.encode(fileData.content)}`;
			const addedImage = addImage(currentState, base64Contents, DEFAULT_IMAGE_WIDTH, DEFAULT_IMAGE_HEIGHT, true);
			setEditorState(addedImage.editorState);

			// Upload file
			const uploadedFile = await handleUpload(fileData.content, fileData.mimeType);

			// Replace the image content's with the uploaded file's URL and remove the loading symbol
			// NOTE: while an image is being uploaded, the user might decide to change its size, so
			// we need to read again the current entity's metadata to avoid overriding the user's updated changes
			// since the image was added
			const contentState = getEditorState().getCurrentContent();
			const entity = contentState.getEntity(addedImage.blockKey);
			const imageMeta = entity.getData();

			// Make sure the saved asset url inly refers to the container folder and asset id
			const fileSrc = buildAssetRelativeUrl(uploadedFile.url);
			if (!fileSrc) { throw new Error(`Failed to decode upload asset URL: ${uploadedFile.url}`); }

			const finalState = updateBlockMeta(
				getEditorState(),
				addedImage.blockKey,
				{
					// Merge current state
					...imageMeta,
					uploading: false,
					src: fileSrc,
					fileId: uploadedFile.id
				}
			);

			setEditorState(finalState);
		} else {
			// Just display the image as base64
			const addedImage = addImage(currentState, fileData.content as unknown as string, DEFAULT_IMAGE_WIDTH, DEFAULT_IMAGE_HEIGHT, false);
			setEditorState(addedImage.editorState);
		}

	} catch (e) {
		console.error('Error processing image: ', e);
	}
};


/** Plugin constructor */
const createImagePlugin = (config: PluginConfig): ImagePlugin => {

	const ImageComponent = config?.decorator ? config.decorator(ThemedImage) : ThemedImage;

	const blockRendererFn = (contentBlock: ContentBlock, { getEditorState, setEditorState, getReadOnly, setReadOnly }: PluginFunctions) => {
		if (contentBlock.getType() === 'atomic') {
			const contentState = getEditorState().getCurrentContent();
			const entityKey = contentBlock.getEntityAt(0);
			if (!entityKey) { return null; }
			const entity = contentState.getEntity(entityKey);
			const type = entity.getType();
			if (type === 'IMAGE' || type === 'image') {
				const data = entity.getData();
				return {
					component: ImageComponent,
					editable: false,
					props: {
						...data,
						userId: config.userId,
						tutorialId: config.tutorialId,
						getReadOnly,
						setReadOnly,
						setEditorState,
						getEditorState
					}
				};
			}
			return null;
		}

		return null;
  };


	const handlePastedImage = (files: Blob[], pluginFunctions: PluginFunctions, selection?: SelectionState): DraftHandleValue => {
		if (files[0].type.match(/image\/*/g) === null) { return 'not-handled'; }
		handleImageFileAdded(files[0], pluginFunctions, config?.handleUpload, selection);
		return 'handled';
	};


	const handleDroppedImage = (selection: SelectionState, files: Blob[], pf: PluginFunctions): DraftHandleValue => {
		return handlePastedImage(files, pf, selection);
	};

	// TODO: Implement in the future (maybe?) disallowed by cors (obviously)
	// const handleExternalDrop = (selection: SelectionState, dataTransfer: {data: DataTransfer}, isInternal: DraftDragType, pluginFunctions: PluginFunctions): DraftHandleValue =>{
	// 	const raw = dataTransfer.data.getData('text/uri-list');

	// 	const getDataNow = async (url: string) => {
	// 		const blob = await getImageDataFromUrl(url);
	// 		handlePastedImage([blob], pluginFunctions, selection);
	// 	};

	// 	// If a url
	// 	if(raw.match(/^http[s]?:\/\//)){
	// 		getDataNow(raw);
	// 		return 'handled';
	// 	}

	// 	return 'not-handled';
	// };

  return {
		// handleDrop: handleExternalDrop,
		handlePastedFiles: handlePastedImage,
		handleDroppedFiles: handleDroppedImage,
		blockRendererFn: blockRendererFn,
		ImageButton: (buttonProps) => (
			<ImageButton
				{...buttonProps}
				handleUpload={config?.handleUpload}
			/>
		)
  };
};

/** Actual icon displayed within the side bar */
const ImageButton = (props: ImageButtonProps): React.JSX.Element => {
	const fileSelectorRef = useRef(buildFileSelector(undefined, false));

	const handleAddImage = () => {
		fileSelectorRef.current.onFilesSelected = (files) => {

			handleImageFileAdded(files[0], {
				setEditorState: props.setEditorState,
				getEditorState: props.getEditorState,
			}, props.handleUpload);

			props.hideToolbar();
		};

		fileSelectorRef.current.click();
	};

	return (
		<span className={props.theme.button} onClick={handleAddImage}>
			<Icon component={ImageIcon} style={{ fontSize: 24 }}/>
		</span>
	);
};

export default createImagePlugin;
