import React, { useState } from 'react';
import { Row, Col, Spin, Alert } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { LoadingOutlined } from '@ant-design/icons';
import { Auth } from '@aws-amplify/auth';
import { RouteComponentProps } from 'react-router-dom';
import AuthCard from '../authCard';
import FormGroup from '../../../components/form-control/formGroup/formGroup';
import StyledButton from '../../../components/form-control/styledButton/styledButton';
import StyledInput from '../../../components/form-control/styledInput/styledInput';
import useQueryString from '../../../common/hooks/useQueryString';
import PasswordReplace from '../passwordReplace/passwordReplace';
import { decodeCognitoError } from '../../../common/utils';
import routes from '../../../common/routes';
import styles from './login.module.css';

interface Props extends RouteComponentProps{
	email?: string;
	successMessage?: string;
}

interface FormElements {
	username: string;
	password: string;
	errors: {
		username?: string;
		password?: string;
	}
}

interface Message{
	type: 'success' | 'error',
	text: string;
}


const Login = (props: Props): React.JSX.Element => {
	const queryString = useQueryString();
	const intl = useIntl();
	const [message, setMessage] = useState<Message | null>(props.successMessage ? { type: 'success', text: props.successMessage } : null);
	const [processing, setProcessing] = useState(false);
	const [showReplacePassword, setShowReplacePassword] = useState<any | null>(null);

	const [formElements, setFormElements] = useState<FormElements>({
		username: queryString.get('email') || '',
		password: '',
		errors: {}
	});

	const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
		const elName = event.currentTarget.getAttribute('name') || undefined;
		const value = event.currentTarget.value;
		if (elName !== undefined && value !== undefined) {
			setFormElements(elements => {
				return {
					...elements,
					[elName]: value!
				};
			});
		}
	};

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		const elements = { ...formElements };
		let formHasErrors = false;
		elements.errors = {};

		if (elements.username.trim() === '') {
			elements.errors.username = intl.formatMessage({ id: 'Screen.SignIn.RequiredField', defaultMessage: '* required' });
			formHasErrors = true;
		}

		if (elements.password.trim() === '') {
			elements.errors.password = intl.formatMessage({ id: 'Screen.SignIn.RequiredField', defaultMessage: '* required' });
			formHasErrors = true;
		}

		if (formHasErrors) {
			setFormElements(elements);
			return;
		}

		setProcessing(true);
		setMessage(null);
		try {
			const res = await Auth.signIn(formElements.username, formElements.password);
			console.log('Sign In res: ', res);
			if (res.challengeName === 'NEW_PASSWORD_REQUIRED') {
				// Res contains a CognitoUser with extra parameters that don't match the default signature, hence using 'any'
				setShowReplacePassword(res);
			}
		} catch (e) {
			if (e.code) {
				const cognitoError = decodeCognitoError(e.message);
				if (e.code === 'UserLambdaValidationException' && cognitoError) {
					setMessage({
						type: 'error',
						text: intl.formatMessage({ id: `Screen.SignIn.${cognitoError.code}`, defaultMessage: e.message })
					});
				} else {
					setMessage({
						type: 'error',
						text: intl.formatMessage({ id: `Screen.SignIn.${e.code}`, defaultMessage: e.message })
					});
				}
			} else {
				console.log('Error: ', e);
				setMessage({
					type: 'error',
					text: intl.formatMessage({ id: 'Screen.SignIn.UnknownError', defaultMessage: 'There was an error processing your request. Please try again later.' })
				});
			}

		}

		setProcessing(false);
	};

	if (showReplacePassword) { return (<PasswordReplace user={showReplacePassword} oldPassword={formElements.password} backToLogin={() => setShowReplacePassword(false)}/>); }

	return (
		<AuthCard title={<FormattedMessage id="Screen.SignIn.Title" defaultMessage="Register" />} >
			<Spin indicator={<LoadingOutlined />} spinning={processing} >
				{message && (
					<>
						<Alert closable={true} message={message.text} type={message.type} />
						<br/>
					</>
				)}

				<form method="POST" onSubmit={handleSubmit}>
					<Row>
						<Col span={24}>
							<FormGroup>
								<StyledInput i18nPlaceholderId="Screen.SignIn.UsernameOrEmail" i18nPlaceholderDefaultMessage="Username or email" name="username" onChange={handleChange} value={formElements.username} errorMessage={formElements.errors.username}/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<FormGroup className={styles.PasswordGroup}>
								<StyledInput i18nPlaceholderId="Screen.SignIn.Password" i18nPlaceholderDefaultMessage="Password" name="password" type="password" onChange={handleChange} value={formElements.password} errorMessage={formElements.errors.password}/>
							</FormGroup>
							<div className={styles.ForgotLink}>
								<a href={routes.auth.getForgotPasswordRoute()}>
									<FormattedMessage id="Screen.SignIn.ForgotPassword" defaultMessage="Forgot Password" />
								</a>
							</div>
						</Col>
					</Row>

					<Row>
						<Col span={24}>
							<FormGroup className={styles.SubmitContainer}>
								<StyledButton title={<FormattedMessage id="Screen.SignIn.SubmitButton" defaultMessage="Sign In" />} type="submit" className={styles.SubmitButton} />
							</FormGroup>
						</Col>
					</Row>
				</form>

				<div className={styles.CardBottomText}>
					<FormattedMessage id="Screen.SignIn.NotRegisteredYet" defaultMessage="Don't have an account?"/>
					&nbsp;<a href={routes.auth.getSignUpRoute()}><FormattedMessage id="Screen.SignIn.CreateAccountLink" defaultMessage="Create One" /></a>
				</div>
			</Spin>
		</AuthCard>
	);
};

export default Login;
