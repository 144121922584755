/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useState } from 'react';
import Editor from '@draft-js-plugins/editor';
import { EditorState } from 'draft-js';
import classNames from 'classnames';
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  UnorderedListButton,
  OrderedListButton,
} from '@draft-js-plugins/buttons';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import createStaticToolbarPlugin, { StaticToolbarProps } from '../../../features/TutorialBuilder/plugins/staticToolbar';
import HeaderPicker from '../../../features/TutorialBuilder/plugins/headerPicker/headerPicker';

import createColorPickerPlugin, { CustomPickerProps } from '../../../features/TutorialBuilder/plugins/colorPicker';
import styles from './BasicEditor.module.css';

// Custom styles for all draftJs editors
import '../../layout/editors.css';

interface Props {
	id: string;
	editorState: EditorState;
	updateEditorState: (newState: EditorState) => void;
	readOnly: boolean;
	onEditingStatus: (editing: boolean) => void;
	className?: string;
	linkTypeClass?: string;
	additionalPlugins?: React.ComponentType<CustomPickerProps>[];
}


type BuildPluginsConfig = {
	linkTypeClass?: string;
}

type BuildPluginsResult = {
	plugins: any[],
	ColorPickerButton: React.ComponentType<CustomPickerProps>,
	Toolbar: React.ComponentType<StaticToolbarProps>,
	Separator: () => React.ReactElement<any, string | React.JSXElementConstructor<any>>
}

const buildPlugins = (props: BuildPluginsConfig): BuildPluginsResult => {
	const linkifyPlugin = createLinkifyPlugin({
		target: '_blank',
		component: (linkProps) => {
			return (
				<a {...linkProps} className={classNames(props.linkTypeClass)} />
			);
		}
	});

	const colorPickerPlugin = createColorPickerPlugin();
	const { ColorPickerButton } = colorPickerPlugin;
	const { plugins: defaultBarPlugins, Toolbar, Separator } = createStaticToolbarPlugin();

	return {
		plugins: [
			...defaultBarPlugins,
			colorPickerPlugin,
			linkifyPlugin
		],
		ColorPickerButton,
		Toolbar,
		Separator
	};
};


const BasicEditor = (props: Props): React.JSX.Element => {
	const editorRef = useRef<HTMLDivElement | null>(null);
	const [{ plugins, ColorPickerButton, Separator, Toolbar }] = useState(() => {
		return buildPlugins(props);
	});


	return (
		<>
			<div
				className={classNames(styles.Editor, props.className, { 'read-only': props.readOnly })}
				// Disable grammarly
				{...(props.readOnly ? { 'data-enable-grammarly': false } : undefined)}
			>
				<div
					ref={editorRef}
					className="editor-wrapper"
				>
					<Editor
						editorState={props.editorState}
						onChange={props.updateEditorState}
						plugins={plugins}
						readOnly={props.readOnly}
					/>
				</div>


				{!props.readOnly && (
					<Toolbar>
						{
							(externalProps) => (
								<>
									<BoldButton {...externalProps} />
									<ItalicButton {...externalProps} />
									<UnderlineButton {...externalProps} />
									<HeaderPicker {...externalProps} />
									<UnorderedListButton {...externalProps} />
									<OrderedListButton {...externalProps} />

									{/* Text style */}
									<ColorPickerButton
										{...externalProps}
										containerRef={editorRef}
										wrapperClassName={styles.ColorPickerWrapper}
										hideOnColorChange
									/>

									{props.additionalPlugins?.length && (
										<>
											<Separator />
											{ props.additionalPlugins?.map((Plugin, index) => {
												return (
													<Plugin key={index} {...externalProps} />
												);
											})}
										</>
									)}
								</>
							)
						}
					</Toolbar>
				)}
			</div>
		</>
	);
};

export default BasicEditor;
