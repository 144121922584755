import React from 'react';
import { Select } from 'antd';
import Icon from '@ant-design/icons';
import MultiplicationWidgetProcessor, { MultiplicationWidgetState, getDefaultState } from '@kemu-io/kemu-core/dist/gates/multiplication';
import classNames from 'classnames';
import { WidgetPortContext } from '@kemu-io/kemu-core/dist/types/gate_t';
import { GetPortsInformationFunction, GateUI, GateUIProps, GateCustomSettingsProps } from '../index';
import useReactiveWidgetState from '../../../common/hooks/useReactiveWidgetState';
import GateIcon from '../../gateIcon/gateIcon';
import { PortLocation } from '../../../types/canvas_t';
import useTranslation from '../../../common/hooks/useTranslation';
import NumericInput from '../../WidgetsComponents/NumericInput/NumericInput';
import { SETTINGS_CONTAINER_CLASS } from '../../../common/constants';
import styles from './multiplication.module.css';
import { ReactComponent as ElapsedGateIcon } from './icon.svg';

const { Option } = Select;
interface Props extends GateUIProps {
	repaint: ()=> void;
}


const MultiplicationWidget = (props: Props): React.JSX.Element => {
	const [state, setState] = useReactiveWidgetState<MultiplicationWidgetState>(props.recipeId, props.thingRecipeId, props.info.id);
	const fixedState = {
		...getDefaultState(),
		...state,
	};

	const minInputChanged = (newValue: number) => {
		setState({
			...state,
			factor: Number(newValue)
		}, true);
	};


	return (
		<div className={styles.GateBody}>
			<NumericInput
				value={fixedState.factor}
				onChange={minInputChanged}
				// className={styles.Input}
			/>
		</div>
	);
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={ElapsedGateIcon} />}/>
	);
};

const getPortsInformation: GetPortsInformationFunction = (state, widgetInfo) => {
	const portContext: WidgetPortContext = { recipePoolId: widgetInfo.recipePoolId, recipeType: widgetInfo.recipeType };
	const outputNames = MultiplicationWidgetProcessor.getOutputNames(state, portContext);
	const inputNames = MultiplicationWidgetProcessor.getInputNames(state, portContext);

	const positionByOutputName: Record<string, PortLocation> = {
		'output': 'Right',
	};

	return {
		inputs: [{
			position: 'Left',
			type: inputNames[0].type,
			name: inputNames[0].name
		}],

		outputs: outputNames.map(output => ({
			position: positionByOutputName[output.name],
			type: output.type,
			name: output.name
		})),
	};
};

const CustomSettings = (props: GateCustomSettingsProps): React.JSX.Element => {
	const [state, setState] = useReactiveWidgetState<MultiplicationWidgetState>(props.recipeId, props.blockId, props.gateInfo.id);
	const t = useTranslation('LogicMaker.Gates.Multiplication');
	const fixedState = {
		...getDefaultState(),
		...state,
	};


	const onChangeDecimalPoints = (value: number) => {
		setState({
			...fixedState,
			decimalPoints: value
		});
	};


	const onPropChanged = (tags: string[]) => {
		setState({
			...fixedState,
			userProperties: tags
		});
	};

	const detectedPropsNotUsed = Object.keys(fixedState.detectedInputJson || {}).filter(propName => {
		return !fixedState.userProperties.find(userProp => userProp === propName);
	});

	return (
		<div className={classNames(styles.SettingsContainer, SETTINGS_CONTAINER_CLASS)}>
			{props.children}
			<div>
				<label>{t('Settings.Decimals', 'Decimals')}</label>
				<NumericInput
					min={0}
					max={4}
					value={fixedState.decimalPoints}
					onChange={onChangeDecimalPoints}
				/>
			</div>

			<div className={styles.TagsContainer}>
				<label>{t('Settings.CustomProperties', 'Properties')}</label>
				<Select
					tokenSeparators={[',']}
					defaultValue={fixedState.userProperties}
					className={styles.TagsElement}
					dropdownClassName={styles.TagsDropdown}
					mode="tags"
					onChange={onPropChanged}
				>
					{fixedState.userProperties.map((propName, i) => (
						<Option key={`usr_${i}`} value={propName}>
							{propName}
						</Option>
					))}

					{detectedPropsNotUsed.map((key, i) => (
						<Option key={`detected_${i}`} value={key}>
							{key}
						</Option>
					))}
				</Select>
			</div>
		</div>
	);
};



export default {
	CustomSettingsDialog: CustomSettings,
	getPortsInformation,
	BarIcon: GateBarIcon,
	Element: MultiplicationWidget,
	hasTitle: true,
	getWidgetTitle: (intl) => intl.formatMessage({ defaultMessage: 'Multiplication', id: 'LogicMaker.Gates.Multiplication.Title' }),
} as GateUI;
