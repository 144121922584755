import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LauncherWindow from '../LauncherWindow/LauncherWindow';
import { PostHogProperties } from '../types';
import { selectUserActions, setUserAction } from '../../interface/interfaceSlice';
import OnboardingModal from './OnboardingModal/OnboardingModal';
import styles from './GetStartedWidget.module.css';
import OnboardingButton from './OnboardingModal/OnboardingButton';
import useTranslation from '@common/hooks/useTranslation';
import {
	selectUserIntegrations,
	selectSignedUserProfile,
	UserActionEvent,
	UserOnboarding,
	selectUserOnboarding
} from '@src/app/reducers/user/userSlice';
import { trackUserEventAction } from '@src/app/reducers/user/trackEventReducer';
import helpHero from '@common/helpHero/helpHero';
import useHelpHeroTourState from '@common/hooks/useHelpHeroTourState';
import { updateUserOnboardingAction } from '@src/app/reducers/user/updateUserOnboardingReducer';


interface Props {
	menuCollapsed: boolean;
}

export const getDefaultOnboardingItems = (): UserOnboarding => ({
	interface: false,
	widgetSettings: false,
	customWidgets: false,
	widgetsCollection: false,
	sharing: false,
});

/**
 * @returns the percentage of properties that start with 'onboarding'
 */
const getOnboardingProgress = (onboardingItems: UserOnboarding | null): number => {
	if (!onboardingItems) { return 0; }
	const onboardingProps = Object.keys(onboardingItems);
	const completed = onboardingProps.filter(key => onboardingItems[key as keyof UserOnboarding] === true);
	return (completed.length / onboardingProps.length) * 100;
};

const GetStartedWidget = (props: Props): React.JSX.Element | null => {
	const t = useTranslation('Launcher.Button');
  const { active: tutorialActive } = useHelpHeroTourState();
	const dispatch = useDispatch();
	const helpHeroInstance = helpHero.getInstance();
	const integrations = useSelector(selectUserIntegrations);
	const userAction = useSelector(selectUserActions);
	const userInfo = useSelector(selectSignedUserProfile);
	const [showModal, setShowModal] = useState(false);
	const [showLauncher, setShowLauncher] = useState(false);
	const userOnboarding = useSelector(selectUserOnboarding);
	const postHotProps = integrations.posthog?.properties as PostHogProperties | undefined;

	const onboardingItems = useMemo(() => ({
		...getDefaultOnboardingItems(),
		...userOnboarding as UserOnboarding | undefined,
	}), [userOnboarding]);

	const firstTimeUser = userInfo && !userOnboarding;
	const onboardingProgress = getOnboardingProgress(onboardingItems);
	const onboardingComplete = onboardingProgress >= 100;

	const handleShowModal = useCallback(() => {
		setShowModal(true);
		dispatch(setUserAction({
			action: 'gettingStartedClosed',
			value: false
		}));
	}, [dispatch]);

	const handleShowLauncher = useCallback(() => {
		// Abort current tutorial
		if (tutorialActive) {
			helpHeroInstance?.cancelTour();
		} else {
			setShowLauncher(true);
		}
	}, [helpHeroInstance, tutorialActive]);

	const handleModalClosed = useCallback((openLauncher?: boolean) => {
		setShowModal(false);
		dispatch(setUserAction({
			action: 'gettingStartedClosed',
			value: true
		}));

		openLauncher && handleShowLauncher();
	}, [handleShowLauncher, dispatch]);

	const handleLauncherClosed = () => {
		setShowLauncher(false);
	};

	// Updates generic properties
	const dispatchUserAction = useCallback((eventName: UserActionEvent, property: keyof PostHogProperties, value: boolean): void => {
		dispatch(trackUserEventAction({
			eventName,
			properties: {
				[property]: value
			}
		}));
	}, [dispatch]);

	// Updates onboarding properties
	const dispatchUpdateOnboarding = useCallback((eventName: UserActionEvent, property: keyof UserOnboarding, value: boolean): void => {
		dispatch(updateUserOnboardingAction({
			eventName,
			onboarding: {
				...onboardingItems,
				[property]: value,
			}
		}));
	}, [dispatch, onboardingItems]);


	const handleVideoWatched = useCallback((video: keyof UserOnboarding) => {
		dispatchUpdateOnboarding(`Watched:${video}`, video, true);
	}, [dispatchUpdateOnboarding]);

	const handleToggleStartupCheckbox = (checked: boolean) => {
		dispatchUserAction(`ToggleStartupCheckbox:${checked}`, 'showLauncherOnStartup', checked);
	};

	// Show modal on first time user
	// useEffect(() => {
	// 	if(!postHotProps?.founderMessage){
	// 		setShowModal(true);
	// 	}
	// }, [postHotProps]);


	// Show launcher if activated
	useEffect(() => {
		// Only show launcher if onboarding modal is not visible
		if (postHotProps?.showLauncherOnStartup && !showModal) {
			setShowLauncher(true);
		}
	}, [postHotProps, dispatchUserAction, showModal]);


	// Show launcher if first time user
	useEffect(() => {
		if (onboardingComplete && userInfo.profile && typeof postHotProps?.showLauncherOnStartup === 'undefined') {
			dispatchUserAction('Auto:ToggleStartupCheckbox', 'showLauncherOnStartup', true);
		}
	}, [userInfo.profile, postHotProps, dispatchUserAction, onboardingComplete]);

	// Show onboarding modal if not completed
	useEffect(() => {
		if (
			userInfo.profile
			&& !onboardingComplete
			&& !userAction.gettingStartedClosed // don't show if user already closed it
		) {
			setShowModal(true);
		}
	}, [onboardingComplete, userInfo.profile, userAction]);

	// Wait for user account to be fetched
	if (!userInfo.profile) { return null; }


	return (
		<>
			{!onboardingComplete && (
				<OnboardingButton
					onButtonClick={handleShowModal}
					progress={getOnboardingProgress(onboardingItems)}
				/>
			)}

			{showModal && onboardingItems && (
				<OnboardingModal
					onboardingItems={onboardingItems}
					onCloseModal={handleModalClosed}
					dispatchVideoWatched={handleVideoWatched}
					firstTimeUser={firstTimeUser}
					userProfile={userInfo.profile}
				/>
			)}

			<LauncherWindow
				visible={showLauncher}
				onClosed={handleLauncherClosed}
				showOnStartup={!!postHotProps?.showLauncherOnStartup}
				onStartupCheckboxChanged={handleToggleStartupCheckbox}
			/>
			<div
				className={classNames(styles.GetStartedButton, {
					[styles.Collapsed]: !props.menuCollapsed,
					[styles.TutorialActive]: tutorialActive
				})}
				onTouchEnd={handleShowLauncher}
				onClick={handleShowLauncher}
			>
				<FontAwesomeIcon icon={['fas', tutorialActive ? 'ban' : 'rocket']} className={styles.ButtonIcon} />
				<span className={styles.HorizontalTitle}>{
					tutorialActive
						? t('AbortTutorialTitle', 'Abort Tutorial')
						: t('Title', 'Get started')
				}</span>
			</div>
		</>
	);

};

export default GetStartedWidget;
