
import React, { useCallback, useEffect, useRef } from 'react';
import { Resizable, ResizeCallbackData } from 'react-resizable';
import Icon from '@ant-design/icons';
import TextWidgetProcessor, {
	TextWidgetState,
	getDefaultState
} from '@kemu-io/kemu-core/dist/gates/text';
import { message } from 'antd';
import { CustomWidgetState, WidgetPortContext } from '@kemu-io/kemu-core/dist/types/gate_t';
import classNames from 'classnames';
import GateIcon from '../../gateIcon/gateIcon';
import {
	GetPortsInformationFunction,
	GateUI,
	GateUIProps,
	GateCustomSettingsProps,
} from '..';
import { PortLocation } from '../../../types/canvas_t';
import useReactiveWidgetState from '../../../common/hooks/useReactiveWidgetState';
import useTranslation, { getTranslationFunction } from '../../../common/hooks/useTranslation';
import KemuSwitch from '../../form-control/kemuSwitch/kemuSwitch';
import { ABORT_DRAGGING_CLASS, SETTINGS_CONTAINER_CLASS } from '../../../common/constants';
import 'react-resizable/css/styles.css';
import { getLastInputPortWithParent, LastPortInfo } from '../../../common/utils';
import styles from './text.module.css';
import { ReactComponent as TextWidgetIcon } from './icon.svg';

const DEFAULT_W = 100;
const DEFAULT_H = 40;

const TextWidget = (props: GateUIProps): React.JSX.Element => {
	const { repaint } = props;
	const textAreaRef = useRef<HTMLTextAreaElement>(null);
	const [state, setState] = useReactiveWidgetState<TextWidgetState>(props.recipeId, props.thingRecipeId, props.info.id);
	const fixedState: TextWidgetState = {
		...getDefaultState(),
		...state
	};
	const handleResize = useCallback((event: React.SyntheticEvent<Element, Event>, data: ResizeCallbackData) => {
		setState(current => ({
			...current,
			size: {
				width: data.size.width,
				height: data.size.height
			}
		}));

		repaint();
	}, [repaint, setState]);

	const handleTextChange: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
		setState(current => ({
			...current,
			text: event.currentTarget.value
		}));
	};

	useEffect(() => {
		// This monstrosity is to keep the DOM element updated
		// with the state WITHOUT causing a re-render which forces the cursor
		// to appear at the end of the text.
		if (textAreaRef.current) {
			const textAreaText = textAreaRef.current.value;
			if (fixedState.text !== textAreaText) {
				textAreaRef.current.value = fixedState.text;
			}
		}
	}, [fixedState.text, setState]);

	return (
		<Resizable
			height={fixedState.size?.height || DEFAULT_H}
			width={fixedState.size?.width || DEFAULT_W}
			minConstraints={[DEFAULT_W, DEFAULT_H]}
			onResize={handleResize}
		>
			<div className={`${styles.GateBody} ${ABORT_DRAGGING_CLASS}`}
				style={{
					width: (fixedState.size?.width || DEFAULT_W),
					height: (fixedState.size?.height || DEFAULT_H)
				}}
			>
				<textarea ref={textAreaRef} defaultValue={ fixedState.text } onChange={handleTextChange}/>
			</div>
		</Resizable>
	);
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={TextWidgetIcon} />}/>
	);
};

const GateCustomSettings = (props: GateCustomSettingsProps): React.JSX.Element => {
	const [state, setState] = useReactiveWidgetState<TextWidgetState>(props.recipeId, props.blockId, props.gateInfo.id);
	const fixedState = {
		...getDefaultState(),
		...state
	};

	const t = useTranslation('LogicMaker.Gates.Text.Settings');
	const gT = useTranslation('LogicMaker.Gates.Generic');

	const warnIfInputAttached = (info: LastPortInfo | null): boolean => {
		// Prevent adding ports if trigger is linked (otherwise new ports will obtain the connection from trigger)
		if (info && info?.portName === 'trigger') {
			message.warning({
				content: gT('RemoveWarning', 'Please remove all connections from port "{name}"',
				{ name: info.portName })
			});
			return true;
		}

		return false;
	};

	const getLastConnectedInput = () => {
		return getLastInputPortWithParent(
			fixedState,
			TextWidgetProcessor,
			props.gateInfo.id,
			props.blockId,
			props.recipeId,
			props.recipeType
		);
	};
	const handleSwitchChange = (checked: boolean) => {
		const lastPortWithConnection = getLastConnectedInput();
		if (warnIfInputAttached(lastPortWithConnection)) { return ; }
		setState({ ...fixedState, dispatchOnSet: checked });
	};

	return (
		<div className={classNames(styles.SettingsContainer, SETTINGS_CONTAINER_CLASS)}>
			{props.children}

			<div className={styles.SwitchContainer}>
				<label>{t('Switch', 'Trigger next gate on \'set text\'.')}</label>
				<KemuSwitch
					size="small"
					checked={fixedState.dispatchOnSet}
					onChange={handleSwitchChange}
				/>
			</div>
		</div>
	);
};


const getPortsInformation: GetPortsInformationFunction = (state: CustomWidgetState<TextWidgetState>, widgetInfo, intl) => {
	const portContext: WidgetPortContext = { recipePoolId: widgetInfo.recipePoolId, recipeType: widgetInfo.recipeType };
	const outputNames = TextWidgetProcessor.getOutputNames(state, portContext);
	const inputNames = TextWidgetProcessor.getInputNames(state, portContext);
	const t = getTranslationFunction(intl, 'LogicMaker.Gates.Text.Port');

	const inputPositions: Record<string, PortLocation> = {
		'trigger': [0, 0.7, -1, 0],
		'setText': state.dispatchOnSet ? 'Left' : [0, 0.3, -1, 0],
	};

	const outputPositions: Record<string, PortLocation> = {
		'string': [1, 0.3, 1, 0],
		'length': [1, 0.7, 1, 0]
	};

	return {
		inputs: inputNames.map(input => ({
			name: input.name,
			type: input.type,
			position: inputPositions[input.name],
			label: t(input.name, input.name),
		})),

		outputs: outputNames.map(output => ({
			name: output.name,
			type: output.type,
			position: outputPositions[output.name],
			label: output.label
		})),
	};
};

export default {
	getPortsInformation,
	BarIcon: GateBarIcon,
	Element: TextWidget,
	CustomSettingsDialog: GateCustomSettings,
	hasTitle: true,
	getGatesBarTitle: (intl) => intl.formatMessage({ id: 'LogicMaker.Gates.Text.Title', defaultMessage: 'Text' }),
} as GateUI;
