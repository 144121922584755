import { Ace } from 'ace-builds';

	// https://github.com/TouK/nussknacker/blob/v0.0.9/ui/client/components/graph/ExpressionSuggest.js#L31
export const customAceEditorCompleter: Ace.Completer = {
    getCompletions: (
			editor: Ace.Editor,
			session: Ace.EditSession,
			caretPosition2d: Ace.Point,
			prefix: string,
			callback: Ace.CompleterCallback
		) => {
			const completions: Ace.Completion[] = [];
			const keywords = ['getWidgetInputs', 'getWidgetOutputs', 'processEvent'];
			keywords.forEach((word) => {
				if (word.startsWith(prefix)) {
					completions.push({
						value: word,
						score: 1,
						// TODO: 'Add documentation?
						meta: `Kemu function`,
					});
				}
			});

			completions.push({
				value: 'Kemu.services.http.request({ method: \'GET\', url: \'YOUR_URL\' }',
				score: 1,
				meta: 'Kemu HTTP service',
			});

			/**
			  getBoundingBox,
				getPixelValueAtIndex,
				copyImageData,
				setPixelValueAtIndex
			 */

			completions.push({
				value: 'const imageCopy = Kemu.image.copyImageData(imageData);',
				score: 1,
				meta: 'copyImageData',
			});

			completions.push({
				value: `Kemu.image.setPixelValueAtIndex(
		imageData,
		0 /* index */, 
		{ /* RGB values or a number between 0 and 255 to set all 3 channels at once */ 
			r: 255,
			g: 255,
			b: 255,
		}
	);`,
				score: 1,
				meta: 'setPixelValueAtIndex',
			});

			completions.push({
				value: 'const { avr, r, g, b } = Kemu.image.getPixelValueAtIndex(imageData, index);',
				score: 1,
				meta: 'getPixelValueAtIndex',
			});

			completions.push({
				value: `const rect = Kemu.image.getBoundingBox(
		imageData, 
		255 /* R */ , 
		255 /* G */ , 
		255 /* B */, 
		10 /* threshold */
	);`,
				score: 1,
				meta: 'getBoundingBox',
			});

// 			completions.push({
// 				value: `
// const requiredFields = {
// 	region: 'us-east-1',
// 	accessKey: 'YOUR_ACCESS_KEY,
// 	secretKey: 'YOUR_SECRET_KEY,
// 	tableName: 'Table ABC'
// };

// const optionalFields = {
// 	limit: 100,
// 	indexName: undefined,
// 	roleArn: undefined,
// 	externalId: undefined,
// 	indexName: undefined,
// 	lastKey: undefined,
// 	filterExpression: undefined,
// 	// projectionExpression: '#id, #name',
// 	// expressionAttributeNames: { '#name': 'name', '#id': 'id' },
// 	lastKey: undefined,
// }

// const result = await Kemu.services.dynamoDb.scan({ 
// 	...requiredFields,
// 	...optionalFields,	
// });

// if(result.success){
// 	console.log(\`Results: \${JSON.stringify(result.data)}\`);
// }else{
// 	console.log(\`Failed to fetch results: \${result.data}\`);
// }
// 				`,
// 				score: 1,
// 				meta: 'Kemu Dynamo service',
// 			});

			callback(null, completions);
      // const suggestions = this.expressionSuggester.suggestionsFor(this.state.value, caretPosition2d)
      // callback(null, suggestions?.map((s: any) => {
      //   //unfortunately Ace treats `#` as special case, we have to remove `#` from suggestions or it will be duplicated
      //   //maybe it depends on language mode?
      //   const methodName = s.methodName.replace("#", "")
      //   const returnType = ProcessUtils.humanReadableType(s.refClazz)
      //   return {
			// 		name: methodName, 
			// 		value: methodName, 
			// 		score: 1,
			// 		meta: returnType, 
			// 		description: s.description, 
			// 		parameters: s.parameters, 
			// 		returnType: returnType
			// 	}
      // }))
    },
    getDocTooltip: (item: any) => {
			return undefined;
			// console.log('Tooltip for item ', item);
      // if (item?.description || !item?.parameters) {
      //   const paramsSignature = item.parameters.map(p => ProcessUtils.humanReadableType(p.refClazz) + " " + p.name).join(", ")
      //   const javaStyleSignature = `${item.returnType} ${item.name}(${paramsSignature})`
      //   item.docHTML = ReactDOMServer.renderToStaticMarkup((
      //     <div className="function-docs">
      //       <b>{javaStyleSignature}</b>
      //       <hr/>
      //       <p>{item.description}</p>
      //     </div>
      //   ))
      // }
    }
  };
