/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from 'react';
import { Switch } from 'antd';
import styles from './kemuSwitch.module.css';

interface Props {
	onChange?: (checked: boolean, event: MouseEvent, data: any) => void;
	size: 'small' | 'default';
	checked: boolean;
	className?: string;
	'data-kemu-meta'?: string;
	data?: any;
}

const KemuSwitch = (props: Props): React.JSX.Element => {
	const { data, onChange } = props;

	const handleChange = useCallback((checked: boolean, event: MouseEvent) => {
		if (onChange) {
			onChange(checked, event, data);
		}
	}, [onChange, data]);

	return (
		<Switch
			{...props['data-kemu-meta'] ? { ['data-kemu-meta']: props['data-kemu-meta'] } : undefined }
			size={props.size || 'default'}
			checked={props.checked}
			onChange={handleChange}
			className={`${styles.KemuSwitch} ${props.className || ''}`}
		/>
	);
};

export default KemuSwitch;
