
import React, { ComponentType, useRef, useState, useMemo } from 'react';
import { LinkOutlined, DownOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { EditorPlugin } from '@draft-js-plugins/editor';
import { Dropdown, Menu, Button } from 'antd';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';
import { useIntl } from 'react-intl';
import { ContentState, EditorState } from 'draft-js';
import { OverrideContentProps, ToolbarChildrenProps } from '../inlineToolbar/components/Toolbar';
import { getTextFromSelection, createLink, linkStrategy, getEntityMetaData, getSelectionKey, clearLink } from './utils';
import styles from './link.module.css';

export type LinkPlugin = EditorPlugin & {
  LinkButton: ComponentType<ToolbarChildrenProps>;
}

export interface LinkComponentProps {
	// blockProps: { src: string, target: string, text: string };
	decoratedText?: string;
  children: React.ReactNode;
  target?: string;
  rel?: string;
	className?: string;
  contentState: ContentState;
  entityKey: string;
  getEditorState: ()=> EditorState;

	// following props are not used
  offsetKey?: unknown;
  setEditorState?: unknown;
  blockKey?: unknown;
  dir?: unknown;
  start?: unknown;
  end?: unknown;
}


type LinkEntityProps = {src: string, target: string};

const LinkButton = (props: ToolbarChildrenProps): React.JSX.Element => {
	const { onOverrideContent } = props;

	const handleMouseDown = (event: React.MouseEvent) => {
		event.preventDefault();
	};

	const showLinkModal = () => {
		onOverrideContent(LinkModal as ComponentType<OverrideContentProps>);
	};


	return (
		<div className={props.theme.buttonWrapper} onMouseDown={handleMouseDown}>
			<button onClick={showLinkModal} className={props.theme.button}>
				<LinkOutlined style={{ fontSize: 24 }}/>
			</button>
		</div>
	);
};


const LinkModal =  (props: ToolbarChildrenProps): React.JSX.Element => {
	const inputRef = useRef<HTMLInputElement | null>(null);
	// Extract the component's meta data
	const existingMeta = useMemo<LinkEntityProps | null>(() => {
		const editorState = props.getEditorState();
		const entityKey = getSelectionKey(editorState);
		if (!entityKey) { return null; }
		const meta = getEntityMetaData<LinkEntityProps>(editorState, entityKey);
		return meta;
	}, [props]);

	const [url, setUrl] = useState<string>(existingMeta?.src || '');
	const [target, setTarget] = useState(existingMeta?.target || '_blank');
	const intl = useIntl();
	const [menuVisible, setMenuVisible] = useState(false);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setUrl(event.target.value);
	};

	const targetDictionary: Record<string, string> = {
		_blank: 'New tab',
		_self: 'Current tab'
	};


	const addLink = () => {
		const editorState = props.getEditorState();
		const selectedText = getTextFromSelection(editorState);
		const newState = createLink(editorState, url, target, selectedText);
		props.setEditorState(newState);
		props.onOverrideContent();
	};

	const storeTarget: MenuClickEventHandler = ({ key }) => {
		setTarget(key as string);
		setMenuVisible(false);
	};

	const menu = (
		<>
			<Menu onClick={storeTarget} className={styles.Menu}>
				{Object.keys(targetDictionary).map((key: string) => {
					return <Menu.Item key={key}>{targetDictionary[key]}</Menu.Item>;
				})}
			</Menu>
		</>
	);

	const showMenu = () => {
		setMenuVisible(true);
	};

	const removeLink = () => {
		const linkLessState = clearLink(props.getEditorState());
		props.setEditorState(linkLessState);

	};

	return (
		<div ref={props.containerRef} className={styles.ModalContainer} >
			<input type="text" className={styles.Input} ref={inputRef} value={url} onChange={handleChange} placeholder={intl.formatMessage({ id: 'TutorialBuilder.Plugin.Link.UrlPlaceholder' })} />
			<Dropdown visible={menuVisible} className={styles.Dropdown} overlay={menu} placement="bottomRight" trigger={['click']}>
				<Button onClick={showMenu}>
					{targetDictionary[target]} <DownOutlined />
				</Button>
			</Dropdown>
			<button className={styles.Button} onClick={addLink} disabled={!url.length}><CheckOutlined /></button>
			{existingMeta && (
				<button className={`${styles.Button} ${styles.DeleteButton}`} onClick={removeLink} ><CloseOutlined /></button>
			)}
		</div>
	);
};


const LinkComponent = (props: LinkComponentProps): React.JSX.Element => {
	const metaData = getEntityMetaData<{src: string, target: string}>(props.getEditorState(), props.entityKey);

	return (
		<a href={metaData.src} target={metaData.target} className={styles.Link}>
			{props.children}
		</a>
	);
};

const createLinkPlugin = (): LinkPlugin => {
  return {
		LinkButton,
		decorators: [
			{
        strategy: linkStrategy,
        component: LinkComponent,
      },
		]
  };
};

export default createLinkPlugin;
