/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	GetPublicationDetailsResponse,
	GetUserTutorialsResponse,
	PublicationEntity,
	RatedPublicationEntity
} from '@kemu-io/kemu-types/dist/types';
import { RootState } from '../../../app/store';
import * as marketplaceApi from '../../../api/marketplace/marketplaceApi';
import * as tutorialApi from '../../../api/tutorial/tutorialApi';

import { AsyncRequestStatus, AsyncState, RecipeResponse } from '../../../types/core_t';
import { getPublicationDetailsReducer } from './reducers/getPublicationDetails';
import { getUserRecipesReducer } from './reducers/getUserRecipes';
import { submitPublicationReviewReducer } from './reducers/submitPublicationReview';
import { publishRecipeReducer } from './reducers/publishRecipe';
import { unpublishRecipeReducer } from './reducers/unpublishRecipe';

export interface MarketplaceState {
	userRecipeList: RecipeResponse[];
	userTutorialList: GetUserTutorialsResponse;
	publicRecipeList: RatedPublicationEntity[];
	publishingRecipe: {
		asyncState: AsyncState,
		publishedEntity?: PublicationEntity;
	},
	reviewingPublication: AsyncState;
	unpublishState: {
		asyncState: AsyncState;
		publicationId?: string;
	},
	fetchingState: AsyncState;
	publicationDetails: {
		asyncState: AsyncState,
		details?: GetPublicationDetailsResponse;
	}
}

const initialState: MarketplaceState = {
	reviewingPublication: { status: AsyncRequestStatus.idle },
	fetchingState: { status: AsyncRequestStatus.idle },
	publishingRecipe: { asyncState: { status: AsyncRequestStatus.idle } },
	publicationDetails: { asyncState: { status: AsyncRequestStatus.idle } },
	unpublishState: { asyncState: { status: AsyncRequestStatus.idle } },
	userRecipeList: [],
	userTutorialList: [],
	publicRecipeList: []
};


const fetchPublicRecipes = createAsyncThunk('/marketplace/publicRecipes', async (): Promise<RatedPublicationEntity[]> => {
	const recipes = await marketplaceApi.getPublicRecipes();
	return recipes;
});


const fetchUserTutorials = createAsyncThunk('/marketplace/myTutorials', async (): Promise<GetUserTutorialsResponse> => {
	const tutorials = await tutorialApi.getAllTutorials();
	return tutorials;
});


export const marketplaceSlice = createSlice({
	name: 'marketplace',
	initialState,
	reducers: {
		clearUnpublishEntityId: (state, action: PayloadAction<string>) => {
			state.unpublishState.publicationId = action.payload;
		},

		removeRecipeFromList: (state, action: PayloadAction<string>) => {
			state.userRecipeList = state.userRecipeList.filter(recipe => recipe.id !== action.payload);
		},

		clearPublicationDetails: (state) => {
			state.publicationDetails.asyncState.error = undefined;
			state.publicationDetails.asyncState.status = AsyncRequestStatus.idle;
			state.publicationDetails.details = undefined;
		},

		clearPublishRecipeState: (state) => {
			state.publishingRecipe.asyncState = {
				status: AsyncRequestStatus.idle,
				error: undefined
			};

			state.publishingRecipe.publishedEntity = undefined;
		}
	},

	extraReducers: (builder) => {

		getPublicationDetailsReducer(builder);
		getUserRecipesReducer(builder);
		submitPublicationReviewReducer(builder);
		publishRecipeReducer(builder);
		unpublishRecipeReducer(builder);

		builder.addCase(fetchPublicRecipes.pending, (state) => { state.fetchingState.status = AsyncRequestStatus.loading; });

		builder.addCase(fetchPublicRecipes.fulfilled, (state, action: PayloadAction<RatedPublicationEntity[]>) => {
			state.fetchingState.status = AsyncRequestStatus.completed;
			state.publicRecipeList = action.payload;
		});

		builder.addCase(fetchPublicRecipes.rejected, (state, action) => {
			state.fetchingState.status = AsyncRequestStatus.error;
			state.fetchingState.error = action.error;
		});


		builder.addCase(fetchUserTutorials.pending, (state) => { state.fetchingState.status = AsyncRequestStatus.loading; });
		builder.addCase(fetchUserTutorials.fulfilled, (state, action: PayloadAction<GetUserTutorialsResponse>) => {
			state.fetchingState.status = AsyncRequestStatus.completed;
			state.userTutorialList = action.payload;
		});

		builder.addCase(fetchUserTutorials.rejected, (state, action) => {
			state.fetchingState.status = AsyncRequestStatus.error;
			state.fetchingState.error = action.error;
		});

	}
});

export const {
	removeRecipeFromList,
	clearPublishRecipeState,
	clearPublicationDetails,
	clearUnpublishEntityId,
} = marketplaceSlice.actions;

export {
	fetchPublicRecipes,
	fetchUserTutorials,
};

export const userRecipeList = (state: RootState): RecipeResponse[] => state.marketplace.userRecipeList;
export const userTutorialList = (state: RootState): GetUserTutorialsResponse => state.marketplace.userTutorialList;
export const publicRecipeList = (state: RootState): RatedPublicationEntity[] => state.marketplace.publicRecipeList;
export const fetchingState = (state: RootState): AsyncState => state.marketplace.fetchingState;
export const publishRecipeState = (state: RootState) => state.marketplace.publishingRecipe;
export const publicationDetails = (state: RootState) => state.marketplace.publicationDetails;
export const publicationReviewState = (state: RootState) => state.marketplace.reviewingPublication;
export const unpublishRecipeState = (state: RootState) => state.marketplace.unpublishState;

export default marketplaceSlice.reducer;
