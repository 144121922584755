import { Position } from '@kemu-io/kemu-core/dist/types/block_t';
import { CustomWidgetState, RecipeWidget, WidgetType } from '@kemu-io/kemu-core/dist/types/gate_t';
import { PayloadAction, Draft, createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { WidgetBundleState } from '@kemu-io/kemu-core/dist/types';
import widgetBundleManager from '@kemu-io/kemu-core/dist/widgetBundle/manager';
import kemuCore from '@kemu-io/kemu-core/dist/index';
import { LogicMapperState, SelectedWidgetInfo } from '../logicMapperSlice';
import { addToKemuClipboard, getFromKemuClipboard } from './clipboardUtils';
import * as recipeUtils from '@src/app/recipe/utils';
import { RootState } from '@src/app/store';
import { safeJsonClone, widgetMapToStatelessMap } from '@common/utils';
import { WidgetsMap, StatelessWidgetsMap } from '@src/types/core_t';

type CopySelectedWidgetsAction = {
	/** id of the recipe in the pool */
	recipeId: string;
	/** id of the Thing in the recipe */
	thingId: string;
	groupId?: string;
};

/**
 * Copies all the widgets in the current selection to the clipboard
 */
const copySelectedWidgetsReducer = (state: Draft<LogicMapperState>, action: PayloadAction<CopySelectedWidgetsAction>): void => {

	const widgetsMap = recipeUtils.getWidgetsInThing(action.payload.recipeId, action.payload.thingId);
	let widgetsToCloneMap: WidgetsMap = {};

	const selectedWidgetsIds: string[] = [];
	state.selectedWidgets.forEach((widgetInfo) => {
		const widgetInThing = widgetsMap[widgetInfo.widgetId];
		if (widgetInThing) {
			// Make a copy of the widget without their state's private properties
			const safeWidgetCopy = safeJsonClone<RecipeWidget>(widgetInThing);
			// Check if a bundle is selected, if so, make sure we restore 
			// bundle's processors private data to point to their cache location.
			// FIXME: Pasting will brake if the widget is removed AFTER copied 
			// to the clipboard.
			if (safeWidgetCopy.type === WidgetType.widgetBundle) {
				const bundleState = widgetInThing.state as CustomWidgetState<WidgetBundleState>;
				safeWidgetCopy.state = {
					...safeWidgetCopy.state,
					...(bundleState.$$cacheInfo ? { $$cacheInfo: { ...bundleState.$$cacheInfo } } : {}),
					...(bundleState.$$collectionInfo ? { $$collectionInfo: { ...bundleState.$$collectionInfo } } : {}),
				} as CustomWidgetState<WidgetBundleState>;
			}

			widgetsToCloneMap[widgetInThing.id] = safeWidgetCopy;
			selectedWidgetsIds.push(widgetInThing.id);
		}
	});


	// find group type widgets in the selection and add to the list any inner widgets.
	Object.values(widgetsToCloneMap).forEach((widget) => {
		if (widget.type === WidgetType.widgetGroup) {
			const childrenMap = recipeUtils.getCustomWidgetInnerWidgets(
				widget.id,
				action.payload.thingId,
				action.payload.recipeId,
				// We need to send the list of selected widgets so that outer children
				// are not removed from the list of children.
				selectedWidgetsIds
			);

			widgetsToCloneMap = {
				...widgetsToCloneMap,
				...childrenMap
			};
		}
	});

	// IMPORTANT: Remove children references that are not part of the selection
	// this may happen if the selected widget is linked to a root level widget
	// that is not part of the selection.
	for (const widgetId in widgetsToCloneMap) {
		const widget = widgetsToCloneMap[widgetId];
		widget.children = (widget.children ||[]).filter((child) => {
			return !!widgetsToCloneMap[child.childId];
		});
	}

	// Remove the groupId from any widget at the root level
	if (action.payload.groupId) {
		Object.values(widgetsToCloneMap).forEach(widget => {
			if (widget.groupId === action.payload.groupId) {
				widget.groupId = undefined;
			}
		});
	}

	addToKemuClipboard(widgetsToCloneMap);
};

/**
 * Keeps track of all selected widgets 
 */
const setSelectedWidgetsReducer = (state: Draft<LogicMapperState>, action: PayloadAction<SelectedWidgetInfo[]>): void => {
	state.selectedWidgets = action.payload;
};

/**
 * Checks the clipboard for a valid kemu-structure string and adds the list of widgets to the current thing.
 */
export const pasteFromClipboardAction = createAsyncThunk('/clipboard/pasteFromClipboardAction', async (
	payload: {
		recipeId: string,
		thingId: string,
		mouseLocation?: Position,
		/** 
		 * A stringified map of widgets to be added. If not provided, it attempts to read 
		 * and decode text from the actual system clipboard.
		 */
		mapData?: string,
	},
	thunkApi
): Promise<StatelessWidgetsMap> => {
	const clipboardData = payload.mapData || await getFromKemuClipboard();
	if (clipboardData) {
		const { logicMapper, workspace } = thunkApi.getState() as RootState;
		const mousePosition = payload.mouseLocation || logicMapper.lastMouseClick;
		const groupId = workspace.folderPath[workspace.folderPath.length - 1]?.groupId;
		const { addedWidgets, widgetsIdMap, originalParsedData } = await recipeUtils.addWidgetsFromStringMap(
			clipboardData, payload.recipeId, payload.thingId, mousePosition, groupId
		);

		const addedWidgetsList = Object.values(addedWidgets);
		for (const widget of addedWidgetsList) {
			// For widget bundles, if in a temp location, create
			// a new location for them.
			if (widget.type === WidgetType.widgetBundle) {
				const bundleState = widget.state as CustomWidgetState<WidgetBundleState>;
				if (bundleState.$$cacheInfo) {
					const isTempBundle = bundleState.$$cacheInfo?.widgetThingId
						!== bundleState.$$collectionInfo?.widgetId;

					// We only need to generate new tmp locations for tmp bundles because
					// those dropped from the Widgets Launchpad (coming from a collection)
					// point to the permanent location of the widget in the cache. It is only
					// if a collection widget is modified that a /tmp location is created.
					if (isTempBundle) {
						const prevWidgetId = Object.keys(widgetsIdMap).find((id) => widgetsIdMap[id] === widget.id);
						if (prevWidgetId) {
							// We need to capture the previous cache location, otherwise we won't be able to duplicate its contents
							const prevWidget = originalParsedData[prevWidgetId];
							const prevWidgetState = prevWidget.state as CustomWidgetState<WidgetBundleState>;
							if (prevWidgetState.$$cacheInfo) {
								const oldWidgetId = prevWidgetState.$$cacheInfo.widgetThingId;
								const oldVersion = prevWidgetState.$$cacheInfo.version;
								const newWidgetId = widget.id;
								const newVersion = 1;
								const { processor } = await widgetBundleManager.duplicateWidgetTempLocation(
									oldWidgetId, oldVersion, newWidgetId, newVersion
								);

								// IMPORTANT: point to the new version of the cloned widget and set a processor
								// otherwise the bundle will try to load its processor the memory cache.
								// NOTE: there is no need to update the `widgetThingId` because it should
								// already point to the new id since `duplicateWidgetTempLocation` regenerates all ids.
								bundleState.$$cacheInfo.version = newVersion;
								bundleState.$$processor = processor;

								/* const { zipHandler } = await widgetBundleManager.recreateBundleFromStorage(
									newWidgetId,
									newVersion,
									true
								); */

								// Now we must initialize the widget so that it loads its processor
								await kemuCore.initializeWidget(payload.recipeId, payload.thingId, newWidgetId, {
									/** prevents replacing the current state with the default one stored in the zip file (state.json) */
									keepCurrentState: true
								});
							}
						}
					} else {
						// We still need to initialize the widget so that it loads its processor
						await kemuCore.initializeWidget(payload.recipeId, payload.thingId, widget.id, {
							/** prevents replacing the current state with the default one stored in the zip file (state.json) */
							keepCurrentState: true
						});
					}
				}
			}
		}

		const statelessMap = widgetMapToStatelessMap(addedWidgets);

		return statelessMap;
	}

	return {};
});


export const pasteFromClipboardReducer = ((builder: ActionReducerMapBuilder<LogicMapperState>): void => {
	builder.addCase(pasteFromClipboardAction.fulfilled, (state, action: PayloadAction<StatelessWidgetsMap>) => {
		state.gates = {
			...state.gates,
			...action.payload
		};

		// Also force re-rendering
		state.renderCounter = state.renderCounter + 1;
	});
});

export default {
	copySelectedWidgetsReducer,
	setSelectedWidgetsReducer,
};
