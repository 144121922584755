import React, { useCallback, useEffect, useRef } from 'react';
import { ImageWarpWidgetState,  } from '@kemu-io/kemu-core/dist/gates/imageWarp';
import { warpImageData } from '@kemu-io/kemu-core/dist/gates/imageWarp/utils';
import { useWidgetState } from '../../../common/hooks';
import { StatelessRecipeWidget } from '../../../types/core_t';
import ParentEventHandler from '../helpers/ParentEventHandler/ParentEventHandler';
import { drawImageBitmap } from './helpers';
import VertexDrawer from './VertexDrawer';

type Props = {
  /** id of the recipe in the pool */
  recipeId: string;
  /** id of the thing in the recipe */
  thingId: string;
  gateInfo: StatelessRecipeWidget;
  canvas: HTMLCanvasElement | null;
  context: CanvasRenderingContext2D | null;
  setWidgetState: (state: ImageWarpWidgetState | ((state: ImageWarpWidgetState) => ImageWarpWidgetState | Promise<ImageWarpWidgetState>)) => void;
}

const resetCanvasSize = (canvas: HTMLCanvasElement, width: number, height: number) => {
  if (canvas.width !== width) {
    canvas.width = width;
  }
  if (canvas.height !== height) {
    canvas.height = height;
  }
};

const canApplyTransform = (state: ImageWarpWidgetState): boolean => {
  return state.anchors.length === 4;
};

const ImageRenderer = (props: Props): React.JSX.Element => {
  const { canvas, context, setWidgetState } = props;
  const { getState } = useWidgetState<ImageWarpWidgetState>(props.recipeId, props.thingId, props.gateInfo.id);
  const inputImageRef = useRef<ImageData | null>(getState().$$private?.lastInputImage || null);

  const drawImage = useCallback((image: ImageData | ImageBitmap) => {
    if (canvas && context) {
      resetCanvasSize(canvas, image.width, image.height);
      if (image instanceof ImageBitmap) {
        drawImageBitmap(image, canvas, context);
      } else {
        context.putImageData(image, 0, 0);
      }
    }
  }, [canvas, context]);

  const handleImageData = useCallback((imageData: ImageData) => {
    inputImageRef.current = imageData;
  }, []);

  const onDrawLastImage = useCallback(async () => {
    const lastState = getState();
    // Only warp if anchors exists
    if (inputImageRef.current) {
      if (canApplyTransform(lastState)) {
        const warpedBitmap = await warpImageData(lastState, inputImageRef.current);
        if (warpedBitmap) {
          drawImage(warpedBitmap);
        }
      } else {
        drawImage(inputImageRef.current);
      }
    }
  }, [getState, drawImage]);

  useEffect(() => {
    onDrawLastImage();
  }, [onDrawLastImage]);


  return (
    <>
      <VertexDrawer
        canvas={canvas}
        context={context}
        setState={setWidgetState}
        renderLastImage={onDrawLastImage}
      />

      <ParentEventHandler
        blockRecipeId={props.thingId}
        gateId={props.gateInfo.id}
        recipeId={props.recipeId}
        onImageData={handleImageData}
      />
    </>
  );
};

export default ImageRenderer;
