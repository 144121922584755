import 'antd/dist/antd.min.css';
import React, { ReactElement, useContext } from 'react';
import '../../components/layout/core.css';
import { Redirect, Route, Switch } from 'react-router-dom';
import routes from '../../common/routes/index';
import { UserSessionContext } from '../../common/context/UserSessionContext/UserSessionContext';
import styles from './layout.module.css';
import ImportRecipe from './importRecipe/importRecipe';
import ImportWidgetScreen from './importWidget/importWidget';

function DirectActionLayout (): (ReactElement | null) {
	const session = useContext(UserSessionContext);

	if (session.state === 'NotLoggedIn') { return <Redirect to={routes.getBaseRoute()} />; }
	if (session.state === 'Unknown') { return null; }

	return (
		<div className={`${styles.Container} ${styles.Light}`}>
			<div className={styles.Section}>
				<Switch>
					<Route path={routes.recipe.getImportRecipeDirectRoute()} component={ImportRecipe} />
					<Route path={routes.widgets.getImportWidgetDirectRoute()} component={ImportWidgetScreen} />
					<Redirect to={routes.getBaseRoute()} />
				</Switch>
			</div>
		</div>
	);
}

export default DirectActionLayout;
