import React, { createElement, memo } from 'react';
import { Layout } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import MainMenu from '../../mainMenu/mainMenu';
import SettingsPanel from '../../../features/SettingsPanel/SettingsPanel';
import GetStartedWidget from '../../../features/GetStarted/GetStartedWidget/GetStartedWidget';
import HeaderIcons from '../../../features/headerIcons/headerIcons';
import RecipeWorkspaceTitle from '../../RecipeWorkspaceTitle/RecipeWorkspaceTitle';
import SaveWidgetAsModal from '../../saveWidgetAsModal/saveWidgetAsModal';
import VirtualBlocks from '../../../features/virtualBlocks/virtualBlocks';
import useJSPlumb from '../../../common/hooks/useJsPlumb';
import Canvas from '../../canvas/canvas';
import { DestroyEternalInstanceFunction, EnhancedUICanvasInstance } from '../../../common/hooks/useEternalCanvasInstance';
import { SelectedBlockInfo } from '../../../types/core_t';

import styles from './HorizontalSplit.module.css';
import 'react-reflex/styles.css';
import { THING_CONNECTION_CLASS } from '@common/constants';

const { Header, Content } = Layout;

type Props = {
  collapsed: boolean;
  recipePoolId: string | null;
  openedBlock: SelectedBlockInfo | null;
  onToggle: () => void;
  createCanvasInstance: (blockDbId: string, blockVersion: string, recipeId: string, blockRecipeId: string, label: string) => EnhancedUICanvasInstance;
  destroyCanvasInstance: DestroyEternalInstanceFunction;
}

const WorkspaceSection = (props: Props): React.JSX.Element => {
  const {
    openedBlock,
    collapsed,
    recipePoolId,
    onToggle,
    createCanvasInstance,
    destroyCanvasInstance,
  } = props;
  const {
    initialize: initWorkspaceJsPlumb,
    jspInstance: workspaceJsPlumb
  } = useJSPlumb({ connectionClass: THING_CONNECTION_CLASS });

  return (
    <div className={styles.Section}>
      <MainMenu collapsed={collapsed} />
      <Layout className="site-layout">
        <SettingsPanel />
        <GetStartedWidget menuCollapsed={collapsed} />

        <Header className="site-header" style={{ padding: 0, display: 'flex' }}>
          <div className="trigger-btn" data-kemu-meta={`${collapsed ? 'expand' : 'collapse'}-menu-btn`}>
            {createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              onClick: onToggle,
            })}
          </div>

          <HeaderIcons />
        </Header>

        <div className="section">
          <div className="section-header">
            <RecipeWorkspaceTitle />
          </div>
        </div>

        <Content
          className="site-layout-background kemu-workspace"
          style={{
            margin: '16px 16px',
            minHeight: 280,
          }}
        >

          <SaveWidgetAsModal />

          { recipePoolId && (
            <Canvas
              ref={initWorkspaceJsPlumb}
              recipeId={recipePoolId}
              selectedBlock={openedBlock}
              loadingArea="workspace"
            >
              {workspaceJsPlumb && (
                <VirtualBlocks
                  createCanvasUI={createCanvasInstance}
                  destroyCanvasUI={destroyCanvasInstance}
                  plumbInstance={workspaceJsPlumb}
                  recipeId={recipePoolId}
                />
              )}

              {/* <AnnotationsManager recipeId={recipePoolId} /> */}
            </Canvas>
          )}
        </Content>
      </Layout>
    </div>
  );
};

export default memo(WorkspaceSection);
