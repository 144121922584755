import { createIntl, createIntlCache, IntlShape } from 'react-intl';

const cache = createIntlCache();
let intl: IntlShape;

export const setLanguage = async (lang: string): Promise<void> => {
	const msg = await import(`../assets/i18n/messages/${lang}.json`);
	intl = createIntl({
		locale: lang,
		messages: msg
	}, cache);
};

type AllowedValues = string | number | boolean | Date | null | undefined;
export type GlobalTranslateFunction = (id: string, defaultMessage: string, values?: Record<string, AllowedValues>) => string;
export const getGlobalIntl = () => {
	return (key: string, defaultMessage: string, values?: Record<string, AllowedValues>): string => {
		if (intl) {
			return intl.formatMessage({ id: key, defaultMessage }, values);
		}

		return '';
	};
};
