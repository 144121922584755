import { LimitedThingInfo } from '@kemu-io/kemu-types/dist/types';
import { createSlice } from '@reduxjs/toolkit';
import { AsyncRequestStatus, AsyncState } from '../../../types/core_t';
import { RootState } from '../../store';
import { installDefaultThingsReducer } from './installDefaultThingsReducer';
import { installThingReducer } from './installThingReducer';
import { uninstallThingReducer } from './uninstallThingReducer';

export interface ThingInfoWithIcon extends LimitedThingInfo {
	icon: string
  _isDevMode?: boolean;
}


export interface ThingState {
  things: ThingInfoWithIcon[];
  fetchDefaultThingStatus: AsyncState;
  installThingStatus: AsyncState;
  action: 'installing' | 'uninstalling' | null;
}

const initialState: ThingState = {
	things: [],
  fetchDefaultThingStatus: {
    status: AsyncRequestStatus.idle,
  },
  installThingStatus: {
    status: AsyncRequestStatus.idle,
  },
  action: null,
};

export const thingSlice = createSlice({
	name: 'customer',
	initialState,
	reducers: {

  },

  extraReducers: (builder) => {
    installDefaultThingsReducer(builder);
    uninstallThingReducer(builder);
    installThingReducer(builder);
  }
});


// export const {

// } = thingSlice.actions;

export const selectInstalledThings = (state: RootState): ThingInfoWithIcon[] => state.thing.things;
export const selectThingInstallationStatus = (state: RootState): AsyncState => state.thing.installThingStatus;
export const selectInstallDefaultThingStatus = (state: RootState): AsyncState => state.thing.fetchDefaultThingStatus;


export default thingSlice.reducer;
