import React from 'react';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import Icon from '@ant-design/icons';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import useTranslation from '../../../../common/hooks/useTranslation';
import { ReactComponent as ChecklistIcon } from './checklist-icon.svg';
import 'react-circular-progressbar/dist/styles.css';
import styles from './OnboardingButton.module.css';

interface Props {
  onButtonClick: () => void;
  /** a number between 0 and 100 */
  progress: number;
}


const OnboardingButton = (props: Props): React.JSX.Element => {
  const t = useTranslation('Onboarding.Button');
  const handleButtonClick = () => {
    props.onButtonClick();
  };

  return (
    <div className={styles.ButtonWrapper}>
      <CircularProgressbarWithChildren
        value={props.progress}
        strokeWidth={10}
        styles={{
          path: {
            strokeLinecap: 'square',
          },
        }}
      >
        <div
          className={classNames(
            'onboarding-btn',
            styles.Button,
            !props.progress && styles.WithShadow,
            props.progress > 0 && 'started',
          )}
          onClick={handleButtonClick}
          data-kemu-meta={props.progress}
        >
          <Tooltip
            mouseEnterDelay={0.5}
            title={t('Tooltip', 'Onboarding {percentage}% completed', { percentage: props.progress })}
            placement="right"
            destroyTooltipOnHide
          >
            <Icon
              component={ChecklistIcon}
              className={styles.ButtonIcon}
            />
          </Tooltip>
        </div>
      </CircularProgressbarWithChildren>
     </div>
  );
};

export default OnboardingButton;
