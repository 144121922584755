import React, { useEffect, useState } from 'react';
import { Button, Tooltip, Space, Popconfirm } from 'antd';
import Icon, { CloseOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import styles from './settingsIcons.module.css';
import { ReactComponent as SettingsPanelIcon } from '@src/assets/img/interface/settings-panel.svg';
import { ReactComponent as LogicMakerIcon } from '@src/assets/img/interface/logic-maker.svg';


interface Props {
	blockRecipeId: string;
	recipePoolId: string;
	defaultVisibility?: boolean;
	onPropsPanelClick?: ()=> void;
	onLogicMakerClick?: ()=> void;
	onRemoveClick?: ()=> void;
	getParentRef: ()=> React.RefObject<HTMLDivElement>
}

const SettingsIcons = (props: Props): React.JSX.Element => {
	const [isVisible, setIsVisible] = useState(props.defaultVisibility || false);
	const [confirmIsVisible, setConfirmIsVisible] = useState(false);
	const intl = useIntl();
	const { getParentRef } = props;

	const handleLogicMaker = () => {
		props.onLogicMakerClick && props.onLogicMakerClick();
	};

	const handleProperties =() => {
		props.onPropsPanelClick && props.onPropsPanelClick();
	};

	const handleDelete = () => {
		props.onRemoveClick && props.onRemoveClick();
	};

	useEffect(() => {
		const ref = getParentRef();
		if (ref.current) {
			ref.current.onmouseover = () => { setIsVisible(() => true ); };
			ref.current.ontouchstart = () => { setIsVisible(() => true ); };
			ref.current.onmouseleave = () => setIsVisible(false);
		}

		return () => {
			if (ref.current) {
				ref.current.onmouseover = null;
				ref.current.onmouseleave = null;
			}
		};

	}, [getParentRef]);

	return (


		<div className={`${styles.Container} ${isVisible || confirmIsVisible ? 'visible' : styles.Invisible}`} data-kemu-meta="settings-icons">
			<Space size={10}>
				<Tooltip placement="bottom" title={intl.formatMessage({ id: 'Workspace.SettingsIcon.LogicMapper', defaultMessage: 'LogicMapper' })}>
					<Button data-kemu-meta="thing-logic-mapper" className={styles.Button} onClick={handleLogicMaker} shape="circle" icon={<Icon component={LogicMakerIcon} className={styles.CustomSvg} />} />
				</Tooltip>
				<Tooltip placement="bottom" title={intl.formatMessage({ id: 'Workspace.SettingsIcon.Settings', defaultMessage: 'Settings' })}>
					<Button data-kemu-meta="thing-settings" className={styles.Button} onClick={handleProperties} shape="circle" icon={<Icon component={SettingsPanelIcon} className={styles.CustomSvg} />} />
				</Tooltip>
				<Tooltip placement="bottom" title={intl.formatMessage({ id: 'Workspace.SettingsIcon.Remove', defaultMessage: 'Remove' })} {...(confirmIsVisible && { visible: false }) }>
					<Popconfirm
						onVisibleChange={setConfirmIsVisible}
						placement="right"
						title={intl.formatMessage({ id: 'Workspace.DeletePopup.Title', defaultMessage: 'Are you sure?' })}
						onConfirm={handleDelete}
						okButtonProps={{ className: `${styles.ConfirmButton} ${styles.ConfirmButtonPrimary}` }}
						cancelButtonProps={{ className: `${styles.ConfirmButton} ${styles.ConfirmButtonLight}` }}
						okText={intl.formatMessage({ id: 'Workspace.DeletePopup.Delete', defaultMessage: 'Delete' })}
						cancelText={intl.formatMessage({ id: 'Workspace.DeletePopup.Cancel', defaultMessage: 'Cancel' })}
					>
						<Button data-kemu-meta="thing-delete" className={styles.Button} shape="circle" icon={<CloseOutlined className={styles.CustomSvg}/>} />
					</Popconfirm>
				</Tooltip>
			</Space>
		</div>
	);
};

export default SettingsIcons;
