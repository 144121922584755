import React, { useState } from 'react';
import VideoPopup from '../OnboardingModal/VideoPopup';
import RoundedModal from '../../../../components/roundedModal/roundedModal';
import useTranslation from '../../../../common/hooks/useTranslation';
import StyledButton from '../../../../components/form-control/styledButton/styledButton';
import MarkedText from '../../../../components/MarkedText/MarkedText';
import styles from './WelcomeMessagePopup.module.css';

interface Props {
	visible: boolean;
	url: string;
	onVideoPlayed: () => void;
	onModalClosed: () => void;
}


const WelcomeMessagePopup = (props: Props): React.JSX.Element => {
	const t = useTranslation('Luncher.WelcomePopup');
	const [videoWatched, setVideoWatched] = useState(false);

	const handleVideoPlayed = (): void => {
		setVideoWatched(true);
	};

	const handleOkPressed = () => {
		props.onVideoPlayed();
		props.onModalClosed();
	};

	const CustomFooter = (): React.JSX.Element => {
		return (
			<div className={styles.ModalFooter}>
				<div className={styles.FooterText}>
					{t('FooterMessage', 'Watch this 1 min video to know what you can do with Kemu!')}
				</div>
				<StyledButton
					color='light'
					disabled={!videoWatched}
					title={t('GetStartedButton', 'Get Started')}
					onClick={handleOkPressed}
				/>
			</div>
		);
	};

	return (
		<RoundedModal
			visible={props.visible}
			onAfterClosed={props.onModalClosed}
			onCancel={props.onModalClosed}
			centered={true}
			// Using markdown as it uses 'dangerouslySetInnerHTML' to allow empty space in the text
			title={<MarkedText text={t('Title', '🎉&nbsp;&nbsp;Welcome To Kemu !')} />}
			closable={true}
			customFooter={<CustomFooter />}
			bodyClassName={styles.ModalWrapper}
			width={'53.332vw'}
		>
			<div>
				<VideoPopup
					onVideoViewed={handleVideoPlayed}
					onClose={props.onModalClosed}
					wrapperClassName={styles.VideoWrapper}
					url={props.url}
				/>
			</div>
		</RoundedModal>
	);
};

export default WelcomeMessagePopup;
