import { SelectValue } from 'antd/lib/select';
import { Alert } from 'antd';
import React, { useEffect, useState } from 'react';
import capitalize from 'capitalize';
import { PublicationState } from '@kemu-io/kemu-types/dist/types';
import useTranslation from '../../../common/hooks/useTranslation';
import FormGroup from '../../form-control/formGroup/formGroup';
import StyledSelect, { Option } from '../../form-control/styledSelect/styledSelect';
import RoundedModal from '../../roundedModal/roundedModal';
import KemuSwitch from '../../form-control/kemuSwitch/kemuSwitch';
import useMarkedText from '../../../common/hooks/useMarkedText';
import styles from './publicationReviewModal.module.css';

interface Props {
	publicationId: string;
	publicationState: PublicationState;
	publicationType: 'recipe' | 'tutorial';
	visible: boolean;
	onSubmit?: (status: PublicationState, comments: string) => void;
	onClose?: (reload: boolean) => void;
	loading: boolean;
	errorMessage?: string;
}

const statuses = ['published', 'unpublished', 'rejected', 'pending', 'outdated', 'unlisted'];

const PublicationReviewModal = (props: Props): React.JSX.Element | null => {
	const [status, setStatus] = useState<PublicationState>(props.publicationState);
	const [comments, setComments] = useState('');
	const [previewMode, setPreviewMode] = useState(false);
	const parseHtml = useMarkedText();
	const t = useTranslation('Marketplace.PublicRecipe.ReviewPopup');

	const onSubmit = () => {
		props.onSubmit && props.onSubmit(status, getMarkedComments());
	};

	const handleSetComment = (el: React.ChangeEvent<HTMLTextAreaElement>) => {
		setComments(el.currentTarget.value);
	};

	const togglePreviewMode = () => {
		setPreviewMode(s => !s);
	};

	const handleStatusChange = (value: SelectValue) => {
		setStatus(value as PublicationState);
	};

	const getMarkedComments = (): string => {
		const text = parseHtml(comments);
		return text;
	};

	const onClose = () => {
		props.onClose && props.onClose(true);
	};


	useEffect(() => {
		setStatus(props.publicationState);
		setComments('');
		setPreviewMode(false);
	}, [props.visible, props.publicationState]);

	return (
		<RoundedModal
			loading={props.loading}
			title={t('Title', 'Submit Review')}
			onOk={onSubmit}
			onCancel={onClose}
			okBtnLabel={t('SubmitBtn', 'Submit')}
			cancelBtnLabel={t('CancelBtn', 'Cancel')}
			closeOnMaskClick={true}
			visible={props.visible}
		>

			{props.errorMessage !== undefined && (
				<Alert message={props.errorMessage} type="error" closable={true} className={styles.Alert}/>
			)}

			<FormGroup row>
				<StyledSelect
					label={t('StatusLabel', 'Submission Status')}
					value={status}
					defaultValue={props.publicationState}
					onChange={handleStatusChange}
				>
					{statuses.map(status => (
						<Option value={status} key={status}>{capitalize(status)}</Option>
					))}
				</StyledSelect>
			</FormGroup>

			<FormGroup column>
				<div className={styles.PreviewSwitchContainer}>
					<label>{t('PreviewLabel', 'Preview mode')}</label>
					<KemuSwitch checked={previewMode} onChange={togglePreviewMode} size="default" />
				</div>
				{previewMode ? (
					<div className={styles.PreviewViewer} dangerouslySetInnerHTML={{ __html: getMarkedComments() }} />
				) : (
					<textarea
						onChange={handleSetComment}
						className={styles.PreviewTextArea}
						readOnly={previewMode}
						value={comments}
					/>
				)}
			</FormGroup>
		</RoundedModal>
	);
};

export default PublicationReviewModal;
