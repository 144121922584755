import React, { useCallback, useEffect, useRef, CSSProperties, memo } from 'react';
import { BrowserJsPlumbInstance } from '@jsplumb/community';
import classNames from 'classnames';
import { GroupWidgetPort } from '@kemu-io/kemu-core/dist/types/gate_t';
import { Position } from '@kemu-io/kemu-core/dist/types/block_t';
import { getUniqueGatePortId } from '../../app/recipe/utils';
import { getEndpointSetting } from '../../common/jsPlumb/settings';
import { WIDGET_PORT_RADIUS } from '../../common/constants';
import { PortLocation } from '../../types/canvas_t';
import { generateDomId } from '../../common/utils';
import styles from './virtualPort.module.css';

interface Props {
	portIndex: number;
	port: GroupWidgetPort;
	isInner: boolean;
	gateId: string;
	blockId: string;
	recipeId: string;
	plumbInstance: BrowserJsPlumbInstance;
	updatePortPosition: (port: GroupWidgetPort, position: Position) => void;
	portLocation: 'Right' | 'Left';
}


const getEndpointConfig = (isTarget: boolean, id: string, customPosition: PortLocation) => {
	// top (LR), left (TB), (dx, dy) <== direction of curve
	const portSettings = getEndpointSetting(isTarget, customPosition, WIDGET_PORT_RADIUS);
	portSettings.portId = id;
	portSettings.parameters = {
		isTarget,
	};

	portSettings.uuid = id;
	return portSettings;
};


const getDefaultPosition = (portLocation: 'Right' | 'Left', index: number, position?: Position): Position => {
	if (position?.x !== undefined && position?.y !== undefined) { return position; }

	// Handle inputs
	if (portLocation === 'Right') {
		return {
			x: 100,
			y: 100 + (60 * index)
		};
	} else {
		return {
			x: 500,
			y: 100 + (60 * index)
		};
	}
};

const VirtualPort = (props: Props): React.JSX.Element => {
	const { gateId, plumbInstance, port, portIndex, portLocation, updatePortPosition } = props;
	const containerRef = useRef<HTMLDivElement | null>(null);
	const canvasDomId = generateDomId(props.recipeId, props.blockId, props.gateId, port.name);
	const position = getDefaultPosition(props.portLocation, props.portIndex, port.position);
	const gatePos: CSSProperties= { left: position.x, top: position.y };
	const newPortPosStr = JSON.stringify(position);
	const currentPortPosStr = JSON.stringify(port.position);
	// IMPORTANT: `port.type` is an array which reference changes between renders. We serialize it to a string
	// to prevent the buildPort from creating new references every time the component renders.
	const portTypeStr = JSON.stringify(port.type);

	// Adds the JS plumb port to the canvas element
	const buildPort = useCallback(() => {
		const isOutput = portLocation === 'Right';
		const type = isOutput ? 'innerOutput' : 'innerInput';
		const portId = getUniqueGatePortId(portIndex, type, gateId);
		const portType = JSON.parse(portTypeStr);
		if (containerRef.current && plumbInstance) {
			const sourcePortSettings = getEndpointConfig(!isOutput, portId, portLocation);
			const portTypes = (Array.isArray(portType) ? portType : [portType]).map(arrType => `type-${arrType}`);
			sourcePortSettings.cssClass = classNames(
				sourcePortSettings.cssClass,
				isOutput ? 'source-port' : 'target-port',
				'port',
				'gate-port',
				portTypes
			);

			plumbInstance.addEndpoint(containerRef.current, { ...sourcePortSettings });
		}
	}, [plumbInstance, gateId, portTypeStr, portIndex, portLocation]);

	// Create ports and rebuild connections 
	useEffect(() => {
		const elRefId = containerRef.current?.id;
		if (elRefId && plumbInstance) {
			buildPort();
		}

		return () => {
			if (elRefId && plumbInstance) {
				plumbInstance.removeAllEndpoints(elRefId);
				// Remove connections making sure no event listeners are triggered (since we don't want 
				// the disconnection to be registered in the recipe)
				plumbInstance.deleteConnectionsForElement(elRefId, { fireEvent: false });
				delete plumbInstance.getManagedElements()[elRefId];
				// plumbInstance.unmanage(elRefId);
			}
		};
	}, [buildPort, plumbInstance, gateId]);

	// We update the widget's state and set the default position if missing
	useEffect(() => {
		if (newPortPosStr !== currentPortPosStr) {
			updatePortPosition(port, JSON.parse(newPortPosStr));
		}
	}, [newPortPosStr, currentPortPosStr, port, updatePortPosition]);

	return (
		<div
			className={styles.PortContainer}
			ref={containerRef}
			style={gatePos}
			id={canvasDomId}
		>
			{port.label || port.name}
		</div>
	);
};


export default memo(VirtualPort, (prevProps: Props, nextProps: Props) => {
	const areEqual =
		JSON.stringify(prevProps.port) === JSON.stringify(nextProps.port) &&
		prevProps.gateId === nextProps.gateId;
	return areEqual;
});
