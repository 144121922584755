import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ChildBlockCustomInfoWithActions, getChildBlocksActions } from '../../app/recipe/utils';
import { RootState } from '../../app/store';
import { selectThingChildren } from '../../features/Workspace/workspaceSlice';
import { AsyncRequestStatus, AsyncState } from '../../types/core_t';


type ChildBlockActionsResponse = [ChildBlockCustomInfoWithActions, AsyncState]

/**
 * Keeps track of the available actions for a block
 * @param recipeId the id of the recipe in the pool
 * @param blockId th id of the block in the recipe (recipeId)
 */
function useChildBlocksActions (recipeId: string | null, blockId: string | null): ChildBlockActionsResponse {
	const [blockActions, setBlockActions] = useState<ChildBlockCustomInfoWithActions>({});
	// Keep track fo the attached things so we can reload the list of actions dynamically
	const thingChildren = useSelector((state: RootState) => selectThingChildren(state, blockId));
	const loadingRef = useRef<AsyncState>({ status: AsyncRequestStatus.idle });

	const getActions = useCallback(async () => {
		try {
			loadingRef.current.status = AsyncRequestStatus.loading;
			const actions = await getChildBlocksActions(recipeId!, blockId!);
			loadingRef.current.status = AsyncRequestStatus.completed;
			setBlockActions(actions);
		} catch (e) {
			console.error('Failed to load child actions: ', );
			loadingRef.current.status = AsyncRequestStatus.error;
			loadingRef.current.error = e;
		}

	}, [recipeId, blockId]);

	useEffect(() => {
		if (recipeId && blockId) { getActions(); }
	}, [recipeId, blockId, getActions, thingChildren]);

	return [blockActions, loadingRef.current];
}

export default useChildBlocksActions;
