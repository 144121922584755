import { Skeleton, Col, Row } from 'antd';
import React from 'react';
import styles from './CardSkeleton.module.css';

interface Props {
	totalCards: number;
}

const CardSkeleton = (props: Props): React.JSX.Element => {
	const cards = Array.from('x'.repeat(props.totalCards));

	return (

		<Row gutter={{ xs: 0, sm: 12, md: 16, lg: 16, xl: 24 }}>
			{cards.map((_, index) => (
				<Col key={index} xs={24} sm={24} md={12} lg={8} xl={6} xxl={4}>
					<article className={styles.Card}>
						<div className={styles.Header}>
							<Skeleton.Avatar active={true} shape="square" className={styles.Image}/>
						</div>
						<div className={styles.Details}>
							<div className={styles.Title}>
								<Skeleton active={true} />
							</div>
						</div>
					</article>
				</Col>
			))}
		</Row>

	);
};

export default CardSkeleton;
