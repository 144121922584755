
import React from 'react';
import Icon from '@ant-design/icons';
import ObjectWidgetProcessor, {
	Base64ToImageWidgetState,
	getDefaultState,
} from '@kemu-io/kemu-core/dist/gates/base64ToImageData';

import { CustomWidgetState, WidgetPortContext } from '@kemu-io/kemu-core/dist/types/gate_t';
import classNames from 'classnames';
import GateIcon from '../../gateIcon/gateIcon';
import {
	GateCustomSettingsProps,
	GetPortsInformationFunction,
	GateUI,
	GateUIProps,
} from '..';
import useReactiveWidgetState from '../../../common/hooks/useReactiveWidgetState';
import useTranslation from '../../../common/hooks/useTranslation';
import NumericInput from '../../WidgetsComponents/NumericInput/NumericInput';
import KemuSwitch from '../../form-control/kemuSwitch/kemuSwitch';
import { SETTINGS_CONTAINER_CLASS } from '../../../common/constants';
import styles from './base64ToImageData.module.css';
import { ReactComponent as Base64ToImageIcon } from './icon.svg';

const Base64ToImageDataWidget = (props: GateUIProps): React.JSX.Element => {
	return (
		<div className={`${styles.GateBody} ${props.info.disabled ? styles.DisabledWidget: ''}`}>
			<Icon component={Base64ToImageIcon}/>
		</div>
	);
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={Base64ToImageIcon} />}/>
	);
};

const GateCustomSettings = (props: GateCustomSettingsProps): React.JSX.Element => {
	const [state, setState] = useReactiveWidgetState<Base64ToImageWidgetState>(props.recipeId, props.blockId, props.gateInfo.id);
	const fixedState = {
		...getDefaultState(),
		...state
	};

	const t = useTranslation('LogicMaker.Gates.Base64ToImage.Settings');

	const updateResizeValue = (prop: 'width' | 'height', value: number) => {
		if (fixedState.resize) {
			setState({
				...fixedState,
				resize: {
					...fixedState.resize,
					[prop]: value,
				}
			});
		}
	};

	const handleWChange = (value: number) => updateResizeValue('width', value);
	const handleHChange = (value: number) => updateResizeValue('height', value);


	const handleSwitchChange = (checked: boolean) => {
		if (!checked) {
			setState({ ...fixedState, resize: undefined });
		} else {
			setState({
				...fixedState,
				resize: {
					width: 0,
					height: 0
				}
			});
		}
	};

	return (
		<div className={classNames(styles.SettingsContainer, SETTINGS_CONTAINER_CLASS)}>
			{props.children}

			<div className={styles.SwitchContainer}>
				<label>{t('Resize', 'Resize Image')}</label>
				<KemuSwitch
					size="small"
					checked={!!fixedState.resize}
					onChange={handleSwitchChange}
				/>
			</div>

			<div className={styles.InputContainer}>
				<NumericInput
					min={1}
					placeholder={t('PlaceHolderWidth', 'width')}
					value={fixedState.resize?.width}
					disabled={!fixedState.resize}
					onChange={handleWChange}
				/>
				<NumericInput
					min={1}
					placeholder={t('PlaceHolderHeight', 'height')}
					value={fixedState.resize?.height}
					disabled={!fixedState.resize}
					onChange={handleHChange}
				/>
			</div>

		</div>
	);
};

const getPortsInformation: GetPortsInformationFunction = (state: CustomWidgetState<Base64ToImageWidgetState>, widgetInfo) => {
	const portContext: WidgetPortContext = { recipePoolId: widgetInfo.recipePoolId, recipeType: widgetInfo.recipeType };
	const outputNames = ObjectWidgetProcessor.getOutputNames(state, portContext);
	const inputNames = ObjectWidgetProcessor.getInputNames(state, portContext);

	return {
		outputs: [{
			position: 'Right',
			name: outputNames[0].name,
			type: outputNames[0].type,
		}],

		inputs: [{
			position: 'Left',
			name: inputNames[0].name,
			type: inputNames[0].type,
		}],
	};
};

export default {
	getPortsInformation,
	BarIcon: GateBarIcon,
	Element: Base64ToImageDataWidget,
	CustomSettingsDialog: GateCustomSettings,
	hasTitle: true,
	getGatesBarTitle: (intl) => intl.formatMessage({ id: 'LogicMaker.Gates.Base64ToImage.Title', defaultMessage: 'Base64 To Image' }),
	getWidgetTitle: () => ' ',
} as GateUI;
