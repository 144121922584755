
import React from 'react';
import Icon from '@ant-design/icons';
import ArrayItemProcessor, { ArrayItemWidgetState, getDefaultState } from '@kemu-io/kemu-core/dist/gates/arrayItem';
import { WidgetPortContext } from '@kemu-io/kemu-core/dist/types/gate_t';
import useReactiveWidgetState from '../../../common/hooks/useReactiveWidgetState';
import { GetPortsInformationFunction, GateUI, GateUIProps } from '..';
import GateIcon from '../../gateIcon/gateIcon';
import { PortLocation } from '../../../types/canvas_t';
import { ReactComponent as ArrayItemIcon } from './arrayItem.svg';
import styles from './arrayItem.module.css';

type Props = GateUIProps

const ArrayItemWidget = (props: Props): React.JSX.Element => {
	const [state, setState] = useReactiveWidgetState<ArrayItemWidgetState>(props.recipeId, props.thingRecipeId, props.info.id);
	const fixedState = {
		...getDefaultState(),
		...state,
	};

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.currentTarget.value;
		if (!isNaN(Number(value))) {
			setState({
				...fixedState,
				index: Number(value),
			}, true);

		}
	};

	return (
		<div className={`${styles.GateBody}`}>
			<div className={`gate-input`}>
				{/* Using input to allow future implementation of variables */}
				<input type="text" value={fixedState.index} onChange={handleInputChange}/>
			</div>
		</div>
	);
};

/** Icon to be added to the bar */
const GateBarIcon = (): React.JSX.Element => {
	return (
		<GateIcon icon={<Icon component={ArrayItemIcon} />}/>
	);
};

const getPortsInformation: GetPortsInformationFunction = (state, widgetInfo) => {
	const portContext: WidgetPortContext = { recipePoolId: widgetInfo.recipePoolId, recipeType: widgetInfo.recipeType };
	const outputNames = ArrayItemProcessor.getOutputNames(state, portContext);
	const inputNames = ArrayItemProcessor.getInputNames(state, portContext);

	const positionByOutputName: Record<string, PortLocation> = {
		'output': [1, 0.3, 1, 0],
		'noItem': [1, 0.7, 1, 0]
	};

	return {
		inputs: [{
			position: 'Left',
			type: inputNames[0].type,
			name: inputNames[0].name
		}],

		outputs: outputNames.map(output => ({
			position: positionByOutputName[output.name],
			type: output.type,
			name: output.name
		})),
	};
};


export default {
	getPortsInformation,
	BarIcon: GateBarIcon,
	Element: ArrayItemWidget,
	hasTitle: true,
	getWidgetTitle: (intl) => intl.formatMessage({ id: 'LogicMaker.Gates.Json.BarTitle', defaultMessage: 'Array Item' }),
} as GateUI;
