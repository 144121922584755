import React from 'react';
import { CloseCircleFilled } from '@ant-design/icons';
import styles from './gateSettingsCloseBtn.module.css';

interface Props {
	onClose: () => void;
	className?: string;
}

const GateSettingsCloseBtn = (props: Props): React.JSX.Element => {
	return (
		<span className={`settings-close-btn ${styles.CloseBtn} ${props.className ? props.className : ''}`} onClick={props.onClose}>
			<CloseCircleFilled/>
		</span>
	);
};

export default GateSettingsCloseBtn;
