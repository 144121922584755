/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommandMessageResponse, GetInstalledDependenciesResponse, InstallDependenciesResponse, WebsocketCommand, WebsocketCommandStatus } from '@kemu-io/kemu-types/dist/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';


export interface WebsocketState {
	commands: Record<string, CommandMessageResponse<any> & {
    action: WebsocketCommand,
    sentAt: number,
    receivedAt?: number
  }>;
}


const initialState: WebsocketState = {
  commands: {},
};


export const websocketSlice = createSlice({
	name: 'websocket',
	initialState,
	reducers: {
    /** clears out all InstallDependencies commands*/
    clearInstallCommandAction: (state) => {
      const next: WebsocketState = { ...state, commands: { ...state.commands } };
      Object.keys(next.commands).forEach(key => {
        if (next.commands[key].action === WebsocketCommand.InstallDependencies) {
          delete next.commands[key];
        }
      });

      return next;
    },
		setPendingCommandAction: (state, action: PayloadAction<{ id: string, commandAction: WebsocketCommand }>) => {
      state.commands[action.payload.id] = {
        action: action.payload.commandAction,
        status: WebsocketCommandStatus.Pending,
        error: undefined,
        id: action.payload.id,
        response: undefined,
        sentAt: Date.now(),
      };
    },
    setCommandResponseAction: (state, action: PayloadAction<{response: CommandMessageResponse}>) => {
      const next: WebsocketState = { ...state, commands: { ...state.commands } };
      const messageId = action.payload.response.id;

      // Ignore already answered responses
      if (next.commands[messageId]?.status !== WebsocketCommandStatus.Pending) {
        // console.log(`Message id ${messageId} already answered. Ignoring response: ${JSON.stringify(action.payload.response)}`);
        return;
      }

      if (next.commands[messageId]) {
        next.commands[messageId] = {
          ...next.commands[messageId],
          ...action.payload.response,
          ...(
            action.payload.response.status !== WebsocketCommandStatus.Pending ? {
            receivedAt: Date.now()
          } :{}),
        };
      }

      const commandsList = Object.keys(next.commands);
      const now = (new Date()).getTime();

      // Clear out old commands
      commandsList.forEach((commandId) => {
        // Delete commands that have been waiting for more than 15 min for a response
        if (!next.commands[commandId].receivedAt) {
          const elapsedSinceSent = now - next.commands[commandId].sentAt;
          if (elapsedSinceSent > 15 * 60 * 1000) {
            delete next.commands[commandId];
          }
        } else {
          // Delete commands that were answered over 5 mins ago (to keep memory clean)
          // Calculate elapsed time between now and the command's sentAt timestamp
          const elapsedSinceReceived = now - next.commands[commandId].receivedAt!;
          if (elapsedSinceReceived > 5 * 60 * 1000) {
            delete next.commands[commandId];
          }
        }
      });

      return next;
    }
	},

	// extraReducers: (builder) => {

	// }
});


export const {
	clearInstallCommandAction,
	setPendingCommandAction,
	setCommandResponseAction
} = websocketSlice.actions;


export const selectCommandResponse = (id: string | null) => (state: RootState) => {
  if (!id) { return null; }
  if (state.websocket.commands[id]) {
    return state.websocket.commands[id]?.response;
  }

  return null;
};


export const selectLastDependencyList = (state: RootState): CommandMessageResponse<GetInstalledDependenciesResponse> | null => {
  const commands = Object.values(state.websocket.commands);
  // Order commands by receivedAt timestamp (newest first)
  const getInstalledCommands = commands.filter((command) => (
    command.action === WebsocketCommand.GetInstalledDependencies
  ));

  const orderedCommands = getInstalledCommands.sort((a, b) => b.sentAt! - a.sentAt!);

  return orderedCommands[0] || null;
};


export const selectLastInstallDependencyCommand = (state: RootState): CommandMessageResponse<InstallDependenciesResponse> | null => {
  const commands = Object.values(state.websocket.commands);
  // Order commands by receivedAt timestamp (newest first)
  const getInstalledCommands = commands.filter((command) => (
    command.action === WebsocketCommand.InstallDependencies
  ));

  const orderedCommands = getInstalledCommands.sort((a, b) => b.sentAt! - a.sentAt!);

  return orderedCommands[0] || null;
};

export default websocketSlice.reducer;
