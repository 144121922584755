import { Spin, Row, Col, Select } from 'antd';
import React, { useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import FormGroup from '../../../components/form-control/formGroup/formGroup';
import StyledInput from '../../../components/form-control/styledInput/styledInput';
import AuthCard from '../authCard';
import StyledSelect from '../../../components/form-control/styledSelect/styledSelect';
import StyledButton from '../../../components/form-control/styledButton/styledButton';
// import styles from './organizationDetails.module.css';

const { Option } = Select;

export interface OrganizationInfo {
	name: string;
	logo: string;
	// TO BE implemented
	domain: string;
	industry: string;
	errors: Partial<Omit<OrganizationInfo, 'errors'>>
}

interface Props {
	onSubmit: (details: OrganizationInfo) => void;
	processing: boolean;
}

const defaultOrganization: OrganizationInfo = {
	name: '', logo: '', domain: '', industry: '', errors: {}
};

const defaultIndustries = ['Colleges', 'Universities', 'Schools', 'Homeschooling', 'Education Other', 'Health', 'Computer Software', 'Other'];

const OrganizationDetails = (props: Props): React.JSX.Element => {
	const { processing } = props;
	const [formElements, setFormElements] = useState<OrganizationInfo>(defaultOrganization);
	const intl = useIntl();

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		const elements = { ...formElements };
		let hasErrors = false;
		setFormElements(elements => ({ ...elements, errors: {} }));

		if (elements.name.trim() === '') {
			elements.errors.name = intl.formatMessage({ id: 'Screen.SignUp.RequiredField', defaultMessage: '* required' });
			hasErrors = true;
		}

		if (elements.industry.trim() === '') {
			elements.errors.industry = intl.formatMessage({ id: 'Screen.SignUp.RequiredField', defaultMessage: '* required' });
			hasErrors = true;
		}

		if (hasErrors) {
			return setFormElements(elements);
		}

		props.onSubmit && props.onSubmit(elements);
	};

	const handleSelectedIndustry = (value: unknown) => {
		setFormElements(elements => ({ ...elements, industry: value as string }));
	};

	const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
		const elName = event.currentTarget.getAttribute('name') || undefined;
		const value = event.currentTarget.value;
		if (elName !== undefined && value !== undefined) {
			setFormElements(elements => {
				return {
					...elements,
					[elName]: value!,
					errors: {
						[elName]: undefined
					}
				};
			});
		}
	};


	return (
		<AuthCard title="Organization Details">
			<Spin indicator={<LoadingOutlined />} spinning={processing}>
				<form method="POST" onSubmit={handleSubmit}>
					<Row gutter={15}>
						<Col xs={20} sm={12} md={4} lg={12} xl={12}>
							<FormGroup>
								<StyledInput
									label={<FormattedMessage id="Auth.OrganizationDetails.Name.Label" defaultMessage="Organization name" />}
									i18nPlaceholderId="Auth.OrganizationDetails.Name.Placeholder"
									i18nPlaceholderDefaultMessage="Enter the name of your organization"
									name="name"
									onChange={handleChange}
									value={formElements.name}
									errorMessage={formElements.errors.name}
								/>
							</FormGroup>
						</Col>
						<Col xs={20} sm={12} md={12} lg={12} xl={12}>
							<FormGroup>
								<StyledSelect value={formElements.industry} label="Industry" errorMessage={formElements.errors.industry} onChange={handleSelectedIndustry}>
									{defaultIndustries.map((industry, index) => (
										<Option value={industry} key={index}>{industry}</Option>
									))}
								</StyledSelect>
							{/* <StyledInput
								label={<FormattedMessage id="Auth.OrganizationDetails.industry.Label" defaultMessage="Industry" />}
								name="industry"
								onChange={handleChange}
								value={formElements.industry}
								errorMessage={formElements.errors.industry}
							/> */}
							</FormGroup>
						</Col>
					</Row>

					<Row align="middle" justify="end">
						<Col>
							<FormGroup >
								<StyledButton
									title={<FormattedMessage id="Auth.OrganizationDetails.SubmitBtn" defaultMessage="Submit" />}
									type="submit"
								/>
							</FormGroup>
						</Col>
					</Row>
				</form>
			</Spin>
		</AuthCard>
	);
};

export default OrganizationDetails;
