import { LimitedThingInfo, ThingCategory } from '@kemu-io/kemu-types/dist/types';
import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import * as thingApi from '../../../api/thing/thingApi';
import { overrideDevThings } from '../../../common/utils';
import { AsyncRequestStatus } from '../../../types/core_t';
import { clearInstallationProgress, setInstallationProgress } from '../../../features/Workspace/workspaceSlice';
import { ThingInfoWithIcon, ThingState } from './thingSlice';
import { downloadThingBundlesInParallel } from './helpers';

/**
 * Fetches the list of default and user installed blocks.
 */
export const installDefaultThingsAction = createAsyncThunk('/thing/fetchDefaultThings', async (_, thunkAPI): Promise<ThingInfoWithIcon[]> => {
	const response = await thingApi.getDefaultThings();

	// Replace any block in dev mode
	overrideDevThings(response);

	const thingsMap = response.reduce((map, thing) => ({
		...map,
		// NOTE: We use a combination of id + version because 2 different versions of the same thing
		// share the same database ID. By appending the version we guarantee the id is unique.
		[`${thing.id}_${thing.version}`]: {
			...thing
		}
	}), {} as Record<string, LimitedThingInfo>);


	const results = await downloadThingBundlesInParallel(thingsMap, (thingId, download) => {
		if (!download.errorMsg) {
			thunkAPI.dispatch(setInstallationProgress({
				id: thingsMap[thingId].id,
				block: thingsMap[thingId],
				progress: download.progress!,
				version: thingsMap[thingId].version,
			}));
		} else if (download.errorMsg) {
			thunkAPI.dispatch(setInstallationProgress({
				id: thingsMap[thingId].id,
				block: thingsMap[thingId],
				errorMsg: download.errorMsg,
				version: thingsMap[thingId].version,
			}));
		}
	});


	const successfullyInstalled: LimitedThingInfo[] = [];
	results.forEach(result => {
		if (result.status === 'fulfilled') {
			const blockUid = `${result.value.id}_${result.value.version}`;
			thunkAPI.dispatch(clearInstallationProgress({
				id: result.value.id,
				version: result.value.version
			}));

			successfullyInstalled.push(thingsMap[blockUid]);
		} else {
			// Don't clear: leave errors visible to the user
			// Don't add to list of installed blocks
		}
	});


	// Add extra info to the results for presentation purposes
	const parsedThings: ThingInfoWithIcon[] = successfullyInstalled.map(blockInfo => {
		const info: ThingInfoWithIcon = {
			...blockInfo,
			icon: blockInfo.bundle + '/icon.svg'
		};

		return info;
	});

	// Only show Virtual Blocks since hardware blocks are displayed as they get online
	return parsedThings.filter(thing => thing.category === ThingCategory.Virtual);
});


export const installDefaultThingsReducer = ((builder: ActionReducerMapBuilder<ThingState>/*, thunk: any*/): void => {
  builder.addCase(installDefaultThingsAction.pending, (state) => {
		state.fetchDefaultThingStatus = {
      status: AsyncRequestStatus.loading,
      error: undefined,
    };
  });

  builder.addCase(installDefaultThingsAction.fulfilled, (state, action: PayloadAction<ThingInfoWithIcon[]>) => {
    state.fetchDefaultThingStatus = {
      status: AsyncRequestStatus.completed,
      error: undefined,
    };

    state.things = action.payload;
  });

  builder.addCase(installDefaultThingsAction.rejected, (state, action) => {
    state.fetchDefaultThingStatus = {
      status: AsyncRequestStatus.error,
      error: action.error
    };
  });
});
