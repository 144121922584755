import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Icon from '@ant-design/icons';
import { Row, Col } from 'antd';
import { Auth } from '@aws-amplify/auth';
import OrganizationDetails, { OrganizationInfo } from '../organizationDetails/organizationDetails';
import StyledButton from '../../../components/form-control/styledButton/styledButton';
import AuthCard from '../authCard';
import useQueryString from '../../../common/hooks/useQueryString';
import Spinner from '../../../components/spinner/spinner';
import { createOrganization } from '../../../api/organization/organizationApi';
import { INDIVIDUAL_ORGANIZATION_ATTR } from '../../../common/constants';
import routes from '../../../common/routes';
import { ReactComponent as UserIcon } from './userIcon.svg';
import { ReactComponent as OrganizationIcon } from './organizationIcon.svg';
import styles from './AccountDetails.module.css';
import { UserSessionContext } from '@common/context/UserSessionContext/UserSessionContext';

const AccountDetails = (): React.JSX.Element | null => {
	const history = useHistory();
	const queryString = useQueryString();
	const userId = queryString.get('sub');
	const { user } = useContext(UserSessionContext);
	const [showOrganizationForm, setShowOrganizationForm] = useState(false);
	const [loadingFor, setLoadingFor] = useState<'individual' | 'organization' | null>(null);
	const onConfigureOrganization = () => {
		setShowOrganizationForm(true);
	};


	const onIndividualSignUp = async () => {
		setLoadingFor('individual');
		// const user = await Auth.currentAuthenticatedUser();
		await Auth.updateUserAttributes(user, {
			'custom:organization': INDIVIDUAL_ORGANIZATION_ATTR
		});
		// UPDATE: The auth/layout.tsx should detect the new user attribute and redirect to home
		// history.push(KemuRoutes.app.baseRoute);
	};

	const handleOrganizationSubmit = async (details: OrganizationInfo) => {
		setLoadingFor('organization');
		const response = await createOrganization({
			industry: details.industry,
			name: details.name,
		});

		// const user = await Auth.currentAuthenticatedUser();
		await Auth.updateUserAttributes(user, {
			'custom:organization': response.id
		});
	};

	// if(!userId) {
	// 	history.push(routes.auth.getSignInRoute());
	// 	return null;
	// }

	useEffect(() => {
		if (!userId && !showOrganizationForm) {
			history.push(routes.auth.getSignInRoute());
		}
	}, [userId, history, showOrganizationForm]);

	if (showOrganizationForm) {
		return <OrganizationDetails processing={loadingFor === 'organization'} onSubmit={handleOrganizationSubmit} />;
	}

	if (!userId) { return null; }

	return (
		<AuthCard title="Account Type">
			{/* TODO: intl! */}
			<p>Please choose the type of account you wish to create</p>
			<div className={styles.OptionsContainer}>
				<Row justify="center" align="middle">
					<Col xs={24} sm={6} xl={9} md={10}>
						<Spinner loading={loadingFor === 'individual'}>
							<StyledButton disabled={!!loadingFor} color="light" className={styles.AccountTypeButton} onClick={onIndividualSignUp} title={
								<>
									<Icon component={UserIcon} style={{ fontSize: 50 }}/>
									<span>Individual</span>
								</>
							}/>
						</Spinner>
					</Col>

					<Col xs={24} sm={6} md={4} xl={2}>
						<span className={styles.OrContainer}>OR</span>
					</Col>

					<Col  xs={24} sm={6} xl={9} md={10}>
						<Spinner loading={loadingFor === 'organization'}>
							<StyledButton disabled={!!loadingFor} color="light" className={styles.AccountTypeButton} onClick={onConfigureOrganization} title={
								<>
									<Icon component={OrganizationIcon} style={{ fontSize: 50 }}/>
									<span>Organization</span>
								</>
							}/>
						</Spinner>
					</Col>
				</Row>

			</div>
		</AuthCard>
	);
};

export default AccountDetails;
