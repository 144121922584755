import { PayloadAction, Draft } from '@reduxjs/toolkit';
import { GroupWidgetPort } from '@kemu-io/kemu-core/dist/types/gate_t';
import { Position } from '@kemu-io/kemu-core/dist/types/block_t';
import { WidgetGroupState } from '@kemu-io/kemu-core/dist/gates/widgetGroup';
import { LogicMapperState } from '../logicMapperSlice';
import showCreateWidgetBundleModal from './showCreateWidgetBundleModal';
import * as RecipeActions from '@src/app/recipe/utils';
import { jsonCloneObject } from '@common/utils';
import { StatelessRecipeWidget } from '@src/types/core_t';

export type WidgetGroupStateWithId = (WidgetGroupState & { widgetId?: string });

export type MinActionInfo = {
	/** id of the thing in the recipe */
	thingId: string;
	/** id of the recipe in the pool */
	recipeId: string;
	/** name of the custom widget */
}

export type AddCustomWidgetProps = MinActionInfo & {
	name: string;
	/** description of the custom widget*/
	description: string;
	inputs: GroupWidgetPort[];
	outputs: GroupWidgetPort[];
	/** 
	 * The default location in the canvas to position the custom widget.
	 * If not provided, the last mouse click location will be used instead.
	 * */
	position?: Position;
	parentGroupId?: string;
	color?: string;
	icon?: string;
}

export const addCustomWidgetReducer = (
	state: Draft<LogicMapperState>,
	action: PayloadAction<AddCustomWidgetProps>
): void => {
	// Prevent connecting widgets already linked
	const addedWidget = RecipeActions.addCustomWidgetToThing(
		action.payload.recipeId,
		action.payload.thingId,
		action.payload.name,
		action.payload.description,
		action.payload.inputs,
		action.payload.outputs,
		action.payload.position || {
			x: state.lastMouseClick.x,
			y: state.lastMouseClick.y
		},
		action.payload.icon,
		action.payload.color,
		action.payload.parentGroupId,
	);

	state.gates[addedWidget.id] = jsonCloneObject<StatelessRecipeWidget>(addedWidget);
};


export const editCustomWidgetReducer = (state: Draft<LogicMapperState>, action: PayloadAction<AddCustomWidgetProps & {widgetId: string}>): void => {
	// const canvasInfo = RecipeActions.getCanvasInfo(action.payload.recipeId);
	const currentState = RecipeActions.getWidgetState<WidgetGroupState>(action.payload.widgetId, action.payload.thingId, action.payload.recipeId);

	RecipeActions.editCustomWidgetState(
		action.payload.recipeId,
		action.payload.thingId,
		action.payload.widgetId,
		{
			description: action.payload.description,
			inputs: action.payload.inputs,
			outputs: action.payload.outputs,
			name: action.payload.name,
			type: 'custom',
			color: action.payload.color,
			icon: action.payload.icon,
			// canvasPosition: canvasInfo.workspace.position,
			canvasPosition: currentState.canvasPosition,
			// restore the linked connection info
			collectionInfo: currentState.collectionInfo
		}
	);

	state.gates[action.payload.widgetId] = {
		...state.gates[action.payload.widgetId],
	};
};


export const setShowCreateWidgetModalReducer = (state: Draft<LogicMapperState>, action: PayloadAction<MinActionInfo & { widgetId: string } | boolean>): void => {
	let widgetInfo: WidgetGroupStateWithId | boolean = action.payload === true ? (action.payload as boolean) : false;
	// If a widget id was provided, we are in editing mode. Read state from recipe
	// before showing the modal
	if (action.payload && action.payload !== true) {
		const currentState = RecipeActions.getWidgetState<WidgetGroupState>(
			action.payload.widgetId,
			action.payload.thingId,
			action.payload.recipeId,
		);

		const stateCopy = jsonCloneObject<WidgetGroupState>(currentState);
		widgetInfo = {
			...stateCopy,
			widgetId: action.payload.widgetId,
		};
	}

	state.showCreateWidgetModal = widgetInfo;
};


/**
 * Adds a new widget and its
 * @param state 
 * @param action 
 */
// export const addWidgetFromTemplateReducer = async (state: Draft<LogicMapperState>, action: PayloadAction<{
// 	widget: WidgetCollectionItem,
// 	recipeId: string,
// 	thingId: string,
// 	currentGroupId?: string,
// 	position?: Position,
// }>): Promise<void> => {
// 	// FIXME: this should be a thunk
// 	const gatesList = await RecipeActions.addCustomWidgetFromTemplate(
// 		action.payload.widget,
// 		action.payload.recipeId,
// 		action.payload.thingId,
// 		action.payload.position,
// 		action.payload.currentGroupId
// 	);

// 	const stateLessGates: StatelessWidgetsMap = {};
// 	Object.values(gatesList).map(gate => {
// 		// eslint-disable-next-line @typescript-eslint/no-unused-vars
// 		const { state, ...stateLess } = gate;
// 		stateLessGates[gate.id] = stateLess as StatelessRecipeWidget;
// 	});

// 	state.gates = safeJsonClone(stateLessGates);
// };



export default {
	addCustomWidgetReducer,
	editCustomWidgetReducer,
	setShowCreateWidgetModalReducer,
	showCreateWidgetBundleModal,
	// addWidgetFromTemplateReducer
};
