import { RecipeType } from '@kemu-io/kemu-types/dist/types';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentRecipe } from '../../features/Workspace/workspaceSlice';
import routes from '../routes';

/**
 * @returns a function that when invoked
 * returns the full path to workspace, including
 * the recipe mode and id (id exists).
 */
const useCurrentWorkspacePath = (): () => string => {
  const currentRecipe = useSelector(selectCurrentRecipe);
  const dbId = currentRecipe.entityInfo?.dbId;
  const type = currentRecipe.type;
  const poolId = currentRecipe.poolId;

  const getWorkspacePath = useCallback(() => {
    // Restore workspace if a recipe is open
    if (poolId) {
			if (type === RecipeType.Cloud && dbId) {
				return routes.recipe.getCloudRecipeRoute(dbId);
			} else if (type === RecipeType.Browser) {
				return routes.recipe.getBrowserRecipeRoute(dbId || '');
			}
		}

		return routes.getBaseRoute();
  }, [poolId, type, dbId]);

  return getWorkspacePath;
};

export default useCurrentWorkspacePath;
