import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StandardModal from '../modal/standardModal';
import StyledInput from '../form-control/styledInput/styledInput';
import { hideSaveAsModal, selectSaveAsModal } from '@src/app/reducers/widget/widgetSlice';
import useTranslation from '@common/hooks/useTranslation';
import { currentRecipePoolId } from '@src/features/Workspace/workspaceSlice';
import { saveWidgetAsAction } from '@src/app/reducers/widget/saveWidgetAsReducer';

const SaveWidgetAsModal = (): React.JSX.Element => {
	const t = useTranslation('Widget.SaveAsModal');
	const dispatch = useDispatch();
	const inputRef = useRef<HTMLInputElement>(null);
	const modalInfo = useSelector(selectSaveAsModal);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [widgetName, setWidgetName] = useState(modalInfo?.collectionInfo?.name || null);
	const recipePoolId = useSelector(currentRecipePoolId);


	const handleSave = useCallback((name: string) => {
		if (modalInfo?.collectionInfo && recipePoolId) {

			dispatch(saveWidgetAsAction({
				newName: name,
				recipeId: recipePoolId,
				widgetDbId: modalInfo.collectionInfo.dbId,
				widgetIdInRecipe: modalInfo.collectionInfo.widgetRecipeId,
				thingId: modalInfo.collectionInfo.thingId,
				variant: modalInfo.collectionInfo.variant,
			}));
		}

	}, [dispatch, modalInfo?.collectionInfo, recipePoolId]);

	// Clear out the error message when they type again
	const handleInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const text = evt.currentTarget.value;
		setWidgetName(text);
		setErrorMessage(() => null);
	};

	const handleOk = useCallback(() => {
		let name = inputRef.current?.value || '';
		name = name.trim();
		if (name === '') {
			return setErrorMessage(t('InvalidName', 'Please enter a valid name'));
		}

		setErrorMessage(null);
		handleSave(name);
	}, [t, handleSave]);


	const handleCancel = useCallback(() => {
		dispatch(hideSaveAsModal());
	}, [dispatch]);

	useEffect(() => {
		if (modalInfo?.collectionInfo) {
			setWidgetName(modalInfo.collectionInfo.name || '');
		}
	}, [modalInfo]);

	return (
		<StandardModal
			closeOnMaskClick={false}
			onOk={handleOk}
			onCancel={handleCancel}
			okBtnLabel={t('SaveBtn', 'Save')}
			cancelBtnLabel={t('CancelBtn', 'Cancel')}
			title={t('Title', 'Save Widget As')}
			visible={modalInfo?.visible === true}
			width={300}
		>
			<StyledInput
				onChange={handleInputChange}
				value={widgetName || ''}
				ref={inputRef}
				errorMessage={errorMessage}
				label={t('InputLabel', 'Name')}
				placeholder={t('InputPlaceholder', 'Enter a new name of your widget')}
			/>

		</StandardModal>
	);
};

export default SaveWidgetAsModal;
