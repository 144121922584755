import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { RecipeType } from '@kemu-io/kemu-types/dist/types';
import { History } from 'history';
import { getDefaultRecipe } from '../../../features/interface/interfaceApi';
import { setRecipeType, WorkspaceState } from '../../../features/Workspace/workspaceSlice';
import routes from '../../../common/routes';
import { openRecipeAction } from './openRecipeReducer';

/**
 * Creates a default recipe, registers it with Kemu Core
 */
export const createBrowserRecipeAction = createAsyncThunk<void, {
  name?: string,
  history: History
}>(
  '/Workspace/createBrowserRecipe', async (options, thunkAPI) => {
    // Create a basic recipe
    const defaultRecipe = await getDefaultRecipe();

    if (options?.name) {
      defaultRecipe.name = options.name;
    }

    // Open it so that Kemu creates a pool id
    await thunkAPI.dispatch(openRecipeAction({
      recipe: defaultRecipe,
      isDefault: true,
      dbInfo: {
        id: '',
        authorId: '',
        version: 0,
        recipeType: RecipeType.Browser
      }
    }));

    thunkAPI.dispatch(setRecipeType({
      type: RecipeType.Browser,
      cloudInfo: null,
    }));

    // No id yet for this recipe, so we pass in an empty string to prevent showing ':id'
    options.history.push(routes.recipe.getBrowserRecipeRoute(''));
  }
);


export const createBrowserRecipeReducer = ((builder: ActionReducerMapBuilder<WorkspaceState>): void => {
  builder.addCase(createBrowserRecipeAction.fulfilled, (state) => {
    state.creatingCloudRecipe = null;
    state.baseRecipeLoaded = true;
  });

  builder.addCase(createBrowserRecipeAction.rejected, (state, action) => {
    console.log('Error creating browser recipe: ', action);
    state.creatingCloudRecipe = null;
  });

  builder.addCase(createBrowserRecipeAction.pending, (state) => {
    state.creatingCloudRecipe = null;
  });
});
