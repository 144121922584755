/*
 * Written by Alexander Agudelo < alex@kemu.io >, 2021
 * Date: 29/Jul/2021
 * Last Modified: 29/08/2023, 6:44:52 pm
 * Modified By: Alexander Agudelo
 * Description:  Stores widgets contents locally
 * 
 * ------
 * Copyright (C) 2021 Kemu - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential.
 */

import { ab2Str, u8Arr2Str } from '@kemu-io/kemu-core/dist/common/utils';
let cache: Cache;

/**
 * Initializes the widget cache
 */
const initialize = async (): Promise<void> => {
	if (!('storage' in navigator)) {
		return console.error('Storage API not supported by this browser');
	}

	cache = await caches.open('kemu-widgets-cache');
};

const isInitialized = (): boolean => !!cache;

const getCacheLocation = (id: string/* , version: number */): string => {
	// return `/widgets/${id}/${version}`;
	return `/widgets/${id}`;
};

/**
 * Adds the widget contents to the local cache.
 */
const storeWidget = async (id: string, version: number, contents: string | Uint8Array): Promise<void> => {
	const headers = new Headers({
		'version': String(version),
		'createdAt': new Date().toJSON(),
		'content-type': 'application/json;charset=UTF-8',
		'content-length': String(contents.length),
	});

	const url = getCacheLocation(id);
	await cache.put(url, new Response(contents, { headers }));
};

/**
 * Removes the given widget from the local cache.
 */
const deleteWidget = async (id: string/* , version: number */): Promise<void> => {
	try {
		await cache.delete(getCacheLocation(id));
	} catch (e) {
		console.error(`Error deleting widget [${id}]: `, e);
	}
};

/**
 * Gets the widgets contents from cache or it downloads it from the given url
 * if missing.
 */
const getWidget = async <T extends boolean = false>(
	id: string,
	version: number,
	downloadUrl: string,
	asArrayBuffer?: T
): Promise<T extends true ? Uint8Array : string> => {
	const response = await cache.match(getCacheLocation(id/* , version */));
	if (!response) {
		// Download instead
		const fetchResponse = await fetch(downloadUrl);

		const data = await fetchResponse.arrayBuffer();
		if (asArrayBuffer) {
			return new Uint8Array(data) as T extends true ? Uint8Array : string;
		} else {
			const contentsString = ab2Str(data);
			await storeWidget(id, version, contentsString);
			return contentsString as T extends true ? Uint8Array : string;
		}
	} else {
		const data = await response.arrayBuffer();
		const binary = new Uint8Array(data);
		if (asArrayBuffer) {
			return binary as T extends true ? Uint8Array : string;
		} else {
			return u8Arr2Str(binary) as T extends true ? Uint8Array : string;
		}
	}
};

export default {
	initialize,
	storeWidget,
	getWidget,
	isInitialized,
	deleteWidget
};
