import React from 'react';
import { Layout } from 'antd';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import Canvas from '../../canvas/canvas';

import 'react-reflex/styles.css';
import Breadcrumb from '../../breadcrumb/breadcrumb';
import styles from './HorizontalSplit.module.css';
import WidgetsBar from '@src/features/LogicMapper/WidgetsBar/WidgetsBar';
import { SelectedBlockInfo } from '@src/types/core_t';
import useJSPlumb from '@hooks/useJsPlumb';
import LogicMapper from '@src/features/LogicMapper/LogicMapper';
import SplitPaneButtons from '@src/features/LogicMapper/SplitPaneButtons/SplitPaneButtons';
import { selectVisibleGroup } from '@src/features/Workspace/workspaceSlice';
import ContextMenu from '@src/features/LogicMapper/contextMenu/contextMenu';
import { WIDGET_CONNECTION_CLASS } from '@common/constants';

type Props = {
  visible: boolean;
  recipePoolId: string | null;
  openedBlock: SelectedBlockInfo | null;
  onLogicMapperMounted: () => void;
}

const { Content } = Layout;

const LogicMapperSection = (props: Props): React.JSX.Element => {
  const { visible, recipePoolId, openedBlock, onLogicMapperMounted } = props;
  const visibleGroup = useSelector(selectVisibleGroup);
  const {
    containerEl: logicMapperContainerEl,
    initialize: initLogicMapperJsPlumb,
    jspInstance: logicMapperJsPlumb,
  } = useJSPlumb({ connectionClass: WIDGET_CONNECTION_CLASS });

  return (
    <div className={styles.Section}>
      {visible && (
        <Layout className="site-layout">
          {recipePoolId && (
            <WidgetsBar recipePoolId={recipePoolId}/>
          )}
          <Content className={
            classNames(
              styles.LayoutContent,
              styles.LogicMapperContainer, {
                'custom-widget': !!visibleGroup
              }
            )
          }>
            <Breadcrumb />
            <ContextMenu plumbInstance={logicMapperJsPlumb} />
            <SplitPaneButtons />
            {recipePoolId && openedBlock && (
              <Canvas
                ref={initLogicMapperJsPlumb}
                recipeId={recipePoolId}
                selectedBlock={openedBlock}
                loadingArea="logic-mapper"
              >
                {logicMapperJsPlumb && (
                  <LogicMapper
                    plumbInstance={logicMapperJsPlumb}
                    containerEl={logicMapperContainerEl}
                    thingRecipeId={openedBlock.recipeId}
                    thingDbId={openedBlock.DbId}
                    thingVersion={openedBlock.version}
                    recipeId={recipePoolId}
                    onMounted={onLogicMapperMounted}
                  />
                )}

                {/* <AnnotationsManager recipeId={recipePoolId} /> */}
              </Canvas>
            )}
          </Content>
        </Layout>
      )}
    </div>
  );
};

export default LogicMapperSection;
