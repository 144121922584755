import { Drawer } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BlocksManager from '@kemu-io/kemu-core/dist/blocks/manager';
import { BlockState } from '@kemu-io/kemu-core/dist/types/block_t';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { settingsPanelDetails, setBlockForSettingsPanel } from '../interface/interfaceSlice';
import styles from './SettingsPanel.module.css';
import { UISettingsPanelInstance } from '@src/types/propertiesPanel_t';
import { SettingsPanelConstructor } from '@src/types/core_t';
import EventBridge from '@src/app/eventBridge/EventBridge';
import { getBlockState } from '@src/app/recipe/utils';
import getFreshChatWidget from '@src/app/recipe/freshChatWidget';
import useBlockState from '@hooks/useBlockState';

const SettingsPanel = (): React.JSX.Element | null => {
	const settingsPanelRef = useRef<UISettingsPanelInstance | null>(null);
	const containerRef = useRef<HTMLDivElement>(null);
	const settingsPanel = useSelector(settingsPanelDetails);
	const recipePoolId = settingsPanel ? settingsPanel.block.recipePoolId : null;
	const blockRecipeId = settingsPanel ? settingsPanel.block.recipeId : null;
	const intl = useIntl();
	const prefix = intl.formatMessage({ id: 'Interface.SettingsPanel.Prefix', defaultMessage: 'Settings' });

	const [blockState, setBlockState] = useBlockState(recipePoolId, blockRecipeId);
	const [panelVisible, setPanelVisible] = useState(!!settingsPanel);

	const dispatch = useDispatch();

	const savePropsSettings = useCallback((newState: BlockState) => {
		setBlockState(newState);
	}, [setBlockState]);

	// Called when the canvas is sending a message, we need to pipe it to the current instance
	if (settingsPanel) {
		EventBridge.setPropsPanelListener(settingsPanel.block.recipeId, async (canvasEvt) => {
			if (settingsPanelRef.current && settingsPanelRef.current.exports.onCanvasEvent) {
				return settingsPanelRef.current.exports.onCanvasEvent(canvasEvt);
			}
		});
	}

	// Updates the internal state of the panel when it changes externally
	useEffect(() => {
		if (panelVisible) {
			settingsPanelRef.current && settingsPanelRef.current.exports.setState && settingsPanelRef.current.exports.setState(blockState);
		}
	}, [blockState, settingsPanelRef, panelVisible]);

	// initializes and waits for the instance to be mounted.
	// (Technically not necessary since at this point in time, nothing happens afterwards)
	const initializeInstance = useCallback(async (location: string) => {
		if (settingsPanelRef.current && settingsPanelRef.current.exports.initialize && blockRecipeId && recipePoolId) {
			const initialState = getBlockState(blockRecipeId, recipePoolId);
			await settingsPanelRef.current.exports.initialize(location, initialState);
		}
	}, [blockRecipeId, recipePoolId]);

	// Panel visibility and instance creation
	useEffect(() => {
		setPanelVisible(!!settingsPanel);
		if (!containerRef.current || !recipePoolId || !settingsPanel) { return; }

		const SettingsPanelCreator = BlocksManager.getBlockUI(settingsPanel.block.DbId, settingsPanel.block.version).SettingsPanel as SettingsPanelConstructor;
		const blockLocation = BlocksManager.getBlockLocation(settingsPanel.block.DbId, settingsPanel.block.version);

		const panel = new SettingsPanelCreator({ target: containerRef.current });

		panel.exports.onStateChanged && panel.exports.onStateChanged(savePropsSettings);

		panel.exports.setEmitToCanvas && panel.exports.setEmitToCanvas(async (evt) => {
			return EventBridge.emitToCanvas(settingsPanel.block.recipeId, evt);
		});

		settingsPanelRef.current = panel;
		panel.exports.initialize && initializeInstance(blockLocation);


		return () => {
			if (settingsPanelRef.current) {
				// clear listener from instance
				panel.exports.setEmitToCanvas && panel.exports.setEmitToCanvas(null);
				// Remove event listener from bridge
				EventBridge.setPropsPanelListener(settingsPanel.block.recipeId, null);
				settingsPanelRef.current.$destroy();
				settingsPanelRef.current = null;
			}
		};
	}, [savePropsSettings, recipePoolId, blockRecipeId, settingsPanel, initializeInstance]);

	const onVisibilityChanged = (isVisible: boolean) => {
		const chatWidget = getFreshChatWidget();

		if (!isVisible) {
			dispatch(setBlockForSettingsPanel(null));
			chatWidget.show();
		} else if (chatWidget.initialized && !chatWidget.isOpen()) {
			chatWidget.hide();
		}
	};

	const onClose = () => {
		setPanelVisible(false);
	};

	if (!settingsPanel) { return null; }

	return (
		<div className={classNames(styles.SettingsPanelWrapper, 'settings-panel')}>
			<Drawer
				title={`${prefix} - ${settingsPanel.block.title}`}
				data-kemu-meta="settings-panel"
				placement="right"
				closable={true}
				maskClosable={true}
				mask={true}
				onClose={onClose}
				afterVisibleChange={onVisibilityChanged}
				visible={panelVisible}
				destroyOnClose={true}
				getContainer={false}
				width={350}
      >
				<div className="container" ref={containerRef}>
					{/* Content will be dynamically injected */}
				</div>
			</Drawer>
		</div>
	);
};

export default SettingsPanel;
