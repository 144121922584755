import { BrowserJsPlumbInstance } from '@jsplumb/community';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import Logger from '../../common/logger';
import { getGateInfoById } from '../../features/LogicMapper/logicMapperSlice';
import CanvasGateElement from './canvasGate';

const logger = Logger('canvasGate');

interface Props {
	thingRecipeId: string;
	thingDbId: string;
	thingVersion: string;
	recipeId: string;
	gateId: string;

	plumbInstance: BrowserJsPlumbInstance;
}

const CanvasGateWrapper = (props: Props): React.JSX.Element | null => {
	const gateInfo = useSelector(getGateInfoById(props.gateId));
	const { gateId, thingRecipeId, recipeId } = props;

	if (!gateInfo) {
		logger.log(`Gate ${gateId} not found (has it been removed?). Aborting rendering...`);
		return null;
	}

	return (
		<CanvasGateElement
			plumbInstance={props.plumbInstance}
			gateInfo={gateInfo}
			thingRecipeId={thingRecipeId}
			thingDbId={props.thingDbId}
			thingVersion={props.thingVersion}
			gateId={gateId}
			recipeId={recipeId}
		/>
	);
};

export default memo(CanvasGateWrapper);
