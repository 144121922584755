import { UserEntity } from '@kemu-io/kemu-types/dist/types';
import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import * as userApi from '../../../api/user/userApi';
import { AsyncRequestStatus } from '../../../types/core_t';
import { setUserProfile } from '../user/userSlice';
import { removeThingVersionsFromCache } from './helpers';
import { ThingState } from './thingSlice';


type ActionFulfilledResponse = {
  userProfile: UserEntity;
  thingId: string;
}

export const uninstallThingAction = createAsyncThunk('/thing/uninstall', async (
  thingId: string,
  thunkAPI
): Promise<ActionFulfilledResponse> => {
  // Remove from DB
  const userUpdated = await userApi.uninstallThing(thingId);
  // Remove from local cache (release disk space)
  await removeThingVersionsFromCache(thingId);
  // Update user profile 
  thunkAPI.dispatch(setUserProfile(userUpdated));

  return {
    userProfile: userUpdated,
    thingId
  };
});


export const uninstallThingReducer = ((builder: ActionReducerMapBuilder<ThingState>/*, thunk: any*/): void => {
  builder.addCase(uninstallThingAction.pending, (state) => {
    state.installThingStatus = {
      status: AsyncRequestStatus.loading,
      error: undefined
    };

    state.action = 'uninstalling';
  });

  builder.addCase(uninstallThingAction.fulfilled, (state, action: PayloadAction<ActionFulfilledResponse>) => {
    state.installThingStatus = {
      status: AsyncRequestStatus.completed,
      error: undefined
    };

    const filteredOut = (state.things || []).filter(thing => thing.id !== action.payload.thingId);
    // Append new thing to list
    state.things = filteredOut;
  });

  builder.addCase(uninstallThingAction.rejected, (state, action) => {
    state.installThingStatus = {
      status: AsyncRequestStatus.error,
      error: action.error
    };
  });
});
