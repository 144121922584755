import { UserEntity, UserIntegrations, UserTokens } from '@kemu-io/kemu-types/dist/types';
import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import * as userApi from '../../../api/user/userApi';
import { processUserWidgetsAction } from '../widget/processWidgetsReducer';
import { UserAccountState } from '../../../types/user';
import { AsyncRequestStatus } from '../../../types/core_t';
import { updateRecipeCacheMeta } from '../../recipe/utils';
import { RootState } from '../../store';
import { CustomerState } from './userSlice';

type UserProfileActionResponse = {
	profile: UserEntity,
	accountState: UserAccountState,
	integrations: UserIntegrations,
	tokens: UserTokens
};

/**
 * Fetches Kemu's user profile for the current user
 */
export const fetchUserProfileAction = createAsyncThunk('/user/fetch/profile', async (_,thunkAPI): Promise<UserProfileActionResponse> => {
	const { user, widgets, integrations, tokens } = await userApi.getProfile();

	let hasKemuAccessLicense = !!user.subscriptionInfo?.stripeSubscriptions?.find(licence => licence.type === 'KemuAccess');
	// Users that belong to an organization and are NOT the owner should not be in charge of billing
	if (user.organization && user.organization.role !== 'Owner') { hasKemuAccessLicense = true; }

	const isAdmin = user.organization?.role === 'Admin';
	const isOwner = user.organization?.role === 'Owner';
	const belongsToOrganization = !!(user.organization && user.organizationId);

	// Process the user's custom widgets
	thunkAPI.dispatch(processUserWidgetsAction({ widgets }));

	// Update the current recipe information now that we have info about the author
	const { workspace } = thunkAPI.getState() as RootState;
	if (workspace.currentRecipe.poolId) {
		updateRecipeCacheMeta(workspace.currentRecipe.poolId, {
			authorId: user.id,
		});
	}

	return {
		profile: user,
		integrations,
		tokens,
		accountState: {
			belongsToOrganization,
			hasKemuAccessLicense: hasKemuAccessLicense,
			isAdmin,
			isOwner
		}
	};
});

export const fetchUserProfileReducer = ((builder: ActionReducerMapBuilder<CustomerState>): void => {

	builder.addCase(fetchUserProfileAction.pending, (state) => {
		state.currentUserProfile.asyncState.status = AsyncRequestStatus.loading;
	});

	builder.addCase(fetchUserProfileAction.rejected, (state, action) => {
		state.currentUserProfile.asyncState.status = AsyncRequestStatus.error;
		state.currentUserProfile.asyncState.error = action.error;
	});

	builder.addCase(fetchUserProfileAction.fulfilled, (state, action: PayloadAction<UserProfileActionResponse>) => {
		state.currentUserProfile.asyncState.status = AsyncRequestStatus.completed;
		state.currentUserProfile.accountState = action.payload.accountState;
		state.currentUserProfile.profile = action.payload.profile;
		state.currentUserProfile.tokens = action.payload.tokens;
		state.currentUserProfile.integrations = action.payload.integrations;
	});
});
