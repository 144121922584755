import { API } from '@aws-amplify/api';
import { LimitedThingInfo } from '@kemu-io/kemu-types/dist/types/thing';
import globals from '../../common/globals';

const API_NAME = globals.THING_API_NAME;
const returnAxiosResponse = { response: true };

/**
 * Queries the database and returns a list of default blocks
 * for the current user profile.
 */
const getDefaultThings = async (): Promise<LimitedThingInfo[]> => {
	const response = await API.get(API_NAME, `/thing/default?webApp=${globals.WEB_APP_VERSION}`, returnAxiosResponse);
	return response.data;
};


/**
 * Fetches information about a block from the DB
 * @param id 
 * @param version 
 */
const getThingInfo = async (id: string, version?: string): Promise<LimitedThingInfo> => {
	const response = await API.get(API_NAME, `/thing/${id}/${version ? version : ''}`, returnAxiosResponse);
	return response.data as LimitedThingInfo;
};


export {
  getThingInfo,
  getDefaultThings,
};
