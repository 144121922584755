import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import styles from './pageModal.module.css';

interface Props {
	fullScreen: boolean;
	children?: React.JSX.Element | JSX.Element[];
	visible: boolean;
	onBackdropClick?: ()=> void;
}

const modalAnimation = {
	visible: {
		opacity: 1,
		transition: {
			delay: 0.2,
			ease: 'linear'
		},
		// y: '0'
	},

	hide: {
		opacity: 0,
		// y: '-100vh',
		transition: { delay: 0.2 },
	},

	hidden: {
		opacity: 0,
		// y: '-100vh'
	},
};

const backdropAnimation = {
	visible: { opacity: 0.6/*, translateY: '-100px' */ },
	hidden: {
		opacity: 0,
		transition: { delay: 0.4 }
		/*, translateY: '0px' */
	}
};

const PageModal = (props: Props): React.JSX.Element => {

	const handleBackdropClick = () => {
		props.onBackdropClick && props.onBackdropClick();
	};

	return (
		<AnimatePresence exitBeforeEnter>
			{props.visible && (
			<div className={styles.Container}>
				<motion.div
					animate="visible"
					initial="hidden"
					variants={backdropAnimation}
					exit="hidden"
					className={styles.Backdrop}
					onClick={handleBackdropClick}
				/>

				<motion.div
					animate="visible"
					initial="hidden"
					variants={modalAnimation}
					className={`${styles.Modal} ${props.fullScreen ? styles.FullScreen : ''}`}
				>

					{ props.children }

				</motion.div>
			</div>
			)}
		</AnimatePresence>
	);
};

export default PageModal;
