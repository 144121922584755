import Icon from '@ant-design/icons';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import RoundedModal from '../roundedModal/roundedModal';
import { ReactComponent as TutorialsIcon } from '../layout/marketplace/MarketplaceMenu/tutorials.svg';
import styles from './openLinkedTutorialPopup.module.css';

interface Props {
	onDismiss?: ()=> void;
	onShowTutorial?: ()=> void;
	width?: number;
}

const OpenLinkedTutorialPopup = (props: Props): React.JSX.Element => {

	return (
		<RoundedModal
			onOk={props.onShowTutorial}
			onCancel={props.onDismiss}
			visible={true}
			title={<>
				<Icon component={TutorialsIcon} style={{ fontSize: 20 }} /> <FormattedMessage id="Interface.Modals.LinkedTutorial.Title" defaultMessage="Linked Tutorial" />
			</>}
			okBtnLabel={<FormattedMessage id="Interface.Modals.LinkedTutorial.OpenBtn" defaultMessage="Open tutorial" />}
			cancelBtnLabel={<FormattedMessage id="Interface.Modals.LinkedTutorial.DismissBtn" defaultMessage="Later" />}
			closeOnMaskClick={false}

		>
			<div className={styles.Container}>
				<p><FormattedMessage id="Interface.Modals.LinkedTutorial.Description" defaultMessage="This recipe comes with a step by step tutorial. Do you want to open it now?" /></p>
			</div>
		</RoundedModal>
	);
};

export default OpenLinkedTutorialPopup;
