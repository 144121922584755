import React, { forwardRef } from 'react';
import { Input, InputProps } from 'antd';
import styles from './AlgoliaSearchInput.module.css';

type Props = InputProps & {
  suffix?: React.ReactNode;
};

const AlgoliaSearchInput = forwardRef<Input, Props>((props, ref): React.JSX.Element => {
  const { suffix, ...domProps } = props;
  return (
    <Input
      {...domProps}
      ref={ref}
      className={styles.Input}
      // Why <span />? ==> https://ant.design/components/input/#FAQ 
      suffix={suffix || <span />}
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="none"
      spellCheck="false"
    />
  );
});

export default AlgoliaSearchInput;
