import React, { memo, useCallback, useEffect, useRef } from 'react';
import { BrowserJsPlumbInstance }  from '@jsplumb/community';
import { useDispatch } from 'react-redux';
import { BlockPort, RecipeBlock } from '@kemu-io/kemu-core/dist/types/block_t';
import { openBlockAction, removeBlockAction } from '../../features/Workspace/workspaceSlice';
import { CreateEternalInstanceFunction, DestroyEternalInstanceFunction } from '../../common/hooks/useEternalCanvasInstance';
import { generateDomId } from '../../common/utils';
import { PortLocation, UICanvasInstance } from '../../types/canvas_t';
import { setBlockForSettingsPanel } from '../../features/interface/interfaceSlice';
import { NativeCanvasAction, SelectedBlockInfo } from '../../types/core_t';
import Logger from '../../common/logger';
import { getEndpointSetting } from '../../common/jsPlumb/settings';
import SettingsIcons from './settingsIcons/settingsIcons';
import './canvasBlock.css';

const logger = Logger('canvasBlock');
interface Props {
	recipeBlock: RecipeBlock;
	plumbInstance: BrowserJsPlumbInstance;
	/** Index in the recipe. This is used to determine the default name */
	index: number;
	/** id of the recipe in the pool this block belongs to */
	recipePoolId: string;
	createCanvasUI: CreateEternalInstanceFunction;
	destroyCanvasUI: DestroyEternalInstanceFunction;
}

const portInitialPos = 0.62;
const labelInitialPos = 35;


const geEndpointConfig = (index: number, isTarget: boolean, id: string, customPos?: PortLocation) => {
	// top (LR), left (TB), (dx, dy) <== direction of curve
	const targetPosition: PortLocation = [0.08, portInitialPos + (index * 0.35), -1, 0];
	const sourcePosition: PortLocation = [0.92, portInitialPos + (index * 0.35), 1, 0];

	const defaultPos = isTarget ? targetPosition : sourcePosition;
	const portSettings = getEndpointSetting(isTarget, customPos || defaultPos);
	portSettings.cssClass = `canvas-port ${portSettings.cssClass || ''}`;
	portSettings.uuid = id;
	return portSettings;
};

/**
 * Adds JSPlumb ports to the given targetElement
 */
const buildPorts = (plumbInstance: BrowserJsPlumbInstance, canvasUI: UICanvasInstance, targetBlockEl: HTMLElement, inputPorts: BlockPort[], outputPorts: BlockPort[]) => {
	// In dev mode, during a refresh, the canvas instance gets removed and 'exports' is no longer valid
	// I'm adding this check to prevent errors from stopping the rebuild
	if (!canvasUI.exports) { return; }

	const customPorts = canvasUI.exports.getPortsPosition && canvasUI.exports.getPortsPosition(inputPorts, outputPorts);

	inputPorts.map((port, index) => {
		if (targetBlockEl) {
			const portSettings = geEndpointConfig(index, true, port.id, customPorts?.inputs[index].position);
			plumbInstance.addEndpoint(targetBlockEl, { ...portSettings });
		}
	});

	outputPorts.map((port, index) => {
		if (targetBlockEl) {
			const portSettings = geEndpointConfig(index, false, port.id, customPorts?.outputs[index].position);
			plumbInstance.addEndpoint(targetBlockEl, { ...portSettings });
		}
	});
};


const CanvasBlockElement = (props: Props) => {
	const dispatch = useDispatch();
	// const { jspInstance: plumbInstance } = usePlumb();
	// if(!plumbInstance) { throw new Error(`Plumb library has not be initialized`); }

	const blockEl = useRef<HTMLDivElement>(null);
	const canvasElementId = useRef<string | null>(null);
	const { recipeBlock, recipePoolId, plumbInstance } = props;

	const blockLabel = `${recipeBlock.canvas.label || recipeBlock.name + ` ${props.index}`}`;
	const canvasUI = props.createCanvasUI(recipeBlock.id, recipeBlock.version, recipePoolId, recipeBlock.recipeId, blockLabel);
	const { destroyCanvasUI } = props;
	const { type: blockType, inputPorts, outputPorts } = recipeBlock;
	const usesCustomBody = !!canvasUI.exports.HAS_CUSTOM_ICON;



	// Extract primitive members to avoid re-renderings when getBlockInfo is defined
	const { id: blockId, recipeId: blockRecipeId, version: blockVersion } = recipeBlock;

	// eslint-disable-next-line deprecation/deprecation 
	canvasUI.exports.setTitle && canvasUI.exports.setTitle(blockLabel);

	const getBlockInfo = useCallback((): SelectedBlockInfo => {
		return {
			DbId: blockId,
			recipeId: blockRecipeId,
			recipePoolId: recipePoolId,
			version: blockVersion,
			title: blockLabel
		};
	}, [blockId, blockRecipeId, blockLabel, blockVersion, recipePoolId]);


	const onOpenBlock = () => {
		// This dispatch will also get intercepted by the LogicMaker reducer to load the correct gates
		dispatch(openBlockAction(getBlockInfo()));
	};

	const handleShowPropsPanel = useCallback(() => {
		dispatch(setBlockForSettingsPanel({ block: getBlockInfo() }));
	}, [getBlockInfo, dispatch]);


	const handleCustomAction = useCallback((action: NativeCanvasAction) => {
		if (action === 'OpenPropertiesPanel') {
			handleShowPropsPanel();
		}
	}, [handleShowPropsPanel]);


	// Called by canvas instances to repaint their ports
	const repaintPorts = useCallback(() => {
		if (blockEl.current) {
			plumbInstance.revalidate(blockEl.current);
		}
	}, [blockEl, plumbInstance]);


	const mountAndPaint = useCallback(() => {
		// Add custom icon to canvas container
		// NOTE: Even though `mount` is async, we don't
		// wait for blocks to finish
		canvasUI.mount(blockEl.current!);

		plumbInstance.batch(() => {
			buildPorts(plumbInstance, canvasUI, blockEl.current!, inputPorts, outputPorts);
		});

		if (canvasUI.exports) {
			// Define an event listener to access this canvas' actions
			canvasUI.exports.setTriggerCanvasAction && canvasUI.exports.setTriggerCanvasAction(handleCustomAction);
			canvasUI.exports.setRepaintPortsCb && canvasUI.exports.setRepaintPortsCb(repaintPorts);
		}
	}, [canvasUI, blockEl, inputPorts, outputPorts, plumbInstance, handleCustomAction, repaintPorts]);

	const getParentRef = useCallback(() => blockEl, [blockEl]);

	const destroyInstance = useCallback(async (force=false, isUserAction: boolean) => {
		if (canvasElementId.current) {
			logger.log(`Removing endpoints (${blockRecipeId})`);
			plumbInstance.removeAllEndpoints(canvasElementId.current);
		}

		if (canvasUI.exports && (!canvasUI.exports.IS_ETERNAL || force)) {
			await destroyCanvasUI(canvasUI, isUserAction);
		}

		if (canvasUI.exports && canvasUI.exports.IS_ETERNAL && canvasUI.exports.onHidden) {
			canvasUI.exports.onHidden();
		}
	}, [canvasUI, plumbInstance, destroyCanvasUI, blockRecipeId]);


	const handleDelete = useCallback(async () => {
		await destroyInstance(true, true);
		dispatch(removeBlockAction(blockRecipeId));
	}, [blockRecipeId, dispatch, destroyInstance]);


	useEffect(() => {
		logger.log(`BuildingPorts (${blockType} ${blockRecipeId})`);
		if (blockEl.current) {
			// Add container to the DOM and paint ports
			mountAndPaint();

			// By the time the canvas blocks are unmounted the reference to the
			// element (blockEl) no longer exists. So that we can refer to the 
			// original object and remove ports and connections I store the id of the
			// element as a reference which is persistent across the component lifecycle
			canvasElementId.current = blockEl.current.id;
		}

		return () => {
			destroyInstance(false, true);
		};

	}, [blockEl, destroyInstance, mountAndPaint, blockType, blockRecipeId]);

	const parentClassName = 'canvas-block';
	// const { show: showMenu } = useContextMenu({ id: MENU_BLOCK });
	const handleItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		event.preventDefault();
		/* showMenu(event, { props: { boundaryClass: parentClassName } }); */
	};

	return (
		// IMPORTANT: A canvas block without input and outputs won't be draggable!
		<div data-kemu-type={recipeBlock.id} className={`${parentClassName} ${usesCustomBody ? 'custom' : ''}`}
			ref={blockEl}
			style={{ left: recipeBlock.canvas.position.x, top: recipeBlock.canvas.position.y }}
			onDoubleClick={onOpenBlock}
			id={generateDomId(recipePoolId, recipeBlock.recipeId)}
			onContextMenu={handleItemClick}
		>
			<SettingsIcons
				getParentRef={getParentRef}
				onPropsPanelClick={handleShowPropsPanel}
				onLogicMakerClick={onOpenBlock}
				onRemoveClick={handleDelete}
				blockRecipeId={recipeBlock.recipeId}
				recipePoolId={recipePoolId}
			/>
			<div className="in ports-container">
				{inputPorts.map((port, index) => {
					return (
						<div key={index} className="port" style={{ top: labelInitialPos + (index * 24) }}>
							<div className="label noselect">{port.label}</div>
						</div>
					);
				})}
			</div>


			<div className="out ports-container">
				{outputPorts.map((port, index) => {
					return (
						<div key={index} className="port" style={{ top: labelInitialPos + (index * 24) }}>
							<div className="label noselect">{port.label}</div>
						</div>
					);
				})}
			</div>

			{!usesCustomBody && (
				<div className="cb-container">
					<div className="cb-header">
						<div className="cb-label noselect">
							<div>{blockLabel}</div>
						</div>
					</div>
					<div className="cb-body" /*ref={canvasIconBodyRef}*/>
						{/* Icon or visual content */}
					</div>
				</div>
			)}
		</div>
	);
};

export default memo(CanvasBlockElement);
