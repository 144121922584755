/*
 * Written by Alexander Agudelo < alex.agudelo@kitsunei.com >, 2020
 * Date: 06/Dec/2020
 * Last Modified: 17/07/2023, 3:58:11 pm
 * Modified By: Alexander Agudelo
 * Description:  State of gates might happen outside the store (Kemu-core), this cook
 * uses Kemu's event listener subscriptions to notify the component the state has changed
 * 
 * ------
 * Copyright (C) 2020 Kitsunei - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential.
 */

import { useCallback, useEffect, useRef, useState } from 'react';
import { GateStateChangeEvent } from '@kemu-io/kemu-core/dist/blocks/logicProcessor';
import KemuCore from '@kemu-io/kemu-core/dist/index';
import { CustomWidgetState, GateState } from '@kemu-io/kemu-core/dist/types/gate_t';
import { extractPrivateProperties, deepMerge } from '@kemu-io/kemu-core/dist/common/utils';
import { safeJsonClone, safeJSONStringify } from '../utils';
import useWidgetState from './useWidgetState';

type GetStateFunction<T> = ((currentState: CustomWidgetState<T>) => T | Promise<T>);
type NewStateOrFunction<T> = (T) | GetStateFunction<T>;
type GateStateResponse<T> = [currentState: T, setState: (newState: NewStateOrFunction<T>, triggerEvents?: boolean) => void];
type CompareFunction<T> = (prevState: CustomWidgetState<T>, newState: CustomWidgetState<T>) => boolean;

const getStateCopy = <T>(currentState: CustomWidgetState<T>): T => {
	const privateLessState = safeJsonClone(currentState);
	const privatePropsOnly = extractPrivateProperties(currentState);
	const merged = deepMerge<CustomWidgetState<T>>(privateLessState, privatePropsOnly);
	return merged;
};

/**
 * Keeps track of the state of the block
 * @param recipePoolId the id of the recipe in the pool
 * @param thingId the id of the thing in the recipe (blockRecipeId)
 * @param widgetId the id of the widget in the thing.
 * @param compareFun a function to call when a new state is available. The function
 * should return true if the new state needs to be updated, otherwise the new state
 * will be ignored. 
 * 
 * IMPORTANT: Make sure this function is declared OUTSIDE the component or created with a 
 * useCallback. 
 */
function useReactiveWidgetState<T=GateState> (recipePoolId: string, thingId: string, widgetId: string, compareFun?: CompareFunction<T>): GateStateResponse<T> {
	const stateRef = useRef<(CustomWidgetState<T>) | null>(null);
	const { getState } = useWidgetState(recipePoolId, thingId, widgetId);

	// Read state directly from the recipe during initialization
	const [state, setState] = useState<GateState>(() => {
		const recipeState = getState();
		// WARNING: nested objects will be copied by reference, causing the state to mutate
		// if the widget state is modified. Widget components MUST make sure never to mutate
		// properties directly.
		stateRef.current = {
			...(recipeState as CustomWidgetState<T>),
		};

		return { ...recipeState };
	});

	const onGateStateChanged = useCallback((evt: GateStateChangeEvent) => {
		if (evt.recipeId === recipePoolId && evt.gateId === widgetId) {
			if (compareFun && stateRef.current) {
				const shouldUpdate = compareFun(stateRef.current, evt.newState as CustomWidgetState<T>);
				if (shouldUpdate) {
					// NOTE: here we need to keep a copy of the widget state in memory
					// so we can compare it with new states and determine if it has changed.
					// However, this copy cannot be the same reference since it will then be 
					// referring to the same object in memory and any changes will be reflected immediately,
					// (making the comparison later useless). We can't just stringify and parse because
					// some widgets will have private properties that are very large or have circular references.
					// So we need to extract the private properties before stringifying the state, then
					// put back the private properties (since they will be ignored anyway during the comparison)
					// const privateLessState = safeJsonClone(evt.newState);
					// const privatePropsOnly = extractPrivateProperties(evt.newState);
					// const merged = deepMerge<CustomWidgetState<T>>(privateLessState, privatePropsOnly);
					// stateRef.current = merged;
					stateRef.current = getStateCopy<CustomWidgetState<T>>(evt.newState as CustomWidgetState<T>);

					setState(evt.newState);
				}
			} else {
				// Compare state without private properties
				const prevStr = safeJSONStringify(stateRef.current);
				const newStr = safeJSONStringify(evt.newState);
				if (prevStr !== newStr) {
					stateRef.current = getStateCopy<CustomWidgetState<T>>(evt.newState as CustomWidgetState<T>);

					setState(evt.newState);
				}
			}
		}
	}, [recipePoolId, widgetId, compareFun]);


	const setGateState = useCallback(async (newState: T | NewStateOrFunction<T>, triggerEvents=true): Promise<void> => {
		if (typeof newState === 'function') {
			// Read the latest state directly from the recipe
			const latestState = getState();
			// Call caller to read the next-to-be state
			const updatedState = await (newState as GetStateFunction<T>)(latestState as CustomWidgetState<T>);
			// Ignore updates that contain the same object
			if ((updatedState as unknown as GateState) !== latestState) {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				KemuCore.setGateState(recipePoolId, thingId, widgetId, updatedState as any, triggerEvents);
			}
		} else {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			KemuCore.setGateState(recipePoolId, thingId, widgetId, newState as any, triggerEvents);
		}
	}, [recipePoolId, thingId, widgetId, getState]);


	useEffect(() => {
		KemuCore.onGateStateChanged(onGateStateChanged, thingId, widgetId);

		// Update the state if one of the key properties changes (because it means)
		// we are not longer referring to the same widget.
		const latestState = getState();
		// Do a double check to prevent unnecessary render cycles by setting a state that is effectively the same
		const differ = safeJSONStringify(latestState) !== safeJSONStringify(stateRef.current);
		if (differ) {
			stateRef.current = getStateCopy<CustomWidgetState<T>>(latestState as CustomWidgetState<T>);

			setState(latestState);
		}


		return () => {
			KemuCore.offGateStateChanged(onGateStateChanged, thingId, widgetId);
		};
	}, [recipePoolId, thingId, widgetId, onGateStateChanged, getState]);

	return [
		<T> <unknown>state,
		setGateState
	];
}

export default useReactiveWidgetState;
